import { Box, styled } from '@mui/material';
import { ThemeTypography } from 'designSystem';
import React, { FC, MouseEvent } from 'react';
import theme from 'styles/theme';
import ValidationIndicator, { ValidationState } from './ValidationIndicator';

export type NavigationItemState = 'selected' | 'disabled' | 'default';

interface INavigationItemProps {
  index?: number;
  title: string;
  state?: NavigationItemState;
  validationState?: ValidationState;
  onClick: (event: MouseEvent) => void;
}

const Index = styled('div')(({ theme }) => ({
  color: '#fff',
  background: theme.palette.secondary.main,
  borderRadius: '50%',
  width: theme.spacing(3),
  minWidth: theme.spacing(3),
  height: theme.spacing(3),
  fontSize: 12,
  lineHeight: 11,
  boxShadow: theme.custom.shadows[1],
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontWeight: 700,
  marginRight: theme.spacing(1),
}));

const MENU_ITEM_COLOR: Record<NavigationItemState, string> = {
  disabled: theme.palette.grey[500],
  selected: theme.palette.secondary.main,
  default: theme.palette.text.primary,
};

const StyledMenuItem = styled('div')<{ state: NavigationItemState }>(({ state, theme }) => ({
  position: 'relative',
  background: '#fff',
  flexGrow: 1,
  boxShadow: state === 'disabled' ? 'none' : theme.custom.shadows[2],
  opacity: state === 'disabled' ? 0.5 : 1,
  cursor: state === 'disabled' ? 'default' : 'pointer',
  padding: theme.spacing(2),
  marginBottom: theme.spacing(1),
  color: MENU_ITEM_COLOR[state],
  transition: 'all 0.125s',
  overflow: 'hidden',
  borderRadius: theme.shape.borderRadius,

  '&:hover': {
    background: state === 'disabled' ? '#fff' : theme.custom.colors.backgroundLight,
  },

  '&:after': {
    content: '""',
    position: 'absolute',
    width: 8,
    height: '100%',
    left: 0,
    top: 0,
    background: state === 'selected' ? theme.palette.secondary.main : 'transparent',
  },
}));

const NavigationItem: FC<INavigationItemProps> = ({
  state = 'default',
  index,
  title,
  validationState,
  onClick,
}) => {
  return (
    <StyledMenuItem state={state} data-cy={`menu-item-${index}`} onClick={onClick}>
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" alignItems="center" overflow="hidden">
          {index !== undefined && <Index>{index + 1}</Index>}
          <ThemeTypography variant="BODY_LARGE" autoOverflow>
            {title}
          </ThemeTypography>
        </Box>
        {<ValidationIndicator state={validationState} />}
      </Box>
    </StyledMenuItem>
  );
};

export default NavigationItem;
