import React, { useEffect } from 'react';
import { appQueryParams } from 'constants/appQueryParams';
import { useQueryParam, BooleanParam } from 'use-query-params';
import { useMutation } from '@apollo/client';
import { UPDATE_ONBOARDING } from 'graphql/mutations';
import { useOnboardingTour, useUserData } from 'components/hooks';

interface IUseDataImportOnboardingProps {
  hideTour?: boolean;
}

const useDataImportOnboarding = ({ hideTour }: IUseDataImportOnboardingProps) => {
  const [displayOnboardingTour, setOnboardingTour] = useQueryParam(
    appQueryParams.tour,
    BooleanParam
  );

  const [updateOnboarding] = useMutation(UPDATE_ONBOARDING);
  const { startTour } = useOnboardingTour();
  const { user } = useUserData();

  const welcomeStep = {
    content: () => (
      <div>
        <b>Welcome to the origin data import tool!</b>
        <p>
          This is where you can upload tracing data from your operations and easily share it with
          your partners. Let’s take a quick tour to get you started!
        </p>
      </div>
    ),
    style: {
      maxWidth: 420,
    },
  };

  const tableStep = {
    content: () => (
      <p>
        The column titles represent your data points. You can drag and drop to re-order the columns
        so that they match the order of your external sheet or table. Then simply copy all data from
        your existing table or sheet, mark the top left cell in this table, and hit ‘ctrl-v’ to
        paste all data into this table
      </p>
    ),
    selector: '[data-tut="onboarding_tour_data_import_table"]',
    style: {
      top: -16,
      minWidth: 470,
    },
  };

  const settingsStep = {
    content: () => (
      <p>
        You can also update the table settings so that the data format and units matches that of
        your external sheet or table. This allows you to simply paste and save your data with less
        manual edits.
      </p>
    ),
    selector: '[data-tut="onboarding_tour_data_import_settings"]',
  };

  const datasetNameStep = {
    content: () => (
      <div>
        <p>
          You can name your dataset in this field. You will see in the overview when each data set
          was imported.
        </p>
        <b>Happy tracing!</b>
      </div>
    ),
    selector: '[data-tut="onboarding_tour_data_import_dataset_name"]',
    style: {
      minWidth: 400,
    },
  };

  const dataImportSteps = [welcomeStep, tableStep, settingsStep, datasetNameStep];

  const handleCompleteOnboardingTour = () => {
    setOnboardingTour(undefined);
    updateOnboarding({
      variables: {
        input: {
          flag: 'sawDataImportTour',
        },
      },
    });
  };

  const startOnboardingTour = () => {
    setOnboardingTour(true);
    startTour({
      tourSteps: dataImportSteps,
      amplitudeEvent: 'DID_DATA_IMPORT_ONBOARDING_TOUR',
      onCompleted: handleCompleteOnboardingTour,
      firstStepButtonText: `Let's do it!`,
    });
  };

  useEffect(() => {
    if (user?.onboarding && !user.onboarding.sawDataImportTour && !hideTour) {
      startOnboardingTour();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return {
    displayOnboardingTour,
    handleStartOnboardingTour: startOnboardingTour,
  };
};

export default useDataImportOnboarding;
