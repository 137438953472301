import { useMutation } from '@apollo/client';
import { PublishValidationDialog, ValidFinalCheckDialog } from 'components/Product';
import CompanyDataDialog from 'components/Product/MultiStep/CompanyDataDialog';
import UnpublishDialog from 'components/Product/UnpublishDialog';
import {
  useBackendErrorMessage,
  useConfig,
  useDialog,
  useLogEvent,
  useMessages,
} from 'components/hooks';
import { AlertDialog } from 'designSystem';
import { PUBLISH_PRODUCT } from 'graphql/mutations';
import { UNPUBLISH_PRODUCT } from 'graphql/mutations/product.mutations';
import { GET_LATEST_VERSION_AND_PRODUCT, GET_PRODUCT } from 'graphql/queries';
import React, {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Product } from 'types/types';
import { BooleanParam, StringParam, useQueryParam } from 'use-query-params';

interface IPublishProductProviderProps extends PropsWithChildren {
  product: Product;
  disableValidation?: boolean;
}

interface PublishProductContextValues {
  handleClickPublish: () => void;
  handleClickUnpublish: () => void;
}

const PublishProductContext = createContext<PublishProductContextValues>({
  handleClickPublish: () => undefined,
  handleClickUnpublish: () => undefined,
});

const PublishProductProvider: FC<IPublishProductProviderProps> = ({
  children,
  product,
  disableValidation,
}) => {
  const [validationDialogOpen, setValidationDialogOpen] = useState(false);
  const [unpublishDialogOpen, setUnpublishDialogOpen] = useState(false);
  const [companyAlert, setCompanyAlert] = useState(false);
  const [companyEditOpen, setCompanyEditOpen] = useState(false);
  // const [defaultLanguageAlert, setDefaultLanguageAlert] = useState(false);
  const { openDialog } = useDialog();
  const { logEvent } = useLogEvent();

  const setBackendError = useBackendErrorMessage();
  const { setSuccessMessage } = useMessages();
  const productId = product?.id;
  const defaultLanguage = product?.languageConfig?.default;
  // const publishedLanguages = product?.publishedLanguages;

  const { appQueryParams } = useConfig();
  const [selectedLang] = useQueryParam(appQueryParams.lang, StringParam);
  const [, setShowValidation] = useQueryParam(appQueryParams.reviewMode, BooleanParam);
  const language = selectedLang || defaultLanguage;

  const [publish, { loading: publishLoading }] = useMutation(PUBLISH_PRODUCT, {
    onCompleted: () => {
      setValidationDialogOpen(false);

      openDialog({
        type: 'PUBLISH_SUCCESS',
        props: {
          product,
          lang: language,
        },
      });
    },
    onError: error => {
      console.error(error);
      setValidationDialogOpen(false);

      if (error.message === 'COMPANY_DATA_INVALID') {
        setCompanyAlert(true);
        return;
      }

      setBackendError(error.message, 'Something went wrong publishing your product');
    },
    variables: {
      id: productId,
      lang: language,
    },
    refetchQueries: [
      {
        query: GET_LATEST_VERSION_AND_PRODUCT,
        variables: {
          id: productId,
          lang: language,
        },
      },
    ],
    awaitRefetchQueries: true,
  });

  const [unpublish, { loading: unpublishLoading }] = useMutation(UNPUBLISH_PRODUCT, {
    onCompleted: () => {
      setSuccessMessage('Product page was unpublished');
      setUnpublishDialogOpen(false);
    },
    onError: error => {
      setBackendError(error.message, 'Something went wrong unpublishing your product');
    },
    update: (cache, { data }) => {
      cache.writeQuery({
        query: GET_PRODUCT,
        data: {
          product: {
            ...data.unpublishProduct,
            __typename: 'Product',
          },
        },
      });
    },
  });

  const handleClickPublish = () => {
    if (!product) return;
    setValidationDialogOpen(true);

    // const disablePublishing = !(
    //   defaultLanguage === language || publishedLanguages.includes(defaultLanguage)
    // );
    // if (disablePublishing) {
    //   setDefaultLanguageAlert(true);
    // } else {
    //   // setPublishingProduct(product);
    //   setValidationDialogOpen(true);
    // }
  };

  const handleClickUnpublish = () => setUnpublishDialogOpen(true);
  const handleCloseUnpublishDialog = () => setUnpublishDialogOpen(false);

  const handleUnpublishAll = () => unpublish({ variables: { id: productId } });
  const handleUnpublish = (lang: string) => unpublish({ variables: { id: productId, lang } });

  useEffect(() => {
    if (!product) {
      console.error(
        'No product provided to the "PublishProductContext", only use the context when a product is active'
      );
    }
  }, [product]);

  // If no product is found, it should NOT continue
  // This is a fallback, should never happen
  if (!product) {
    return (
      <PublishProductContext.Provider
        value={{
          handleClickUnpublish,
          handleClickPublish,
        }}
      >
        {children}
      </PublishProductContext.Provider>
    );
  }

  async function handlePublish() {
    await publish();
    logEvent('PUBLISH_PRODUCT', {
      product_id: productId,
      product_slug: product?.slug,
    });
  }

  const handleValidationDialogClose: (event?: { showValidation: boolean }) => void = event => {
    setValidationDialogOpen(false);

    if (event?.showValidation) setShowValidation(true);
  };

  return (
    <PublishProductContext.Provider
      value={{
        handleClickUnpublish,
        handleClickPublish,
      }}
    >
      {validationDialogOpen &&
        (disableValidation ? (
          <ValidFinalCheckDialog
            loading={publishLoading}
            onPublish={handlePublish}
            onClose={handleValidationDialogClose}
            language={defaultLanguage}
          />
        ) : (
          <PublishValidationDialog
            loading={publishLoading}
            onPublish={handlePublish}
            onClose={handleValidationDialogClose}
          />
        ))}
      {companyAlert && (
        <AlertDialog
          onClose={() => setCompanyAlert(false)}
          title="Company details missing"
          data-cy="dialog-company-details-missing"
          open
          text={`
            We could not publish your product
            because you have not filled in all
            required information about your company.
            We use this information to e.g.
            link to your website or display your location
            on the published product page.
          `}
          onSubmit={() => setCompanyEditOpen(true)}
          onCancel={() => setCompanyAlert(false)}
          cancelText="Maybe later"
          submitText="Update details"
        />
      )}
      {companyEditOpen && (
        <CompanyDataDialog
          open
          onClose={() => setCompanyEditOpen(false)}
          onSubmit={() => {
            setCompanyEditOpen(false);
            setValidationDialogOpen(true);
          }}
        />
      )}

      {/*
        Should not be needed since the fallback product still works even if it's not published in the default language
      {defaultLanguageAlert && (
        <AlertDialog
          onClose={() => setDefaultLanguageAlert(false)}
          title="Default language not published"
          data-cy="default-language-not-published"
          open
          text="In order to publish the product in the selected language, the
              product must first be published in the default language."
          onSubmit={() => setDefaultLanguageAlert(false)}
          submitText="Close"
        />
      )} */}
      {unpublishDialogOpen && (
        <UnpublishDialog
          open={unpublishDialogOpen}
          loading={unpublishLoading}
          currentProductLang={language}
          hasMultiplePublishedLanguages={product.publishedLanguages.length > 1}
          onUnpublishAllLanguages={handleUnpublishAll}
          onUnpublishLanguages={handleUnpublish}
          onClose={handleCloseUnpublishDialog}
        />
      )}
      {children}
    </PublishProductContext.Provider>
  );
};

const usePublishProductContext = () =>
  useContext<PublishProductContextValues>(PublishProductContext);

export { PublishProductProvider, usePublishProductContext };

export default PublishProductContext;
