import React, { createContext } from 'react';
import config from './config.json';
import Config from './Config';
import { appQueryParams } from 'constants/appQueryParams';
import userRoles from 'constants/userRoles';

const defaultConfig = config.dev;
const environment = process.env.REACT_APP_DEPLOYMENT_ENV || 'dev';
const environmentConfig = config[environment];
const finalConfig = {
  ...defaultConfig,
  ...environmentConfig,
  appQueryParams,
  userRoles,
};

const ConfigContext = createContext(null);
const ConfigConsumer = ConfigContext.Consumer;
const configInstance = new Config(finalConfig);

const ConfigProvider = ({ children }) => (
  <ConfigContext.Provider value={{ config: configInstance }}>{children}</ConfigContext.Provider>
);

export { ConfigProvider, ConfigConsumer, configInstance };

export default ConfigContext;
