/**
 * Get the category id from the type name
 */
export function getCategoryId(categoryType, categories) {
  return categories.find(({ type }) => categoryType === type)?.id;
}

/**
 * Get the title matching the impact claim status
 */
export const getStatusTitle = status => {
  return {
    CREATED: 'First submitted',
    UPDATED: 'Re-submitted',
    REQUESTED: 'Requested',
    APPROVED: 'Approved',
    DENIED: 'Declined',
    ARCHIVED: 'Archived',
    DELETED: 'Deleted',
  }[status];
};

/**
 * Get the title matching the impact claim status
 */
export const getStatusSubtitle = status => {
  return {
    CREATED: 'DRAFT',
    UPDATED: 'DRAFT',
    REQUESTED: 'PENDING',
    APPROVED: 'APPROVED',
    DENIED: 'DECLINED',
    ARCHIVED: 'ARCHIVED',
    DELETED: 'DELETED',
    GROUP: 'GROUP',
  }[status];
};

export const getImpactClaimGroups = ({ impactClaims, impactCatalogItems }) => {
  const {
    ecologicalItems: { edges: ecologicalItemEdges = [] } = {},
    socialItems: { edges: socialItemEdges = [] } = {},
  } = impactCatalogItems;
  const allImpactCatalogItems = [...ecologicalItemEdges, ...socialItemEdges];
  return allImpactCatalogItems.reduce((allCatalogItems, catalogItem) => {
    const impactClaimGroup = impactClaims.filter(impactClaim => {
      return impactClaim.impactCatalogItem?.id === catalogItem.node.id;
    });

    if (!!impactClaimGroup.length) {
      allCatalogItems.push({
        ...catalogItem.node,
        impactClaims: impactClaimGroup,
      });
    }

    return allCatalogItems;
  }, []);
};

export default getImpactClaimGroups;

export const getCreatedTimestampOfGroup = ({ impactClaims }) => {
  return impactClaims.reduce((a, b) => {
    return a.createdTimestamp < b.createdTimestamp ? a.createdTimestamp : b.createdTimestamp;
  });
};

export const calculateAttachmentCountOfGroup = ({ impactClaims }) => {
  return impactClaims.reduce((total, impactClaim) => total + impactClaim.attachmentCount, 0);
};

export const filterImpactClaims = (impactClaims, searchTerm = '') => {
  if (!impactClaims) return impactClaims;

  if (!searchTerm.length) return impactClaims.map(item => item.node);

  const filtered = impactClaims.filter(({ node: { impactCatalogItem, partner, product } }) => {
    const catalogMatch = impactCatalogItem.title.toLowerCase().includes(searchTerm.toLowerCase());
    const partnerMatch = partner?.title.toLowerCase().includes(searchTerm.toLowerCase());
    const productMatch = product?.title.toLowerCase().includes(searchTerm.toLowerCase());

    return catalogMatch || partnerMatch || productMatch;
  });

  return filtered.map(item => item.node);
};
