import { FormControl, FormHelperText } from '@mui/material';
import { styled } from '@mui/material/styles';
import { AlertDialog } from 'designSystem';
import LanguageMultiSelect from 'components/Forms/Input/LanguageSelect/LanguageMultiSelect';
import { useConfig } from 'components/hooks';
import { getIn, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';

const StyledControl = styled(FormControl)({
  position: 'relative',
  width: '100%',
});

const Error = styled(FormHelperText)({
  position: 'absolute',
  bottom: -20,
});

const LanguageSelector = ({ defaultLanguage, publishedLanguages, ...fieldProps }) => {
  const config = useConfig();
  const [alertForLang, setAlertForLang] = useState(null);
  const [alertLastPublished, setAlertLastPublished] = useState(null);
  const { values } = useFormikContext();
  const {
    field: { value, name },
    form: { errors, touched, setFieldValue },
  } = fieldProps;

  const fieldError = getIn(errors, name);
  const showError = getIn(touched, name) && typeof fieldError === 'string';
  const showStatus = !!publishedLanguages;

  const handleRemove = (key, force = false) => {
    if (publishedLanguages?.includes(key) && publishedLanguages?.length === 1 && !force) {
      setAlertLastPublished(key);
      return;
    }

    if (publishedLanguages?.includes(key) && !force) {
      setAlertForLang(key);
      return;
    }

    const rest = value.filter(item => item !== key);
    setFieldValue(name, rest);
  };

  useEffect(() => {
    if (!defaultLanguage || value.includes(defaultLanguage)) return;

    setFieldValue(name, [...value, defaultLanguage]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultLanguage]);

  useEffect(() => {
    if (!defaultLanguage) return;

    if (!value.includes(defaultLanguage)) {
      setFieldValue(name, [...value, defaultLanguage]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <StyledControl>
      <LanguageMultiSelect
        selectedLanguages={values.languageConfig.enabled}
        setSelectedLanguages={selectedLanguages => {
          setFieldValue(name, selectedLanguages);
        }}
        languages={config.productLanguages}
        onDelete={handleRemove}
        defaultLanguage={defaultLanguage}
      />

      {showStatus && alertForLang && (
        <AlertDialog
          onClose={() => setAlertForLang(null)}
          title={`You're about to unpublish the ${
            config.getLanguageConfig(alertForLang).title
          } version of your product`}
          open
          text="Removing this language from the enabled language list will make it inaccessible for your visitors. You can always add it back and publish this language again."
          onSubmit={() => {
            handleRemove(alertForLang, true);
            setAlertForLang(null);
          }}
          onCancel={() => setAlertForLang(null)}
          submitText="Remove"
        />
      )}
      {showStatus && alertLastPublished && (
        <AlertDialog
          onClose={() => setAlertLastPublished(null)}
          title={`Cannot remove ${
            config.getLanguageConfig(alertLastPublished).title
          } version of your product`}
          open
          text="This is your only published language. If you would like to unpublish your product completely please go to the Products page and click the archive icon"
          onSubmit={() => {
            setAlertLastPublished(null);
          }}
          submitText="Close"
        />
      )}
      {showError && <Error error>{fieldError}</Error>}
    </StyledControl>
  );
};

LanguageSelector.propTypes = {};

export default LanguageSelector;
