import React, { FC } from 'react';
import { createCrop } from 'utils/image.utils';
import Imgix from 'react-imgix';
import { styled } from '@mui/material';
import { ImageVariant, MediaItem } from 'types/media.types';

interface IImageProps {
  image?: ImageVariant | MediaItem | null;
  backupImageUrl?: string;
  width?: number;
  height?: number;
  alt?: string;
  borderRadius?: number;
}

const ImageContainer = styled('div')<{
  width?: number | string;
  height?: number | string;
  backgroundImage?: string;
}>(({ width, height, backgroundImage }) => ({
  backgroundImage: `url(${backgroundImage})`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  width: width ? width : '100%',
  height: height ? height : '100%',
  flexShrink: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const ImgixImage = styled(Imgix)<{ borderRadius: number }>(({ borderRadius }) => ({
  width: 'auto',
  height: 'auto',
  maxWidth: '100%',
  maxHeight: '100%',
  objectFit: 'contain',
  flexShrink: 0,
  borderRadius: borderRadius,
}));

const Image: FC<IImageProps> = ({
  image,
  width,
  height,
  backupImageUrl,
  alt,
  borderRadius = 4,
}) => {
  const cropString = image?.crop ? createCrop(image?.crop) : null;

  return (
    <ImageContainer
      width={width}
      height={height}
      backgroundImage={!image ? backupImageUrl : undefined}
    >
      {image && (
        <ImgixImage
          width={width}
          height={height}
          src={image.url || ''}
          borderRadius={borderRadius}
          htmlAttributes={{ loading: 'lazy', alt: alt || '' }}
          imgixParams={{
            ...(cropString?.length && {
              rect: cropString,
            }),
          }}
        />
      )}
    </ImageContainer>
  );
};

export default Image;
