import { Box } from '@mui/material';
import { CustomTooltip } from 'components/Structure';
import Icon, { IconNameType } from 'designSystem/Primitives/Icon/Icon';
import React, { CSSProperties, FC } from 'react';
import { matchRoutes, useLocation, useParams } from 'react-router-dom';
import { DRAWER_CLASSES } from '../DrawerMenu';
import { DrawerSubMenuItem } from '../DrawerUtils';
import {
  StyledListItem,
  StyledListItemText,
  StyledNavSubLinkWrapper,
  StyledSubMenuIcon,
  StyledSvgIcon,
} from '../StyledDrawerItems';

interface IDrawerSubItemProps {
  // To show the selected indicator of the submenu items correctly the inactive items before the current item has to be ignored
  visibleIndex: number;
  item: DrawerSubMenuItem;
  drawerExpanded: boolean;
  params?: string[];
}

const SubMenuIcon: FC<
  | {
      /**
       * Use the iconName instead
       * @deprecated
       */
      Icon: FC;
      activeClassStyle?: CSSProperties | { [key: string]: CSSProperties };
    }
  | { iconName: IconNameType }
> = props => {
  if ('Icon' in props) {
    const ThisIcon = props.Icon;
    return (
      <StyledSvgIcon
        className={`${DRAWER_CLASSES.icon} menu-icon`}
        viewBox="0 0 15 18"
        is-child="true"
      >
        <StyledSubMenuIcon activeClassStyle={props.activeClassStyle}>
          <ThisIcon />
        </StyledSubMenuIcon>
      </StyledSvgIcon>
    );
  }
  return <Icon name={props.iconName} size="medium" />;
};

const DrawerSubItem: FC<IDrawerSubItemProps> = ({
  visibleIndex,
  item: { visible, label, to, activeRoutes = [], dataTestId, ...subMenuIconProps },
  params,
  drawerExpanded,
}) => {
  // Instead of calling these functions in each item, we could call them in the parent component and pass the result as props
  const routeParams = useParams();
  const location = useLocation();

  const matchDrawerItemRoute = matchRoutes(
    activeRoutes.map(path => ({ path, end: true })),
    location
  );

  let newRouteParams: { [key: string]: string } = {};
  params?.forEach(param => {
    // @ts-ignore TODO: Fix this with typing the props correctly
    newRouteParams = {
      ...newRouteParams,
      [param]: routeParams[param],
    };
  });
  const target = to(newRouteParams);

  if (!visible) return <></>;

  const Link = (
    <CustomTooltip placement="right" title={drawerExpanded ? '' : label}>
      <Box component="span">
        <StyledNavSubLinkWrapper
          end
          to={target}
          className={({ isActive }) => (isActive || matchDrawerItemRoute?.length ? 'active' : '')}
          nthChild={visibleIndex}
          data-testid={dataTestId}
          drawerExpanded={drawerExpanded}
        >
          {drawerExpanded ? (
            <StyledListItem is-child="true">
              <StyledListItemText is-child="true" drawer-expanded="true">
                {label}
              </StyledListItemText>
            </StyledListItem>
          ) : (
            <SubMenuIcon {...subMenuIconProps} />
          )}
        </StyledNavSubLinkWrapper>
      </Box>
    </CustomTooltip>
  );

  return Link;
};

export default DrawerSubItem;
