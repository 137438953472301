import { Box, Grid, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { MediaField, TextFieldLimited } from 'components/Forms';
import { FieldWrapper } from 'designSystem';
import { mergeDefaults } from 'components/Forms/utils';
import { useProduct } from 'components/Product/hooks';
import { useMenu } from 'components/Product/MultiStep/hooks';
import { generalSchema } from 'constants/schemas';
import { Field } from 'formik';
import React, { useMemo } from 'react';
import FormContainer from './FormContainer';
import Header from './Header';
import { Container } from './styles';
import { TextField } from 'formik-mui';

const General = () => {
  const {
    latestProductVersion: { title, image, webshopUrl },
  } = useProduct();
  const initialValues = useMemo(
    () =>
      mergeDefaults(generalSchema.default(), {
        title,
        image,
        webshopUrl,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [title, image, webshopUrl, generalSchema]
  );
  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up('lg'));
  const { previewIsActive } = useMenu();
  const showTextOnTheSide = !previewIsActive && largeScreen;

  return (
    <Container>
      <FormContainer initialValues={initialValues} schema={generalSchema}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Header title="General" />
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" flexDirection={showTextOnTheSide ? `row` : 'column'} gap={3}>
              <FieldWrapper label="Product image">
                <Field
                  component={MediaField}
                  fullWidth
                  name="image"
                  data-cy="field-product-image"
                  legacyImage={false}
                  styles={{
                    width: 180,
                    height: 180,
                    maxWidth: '100%',
                  }}
                />
              </FieldWrapper>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <FieldWrapper label="Product title">
                    <Field
                      name="title"
                      fullWidth
                      component={TextFieldLimited}
                      variant="outlined"
                      charLimit={50}
                      inputProps={{
                        'data-cy': 'field-product-title',
                      }}
                    />
                  </FieldWrapper>
                </Grid>
                <Grid item xs={12}>
                  <FieldWrapper label="Link to product in online shop">
                    <Field
                      name="webshopUrl"
                      fullWidth
                      component={TextField}
                      placeholder="https://webshop.com/product-link"
                      variant="outlined"
                      inputProps={{
                        'data-cy': 'field-product-webshop-link',
                      }}
                    />
                  </FieldWrapper>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </FormContainer>
    </Container>
  );
};

export default General;
