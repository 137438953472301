import React from 'react';
import { PropTypes } from 'prop-types';
import { styled } from '@mui/styles';
import { Box, Typography } from '@mui/material';
import { useDialog } from 'components/hooks';
import { Plus } from '@styled-icons/bootstrap/Plus';
import ThemeButton from 'designSystem/Buttons/ThemeButton/ThemeButton';

const TitleTypography = styled(Typography)({
  fontSize: '14px',
});

const NewCatalogItemBlock = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
  background: '#fff',
  borderRadius: theme.spacing(1),
  marginTop: theme.spacing(2),
}));

const RequestCatalogItemBlock = ({ variant }) => {
  const { openDialog, closeDialog } = useDialog();
  const title =
    variant === 'CLAIM'
      ? ' Not finding the claim you are looking for?'
      : 'Not finding the certification you are looking for?';

  return (
    <NewCatalogItemBlock>
      <TitleTypography variant="h6">{title}</TitleTypography>
      <Box mt={2}>
        <ThemeButton
          color="BLUE_ICE"
          startIcon={<Plus size={20} />}
          onClick={() => {
            if (variant === 'CLAIM') {
              openDialog({ type: 'REQUEST_IMPACT_CLAIM' });
              closeDialog('CREATE_IMPACT_CLAIM');
            } else {
              openDialog({ type: 'REQUEST_CERTIFICATION' });
              closeDialog('CREATE_CERTIFICATION');
            }
          }}
          size="medium"
          data-cy="request-new-btn"
        >
          Request to add new
        </ThemeButton>
      </Box>
    </NewCatalogItemBlock>
  );
};

RequestCatalogItemBlock.propTypes = {
  variant: PropTypes.oneOf(['CLAIM', 'CERTIFICATION']),
};

export default RequestCatalogItemBlock;
