import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { Feature, Polygon } from '@turf/turf';
import React, { FC, useEffect } from 'react';
import { ControlPosition, useControl } from 'react-map-gl';

type DrawControlProps = ConstructorParameters<typeof MapboxDraw>[0] & {
  position?: ControlPosition;
  onCreate?: (event: Feature<Polygon>[]) => void;
  onUpdate?: (event: Feature<Polygon>[]) => void;
  onDelete?: (event: Feature<Polygon>[]) => void;
  onDrawControlInit?: (ref: MapboxDraw) => void;
};

const DrawControl: FC<DrawControlProps> = ({ onDrawControlInit, ...props }) => {
  const drawControl = useControl<MapboxDraw>(
    () => new MapboxDraw(props),
    ({ map }) => {
      map.on('draw.create', ({ features }) => props.onCreate?.(features));
      map.on('draw.update', ({ features }) => props.onUpdate?.(features));
      map.on('draw.delete', ({ features }) => props.onDelete?.(features));
    },
    ({ map }) => {
      map.on('draw.create', ({ features }) => props.onCreate?.(features));
      map.on('draw.update', ({ features }) => props.onUpdate?.(features));
      map.on('draw.delete', ({ features }) => props.onDelete?.(features));
    },
    {
      position: props.position,
    }
  );

  useEffect(() => {
    onDrawControlInit?.(drawControl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawControl]);

  return <></>;
};

export default DrawControl;
