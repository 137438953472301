import { useDroppable } from '@dnd-kit/core';
import { styled } from '@mui/material';
import { ThemeTypography } from 'designSystem';
import React, { FC } from 'react';
import { Booleanish, booleanish } from 'types/booleanish.types';
import AbsoluteElement from './AbsoluteElement';
import { IGraphSubChain } from './SubChain';

interface IDropZoneProps extends Pick<IGraphSubChain, 'id' | 'x' | 'y' | 'height' | 'width'> {
  isCancel?: boolean;
}

const DashedDropZone = styled(AbsoluteElement)<{ highlight: booleanish }>(
  ({ theme, highlight }) => ({
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: 0.6,
    border: `2px dashed ${theme.custom.themeColors.grayScale[40]}`,
    borderColor:
      highlight === 'true' ? theme.palette.primary.main : theme.custom.themeColors.grayScale[40],
  })
);

const CancelDropZone = styled(AbsoluteElement)<{ highlight: booleanish }>(
  ({ theme, highlight }) => ({
    display: 'flex',
    alignItems: 'end',
    padding: theme.spacing(2),
    opacity: highlight === 'true' ? 0.3 : 0.4,
    background:
      highlight === 'true'
        ? theme.custom.themeColors.accent[60]
        : theme.custom.themeColors.grayScale[30],
  })
);

const DropZone: FC<IDropZoneProps> = ({ id, width, height, y, x, isCancel }) => {
  const { isOver, setNodeRef } = useDroppable({
    id,
  });

  if (isCancel) {
    return (
      <CancelDropZone
        highlight={Booleanish(isOver)}
        ref={setNodeRef}
        x={x}
        y={y}
        width={width}
        height={height}
      >
        <ThemeTypography variant="BODY_MEDIUM_BOLD">
          Drop here to cancel re-ordering
        </ThemeTypography>
      </CancelDropZone>
    );
  }

  return (
    <DashedDropZone
      highlight={Booleanish(isOver)}
      ref={setNodeRef}
      x={x}
      y={y}
      width={width}
      height={height}
    />
  );
};

export default DropZone;
