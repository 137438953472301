import update from 'immutability-helper';

export const stripValuesToSchema = (values, schema) => {
  if (!schema) return values;

  let strippedValues;

  try {
    strippedValues = schema.validateSync(values, {
      abortEarly: false,
      stripUnknown: true,
    });
  } catch (e) {
    strippedValues = e.value;
  }

  return strippedValues;
};

export const move = (array, from, to) => {
  const item = array[from];

  return update(array, {
    $splice: [
      [from, 1],
      [to, 0, item],
    ],
  });
};

export const isActiveMenuEnabled = (activeMenuId, contentBlocks) => {
  const matchingContentBlock = contentBlocks.find(
    contentBlock => contentBlock['contentType'] === activeMenuId
  );
  const enabled = matchingContentBlock?.enabled;

  return activeMenuId ? enabled : undefined;
};
