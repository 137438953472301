import React from 'react';
import { LanguageSelectorItem } from 'components/Forms';
import PropTypes from 'prop-types';
import { useConfig } from 'components/hooks';
import { StyledInput, StyledSelect } from 'components/Forms/Input';

const LanguageSingleSelect = ({ selectedLanguage, setSelectedLanguage, languages, ...props }) => {
  const config = useConfig();
  return (
    <StyledSelect
      value={selectedLanguage}
      onChange={event => setSelectedLanguage(event.target.value)}
      variant="outlined"
      defaultValue="none"
      data-cy="default-language-select"
      fullWidth
      input={<StyledInput />}
      {...props}
      renderValue={selectedItem => {
        const { flag, title } = config.getLanguageConfig(selectedItem);
        return <LanguageSelectorItem value={selectedItem} flag={flag} title={title} noPadding />;
      }}
    >
      {languages.map(({ title, key, flag }) => {
        return (
          <LanguageSelectorItem value={key} key={`product-lang-${key}`} flag={flag} title={title} />
        );
      })}
    </StyledSelect>
  );
};

LanguageSingleSelect.propTypes = {
  selectedLanguage: PropTypes.string.isRequired,
  setSelectedLanguage: PropTypes.func.isRequired,
  languages: PropTypes.array.isRequired,
};

export default LanguageSingleSelect;
