import { Box, DialogContent, DialogTitle, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { Fragment } from 'react';
import ApiKey from 'assets/img/publishDialog/api_key.png';
import IntegrationExample from 'assets/img/publishDialog/integration_example.png';
import { ArrowRight } from '@styled-icons/bootstrap/ArrowRight';
import { HeadlinePublishDialog, SuccessTitle } from './styles';
import { ThemeTypography } from 'designSystem';

const SuccessDialogTitle = styled(DialogTitle)(() => ({
  padding: 0,
}));

const ApiKeyImage = styled('img')(() => ({
  marginTop: 44,
  width: '85%',
}));

const IntegrationImage = styled('img')(() => ({
  width: '90%',
  marginLeft: '15px',
}));

const Arrow = styled(ArrowRight)(({ theme }) => ({
  marginLeft: '20px',
  color: theme.palette.primary.main,
}));

const PublishDialogSuccess: React.FC = () => {
  return (
    <Fragment>
      <SuccessDialogTitle>
        <SuccessTitle>
          <HeadlinePublishDialog>Your product is live!</HeadlinePublishDialog>
        </SuccessTitle>
      </SuccessDialogTitle>
      <DialogContent>
        <Box display="flex" mt={2}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Grid container spacing={2}>
                <Grid item xs={10}>
                  <Box mb={3}>
                    <ThemeTypography variant="TITLE_SMALL">
                      Next step is to set up your webshop integration
                    </ThemeTypography>
                  </Box>
                  <ThemeTypography variant="BODY_LARGE">
                    Follow our guide to activate the seedtrace transparency plugin to your online
                    shop.
                  </ThemeTypography>
                </Grid>
              </Grid>
              <Box display="flex" alignItems="center" justifyContent="center">
                <ApiKeyImage src={ApiKey} />
                <Arrow size={40} />
              </Box>
            </Grid>
            <Grid></Grid>
            <Grid item xs={6}>
              <IntegrationImage src={IntegrationExample} />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    </Fragment>
  );
};

export default PublishDialogSuccess;
