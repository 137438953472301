import { ILocationVisibility } from 'types/supplyChain.types';
import * as Yup from 'yup';
import { activitySchema } from './activities.schema';

export const VisibilitySettingsSchema = Yup.object().shape({
  locationVisibility: Yup.mixed()
    .oneOf(Object.keys(ILocationVisibility))
    .default('EXACT')
    .required(),
});

const IncomingLinkSchema = Yup.object().shape({ chainStepActivitySourceId: Yup.string() });

const OutputComponentsSchema = Yup.object().shape({
  partner: Yup.object().shape({ title: Yup.string() }).nullable(),
});

export const ChainActivitySchema = Yup.object().shape({
  id: Yup.string(),
  incomingLinks: Yup.array().of(IncomingLinkSchema),
  activity: activitySchema,
});

export const SubChainSchema = Yup.object().shape({
  id: Yup.string(),
  title: Yup.string(),
  outputComponents: Yup.array().of(OutputComponentsSchema),
});

export const ChainStepSchema = Yup.object().shape({
  id: Yup.string(),
  title: Yup.string().required(),
});

export const SupplyChainSchema = Yup.object().shape({
  id: Yup.string().required(),
  title: Yup.string().required(),
  subChains: Yup.array().of(SubChainSchema).default([]),
  chainStepActivities: Yup.array()
    .of(ChainActivitySchema)
    .min(1, 'Please add at least one activity'),
  chainSteps: Yup.array().of(ChainStepSchema).min(1, 'Please add at least one step'),
});
