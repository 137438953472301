import { gql } from '@apollo/client';
import {
  IMPACT_CLAIM_FRAGMENT,
  // IMPACT_CLAIM_LOG_FRAGMENT,
} from 'graphql/fragments';

export const CREATE_IMPACT_CLAIM = gql`
  mutation CreateImpactClaim($input: ImpactClaimCreateInput!) {
    createImpactClaim(input: $input) {
      impactClaim {
        ...impactClaimValues
      }
    }
  }
  ${IMPACT_CLAIM_FRAGMENT}
`;

export const CREATE_IMPACT_CATALOG_ITEM = gql`
  mutation CreateImpactCatalogItemRequest($input: CreateImpactCatalogItemRequestInput!) {
    createImpactCatalogItemRequest(input: $input) {
      item {
        title
        description
      }
    }
  }
`;

export const UPDATE_IMPACT_CLAIM = gql`
  mutation UpdateImpactClaim($id: UUID!, $input: ImpactClaimUpdateInput!) {
    updateImpactClaim(id: $id, input: $input) {
      impactClaim {
        ...impactClaimValues
      }
    }
  }
  ${IMPACT_CLAIM_FRAGMENT}
`;

export const DELETE_IMPACT_CLAIMS = gql`
  mutation DeleteImpactClaims($ids: [UUID]!) {
    deleteImpactClaims(ids: $ids) {
      ids
    }
  }
`;
