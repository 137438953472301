import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import { LoadingCard } from 'components/Forms';
import { Box } from '@mui/material';
import { useTimeframe } from 'components/Product/hooks';
import { InfoTooltip } from 'designSystem';

const KeyMetrics = ({ loading, views, infoText, fullHeight, label }) => {
  const { getTimeFrame, selectedTimeFrame } = useTimeframe();
  const { unit } = getTimeFrame(selectedTimeFrame);

  return (
    <LoadingCard
      LabelAdornment={infoText ? <InfoTooltip text={infoText} /> : undefined}
      loading={loading}
      label={label !== undefined ? label : 'Key metrics'}
      fullHeight={fullHeight}
    >
      <Box>
        {views.map((View, index) => {
          const Component = cloneElement(
            View,
            {
              key: index,
              comparedLabel: `vs. previous ${unit}`,
            },
            null
          );

          return Component;
        })}
      </Box>
    </LoadingCard>
  );
};

KeyMetrics.propTypes = {
  loading: PropTypes.bool,
  views: PropTypes.arrayOf(PropTypes.node).isRequired,
  infoText: PropTypes.string,
  fullHeight: PropTypes.bool,
  label: PropTypes.string,
};

export default KeyMetrics;
