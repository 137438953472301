import { PRODUCT_FRAGMENT } from 'graphql/fragments';

const productResolver = {
  Mutation: {
    setLastEditedLang: async (_, { id, lang }, { client, cache }) => {
      const productCacheKey = cache.identify({
        __typename: 'Product',
        id,
      });

      const product = client.readFragment({
        fragment: PRODUCT_FRAGMENT,
        fragmentName: 'productValues',
        id: productCacheKey,
      });

      client.writeFragment({
        fragment: PRODUCT_FRAGMENT,
        fragmentName: 'productValues',
        id,
        data: {
          ...product,
          lastEditedLang: lang,
        },
      });

      return {
        ...product,
        lastEditedLang: lang,
      };
    },
  },
};

export default productResolver;
