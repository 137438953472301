import { styled } from '@mui/material';
import ProductForm, {
  ProductFormType,
  ProductFormValues,
} from 'components/Product/Create/ProductForm';
import { DialogDefault } from 'designSystem';
import React, { FC, useMemo } from 'react';
import { IDefaultDialogProps } from 'types/dialog.types';
import { useCreateProduct } from './hooks';
import { StyledDialogContent, TopBackground } from './styles';
import { useProductActions } from 'components/hooks';

const StyledDialog = styled(DialogDefault)(({ theme }) => ({
  '& .MuiPaper-root:not(.MuiAlert-root)': {
    maxWidth: '750px',
  },
}));

export type ICreateDuplicateEditProductDialogProps = IDefaultDialogProps & ProductFormType;

const CreateDuplicateEditProductDialog: FC<ICreateDuplicateEditProductDialogProps> = ({
  open,
  product,
  action,
  onClose,
}) => {
  const { onDuplicate, onCreate } = useCreateProduct();
  const { handleUpdateProduct } = useProductActions();

  const dialogConfig = useMemo(() => {
    switch (action) {
      case 'CREATE':
        return {
          title: 'Add new product',
          submitButtonText: 'Continue',
          onSubmit: (values: ProductFormValues) => onCreate(values),
        };
      case 'DUPLICATE':
        return {
          title: `Duplicate ${product.title}`,
          submitButtonText: 'Duplicate',
          onSubmit: (values: ProductFormValues) => onDuplicate({ id: product.id, ...values }),
        };
      case 'EDIT':
        return {
          title: 'Edit product',
          submitButtonText: 'Save changes',
          onSubmit: (values: ProductFormValues) => handleUpdateProduct(product.id, values),
        };
      default:
        throw new Error(`Invalid action: ${action}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action]);

  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      title={dialogConfig.title}
      maxWidth="md"
      data-cy="create-product-dialog"
    >
      <TopBackground />
      <StyledDialogContent>
        {/* @ts-ignore Type can not be resolved correctly */}
        <ProductForm
          action={action}
          product={product}
          submitButtonText={dialogConfig.submitButtonText}
          onSubmit={dialogConfig.onSubmit}
        />
      </StyledDialogContent>
    </StyledDialog>
  );
};

export default CreateDuplicateEditProductDialog;
