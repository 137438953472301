import { Box, ClickAwayListener, IconButton, MenuItem, Select } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { Refresh } from '@styled-icons/evil/Refresh';
import { useTimeframe } from 'components/Product/hooks';
import { TimeFrame } from 'components/Product/hooks/useTimeframe';
import { useLogEvent } from 'components/hooks';
import { ThemeTypography } from 'designSystem';
import moment from 'moment';
import React, { FC, Fragment, useState } from 'react';
import { DateRange } from 'react-date-range';
import Moment from 'react-moment';

interface IDateRangeMultiSelectProps {
  productId: string;
  showRefreshButton?: boolean;
}

const RefreshButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  boxShadow: theme.shadows[1],
  padding: theme.spacing(0.5),
  marginLeft: theme.spacing(1),
}));

const RangeContainer = styled('div')(() => ({
  position: 'absolute',
  right: 0,
  top: '100%',
  boxShadow: '0 1px 3px 1px rgba(0, 0, 0, 0.2)',
  zIndex: 10,
  '& .rdrCalendarWrapper .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span':
    {
      color: 'black',
    },
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  boxShadow: theme.shadows[1],

  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
}));

const DateRangeMultiSelect: FC<IDateRangeMultiSelectProps> = ({
  productId,
  showRefreshButton = true,
}) => {
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const { palette } = useTheme();
  const { logEvent } = useLogEvent();

  const { selectedTimeFrame, handleTimeFrameChange, getTimeFrame, timeFramekeys, start, end } =
    useTimeframe();

  return (
    <Box display="flex" alignItems="center">
      <Box mr={2}>
        <ThemeTypography variant="BODY_LARGE" color="GRAY">
          <Moment format="MMMM D, YYYY">{start}</Moment>
          {start !== end && (
            <Fragment>
              <span>&nbsp;-&nbsp;</span>
              <Moment format="MMMM D, YYYY">{end || new Date()}</Moment>
            </Fragment>
          )}
        </ThemeTypography>
      </Box>
      <Box position="relative">
        {datePickerOpen && (
          <ClickAwayListener onClickAway={() => setDatePickerOpen(false)}>
            <RangeContainer>
              <DateRange
                rangeColors={[palette.primary.main, palette.primary.light]}
                editableDateInputs={false}
                onChange={({ selection: { startDate, endDate } }) => {
                  // here we are formatting date to receive startDate without offset slicing,
                  // toISOString(true) returns correct date/time with offset +00:00,
                  // .split('+')[0] takes only date without offset
                  // +'Z' needed to match ISOString format
                  const startDateMoment =
                    moment(startDate).startOf('day').toISOString(true).split('+')[0] + 'Z';
                  const endDateMoment = moment(endDate).endOf('day').utc().toISOString();

                  // on first selection
                  if (startDateMoment === endDateMoment && moment(end).isAfter(endDateMoment)) {
                    handleTimeFrameChange('custom', startDateMoment, end);
                    return;
                  }

                  handleTimeFrameChange('custom', startDateMoment, endDateMoment);
                }}
                moveRangeOnFirstSelection={false}
                maxDate={new Date()}
                ranges={[
                  {
                    startDate: new Date(start),
                    endDate: end ? new Date(end) : new Date(),
                    key: 'selection',
                  },
                ]}
              />
            </RangeContainer>
          </ClickAwayListener>
        )}
        <StyledSelect
          value={selectedTimeFrame}
          variant="outlined"
          onChange={event => {
            logEvent('CHANGE_TIMEFRAME', {
              productId,
              timeFrame: event.target.value,
            });
            if (event.target.value === 'custom') return;
            handleTimeFrameChange(event.target.value as TimeFrame);
          }}
        >
          {timeFramekeys.map((timeFrameKey, index) => {
            const { value, label } = getTimeFrame(timeFrameKey);

            return (
              <MenuItem
                key={index}
                value={value}
                onClick={() => {
                  if (value === 'custom') setDatePickerOpen(true);
                }}
              >
                <div>{label}</div>
              </MenuItem>
            );
          })}
        </StyledSelect>
      </Box>
      {showRefreshButton && (
        <RefreshButton
          disabled={selectedTimeFrame === 'custom'}
          onClick={() => handleTimeFrameChange(selectedTimeFrame)}
        >
          <Refresh size={30} />
        </RefreshButton>
      )}
    </Box>
  );
};

export default DateRangeMultiSelect;
