import { useQuery } from '@apollo/client';
import { Box, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { X as Close } from '@styled-icons/bootstrap/X';
import { ReactComponent as DismissDocument } from 'assets/img/icons/dismiss-document.svg';
import DocumentUpload from 'components/DocumentLibrary/DocumentUpload';
import { Loader } from 'components/Forms';
import { ReceivedRequestCard } from 'components/Home/Requests';
import usePartnerRequestActions from 'components/Partners/PartnerRequests/usePartnerRequestActions';
import { removeRequestFromCachePartnerHome } from 'components/Partners/utils/partnerUtils';
import { SlidesCloseButton, SlidesContainer, SlidesDialog } from 'components/SliderForm';
import { ErrorState } from 'components/Structure';
import { useMessages, useUploadState } from 'components/hooks';
import { DialogTitle, ThemeButton } from 'designSystem';
import Icon from 'designSystem/Primitives/Icon/Icon';
import { GET_PARTNER_REQUEST } from 'graphql/queries';
import { GetPartnerRequestData } from 'graphql/queries/partner.queries';
import React, { FC, Fragment, useEffect, useState } from 'react';
import { IDefaultDialogProps } from 'types/dialog.types';
import { PartnerRequest } from 'types/partner.types';
import { IDocument } from 'types/document.types';
import { useRequestReplyDialogContext } from '../Contexts/RequestReplyDialogContext';
import RequestTitle from './RequestTitle';
import { UploadProvider } from 'contexts/UploadContext';

interface IUploadDocumentToRequestDialogProps extends IDefaultDialogProps {
  requestId: string;
}

const Container = styled('div')(() => ({
  display: 'flex',
  height: '100%',
  width: '100%',
}));

const UploadColumn = styled('div')(({ theme }) => ({
  width: '50%',
  height: '100%',
  overflow: 'auto',
}));

const RequestColumn = styled('div')(({ theme }) => ({
  width: '50%',
  height: '100%',
  overflow: 'auto',
  padding: theme.spacing(2, 5, 3),
  background: theme.custom.colors.backgroundMedium,
}));

const UploadContainer = styled('div')(({ theme }) => ({
  margin: theme.spacing(6, 4),
  background: '#fff',
  padding: theme.spacing(2),
  borderRadius: 6,
  boxShadow: theme.custom.shadows[5],
}));

export const ButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  gridGap: theme.spacing(1),
  background: '#fff',
  marginTop: 'auto',
  padding: theme.spacing(3),
  position: 'absolute',
  bottom: 0,
  width: '50%',
  boxShadow: theme.shadows[28 as number],
  zIndex: 1,
}));

const DismissIcon = styled(DismissDocument)(() => ({
  width: 10,
}));

const UploadDocumentToRequestDialog: FC<IUploadDocumentToRequestDialogProps> = ({
  open,
  onClose,
  requestId,
}) => {
  const { updatePartnerRequest, loading } = usePartnerRequestActions();
  const { openDismissDialog } = useRequestReplyDialogContext();
  const { setErrorMessage, setSuccessMessage } = useMessages();
  const { data, loading: loadingRequest } = useQuery<GetPartnerRequestData>(GET_PARTNER_REQUEST, {
    variables: {
      id: requestId,
    },
  });
  const { cleanFiles, handleCleanCreatedRecords } = useUploadState();
  const [documents, setDocuments] = useState<IDocument[]>([]);
  const [deletedDocuments, setDeletedDocuments] = useState<string[]>([]);
  const handleDelete = (recordId: string) => {
    setDeletedDocuments([...deletedDocuments, recordId]);
  };
  const disableSubmitButton = documents.length === 0 || !documents[0]?.category;

  useEffect(() => {
    return () => {
      cleanFiles();
      handleCleanCreatedRecords();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUploadRequest = () => {
    updatePartnerRequest({
      variables: {
        id: requestId,
        input: {
          requestStatus: 'PROVIDED',
          documentId: documents[0].id,
        },
      },

      onError: () =>
        setErrorMessage('Something went wrong uploading the request. Please try again later.'),
      onCompleted: () => {
        setSuccessMessage('You have uploaded the request');
        onClose?.();
      },
      update: (cache, { data }) => {
        if (data) {
          removeRequestFromCachePartnerHome(cache, data.updatePartnerRequest.partnerRequest);
        }
      },
    });
  };

  return (
    <UploadProvider>
      <SlidesDialog
        fullWidth={true}
        maxWidth="lg"
        open={!!open}
        onClose={onClose}
        data-cy="upload-request-dialog"
      >
        <SlidesCloseButton onClick={onClose} data-cy="close-dialog-button">
          <Close size={20} />
        </SlidesCloseButton>
        <SlidesContainer data-cy="upload-dialog">
          {loadingRequest ? (
            <Loader />
          ) : (
            <Container>
              {!data ? (
                <ErrorState error="Cannot load the request" />
              ) : (
                <Fragment>
                  <RequestColumn>
                    <Box mb={4} ml={-3}>
                      <DialogTitle icon={<Icon name="upload" size="large" />}>
                        Upload document
                      </DialogTitle>
                    </Box>
                    <Box mb={3}>
                      <RequestTitle request={data?.partnerRequest as PartnerRequest} />
                    </Box>
                    <Box mb={3}>
                      <ReceivedRequestCard
                        request={data?.partnerRequest as PartnerRequest}
                        displayActions={false}
                      />
                    </Box>
                  </RequestColumn>
                  <UploadColumn>
                    <UploadContainer>
                      <DocumentUpload
                        title="Add documents"
                        handleDocumentsChange={setDocuments}
                        documents={documents}
                        deletedDocuments={deletedDocuments}
                        handleDelete={handleDelete}
                        displaySelector
                        data-cy="request-document-upload"
                        multipleDocuments={false}
                        isRequestDocument
                      />
                    </UploadContainer>
                    <ButtonContainer>
                      <ThemeButton
                        color="BLUE_ICE"
                        startIcon={<DismissIcon />}
                        onClick={() => openDismissDialog(requestId)}
                      >
                        Dismiss request
                      </ThemeButton>
                      <Box display="flex" gap={1}>
                        <ThemeButton color="BLUE_ICE" size="medium" onClick={onClose}>
                          Cancel
                        </ThemeButton>
                        <Tooltip title="Make sure that all required fields of the document are set">
                          <div>
                            <ThemeButton
                              data-cy="submit-request-form-btn"
                              color="YELLOW"
                              type="submit"
                              size="medium"
                              onClick={handleUploadRequest}
                              loading={loading}
                              disabled={disableSubmitButton}
                            >
                              Save & Share
                            </ThemeButton>
                          </div>
                        </Tooltip>
                      </Box>
                    </ButtonContainer>
                  </UploadColumn>
                </Fragment>
              )}
            </Container>
          )}
        </SlidesContainer>
      </SlidesDialog>
    </UploadProvider>
  );
};

export default UploadDocumentToRequestDialog;
