import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { Container } from './styles';
import { Box, Grid, useMediaQuery } from '@mui/material';
import { MediaField, TextFieldLimited } from 'components/Forms';
import FormContainer from './FormContainer';
import { FieldWrapper } from 'designSystem';
import { useContentBlock } from 'components/Product/MultiStep/hooks';
import { useTheme } from '@mui/material/styles';
import Header from './Header';
import HTMLEditorField from 'designSystem/Formik/HTMLEditorField/HTMLEditorField';

const MoreInfo = ({ menu, previewIsActive }) => {
  const theme = useTheme();
  const [{ data, enabled, id }, schema] = useContentBlock(menu.id);
  const largeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  const showTextOnTheSide = !previewIsActive && largeScreen;

  return (
    <Container>
      <FormContainer
        initialValues={{
          enabled,
          ...data,
        }}
        schema={schema}
      >
        {({ setValues }) => (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Header
                title={menu.title}
                helperText={menu.description}
                showEnabled
                contentBlockId={id}
                setContent={setValues}
                contentType={menu.id}
              />
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" flexDirection={showTextOnTheSide ? `row` : 'column'} gap={3}>
                <FieldWrapper label="Image or Video">
                  <Field
                    component={MediaField}
                    fullWidth
                    legacyImage={false}
                    name="mediaItem"
                    data-cy="field-more-info-image"
                    allowedMedia={['image', 'video']}
                    styles={{
                      width: 280,
                      height: 280,

                      [theme.breakpoints.down('md')]: {
                        width: 240,
                        height: 240,
                      },
                    }}
                  />
                </FieldWrapper>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <FieldWrapper label="Block title">
                      <Field
                        component={TextFieldLimited}
                        fullWidth
                        placeholder="e.g. Product details"
                        name="blockTitle"
                        variant="outlined"
                        charLimit={20}
                      />
                    </FieldWrapper>
                  </Grid>
                  <Grid item xs={12}>
                    <FieldWrapper label="Headline">
                      <Field
                        component={TextFieldLimited}
                        fullWidth
                        name="headline"
                        variant="outlined"
                        placeholder="Transparency tastes delicious"
                        charLimit={50}
                        inputProps={{
                          'data-cy': 'field-more-info-headline',
                        }}
                      />
                    </FieldWrapper>
                  </Grid>
                  <Grid item xs={12}>
                    <FieldWrapper label="Description">
                      <Field
                        component={HTMLEditorField}
                        placeholder="Write your message..."
                        name="description"
                        charLimit={650}
                      />
                    </FieldWrapper>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        )}
      </FormContainer>
    </Container>
  );
};

MoreInfo.propTypes = {
  menu: PropTypes.object.isRequired,
  previewIsActive: PropTypes.bool,
};

export default MoreInfo;
