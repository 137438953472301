import React, { FC } from 'react';
import { SeparatorLine } from 'components/Structure';
import { Box, DialogContent, styled, useTheme } from '@mui/material';
import ThemeTypography from 'designSystem/Primitives/Typography/ThemeTypography';
import ThemeButton from 'designSystem/Buttons/ThemeButton/ThemeButton';
import { useDialog } from 'components/hooks';
import { DialogDefault } from 'designSystem';
import { IDefaultDialogProps } from 'types/dialog.types';
import Icon from 'designSystem/Primitives/Icon/Icon';
import { IBasePartnerCompany } from 'types/partner.types';
import { IClusterSite } from 'types/site.types';

interface IAddSiteToClusterDialogProps extends IDefaultDialogProps {
  siteCluster: IClusterSite;
  partner?: IBasePartnerCompany;
}

const ContentWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
}));

const AddSiteToClusterDialog: FC<IAddSiteToClusterDialogProps> = ({
  onClose,
  siteCluster,
  partner,
}) => {
  const theme = useTheme();
  const { openDialog, closeDialog } = useDialog();

  const handleCreateSite = () => {
    openDialog({ type: 'ADD_EDIT_SITE', props: { siteClusterId: siteCluster.id, partner } });
    closeDialog('ADD_SITE_TO_CLUSTER');
  };

  const handleSelectSites = () => {
    openDialog({ type: 'SELECT_SITES', props: { siteCluster, partner } });
  };

  return (
    <DialogDefault
      title="Manage cluster sites"
      iconName="site-cluster-manage"
      onClose={onClose}
      open
      fullWidth
      maxWidth="xs"
      background="#fff"
      preventCloseOnBackground
      data-cy="site-to-cluster-dialog"
    >
      <DialogContent>
        <ContentWrapper>
          <ThemeButton
            color="YELLOW"
            fullWidth
            startIcon={<Icon name="plus" />}
            size="large"
            onClick={handleCreateSite}
          >
            Add new site
          </ThemeButton>

          <Box mt={1}>
            <ThemeTypography variant="BODY_SMALL" color="GRAY" textAlign="center">
              Add a new site to this cluster
            </ThemeTypography>
          </Box>

          <SeparatorLine label="or" width="80%" backgroundColor={theme.custom.colors.offWhite} />

          <ThemeButton
            color="BLUE_ICE"
            fullWidth
            size="large"
            data-cy="select-existing-sites-btn"
            startIcon={<Icon name="site-cluster-manage" />}
            onClick={handleSelectSites}
          >
            Manage sites in cluster
          </ThemeButton>

          <Box mt={1} textAlign="center">
            <ThemeTypography variant="BODY_SMALL" color="GRAY" mb={2}>
              Add or remove existing sites.
            </ThemeTypography>
          </Box>
        </ContentWrapper>
      </DialogContent>
    </DialogDefault>
  );
};

export default AddSiteToClusterDialog;
