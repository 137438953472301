import { Box } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { ThemeTypography } from 'designSystem';
import React from 'react';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LabelColor = styled(({ backgroundColor, ...props }) => <div {...props} />)(
  ({ backgroundColor, theme }) => ({
    width: 10,
    height: 10,
    backgroundColor,
    borderRadius: '50%',
    marginRight: theme.spacing(1),
  })
);

const RecentActivityLegend = ({ ...rest }) => {
  const theme = useTheme();
  return (
    <Box display="flex" alignItems="center" justifyContent="flex-start" {...rest}>
      <Box mx={1} display="flex" alignItems="center" justifyContent="center">
        <LabelColor backgroundColor={theme.palette.secondary.main} />
        <ThemeTypography variant="BODY_LARGE">Total visitors</ThemeTypography>
      </Box>
      <Box mx={1} display="flex" alignItems="center" justifyContent="center">
        <LabelColor backgroundColor={theme.palette.primary.main} />
        <ThemeTypography variant="BODY_LARGE">New visitors</ThemeTypography>
      </Box>
    </Box>
  );
};

export default RecentActivityLegend;
