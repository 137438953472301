import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React from 'react';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const BorderRadius = styled(({ borderRadius, ...props }) => <div {...props} />)(
  ({ borderRadius, theme }) => ({
    position: 'relative',
    width: 50,
    height: 50,
    top: 10,
    left: 10,
    borderTop: `3px solid ${theme.palette.secondary.main}`,
    borderLeft: `3px solid ${theme.palette.secondary.main}`,
    borderRadius,
  })
);

const Container = styled('div')(({ theme }) => ({
  position: 'relative',
  width: 40,
  height: 40,
  border: `1px solid ${theme.palette.grey[400]}`,
  borderRadius: theme.spacing(0.5),
  overflow: 'hidden',
  backgroundColor: 'white',

  '&:before': {
    position: 'absolute',
    bottom: 0,
    left: 0,
    border: '4px solid white',
    content: '""',
    top: 0,
    right: 0,
    zIndex: 1,
  },
}));

const BorderRadiusDisplay = ({ borderRadius }) => {
  return (
    <Container>
      <BorderRadius borderRadius={borderRadius} />
    </Container>
  );
};

BorderRadiusDisplay.propTypes = {
  borderRadius: PropTypes.number.isRequired,
};

export default BorderRadiusDisplay;
