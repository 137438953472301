import { Box } from '@mui/material';
import EudrComplianceGuideSubSection from 'components/ComplianceGuide/EudrComplianceGuideSubSection';
import { useEudrComplianceGuide } from 'components/ComplianceGuide/EudrComplianceGuideContext';
import { PageSubTitle } from 'components/Structure';
import { ThemeButton } from 'designSystem';
import React, { FC, useState } from 'react';
import { EudrAffectednessType, EudrResponsibilityType } from 'types/compliance.types';
import QnAGuideTemplate from '../../QnAGuideTemplate';
import AnnexTable from '../AnnexTable';

const AffectedProducer: FC = () => {
  const {
    formData,
    selectedSubSection,
    selectedStatementId,
    setFormData,
    setSelectedSubSection,
    updateStatement,
  } = useEudrComplianceGuide();

  const [selectedAnswer, setSelectedAnswer] = useState<string | undefined>();

  const handleNextStepClick = () => {
    setFormData({
      ...formData,
      previousSubSectionKey: selectedSubSection?.key,
      affectedProducer: selectedAnswer === 'yes',
    });
    // If answer is no, then the company is not affected, if answer is yes, then the company is fully affected as operator
    setSelectedSubSection(selectedAnswer === 'yes' ? 'fully-affected' : 'not-affected');

    if (selectedStatementId) {
      updateStatement(selectedStatementId, {
        companyAffectednessType:
          selectedAnswer === 'yes'
            ? EudrAffectednessType.NOT_AFFECTED
            : EudrAffectednessType.INDIRECTLY_AFFECTED,
        companyResponsibilityType:
          selectedAnswer === 'yes' ? EudrResponsibilityType.OPERATOR : undefined,
      });
    }
  };

  return (
    <EudrComplianceGuideSubSection
      allowNextStepNavigation={!!selectedAnswer}
      titleAdornment={
        <ThemeButton color="BLUE_ICE" disabled>
          Skip & apply previous affectedness
        </ThemeButton>
      }
      onNextStepClick={handleNextStepClick}
    >
      <QnAGuideTemplate
        selectedAnswer={selectedAnswer}
        question="Is your company cultivating any of the products listed in Annex I within the EU?"
        answers={[
          { id: 'yes', label: 'Yes' },
          {
            id: 'no',
            label: 'No',
          },
        ]}
        onAnswerChange={setSelectedAnswer}
      />

      <Box mt={2} />
      <PageSubTitle title="Annex I" />
      <Box mt={1} />
      <AnnexTable />
    </EudrComplianceGuideSubSection>
  );
};

export default AffectedProducer;
