import { FormControl, MenuItem, Select, SelectChangeEvent, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Plus } from '@styled-icons/bootstrap/Plus';
import { useConfig, useDialog } from 'components/hooks';
import React, { FC, useEffect, useState } from 'react';
import { AvailableLanguages } from 'types/enums';
import { Product } from 'types/types';
import { StringParam, useQueryParam } from 'use-query-params';

interface ILanguageSelectProps {
  product: Product;
}

const StyledSelect = styled(Select<AvailableLanguages>)(({ theme }) => ({
  boxShadow: theme.custom.shadows[1],
  minWidth: 180,
  borderRadius: 3,
  padding: theme.spacing(0.5),

  '& .MuiSelect-select': {
    padding: '0 !important',
    display: 'flex',
    alignItems: 'center',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
}));

const Flag = styled('img')(() => ({
  width: 18,
  borderRadius: 2,
}));

const Label = styled('span')(({ theme }) => ({
  fontSize: 12,
  marginLeft: theme.spacing(0.5),
  color: theme.custom.themeColors.grayScale[80],
}));

const LanguageSelect: FC<ILanguageSelectProps> = ({ product }) => {
  const config = useConfig();
  const { productLanguages } = config;
  const [selectedLang = product.languageConfig?.default, setSelectedLang] = useQueryParam(
    config.appQueryParams.lang,
    StringParam
  );

  const [open, setOpen] = useState(false);
  const { openDialog } = useDialog();
  const openLanguageDialog = () => {
    openDialog({
      type: 'ADD_LANGUAGE',
      props: {
        product,
      },
    });
  };

  useEffect(() => {
    if (
      !selectedLang ||
      !product.languageConfig?.enabled.includes(selectedLang as AvailableLanguages)
    ) {
      setSelectedLang(product.languageConfig?.default);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product.languageConfig]);

  const SelectComponent = (
    <StyledSelect
      value={selectedLang as AvailableLanguages}
      onChange={(event: SelectChangeEvent<AvailableLanguages>) =>
        event.target.value ? setSelectedLang(event.target.value) : undefined
      }
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      label="Language"
      data-cy="header-language-select"
      inputProps={{
        name: 'language',
      }}
    >
      {product.languageConfig?.enabled.map(lang => {
        const { flag, title } = config.getLanguageConfig(lang);

        return (
          <MenuItem key={`product-lang-${lang}`} value={lang} data-cy={`option-lang-${lang}`}>
            <Flag src={flag} />
            <Label>{title}</Label>
          </MenuItem>
        );
      })}
      {productLanguages.length > product.languageConfig?.enabled.length && (
        <MenuItem onClick={openLanguageDialog} data-cy="option-add-new-language">
          <Plus size={14} />
          <Label>Add new language</Label>
        </MenuItem>
      )}
    </StyledSelect>
  );

  return (
    <FormControl className="className" data-tut="onboarding_tour__content-language">
      <Tooltip title={open ? '' : 'Current content language being edited'}>
        {SelectComponent}
      </Tooltip>
    </FormControl>
  );
};

export default LanguageSelect;
