import React, { FC } from 'react';
import { Box, styled } from '@mui/material';
import { ThemeTypography } from 'designSystem';
import Icon from 'designSystem/Primitives/Icon/Icon';
import { Booleanish, booleanish } from 'types/booleanish.types';

interface IClickablePartnerNameProps {
  partnerName: string;
  hasIconBackground?: boolean;
  onClick: () => void;
}
const Container = styled(Box)(() => ({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  component: 'span',
}));

const IconContainer = styled(Box)<{ 'has-background': booleanish }>(
  ({ 'has-background': hasBackground }) => ({
    ...(hasBackground === 'true' && {
      backgroundColor: 'white',
      borderRadius: 4,
    }),
  })
);

const ClickablePartnerName: FC<IClickablePartnerNameProps> = ({
  partnerName,
  hasIconBackground,
  onClick,
}) => {
  return (
    <Container display="flex" alignItems="center" component="span" onClick={onClick}>
      <IconContainer
        mr={hasIconBackground ? 0.8 : 0.5}
        has-background={Booleanish(hasIconBackground)}
      >
        <Icon size="x-large" name="partner" color="blue-ice" />
      </IconContainer>
      <ThemeTypography color="BLACK" variant="BUTTON_SMALL">
        {partnerName}
      </ThemeTypography>
    </Container>
  );
};

export default ClickablePartnerName;
