import { useRef } from 'react';
import useMeasure from 'react-use/lib/useMeasure';
import useScroll from 'react-use/lib/useScroll';

const useHorizontalScrollIndicator = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [measureRef, { width, height }] = useMeasure();
  const { x } = useScroll(containerRef);

  const refCallback = (ref: HTMLDivElement) => {
    // @ts-ignore
    containerRef.current = ref;
    measureRef(ref);
  };

  const scrollLeft = x > 0;
  const scrollRight = containerRef.current
    ? containerRef.current?.scrollWidth - x > Math.ceil(width)
    : false;

  const scrollToLeft = () => {
    containerRef.current?.scrollBy({
      left: -200,
      behavior: 'smooth',
    });
  };

  const scrollToRight = () => {
    containerRef.current?.scrollBy({
      left: 200,
      behavior: 'smooth',
    });
  };

  return {
    ref: refCallback,
    width,
    height,
    scrollLeft,
    scrollRight,
    scrollToLeft,
    scrollToRight,
  };
};

export default useHorizontalScrollIndicator;
