import { Box, BoxProps, styled } from '@mui/material';
import { ChevronLeft, ChevronRight } from '@styled-icons/bootstrap';
import useIsOverflow from 'components/hooks/useIsOverflow';
import React, {
  FC,
  Fragment,
  PropsWithChildren,
  UIEvent,
  useCallback,
  useRef,
  useState,
} from 'react';

type IHorizontalScrollContainerProps = PropsWithChildren & BoxProps;

const Container = styled(Box)(() => ({
  position: 'relative',
  overflow: 'hidden',
}));

const ChildrenContainer = styled('div')(({ theme }) => ({
  overflowX: 'auto',
  overflowY: 'hidden',
  display: 'flex',
  gridGap: theme.spacing(2),
  padding: theme.spacing(1.5, 0),
  transition: 'all .2s ease',

  '-ms-overflow-style': 'none' /* IE and Edge */,
  scrollbarWidth: 'none' /* Firefox */,

  '&::-webkit-scrollbar': {
    display: 'none',
  },
}));

const Fade = styled('div')(() => ({
  position: 'absolute',
  top: 0,
  display: 'block !important',
  width: 100,
  height: '100%',
  zIndex: 1,
  pointerEvents: 'none',
}));

const FadeOut = styled(Fade)(() => ({
  right: 0,
  background: 'linear-gradient(to left, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%)',
}));

const FadeIn = styled(Fade)(() => ({
  left: 0,
  background: 'linear-gradient(to right, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%)',
}));

const ArrowButton = styled('button')<{ backButton?: boolean }>(({ theme, backButton }) => ({
  position: 'absolute',
  border: 0,
  background: '#fff',
  borderRadius: '50%',
  boxShadow: '0px 2px 16px rgba(0, 0, 0, 0.11)',
  height: 35,
  width: 35,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 99,
  cursor: 'pointer',
  // Height of the button is 35px
  top: 'calc(50% - 17.5px)',
  left: backButton ? theme.spacing(2) : 'initial',
  right: backButton ? 'initial' : theme.spacing(2),

  '& svg': {
    color: theme.custom.themeColors.accent[100],
  },
}));

const HorizontalScrollContainer: FC<IHorizontalScrollContainerProps> = ({ children, ...props }) => {
  const [currentScrollPosition, setCurrentScrollPosition] = useState<number>(0);

  const containerRef = useRef<HTMLDivElement | null>(null);
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const isOverflow = useIsOverflow(scrollRef);
  const maxScroll: number =
    (scrollRef.current?.scrollWidth || 0) - (containerRef.current?.clientWidth || 0) - 10;

  const scroll = (scrollOffset: number) => {
    if (scrollRef.current) {
      const newScroll = scrollRef.current.scrollLeft + scrollOffset;
      scrollRef.current.scrollTo({ left: newScroll, behavior: 'smooth' });
    }
  };

  const onScroll = useCallback(
    (event: UIEvent<HTMLDivElement>) => {
      setCurrentScrollPosition(event.currentTarget.scrollLeft);
    },
    [setCurrentScrollPosition]
  );

  return (
    <Container {...props} ref={containerRef}>
      {isOverflow && (
        <>
          {!maxScroll ||
            (currentScrollPosition < maxScroll && (
              <>
                <ArrowButton onClick={() => scroll(300)}>
                  <ChevronRight size={20} />
                </ArrowButton>
                <FadeOut />
              </>
            ))}
          {currentScrollPosition > 0 && (
            <Fragment>
              <ArrowButton backButton onClick={() => scroll(-300)}>
                <ChevronLeft size={20} />
              </ArrowButton>
              <FadeIn />
            </Fragment>
          )}
        </>
      )}
      <ChildrenContainer ref={scrollRef} onScroll={onScroll}>
        {children}
      </ChildrenContainer>
    </Container>
  );
};

export default HorizontalScrollContainer;
