import { DialogTitle, DialogContent } from '@mui/material';
import { PageSubTitle } from 'components/Structure';
import { StyledDialog } from 'designSystem/Overlays/Dialog/DialogDefault/DialogDefault';
import React, { FC } from 'react';
import { IDefaultDialogProps } from 'types/dialog.types';
import TableSettingsForm from './TableSettingsForm';
import { ITableSettings } from 'types/dataImport.types';

interface ITableSettingsDialogProps extends IDefaultDialogProps {
  settings?: ITableSettings;
  onChangeSettings: (settings: ITableSettings) => void;
}

const TableSettingsDialog: FC<ITableSettingsDialogProps> = ({
  open,
  onClose,
  settings,
  onChangeSettings,
}) => {
  return (
    <StyledDialog open={!!open} onClose={onClose}>
      <DialogTitle>
        <PageSubTitle title="Table Settings" />
      </DialogTitle>
      <DialogContent>
        <TableSettingsForm onSubmit={onChangeSettings} onCancel={onClose} settings={settings} />
      </DialogContent>
    </StyledDialog>
  );
};

export default TableSettingsDialog;
