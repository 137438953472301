import HeadContext from 'contexts/HeadContext';
import { useContext, useEffect } from 'react';

const usePageTitle = (title: string) => {
  const { setTitle } = useContext(HeadContext);

  useEffect(() => {
    setTitle(title);
  }, [title, setTitle]);
};

export default usePageTitle;
