import { useMutation } from '@apollo/client';
import { Box, Button, SvgIcon } from '@mui/material';
import { styled } from '@mui/material/styles';
import { BoxArrowRight } from '@styled-icons/bootstrap/BoxArrowRight';
import { Clock } from '@styled-icons/bootstrap/Clock';
import { CollapseMenuIcon } from 'assets/img/menu';
import { useCompanyPlanContext } from 'contexts/CompanyPlanContext';
import { DrawerHead, DrawerMenu } from 'components/Drawer';
import { FadeMount } from 'components/Forms';
import { CustomTooltip } from 'components/Structure';
import { LOGOUT } from 'graphql/mutations';
import React, { FC, useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import useMeasure from 'react-use/lib/useMeasure';
import { Booleanish, booleanish } from 'types/booleanish.types';

export interface IDrawerContentProps {
  drawerExpanded: boolean;
  disableExpand: boolean;
  toggleDrawer: () => void;
}

const LogoutButton = styled(Button)(({ theme }) => ({
  background: theme.palette.secondary.light,
  color: '#fff',
  textTransform: 'none',
  padding: theme.spacing(1),
  fontWeight: 'normal',
  fontSize: '12px',
  width: '114px',
  margin: '0 auto',

  '&:hover': {
    backgroundColor: theme.custom.colors.secondaryLighter,
  },
}));

const DrawerBottom = styled('div')(() => ({
  display: 'flex',
  marginTop: 'auto',
  flexDirection: 'column',
}));

const DrawerContainer = styled('nav')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
  overflowX: 'hidden',
  overflowY: 'auto',

  '&::-webkit-scrollbar': {
    width: 8,
  },
  '&::-webkit-scrollbar-track': {
    background: theme.palette.secondary.main,
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.custom.colors.secondaryLighter,
    borderRadius: '20',
    border: `2px solid ${theme.palette.secondary.main}`,
  },
}));

const Action = styled('div')(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(3.4),
  fontWeight: 400,
  cursor: 'pointer',
  color: '#fff',
  textTransform: 'none',
  fontSize: '12px',
  whiteSpace: 'nowrap',
  margin: '0 auto',
  textAlign: 'center',
  alignItems: 'center',
}));

const TrialDaysContainer = styled(Action)(({ theme }) => ({
  color: theme.custom.colors.secondaryLighter,
}));

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const StyledSvgIcon = styled(SvgIcon)<{ rotated: booleanish }>(({ theme, rotated }) => ({
  width: 24,
  height: 14,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transform: `rotate(${rotated === 'true' ? '180' : '0'}deg)`,
  marginRight: rotated === 'true' ? theme.spacing(2) : 0,
  transition: 'all .4s ease',
}));

const DrawerContent: FC<IDrawerContentProps> = ({
  drawerExpanded,
  disableExpand,
  toggleDrawer,
}) => {
  const navigate = useNavigate();
  const contentRef = useRef<HTMLElement>(null);
  const [ref, { height }] = useMeasure();
  const { daysLeftInTrial, setOpen } = useCompanyPlanContext();

  const daysLeftMessage = `${daysLeftInTrial} ${daysLeftInTrial === 1 ? 'day' : 'days'} left`;

  const [logout] = useMutation(LOGOUT, {
    onCompleted: () => navigate('/login'),
  });

  const handleLogout = () => logout();
  const handleTrialDaysClick = () => setOpen(true);

  const hasOverflow = useMemo(() => {
    if (contentRef.current && height) {
      return contentRef.current.scrollHeight > contentRef.current.clientHeight;
    }
    return false;
  }, [height, contentRef]);

  /**
   * We have an issue with some spacings if the drawer is collapsed and a scroll bar is present.
   */
  const registerRef = (reference: HTMLDivElement) => {
    // @ts-ignore
    contentRef.current = reference;
    ref(reference);
  };

  return (
    <DrawerContainer ref={registerRef}>
      <DrawerHead drawerExpanded={drawerExpanded} />
      <DrawerMenu drawerExpanded={drawerExpanded} hasOverflow={hasOverflow} />

      <DrawerBottom>
        {daysLeftInTrial !== null && drawerExpanded && daysLeftInTrial <= 14 && (
          <FadeMount>
            <TrialDaysContainer onClick={handleTrialDaysClick}>
              <Clock size={20} />
              <Box pl={1}>{daysLeftMessage}</Box>
            </TrialDaysContainer>
          </FadeMount>
        )}

        <Box mt={2} display="flex" flexDirection="column" justifyContent="flex-start">
          {drawerExpanded && (
            <FadeMount>
              <Box display="flex" alignItems="center" justifyContent="center">
                <LogoutButton onClick={handleLogout} data-cy="action-logout">
                  <BoxArrowRight size={18} />
                  <Box pl={1.5} pr={1.5}>
                    Log out
                  </Box>
                </LogoutButton>
              </Box>
            </FadeMount>
          )}

          {!disableExpand && (
            <CustomTooltip placement="right" title={drawerExpanded ? '' : 'Expand menu'}>
              <Action onClick={() => toggleDrawer()} data-cy="action-toggle-menu">
                <StyledSvgIcon
                  viewBox="0 0 20 10"
                  rotated={Booleanish(!drawerExpanded)}
                  data-cy="action-toggle-menu-icon"
                >
                  <CollapseMenuIcon color="white" />
                </StyledSvgIcon>
                {drawerExpanded && <Box pl={1}>Collapse menu</Box>}
              </Action>
            </CustomTooltip>
          )}
        </Box>
      </DrawerBottom>
    </DrawerContainer>
  );
};

export default DrawerContent;
