import { styled } from '@mui/material/styles';
import React from 'react';
import BorderRadiusDisplay from './BorderRadiusDisplay';
import NumberInput from './NumberInput';

const Container = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}));

const StyledNumberInput = styled(NumberInput)(({ theme }) => ({
  width: 93,
  marginRight: theme.spacing(1),

  '& input': {
    textAlign: 'end',
  },
}));

const BorderRadiusSelector = ({ ...props }) => {
  const {
    field: { value },
  } = props;

  return (
    <Container>
      <StyledNumberInput unit="px" {...props} />
      <BorderRadiusDisplay borderRadius={value} />
    </Container>
  );
};

export default BorderRadiusSelector;
