import { CompanyFeatureType } from 'types/company.types';
import useUserData from './useUserData';

const useCompanyFeatureFlags = () => {
  const { company } = useUserData();

  const isCompanyFeatureEnabled = (feature: CompanyFeatureType) => {
    return !!company?.planDetails?.features.includes(feature);
  };

  return { isCompanyFeatureEnabled };
};

export default useCompanyFeatureFlags;
