import { gql } from '@apollo/client';
import { RAW_MATERIAL_FRAGMENT } from './component.fragments';
import { BASE_PARTNER_COMPANY_FRAGMENT, PARTNER_REQUEST_FRAGMENT } from './partner.fragments';
import { CULTIVATED_AREAS_FRAGMENT } from './site.fragments';

export const COMPLIANCE = gql`
  fragment compliance on Compliance {
    id
    type
    title
    description
    imageUrl
  }
`;

export const GEOC_BATCH = gql`
  fragment geocBatchValues on GeocBatch {
    id
    createdTimestamp
    modifiedTimestamp
    externalBatchId
    geocBatchId
    eudrDatasetId
    weight
    riskStatus
    harvestDate
  }
`;

export const EUDR_BASE_DATASET = gql`
  fragment eudrBaseDatasetValues on EUDRDataset {
    id
    createdTimestamp
    modifiedTimestamp
    title
    originCountry
    status
  }
`;

export const DATASET_TABLE_REPRESENTATION_CELL_FRAGMENT = gql`
  fragment datasetTableRepresentationCellValues on EUDRDatasetCell {
    value
    error {
      entityId
      entityType
      errorMessage
    }
  }
`;

export const DATASET_TABLE_REPRESENTATION_FRAGMENT = gql`
  fragment datasetTableRepresentationValues on EUDRDatasetTableRepresentation {
    columnsNames
    rows {
      name {
        ...datasetTableRepresentationCellValues
      }
      farmId {
        ...datasetTableRepresentationCellValues
      }
      coordinates {
        ...datasetTableRepresentationCellValues
      }
      size {
        ...datasetTableRepresentationCellValues
      }
    }
  }
  ${DATASET_TABLE_REPRESENTATION_CELL_FRAGMENT}
`;

export const EUDR_DATASET_FRAGMENT = gql`
  fragment eudrDatasetValues on EUDRDataset {
    ...eudrBaseDatasetValues
    geocBatches {
      ...geocBatchValues
    }
    ownedBy {
      ...basePartnerCompanyValues
    }
    rawMaterial {
      id
      title
    }
    documents {
      id
      title
    }
    cultivationAreas: cultivatedAreas {
      ...cultivatedAreaValues
    }
    tableRepresentation {
      ...datasetTableRepresentationValues
    }
  }
  ${EUDR_BASE_DATASET}
  ${CULTIVATED_AREAS_FRAGMENT}
  ${BASE_PARTNER_COMPANY_FRAGMENT}
  ${DATASET_TABLE_REPRESENTATION_FRAGMENT}
  ${GEOC_BATCH}
`;

export const EUDR_BASE_STATEMENT = gql`
  fragment eudrBaseStatementValues on EUDRStatement {
    id
    title
    createdTimestamp
    modifiedTimestamp
    year
    status
    companyResponsibilityType
    companyAffectednessType
    aggregatedQuestionnaires {
      aggregatedResponseSummary {
        answered
        notAnswered
        total
      }
      aggregatedRiskSummary {
        lowRiskPercentage
        mediumRiskPercentage
        highRiskPercentage
      }
      requests {
        ...partnerRequestValues
      }
    }
  }
  ${PARTNER_REQUEST_FRAGMENT}
`;

export const EUDR_STATEMENT = gql`
  fragment eudrStatementValues on EUDRStatement {
    ...eudrBaseStatementValues
    involvedPartnerCompanies {
      id
    }
    eudrDatasets {
      ...eudrDatasetValues
    }
  }
  ${EUDR_BASE_STATEMENT}
  ${EUDR_DATASET_FRAGMENT}
`;

export const GEOC_PARCEL = gql`
  fragment geocParcelValues on GeocParcel {
    id
    createdTimestamp
    geocParcelId
    geocBatchId
    harvestDate
    rawMaterialId
    cultivatedAreasId
    resultTimestamp
    deforestationRisk
    baselineDate
    landcoverPngBaselineDate
    forestCoverage
    plantationCoverage
    shrubsCoverage
    noTreesCoverage
    rgbPngBaselineDate
    rgbPngProductionEnddate
    forestChangePng
    deforestationIndex
    deforestationAreaHa
    degradationRisk
    degradationAreaHa
    degradationIndex
    countryRisk
    geocBatch {
      ...geocBatchValues
    }
    rawMaterial {
      ...rawMaterialValues
    }
    cultivatedAreas {
      ...cultivatedAreaValues
    }
  }
  ${GEOC_BATCH}
  ${RAW_MATERIAL_FRAGMENT}
  ${CULTIVATED_AREAS_FRAGMENT}
`;

export const EUDR_DATASET_MAPPING = gql`
  fragment eudrDatasetMappingValues on DatasetMapping {
    datasetId
    columnsData {
      columnName
      columnDataExample
    }
  }
`;

export const QUESTIONNAIRE_RESPONSE_OPTION_FRAGMENT = gql`
  fragment questionnaireResponseOptionValues on QuestionnaireResponsesOptions {
    id
    text
    weight
    riskLevel
    answerPercentage
  }
`;
