import React, { Fragment, useState } from 'react';
import {
  ButtonsContainer,
  Container,
  FrameContainer,
  DisabledMenuMessage,
  DisabledMessageIcon,
  LoadingIframe,
  StyledIFrame,
  TogglePreviewButton,
  PreviewToggleIcon,
} from './styles';
import { ExternalLink } from '@styled-icons/evaicons-solid/ExternalLink';
import { useProductActions } from 'components/hooks';
import { useProduct } from 'components/Product/hooks';
import { useConsumerPreview, useEditorState, useMenu } from 'components/Product/MultiStep/hooks';
import { Warning } from '@styled-icons/ionicons-outline/Warning';
import { CircularProgress, useMediaQuery, useTheme } from '@mui/material';
import { isActiveMenuEnabled } from '../MultiStep/utils';
import hidePreviewIcon from 'assets/img/icons/hide-preview.svg';
import showPreviewIcon from 'assets/img/icons/show-preview.svg';
import { useCompanyPlanContext } from 'contexts/CompanyPlanContext';
import ThemeButton from 'designSystem/Buttons/ThemeButton/ThemeButton';

const EditorPreview = () => {
  const {
    product: { id: productId, slug },
  } = useProduct();
  const {
    version: { contentBlocks },
  } = useMenu();
  const { plan } = useCompanyPlanContext();
  const isSlimPlan = plan === 'SLIM_PLAN';
  const { activeMenu, previewIsActive, setPreviewIsActive } = useMenu() || {};
  const activeMenuId = activeMenu?.id;

  const togglePreview = () => setPreviewIsActive(cur => !cur);

  const activeMenuEnabled = isActiveMenuEnabled(activeMenuId, contentBlocks);
  const { openPreview } = useProductActions();
  const [iframeIsLoaded, setIframeIsLoaded] = useState(false);
  const { lang, iframeRef } = useEditorState();
  const { iframeUrl } = useConsumerPreview({
    // Only pass the iframe when it's loaded
    iframeRef: iframeIsLoaded ? iframeRef : null,
    activeMenuId: activeMenuEnabled === true ? activeMenuId : 'content-blocks',
    lang,
  });

  const theme = useTheme();
  const mediumScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const smallScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Container>
      <ButtonsContainer data-tut="onboarding_tour__preview">
        {!mediumScreen && (
          <TogglePreviewButton color="inherit" onClick={togglePreview}>
            {previewIsActive ? (
              <Fragment>
                <PreviewToggleIcon src={hidePreviewIcon} alt="hide-preview" />
                Hide preview
              </Fragment>
            ) : (
              <Fragment>
                <PreviewToggleIcon src={showPreviewIcon} alt="hide-preview" />
                Show preview
              </Fragment>
            )}
          </TogglePreviewButton>
        )}
        {!isSlimPlan && (
          <ThemeButton
            color="BLUE_ICE"
            size="large"
            startIcon={<ExternalLink size={16} />}
            onClick={() =>
              openPreview({
                productId,
                slug,
                lang,
              })
            }
            data-cy="editor-preview-button"
          >
            Preview
            {smallScreen ? '' : ' in new tab'}
          </ThemeButton>
        )}
      </ButtonsContainer>
      {previewIsActive && (
        <FrameContainer>
          {!iframeIsLoaded && (
            <LoadingIframe>
              <CircularProgress size={16} thickness={8} color="inherit" />
              Loading preview
            </LoadingIframe>
          )}
          {activeMenuEnabled === false && activeMenuId && (
            <DisabledMenuMessage>
              <DisabledMessageIcon>
                <Warning size={20} />
              </DisabledMessageIcon>
              The selected content block is disabled
            </DisabledMenuMessage>
          )}
          <StyledIFrame
            ref={iframeRef}
            src={iframeUrl}
            title="product-preview"
            frameBorder={0}
            width="100%"
            data-cy="editor-preview-frame"
            onLoad={() => setIframeIsLoaded(true)}
          />
        </FrameContainer>
      )}
    </Container>
  );
};

EditorPreview.propTypes = {};

export default EditorPreview;
