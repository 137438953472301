import * as Yup from 'yup';

export type IEudrStatementCreationFormValues = {
  title: string;
  year: number;
};

export type IEudrStatementSubmitSchemaFormValues = {
  eoriNumber: string;
  importToCountry: string;
  internalDdsNumber?: string;
  confirmed: boolean;
};

const eudrStatementCreationSchema = Yup.object().shape({
  title: Yup.string().required('This field is required'),
  year: Yup.number().required('This field is required'),
}) as Yup.ObjectSchema<IEudrStatementCreationFormValues>;

export const eudrStatementSubmitSchema = Yup.object().shape({
  eoriNumber: Yup.string().required('This field is required'),
  internalDdsNumber: Yup.string().nullable(),
  importToCountry: Yup.string().required('This field is required'),
  confirmed: Yup.bool().default(false).oneOf([true]).required('Needs to be confirmed'),
}) as Yup.ObjectSchema<IEudrStatementSubmitSchemaFormValues>;

export default eudrStatementCreationSchema;
