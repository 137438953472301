import { relayStylePagination } from '@apollo/client/utilities';
import orderBy from 'lodash/orderBy';

const feedbackPolicies = {
  Reactions: {
    fields: {
      reactions: relayStylePagination(),
    },
  },
  FeedbackAnalytics: {
    fields: {
      average: average => Math.round(average * 10) / 10,
      total: (_, { readField }) => {
        return readField('ratings').reduce((total, { value }) => total + value, 0);
      },
      ratings: data => orderBy(data, ['label'], ['desc']),
    },
  },
};

export default feedbackPolicies;
