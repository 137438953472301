import React from 'react';
import ThemeButton from 'designSystem/Buttons/ThemeButton/ThemeButton';
import { usePublishProductContext } from 'contexts/PublishProductContext';

const PublishButton = ({ ...rest }) => {
  const { handleClickPublish } = usePublishProductContext();

  return (
    <ThemeButton onClick={handleClickPublish} color="YELLOW" size="large" {...rest}>
      Publish product
    </ThemeButton>
  );
};

export default PublishButton;
