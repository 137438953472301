import React, { ReactNode, FC } from 'react';
import { FormHelperText } from '@mui/material';
import { styled } from '@mui/material/styles';
import Icon from 'designSystem/Primitives/Icon/Icon';

interface IErrorMessageProps {
  children: ReactNode;
}

const Message = styled(FormHelperText)(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  lineHeight: 1,
  color: theme.palette.error.main,
  fontWeight: 400,
  padding: 4,
}));

const IconContainer = styled('span')(({ theme }) => ({
  padding: theme.spacing(0, 1, 0.25, 0),
}));

const ErrorMessage: FC<IErrorMessageProps> = ({ children }) => {
  return (
    <Message error>
      <IconContainer>
        <Icon name="warning" size="small" color="red" />
      </IconContainer>
      {children}
    </Message>
  );
};

export default ErrorMessage;
