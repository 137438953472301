import React, { FC } from 'react';
import { useWelcomeSlides } from 'components/WelcomeSlides/hooks';
import { SlideIndicator } from 'components/Structure';
import { PrevButton, NextButton, ControlBarContainer } from './styles';
import { styled } from '@mui/material/styles';
import ThemeButton from 'designSystem/Buttons/ThemeButton/ThemeButton';

interface IFormControlBarProps {
  onSubmit: () => void;
  isSubmitting: boolean;
  submitButton?: boolean;
}

const StyledButton = styled(ThemeButton)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(4),
}));

const FormControlBar: FC<IFormControlBarProps> = ({ onSubmit, isSubmitting, submitButton }) => {
  const { currentSlide, nextSlide, slides, prevSlide, setCurrentSlide } = useWelcomeSlides();

  const handleSubmit = async () => {
    const res = await onSubmit();
    if (res !== undefined) {
      nextSlide();
    }
  };

  return (
    <ControlBarContainer>
      {currentSlide !== 0 && (
        <PrevButton onClick={prevSlide} inverted rtl>
          Previous
        </PrevButton>
      )}
      <SlideIndicator
        activeIndex={currentSlide}
        slideCount={slides.length}
        stepColor="WHITE"
        onClick={setCurrentSlide}
      />
      {submitButton ? (
        <StyledButton size="large" color="YELLOW" loading={isSubmitting} onClick={handleSubmit}>
          Save
        </StyledButton>
      ) : (
        <NextButton loading={isSubmitting} onClick={handleSubmit} inverted>
          Next
        </NextButton>
      )}
    </ControlBarContainer>
  );
};

export default FormControlBar;
