import FirebaseContext from 'contexts/FirebaseContext';
import { useContext } from 'react';

export enum FeatureFlag {
  UNDER_MAINTENANCE = 'underMaintenance',
  PARTNER_INVITATIONS = 'partnerInvitations',
  DOCUMENT_VISIBILITY_SETTING = 'documentVisibilitySetting',
  CUSTOM_LABEL_ENABLED = 'customLabelEnabled',
  ENABLE_START_DUE_DILIGENCE = 'enableStartDueDiligence',
  DISABLE_EUDR_GUIDE_AFTER_STEP3 = 'disableEudrGuideAfterStep3',
  ENABLE_CHAIN_REQUESTS = 'enableChainRequests',
}

const useFeatureFlags = () => {
  const { featureFlags } = useContext(FirebaseContext);

  const isFeatureEnabled = (feature: FeatureFlag) => {
    return !!featureFlags[feature] && featureFlags[feature] === true;
  };

  return { isFeatureEnabled };
};

export default useFeatureFlags;
