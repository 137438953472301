import { useEffect, useRef } from 'react';
import { useFormikContext } from 'formik';
import { useDebounce } from 'use-debounce';

// https://github.com/hughsk/flat/issues/115
// need to flatten errors into strings
function flatten(object) {
  const output = {};

  function step(object, prev, currentDepth, useBrackets) {
    const depth = currentDepth || 1;
    Object.keys(object).forEach(function (key) {
      const value = object[key];
      const isarray = Array.isArray(value);
      const type = Object.prototype.toString.call(value);
      const isobject = type === '[object Object]' || type === '[object Array]';

      const newKey = prev
        ? `${prev}${useBrackets ? '[' : '.'}${key}${useBrackets ? ']' : ''}`
        : key;

      if (isobject && Object.keys(value).length) {
        return step(value, newKey, depth + 1, isarray);
      }

      output[newKey] = value;
    });
  }

  step(object);

  return output;
}

const FormikAutoValidate = ({ showValidation }) => {
  const prevValue = useRef(showValidation);
  const { values, setErrors, setFieldTouched, validateForm } = useFormikContext();

  const [debouncedValues] = useDebounce(values, 250);

  useEffect(() => {
    if (!showValidation && prevValue.current) {
      setErrors({});
    } else if (showValidation) {
      validateForm().then(errors => {
        Object.keys(flatten(errors)).forEach(item => {
          setFieldTouched(item);
        });
      });
    }

    prevValue.current = showValidation;
  }, [showValidation, debouncedValues]); // eslint-disable-line

  return null;
};

export default FormikAutoValidate;
