import { Box, styled } from '@mui/material';
import EudrComplianceGuideSubSection from 'components/ComplianceGuide/EudrComplianceGuideSubSection';
import { useEudrComplianceGuide } from 'components/ComplianceGuide/EudrComplianceGuideContext';
import {
  EUDRAffectednessTypeColor,
  EUDRAffectednessTypeLabel,
} from 'components/ComplianceGuide/utils/eudrCompliance.utils';
import { FlexBox } from 'components/Structure';
import { ThemeTypography } from 'designSystem';
import React, { FC } from 'react';
import { EudrAffectednessType } from 'types/compliance.types';

interface ISelfAffectedResultProps {
  affectiveness: EudrAffectednessType;
}

const AFFECTIVENESS_LABELS: Record<
  ISelfAffectedResultProps['affectiveness'],
  { badgeColor: string; badgeText: string; description: string }
> = {
  [EudrAffectednessType.NOT_AFFECTED]: {
    badgeColor: EUDRAffectednessTypeColor[EudrAffectednessType.NOT_AFFECTED],
    badgeText: EUDRAffectednessTypeLabel[EudrAffectednessType.NOT_AFFECTED],
    description:
      'If the commodities involved in your supply chain changes, or if you have new customers or buyers within the EU, it is a good idea to check again and re-evaluate if you need to do any due diligence. ',
  },
  [EudrAffectednessType.INDIRECTLY_AFFECTED]: {
    badgeColor: EUDRAffectednessTypeColor[EudrAffectednessType.INDIRECTLY_AFFECTED],
    badgeText: EUDRAffectednessTypeLabel[EudrAffectednessType.INDIRECTLY_AFFECTED],
    description:
      'In the EUDR framework, you are not directly affected by the regulation. Your supply chain partners however are (since they place affected commodities on the EU market). If you want to continue to supply your products to companies within the EU, it is still important for you to collect the necessary data from the origins that will support you and your supply chain partners in becoming compliant. To do this, simply continue the personalised next steps of the compliance guide.',
  },
  [EudrAffectednessType.PARTIALLY_AFFECTED]: {
    badgeColor: EUDRAffectednessTypeColor[EudrAffectednessType.PARTIALLY_AFFECTED],
    badgeText: EUDRAffectednessTypeLabel[EudrAffectednessType.PARTIALLY_AFFECTED],
    description:
      'In the EUDR framework, you have the obligations of a Trader and SME, meaning you have the responsibility to complete some of the due diligence steps. The compliance guide will lead you through your personalised required process. Please continue to the next step.',
  },
  [EudrAffectednessType.FULLY_AFFECTED]: {
    badgeColor: EUDRAffectednessTypeColor[EudrAffectednessType.FULLY_AFFECTED],
    badgeText: 'Fully affected',
    description:
      'If the commodities involved in your supply chain changes, or if you have new customers or buyers within the EU, it is a good idea to check again and re-evaluate if you need to do any due diligence. ',
  },
};

const AffectivenessIndicator = styled(FlexBox)<{ background: string }>(({ theme, background }) => ({
  background,
  borderRadius: 8,
  padding: theme.spacing(1, 2),
  width: 'fit-content',
}));

const SelfAffectedResult: FC<ISelfAffectedResultProps> = ({ affectiveness }) => {
  const { formData, setSelectedSubSection } = useEudrComplianceGuide();

  const handleNextStepClick = () => {
    setSelectedSubSection('select-partners');
  };

  const handlePreviousStepClick = () => {
    if (formData.previousSubSectionKey) {
      setSelectedSubSection(formData.previousSubSectionKey);
    } else {
      // TODO: From here we can not navigate back since the state is lost, think about storing the previous state on backend if its needed
      console.warn('Previous sub section key is not available');
    }
  };

  return (
    <EudrComplianceGuideSubSection
      allowNextStepNavigation
      disabledPreviousNavigation={!formData?.previousSubSectionKey}
      onNextStepClick={handleNextStepClick}
      onPreviousStepClick={handlePreviousStepClick}
    >
      <Box>
        <AffectivenessIndicator background={AFFECTIVENESS_LABELS[affectiveness].badgeColor} mb={2}>
          <ThemeTypography variant="BODY_MEDIUM_BOLD">
            {AFFECTIVENESS_LABELS[affectiveness].badgeText}
          </ThemeTypography>
        </AffectivenessIndicator>

        <ThemeTypography variant="BODY_MEDIUM">
          {AFFECTIVENESS_LABELS[affectiveness].description}
        </ThemeTypography>
      </Box>
    </EudrComplianceGuideSubSection>
  );
};

export default SelfAffectedResult;
