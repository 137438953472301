import { Box, IconButton, TableCell, Tooltip, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Trash } from '@styled-icons/bootstrap';
import { ChevronDown } from '@styled-icons/bootstrap/ChevronDown';
import { ChevronUp } from '@styled-icons/bootstrap/ChevronUp';
import { StyledIconButton } from 'components/ImpactClaims/Table/styles';
import { FlexBox } from 'components/Structure';
import { StyledTableRow } from 'components/TableBase';
import { ThemeTypography } from 'designSystem';
import Icon from 'designSystem/Primitives/Icon/Icon';
import truncate from 'lodash/truncate';
import React, { FC, Fragment, MouseEvent, useRef, useState } from 'react';
import Moment from 'react-moment';
import { Booleanish, booleanish } from 'types/booleanish.types';
import {
  PartnerRequest,
  PartnerRequestStatus,
  PartnerRequestStatusType,
  RequestType,
} from 'types/partner.types';
import { isSafariBrowser } from 'utils';
import {
  REQUEST_STATUS_CLIENT,
  REQUEST_STATUS_COLOR_MAPPING,
  REQUEST_STATUS_PARTNER,
} from 'utils/requestStatus.utils';
import InnerChainTableRow from './InnerChainTableRow';
import InnerDocumentTableRow from './InnerDocumentTableRow';
import { RequestsTableAction } from './PaginatedRequestsTable';

interface IRequestGroupTableRowProps {
  request: PartnerRequest;
  isPartner: boolean;
  onRequestClick?: (id: string) => void;
  onActionClick: (id: string, action: RequestsTableAction) => void;
}

const Status = styled(Typography)<{ status: PartnerRequestStatusType }>(({ status }) => ({
  fontSize: 13,
  marginRight: 8,
  textTransform: 'uppercase',
  color: REQUEST_STATUS_COLOR_MAPPING[status],
}));

const ChevronIconButton = styled(IconButton)(({ theme }) => ({
  marginRight: theme.spacing(1.5),
}));

// Creates a row with 0 height, but has the global table border-spacing applied so it acts as an additional spaceing item.
// Used to have enough spacing after the innerTableRows in the collapsed group within the shadow container.
const SpacingRow = styled('tr')(() => ({
  height: 0,
}));

const ShadowContainer = styled(Box)<{
  height: number;
  'is-expanded': booleanish;
  'is-displayed': booleanish;
}>(({ theme, height, 'is-expanded': isExpanded, 'is-displayed': isDisplayed }) => ({
  display: isDisplayed === 'true' ? 'box' : 'none',
  position: 'absolute',
  background: '#fff',
  top: 0,
  left: 8,
  right: 8,
  bottom: isExpanded === 'true' ? -height : -8,
  height: 'auto',
  zIndex: -1,
  borderRadius: 6,
  boxShadow: theme.custom.shadows[1],
}));

const Dot = styled('span')(({ theme }) => ({
  width: 8,
  height: 8,
  background: theme.custom.themeColors.error[100],
  borderRadius: '100%',
  marginRight: 8,
}));

const RequestGroupTableRow: FC<IRequestGroupTableRowProps> = ({
  request,
  isPartner,
  onRequestClick,
  onActionClick,
}) => {
  const {
    id,
    requestTitle,
    requestStatus,
    requestedTimestamp,
    document,
    requestType,
    chainId,
    requestedFromCompany: { name: companyName, logo: companyLogo },
  } = request;
  const isRequested =
    (requestStatus === 'REQUESTED' && !isPartner) || (requestStatus === 'EDITED' && !isPartner);

  const expandableRow =
    !!document ||
    (requestType === RequestType.CHAIN &&
      (PartnerRequestStatus[requestStatus] === PartnerRequestStatus.PROVIDED ||
        PartnerRequestStatus[requestStatus] === PartnerRequestStatus.PROVIDED_COMPLETED));

  const isSafari = isSafariBrowser();
  // use table cell to calculate the height of shadow container when opening impact claim group
  const firstTableCell = useRef<HTMLTableRowElement>(null);

  const [isExpanded, setExpanded] = useState<boolean>(false);

  const handleDocumentAction = (action: RequestsTableAction) => {
    onActionClick(id, action);
  };

  const handleExpansionClick = (event: MouseEvent) => {
    setExpanded(prev => !prev);
    event.stopPropagation();
  };

  const handleRowClick = (event: MouseEvent) => {
    event.stopPropagation();
    onRequestClick?.(id);
  };

  const handleActionClick = (event: MouseEvent, action: RequestsTableAction) => {
    event.stopPropagation();
    onActionClick(id, action);
  };

  return (
    <Fragment>
      <StyledTableRow
        key={id}
        hover
        data-cy="request-table-item"
        data-cy-id={id}
        transparent={Booleanish(isRequested)}
        has-more={Booleanish(!!document && !isExpanded)}
        onClick={isPartner ? handleExpansionClick : handleRowClick}
      >
        <TableCell ref={firstTableCell} scope="row">
          <Box display="flex">
            <FlexBox mr={1.5}>
              <Icon
                name={
                  REQUEST_STATUS_CLIENT[requestType][PartnerRequestStatus[requestStatus]].iconName
                }
                size="xx-large"
              />
            </FlexBox>

            <Box>
              <Box display="flex" alignItems="center">
                {isPartner && requestStatus === 'REQUESTED' ? <Dot /> : <Fragment />}
                <Tooltip title={request.requestTitle || ''}>
                  <ThemeTypography variant="ITEM_TITLE">
                    {truncate(requestTitle, {
                      length: 45,
                      separator: '',
                    })}
                  </ThemeTypography>
                </Tooltip>
              </Box>

              <Box display="flex" alignItems="center" gap={0.8}>
                <Status variant="body2" status={requestStatus}>
                  {isPartner
                    ? REQUEST_STATUS_PARTNER[requestType][PartnerRequestStatus[requestStatus]]
                        .statusTitle
                    : REQUEST_STATUS_CLIENT[requestType][PartnerRequestStatus[requestStatus]]
                        .statusTitle}
                </Status>
              </Box>
            </Box>
          </Box>
        </TableCell>

        {isPartner ? (
          <TableCell scope="row">
            <Box display="flex" alignItems="center">
              {companyLogo?.url ? (
                <img src={companyLogo?.url} alt="logo" height={20} />
              ) : (
                <Icon name="partner" size="xx-large" color="blue-ice" />
              )}
              <Box mr={1} />
              {companyName}
            </Box>
          </TableCell>
        ) : (
          <Fragment />
        )}

        <TableCell scope="row">
          {requestedTimestamp && <Moment format="LL">{requestedTimestamp}</Moment>}
        </TableCell>

        <TableCell scope="row" width={150}>
          <Box display="flex" alignItems="center" justifyContent="flex-end">
            {isPartner &&
            (PartnerRequestStatus[requestStatus] === PartnerRequestStatus.REQUESTED ||
              PartnerRequestStatus[requestStatus] === PartnerRequestStatus.EDITED) ? (
              <Tooltip
                title={
                  REQUEST_STATUS_PARTNER[request.requestType][PartnerRequestStatus.REQUESTED]
                    .actionText
                }
              >
                <StyledIconButton
                  data-cy="upload-document-btn"
                  onClick={event => handleActionClick(event, RequestsTableAction.MANAGE)}
                >
                  <Icon
                    size="large"
                    name={
                      REQUEST_STATUS_PARTNER[request.requestType][PartnerRequestStatus.PROVIDED]
                        .iconName
                    }
                  />
                </StyledIconButton>
              </Tooltip>
            ) : (
              <Fragment />
            )}

            {(isPartner && requestStatus === 'REQUESTED') ||
            (!isPartner && requestStatus === 'PROVIDED') ? (
              <Tooltip title={isPartner ? 'Dismiss request' : 'Accept request'}>
                <StyledIconButton
                  data-cy="dismiss-accept-request-btn"
                  onClick={event =>
                    handleActionClick(
                      event,
                      isPartner ? RequestsTableAction.DISMISS : RequestsTableAction.ACCEPT
                    )
                  }
                >
                  {isPartner ? (
                    <Icon
                      name={
                        REQUEST_STATUS_PARTNER[requestType][PartnerRequestStatus.DENIED].iconName
                      }
                      size="large"
                    />
                  ) : (
                    <Icon
                      name={
                        REQUEST_STATUS_CLIENT[requestType][PartnerRequestStatus.PROVIDED].iconName
                      }
                      size="large"
                    />
                  )}
                </StyledIconButton>
              </Tooltip>
            ) : (
              <Fragment />
            )}

            {!isPartner && PartnerRequestStatus[requestStatus] === PartnerRequestStatus.DENIED ? (
              <Tooltip title="See details and edit request">
                <StyledIconButton
                  data-cy="see-details-request-btn"
                  onClick={event => handleActionClick(event, RequestsTableAction.SEE_DETAILS)}
                >
                  <Icon name="edit" />
                </StyledIconButton>
              </Tooltip>
            ) : (
              <Fragment />
            )}

            {(!isPartner && PartnerRequestStatus[requestStatus] === PartnerRequestStatus.EDITED) ||
            (!isPartner &&
              PartnerRequestStatus[requestStatus] === PartnerRequestStatus.REQUESTED) ||
            (!isPartner &&
              PartnerRequestStatus[requestStatus] === PartnerRequestStatus.PROVIDED) ? (
              <Tooltip title="Edit request">
                <StyledIconButton
                  data-cy="edit-request-btn"
                  onClick={event => handleActionClick(event, RequestsTableAction.EDIT)}
                >
                  <Icon name="edit" />
                </StyledIconButton>
              </Tooltip>
            ) : (
              <Fragment />
            )}

            {!isPartner &&
            PartnerRequestStatus[requestStatus] !== PartnerRequestStatus.PROVIDED &&
            !isPartner &&
            PartnerRequestStatus[requestStatus] !== PartnerRequestStatus.PROVIDED_COMPLETED ? (
              <Tooltip title="Delete request">
                <StyledIconButton
                  data-cy="delete-request-btn"
                  onClick={event => handleActionClick(event, RequestsTableAction.DELETE)}
                >
                  <Trash size={16} />
                </StyledIconButton>
              </Tooltip>
            ) : (
              <Fragment />
            )}

            {expandableRow ? (
              <ChevronIconButton
                aria-label="expand row"
                size="small"
                data-cy="expand-request-btn"
                onClick={handleExpansionClick}
              >
                {isExpanded ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
              </ChevronIconButton>
            ) : (
              <Fragment />
            )}
          </Box>

          {expandableRow ? (
            <ShadowContainer
              is-displayed={Booleanish(!isSafari)}
              is-expanded={Booleanish(isExpanded)}
              height={(firstTableCell.current?.clientHeight || 60) + 40}
            />
          ) : (
            <Fragment />
          )}
        </TableCell>
      </StyledTableRow>

      {requestType === RequestType.CHAIN &&
      (PartnerRequestStatus[requestStatus] === PartnerRequestStatus.PROVIDED ||
        PartnerRequestStatus[requestStatus] === PartnerRequestStatus.PROVIDED_COMPLETED) ? (
        <InnerChainTableRow isExpanded={isExpanded} chainId={chainId} request={request} />
      ) : (
        <Fragment />
      )}

      {document ? (
        <InnerDocumentTableRow
          document={document}
          isAccepted={
            PartnerRequestStatus[requestStatus] === PartnerRequestStatus.PROVIDED_COMPLETED
          }
          isPartner={isPartner}
          isExpanded={isExpanded}
          onDocumentActionClick={handleDocumentAction}
        />
      ) : (
        <Fragment />
      )}

      <SpacingRow />
    </Fragment>
  );
};

export default RequestGroupTableRow;
