import { useMutation } from '@apollo/client';
import { Box, DialogActions, styled } from '@mui/material';
import { LanguageSelector, Loader } from 'components/Forms';
import { LanguageSingleSelect } from 'components/Forms/Input';
import { mergeDefaults } from 'components/Forms/utils';
import { useProduct } from 'components/Product/hooks';
import { ErrorState } from 'components/Structure';
import { useConfig, useDialog } from 'components/hooks';
import { productLanguageSchema } from 'constants/schemas/product';
import { FieldWrapper, ThemeButton, ThemeTypography } from 'designSystem';
import { Field, Form, Formik } from 'formik';
import { UPDATE_PRODUCT_LANGUAGES } from 'graphql/mutations';
import difference from 'lodash/difference';
import React, { FC } from 'react';
import { AvailableLanguages } from 'types/enums';
import { Product } from 'types/types';

interface ILanguageSettingsContentProps {
  productId: string;
  onClose?: () => void;
}

const SettingsContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(5, 10, 10, 10),
}));

const PaddedFieldWrapper = styled(FieldWrapper)(({ theme }) => ({
  marginBottom: theme.spacing(5),
}));

const StyledDialogActions = styled(DialogActions)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

const LanguageSettingsContent: FC<ILanguageSettingsContentProps> = ({ productId, onClose }) => {
  const config = useConfig();
  const { product, loading, error } = useProduct(productId);
  const { openDialog } = useDialog();

  const [saveProductLanguage] = useMutation(UPDATE_PRODUCT_LANGUAGES, {});

  if (loading) {
    return (
      <Box minHeight={300}>
        <Loader />
      </Box>
    );
  }
  if (error || !product) {
    return <ErrorState />;
  }

  const initialValues = mergeDefaults(productLanguageSchema.default(), {
    languageConfig: product.languageConfig,
  });

  const openAddLanguageDialog = (
    languagesToAdd: AvailableLanguages[],
    defaultLanguage: AvailableLanguages
  ) => {
    openDialog({
      type: 'ADD_LANGUAGE',
      props: {
        product,
        languagesToAdd,
        defaultLanguage,
      },
    });
  };

  const handleSaveClick = (values: { languageConfig: Product['languageConfig'] }) => {
    saveProductLanguage({
      variables: {
        id: product.id,
        input: {
          languageConfig: values.languageConfig,
        },
      },
    });

    // Checks the difference between the orignal values ( in the language config )
    // and the values currently in formik. Difference spits out the items that are in the array which are not in the other.
    // So these are the values that have been added.
    const addedLanguages = difference(
      values.languageConfig.enabled,
      product.languageConfig.enabled
    );

    // If there's added languages, we can open the Add Language Dialog
    if (addedLanguages.length > 0) {
      openAddLanguageDialog(addedLanguages, values.languageConfig.default);
    }

    onClose?.();
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={productLanguageSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={handleSaveClick}
    >
      {({ values, setFieldValue }) => {
        return (
          <Form>
            <SettingsContainer>
              <Box>
                <PaddedFieldWrapper label="Product languages">
                  <Field
                    variant="outlined"
                    fullWidth
                    component={LanguageSelector}
                    name="languageConfig.enabled"
                    defaultLanguage={values.languageConfig.default}
                    publishedLanguages={product.publishedLanguages}
                  />
                  <Box mt={1}>
                    <ThemeTypography variant="BODY_LARGE">
                      Select the languages you want to enable.
                    </ThemeTypography>
                  </Box>
                </PaddedFieldWrapper>

                <FieldWrapper label="Default language">
                  <LanguageSingleSelect
                    selectedLanguage={values.languageConfig.default}
                    setSelectedLanguage={(selectedLanguage: AvailableLanguages) => {
                      setFieldValue('languageConfig.default', selectedLanguage);
                    }}
                    languages={config.productLanguages}
                  />
                  <Box mt={1}>
                    <ThemeTypography variant="BODY_LARGE">
                      Set the default language for your product content. It has to be one of your
                      selected product languages.
                    </ThemeTypography>
                  </Box>
                </FieldWrapper>
              </Box>
            </SettingsContainer>
            <StyledDialogActions>
              <ThemeButton color="BLUE_ICE" size="large" onClick={onClose}>
                Cancel
              </ThemeButton>
              <ThemeButton color="YELLOW" size="large" type="submit" data-cy="save-changes-button">
                Save Changes
              </ThemeButton>
            </StyledDialogActions>
          </Form>
        );
      }}
    </Formik>
  );
};

export default LanguageSettingsContent;
