export const urlSafeFileName = (fileName = '') => {
  const spaceRegex = new RegExp(' +', 'g');

  return fileName.replace(spaceRegex, '_');
};

export const getDimensionsImage = file => {
  const imageFile = URL.createObjectURL(file);

  return new Promise((resolve, reject) => {
    const img = new Image();

    img.onload = () =>
      resolve({
        height: img.height,
        width: img.width,
      });
    img.onerror = reject;
    img.src = imageFile;
  });
};
