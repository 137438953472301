import { gql } from '@apollo/client';
import { COMPONENT_FRAGMENT } from 'graphql/fragments';
import { CHAIN_BASE_FRAGMENT } from 'graphql/fragments/chain.fragments';
import { RAW_MATERIAL_FRAGMENT } from 'graphql/fragments/component.fragments';

export const GET_COMPONENT = gql`
  query GetComponent($id: UUID!, $lang: Language) {
    component(id: $id, lang: $lang) {
      ...componentValues
      componentChains {
        ...chainBaseValues
      }
      supplyChains {
        ...chainBaseValues
      }
    }
  }
  ${COMPONENT_FRAGMENT}
  ${CHAIN_BASE_FRAGMENT}
`;

export const GET_COMPONENTS_LIST = gql`
  query GetComponentsList(
    $first: Int
    $after: String
    $filters: ComponentsFilters
    $orderBy: ComponentsOrderBy
  ) {
    components(first: $first, after: $after, filters: $filters, orderBy: $orderBy) {
      edges {
        node {
          ...componentValues
        }
      }
    }
  }
  ${COMPONENT_FRAGMENT}
`;

export const GET_RAW_MATERIALS = gql`
  query GetRawMaterials {
    rawMaterials {
      edges {
        node {
          ...rawMaterialValues
        }
      }
    }
  }
  ${RAW_MATERIAL_FRAGMENT}
`;
