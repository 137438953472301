import * as Yup from 'yup';
import { createMediaSchema, createProductDefault } from './shared';

export default Yup.object().shape({
  moreProducts: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.string(),
        title: Yup.string().label('Title').required().default(''),
        description: Yup.string().label('Description').required().default(''),
        image: createMediaSchema('Product image missing'),
        link: Yup.string().label('Link').url(),
      })
    )
    .default([createProductDefault()])
    .required('Please add at least one product')
    .min(1),
});
