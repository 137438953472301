import React, { PropsWithChildren, useState } from 'react';
import { FastField as Field, Form, Formik } from 'formik';
import { Link as RouterLink } from 'react-router-dom';
import { TextField } from 'formik-mui';
import { Grid, Link, MenuItem, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import TermsDialog from './TermsDialog';
import { industries } from 'components/Auth/constants';
import { registrationSchema } from 'constants/schemas';
import PasswordInputField from './PasswordInputField';
import AcceptTermsCheckbox from './AcceptTermsCheckbox';
import { ThemeButton, FieldWrapper } from 'designSystem';
import { RegisterValues } from 'types/types';

const TrialText = styled('p')(({ theme }) => ({
  color: '#fff',
  marginLeft: theme.spacing(2),
}));

interface Props extends PropsWithChildren {
  email?: string;
  isPartnerRegistration?: boolean;
  onSubmit: (values: RegisterValues) => void;
}

const RegistrationForm: React.FC<Props> = ({
  onSubmit,
  isPartnerRegistration = false,
  email = '',
}) => {
  const [termsOpen, setTermsOpen] = useState(false);

  return (
    <Formik<RegisterValues>
      initialValues={
        email ? { ...registrationSchema.default(), email } : { ...registrationSchema.default() }
      }
      validateOnBlur={false}
      validateOnChange={true}
      validationSchema={registrationSchema}
      onSubmit={values => onSubmit(values)}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Form onSubmit={handleSubmit}>
          <Grid container>
            <Grid item sm={10}>
              <Grid container spacing={3}>
                <Grid item sm={12} md={10}>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <FieldWrapper label="First Name" inverted>
                        <Field
                          component={TextField}
                          placeholder="Jane"
                          name="firstName"
                          variant="outlined"
                          inputProps={{
                            'data-cy': 'first-name-field',
                          }}
                        />
                      </FieldWrapper>
                    </Grid>
                    <Grid item xs={6}>
                      <FieldWrapper label="Last name" inverted>
                        <Field
                          component={TextField}
                          placeholder="Doe"
                          name="lastName"
                          variant="outlined"
                          inputProps={{
                            'data-cy': 'last-name-field',
                          }}
                        />
                      </FieldWrapper>
                    </Grid>
                    <Grid item xs={12}>
                      <FieldWrapper label="Company name" inverted>
                        <Field
                          component={TextField}
                          fullWidth
                          placeholder="Transparency Inc."
                          name="companyName"
                          variant="outlined"
                          inputProps={{
                            'data-cy': 'company-name-field',
                          }}
                        />
                      </FieldWrapper>
                    </Grid>
                    <Grid item xs={12}>
                      <FieldWrapper label="Industry" inverted>
                        <Field
                          component={TextField}
                          type="text"
                          name="industry"
                          variant="outlined"
                          select
                          fullWidth
                          data-cy="select-industry"
                        >
                          {industries.map(({ displayName, value }) => (
                            <MenuItem key={value} value={value} data-cy="dropdown-item">
                              {displayName}
                            </MenuItem>
                          ))}
                        </Field>
                      </FieldWrapper>
                    </Grid>
                    <Grid item xs={12}>
                      <FieldWrapper label="Email" inverted>
                        <Field
                          disabled={isPartnerRegistration && email}
                          component={TextField}
                          fullWidth
                          placeholder="jane.doe@transparency.org"
                          name="email"
                          variant="outlined"
                          inputProps={{
                            'data-cy': 'email-field',
                          }}
                        />
                      </FieldWrapper>
                    </Grid>
                    <Grid item xs={12}>
                      <FieldWrapper label="Password" inverted>
                        <Field
                          fullWidth
                          component={PasswordInputField}
                          name="password"
                          placeholder="Min. 6 characters"
                          variant="outlined"
                          inputProps={{
                            'data-cy': 'password-field',
                          }}
                        />
                      </FieldWrapper>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <AcceptTermsCheckbox handleOpenTerms={() => setTermsOpen(true)} />
                </Grid>
                <Grid item xs={12}>
                  <Box display="flex" alignItems="center">
                    <ThemeButton
                      loading={isSubmitting}
                      size="large"
                      color="YELLOW"
                      type="submit"
                      className="button-wide"
                      data-cy="register-button"
                    >
                      Register
                    </ThemeButton>
                    {isPartnerRegistration === false && (
                      <TrialText>Starts your 14 day free trial</TrialText>
                    )}
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Link component={RouterLink} to="/login" color="secondary">
                    Already registered? Login
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <TermsDialog open={termsOpen} onClose={() => setTermsOpen(false)} />
        </Form>
      )}
    </Formik>
  );
};

export default RegistrationForm;
