/**
 * Guarantee uniqueness since multiple activities can have the same id if imported multiple times
 */
const chainStepActivityPolicies = {
  ChainStepActivity: {
    keyFields: ['id', 'pointPosition'],
  },
};

export default chainStepActivityPolicies;
