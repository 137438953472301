import { styled } from '@mui/material/styles';
import { ChevronDown } from '@styled-icons/bootstrap';
import { ICellRendererParams } from 'ag-grid-community';
import React, { FC } from 'react';
import { IExcelTableContext } from '../excelTable.types';
import { FarmColumns } from 'types/dataImport.types';

const CropCellContainer = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
});

const CropCellContent = styled('div')({
  flex: 1,
  textAlign: 'left',
  overflowX: 'hidden',
  textOverflow: 'ellipsis',
});

const CropCellRenderer: FC<ICellRendererParams<FarmColumns, string, IExcelTableContext>> = ({
  value,
  context,
}) => {
  return (
    <CropCellContainer>
      {value && <CropCellContent>{value}</CropCellContent>}
      <ChevronDown size={16} color={context.theme.custom.themeColors.grayScale[40]} />
    </CropCellContainer>
  );
};

export default CropCellRenderer;
