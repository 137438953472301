import { PartnerRequest } from 'types/partner.types';
import React from 'react';
import { ThemeTypography } from 'designSystem';
import CompanyNameLogo from 'components/Structure/CompanyNameLogo';
import { Box } from '@mui/material';
import { AvailableSizes } from 'types/enums';

interface Props {
  request: PartnerRequest;
}

const RequestTitle: React.FC<Props> = ({ request }) => {
  return (
    <Box display="flex" flexWrap="wrap">
      <Box display="flex" mr={1}>
        <CompanyNameLogo
          size={AvailableSizes.MEDIUM}
          companyName={request.requestedFromCompany.name}
          companyLogoUrl={request.requestedFromCompany.logo?.url}
        />
      </Box>
      <Box whiteSpace="nowrap" display="flex" alignItems="center">
        <ThemeTypography variant="BODY_LARGE_BOLD">
          has requested information from you
        </ThemeTypography>
      </Box>
    </Box>
  );
};

export default RequestTitle;
