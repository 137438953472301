import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Edit } from '@styled-icons/boxicons-solid/Edit';
import { UploadListItem } from 'components/FileUpload';
import { SummaryBox } from 'components/SliderForm';
import { ThemeTypography, DialogTitle } from 'designSystem';
import { ProofListItem } from 'components/Partners/PartnerRequests';

const Container = styled('div')(({ theme }) => ({
  maxWidth: 600,
  margin: '0 auto',
  padding: theme.spacing(2, 0, 3, 0),
}));

const DocumentList = styled('div')(({ theme }) => ({
  background: '#fff',
  width: '100%',
  padding: theme.spacing(2),
  marginTop: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.custom.shadows[1],
}));

const Header = styled('div')(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(2),
  background: '#fff',
  zIndex: 1,
}));

const ConfirmationSlide = ({
  selectedImpactClaim,
  documents,
  title,
  selectedImpactLevel,
  impactLevelItem,
  documentRequests,
}) => {
  return (
    <Box maxWidth="100%" maxHeight="100%" overflow="auto">
      <Header>
        <DialogTitle icon={<Edit size={16} />}>{title}</DialogTitle>
      </Header>
      <Container>
        {selectedImpactClaim && (
          <SummaryBox
            title={selectedImpactClaim?.title}
            variant={selectedImpactClaim?.iconUrl ? 'IMAGE' : 'BACKGROUND_ICON'}
            category={selectedImpactClaim?.category}
            body={selectedImpactClaim?.definition}
            imageUrl={selectedImpactClaim?.iconUrl}
            levelPillOptions={{
              level: selectedImpactLevel,
              title: impactLevelItem,
            }}
            titleTestId="catalog-item-summary-title"
          />
        )}

        {documents.length ? (
          <DocumentList>
            <Box mb={1}>
              <ThemeTypography variant="BODY_LARGE_BOLD">Proof documents</ThemeTypography>
            </Box>
            {documents.map(({ id, title }) => (
              <UploadListItem key={id} fileName={title} success />
            ))}
          </DocumentList>
        ) : null}
        {documentRequests.length ? (
          <DocumentList>
            <Box mb={1}>
              <ThemeTypography variant="BODY_LARGE_BOLD">Document Requests</ThemeTypography>
            </Box>
            {documentRequests.map(partnerRequest => (
              <ProofListItem key={partnerRequest.id} partnerRequest={partnerRequest} />
            ))}
          </DocumentList>
        ) : null}
      </Container>
    </Box>
  );
};

ConfirmationSlide.propTypes = {
  selectedImpactClaim: PropTypes.object,
  title: PropTypes.string.isRequired,
  documents: PropTypes.array.isRequired,
  selectedImpactLevel: PropTypes.string,
  impactLevelItem: PropTypes.string,
};

export default ConfirmationSlide;
