import React, { useCallback, useMemo, useState } from 'react';
import { TextContainer, CharacterLimit } from './TextFieldLimited';
import { TextField } from 'designSystem';
import { Props as TextFieldProps } from 'designSystem/Inputs/TextField/TextField';

interface Props extends TextFieldProps {
  'data-cy'?: string;
  charLimit?: number;
  lightBordered?: boolean;
  value?: string | null;
}

// Todo: combine with TextFieldLimited.js
const LimitedTextField: React.FC<Props> = ({
  'data-cy': dataCy,
  charLimit = 50,
  lightBordered = false,
  ...props
}: Props) => {
  const [focused, setFocused] = useState(false);

  // if text already exists and it is longer than the limit, then the limit is not applied
  const applyCharLimit = useMemo(() => {
    return (props.value || '').length <= charLimit;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [charLimit]);

  const handleFocus = useCallback(() => {
    setFocused(true);
  }, []);

  const handleBlur = useCallback(() => {
    setFocused(false);
  }, []);

  return (
    <TextContainer lightBordered={lightBordered}>
      <TextField
        {...props}
        inputProps={{
          ...(props.inputProps ? props.inputProps : {}),
          'data-cy': dataCy,
          maxLength: applyCharLimit ? charLimit : null,
          onBlur: handleBlur,
          onFocus: handleFocus,
        }}
      />
      {applyCharLimit && (
        <CharacterLimit visible={focused}>{`${
          (props.value || '').length
        }/${charLimit}`}</CharacterLimit>
      )}
    </TextContainer>
  );
};

export default LimitedTextField;
