import React, { FC } from 'react';
import { FormControlBar } from 'components/WelcomeSlides';
import CompanyForm, { CompanyFormValues } from './CompanyForm';
import { useMutation, useQuery } from '@apollo/client';
import { GET_COMPANY_DETAILS } from 'graphql/queries';
import { useLogEvent, useMessages } from 'components/hooks';
import { EDIT_COMPANY } from 'graphql/mutations';
import { ErrorState } from 'components/Structure';
import { Loader } from 'components/Forms';

interface ICompanyProps {
  active: boolean;
}

const Company: FC<ICompanyProps> = ({ active }) => {
  const { logEvent } = useLogEvent();
  const {
    data: { company } = {},
    error,
    loading,
  } = useQuery(GET_COMPANY_DETAILS, {
    fetchPolicy: 'cache-only',
  });

  const { setSuccessMessage, setErrorMessage } = useMessages();
  const [editCompany] = useMutation(EDIT_COMPANY, {
    onCompleted: () => {
      setSuccessMessage('Success! Company details were updated.');
      logEvent('WELCOME_SLIDES_UPDATE_COMPANY');
    },
    onError: () => {
      setErrorMessage('Something went wrong updating your companies details.');
    },
  });

  const handleSubmit = (input: CompanyFormValues) => {
    return editCompany({
      variables: {
        input,
      },
    });
  };

  if (error) return <ErrorState error={error} />;
  if (!company && loading) return <Loader />;

  return (
    <>
      <CompanyForm company={company} onSubmit={handleSubmit} active={active}>
        {({ submitForm, isSubmitting }) => (
          <FormControlBar onSubmit={submitForm} isSubmitting={isSubmitting} />
        )}
      </CompanyForm>
    </>
  );
};
export default Company;
