import React from 'react';
import AuthHeadline from './AuthHeadline';
import * as Yup from 'yup';
import { Grid, Link } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import { FieldWrapper } from 'designSystem';
import ThemeButton from 'designSystem/Buttons/ThemeButton/ThemeButton';

const Schema = Yup.object().shape({
  email: Yup.string()
    .email('Please enter a valid email')
    .required('This field is required')
    .default(''),
});

const Message = styled('p')(() => ({
  color: '#fff',
}));

interface FormInputs {
  email: string;
}

interface Props {
  success: boolean;
  email?: string;
  onSubmit: (inputs: FormInputs) => void;
  onRouteChange: () => void;
}

const PasswordForgotForm: React.FC<Props> = ({ success, onSubmit, email, onRouteChange }) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <AuthHeadline text="Reset your" highlighted="password" />
      </Grid>
      <Grid item>
        {success ? (
          <Grid item lg={8} md={8} sm={8} xs={12}>
            <Message>
              If this email is registered, we sent reset instructions to {email}. Use the link in
              the mail to reset your password and sign in with your new one.{' '}
            </Message>
          </Grid>
        ) : (
          <Formik<FormInputs>
            initialValues={Schema.default() as FormInputs}
            validateOnBlur={false}
            validationSchema={Schema}
            onSubmit={values => onSubmit(values)}
          >
            {({ isSubmitting }) => (
              <Form>
                <Grid container spacing={3}>
                  <Grid item xs={10}>
                    <FieldWrapper label="Email" inverted>
                      <Field
                        component={TextField}
                        fullWidth
                        placeholder="jane.doe@transparency.org"
                        name="email"
                        variant="outlined"
                      />
                    </FieldWrapper>
                  </Grid>
                  <Grid item xs={12}>
                    <ThemeButton
                      loading={isSubmitting}
                      size="large"
                      color="YELLOW"
                      type="submit"
                      className="button-wide"
                    >
                      Send Mail
                    </ThemeButton>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        )}
      </Grid>
      <Grid item lg={8} md={8} sm={8} xs={12}>
        <Link onClick={onRouteChange} color="secondary">
          Back to login
        </Link>
      </Grid>
    </Grid>
  );
};

export default PasswordForgotForm;
