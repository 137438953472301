import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import {
  Container,
  ErrorMessage,
  StyledProgress,
  FileNameText,
  ActionButton,
  ProgressNumber,
  ProgressContainer,
  SubTitle,
} from './styles';
import { Trash } from '@styled-icons/bootstrap/Trash';
import { FileEarmarkText } from '@styled-icons/bootstrap/FileEarmarkText';
import { Edit } from '@styled-icons/boxicons-solid/Edit';
import { ExternalLink } from '@styled-icons/evaicons-solid/ExternalLink';

const UploadListItem = ({
  progress,
  fileName,
  error,
  success,
  onDelete,
  onEdit,
  subTitle,
  onOpen,
}) => {
  const uploading = !error && !success;

  return (
    <Container data-cy={onOpen ? 'view-document-list-item' : 'upload-list-item'}>
      <Box display="flex" alignItems="center" width="90%">
        <FileEarmarkText size={28} />
        <Box display="flex" flexGrow={1} flexDirection="column" width="70%">
          <Box>
            <FileNameText>{fileName}</FileNameText>
            {success && subTitle && <SubTitle>{subTitle}</SubTitle>}
          </Box>
          {error && <ErrorMessage error>{error}</ErrorMessage>}
        </Box>
      </Box>
      {uploading && (
        <ProgressContainer>
          <ProgressNumber>{progress}%</ProgressNumber>
          <StyledProgress color="secondary" variant="determinate" value={progress} />
        </ProgressContainer>
      )}
      {onEdit && success && (
        <ActionButton variant="contained" onClick={onEdit} data-cy="upload-list-item-edit">
          <Edit size={14} />
        </ActionButton>
      )}
      {onDelete && (
        <ActionButton variant="contained" onClick={onDelete} data-cy="upload-list-item-delete">
          <Trash size={14} />
        </ActionButton>
      )}
      {onOpen && (
        <ActionButton variant="contained" onClick={onOpen} data-cy="upload-list-item-open">
          <ExternalLink size={14} />
        </ActionButton>
      )}
    </Container>
  );
};

UploadListItem.propTypes = {
  progress: PropTypes.number,
  fileName: PropTypes.string,
  error: PropTypes.string,
  success: PropTypes.bool,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  subTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default UploadListItem;
