import { MultiPolygon } from 'types/map.types';
import { Feature, Polygon as TurfPolygon, polygon } from '@turf/turf';
import { v4 as uuid } from 'uuid';

export const transformLatLngToArrayPosition = (polygon: { lat: number; lng: number }[]) =>
  polygon.map(c => [c.lng, c.lat]);

/* Correct format for mapbox */
export const transformLatLngArrayToLngLat = (multiPolygon: MultiPolygon): MultiPolygon =>
  multiPolygon.map(polygon => polygon.map(([lat, lng]) => [lng, lat]));

/* Warning: This format cna not be used for mapbox and is only shown in the json formatter */
export const transformLngLatArrayToLatLng = (multiPolygon: MultiPolygon): MultiPolygon =>
  multiPolygon.map(polygon => polygon.map(([lng, lat]) => [lat, lng]));

/** Assigns a random uuid to the feature set */
export const transformMultiPolygonToFeature = (
  multiPolygon: MultiPolygon
): Feature<TurfPolygon>[] =>
  multiPolygon.map(singlePolygon => polygon([singlePolygon], {}, { id: uuid() }));
