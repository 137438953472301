import React, { FC } from 'react';
import Tabs, { ITab } from 'designSystem/Primitives/Tabs/Tabs';
import ActivitySelection from './ActivitySelection';
import ExternalActivitySelection from './ExternalActivitySelection';
import { Box } from '@mui/material';
import {
  ActivityOwnership,
  ActivityOwnershipType,
} from 'graphql/mutations/types/activity-mutation.types';
import { IActivity } from 'types/activity.types';

interface ISelectActivityTypeProps {
  /** Default: all types */
  tabKeys?: ActivityOwnershipType[];
  currentActivityType: ActivityOwnershipType;
  setCurrentActivityType: (activityType: ActivityOwnershipType) => void;
  setActivities: (activities: IActivity[]) => void;
  setLoading: (loading: boolean) => void;
}

const ACTIVITY_TABS: ITab<ActivityOwnershipType>[] = [
  {
    key: ActivityOwnership.INTERNAL,
    label: 'Internal',
  },
  {
    key: ActivityOwnership.EXTERNAL,
    label: 'External',
  },
  {
    key: ActivityOwnership.UNASSIGNED,
    label: 'Unassigned',
  },
];

const SelectActivityType: FC<ISelectActivityTypeProps> = ({
  tabKeys,
  currentActivityType,
  setCurrentActivityType,
  setActivities,
  setLoading,
}) => {
  const shownTabs = tabKeys
    ? ACTIVITY_TABS.filter(tab => tabKeys.includes(tab.key))
    : ACTIVITY_TABS;

  const handleChangeTab = (tab: ActivityOwnershipType) => {
    setCurrentActivityType(tab);
  };

  return (
    <Box width="100%">
      <Tabs tabs={shownTabs} selectedTab={currentActivityType} onChange={handleChangeTab} />
      {currentActivityType === ActivityOwnership.INTERNAL && (
        <ActivitySelection
          setActivities={setActivities}
          setLoading={setLoading}
          activityType={ActivityOwnership.INTERNAL}
        />
      )}
      {currentActivityType === ActivityOwnership.EXTERNAL && (
        <ExternalActivitySelection setActivities={setActivities} />
      )}
      {currentActivityType === ActivityOwnership.UNASSIGNED && (
        <ActivitySelection
          setActivities={setActivities}
          setLoading={setLoading}
          activityType={ActivityOwnership.UNASSIGNED}
        />
      )}
    </Box>
  );
};

export default SelectActivityType;
