import React from 'react';
import { List, ListItem, Card } from '@mui/material';
import { styled } from '@mui/material/styles';
import { NavLink } from 'react-router-dom';
import { ExclamationCircle } from '@styled-icons/bootstrap/ExclamationCircle';

const StyledNavLink = styled(NavLink)(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  alignItems: 'center',
  color: theme.palette.text.primary,
  textDecoration: 'none',
  borderBottom: `1px solid ${theme.palette.grey[300]}`,
  paddingRight: theme.spacing(1),

  '&:last-of-type': {
    borderBottom: 'none',
  },

  '&.active': {
    color: theme.palette.secondary.main,
    fontWeight: 600,
    background: theme.palette.common.white,

    '&:before': {
      position: 'absolute',
      content: '""',
      height: '100%',
      width: 4,
      background: theme.palette.secondary.main,
    },
  },
}));

const Sidebar = styled(Card)(() => ({}));

const StyledList = styled(List)(() => ({
  padding: 0,
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  padding: theme.spacing(1.5, 1),

  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(1.5, 0.5),
  },
}));

const StyledListItemText = styled('span')(({ theme }) => ({
  alignSelf: 'flex-end',
  paddingLeft: theme.spacing(1),
}));

export const SettingsContainer = styled(List)(({ theme }) => ({
  width: '85%',
  height: '100%',
  paddingLeft: theme.spacing(3),
}));

const SettingsSidebar = ({ menu }) => {
  return (
    <Sidebar>
      <StyledList>
        {menu.map(({ label, to, displayWarning, active }, index) => {
          if (!active) {
            return null;
          }

          return (
            <StyledNavLink end key={`${label}-${index}`} to={to}>
              <StyledListItem>
                <StyledListItemText>{label}</StyledListItemText>
              </StyledListItem>
              {displayWarning && <ExclamationCircle size={18} />}
            </StyledNavLink>
          );
        })}
      </StyledList>
    </Sidebar>
  );
};

export default SettingsSidebar;
