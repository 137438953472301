import { IBasePartnerCompany, PartnerRequest } from 'types/partner.types';

export const getRequestCompaniesList = (
  requests: PartnerRequest[],
  direction: 'FROM' | 'TO'
): IBasePartnerCompany[] => {
  const fromOrToCompanyProp = direction === 'FROM' ? 'requestedFromCompany' : 'requestedToCompany';
  return requests.reduce<IBasePartnerCompany[]>((allCompanies, request) => {
    if (allCompanies.findIndex(company => company.id === request[fromOrToCompanyProp]?.id) === -1) {
      return allCompanies.concat(request[fromOrToCompanyProp]);
    }
    return allCompanies;
  }, []);
};
