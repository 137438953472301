import { Alert, AlertTitle } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Warning } from '@styled-icons/ionicons-outline/Warning';
import React, { FC, ReactNode } from 'react';

export interface IAlertBannerProps {
  severity?: 'warning' | 'info' | 'success' | 'error';
  title?: string;
  children?: ReactNode;
}

export const StyledAlert = styled(Alert)<{ center: boolean }>(({ theme, center }) => ({
  display: 'flex',
  alignItems: center ? 'center' : 'flex-start',
  '&.MuiAlert-standardWarning': {
    backgroundColor: theme.custom.themeColors.error[20],
    color: theme.custom.themeColors.error[50],
    '&. MuiAlert-icon': {
      color: theme.custom.themeColors.error[80],
    },
  },
}));

const AlertBanner: FC<IAlertBannerProps> = ({ severity = 'warning', title, children }) => {
  return (
    <StyledAlert
      iconMapping={{
        warning: <Warning size={50} />,
      }}
      severity={severity}
      center={!title}
    >
      <AlertTitle>{title}</AlertTitle>
      {children}
    </StyledAlert>
  );
};

export default AlertBanner;
