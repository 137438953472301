import { styled } from '@mui/material/styles';
import { CheckCircleFill } from '@styled-icons/bootstrap/CheckCircleFill';
import { CircleFill } from '@styled-icons/bootstrap/CircleFill';
import { ExclamationCircleFill } from '@styled-icons/bootstrap/ExclamationCircleFill';
import PropTypes from 'prop-types';
import React from 'react';

const Container = styled(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ valid, disabled, showWarning, ...props }) => <div {...props} />
)(({ theme, valid, disabled, showWarning }) => {
  let color = theme.palette.grey[200];

  if (disabled)
    return {
      color,
    };

  if (valid) color = theme.palette.success.main;
  else if (!valid && showWarning) color = theme.palette.warning.main;

  return {
    color,
    background: theme.palette.common.white,
    alignSelf: 'center',
    width: 14,
    height: 14,
    borderRadius: '50%',

    '& svg': {
      verticalAlign: 'top',
      marginTop: -1,
      marginBottom: -1,
    },
  };
});

const Icon = ({ valid, showWarning, disabled }) => {
  const size = 16;

  if (disabled === true) return <CircleFill size={size} />;
  if (valid) return <CheckCircleFill size={size} />;
  if (!valid && showWarning) return <ExclamationCircleFill size={size} />;

  return <CircleFill size={size} />;
};

const ValidationIndicator = ({ valid, disabled, showWarning }) => (
  <Container
    disabled={disabled}
    valid={valid}
    showWarning={showWarning}
    data-cy={
      valid === true
        ? 'valid-indicator'
        : valid === false
        ? 'invalid-indicator'
        : 'not-validated-indicator'
    }
  >
    <Icon disabled={disabled} valid={valid} showWarning={showWarning} />
  </Container>
);

ValidationIndicator.propTypes = {
  valid: PropTypes.bool,
  disabled: PropTypes.bool,
  showWarning: PropTypes.bool,
};

export default ValidationIndicator;
