import { useQuery } from '@apollo/client';
import loadable from '@loadable/component';
import { Loader } from 'components/Forms';
import { ErrorState } from 'components/Structure';
import { useLogEventOnMount } from 'components/hooks';
import { GET_PRODUCT } from 'graphql/queries';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { RouteParamsWithId } from 'types/router.types';

const LoadableAnalytics = loadable(() => import('components/Product/Analytics/BehaviorAnalytics'), {
  fallback: <Loader />,
});

const ProductBehaviorAnalytics = () => {
  const { id } = useParams<RouteParamsWithId>();
  useLogEventOnMount('VIEW_PRODUCT_BEHAVIOUR_ANALYTICS', { productId: id });
  const { error, loading, data } = useQuery(GET_PRODUCT, {
    variables: { id },
  });

  useEffect(() => {
    LoadableAnalytics.preload();
  }, []);

  if (error) return <ErrorState error={error} />;
  if (!data && loading) return <Loader />;

  return <LoadableAnalytics />;
};

export default ProductBehaviorAnalytics;
