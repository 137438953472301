export const CHAIN_STEPS_OPTIONS = [
  'Processing',
  'Handling',
  'Manufacturing',
  'Distribution',
  'Return',
];

export type TChainStepOption =
  | 'Origin'
  | 'Processing'
  | 'Handling'
  | 'Manufacturing'
  | 'Distribution'
  | 'Return'
  | 'Other';
