import React, { useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { Autocomplete } from '@mui/material';
import { TextField, Box } from '@mui/material';
import { GET_CERTIFICATES_CATALOG_ITEMS } from 'graphql/queries';
import { useQuery } from '@apollo/client';
import { CertificateCatalogItems, CertificateCatalogItem } from 'types/types';
import { getIn, FieldInputProps, FormikProps } from 'formik';
import { FileEarmarkText } from '@styled-icons/bootstrap/FileEarmarkText';
import { removeGraphConnections } from 'utils/graphql.utils';
import { OTHER_CERTIFICATE_ID } from 'constants/documents';

const AutoCompleteWrapper = styled('div')(({ theme }) => ({
  flex: 1,
}));

const Image = styled('img')(({ theme }) => ({
  height: 17,
  width: 17,
  objectFit: 'contain',
}));

const CertificateLogoPlaceholder = styled('div')(({ theme }) => ({
  height: 20,
  width: 20,
  backgroundColor: theme.custom.colors.backgroundLight,
  borderRadius: '50%',
}));

const CertificateLogo = styled('img')(({ theme }) => ({
  width: 20,
}));

interface AutoCompleteOptions {
  label: string;
  id: string | undefined;
  logo: string | undefined;
}

interface Props<V> {
  field: FieldInputProps<V>;
  form: FormikProps<V>;
}

const CertificationAutoComplete: React.FC<Props<string>> = ({ field, form }) => {
  const { name, value } = field;
  const { touched, errors, setFieldValue } = form;
  const fieldError = getIn(errors, name);
  const showError = getIn(touched, name) && !!fieldError;

  const { loading, data } = useQuery<{
    certificateCatalogItems: CertificateCatalogItems;
  }>(GET_CERTIFICATES_CATALOG_ITEMS);

  // build the autocomplete options
  const certificationsOptions: AutoCompleteOptions[] = useMemo(() => {
    if (!data?.certificateCatalogItems) return [];
    const optionList: AutoCompleteOptions[] = removeGraphConnections(
      data?.certificateCatalogItems
    ).map((certification: CertificateCatalogItem) => {
      return {
        label: certification.title,
        id: certification.id,
        logo: certification.imageUrl,
      };
    });
    // Add other certification to certification list
    optionList.push({
      label: 'Other',
      id: OTHER_CERTIFICATE_ID,
      logo: undefined,
    });
    return optionList;
  }, [data]);

  const selectedCertificate = useMemo(() => {
    if (!certificationsOptions) return;
    return certificationsOptions.find(certificate => certificate?.id === value);
  }, [value, certificationsOptions]);

  return (
    <AutoCompleteWrapper>
      <Autocomplete<AutoCompleteOptions>
        loading={loading}
        value={selectedCertificate ? selectedCertificate : null}
        options={certificationsOptions}
        onChange={(event, certificate: AutoCompleteOptions | null) => {
          setFieldValue(name, certificate?.id);
        }}
        getOptionLabel={(option: AutoCompleteOptions) => option.label}
        renderOption={(props, option: AutoCompleteOptions) => (
          <li {...props}>
            <Box display="flex" alignItems="center">
              <Box mr={2}>
                {option.logo ? (
                  <Image loading="lazy" src={option.logo} />
                ) : (
                  <FileEarmarkText width={17} />
                )}
              </Box>
              {option.label}
            </Box>
          </li>
        )}
        renderInput={params => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              startAdornment: selectedCertificate ? (
                selectedCertificate?.id === OTHER_CERTIFICATE_ID ? (
                  <FileEarmarkText size={20} />
                ) : (
                  <CertificateLogo src={selectedCertificate?.logo} />
                )
              ) : (
                <CertificateLogoPlaceholder />
              ),
            }}
            size="small"
            variant="outlined"
            placeholder="Type to filter"
            onKeyPress={keyEvent => {
              // Prevents the form from being submitted on enter.
              if (keyEvent.key === 'Enter') {
                keyEvent.preventDefault();
              }
            }}
            error={showError && !!fieldError}
            helperText={showError && fieldError && fieldError}
          />
        )}
      />
    </AutoCompleteWrapper>
  );
};

export default CertificationAutoComplete;
