import { grey, white } from 'assets/sdgs';

const sdgConfig = [
  {
    title: 'No Poverty',
    icon: grey[0],
    iconWhite: white[0],
    hex: '#e5233d',
  },
  {
    title: 'Zero Hunger',
    icon: grey[1],
    iconWhite: white[1],
    hex: '#DDA63A',
  },
  {
    title: 'Good Health and Well-Being',
    icon: grey[2],
    iconWhite: white[2],
    hex: '#4C9F38',
  },
  {
    title: 'Quality Education',
    icon: grey[3],
    iconWhite: white[3],
    hex: '#C5192D',
  },
  {
    title: 'Gender Equality',
    icon: grey[4],
    iconWhite: white[4],
    hex: '#FF3A21',
  },
  {
    title: 'Clean Water and Sanitation',
    icon: grey[5],
    iconWhite: white[5],
    hex: '#26BDE2',
  },
  {
    title: 'Affordable and Clean Energy',
    icon: grey[6],
    iconWhite: white[6],
    hex: '#FCC30B',
  },
  {
    title: 'Decent Work and Economic Growth',
    icon: grey[7],
    iconWhite: white[7],
    hex: '#A21942',
  },
  {
    title: 'Industry, Innovation and Infrastructure',
    icon: grey[8],
    iconWhite: white[8],
    hex: '#FD6925',
  },
  {
    title: 'Reduced Inequalities',
    icon: grey[9],
    iconWhite: white[9],
    hex: '#DD1367',
  },
  {
    title: 'Sustainable Cities and Communities',
    icon: grey[10],
    iconWhite: white[10],
    hex: '#FD9D24',
  },
  {
    title: 'Responsible Consumption & Production',
    icon: grey[11],
    iconWhite: white[11],
    hex: '#BF8B2E',
  },
  {
    title: 'Climate Action',
    icon: grey[12],
    iconWhite: white[12],
    hex: '#3F7E44',
  },
  {
    title: 'Life Below Water',
    icon: grey[13],
    iconWhite: white[13],
    hex: '#0A97D9',
  },
  {
    title: 'Life On Land',
    icon: grey[14],
    iconWhite: white[14],
    hex: '#56C02B',
  },
  {
    title: 'Peace Justice & Strong Institutions',
    icon: grey[15],
    iconWhite: white[15],
    hex: '#00689D',
  },
  {
    title: 'Partnerships for the Goals',
    icon: grey[16],
    iconWhite: white[16],
    hex: '#19486A',
  },
];

export default sdgConfig;
