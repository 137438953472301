import { Box, styled } from '@mui/material';
import { Plus } from '@styled-icons/bootstrap';
import ComponentItemTable from 'components/ComponentsLibrary/ComponentItemsTable';
import { ComponentItemTableColumnKeys } from 'components/ComponentsLibrary/constants/headerColumns';
import useComponentItemActions from 'components/ComponentsLibrary/hooks/useComponentItemActions';
import useComponentItems from 'components/ComponentsLibrary/hooks/useComponentItems';
import { Loader, SearchToolbar } from 'components/Forms';
import { ProductSummarySkeleton } from 'components/Product/ProductTable';
import {
  ActionContainer,
  EmptySearchResults,
  ErrorState,
  PageContainer,
  ScrollableTableContainer,
} from 'components/Structure';
import { CardIllustration, EmptyStateCard, ThemeButton } from 'designSystem';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { IComponentItem } from 'types/component.types';
import { AvailableSizes, BackgroundTypes, IllustrationTypes, ItemTypes } from 'types/enums';

const Container = styled('div')(() => ({
  position: 'relative',
  minHeight: 50,

  '& .MuiGrid-item': {
    display: 'flex',
  },
}));

const ComponentItems: FC = () => {
  const { components, error, loading, searchTerm, filteredComponents, handleSearchTermChange } =
    useComponentItems();
  const navigate = useNavigate();

  const { handleCreateComponent } = useComponentItemActions();

  const handleRowClick = ({ id }: IComponentItem) => navigate(`/components/${id}`);

  if (loading) return <Loader />;
  if (error) return <ErrorState />;

  if (!components.length) {
    return (
      <Box mt={4}>
        <EmptyStateCard
          title="Add your product components to start tracing the material flow of your supply chains"
          onCreateItemClick={handleCreateComponent}
          variant={ItemTypes.COMPONENT}
          buttonText="Add component"
          background={BackgroundTypes.WORLD}
          renderIllustration={
            <CardIllustration image={IllustrationTypes.LOCATIONS} width={300} right={25} top={0} />
          }
          size={AvailableSizes.LARGE}
          data-cy="components-getting-started-card"
          data-cy-button="components-getting-started-card-button"
        />
      </Box>
    );
  }

  return (
    <PageContainer>
      <Container>
        <ActionContainer>
          <SearchToolbar
            setSearchTerm={handleSearchTermChange}
            searchTerm={searchTerm || ''}
            className="search-input"
            data-cy="components-search-input"
            placeholder="Search component"
            actions={
              <Box ml={1}>
                <ThemeButton
                  size="medium"
                  color="YELLOW"
                  onClick={handleCreateComponent}
                  startIcon={<Plus size={20} />}
                  data-cy="create-component-btn"
                >
                  Create new component
                </ThemeButton>
              </Box>
            }
          />
        </ActionContainer>
        <ScrollableTableContainer pb={1}>
          {!!filteredComponents?.length && (
            <ComponentItemTable
              data-cy="component-table"
              components={filteredComponents}
              columns={[
                ComponentItemTableColumnKeys.TITLE,
                ComponentItemTableColumnKeys.PARTNER,
                ComponentItemTableColumnKeys.MODIFIED_TIMESTAMP,
                ComponentItemTableColumnKeys.ACTIONS,
              ]}
              onSelect={handleRowClick}
            />
          )}
        </ScrollableTableContainer>
      </Container>

      {/* No result container */}
      {!filteredComponents?.length && (
        <EmptySearchResults
          message="No search results found"
          action={{
            label: 'Create component',
            onClick: handleCreateComponent,
          }}
          skeletonComponent={<ProductSummarySkeleton title={searchTerm} />}
        />
      )}
    </PageContainer>
  );
};

export default ComponentItems;
