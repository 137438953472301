import { gql } from '@apollo/client';

export const ABOUT_FRAGMENT = gql`
  fragment AboutValues on AboutBlock {
    id
    contentType
    enabled
    validation @client @expensive {
      valid
      errors
      fields
    }
    data {
      id
      headline
      image {
        id
        imageId
        url
        crop {
          x
          y
          width
          height
        }
      }
      description
    }
  }
`;

export const IMPACT_FACTS_FRAGMENT = gql`
  fragment ImpactFactsValues on ImpactFactsBlock {
    id
    contentType
    enabled
    validation @client @expensive {
      valid
      errors
      fields
    }
    data {
      id
      headline
      description
      image {
        imageId
        url
        crop {
          x
          y
          width
          height
        }
      }
      impactFacts {
        id
        title
        description
        sdgs
      }
    }
  }
`;

export const PRODUCTS_FRAGMENT = gql`
  fragment ProductsValues on ProductsBlock {
    id
    contentType
    enabled
    validation @client @expensive {
      valid
      errors
      fields
    }
    data {
      id
      moreProducts {
        id
        title
        description
        link
        image {
          url
          imageId
          crop {
            x
            y
            width
            height
          }
        }
      }
    }
  }
`;

export const PRODUCT_JOURNEY_FRAGMENT = gql`
  fragment ProductJourneyValues on ProductJourneyBlock {
    id
    contentType
    enabled
    versionId
    validation @client @expensive {
      valid
      errors
      fields
    }
  }
`;

export const FEEDBACK_BLOCK_FRAGMENT = gql`
  fragment FeedbackBlockValues on FeedbackBlock {
    id
    contentType
    enabled
    versionId
    validation @client @expensive {
      valid
      errors
      fields
    }
    data {
      id
      ratingEnabled
      headline
      subtitle
    }
  }
`;

export const MORE_INFO_BLOCK_FRAGMENT = gql`
  fragment MoreInfoBlockValues on MoreInfoBlock {
    id
    contentType
    enabled
    versionId
    validation @client @expensive {
      valid
      errors
      fields
    }
    data {
      id
      blockTitle
      headline
      description
      mediaItem {
        ... on ImageVariant {
          id
          imageId
          url
          crop {
            x
            y
            width
            height
          }
        }
        ... on Video {
          vimeoLink
          videoId: id
        }
      }
    }
  }
`;

export const VERIFIED_PAYMENT_BLOCK_FRAGMENT = gql`
  fragment VerifiedPaymentsBlockValues on VerifiedPaymentsBlock {
    id
    contentType
    enabled
    versionId
    validation @client @expensive {
      valid
      errors
      fields
    }
    data {
      id
      headline
      description
      image {
        imageId
        id
        url
        crop {
          x
          y
          width
          height
        }
      }
    }
  }
`;

export const CONTENT_BLOCKS_FRAGMENT = gql`
  fragment contentBlocks on ProductVersion {
    contentBlocks {
      ... on AboutBlock {
        ...AboutValues
      }
      ... on ImpactFactsBlock {
        ...ImpactFactsValues
      }
      ... on ProductsBlock {
        ...ProductsValues
      }
      ... on ProductJourneyBlock {
        ...ProductJourneyValues
      }
      ... on MoreInfoBlock {
        ...MoreInfoBlockValues
      }
      ... on FeedbackBlock {
        ...FeedbackBlockValues
      }
      ... on VerifiedPaymentsBlock {
        ...VerifiedPaymentsBlockValues
      }
    }
  }
  ${IMPACT_FACTS_FRAGMENT}
  ${ABOUT_FRAGMENT}
  ${PRODUCTS_FRAGMENT}
  ${MORE_INFO_BLOCK_FRAGMENT}
  ${FEEDBACK_BLOCK_FRAGMENT}
  ${VERIFIED_PAYMENT_BLOCK_FRAGMENT}
  ${PRODUCT_JOURNEY_FRAGMENT}
`;

export const contentBlockFragmentMap = {
  About: ABOUT_FRAGMENT,
  ImpactFacts: IMPACT_FACTS_FRAGMENT,
  ProductsBlock: PRODUCTS_FRAGMENT,
  ProductJourney: PRODUCT_JOURNEY_FRAGMENT,
  MoreInfoBlock: MORE_INFO_BLOCK_FRAGMENT,
  FeedbackBlock: FEEDBACK_BLOCK_FRAGMENT,
  VerifiedPaymentsBlock: VERIFIED_PAYMENT_BLOCK_FRAGMENT,
};
