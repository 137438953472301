import { gql } from '@apollo/client';
import { IMAGE_FRAGMENT } from 'graphql/fragments';

export const UPDATE_IMAGE = gql`
  mutation UpdateImage($id: UUID!, $input: UpdateImageInput!) {
    updateImage(id: $id, input: $input) {
      image {
        ...imageValues
      }
    }
  }
  ${IMAGE_FRAGMENT}
`;

export const DELETE_IMAGE = gql`
  mutation DeleteImage($id: UUID!) {
    deleteImage(id: $id) {
      id
    }
  }
`;

export const UPLOAD_IMAGE = gql`
  mutation UploadImage($input: UploadImageInput!) {
    uploadImage(input: $input) {
      image {
        ...imageValues
      }
    }
  }
  ${IMAGE_FRAGMENT}
`;
