import React from 'react';
import { Button, Divider, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Header = styled(Grid)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  width: '100%',
}));

export const TitleContainer = styled(Grid)({
  position: 'relative',
});

export const TitleActionButton = styled(Button)(({ theme }) => ({
  position: 'absolute',
  right: 0,
  backgroundColor: theme.palette.primary.main,
}));

const Subtitle = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(1, 0, 2),
  width: '100%',
  fontSize: 14,
}));

const Title = styled('h3')(() => ({
  marginBottom: 0,
  marginTop: 0,
  width: '100%',
}));

const SettingsHeader = ({ buttonText, title, onClick, subtitle }) => {
  return (
    <Header data-cy="settings-header">
      <TitleContainer item container direction="row" alignItems="baseline">
        <Title>{title}</Title>
        {buttonText && onClick ? (
          <TitleActionButton variant="contained" onClick={onClick}>
            {buttonText}
          </TitleActionButton>
        ) : null}
      </TitleContainer>
      <Subtitle variant="subtitle2">{subtitle}</Subtitle>
      <Divider />
    </Header>
  );
};

export default SettingsHeader;
