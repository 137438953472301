import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React from 'react';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const StyledDialog = styled(({ maxSize, ...props }) => <Dialog {...props} />)(({ maxSize }) => ({
  '& .MuiDialog-paperScrollPaper': {
    height: maxSize ? '100%' : 'auto',
    width: maxSize ? '100%' : 'auto',
  },
}));

const DialogFormFrame = ({ actions, formComponent, onClose, open, title, maxSize = false }) => {
  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      maxSize={maxSize}
    >
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <DialogContent>{formComponent}</DialogContent>
      <DialogActions>{actions}</DialogActions>
    </StyledDialog>
  );
};

DialogFormFrame.propTypes = {
  formComponent: PropTypes.node,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  maxSize: PropTypes.bool,
};

export default DialogFormFrame;
