const industries = [
  {
    displayName: 'Cosmetics',
    value: 'COSMETICS',
  },
  {
    displayName: 'Fashion',
    value: 'FASHION',
  },
  {
    displayName: 'Food & Beverages',
    value: 'FOOD',
  },
  {
    displayName: 'Furniture',
    value: 'FURNITURE',
  },
];

export default industries;
