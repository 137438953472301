import { useApolloClient } from '@apollo/client';
import { useUploadState } from 'components/hooks';
import { DOCUMENT_FRAGMENT } from 'graphql/fragments';
import React, { useMemo } from 'react';
import { FileUploadItem } from '../FileUpload';
import SubTitleUploadItem from './SubTitleUploadItem';

const DocumentUploadItem = props => {
  const { fileMap } = useUploadState();
  const client = useApolloClient();
  const { recordId } = fileMap[props.id];
  const document = useMemo(() => {
    if (!recordId) return null;

    return (
      client.readFragment({
        fragment: DOCUMENT_FRAGMENT,
        id: `Document:${recordId}`,
      }) || {}
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordId]);

  return (
    <FileUploadItem
      {...props}
      fileName={document?.title}
      // In order not to open the document again directly in the edit container after updating, we set isUpdated here.
      // To update a document, you have to set a category, so we can assume that the document has already been updated once.
      isUpdated={!!document?.category}
      subTitle={
        <SubTitleUploadItem category={document?.category} expiryDate={document?.expiryDate} />
      }
    />
  );
};

export default DocumentUploadItem;
