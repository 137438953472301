import noLogo from 'assets/img/qrGeneatator/logos/no-logo.png';
import scanMe from 'assets/img/qrGeneatator/logos/scan-me-1.svg';
import scanMeSquare from 'assets/img/qrGeneatator/logos/scan-me-2.svg';

const logos = [
  {
    displayName: 'No logo',
    value: 'no-logo',
    img: noLogo,
  },
  {
    displayName: 'Scan me square',
    value: 'scan-me-square',
    img: scanMeSquare,
  },
  {
    displayName: 'Scan me',
    value: 'scan-me',
    img: scanMe,
  },
];

export default logos;
