import { gql } from '@apollo/client';
import { DOCUMENT_FRAGMENT } from '../fragments';

export const CREATE_DOCUMENT = gql`
  mutation CreateDocument($input: DocumentInput!) {
    createDocument(input: $input) {
      document {
        ...documentsValues
      }
    }
  }
  ${DOCUMENT_FRAGMENT}
`;

export const UPDATE_DOCUMENT = gql`
  mutation UpdateDocument($id: UUID!, $input: DocumentInput!) {
    updateDocument(id: $id, input: $input) {
      document {
        ...documentsValues
      }
    }
  }
  ${DOCUMENT_FRAGMENT}
`;

export const DELETE_DOCUMENTS = gql`
  mutation DeleteDocument($ids: [UUID]!) {
    deleteDocument(ids: $ids) {
      ids
    }
  }
`;
