import React from 'react';
import { useEditorState, useMenu } from '../hooks';
import ContentBlockMenu from './ContentBlockMenu/ContentBlockMenu';

import MenuItem from './MenuItem';

const Menu = () => {
  const { version } = useMenu();
  const { showValidation } = useEditorState();

  return (
    <div>
      <MenuItem
        step="General"
        title="General"
        disabled={false}
        showWarning={showValidation}
        valid={version.generalValidation?.valid}
      />

      <ContentBlockMenu />
    </div>
  );
};

export default Menu;
