import { styled } from '@mui/material/styles';
import { Trash } from '@styled-icons/bootstrap';
import { ICellRendererParams } from 'ag-grid-community';
import { getEmptyRow, isRowEmpty } from 'components/DataImport/utils/dataImport.utils';
import React, { FC } from 'react';
import { IExcelTableContext } from '../../excelTable.types';

const DeleteContainer = styled('div')(() => ({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& svg': {
    cursor: 'pointer',
  },
}));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const DeleteCellRenderer: FC<ICellRendererParams<any, any, IExcelTableContext & any>> = ({
  data,
  api,
  context,
}) => {
  return (
    <DeleteContainer>
      {!isRowEmpty(data) && (
        <Trash
          size={15}
          onClick={() => {
            api.applyTransaction({ remove: [data] });
            api.applyTransaction({
              add: [
                getEmptyRow({
                  ...('settings' in context ? context.settings : {}),
                  autoAssignIds: false,
                }),
              ],
            });
          }}
        />
      )}
    </DeleteContainer>
  );
};

export default DeleteCellRenderer;
