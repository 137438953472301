import React, { FC, PropsWithChildren, createContext } from 'react';
import { useMessages, useUserData } from 'components/hooks';
import { useMutation } from '@apollo/client';
import { ACCEPT_TERMS } from 'graphql/mutations';
import { DialogFormFrame, Loader } from 'components/Forms';
import { TermsContent } from 'components/Auth';
import ThemeButton from 'designSystem/Buttons/ThemeButton/ThemeButton';

const TermsContext = createContext<null | boolean>(null);

const TermsProvider: FC<PropsWithChildren> = ({ children }) => {
  const { user } = useUserData();

  const { setErrorMessage } = useMessages();

  const [updateTerms, { loading }] = useMutation(ACCEPT_TERMS, {
    variables: {
      input: {
        accepted: true,
      },
    },
    onError: () => {
      setErrorMessage('Sorry, something went wrong. Please try again later');
    },
  });

  if (!user) return <Loader />;

  return (
    <TermsContext.Provider value={user.latestTermsAccepted}>
      <DialogFormFrame
        open={!user?.latestTermsAccepted}
        onClose={() => null}
        title="Please accept our updated Terms and Conditions to further use our services"
        formComponent={<TermsContent />}
        actions={
          <ThemeButton loading={loading} color="YELLOW" size="large" onClick={() => updateTerms()}>
            Accept
          </ThemeButton>
        }
      />
      {children}
    </TermsContext.Provider>
  );
};

export { TermsProvider };

export default TermsContext;
