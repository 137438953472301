import { gql } from '@apollo/client';
import { PRODUCT_FRAGMENT, REACTION_FRAGMENT } from 'graphql/fragments';

export const GET_FEEDBACK = gql`
  query GetFeedback($id: UUID!) {
    product(id: $id) {
      ...productValues
    }
    productFeedbackAnalytics(id: $id) {
      average
      total @client
      ratings {
        label
        value
        weight
      }
    }
    productFeedback(id: $id) {
      reactions(first: 10) {
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        edges {
          cursor
          node {
            ...reactionValues
          }
        }
      }
    }
  }
  ${PRODUCT_FRAGMENT}
  ${REACTION_FRAGMENT}
`;

export const GET_REACTIONS = gql`
  query GetReactions($id: UUID!, $cursor: String!) {
    productFeedback(id: $id) {
      reactions(first: 10, after: $cursor) {
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        edges {
          cursor
          node {
            ...reactionValues
          }
        }
      }
    }
  }
  ${REACTION_FRAGMENT}
`;
