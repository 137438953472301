import { Box, IconButton, TableCell, Tooltip, Typography, styled } from '@mui/material';
import { CompaniesIcon } from 'assets/img/icons';
import defaultSupplyChainImage from 'assets/img/supply-chain-placeholder-image.svg';
import { FlexBox } from 'components/Structure';
import { StyledTableRow } from 'components/TableBase';
import { TaskProgress, ThemeTypography } from 'designSystem';
import React, { FC, ReactNode } from 'react';
import Moment from 'react-moment';
import { IBaseProduct } from 'types/product.type';
import Image from 'designSystem/DataDisplay/Image/Image';

interface ITableRowProps {
  product: IBaseProduct;
  actions?: ITableRowAction[];
  inUse?: boolean;
  onRowClick?: (product: IBaseProduct) => void;
}

export interface ITableRowAction {
  tooltip: string;
  disableIfUsed?: boolean;
  disabledTooltip?: string;
  renderIcon: () => ReactNode;
  onClick: (product: IBaseProduct) => void;
}

const IconContainer = styled(Box)(() => ({
  marginRight: 26,
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  padding: theme.spacing(0, 4, 0, 0),
  color: theme.palette.text.secondary,
}));

const Date = styled(Typography)(() => ({
  width: 140,
}));

const TableRow: FC<ITableRowProps> = ({ product, onRowClick, actions, inUse = false }) => {
  const { title, articleNumber, latestSupplyChain, connectedPartners, image } = product;

  return (
    <StyledTableRow hover onClick={() => (onRowClick ? onRowClick(product) : undefined)}>
      <TableCell scope="row">
        <Box display="flex" alignItems="center">
          <Image image={image} width={44} height={44} backupImageUrl={defaultSupplyChainImage} />
          <Box ml={2}>
            <FlexBox>
              <ThemeTypography variant="ITEM_TITLE" autoOverflow maxWidth={250}>
                {title}
              </ThemeTypography>
              {articleNumber && (
                <Box ml={0.8}>
                  <ThemeTypography
                    variant="BODY_MEDIUM"
                    autoOverflow
                    maxWidth={150}
                    color="GRAY_80"
                  >
                    | {articleNumber}
                  </ThemeTypography>
                </Box>
              )}
            </FlexBox>
            {latestSupplyChain?.chainTasksDetails && (
              <TaskProgress
                totalTasks={latestSupplyChain.chainTasksDetails.total}
                completedTasks={latestSupplyChain.chainTasksDetails.completed}
              />
            )}
          </Box>
        </Box>
      </TableCell>
      <TableCell scope="row">
        <Box display="flex" alignItems="center">
          <CompaniesIcon />
          <Box ml={1}>
            <ThemeTypography variant="ITEM_SUBTITLE">
              {connectedPartners?.length || 0}
            </ThemeTypography>
          </Box>
        </Box>
      </TableCell>

      <TableCell scope="row">
        <Date variant="subtitle2">
          <Moment format="LL">{product.modifiedTimestamp}</Moment>
        </Date>
      </TableCell>
      <TableCell scope="row" align="right">
        {actions && (
          <IconContainer display="flex" alignItems="center" justifyContent="flex-end">
            {actions.map((action, index) => {
              const disabled = action.disableIfUsed && inUse;
              const disabledTooltip = action.disabledTooltip || '';
              return (
                <Tooltip key={index} title={disabled ? disabledTooltip : action.tooltip}>
                  <Box component="span">
                    <StyledIconButton
                      onClick={event => {
                        event.stopPropagation();
                        action.onClick(product);
                      }}
                      disabled={disabled}
                    >
                      {action.renderIcon()}
                    </StyledIconButton>
                  </Box>
                </Tooltip>
              );
            })}
          </IconContainer>
        )}
      </TableCell>
    </StyledTableRow>
  );
};

export default TableRow;
