import { useState } from 'react';
import { GET_PRODUCT_ANALYTICS_OVERVIEW } from 'graphql/queries';
import { useQuery } from '@apollo/client';
import isEmpty from 'lodash/isEmpty';
import { getClientTime } from 'components/Product/utils';

const useProductAnalyticsOverview = ({ product }) => {
  const [{ start, end }] = useState({
    start: getClientTime('months', 1),
    end: getClientTime(),
  });
  const { id, status } = product;

  const {
    loading,
    data = {},
    error,
  } = useQuery(GET_PRODUCT_ANALYTICS_OVERVIEW, {
    variables: {
      id,
      params: {
        productId: id,
        startDate: start,
        endDate: end,
      },
    },
    fetchPolicy: 'no-cache',
  });

  const hasData = !isEmpty(data);
  const {
    productAnalyticsExist = false,
    pageActivity = {},
    productAnalyticsSummary: { views } = {},
  } = data;

  return {
    hasData,
    productAnalyticsExist,
    pageActivity,
    views,
    loading,
    status,
    error,
  };
};

export default useProductAnalyticsOverview;
