import * as Yup from 'yup';
import { activitySchema, IActivityFormValue } from './activities.schema';
import { createImageSchema } from './shared';

export type IChainActivityFormValue = {
  id: string | null;
  incomingLinks: { chainStepActivitySourceId: string }[];
  activity: IActivityFormValue;
  chainStepId: string;
};

export const nestedActivitySchema = Yup.object().shape({
  activity: activitySchema,
}) as Yup.ObjectSchema<IChainActivityFormValue>;

export const linkSchema = Yup.object().shape({
  chainStepActivitySourceId: Yup.string().required(),
});

const chainActivitySchema = Yup.object().shape({
  id: Yup.string().notRequired(),
  incomingLinks: Yup.array().of(linkSchema).default([]),
  activity: activitySchema,
  chainStepId: Yup.string().required(),
}) as Yup.ObjectSchema<IChainActivityFormValue>;

export const componentChainSchema = Yup.object().shape({
  title: Yup.string().required(),
  image: createImageSchema({
    message: 'Please add an image of your component',
    required: false,
  }),
  ownedById: Yup.string(),
});

export default chainActivitySchema;
