import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Breadcrumbs } from '@mui/material';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';

const StyledCrumbs = styled(Breadcrumbs)(() => ({
  fontSize: 12,
  textTransform: 'uppercase',
}));

const BreadCrumbLink = styled(Link)(({ theme }) => ({
  color: theme.palette.grey[600],
  textDecoration: 'none',

  '&:visited': {
    color: theme.palette.grey[600],
  },
}));

const Last = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[600],
  fontSize: 'inherit',
}));

const BreadCrumbs = ({ breadcrumbs, className }) => {
  return (
    <StyledCrumbs className={className} separator="›" aria-label="breadcrumb">
      {breadcrumbs.map(({ label, to }) => {
        if (!to) return <Last key={label}>{label}</Last>;
        return (
          <BreadCrumbLink key={label} to={to}>
            {label}
          </BreadCrumbLink>
        );
      })}
    </StyledCrumbs>
  );
};

BreadCrumbs.propTypes = {
  breadcrumbs: PropTypes.array.isRequired,
  className: PropTypes.string,
};

export default BreadCrumbs;
