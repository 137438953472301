import React from 'react';
import { Form, Formik, Field } from 'formik';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { ColorSelector } from 'components/Forms';
import { FieldWrapper } from 'designSystem';
import { designSettingsSchema } from 'constants/schemas';
import { mergeDefaults } from 'components/Forms/utils';
import { styled } from '@mui/material/styles';
import FontsAutocomplete from 'components/Forms/FontsAutocomplete';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const FormGrid = styled(({ paddingHorizontal, ...props }) => <Grid {...props} />)(
  ({ paddingHorizontal }) => ({
    padding: paddingHorizontal ? `0 ${paddingHorizontal}px` : 0,
  })
);

const DesignForm = ({ onSubmit, design, fullWidth, paddingHorizontal, children }) => {
  const initialValues = mergeDefaults(designSettingsSchema.default(), design);

  return (
    <Formik
      initialValues={initialValues}
      validateOnBlur={true}
      validateOnChange={true}
      validationSchema={designSettingsSchema}
      onSubmit={values => onSubmit(values)}
    >
      {({ isSubmitting, submitForm }) => (
        <Form>
          <FormGrid paddingHorizontal={paddingHorizontal} container spacing={3}>
            <Grid item xs={fullWidth ? 12 : 6}>
              <Grid container spacing={3}>
                <Grid item xs={fullWidth ? 6 : 12}>
                  <FieldWrapper label="Header font">
                    <Field
                      component={FontsAutocomplete}
                      resultLimit={10}
                      fullWidth
                      name="typography.header"
                      variant="outlined"
                    />
                  </FieldWrapper>
                </Grid>
                <Grid item xs={12}>
                  <FieldWrapper label="Accent Color">
                    <Field name="palette.primary" component={ColorSelector} />
                  </FieldWrapper>
                </Grid>
              </Grid>
            </Grid>
          </FormGrid>
          {children({
            submitForm,
            isSubmitting,
          })}
        </Form>
      )}
    </Formik>
  );
};

DesignForm.defaultProps = {
  fullWidth: false,
};

DesignForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  design: PropTypes.object.isRequired,
  fullWidth: PropTypes.bool,
  paddingHorizontal: PropTypes.number,
};

export default DesignForm;
