import { styled } from '@mui/material/styles';
import { useUploadItem } from 'components/hooks';
import { useSelectable } from 'components/MediaLibrary/hooks';
import { IMAGE_PLACEHOLDER_URL } from 'constants';
import PropTypes from 'prop-types';
import React, { Fragment, memo, useState } from 'react';
import Imgix from 'react-imgix';
import ListItem from './ListItem';
import { ImageFill } from '@styled-icons/bootstrap/ImageFill';
import { MAX_MEDIA_RETRIES, MEDIA_RETRY_TIMEOUT } from 'config/mediaConfig';

const TempImageItem = styled('img')(() => ({
  width: '100%',
  objectFit: 'contain',
}));

export const ImageItem = styled(
  ({ className, onErrorLoadingImage, onLoadingSucces, isLoadingImage, ...rest }) => (
    <Imgix
      className={`${className} lazyload`}
      imgixParams={{
        q: 50,
      }}
      width={400}
      height={400}
      attributeConfig={{
        src: 'data-src',
        srcSet: 'data-srcset',
        sizes: 'data-sizes',
      }}
      htmlAttributes={{
        onError: onErrorLoadingImage,
        onLoad: onLoadingSucces,
      }}
      isLoadingImage={isLoadingImage}
      {...rest}
    />
  )
)(({ isLoadingImage }) => ({
  width: '100%',
  objectFit: 'contain',
  opacity: isLoadingImage ? 0 : 1,
}));
/* eslint-disable jsx-a11y/alt-text */

export const ImageContainer = styled('div')(() => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  width: 160,
  height: 160,
  borderRadius: 4,
  overflow: 'hidden',
}));

export const IconContainer = styled('div')(({ theme }) => ({
  borderRadius: '40px',
  backgroundColor: theme.palette.grey[200],
  weight: '3rem',
  padding: theme.spacing(0.5),
  boxShadow: theme.shadows[5],
  position: 'absolute',
  right: '-10px',
  top: '-10px',
  cursor: 'pointer',
  opacity: 0,
}));

const LoadingIconWrapper = styled('div')(({ theme }) => ({
  animation: 'enter 5s infinite',
  position: 'absolute',
  top: theme.spacing(5),
  left: 'calc(50% - 37px)',
}));

const LoadingIcon = styled('div')(({ theme }) => ({
  color: theme.palette.grey[300],
}));

const Image = ({ image }) => {
  const [retryAttempt, setRetryAttempt] = useState(0);
  const [isLoadingImage, setIsLoadingImage] = useState(false);
  const { onSelect, selectedItem } = useSelectable();
  const isSelected = selectedItem.id === image.id;
  const { url, uploadItemId, fileName } = image;
  const { tempURL, progress, success } = useUploadItem(uploadItemId);
  const backgroundImage = url || IMAGE_PLACEHOLDER_URL;
  const uploading = !success && !isNaN(progress);

  const onErrorLoadingImage = () => {
    // This triggers if the image has a url, but the specified url does not expose the image just yet.
    // Means it's most likely still uploading/processing
    // So we retry x times and pass the retry attempt to the imageItem, which rerenders it any tries to load the image again.
    if (isLoadingImage === false) {
      setIsLoadingImage(true);
    }

    if (retryAttempt < MAX_MEDIA_RETRIES) {
      setTimeout(() => {
        setRetryAttempt(retryAttempt + 1);
      }, MEDIA_RETRY_TIMEOUT);
    }
  };

  const onLoadingSucces = () => {
    setIsLoadingImage(false);
  };

  return (
    <ListItem
      selected={isSelected}
      onSelect={() => onSelect(image)}
      progress={progress}
      uploading={uploading}
      fileName={fileName}
      item={image}
      disabled={isLoadingImage}
    >
      <ImageContainer>
        <Fragment>
          {isLoadingImage && (
            <LoadingIconWrapper className={'shimmer'}>
              <LoadingIcon>
                <ImageFill size={75} />
              </LoadingIcon>
            </LoadingIconWrapper>
          )}

          {tempURL && <TempImageItem src={tempURL} />}
          {!tempURL && (
            <ImageItem
              key={`${backgroundImage}-${retryAttempt}`}
              src={backgroundImage}
              onErrorLoadingImage={onErrorLoadingImage}
              onLoadingSucces={onLoadingSucces}
              isLoadingImage={isLoadingImage}
            />
          )}
        </Fragment>
      </ImageContainer>
    </ListItem>
  );
};

Image.propTypes = {
  image: PropTypes.object,
};

export default memo(Image);
