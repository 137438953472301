import { Dialog, DialogActions, IconButton, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Close } from '@styled-icons/evaicons-solid/Close';
import { useUploadState } from 'components/hooks';
import ThemeButton from 'designSystem/Buttons/ThemeButton/ThemeButton';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { IDefaultDialogProps } from 'types/dialog.types';
import { IDocument } from 'types/document.types';
import DocumentUpload from './DocumentUpload';
import { useUploadDialogActions } from './hooks';
import { UploadProvider } from 'contexts/UploadContext';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  margin: theme.spacing(4),

  '& .MuiDialog-paperWidthXl': {
    maxHeight: 655,
    minWidth: 700,

    [theme.breakpoints.up('lg')]: {
      minWidth: '60%',
    },
  },

  '& .rdrCalendarWrapper': {
    position: 'absolute',
  },
}));

const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  marginTop: theme.spacing(1),
}));

const UploadContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(2.5, 4, 0),
}));

const CloseButton = styled(IconButton)(() => ({
  position: 'absolute',
  top: 0,
  right: 0,
  zIndex: 1,
}));

const UploadDocumentDialog: FC<IDefaultDialogProps> = ({ open, onClose }) => {
  const [selectedForDeletion, setSelectedForDeletion] = useState<string[]>([]);
  const { cleanFiles, handleCleanCreatedRecords } = useUploadState();
  const [documents, setDocuments] = useState<IDocument[]>([]);
  const { handleSaveDocuments, handleCancelEditing } = useUploadDialogActions();

  useEffect(() => () => cleanFiles(), []); // eslint-disable-line

  // Make sure that all documents have the required fields before saving
  const disableSaveButton = useMemo(
    () => !documents.every(document => document.category),
    [documents]
  );

  const resetState = () => {
    setSelectedForDeletion([]);
    setDocuments([]);
    handleCleanCreatedRecords();
    onClose?.();
    cleanFiles();
  };

  const handleDeleteDocument = (id: string) => {
    setSelectedForDeletion([...selectedForDeletion, id]);
  };

  const cancelEditing = () => {
    handleCancelEditing({
      documents,
      deletedDocuments: selectedForDeletion,
    });
    resetState();
  };

  const onSubmit = () => {
    handleSaveDocuments({
      documents,
      deletedDocuments: selectedForDeletion,
    });
    resetState();
  };

  return (
    <UploadProvider>
      <StyledDialog open={!!open} onClose={cancelEditing} fullWidth maxWidth="xl">
        <CloseButton onClick={cancelEditing} data-cy="close-dialog-button">
          <Close size={23} />
        </CloseButton>
        <UploadContainer>
          <DocumentUpload
            deletedDocuments={selectedForDeletion}
            handleDelete={handleDeleteDocument}
            title="Add new documents"
            handleDocumentsChange={setDocuments}
            documents={documents}
          />
        </UploadContainer>
        <StyledDialogActions>
          <ThemeButton color="WHITE" onClick={cancelEditing} size="large">
            Cancel
          </ThemeButton>

          <Tooltip title="Make sure that all required fields are set">
            <div>
              <ThemeButton
                color="YELLOW"
                data-cy="save-documents-btn"
                onClick={onSubmit}
                disabled={disableSaveButton}
                size="large"
              >
                Save
              </ThemeButton>
            </div>
          </Tooltip>
        </StyledDialogActions>
      </StyledDialog>
    </UploadProvider>
  );
};

export default UploadDocumentDialog;
