import { gql } from '@apollo/client';

export const IMAGE_FRAGMENT = gql`
  fragment imageValues on Image {
    attribution
    createdTimestamp
    fileName
    height
    id
    size
    uploadItemId @client
    url
    width
    path
  }
`;
