const columns = [
  {
    id: 'title',
    headerName: 'Title',
    sortable: false,
  },
  {
    id: 'language',
    headerName: 'Languages',
    sortable: false,
  },
  {
    id: 'lastEditedTimestamp',
    headerName: 'Last edited',
    sortable: true,
  },
  {
    id: 'actions',
    headerName: 'Actions',
    sortable: false,
  },
];

export default columns;
