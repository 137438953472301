import { useEffect, useState } from 'react';
import useEditorState from './useEditorState';

const useEnableReinitialize = () => {
  const { refreshing } = useEditorState();
  const [enableReinitialize, setEnableReinitialize] = useState();

  useEffect(() => {
    if (refreshing) {
      setEnableReinitialize(true);
    }

    // we want to reinitialize once when api response refreshes data
    // afterwards we want to aviod mutation responses overwriting form state
    setTimeout(() => setEnableReinitialize(false), 2000);
  }, [refreshing]);

  return {
    enableReinitialize,
  };
};

export default useEnableReinitialize;
