import { GraphQlConnection } from 'types/types';

export const removeGraphConnections = <T>(graph: GraphQlConnection<T>): T[] => {
  return graph.edges.map(({ node }) => node);
};

export const buildGraphConnections = <T>(collection: Array<T>): GraphQlConnection<T> => {
  return {
    edges: collection.map(activity => {
      return {
        node: activity,
      };
    }),
  };
};
