import { useConfig } from 'components/hooks';
import { useProduct } from 'components/Product';
import { EditorStateContextValues } from 'components/Product/MultiStep/Contexts/EditorStateContext';
import { useEffect, useMemo } from 'react';
import { useDebouncedCallback } from 'use-debounce';

interface Props {
  iframeRef: EditorStateContextValues['iframeRef'];
  activeMenuId?: string;
  lang?: string;
}

interface useConsumerPreviewReturn {
  refetchConsumerData: () => void;
  iframeUrl: string;
}

const useConsumerPreview = ({ iframeRef, activeMenuId, lang }: Props): useConsumerPreviewReturn => {
  const config = useConfig();
  const { product } = useProduct();

  const sendMessageToConsumer = (data: string): void => {
    const consumerApp = iframeRef?.current?.contentWindow;
    if (!consumerApp) return;

    consumerApp.postMessage(data, config.productURL);
  };

  /**
   * Send message to the IFrame (consumer app)
   * This will make sure the data will be re-fetched
   */
  const refetchConsumerData = useDebouncedCallback(() => sendMessageToConsumer('refetch'), 1000);

  useEffect(() => {
    if (activeMenuId) {
      sendMessageToConsumer(activeMenuId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeMenuId, iframeRef]);

  const iframeUrl: string = useMemo(
    () => config.generatePreviewConsumerURl(product?.slug, lang || product?.languageConfig.default),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [product, lang]
  );

  return {
    refetchConsumerData: refetchConsumerData.callback,
    iframeUrl,
  };
};

export default useConsumerPreview;
