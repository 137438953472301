import React, { FC } from 'react';
import { Box } from '@mui/material';
import { ProductSummaryCard } from 'components/Product/ProductTable';
import { useNavigate } from 'react-router-dom';
import { useProductActions } from 'components/hooks';
import { Plus } from '@styled-icons/bootstrap/Plus';
import { ThemeButton, CardContainer, ActionLink, ItemCardSmall } from 'designSystem';
import { Product } from 'types/types';
import { useQueryParam, BooleanParam } from 'use-query-params';
import { useConfig } from 'components/hooks';
import { AvailableSizes, BackgroundTypes } from 'types/enums';

interface INextStepLanguageProps {
  publishedProduct: Product;
  minHeight?: number;
  handleShowNextCard?: () => void;
}

const NextStepLanguage: FC<INextStepLanguageProps> = ({
  publishedProduct,
  minHeight,
  handleShowNextCard,
}) => {
  const navigate = useNavigate();
  const { goToProductAnalytics, navigateToProductOverview, navigateToProductEditor } =
    useProductActions();
  const { appQueryParams } = useConfig();
  const [, setOpenLanguageDialog] = useQueryParam<boolean | null | undefined>(
    appQueryParams.addLanguage,
    BooleanParam
  );

  const handleAddLanguage = () => {
    navigate(`/products/${publishedProduct.id}`);
    setOpenLanguageDialog(true);
  };

  return (
    <CardContainer
      title="Make your product information more accessible and publish in another language"
      background={BackgroundTypes.WORLD}
      size={AvailableSizes.MEDIUM}
      minHeight={minHeight}
    >
      <Box mt="auto" pb={2}>
        {publishedProduct && (
          <ItemCardSmall
            onCardClick={() => navigateToProductOverview(publishedProduct.id)}
            onClickEdit={() => navigateToProductEditor(publishedProduct.id)}
            onClickAnalytics={() => goToProductAnalytics(publishedProduct.id)}
          >
            <ProductSummaryCard product={publishedProduct} />
          </ItemCardSmall>
        )}
      </Box>
      <Box display="flex" alignItems="center" gap={2.5}>
        <ThemeButton
          color="YELLOW"
          size="large"
          startIcon={<Plus size={20} />}
          onClick={handleAddLanguage}
        >
          Add new language
        </ThemeButton>
        {handleShowNextCard && <ActionLink onClick={handleShowNextCard}>Next tip</ActionLink>}
      </Box>
    </CardContainer>
  );
};

export default NextStepLanguage;
