import { useApolloClient } from '@apollo/client';
import { PRODUCT_FRAGMENT } from 'graphql/fragments';
import { SET_LAST_EDITED_LANG } from 'graphql/mutations';

const useLastEditedLang = id => {
  const client = useApolloClient();
  const product = client.readFragment({
    fragment: PRODUCT_FRAGMENT,
    fragmentName: 'productValues',
    id: `Product:${id}`,
  });

  const lastEditedLang = product ? product.lastEditedLang : null;
  const lastEditedLangIsDefault = !!product && product.languageConfig.default === lastEditedLang;

  const setLastEditedLang = async (id, lang) => {
    const product = await client.mutate({
      mutation: SET_LAST_EDITED_LANG,
      variables: {
        id,
        lang,
      },
    });

    return product;
  };

  return {
    lastEditedLang,
    lastEditedLangIsDefault,
    setLastEditedLang,
  };
};

export default useLastEditedLang;
