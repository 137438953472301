import React, { FC, useState } from 'react';
import { useEudrComplianceGuide } from 'components/ComplianceGuide/EudrComplianceGuideContext';
import EudrComplianceGuideSubSection from 'components/ComplianceGuide/EudrComplianceGuideSubSection';
import { Box } from '@mui/material';
import usePartners from 'components/Partners/hooks/usePartners';
import { ErrorState } from 'components/Structure';
import { Loader } from 'components/Forms';
import PartnersSelection from 'components/ComplianceGuide/components/PartnerSelection';

const RequestPartnerSelfAssessment: FC = () => {
  const { navigateToNextSubSection, setFormData } = useEudrComplianceGuide();
  const { partners, loading, error } = usePartners();
  const [selectedPartners, setSelectedPartners] = useState<string[]>([]);

  const handleNextStepClick = () => {
    setFormData({
      partnerSelfAssessmentIds: partners.filter(({ id }) => selectedPartners.includes(id)),
    });
    navigateToNextSubSection();
  };

  if (!partners && loading) return <Loader />;
  if (error) return <ErrorState action={undefined} />;

  return (
    <EudrComplianceGuideSubSection
      allowNextStepNavigation={selectedPartners.length > 0}
      onNextStepClick={handleNextStepClick}
    >
      <Box display="flex" gap={2} flexWrap="wrap">
        <PartnersSelection
          partners={partners}
          selectedPartnerIds={selectedPartners}
          onSelect={setSelectedPartners}
        />
      </Box>
    </EudrComplianceGuideSubSection>
  );
};

export default RequestPartnerSelfAssessment;
