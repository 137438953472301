import React, { FC } from 'react';
import { Box } from '@mui/material';
import { ExternalLink } from '@styled-icons/evaicons-solid/ExternalLink';
import { useConfig } from 'components/hooks';
import { ThemeButton, CardIllustration, CardContainer, ActionLink } from 'designSystem';
import { AvailableSizes, IllustrationTypes } from 'types/enums';

interface INextStepWebshopProps {
  handleShowNextCard?: () => void;
  minHeight?: number;
  actionType?: 'BUTTON' | 'LINK';
  alignmentActions?: 'LEFT' | 'RIGHT';
}

const NextStepWebshop: FC<INextStepWebshopProps> = ({
  handleShowNextCard,
  minHeight,
  actionType = 'BUTTON',
  alignmentActions = 'LEFT',
}) => {
  const { helpDeskIntegrationURL } = useConfig();
  const onActionClick = () => window.open(helpDeskIntegrationURL, '_blank');
  const actionLabel = 'Read more';

  return (
    <CardContainer
      title="Visualize your product journeys directly in your webshop"
      size={AvailableSizes.MEDIUM}
      minHeight={minHeight}
    >
      <Box mt="auto" pb={1} display="flex" justifyContent="center">
        <CardIllustration image={IllustrationTypes.WEBSHOP} width={400} mb={1} mt={0.5} />
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent={alignmentActions === 'RIGHT' ? 'flex-end' : 'flex-start'}
        gap={2.5}
      >
        {actionType === 'BUTTON' ? (
          <ThemeButton
            color="YELLOW"
            size="large"
            startIcon={<ExternalLink size={20} />}
            onClick={onActionClick}
          >
            {actionLabel}
          </ThemeButton>
        ) : (
          <ActionLink onClick={onActionClick}>{actionLabel}</ActionLink>
        )}
        {handleShowNextCard && <ActionLink onClick={handleShowNextCard}>Next tip</ActionLink>}
      </Box>
    </CardContainer>
  );
};

export default NextStepWebshop;
