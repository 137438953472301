import { Divider, IconButton, InputAdornment, styled, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ChevronDown, ChevronUp } from '@styled-icons/bootstrap';
import { TextField } from 'formik-mui';
import PropTypes from 'prop-types';
import React from 'react';

const StyledNumberInput = styled(TextField)(({ theme }) => ({
  '& ::-webkit-inner-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0,
  },

  '& [type=number]': {
    '-moz-appearance': 'textfield',
  },

  '& .MuiInputAdornment-positionEnd': {
    marginLeft: 0,
  },

  '& .MuiOutlinedInput-input': {
    background: 'white',
    borderRadius: theme.spacing(2),
    borderColor: 'white',
  },
}));

const NumberArrows = styled('div')(({ theme }) => ({
  position: 'absolute',
  right: 0,
  top: 0,
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: 'transparent',
  borderLeftColor: theme.palette.grey[300],
  backgroundColor: theme.custom.colors.secondaryLightest,
  height: '100%',
  width: 30,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  borderTopRightRadius: 4,
  borderBottomRightRadius: 4,
  gap: 0,
}));

const StyledIconButton = styled(IconButton)(() => ({
  borderRadius: 0,
  padding: 2,
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  marginRight: theme.spacing(3),
}));

const NumberInput = ({ unit, ...props }) => {
  const { field, form } = props;

  const { name, value } = field;
  const { setFieldValue } = form;

  const theme = useTheme();

  return (
    <StyledNumberInput
      variant="outlined"
      type="number"
      {...props}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {unit && <StyledTypography>{unit}</StyledTypography>}
            <NumberArrows>
              <StyledIconButton onClick={() => setFieldValue(name, value + 1)} size="small">
                <ChevronUp size={14} color={theme.palette.secondary.main} />
              </StyledIconButton>
              <Divider />
              <StyledIconButton onClick={() => setFieldValue(name, value - 1)} size="small">
                <ChevronDown size={14} color={theme.palette.secondary.main} />
              </StyledIconButton>
            </NumberArrows>
          </InputAdornment>
        ),
      }}
    />
  );
};

NumberInput.propTypes = {
  unit: PropTypes.string,
  className: PropTypes.string,
};

export default NumberInput;
