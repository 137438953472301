import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Close } from '@styled-icons/evaicons-solid/Close';
import { ContentCopy } from '@styled-icons/material/ContentCopy';
import { useClipboard, useConfig } from 'components/hooks';
import { FieldWrapper, ThemeButton } from 'designSystem';
import React, { FC, Fragment } from 'react';
import { IDefaultDialogProps } from 'types/dialog.types';
import { Product } from 'types/types';
import PublishDialogError from './PublishDialogError';
import { QRCode, QRDownloadButton } from './QRCode';
import { HeadlinePublishDialog, SuccessTitle } from './styles';

export interface IPublishFinishedDialogProps extends IDefaultDialogProps {
  product: Product;
  lang: string;
}

const CloseButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.primary,
  position: 'absolute',
  top: theme.spacing(1),
  right: theme.spacing(1),
}));

const QRContainer = styled('div')(() => ({
  width: 120,
  height: 120,
  flexShrink: 0,
}));

const StyledDialog = styled(Dialog)<{ hasError?: boolean }>(({ theme, hasError }) => ({
  padding: theme.spacing(5, 2),

  '& .MuiPaper-root': {
    width: hasError ? 400 : 800,
    maxWidth: 800,
  },
}));

const SuccessDialogTitle = styled(DialogTitle)(() => ({
  padding: '0 !important',
}));

const SuccessInner: FC<{ product: Product; lang: string }> = ({
  product: { defaultQrCode, publicUrl, slug, title, id },
  lang,
}) => {
  const handleCopy = useClipboard('Public URL copied to clipboard');
  const config = useConfig();
  const localeUrl = config.generateProductLiveURL(slug, lang);

  return (
    <Fragment>
      <SuccessDialogTitle>
        <SuccessTitle>
          <HeadlinePublishDialog>Your product is live!</HeadlinePublishDialog>
        </SuccessTitle>
      </SuccessDialogTitle>
      <DialogContent>
        <Box display="flex" mt={2}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Box display="flex">
                <FieldWrapper
                  label="QR Code"
                  subtitle="You can save your product’s QR Code in high resolution for your prints"
                >
                  <Box mt={2}>
                    <QRDownloadButton productId={id} qrCode={defaultQrCode} title={title}>
                      Save QR Code
                    </QRDownloadButton>
                  </Box>
                </FieldWrapper>
                <QRContainer>
                  <QRCode productId={id} qrCode={defaultQrCode} />
                </QRContainer>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <FieldWrapper
                label="URL Link"
                subtitle="Use this link to share your product on social media"
              >
                <TextField disabled fullWidth name="url" value={publicUrl} variant="outlined" />
              </FieldWrapper>
              <Box mt={2} display="flex">
                <ThemeButton
                  size="medium"
                  href={localeUrl}
                  target="_blank"
                  color="YELLOW"
                  data-cy="published-product-link"
                >
                  Open Link
                </ThemeButton>
                <Box ml={1}>
                  <ThemeButton
                    onClick={() => handleCopy(publicUrl)}
                    startIcon={<ContentCopy size={14} />}
                    color="BLUE_ICE"
                    size="medium"
                  >
                    Copy Link
                  </ThemeButton>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    </Fragment>
  );
};

const PublishFinishedDialog: FC<IPublishFinishedDialogProps> = ({ onClose, product, lang }) => {
  const { hasError } = product;

  return (
    <StyledDialog hasError={hasError} open onClose={onClose}>
      <CloseButton onClick={onClose}>
        <Close size={23} />
      </CloseButton>
      {hasError ? <PublishDialogError /> : <SuccessInner product={product} lang={lang} />}
      <DialogActions>
        <ThemeButton
          color="BLUE_ICE"
          size="large"
          onClick={onClose}
          data-cy="button-close-finished-publish"
        >
          Close
        </ThemeButton>
      </DialogActions>
    </StyledDialog>
  );
};

export default PublishFinishedDialog;
