import { Box, Grid, IconButton } from '@mui/material';
import { styled, Theme } from '@mui/material/styles';
import { FieldWrapper } from 'designSystem';
import { TextField } from 'formik-mui';

export const CloseButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.primary,
  padding: 0,
  margin: 0,
}));

export const FormContainer = styled(Grid)(({ theme }) => ({
  position: 'relative',

  '&:before': {
    content: '""',
    background: theme.palette.background.default,
    position: 'absolute',
    top: -100,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 0,
  },
}));

export const TitleField = styled(TextField)(({ theme }) => ({
  flex: 1,

  '& .MuiOutlinedInput-root': {
    overflow: 'visible',
  },

  '& .MuiOutlinedInput-input': {
    borderRadius: 4,
  },

  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.divider,
    boxShadow: theme.shadows[28 as number],
  },
}));

export const CertificateFieldWrapper = styled(FieldWrapper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  gridGap: theme.spacing(2),
}));

export const ShadowedBlock = styled(Box)(({ theme }) => ({
  background: '#fff',
  borderRadius: 4,
  padding: theme.spacing(2),
  boxShadow: theme.shadows[28 as number],
}));

export const ButtonContainer = styled(Box)<{ justifyContent?: string }>(
  ({ theme, justifyContent }) => ({
    display: 'flex',
    justifyContent: justifyContent || 'flex-end',
    gridGap: theme.spacing(1),
    background: '#fff',
    marginTop: 'auto',
    padding: theme.spacing(4),
    boxShadow: theme.shadows[28 as number],
  })
);

export const PartnerFieldBlock = styled(Box)(({ theme }) => ({
  background: '#fff',
  borderRadius: 4,
  padding: theme.spacing(2),
  border: `1px solid ${theme.custom.colors.lightestBorder}`,
}));

interface ShadowedFieldBlockProps {
  theme?: Theme;
  spacing?: number;
}

export const ShadowedFieldBlock = styled(Box)<{ spacing?: number } & ShadowedFieldBlockProps>(
  ({ theme, spacing = 2.5 }) => ({
    background: '#fff',
    borderRadius: 4,
    padding: theme.spacing(spacing),
    boxShadow: theme.shadows[25 as number],
  })
);

export const OpenMapAction = styled('div')(({ theme }) => ({
  float: 'right',
  fontSize: 12,
  color: theme.custom.colors.textLight,
}));

export const LanguageDropdownContainer = styled('div')(({ theme }) => ({
  position: 'absolute',
  right: '50%',
  top: 10,
  zIndex: 3,
  margin: theme.spacing(3, 4),
  padding: '0px 8px',
  width: 150,
  background: '#fff',
  borderRadius: theme.shape.borderRadius,

  '& .MuiFormControl-root': {
    width: '100%',
  },
}));

export const DialogInner = styled(Grid)<{ minHeight?: number }>(({ minHeight }) => ({
  minHeight: minHeight === undefined ? 500 : minHeight,
}));

export const LocationMainHeightContainer = styled(Box)(({ theme }) => ({
  maxHeight: 600,
  overflow: 'auto',
  paddingTop: theme.spacing(1),

  [theme.breakpoints.up('xl')]: {
    maxHeight: 800,
  },
}));

export const LocationDetailsHeightContainer = styled(Box)(({ theme }) => ({
  maxHeight: 500,
  overflow: 'auto',
  paddingTop: theme.spacing(1),

  [theme.breakpoints.up('xl')]: {
    maxHeight: 700,
  },
}));
