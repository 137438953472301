import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field, FieldArray, useFormikContext } from 'formik';
import { Grid, Box } from '@mui/material';
import { SdgSelector, TextFieldLimited } from 'components/Forms';
import { Trash } from '@styled-icons/bootstrap/Trash';
import { ErrorMessage } from 'components/Forms';
import { Plus } from '@styled-icons/bootstrap/Plus';
import { createImpactFactDefault } from 'constants/schemas';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { FieldWrapper, ThemeTypography, ThemeButton, CardContainer } from 'designSystem';
import HTMLEditorField from 'designSystem/Formik/HTMLEditorField/HTMLEditorField';

const ImpactFactItems = ({ items }) => {
  const theme = useTheme();
  const { errors } = useFormikContext();
  const itemArrayErrors = typeof errors.impactFacts === 'string' ? errors.impactFacts : null;
  const largeScreen = useMediaQuery(theme.breakpoints.up('xl'));

  return (
    <Grid item xs={12}>
      <Box pb={1}>
        <ThemeTypography variant="BODY_LARGE_BOLD">Impact facts</ThemeTypography>
      </Box>

      <FieldArray
        name="impactFacts"
        render={arrayHelpers => (
          <Fragment>
            {itemArrayErrors && (
              <Box mb={2}>
                <ErrorMessage>{itemArrayErrors}</ErrorMessage>
              </Box>
            )}
            {items.map((impactFact, index) => (
              <CardContainer key={`impact-fact-${index}`} mb={2} alignItems="stretch">
                <Grid container spacing={3}>
                  <Grid item xs={12} xl={6}>
                    <Grid container spacing={3} direction="column">
                      <Grid item xs={12}>
                        <FieldWrapper label="Title">
                          <Field
                            component={TextFieldLimited}
                            variant="outlined"
                            placeholder="Carbon neutral"
                            fullWidth
                            name={`impactFacts[${index}].title`}
                            charLimit={30}
                            inputProps={{
                              'data-cy': 'impact-facts-title-field',
                            }}
                          />
                        </FieldWrapper>
                      </Grid>
                      <Grid item xs={12}>
                        <FieldWrapper label="Description">
                          <Field
                            component={HTMLEditorField}
                            variant="outlined"
                            placeholder="Describe how you are creating impact..."
                            fullWidth
                            multiline
                            rows={5}
                            name={`impactFacts[${index}].description`}
                            charLimit={650}
                            data-cy={`impact-facts-item-description-field-${index}`}
                          />
                        </FieldWrapper>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} xl={6}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems={largeScreen ? 'flex-end' : 'flex-start'}
                      justifyContent="space-between"
                      height="100%"
                      gap={2}
                    >
                      <FieldWrapper
                        width="100%"
                        label="Connect to the global sustainable development goals (SDGs)"
                      >
                        <Field
                          name={`impactFacts[${index}].sdgs`}
                          component={SdgSelector}
                          data-cy="impact-facts-sdg-input"
                          style={{
                            overflow: 'hidden',
                          }}
                        />
                      </FieldWrapper>
                      <Box>
                        {items.length > 1 && (
                          <ThemeButton
                            onClick={() => arrayHelpers.remove(index)}
                            color="BLUE_ICE"
                            size="medium"
                            data-cy="impact-facts-delete-button"
                            startIcon={<Trash size={14} />}
                          >
                            Delete
                          </ThemeButton>
                        )}
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </CardContainer>
            ))}

            <Box>
              <ThemeButton
                startIcon={<Plus size={14} />}
                color="BLUE_ICE"
                size="medium"
                onClick={() => arrayHelpers.push(createImpactFactDefault())}
                data-cy="impact-facts-add-button"
              >
                Add new
              </ThemeButton>
            </Box>
          </Fragment>
        )}
      />
    </Grid>
  );
};

ImpactFactItems.propTypes = {
  items: PropTypes.array.isRequired,
};

export default ImpactFactItems;
