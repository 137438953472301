import { styled } from '@mui/material';
import React, { forwardRef } from 'react';

const Container = styled('div')(() => ({
  '& p': {
    margin: 0,
  },
}));

interface Props {
  children: string;
  className?: string;
}

// eslint-disable-next-line react/display-name
const HtmlField = forwardRef<HTMLDivElement, Props>(
  ({ children, className }: Props, ref: React.ForwardedRef<HTMLDivElement>): JSX.Element => {
    return (
      <Container ref={ref} className={className} dangerouslySetInnerHTML={{ __html: children }} />
    );
  }
);

export default HtmlField;
