/* eslint-disable react-hooks/exhaustive-deps */
import { useLazyQuery } from '@apollo/client';
import { CircularProgress, InputAdornment } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Check } from '@styled-icons/entypo/Check';
import { Close } from '@styled-icons/evaicons-solid/Close';
import { useConfig } from 'components/hooks';
import { Field, useField, useFormikContext } from 'formik';
import { TextField, TextFieldProps } from 'formik-mui';
import { GET_SLUG_AVAILABILITY } from 'graphql/queries';
import debounce from 'lodash/debounce';
import React, { FC, useCallback, useEffect } from 'react';

interface ISlugFieldProps extends Omit<TextFieldProps, 'field' | 'form' | 'meta'> {
  name: string;
  dependency: string;
  allowValue?: string;
  disableAutocomplete?: boolean;
  'data-cy'?: string;
}

const StyledCheck = styled(Check)(({ theme }) => ({
  color: theme.palette.success.main,
}));

const StyledClose = styled(Close)(({ theme }) => ({
  color: theme.palette.error.main,
}));

const StyledAdornment = styled(InputAdornment)(({ theme }) => ({
  marginRight: 0,
  color: theme.palette.grey[600],
  fontWeight: 300,
}));

const StyledTextField = styled(TextField)(() => ({
  '& input': {
    paddingLeft: '0 !important',
  },
}));

const EndAdornment: FC<{ loading: boolean; slugAvailable: boolean; onClick: () => void }> = ({
  loading,
  slugAvailable,
  onClick,
}) => {
  if (loading)
    return (
      <InputAdornment position="end">
        <CircularProgress size={14} />
      </InputAdornment>
    );

  if (slugAvailable)
    return (
      <InputAdornment position="end">
        <StyledCheck size={14} />
      </InputAdornment>
    );

  if (!slugAvailable)
    return (
      <InputAdornment position="end" style={{ cursor: 'pointer' }} onClick={onClick}>
        <StyledClose size={14} />
      </InputAdornment>
    );

  return null;
};

const ERROR = 'This URL is already taken.';

const SlugField: FC<ISlugFieldProps> = ({
  dependency,
  disableAutocomplete,
  allowValue,
  ...props
}) => {
  const { name } = props;
  const { status, setStatus } = useFormikContext();
  const { productURL } = useConfig();

  const [dependentField, dependentMeta] = useField(dependency);
  const [field, meta, helper] = useField(name);

  const [checkSlug, { data = {}, loading }] = useLazyQuery(GET_SLUG_AVAILABILITY, {
    onCompleted: ({
      getSlugAvailability: {
        data: { available },
      },
    }) => {
      if (available) {
        setStatus({
          slugValidationDone: true,
          slugAvailable: true,
        });
        helper.setError(undefined);
      } else {
        setStatus({
          slugValidationDone: true,
          slugAvailable: false,
        });
        helper.setError(ERROR);
        helper.setTouched(true);
      }
    },
    fetchPolicy: 'no-cache',
  });
  const { getSlugAvailability: { data: { available = false } = {} } = {} } = data;

  const validateSlug = useCallback(
    debounce((value: string, error?: string) => {
      if ((error && error !== ERROR) || !value?.length) return;

      // In case of editing we have to allow the old value of the slug
      if (value === allowValue) {
        setStatus({
          slugValidationDone: true,
          slugAvailable: true,
        });
        return;
      }

      checkSlug({
        variables: {
          slug: value.toLowerCase(),
        },
      });
    }, 750),
    []
  );

  const setSlug = useCallback(
    debounce((value: string) => {
      const spaceRegex = new RegExp(' +', 'g');
      const removeRegex = new RegExp(/[^0-9a-zA-Z -]+/, 'g');
      const slug = value?.trim().toLowerCase().replace(removeRegex, '').replace(spaceRegex, '-');

      helper.setValue(slug);
    }, 250),
    []
  );

  useEffect(() => {
    if (disableAutocomplete || meta.touched) return;
    setSlug(dependentField.value);
  }, [dependentField.value]);

  useEffect(() => {
    if (field.value) {
      helper.setTouched(true);
    }
    validateSlug(field.value, meta.error);
  }, [field.value]);

  /**
   * The formic validator tries to override the error values since it is not empty
   */
  useEffect(() => {
    if (status.slugValidationDone) {
      helper.setError(!status.slugAvailable ? ERROR : meta.error);
    }
  }, [meta.error, status]);

  return (
    <Field
      {...props}
      InputProps={{
        startAdornment: <StyledAdornment position="start">{productURL}/</StyledAdornment>,
        endAdornment: (field.value ||
          meta.touched ||
          dependentField.value ||
          dependentMeta.touched) && (
          <EndAdornment
            loading={loading}
            slugAvailable={!meta.error && available}
            onClick={() => helper.setValue('')}
          />
        ),
      }}
      component={StyledTextField}
    />
  );
};

export default SlugField;
