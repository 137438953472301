import React, { Fragment } from 'react';
import { styled } from '@mui/material';
import { addRequestToCachePartnerOverview } from 'components/Partners/utils/partnerUtils';
import { IBasePartnerCompany, PartnerRequest } from 'types/partner.types';
import { ApolloCache } from '@apollo/client';
import PartnerDocumentList from './PartnerDocumentList';
import { IDocument } from 'types/document.types';
import { useDialog } from 'components/hooks';
import { useNavigate } from 'react-router-dom';

export const RequestIcon = styled('img')(() => ({
  width: 18,
}));

interface Props {
  totalDocuments?: number;
  documents: IDocument[];
  partner: IBasePartnerCompany;
}

const PartnerDocumentOverview: React.FC<Props> = ({ documents, partner, totalDocuments }) => {
  const navigate = useNavigate();
  const { openDialog } = useDialog();

  const handleCreateRequest = () => {
    openDialog({
      type: 'PARTNER_REQUEST',
      props: {
        requestedToCompany: partner,
        onUpdateCache: (cache: ApolloCache<unknown>, request: PartnerRequest) =>
          addRequestToCachePartnerOverview(cache, request, partner.id),
      },
    });
  };

  const handleAllDocumentsClick = () => {
    navigate(`/partners/${partner.id}/documents`);
  };

  return (
    <Fragment>
      {documents.length > 0 && (
        <PartnerDocumentList
          totalItems={totalDocuments}
          onCreateClick={handleCreateRequest}
          onAllDocumentsClick={handleAllDocumentsClick}
          documents={documents}
        />
      )}
    </Fragment>
  );
};

export default PartnerDocumentOverview;
