import { Box, DialogActions, styled } from '@mui/material';
import { mergeDefaults } from 'components/Forms/utils';
import { StyledDialogContent } from 'components/Product/Create/styles';
import { InlineHelperText } from 'components/Product/InlineHelperText/InlineHelperText';
import { useDialog } from 'components/hooks';
import { siteClusterSchema } from 'constants/schemas/sites.schema';
import { DialogDefault, ThemeButton } from 'designSystem';
import { Form, Formik, FormikProps } from 'formik';
import { ICreateSiteInput, IUpdateSiteInput } from 'graphql/mutations/types/site-mutation.types';
import isEqual from 'lodash/isEqual';
import React, { FC, useRef } from 'react';
import { ImageVariant } from 'types/media.types';
import { IDefaultDialogProps } from 'types/dialog.types';
import { IBasePartnerCompany } from 'types/partner.types';
import { IClusterSite, SiteType } from 'types/site.types';
import { Coordinates } from 'types/types';
import SiteClusterForm from './SiteClusterForm';
import useSiteMutations from './hooks/useSiteMutations';

interface IAddEditSiteClusterDialogProps extends IDefaultDialogProps {
  /** If passed its editing the existing site cluster */
  siteCluster?: IClusterSite;
  /**
   * Hide partner selector
   */
  hidePartnerSelector?: boolean;
  /**
   * Always assigns the site to this partner
   */
  partner?: IBasePartnerCompany;
  /**
   * Callback function that is called when a site is created
   */
}

export interface FormValues {
  title: string;
  image?: ImageVariant;
  partner?: IBasePartnerCompany;
  locationCoordinates: Coordinates;
  locationName: string;
  mapboxId: string;
}

const TopBackground = styled('div')(({ theme }) => ({
  '&::before': {
    content: '""',
    position: 'absolute',
    zIndex: 0,
    top: 0,
    left: 0,
    right: 0,
    height: '42%',
    background: theme.custom.colors.backgroundLight,
  },
}));

const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  padding: theme.spacing(3, 5, 4),
  justifyContent: 'space-between',
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
}));

const AddEditSiteClusterDialog: FC<IAddEditSiteClusterDialogProps> = ({
  siteCluster,
  partner,
  hidePartnerSelector,
  onClose,
  open,
}) => {
  const { createSite, updateSite } = useSiteMutations();
  const { openDialog } = useDialog();

  const isEdit = siteCluster !== undefined;

  const formRef = useRef<FormikProps<FormValues>>(null);
  const initialValues = mergeDefaults(siteClusterSchema.default(), {
    title: siteCluster?.title,
    image: siteCluster?.image,
    partner: partner || siteCluster?.ownedBy,
    locationCoordinates: siteCluster?.locationCoordinates,
    externalId: siteCluster?.externalId,
    locationName: siteCluster?.locationName,
  });

  const handleSubmit = async (values: FormValues) => {
    if (!isEdit) {
      const input: ICreateSiteInput = {
        siteType: SiteType.CLUSTER,
        title: values.title,
        image: values.image?.id ? { imageId: values.image.id } : null,
        ownedById: partner?.id || values.partner?.id,
        mapboxId: values.mapboxId,
        siteClusterInput: { siteIds: [] },
      };
      await createSite({ variables: { input } });
    } else {
      const input: IUpdateSiteInput = {
        siteType: SiteType.CLUSTER,
        title: values.title,
        image: values.image?.id ? { imageId: values.image.id } : null,
        ownedById: partner?.id || values.partner?.id || null,
        mapboxId: values.mapboxId,
        siteClusterInput: {
          siteIds: siteCluster.sites?.edges.length
            ? siteCluster.sites?.edges.map(({ node: { id } }) => id)
            : [],
        },
      };
      await updateSite({ variables: { id: siteCluster.id, input } });
    }
    onClose?.();
  };

  // If there are unsaved changes, ask the user if they want to leave the page
  const handleClose = () => {
    if (formRef.current && !isEqual(formRef.current.values, initialValues)) {
      openDialog({
        type: 'ALERT',
        props: {
          title: 'Unsaved changes',
          text: 'Are you sure you want to close this window? All unsaved changes will be lost and you will not be able to undo this action.',
          itemTitle: formRef.current?.values?.title,
          submitText: 'Close',
          displayCloseButton: true,
          onSubmit: onClose,
          onCancel: () => undefined,
        },
      });
    } else {
      onClose?.();
    }
  };

  return (
    <DialogDefault
      title={!siteCluster ? 'Add new origin cluster' : 'Edit origin cluster'}
      onClose={handleClose}
      data-cy="add-edit-cluster-dialog"
      open={open}
      fullWidth
      iconName={!siteCluster ? 'plus' : 'edit'}
      maxWidth="md"
    >
      <TopBackground />

      <Box zIndex={2}>
        <Formik
          innerRef={formRef}
          initialValues={initialValues}
          enableReinitialize
          validateOnChange
          validationSchema={siteClusterSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, isValid }) => (
            <Form>
              <StyledDialogContent>
                <Box display="flex" alignItems="center" mb={2} position="relative">
                  <InlineHelperText
                    variant="INFO"
                    helperText="An origin cluster is a group of sites at the origin that output the same component (e.g. a cluster of farms)."
                  />
                </Box>
                <SiteClusterForm hidePartnerSelector={hidePartnerSelector} />
              </StyledDialogContent>

              <Box mt={7} />
              <StyledDialogActions>
                <ThemeButton color="BLUE_ICE" size="large" onClick={handleClose}>
                  Cancel
                </ThemeButton>
                <ThemeButton
                  loading={isSubmitting}
                  disabled={!isValid}
                  color="YELLOW"
                  size="large"
                  type="submit"
                >
                  Save
                </ThemeButton>
              </StyledDialogActions>
            </Form>
          )}
        </Formik>
      </Box>
    </DialogDefault>
  );
};

export default AddEditSiteClusterDialog;
