import * as Yup from 'yup';
import theme from 'styles/theme';

export default Yup.object().shape({
  typography: Yup.object().shape({
    header: Yup.string().required().default('Lato'),
  }),
  palette: Yup.object().shape({
    primary: Yup.string().required().default(theme.palette.primary.main),
  }),
});
