import useMessages from './useMessages';
import errorCodes from 'constants/errorCodes';

const useBackendErrorMessage = () => {
  const { setErrorMessage } = useMessages();

  return (code: string, fallbackMessage: string) => {
    // @ts-ignore
    const message = code in errorCodes && errorCodes[code] ? errorCodes[code] : fallbackMessage;

    setErrorMessage(message);
  };
};

export default useBackendErrorMessage;
