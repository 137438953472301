import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from '@mui/material';
import filter from 'lodash/filter';
import { styled } from '@mui/material/styles';
import { ThemeTypography } from 'designSystem';

const StyledUl = styled('ul')(() => ({
  paddingLeft: 30,
}));

const CatalogItemThirdParties = ({ items = [] }) => {
  const firstCol = useMemo(() => filter(items, (item, index) => index % 2 === 0), [items]);
  const secondCol = useMemo(() => filter(items, (item, index) => index % 2 !== 0), [items]);

  return (
    <Box>
      <ThemeTypography variant="BODY_LARGE_BOLD">Trusted third parties</ThemeTypography>

      <Grid container>
        <Grid item xs={6}>
          <StyledUl>
            {firstCol.map((item, index) => (
              <li key={item + index}>{item}</li>
            ))}
          </StyledUl>
        </Grid>
        <Grid item xs={6}>
          <StyledUl>
            {secondCol.map((item, index) => (
              <li key={item + index}>{item}</li>
            ))}
          </StyledUl>
        </Grid>
      </Grid>
    </Box>
  );
};

CatalogItemThirdParties.propTypes = {
  items: PropTypes.array.isRequired,
};

export default CatalogItemThirdParties;
