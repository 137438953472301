import { TablePagination } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ArrowIosUpward } from '@styled-icons/evaicons-solid/ArrowIosUpward';
import { PageSubTitle } from 'components/Structure';
import { PaginationContainer, SortableTableHead, StyledTable } from 'components/TableBase';
import { appQueryParams } from 'constants/appQueryParams';
import React, { FC, Fragment, MouseEvent, ReactNode, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import { getComparator, stableSort } from 'utils';
import { headerColumns } from './constants';
import ProductTableBody from './ProductTableBody';
import { Product } from 'types/types';

interface TableAction {
  icon: ReactNode;
  tooltip: string;
  onClick: (product: Product) => void;
  dataCy?: string;
}

interface IProductTableProps {
  actions: TableAction[];
  products: Product[];
  rowsPerPage?: number;
  hideHeader?: boolean;
  archived?: boolean;
  'data-cy'?: string;
}

const Container = styled('div')(() => ({
  minWidth: 1100,
}));

const TitleWrapper = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
}));

export const UnarchiveIcon = styled('img')(({ theme }) => ({
  marginRight: theme.spacing(1),
}));

const CollapseIcon = styled(ArrowIosUpward)<{ isHidden: boolean }>(({ theme, isHidden }) => ({
  marginLeft: theme.spacing(1),
  transition: 'transform 0.5s ease',
  transform: `rotate(${isHidden ? 0 : 180}deg)`,
  color: theme.statusColor['ARCHIVED'],

  '&:hover': {
    cursor: 'pointer',
  },
}));

const ProductTable: FC<IProductTableProps> = ({
  actions,
  products,
  rowsPerPage = 6,
  hideHeader,
  archived,
  'data-cy': dataCy = '',
}) => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [isHidden, setIsHidden] = useState<boolean>(false);

  const [query, setQuery] = useQueryParams({
    [appQueryParams.order]: withDefault(StringParam, 'desc'),
    [appQueryParams.orderBy]: withDefault(StringParam, 'lastEditedTimestamp'),
  });
  const order = query[appQueryParams.order] as 'asc' | 'desc';
  const orderBy = query[appQueryParams.orderBy];

  const handleChangePage = (event: MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleSelectRow = (event: MouseEvent, id: string) => {
    event.stopPropagation();
    navigate(`/products/${id}`);
  };

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setQuery({
      [appQueryParams.order]: isAsc ? 'desc' : 'asc',
    });
    setQuery({
      [appQueryParams.orderBy]: property,
    });
  };

  const sortedProducts = useMemo(
    () => stableSort(products, getComparator(order, orderBy)),
    [order, orderBy, products]
  );

  return (
    <Container>
      {hideHeader && !!products.length && (
        <TitleWrapper>
          <PageSubTitle title="Archived Products" />
          <CollapseIcon size={20} isHidden={isHidden} onClick={() => setIsHidden(!isHidden)} />
        </TitleWrapper>
      )}
      {!isHidden && (
        <Fragment>
          <StyledTable aria-labelledby="tableTitle" aria-label="enhanced table">
            {!hideHeader && !!products.length && (
              <SortableTableHead
                onSort={handleRequestSort}
                order={order}
                orderBy={orderBy}
                headerColumns={headerColumns}
              />
            )}
            <ProductTableBody
              data-cy={dataCy}
              rowsPerPage={rowsPerPage}
              currentPage={currentPage}
              actions={actions}
              products={sortedProducts}
              onSelect={handleSelectRow}
              archived={archived}
            />
          </StyledTable>
          {products.length > rowsPerPage && (
            <PaginationContainer>
              <TablePagination
                component="div"
                rowsPerPageOptions={[]}
                count={products.length}
                rowsPerPage={rowsPerPage}
                page={currentPage}
                onPageChange={handleChangePage}
                labelDisplayedRows={({ page }) =>
                  `Page ${page + 1} of ${Math.ceil(products.length / rowsPerPage)}`
                }
              />
            </PaginationContainer>
          )}
        </Fragment>
      )}
    </Container>
  );
};

export default ProductTable;
