import { Dialog, DialogActions, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Close } from '@styled-icons/evaicons-solid/Close';
import { useConfig } from 'components/hooks';
import React, { FC } from 'react';
import PublishDialogError from './PublishDialogError';
import PublishDialogSuccess from './PublishDialogSuccess';
import { Product } from 'types/types';
import ThemeButton from 'designSystem/Buttons/ThemeButton/ThemeButton';
import { IDefaultDialogProps } from 'types/dialog.types';

const CloseIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.primary,
  position: 'absolute',
  top: theme.spacing(1),
  right: theme.spacing(1),
}));

const StyledDialog = styled(Dialog)<{ hasError: boolean | undefined }>(({ theme, hasError }) => ({
  padding: theme.spacing(5, 2),

  '& .MuiPaper-root': {
    width: hasError ? 400 : 800,
    maxWidth: 800,
  },
}));

const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  background: theme.custom.colors.backgroundLight,
}));

export interface IPublishFinishedDialogSlimVersionProps extends IDefaultDialogProps {
  product: Product;
}

const PublishFinishedDialogSlimVersion: FC<IPublishFinishedDialogSlimVersionProps> = ({
  open,
  onClose,
  product,
}) => {
  const { hasError } = product;
  const { helpDeskExternalIntegration } = useConfig();

  const openHelpDeskArticle = () => {
    window.open(helpDeskExternalIntegration, '_blank');
  };

  return (
    <StyledDialog hasError={hasError} open={!!open} onClose={onClose}>
      <CloseIconButton onClick={onClose}>
        <Close size={23} />
      </CloseIconButton>
      {hasError ? <PublishDialogError /> : <PublishDialogSuccess />}
      <StyledDialogActions>
        <ThemeButton
          color="WHITE"
          size="large"
          onClick={onClose}
          data-cy="button-close-finished-publish"
        >
          Close
        </ThemeButton>
        <ThemeButton
          onClick={openHelpDeskArticle}
          data-cy="button-close-finished-publish"
          size="large"
          color="YELLOW"
        >
          Read more
        </ThemeButton>
      </StyledDialogActions>
    </StyledDialog>
  );
};

export default PublishFinishedDialogSlimVersion;
