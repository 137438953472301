const welcomeStep = {
  content: "Hey, welcome to the editor. Let's take a quick tour to get you up to speed!",
};

const saveStep = {
  selector: '[data-tut="onboarding_tour__save"]',
  content: `The Product page editor will save your changes automatically and show you the latest saved version.`,
};

const previewStep = {
  selector: '[data-tut="onboarding_tour__preview"]',
  content: `You can preview what your product page will look like to your customers. Show the mobile preview here directly, or open preview in new tab to view the desktop version.`,
};

const reviewStep = {
  selector: '[data-tut="onboarding_tour__review"]',
  content: `To see if you are missing something in order to publish your product, you can enable the review mode.`,
};

const publishStep = {
  selector: '[data-tut="onboarding_tour__publish"]',
  content: `If you are ready, publish your product here. It will be accessible to external visitors via the QR code and link as soon as it is published.`,
};

const languageStep = {
  selector: '[data-tut="onboarding_tour__content-language"]',
  content: `Here, you can change the content language you are currently editing, or add a new language and auto-translate your product information.`,
};

const helpStep = {
  selector: '[data-tut="onboarding_tour__help"]',
  content: `If you need help, you can find more resources in our helpdesk. You can also contact us there directly. Enjoy the journey towards supply chain transparency!`,
  style: {
    left: 50,
    top: -50,
  },
};

export const fullPlanSteps = [
  welcomeStep,
  saveStep,
  previewStep,
  reviewStep,
  publishStep,
  languageStep,
  helpStep,
];
