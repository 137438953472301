import { useMutation } from '@apollo/client';
import { useDialog, useMessages } from 'components/hooks';
import {
  CREATE_ACTIVITY,
  DELETE_ACTIVITIES,
  TRANSLATE_ACTIVITY,
  UPDATE_ACTIVITY,
} from 'graphql/mutations/activity.mutations';
import {
  CREATE_PARTNER_COMPANY,
  DELETE_PARTNER_COMPANY,
  UPDATE_PARTNER_COMPANY,
} from 'graphql/mutations/partner.mutations';
import { GET_ACTIVITIES } from 'graphql/queries/activity.queries';
import { GET_ALL_PARTNER_COMPANIES, GET_PARTNER_OVERVIEW } from 'graphql/queries/partner.queries';
import { IActivity } from 'types/activity.types';
import { AvailableLanguages } from 'types/enums';
import { IBasePartnerCompany } from 'types/partner.types';
import { TranslateActivityInput } from 'types/types';
import { UpdateActivityInput } from 'graphql/mutations/types/activity-mutation.types';
import { CreateActivityInput } from 'graphql/mutations/types/activity-mutation.types';

interface Props {
  onClose?: () => void;
}

const usePartnerMutations = (props?: Props) => {
  const { setSuccessMessage, setErrorMessage } = useMessages();
  const { openDialog, closeDialog } = useDialog();

  const [deletePartners, { loading: isDeleting }] = useMutation<
    { deletePartners: { ids: string[] } },
    { ids: string[] }
  >(DELETE_PARTNER_COMPANY, {
    onCompleted: () => {
      setSuccessMessage('Partner deleted');
    },
    onError: () => setErrorMessage('There was an error deleting your partner'),
    update: (cache, { data }) => {
      const partnerData = cache.readQuery<{ partners: { edges: { node: IBasePartnerCompany }[] } }>(
        {
          query: GET_ALL_PARTNER_COMPANIES,
        }
      );
      const partners = partnerData?.partners;
      const deletedIds = new Set(data?.deletePartners.ids);
      cache.writeQuery({
        query: GET_ALL_PARTNER_COMPANIES,
        data: {
          partners: {
            ...partners,
            edges: partners?.edges.filter(edge => !deletedIds.has(edge.node.id)),
          },
        },
      });
    },
  });

  const [updatePartner, { loading: isUpdating }] = useMutation<
    { partner: IBasePartnerCompany },
    { id: string; input: Partial<IBasePartnerCompany> }
  >(UPDATE_PARTNER_COMPANY, {
    onCompleted: () => {
      setSuccessMessage('Partner successfully updated');
      props?.onClose?.();
    },
    onError: error => {
      if (
        error.graphQLErrors.length &&
        error.graphQLErrors.findIndex(
          ({ message }) => message === 'PARTNER_DUPLICATED_EXTERNAL_ID'
        ) !== -1
      ) {
        setErrorMessage(
          'The partner id already exists in the system. The id has to be unique across the platform.'
        );
      } else {
        setErrorMessage('There was an error updating your partner');
      }
    },
  });

  const [createPartner, { loading: isCreating }] = useMutation<
    { createPartner: { partner: IBasePartnerCompany } },
    { input: Omit<IBasePartnerCompany, 'id' | 'company'> & { companyId?: string } }
  >(CREATE_PARTNER_COMPANY, {
    onCompleted: () => {
      setSuccessMessage('Partner successfully created');
      props?.onClose?.();
    },
    onError: error => {
      if (
        error.graphQLErrors.length &&
        error.graphQLErrors.findIndex(
          ({ message }) => message === 'PARTNER_DUPLICATED_EXTERNAL_ID'
        ) !== -1
      ) {
        setErrorMessage(
          'The partner id already exists in the system. The id has to be unique across the platform.'
        );
      } else {
        setErrorMessage('There was an error creating your partner');
      }
    },
    update: (cache, { data }) => {
      cache.modify({
        fields: {
          partners(existingPartners = { edges: [] }) {
            return {
              ...existingPartners,
              edges: [
                { node: data?.createPartner.partner, __typename: 'PartnerEdge' },
                ...existingPartners.edges,
              ],
            };
          },
        },
      });
    },
  });

  const [createActivity] = useMutation<
    { createActivity: { activity: IActivity } },
    { input: CreateActivityInput; lang?: AvailableLanguages }
  >(CREATE_ACTIVITY, {
    onCompleted: () => {
      setSuccessMessage('Activity successfully created');
      props?.onClose?.();
    },
    onError: () => {
      setErrorMessage('There was an error creating the activity');
    },
    update: (cache, { data }) => {
      cache.modify({
        fields: {
          activities(existingActivities = { edges: [] }) {
            return {
              ...existingActivities,
              edges: [
                { node: data?.createActivity.activity, __typename: 'ActivityEdge' },
                ...existingActivities.edges,
              ],
            };
          },
        },
      });
    },
    refetchQueries: [GET_PARTNER_OVERVIEW],
  });

  const [translateActivity] = useMutation<
    { activity: IActivity },
    { id: string; input: TranslateActivityInput }
  >(TRANSLATE_ACTIVITY, {
    onError: () => setErrorMessage('There was an error translating the location'),
  });

  const [updateActivity] = useMutation<
    { updateActivity: { activity: IActivity } },
    { id: string; lang: AvailableLanguages; input: UpdateActivityInput }
  >(UPDATE_ACTIVITY);

  const [removeActivity] = useMutation<{ deleteActivities: { ids: string[] } }, { ids: string[] }>(
    DELETE_ACTIVITIES,
    {
      onCompleted: () => {
        setSuccessMessage('Activity is deleted');
      },
      onError: () => setErrorMessage('There was an error deleting the activity'),
      update: (cache, { data }) => {
        const activityData = cache.readQuery<{ activities: { edges: { node: IActivity }[] } }>({
          query: GET_ACTIVITIES,
        });
        const activities = activityData?.activities;
        const deletedIds = new Set(data?.deleteActivities.ids);
        cache.writeQuery({
          query: GET_ACTIVITIES,
          data: {
            activities: {
              ...activities,
              edges: activities?.edges.filter(edge => !deletedIds.has(edge.node.id)),
            },
          },
        });
      },
      refetchQueries: [GET_PARTNER_OVERVIEW],
    }
  );

  const handleCreatePartner = async (values: Omit<IBasePartnerCompany, 'id'>) => {
    return await createPartner({
      variables: {
        input: values,
      },
    });
  };

  const handleRemovePartner = async ({ companyId }: { companyId: string }) => {
    return await deletePartners({
      variables: {
        ids: [companyId],
      },
    });
  };

  const handleRemoveActivity = async ({
    activityId,
    alertFirst = false,
  }: {
    activityId: string;
    alertFirst?: boolean;
  }) => {
    const mutationProps = {
      variables: {
        ids: [activityId],
      },
    };

    if (alertFirst) {
      openDialog({
        type: 'ALERT',
        props: {
          title: 'Delete activity',
          text: 'Are you sure you want to delete this location? All information will be lost and you will not be able to get this information back.',
          onSubmit: async () => {
            await removeActivity(mutationProps);
          },
          submitText: 'Delete',
          onCancel: () => {
            closeDialog();
          },
        },
      });
      return;
    }

    return await removeActivity(mutationProps);
  };

  const handleCreateActivity = ({
    variables,
  }: {
    variables: { input: CreateActivityInput; lang?: AvailableLanguages };
  }) => {
    return createActivity({
      variables,
    });
  };

  const handleUpdatePartner = async (values: Partial<IBasePartnerCompany> & { id: string }) => {
    return await updatePartner({
      variables: {
        id: values.id,
        input: {
          name: values.name,
          logo: values.logo,
          externalId: values.externalId,
        },
      },
    });
  };

  const handleTranslateActivity = async (companyId: string, input: TranslateActivityInput) => {
    return await translateActivity({
      variables: {
        id: companyId,
        input,
      },
    });
  };

  return {
    handleRemovePartner,
    handleCreatePartner,
    handleUpdatePartner,
    loading: isDeleting || isCreating || isUpdating,
    createActivity: handleCreateActivity,
    handleTranslateActivity,
    updateActivity,
    handleRemoveActivity,
  };
};

export default usePartnerMutations;
