import { useApolloClient, useMutation } from '@apollo/client';
import { ProductFormValues } from 'components/Product/Create/ProductForm';
import { useConfig, useDialog, useLogEvent, useMessages } from 'components/hooks';
import { PRODUCT_FRAGMENT } from 'graphql/fragments';
import { ARCHIVE_PRODUCT, DELETE_PRODUCT, UNARCHIVE_PRODUCT } from 'graphql/mutations';
import { UPDATE_PRODUCT } from 'graphql/mutations/product.mutations';
import { useNavigate } from 'react-router-dom';
import { IBaseProduct } from 'types/product.type';
import { Product } from 'types/types';
import omit from 'lodash/omit';

const useProductActions = () => {
  const client = useApolloClient();
  const navigate = useNavigate();
  const config = useConfig();
  const { logEvent } = useLogEvent();
  const { openDialog, closeDialog } = useDialog();

  // we need to get the product for logging product related events
  const getProductFromCache = (id: string) => {
    return client.readFragment({
      fragment: PRODUCT_FRAGMENT,
      fragmentName: 'productValues',
      id: `Product:${id}`,
    });
  };

  const { setSuccessMessage, setErrorMessage } = useMessages();

  const [archiveProduct] = useMutation(ARCHIVE_PRODUCT, {
    onCompleted: ({
      archiveProduct: {
        product: { id },
      },
    }) => {
      setSuccessMessage('Success! Your product was archived. It will not be publicly available.');
      const product = getProductFromCache(id);
      logEvent('ARCHIVE_PRODUCT', {
        product_id: id,
        product_slug: product?.slug,
      });
    },
    onError: () => {
      setErrorMessage('Something went wrong archiving your product');
    },
    refetchQueries: ['GetAllProducts'],
  });

  const [deleteProduct] = useMutation(DELETE_PRODUCT, {
    onCompleted: ({ deleteProduct: { id } }) => {
      logEvent('DELETE_PRODUCT', {
        product_id: id,
      });
      setSuccessMessage('Success! Your product was deleted.');
    },
    onError: () => {
      setErrorMessage('Something went wrong archiving your product');
    },
    refetchQueries: ['GetAllProducts'],
  });

  const [unarchiveProduct] = useMutation(UNARCHIVE_PRODUCT, {
    onCompleted: ({
      unarchiveProduct: {
        product: { id },
      },
    }) => {
      setSuccessMessage(
        'Success! Your product was reactivated. Before it goes life you need to publish it again'
      );
      const product = getProductFromCache(id);
      logEvent('UNARCHIVE_PRODUCT', {
        product_id: id,
        product_slug: product?.slug,
      });
    },
    onError: () => {
      setErrorMessage('Something went wrong archiving your product');
    },
    refetchQueries: ['GetAllProducts'],
  });

  const [updateProduct] = useMutation(UPDATE_PRODUCT, {
    onCompleted: ({
      updateProduct: {
        product: { id },
      },
    }) => {
      closeDialog('CREATE_EDIT_DUPLICATE_PRODUCT');
      setSuccessMessage('Success! Your product was updated.');
      const product = getProductFromCache(id);
      logEvent('UPDATE_PRODUCT', {
        product_id: id,
        product_slug: product?.slug,
        article_number: product?.articleNumber,
      });
    },
    onError: error => {
      if (
        error.graphQLErrors.length &&
        error.graphQLErrors.findIndex(
          ({ message }) => message === 'PRODUCT_DUPLICATED_EXTERNAL_ID'
        ) !== -1
      ) {
        setErrorMessage(
          'The product id already exists in the system. The id has to be unique across the platform.'
        );
      } else {
        setErrorMessage('Something went wrong updating your product. Please try again!');
      }
    },
  });

  const handleCreateProduct = () => {
    openDialog({
      type: 'CREATE_EDIT_DUPLICATE_PRODUCT',
      props: {
        action: 'CREATE',
      },
    });
  };

  const handleDuplicateProduct = (product: IBaseProduct) => {
    openDialog({
      type: 'CREATE_EDIT_DUPLICATE_PRODUCT',
      props: {
        product,
        action: 'DUPLICATE',
      },
    });
  };

  const handleEditProduct = (product: Product) => {
    openDialog({
      type: 'CREATE_EDIT_DUPLICATE_PRODUCT',
      props: {
        product,
        action: 'EDIT',
      },
    });
  };

  const navigateToProductOverview = (productId: string) => {
    navigate(`/products/${productId}`);
  };

  const navigateToProductEditor: (productId: string, product?: { language: string }) => void = (
    productId: string,
    product
  ) => {
    const languageParam = product?.language ? `?lang=${product?.language}` : '';
    navigate(`/products/${productId}/editor${languageParam}`);
  };

  const goToProductAnalytics = (productId: string) => {
    navigate(`/products/${productId}/analytics`);
  };

  const openPreview: (product: { productId: string; slug: string; lang?: string }) => void = ({
    productId,
    slug,
    lang,
  }) => {
    window.open(config.generatePreviewURL(slug, lang), '_blank');
    logEvent('OPEN_PREVIEW', {
      productId,
    });
  };

  const openProductLive: (product: { productId: string; slug: string }) => void = ({
    productId,
    slug,
  }) => {
    window.open(config.generateProductLiveURL(slug), '_blank');
    logEvent('OPEN_LIVE', {
      productId,
    });
  };

  const handleArchiveProduct = async (id: string) => {
    await archiveProduct({
      variables: {
        id,
      },
    });
  };

  const handleDeleteProduct = async (id: string) => {
    await deleteProduct({
      variables: {
        id: id,
      },
    });
  };

  const handleUnarchiveProduct = async (id: string) => {
    await unarchiveProduct({
      variables: {
        id,
      },
    });
  };

  const handleUpdateProduct = async (id: string, values: ProductFormValues) => {
    await updateProduct({
      variables: {
        id,
        input: {
          ...omit(values, 'languageConfig'),
          defaultLanguage: values.languageConfig.default,
        },
      },
    });
  };

  return {
    handleCreateProduct,
    handleDuplicateProduct,
    navigateToProductOverview,
    navigateToProductEditor,
    goToProductAnalytics,
    openPreview,
    openProductLive,
    handleArchiveProduct,
    handleDeleteProduct,
    handleUnarchiveProduct,
    handleEditProduct,
    handleUpdateProduct,
  };
};

export default useProductActions;
