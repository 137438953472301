import { Box, IconButton, styled, Tooltip } from '@mui/material';
import React, { FC } from 'react';
import Image from 'designSystem/DataDisplay/Image/Image';
import Icon from 'designSystem/Primitives/Icon/Icon';
import { FlexBox } from 'components/Structure';
import ThemeTypography from 'designSystem/Primitives/Typography/ThemeTypography';
import { SiteLabelKeys } from 'utils/site.utils';
import { ISite, SiteType } from 'types/site.types';
import { CardContainer } from 'designSystem';
import useMeasure from 'react-use/lib/useMeasure';

interface ISiteItemProps {
  site: ISite;
  onClick?: (site: ISite) => void;
  onEdit?: (site: ISite) => void;
  onDelete?: (site: ISite) => void;
  onEditCultivationArea?: (site: ISite) => void;
}

const IconContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'end',
  width: 90,
}));

const SiteItem: FC<ISiteItemProps> = ({
  site,
  onClick,
  onEdit,
  onDelete,
  onEditCultivationArea,
}) => {
  const [ref, { width }] = useMeasure<HTMLDivElement>();

  return (
    <div ref={ref}>
      <CardContainer
        padding={1.5}
        maxWidth="100%"
        width="100%"
        onClick={() => onClick?.(site)}
        blueHover={!!onClick}
        display="flex"
      >
        <Box display="flex" alignItems="center" maxWidth="100%" width="100%">
          {!!site.image ? (
            <Image width={44} height={44} image={site.image} />
          ) : (
            <Box display="flex" bgcolor="white" borderRadius="6px" padding="4px">
              <Icon name="site-placeholder" size="xx-large" color="blue-ice" />
            </Box>
          )}
          <Box ml={1.5} width="100%">
            <FlexBox>
              <ThemeTypography variant="ITEM_TITLE">{site.title}</ThemeTypography>
              {site.externalId && (
                <Box ml={0.8}>
                  <ThemeTypography
                    variant="BODY_MEDIUM"
                    autoOverflow
                    maxWidth={150}
                    color="GRAY_80"
                  >
                    | {site.externalId}
                  </ThemeTypography>
                </Box>
              )}
            </FlexBox>
            <FlexBox width="100%" position="relative">
              <Icon name="tag-filled" size="small" color="gray-60" />
              <Box ml={0.5} />
              <ThemeTypography variant="LABEL_INPUT">
                {SiteLabelKeys[site.siteType]}
              </ThemeTypography>
              <Box ml={1} />
              <Icon name="location" size="small" color="gray-60" />
              <Box ml={0.5} />
              <ThemeTypography
                variant="LABEL_INPUT"
                autoOverflow
                minWidth={110}
                maxWidth={width - 250}
              >
                {site.locationName}
              </ThemeTypography>
            </FlexBox>
          </Box>
          <IconContainer>
            {!!onEditCultivationArea && site.siteType === SiteType.FARM && (
              <Tooltip title="Edit cultivation area" placement="top">
                <IconButton
                  aria-label="edit cultivation area"
                  onClick={event => {
                    event.stopPropagation();
                    onEditCultivationArea(site);
                  }}
                >
                  <Icon name="polygon-mapping" size="small" color="gray-100" />
                </IconButton>
              </Tooltip>
            )}
            {!!onEdit && (
              <Tooltip title="Edit" placement="top">
                <IconButton
                  aria-label="edit"
                  onClick={event => {
                    event.stopPropagation();
                    onEdit(site);
                  }}
                >
                  <Icon name="edit" size="small" color="gray-100" />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title="Delete" placement="top">
              <IconButton
                aria-label="delete"
                onClick={event => {
                  event.stopPropagation();
                  onDelete?.(site);
                }}
              >
                <Icon name="delete" size="small" color="gray-100" />
              </IconButton>
            </Tooltip>
          </IconContainer>
        </Box>
      </CardContainer>
    </div>
  );
};

export default SiteItem;
