import { DialogActions, DialogContent, List, ListItem, ListItemIcon, styled } from '@mui/material';
import { Check } from '@styled-icons/bootstrap/Check';
import ChainPreviewImage from 'assets/img/chain-preview.png';
import { useEditorState } from 'components/Product/MultiStep/hooks';
import { useProduct } from 'components/Product/hooks';
import { useConfig } from 'components/hooks';
import { DialogDefault, ThemeButton, ThemeTypography } from 'designSystem';
import React, { FC, Fragment, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { AvailableLanguages } from 'types/enums';

interface IPublishValidationDialogProps {
  loading: boolean;
  onClose: () => void;
  onPublish: () => void;
}

interface IValidFinalCheckDialogProps {
  language: AvailableLanguages | null;
  loading: boolean;
  onClose: () => void;
  onPublish: () => void;
}

const StyledCheck = styled('div')(({ theme }) => ({
  color: theme.palette.info.main,
  background: theme.palette.info.light,
  borderRadius: '50%',
}));

const InvalidContent: FC<{ onClose: (event: { showValidation: boolean }) => void }> = ({
  onClose,
}) => {
  return (
    <Fragment>
      <DialogContent data-cy="publish-validation-info-missing">
        <ThemeTypography variant="BODY_LARGE">
          Your product is missing information or some of the information you entered is invalid.
          Please check the editor for error messages.
        </ThemeTypography>
      </DialogContent>
      <DialogActions>
        <ThemeButton
          color="BLUE_ICE"
          size="large"
          onClick={() =>
            onClose({
              showValidation: true,
            })
          }
        >
          Close
        </ThemeButton>
      </DialogActions>
    </Fragment>
  );
};

interface IValidContentProps {
  loading: boolean;
  language: AvailableLanguages | null;
  onClose: () => void;
  onPublish: () => void;
}

const ValidContent: FC<IValidContentProps> = ({ onClose, onPublish, loading }) => {
  return (
    <Fragment>
      <DialogContent>
        <ThemeTypography variant="BODY_LARGE">
          Be aware that you will need to publish other languages separately. Please check the
          following list to make sure everything goes smoothly!
        </ThemeTypography>
        <List>
          <ListItem>
            <ListItemIcon>
              <StyledCheck>
                <Check size={20} />
              </StyledCheck>
            </ListItemIcon>
            <ThemeTypography variant="BODY_LARGE">
              I have checked my product in preview
            </ThemeTypography>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <StyledCheck>
                <Check size={20} />
              </StyledCheck>
            </ListItemIcon>
            <ThemeTypography variant="BODY_LARGE">
              I have made sure I have the copyrights for images and videos I used in my product
            </ThemeTypography>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <StyledCheck>
                <Check size={20} />
              </StyledCheck>
            </ListItemIcon>
            <ThemeTypography variant="BODY_LARGE">
              I have filled in all required information about my company on the settings page
            </ThemeTypography>
          </ListItem>
        </List>
      </DialogContent>
      <DialogActions>
        <ThemeButton color="BLUE_ICE" size="large" disabled={loading} onClick={onClose}>
          Cancel
        </ThemeButton>
        <ThemeButton
          color="YELLOW"
          size="large"
          loading={loading}
          onClick={onPublish}
          data-cy="dialog-button-publish"
        >
          Publish
        </ThemeButton>
      </DialogActions>
    </Fragment>
  );
};

const PublishValidationDialog: FC<IPublishValidationDialogProps> = ({
  onClose,
  onPublish,
  loading,
}) => {
  const navigate = useNavigate();
  const { latestProductVersion } = useProduct();

  // Check if every activity has at least one connection
  const supplyChainActivitiesAreConnected = useMemo(() => {
    const supplyChain = latestProductVersion?.supplyChain;
    if (!supplyChain) {
      return false;
    }
    return supplyChain.chainStepActivities.every(
      ({ id, activity: { title }, incomingLinks, pointPosition: { x } }) => {
        // Check if the activity is linked to another activity
        // Activities in the first row are considered to be the first step in the supply chain and do not need incoming links
        const hasIncomingLink = x === 0 || incomingLinks.length > 0;

        // Check if the activity is linked to another activity
        // Activities in the last row are considered to be the last step in the supply chain and do not need outgoing links
        const hasOutgoingLink =
          x === supplyChain.chainSteps.length - 1 ||
          supplyChain.chainStepActivities.findIndex(
            activity =>
              activity.incomingLinks.findIndex(link => link.chainStepActivitySourceId === id) !== -1
          ) !== -1;

        return hasIncomingLink && hasOutgoingLink;
      }
    );
  }, [latestProductVersion]);

  const { lang } = useEditorState();

  const handleGotToSupplyChainClick = () =>
    navigate(
      `/supply-chains/${latestProductVersion?.productId}/${latestProductVersion?.supplyChain?.id}/editor`
    );

  if (latestProductVersion?.versionValidation.valid && supplyChainActivitiesAreConnected) {
    return (
      <DialogDefault title="You're about to publish" open onClose={loading ? () => null : onClose}>
        <ValidContent onClose={onClose} onPublish={onPublish} loading={loading} language={lang} />
      </DialogDefault>
    );
  }

  if (!supplyChainActivitiesAreConnected) {
    return (
      <DialogDefault
        title="Connections missing in Supply chain"
        open
        onClose={loading ? () => null : onClose}
      >
        <DialogContent>
          <ThemeTypography variant="BODY_LARGE">
            The product journey can not be published. All the activities in your supply chain must
            have at least one incoming connection (except the first) and one outgoing connection
            (except the last) in order to publish.
          </ThemeTypography>
          <img src={ChainPreviewImage} alt="Chain preview" />
          <ThemeTypography variant="BODY_LARGE">
            Head over to the supply chain mapping of this product to add the missing connections.
          </ThemeTypography>
        </DialogContent>
        <DialogActions>
          <ThemeButton color="BLUE_ICE" size="large" type="button" onClick={onClose}>
            Cancel
          </ThemeButton>
          <ThemeButton color="YELLOW" size="large" onClick={handleGotToSupplyChainClick}>
            Go to supply chain mapping
          </ThemeButton>
        </DialogActions>
      </DialogDefault>
    );
  }

  return (
    <DialogDefault title={'Information missing'} open onClose={loading ? () => null : onClose}>
      <InvalidContent onClose={onClose} />
    </DialogDefault>
  );
};

const ValidFinalCheckDialog: FC<IValidFinalCheckDialogProps> = ({
  onClose,
  onPublish,
  loading,
  language,
}) => {
  const config = useConfig();
  const { title: langTitle } = config.getLanguageConfig(language);

  return (
    <DialogDefault
      open
      title={`You're about to publish ${langTitle} content`}
      onClose={loading ? () => null : onClose}
    >
      <ValidContent onClose={onClose} onPublish={onPublish} loading={loading} language={language} />
    </DialogDefault>
  );
};

export { PublishValidationDialog, ValidFinalCheckDialog };
