import { Drawer as MuiDrawer } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { FC } from 'react';

const StyledMuiDrawer = styled(MuiDrawer)<{ open: boolean }>(({ theme, open }) => ({
  flexShrink: 0,
  whiteSpace: 'nowrap',
  height: '100vh',
  width: open ? theme.custom.drawerWidth : theme.custom.drawerClosedWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  background: theme.palette.secondary.main,

  '& .MuiDrawer-paper': {
    width: open ? theme.custom.drawerWidth : `${theme.custom.drawerClosedWidth}px !important`,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    border: 'none',
    boxShadow: theme.custom.shadows[2],
    background: theme.palette.secondary.main,
    overflowX: 'hidden',
  },
}));

export interface IDrawerProps {
  open?: boolean;
  children?: React.ReactNode;
}

const Drawer: FC<IDrawerProps> = ({ open = true, children }) => {
  return (
    <StyledMuiDrawer
      variant="permanent"
      open={open}
      data-cy={open ? 'menu-is-open' : 'menu-is-closed'}
    >
      {children}
    </StyledMuiDrawer>
  );
};

export default Drawer;
