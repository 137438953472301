import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-mui';
import { Grid, DialogContent, DialogActions } from '@mui/material';
import { styled } from '@mui/material/styles';
import { DropDown } from 'components/Forms';
import { inviteUserSchema } from 'constants/schemas';
import { useConfig } from 'components/hooks';
import { FieldWrapper, ThemeTypography, ThemeButton, DialogDefault } from 'designSystem';

const StyledContent = styled(DialogContent)(() => ({
  overflow: 'visible',

  '& .MuiFormControl-root': {
    width: '100%',
  },

  '& .user-role-select': {
    width: '100%',
  },
}));

const InviteUserDialog = ({ onClose, onSubmit, prefilledEmail = '', userId, isPartner }) => {
  const { userRoles } = useConfig();
  const isReinvitation = !!prefilledEmail;

  return (
    <DialogDefault
      onClose={onClose}
      title={isReinvitation ? 'Resend invitation!' : 'Send invitation'}
    >
      <Formik
        initialValues={{
          email: prefilledEmail,
          role: 'Editor',
        }}
        validateOnBlur={false}
        validationSchema={inviteUserSchema}
        onSubmit={values => {
          const { email, role } = values;
          onSubmit({
            email,
            role: isPartner ? 'ADMIN' : role.toUpperCase(),
            id: userId,
          });
        }}
      >
        {({ isSubmitting, setFieldValue, values }) => (
          <Form>
            <StyledContent>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <ThemeTypography variant="BODY_LARGE">
                    {isReinvitation
                      ? 'Your colleague will receive a new email with a link to register in your seedtrace account.'
                      : 'Enter the email of your colleague and we will send them an invitation to join your account on seedtrace!'}
                  </ThemeTypography>
                </Grid>
                <Grid item xs={12}>
                  <FieldWrapper label="Email">
                    <Field
                      disabled={!!prefilledEmail}
                      component={TextField}
                      fullWidth
                      placeholder="joe.doe@email.com"
                      name="email"
                      variant="outlined"
                      data-cy="invite-user-mail-input"
                    />
                  </FieldWrapper>
                </Grid>
                {!isPartner && !prefilledEmail && (
                  <Grid item xs={12}>
                    <FieldWrapper label="Role">
                      <Field
                        component={DropDown}
                        fullWidth
                        name="role"
                        currentValue={values.role}
                        options={userRoles}
                        onChange={item => setFieldValue('role', item)}
                        variant="outlined"
                        className="user-role-select"
                        data-cy="user-role-selection"
                        renderItems={item => <div>{item}</div>}
                      />
                    </FieldWrapper>
                  </Grid>
                )}
              </Grid>
            </StyledContent>
            <DialogActions>
              <ThemeButton
                loading={isSubmitting}
                variant="contained"
                type="submit"
                size="large"
                color="YELLOW"
                data-cy="invite-submit-btn"
              >
                Send
              </ThemeButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </DialogDefault>
  );
};

InviteUserDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  userId: PropTypes.string,
  isPartner: PropTypes.bool.isRequired,
};

export default InviteUserDialog;
