import * as Yup from 'yup';
import { createImageSchema, optionalUrlTest } from './shared';

export default Yup.object().shape({
  image: createImageSchema({
    message: 'Please add an image of your product',
    required: true,
  }),
  title: Yup.string().label('Product title').default('').required(),
  webshopUrl: Yup.string()
    .label('Link to product in online shop')
    .test(optionalUrlTest)
    .default(''),
  __typename: Yup.string().default('ProductVersion'),
});
