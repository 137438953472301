import { Box, Tab, Tabs } from '@mui/material';
import { CustomQRCodeTab, LabelQRCodeTab } from 'components/Product/QRCode';
import React, { useState } from 'react';
import { ProductQrCodeDesign } from 'types/types';
import { styled } from '@mui/material/styles';
import { useFeatureFlags } from 'components/hooks';
import { FeatureFlag } from 'components/hooks/useFeatureFlags';

const StyledTabs = styled(Tabs)(({ theme }) => ({
  minHeight: 38,
  width: 'fit-content',
  borderBottom: `1px ${theme.custom.themeColors.grayScale[30]} solid`,

  '& .MuiTabs-indicator': {
    backgroundColor: theme.palette.secondary.main,
    height: 2,
  },
  '& .MuiTab-textColorPrimary.Mui-selected': {
    color: theme.palette.secondary.main,
  },
  '& .MuiTabs-scroller': {
    minHeight: 38,
  },
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  fontWeight: 700,
  fontSize: 12,
  color: theme.custom.themeColors.grayScale[60],
}));

interface Props {
  productId: string;
  setQREditorOpen: (open: boolean) => void;
  qrCode: ProductQrCodeDesign;
  productTitle: string;
}

const QRCodeTabs: React.FC<Props> = ({
  productId,
  setQREditorOpen,
  qrCode,
  productTitle,
}: Props) => {
  const [activeTab, setActiveTab] = useState<number>(0);
  const { isFeatureEnabled } = useFeatureFlags();

  const isCustomLabelEnabled = isFeatureEnabled(FeatureFlag.CUSTOM_LABEL_ENABLED);

  return (
    <Box mb={2}>
      <Box>
        <StyledTabs
          value={activeTab}
          textColor="primary"
          onChange={(event, tab) => setActiveTab(tab)}
        >
          <StyledTab label="Seedtrace QR label" />
          {/* TODO: In future we want to get rid of the custom qr code components */}
          {isCustomLabelEnabled && <StyledTab label="Custom QR code" data-cy="qr-code-tab" />}
        </StyledTabs>
      </Box>
      <Box mt={1.5}>
        <div role="tabpanel" hidden={activeTab !== 0}>
          {activeTab === 0 && <LabelQRCodeTab productId={productId} />}
        </div>
        <div role="tabpanel" hidden={activeTab !== 1}>
          {activeTab === 1 && (
            <CustomQRCodeTab
              productId={productId}
              qrCode={qrCode}
              setQREditorOpen={setQREditorOpen}
              productTitle={productTitle}
            />
          )}
        </div>
      </Box>
    </Box>
  );
};

export default QRCodeTabs;
