import { Box, DialogTitle, styled } from '@mui/material';
import { InfoCircle, Plus } from '@styled-icons/bootstrap';
import ComponentChainTable from 'components/ComponentsLibrary/ComponentChainTable/ComponentChainTable';
import { COMPONENT_CHAIN_COLUMN_IDS } from 'components/ComponentsLibrary/constants/headerColumns';
import useComponentChains from 'components/ComponentsLibrary/hooks/useComponentChains';
import { Loader, SearchToolbar } from 'components/Forms';
import { StyledDialogContent } from 'components/Product/Create/styles';
import { EmptyState, ErrorState, FlexBox, PageSubTitle } from 'components/Structure';
import { ThemeButton, ThemeTypography } from 'designSystem';
import { StyledDialog } from 'designSystem/Overlays/Dialog/DialogDefault/DialogDefault';
import React, { FC, useMemo, useState } from 'react';
import { IChain } from 'types/chain.types';
import { IDefaultDialogProps } from 'types/dialog.types';
import { getChainImage } from 'utils/chainTitle.utils';

interface IImportComponentChainsDialogProps {
  // Used to exclude the current chain from the list of available chains and show title and image of the current chain
  chain: Pick<IChain, 'id' | 'title' | 'image' | 'subChains' | 'productId'>;
  onImport: (selectedIds: string[]) => void;
}
const ChainImage = styled('div')<{ backgroundImage: string }>(({ backgroundImage }) => ({
  width: 32,
  height: 32,
  borderRadius: 4,
  flexShrink: 0,
  backgroundImage: `url(${backgroundImage})`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  marginRight: 8,
}));

const DialogTitleStyled = styled(DialogTitle)(() => ({
  zIndex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingRight: 40,
}));

const Dialog = styled(StyledDialog)(({ theme }) => ({
  '& .MuiDialog-paperWidthXl': {
    width: 1300,
    minHeight: 500,
  },
}));

const StyledActions = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  padding: theme.spacing(0, 4, 4),
}));

const ImportComponentChainsDialog: FC<IDefaultDialogProps & IImportComponentChainsDialogProps> = ({
  open,
  chain,
  onImport,
  onClose,
}) => {
  const importIntoChainType = chain.productId ? 'SUPPLY_CHAIN' : 'COMPONENT_CHAIN';
  const { componentChains, filteredComponentChains, loading, error, searchTerm, setSearchTerm } =
    useComponentChains({ skipUseQueryParamSearch: true });
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const handleSubmit = async () => {
    onImport(selectedIds);
    onClose?.();
  };

  const handleRowClick = (id: string) => {
    const isItemSelected = selectedIds?.includes(id);
    if (!isItemSelected) {
      setSelectedIds([...selectedIds, id]);
    } else {
      setSelectedIds(ids => ids.filter(selectedChainId => selectedChainId !== id));
    }
  };

  const visibleComponentChains = useMemo(() => {
    // Exclude the current chain and all already imported chains from the list importable chains
    const excludeComponentChainIds = [
      chain.id,
      ...chain.subChains.map(({ childChainId }) => childChainId),
    ];
    return filteredComponentChains?.filter(({ id }) => !excludeComponentChainIds.includes(id));
  }, [filteredComponentChains, chain]);

  if (loading) {
    return <Loader />;
  }
  if (error) {
    return <ErrorState />;
  }

  return (
    <Dialog open={!!open} onClose={onClose} maxWidth="xl" data-cy="import-chain-dialog">
      <DialogTitleStyled>
        <Box display="flex" alignItems="center">
          <Box mr={1}>
            <Plus size={16} />
          </Box>
          <PageSubTitle title="Import Component Chains" />
        </Box>
      </DialogTitleStyled>
      <StyledDialogContent>
        <Box display="flex" alignItems="center" mt={-1} mb={2}>
          <InfoCircle size={16} />
          <Box mr={1} />
          <ThemeTypography variant="BODY_MEDIUM">
            {importIntoChainType === 'COMPONENT_CHAIN'
              ? 'Select sub component chains that should be part of this component chain:'
              : 'Select all component chains that are part of this supply chain:'}
          </ThemeTypography>
          <FlexBox ml={1}>
            <ChainImage backgroundImage={getChainImage(chain)} />
            <ThemeTypography variant="BODY_MEDIUM_BOLD">{chain.title}</ThemeTypography>
          </FlexBox>
        </Box>
        {!!componentChains.length && (
          <Box mt={3} mb={0.5}>
            <SearchToolbar
              setSearchTerm={setSearchTerm}
              searchTerm={searchTerm || ''}
              placeholder="Search"
              className="search-input"
              data-cy="search-input-import-chain"
            />
          </Box>
        )}
        {!visibleComponentChains.length && (
          <EmptyState
            message="You have not created any component chains or all available component chains are already selected."
            data-cy="empty-state-import-chain"
          />
        )}
        <ComponentChainTable
          displayTableHeader={visibleComponentChains.length > 0}
          columnKeys={[
            COMPONENT_CHAIN_COLUMN_IDS.SELECTION,
            COMPONENT_CHAIN_COLUMN_IDS.TITLE,
            COMPONENT_CHAIN_COLUMN_IDS.OWNER,
            COMPONENT_CHAIN_COLUMN_IDS.INVOLVED_PARTNERS,
          ]}
          componentChains={visibleComponentChains}
          selectedIds={selectedIds}
          onSelectionChange={setSelectedIds}
          onRowClick={handleRowClick}
        />
      </StyledDialogContent>
      <StyledActions>
        <ThemeButton onClick={onClose} color="BLUE_ICE" size="large">
          Cancel
        </ThemeButton>
        <Box ml={2}>
          <ThemeButton
            color="YELLOW"
            size="large"
            onClick={handleSubmit}
            data-cy="submit-import-chain-btn"
          >
            Import selected
          </ThemeButton>
        </Box>
      </StyledActions>
    </Dialog>
  );
};

export default ImportComponentChainsDialog;
