import { GRAPH_MEDIUM } from 'constants/chainMapping';

const useChainMappingConfig = () => {
  // TODO: For later to have a responsive graph
  // const isSmall = useMediaQuery('(min-width: 768px)');
  // const isMedium = useMediaQuery('(min-width: 1024px)');
  // const isLarge = useMediaQuery('(min-width: 2940px)');

  return { ...GRAPH_MEDIUM, NODE_WIDTH: GRAPH_MEDIUM.COLUMN_WIDTH - GRAPH_MEDIUM.SPACING * 2 };
};

export default useChainMappingConfig;
