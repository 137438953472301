import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import ReactPlayer from 'react-player';
import { Box } from '@mui/material';
import Moment from 'react-moment';
import { useMutation } from '@apollo/client';
import { DELETE_VIDEO } from 'graphql/mutations';
import { GET_VIDEOS } from 'graphql/queries';
import { useMessages } from 'components/hooks';
import update from 'immutability-helper';
import findIndex from 'lodash/findIndex';
import { Trash } from '@styled-icons/bootstrap/Trash';
import { useSelectable } from 'components/MediaLibrary/hooks';
import { ThemeTypography, ThemeButton, AlertDialog } from 'designSystem';

const PlayerContainer = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(3),
  width: '100%',
  height: 200,
  borderRadius: 4,
  overflow: 'hidden',
}));

const Actions = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(3),
}));

const VideoDetail = ({ video: { id, fileName, fileSize, createdTimestamp, vimeoId } }) => {
  const { onUnselect } = useSelectable();
  const fileSizeInMb = (fileSize / 1e6).toFixed(2);
  const { setSuccessMessage, setErrorMessage } = useMessages();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const [deleteVideo, { loading }] = useMutation(DELETE_VIDEO, {
    onCompleted: () => {
      setSuccessMessage(`${fileName} was deleted`);
      onUnselect();
    },
    onError: () => {
      setErrorMessage('Something went wrong deleting this video.');
    },
    update: cache => {
      const { getVideos: videos } = cache.readQuery({
        query: GET_VIDEOS,
      });
      const removedIndex = findIndex(videos.edges, ['node.id', id]);
      cache.writeQuery({
        query: GET_VIDEOS,
        data: {
          getVideos: {
            edges: update(videos.edges, {
              $splice: [[removedIndex, 1]],
            }),
          },
        },
      });
    },
  });

  const handleDelete = () => {
    deleteVideo({
      variables: {
        id,
      },
    });
  };

  return (
    <Fragment>
      <Box mb={1}>
        <ThemeTypography variant="TITLE_SMALL">Video Details</ThemeTypography>
      </Box>
      <PlayerContainer>
        <ReactPlayer
          url={`https://vimeo.com/${vimeoId}`}
          controls={true}
          width="unset"
          height="100%"
        />
      </PlayerContainer>
      {fileName && <ThemeTypography variant="BODY_LARGE_BOLD">{fileName}</ThemeTypography>}
      <ThemeTypography variant="BODY_LARGE">
        <Moment format="MMMM D, YYYY">{createdTimestamp}</Moment>
      </ThemeTypography>
      <ThemeTypography variant="BODY_LARGE">{fileSizeInMb} MB</ThemeTypography>

      <Actions>
        <ThemeButton
          onClick={() => {
            setIsDeleteDialogOpen(true);
          }}
          color="BLUE_ICE"
          size="medium"
          loading={loading}
          startIcon={<Trash size={14} />}
          data-cy="video-delete-button"
        >
          Delete
        </ThemeButton>
      </Actions>

      <AlertDialog
        onClose={() => setIsDeleteDialogOpen(false)}
        title="Delete Video"
        open={isDeleteDialogOpen}
        text="Deleting this video can remove it from already published products. Are you sure you want to delete this image?"
        onSubmit={handleDelete}
        onCancel={() => setIsDeleteDialogOpen(false)}
        submitText="Delete"
        data-cy="alert-dialog"
      />
    </Fragment>
  );
};

VideoDetail.propTypes = {
  video: PropTypes.shape({
    createdTimestamp: PropTypes.string,
    fileName: PropTypes.string,
    fileSize: PropTypes.number,
  }).isRequired,
};

export default VideoDetail;
