import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React from 'react';
import Imgix from 'react-imgix';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const StyledImage = styled(({ isPlaceholder, ...props }) => <Imgix {...props} />)(
  ({ theme, isPlaceholder }) => ({
    background: '#fff',
    borderRadius: theme.shape.borderRadius,
    border: isPlaceholder ? '2px solid' : 'none',
    borderColor: theme.palette.grey[200],
    objectFit: 'contain',
  })
);

const Image = ({ src, sizes = '20vw', className = '' }) => {
  return (
    <StyledImage
      src={src || 'https://seedtrace-prd.imgix.net/defaults/product-placeholder-image-big.svg'}
      sizes={sizes}
      isPlaceholder={!src}
      className={className}
    />
  );
};

Image.propTypes = {
  src: PropTypes.string,
  sizes: PropTypes.string,
  className: PropTypes.string,
};

export default Image;
