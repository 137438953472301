import { MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React from 'react';

const Flag = styled('img')(({ theme }) => ({
  width: 18,
  marginRight: theme.spacing(0.5),
  borderRadius: 2,
}));

const Label = styled('span')(() => ({
  fontSize: 12,
  letterSpacing: 'normal',
  textTransform: 'none',
}));

const StyledMenuItem = styled(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ noPadding, hoverBackground, ...rest }) => <MenuItem {...rest} />
)(({ noPadding, hoverBackground }) => ({
  padding: noPadding ? 0 : 6,

  '&:hover': {
    background: hoverBackground || 'none',
  },
}));

const LanguageSelectorItem = ({ title, flag, value, noPadding, hoverBackground, ...rest }) => (
  <StyledMenuItem
    data-cy="language-selector-item"
    {...rest}
    value={value}
    key={value}
    noPadding={noPadding}
    hoverBackground={hoverBackground}
  >
    <Flag src={flag} />
    <Label>{title}</Label>
  </StyledMenuItem>
);

LanguageSelectorItem.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string,
  flag: PropTypes.string.isRequired,
  hoverBackground: PropTypes.string,
  noPadding: PropTypes.bool,
};

export default LanguageSelectorItem;
