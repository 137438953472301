import React from 'react';
import PropTypes from 'prop-types';
import { Card, List, ListItem, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import truncate from 'lodash/truncate';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ItemLink = styled(({ active, ...props }) => <div {...props} />)(({ theme, active }) => ({
  display: 'flex',
  position: 'relative',
  alignItems: 'center',
  cursor: 'pointer',
  color: active ? theme.palette.secondary.main : theme.palette.text.primary,
  fontWeight: active && 600,
  textDecoration: 'none',
  borderBottom: `1px solid ${theme.palette.grey[300]}`,
  paddingRight: theme.spacing(1),

  '&:last-of-type': {
    borderBottom: 'none',
  },

  '&:before': {
    position: 'absolute',
    content: '""',
    height: '100%',
    width: 4,
    background: active ? theme.palette.secondary.main : '#fff',
  },
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  padding: theme.spacing(1.5, 1),
}));

const StyledList = styled(List)(() => ({
  padding: 0,
}));

const SidebarMenu = ({ items, handleClick, activeItem }) => {
  return (
    <Card>
      <StyledList>
        {items.map(({ id, title }, index) => {
          return (
            <ItemLink
              onClick={() => handleClick(id)}
              active={id === activeItem}
              key={`${title}-${index}`}
            >
              <Tooltip title={title}>
                <StyledListItem>
                  {truncate(title, {
                    length: 17,
                    separator: '',
                  })}
                </StyledListItem>
              </Tooltip>
            </ItemLink>
          );
        })}
      </StyledList>
    </Card>
  );
};

SidebarMenu.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ),
  handleClick: PropTypes.func.isRequired,
  activeItem: PropTypes.string,
};

export default SidebarMenu;
