import { Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import React from 'react';
import {
  buildStyles,
  CircularProgressbarWithChildren as CircularProgressbar,
} from 'react-circular-progressbar';

const Number = styled('div')(() => ({
  position: 'relative',
  display: 'inline-block',
  fontSize: '2rem',
  fontWeight: 300,
  lineHeight: 1,

  '&::after': {
    content: '"%"',
    position: 'absolute',
    right: 0,
    top: '3px',
    transform: 'translateX(105%)',
    fontSize: '1rem',
  },
}));

const Info = styled(Typography)(() => ({
  fontSize: 11,
}));

const CircularProgressIndicator = ({ progress }) => {
  const {
    palette: { primary, grey },
  } = useTheme();

  return (
    <CircularProgressbar
      value={progress}
      strokeWidth={6}
      styles={buildStyles({
        pathColor: primary.main,
        trailColor: grey[200],
      })}
    >
      <div>
        <Number>{progress}</Number>
        <Info variant="subtitle2">complete</Info>
      </div>
    </CircularProgressbar>
  );
};

export default CircularProgressIndicator;
