import React from 'react';
import { Box } from '@mui/material';
import { StyledSkeleton, TitleIcon } from 'components/Partners/Skeleton/PartnerSkeleton.styles';
import partnerImage from 'assets/img/partners/partner-skeleton-image.svg';
import { ThemeTypography } from 'designSystem';

interface Props {
  title?: string;
}

const PartnerSkeleton: React.FC<Props> = ({ title }) => {
  // Dividing all the height numbers by 0.6
  // because the skeleton library is enlarging it with 60 %
  // so now the height px number is accurate: (15 / 0.6) == 15px

  return (
    <Box display="flex" alignItems="center">
      <TitleIcon src={partnerImage} />
      <Box ml={1} gap={2.5}>
        <ThemeTypography variant="BODY_LARGE_BOLD">{title}</ThemeTypography>
        {!title && <StyledSkeleton width={150} height={15 / 0.6} animation={false} />}

        <StyledSkeleton width={105} height={12 / 0.6} animation={false} />
      </Box>
      <Box ml="auto">
        <StyledSkeleton width={105} height={15 / 0.6} animation={false} />
      </Box>
    </Box>
  );
};

export default PartnerSkeleton;
