import { gql } from '@apollo/client';

export const GET_PLACES = gql`
  query GetPlaces($query: String!, $showOnlyCountry: Boolean) {
    getPlaces(query: $query, showOnlyCountry: $showOnlyCountry) @client(always: true) {
      title
      lat
      lng
      mapboxId
    }
  }
`;
