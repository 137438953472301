import React, { Fragment } from 'react';
import { Box, Link } from '@mui/material';
import { PageSubTitle } from 'components/Structure';
import { useConfig, useProductActions } from 'components/hooks';
import { Link as RouterLink } from 'react-router-dom';
import { Plus } from '@styled-icons/bootstrap/Plus';
import { ProductSummaryCard, ProductSummarySkeleton } from 'components/Product/ProductTable';
import { CardContainer, ThemeButton, ItemCardSmall } from 'designSystem';
import { Product } from 'types/types';

interface Props {
  products: Product[];
  loading?: boolean;
}

const HomeProductOverview: React.FC<Props> = ({ products, loading }) => {
  const {
    handleCreateProduct,
    goToProductAnalytics,
    navigateToProductOverview,
    navigateToProductEditor,
  } = useProductActions();
  const { homeProductsMaxLength } = useConfig();

  return (
    <Box flex={1} data-cy="home-products-overview">
      <PageSubTitle title="Products Overview" mb={2}>
        <Box display="flex" alignItems="center" gap={2.5}>
          <Link
            component={RouterLink}
            to="/products"
            color="secondary"
            className="small"
            data-cy="link-see-all-products"
          >
            See all products
          </Link>
          <ThemeButton
            onClick={handleCreateProduct}
            startIcon={<Plus size={20} />}
            size="small"
            color="WHITE"
            data-cy="button-overview-create-new"
          >
            Add new
          </ThemeButton>
        </Box>
      </PageSubTitle>
      {loading && !products?.length ? (
        <Fragment>
          {[...Array(homeProductsMaxLength).keys()].map(key => (
            <CardContainer key={key} padding={1.5} mt={1}>
              <ProductSummarySkeleton loading />
            </CardContainer>
          ))}
        </Fragment>
      ) : (
        <Box data-cy="home-product-container">
          {products.map(product => (
            <ItemCardSmall
              key={product.id}
              onCardClick={() => navigateToProductOverview(product.id)}
              onClickEdit={() => navigateToProductEditor(product.id)}
              onClickAnalytics={() => goToProductAnalytics(product.id)}
            >
              <ProductSummaryCard product={product} />
            </ItemCardSmall>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default HomeProductOverview;
