import { styled } from '@mui/material/styles';
import React, { MouseEvent, ReactElement } from 'react';
import { Booleanish, booleanish } from 'types/booleanish.types';

const Button = styled('span')<{ active?: booleanish }>(({ active, theme }) => ({
  color: active === 'true' ? theme.palette.info.main : theme.custom.colors.actionButtonHover,
  cursor: 'pointer',
  padding: theme.spacing(0.25, 0, 1, 0),
  display: 'inline-block',
}));

interface IHTMLEditorButtonProps<T> {
  label: string;
  style: T;
  active?: boolean;
  onToggle: (style: T) => void;
}

/**
 * Generic function component that renders a button for the HTML editor.
 * Use proper generic FC if available
 */
const HTMLEditorButton: <T>(props: IHTMLEditorButtonProps<T>) => ReactElement = ({
  style,
  active,
  label,
  onToggle,
}) => {
  const handleMouseDown = (event: MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    onToggle(style);
  };

  return (
    <span>
      <Button active={Booleanish(active)} onMouseDown={handleMouseDown}>
        {label}
      </Button>
    </span>
  );
};

export default HTMLEditorButton;
