import { HeaderColumn } from 'types/types';

export enum DocumentsTableColumnKeys {
  TITLE = 'title',
  OWNER = 'owner',
  USAGE = 'usage',
  ISSUE_DATE = 'issueDate',
  EXPIRY_DATE = 'expiryDate',
  ACTIONS = 'actions',
}

export const documentsHeaderColumns: HeaderColumn<DocumentsTableColumnKeys>[] = [
  { id: DocumentsTableColumnKeys.TITLE, headerName: 'Title', sortable: false },
  { id: DocumentsTableColumnKeys.OWNER, headerName: 'Owner', sortable: false },
  { id: DocumentsTableColumnKeys.USAGE, headerName: 'Usage', sortable: false },
  { id: DocumentsTableColumnKeys.ISSUE_DATE, headerName: 'Issue date', sortable: false },
  { id: DocumentsTableColumnKeys.EXPIRY_DATE, headerName: 'Expiry date', sortable: false },
  { id: DocumentsTableColumnKeys.ACTIONS, headerName: 'Actions', sortable: false },
];
