import { useMutation } from '@apollo/client';
import { Box, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Check } from '@styled-icons/bootstrap/Check';
import { ExclamationTriangle } from '@styled-icons/bootstrap/ExclamationTriangle';
import { Loader } from 'components/Forms';
import { AlertDialog } from 'designSystem';
import { useBlockNavigation, useConfig, useMessages } from 'components/hooks';
import { useProduct } from 'components/Product/hooks';
import { useConsumerPreview, useMenu } from 'components/Product/MultiStep/hooks';
import { UPDATE_PRODUCT_CONTENT_STATUS } from 'graphql/mutations';
import moment from 'moment';
import React, {
  cloneElement,
  createContext,
  FC,
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { BooleanParam, useQueryParam } from 'use-query-params';
import { AvailableLanguages } from 'types/enums';

interface Props {
  children: ReactElement | ReactElement[];
  refreshing: boolean;
}

export interface EditorStateContextValues {
  showValidation: boolean;
  setShowValidation: (newValue: boolean) => void;
  saving: boolean;
  isCurrentLanguagePublished: boolean;
  setSaving: (newValue: boolean) => void;
  refreshing: boolean;
  unsavedChanges: boolean;
  setUnsavedChanges: (newValue: boolean) => void;
  savedMessage: ReactElement | null;
  lang: AvailableLanguages;
  onCompleted: () => void;
  onError: () => void;
  iframeRef: React.MutableRefObject<null | HTMLIFrameElement>;
}

const Message = styled('span')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.grey[600],
}));

const StyledProgress = styled(CircularProgress)(({ theme }) => ({
  marginRight: theme.spacing(1),
}));

const SavingComponent = (
  <Message>
    <CircularProgress size={12} color="secondary" />
    <Box ml={1}>Saving!</Box>
  </Message>
);

const SavedComponent = (
  <Message>
    <Check size={16} /> Saved!
  </Message>
);

const SavedDelayedComponent = ({ timestamp }: { timestamp: string }) => (
  <Message>
    <Check size={16} /> Last saved today at {timestamp}
  </Message>
);

const ErrorComponent = (
  <Message>
    <ExclamationTriangle size={14} />
    &nbsp;Error
  </Message>
);

const MessagingComponent = (
  <Message>
    <StyledProgress size={12} color="secondary" /> Refreshing data
  </Message>
);

const EditorStateContext = createContext<EditorStateContextValues>({
  showValidation: false,
  setShowValidation: () => console.warn('Provider not implemented'),
  saving: false,
  setSaving: () => console.warn('Provider not implemented'),
  refreshing: false,
  unsavedChanges: false,
  setUnsavedChanges: () => console.warn('Provider not implemented'),
  savedMessage: null,
  lang: AvailableLanguages.ENGLISH,
  isCurrentLanguagePublished: false,
  onCompleted: () => console.warn('Provider not implemented'),
  onError: () => console.warn('Provider not implemented'),
  iframeRef: { current: null },
});

const EditorStateProvider: FC<Props> = ({ children, refreshing }) => {
  const { appQueryParams } = useConfig();
  const [showValidation, setShowValidation] = useQueryParam(
    appQueryParams.reviewMode,
    BooleanParam
  );
  const { product, currentProductId } = useProduct();
  const [lang = product?.languageConfig.default] = useQueryParam<AvailableLanguages>(
    appQueryParams.lang
  );
  const iframeRef = useRef(null);
  const { refetchConsumerData } = useConsumerPreview({ iframeRef });

  const { setErrorMessage } = useMessages();
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const { contentStatus }: { contentStatus: string } = useMenu();

  const [updateProductContentStatus] = useMutation(UPDATE_PRODUCT_CONTENT_STATUS);

  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(false);
  const [completed, setCompleted] = useState<ReactElement | null>(null);

  const onCompleted = () => {
    refetchConsumerData();
    setUnsavedChanges(false);
    setCompleted(SavedComponent);
    const formattedTimestamp = moment().local().format('HH:mm');

    const Component = cloneElement(
      <SavedDelayedComponent timestamp={formattedTimestamp} />,
      {
        timestamp: formattedTimestamp,
      },
      null
    );

    setTimeout(() => setCompleted(Component), 3000);
  };

  const onError = () => {
    setError(true);
    setErrorMessage('There was an error saving your product.');
  };

  const savedMessage = useMemo(() => {
    if (refreshing) return MessagingComponent;
    if (saving) return SavingComponent;
    if (error) return ErrorComponent;
    if (unsavedChanges) return <Message>Unsaved changes</Message>;
    if (completed) return completed;

    return <Message>No unsaved changes</Message>;
  }, [saving, unsavedChanges, completed, refreshing, error]);

  const { navigationBlocked } = useBlockNavigation({
    shouldBlock: saving || unsavedChanges,
  });

  useEffect(() => {
    if (refreshing) setCompleted(null);
  }, [refreshing]);

  // contentStatus will only update once successful save response comes back from backend
  useEffect(() => {
    // content status is only displayed for default language in rest of the app
    // so we need to avoid overwriting status when another language is selected
    if (!!completed) {
      updateProductContentStatus({
        variables: {
          id: currentProductId,
          contentStatus,
          lang,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentStatus, !!completed]);

  return (
    <EditorStateContext.Provider
      value={{
        showValidation: showValidation || false,
        setShowValidation,
        saving,
        setSaving,
        refreshing,
        unsavedChanges,
        setUnsavedChanges,
        savedMessage,
        lang: lang || AvailableLanguages.ENGLISH,
        onCompleted,
        onError,
        iframeRef,
        isCurrentLanguagePublished: !!(lang && product?.publishedLanguages.includes(lang)),
      }}
    >
      <AlertDialog
        open={navigationBlocked}
        title="Saving latest changes"
        text={
          <Box marginY={4}>
            <Loader size={22} />
          </Box>
        }
        onClose={() => undefined}
        onCancel={undefined}
        onSubmit={undefined}
        preventCloseAfterSubmit={undefined}
        displayCloseButton={undefined}
      />
      {children}
    </EditorStateContext.Provider>
  );
};

export { EditorStateProvider, EditorStateContext };

export default EditorStateContext;
