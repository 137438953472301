import React, { FC, Fragment } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useWelcomeSlides } from 'components/WelcomeSlides/hooks';
import line from 'assets/img/welcomeSlides/assistance-slide-line3.svg';
import { useCompanyPlanContext } from 'contexts/CompanyPlanContext';
import { CustomerPlan } from 'types/company.types';

const StyledBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}));

const StyledCarousel = styled(Carousel)(() => ({
  width: '100%',
  height: '100%',
  margin: 0,
  padding: 0,

  '& .slide': {
    textAlign: 'left',
  },
}));

const SlideContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: 600,
}));

const Line = styled('img')(({ theme }) => ({
  display: 'block',
  position: 'fixed',
  right: 40,
  width: 800,
  bottom: 120,
  zIndex: -1,

  [theme.breakpoints.up('xl')]: {
    width: 900,
  },
}));

const WelcomeSlidesCarousel: FC = () => {
  const { currentSlide, slides, setCurrentSlide } = useWelcomeSlides();
  const { plan } = useCompanyPlanContext();
  const isPartner = plan === CustomerPlan.FREEMIUM;

  const displayYellowLine = isPartner ? currentSlide === 1 : currentSlide === 6;

  return (
    <StyledBox>
      {displayYellowLine && (
        <Fragment>
          <Line src={line} />
        </Fragment>
      )}
      <StyledCarousel
        showArrows={false}
        showThumbs={false}
        showStatus={false}
        showIndicators={false}
        transitionTime={0}
        selectedItem={currentSlide}
        onChange={index => setCurrentSlide(index)}
      >
        {slides.map((Slide, index) => (
          <SlideContainer key={index}>
            <Slide active={index === currentSlide} />
          </SlideContainer>
        ))}
      </StyledCarousel>
    </StyledBox>
  );
};

export default WelcomeSlidesCarousel;
