import FontIconPicker from '@fonticonpicker/react-fonticonpicker';
import { FormHelperText, styled } from '@mui/material';
import { grey as sdgIcons } from 'assets/sdgs';
import { getIn, useField, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

const renderSVG = sdgIndex => {
  const Icon = sdgIcons[sdgIndex];

  return <Icon />;
};

const StyledPicker = styled('div')({
  '& .rfipicons__ibox svg': {
    maxWidth: '27px',
  },

  '& .rfip': {
    margin: 0,
  },

  '& .rfipbtn--bluegrey': {
    border: '2px solid rgba(0, 0, 0, 0.23)',
  },

  '& .rfipbtn': {
    minHeight: 46,
  },
});

const SdgPickerField = props => {
  const { setFieldValue, errors, touched } = useFormikContext();
  const { name } = props;
  const [, meta] = useField({
    ...props,
  }); // eslint-disable-line
  const selectedSdgs = meta.value;
  const icons = useMemo(() => sdgIcons.map((icon, index) => index), []);

  const handleChange = sdg => {
    setFieldValue(name, sdg);
  };
  const pickerValue = selectedSdgs.map(sdg => icons[sdg]);
  const fieldError = getIn(errors, name);
  const showError = getIn(touched, name) && typeof fieldError === 'string';

  return (
    <StyledPicker>
      <FontIconPicker
        showSearch={false}
        noSelectedPlaceholder="Select SDGs"
        icons={icons}
        value={pickerValue}
        onChange={handleChange}
        renderFunc={renderSVG}
        theme="bluegrey"
        isMulti
      />
      {showError && <FormHelperText error>{fieldError}</FormHelperText>}
    </StyledPicker>
  );
};

SdgPickerField.propTypes = {
  name: PropTypes.string.isRequired,
};

export default SdgPickerField;
