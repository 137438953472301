import * as Yup from 'yup';

export const hexLengthTest = {
  name: 'hex-length-test',
  test: function (value) {
    if (value.length === 7) return true;

    return this.createError({
      message: `Please enter a 6 digit hex code like #000000`,
    });
  },
};

export default Yup.object().shape({
  qr_code_rotation: Yup.string().nullable().default(''),
  background_color: Yup.string().nullable().test(hexLengthTest).default('#ffffff'),
  qr_code_pattern: Yup.string().nullable().default(''),
  foreground_color: Yup.string().nullable().test(hexLengthTest).default('#000000'),
  marker_bottom_template: Yup.string().default('version1'),
  marker_bottom_inner_color: Yup.string().test(hexLengthTest).default('#000000'),
  marker_bottom_outer_color: Yup.string().test(hexLengthTest).default('#000000'),
  marker_left_template: Yup.string().default('version1'),
  marker_left_inner_color: Yup.string().test(hexLengthTest).default('#000000'),
  marker_left_outer_color: Yup.string().test(hexLengthTest).default('#000000'),
  marker_right_template: Yup.string().default('version1'),
  marker_right_inner_color: Yup.string().test(hexLengthTest).default('#000000'),
  marker_right_outer_color: Yup.string().test(hexLengthTest).default('#000000'),
  qr_code_logo: Yup.string().nullable().default('no-logo'),
});
