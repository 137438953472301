import { useQuery } from '@apollo/client';
import { ChainMappingEvent } from 'components/ChainMapping/utils/mapping.types';
import {
  getActivitiesInBoundsOfSteps,
  getStepFromXPosition,
  isInBoundsOfSubChain,
} from 'components/ChainMapping/utils/mapping.utils';
import { useDialog, useLogEvent } from 'components/hooks';
import { GET_CHAIN, GET_ONBOARDING_CHAIN } from 'graphql/queries/chain.queries';
import omit from 'lodash/omit';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IChain, IChainResponse, IChainStep, IOnboardingChainResponse } from 'types/chain.types';
import useSupplyChainActions from './useSupplyChainActions';
import useSupplyChainOnboardingSteps from './useSupplyChainOnboardingSteps';

const useSupplyChainMapping = () => {
  const { logEvent } = useLogEvent();
  const { openDialog } = useDialog();
  // Id of the supply chain
  const { chainId } = useParams<{ chainId: string }>() as { chainId: string };
  const navigate = useNavigate();
  const {
    loading: mutationLoading,
    createSteps,
    deleteStep,
    importSubChains,
    updateStep,
    createActivity,
    updateActivity,
    deleteActivity,
    reorderSubChains,
    removeSubChain,
    initialiseChainMapping,
    createSubChainFromStepActivities,
  } = useSupplyChainActions();

  const { displayOnboardingTour, onboardingTourStep, handleStartOnboardingTour } =
    useSupplyChainOnboardingSteps();

  const { data, loading, error } = useQuery<IChainResponse>(GET_CHAIN, {
    variables: { id: chainId },
    skip: !chainId || !!displayOnboardingTour,
    /**
     * Issue TEC-1881: Fetch policy is set to 'no-cache' to ensure that the component chains are always correctly positioned in the chain.
     * Since the same ChainActivities can be used in multiple chains, with different positions, the cache can cause the chains to be displayed incorrectly.
     */
    fetchPolicy: 'no-cache',
  });

  const {
    data: dataOnboarding,
    loading: loadingOnboarding,
    error: errorOnboarding,
  } = useQuery<IOnboardingChainResponse>(GET_ONBOARDING_CHAIN, {
    variables: { id: chainId, onboardingStep: onboardingTourStep },
    skip: !chainId || !displayOnboardingTour,
  });
  const [expandList, setExpandList] = useState<boolean>(false);

  const supplyChain: IChain | undefined = data ? data.chain : undefined;

  const onboardingSupplyChain: IChain | undefined = dataOnboarding
    ? dataOnboarding.onboardingChain
    : undefined;

  const handleChainMappingAction = (event: ChainMappingEvent) => {
    if (!supplyChain) {
      // eslint-disable-next-line no-console
      console.error('Current supply chain is not defined');
      return;
    }
    switch (event.type) {
      case 'ADD_ACTIVITY': {
        const selectedStepId = supplyChain.chainSteps.find(({ id }) => id === event.stepId)?.id;
        if (!selectedStepId) {
          // eslint-disable-next-line no-console
          console.error('Step not found');
          break;
        }
        openDialog({
          type: 'ACTIVITY_SELECT_DECISION',
          props: {
            selectedStepId,
            steps: supplyChain.chainSteps,
            activities: supplyChain.chainStepActivities,
            subChains: supplyChain.subChains,
            onActivityAdd: (activityId, activity) => {
              createActivity({
                variables: {
                  input: {
                    activityId: activityId,
                    chainStepId: selectedStepId,
                    incomingLinks: activity?.incomingLinks || [],
                  },
                },
              });
            },
          },
        });
        break;
      }
      case 'EDIT_ACTIVITY':
        const activity = supplyChain.chainStepActivities.find(
          activity => activity.id === event.elementId
        );
        if (!activity) {
          // eslint-disable-next-line no-console
          console.error('Activity not found');
          return;
        }
        openDialog({
          type: 'ADD_EDIT_ACTIVITY',
          props: {
            mode: 'edit-chain-activity',
            chainStepActivity: activity,
            steps: supplyChain.chainSteps,
            activities: supplyChain.chainStepActivities,
            subChains: supplyChain.subChains,
            onActivityEdit: activity =>
              updateActivity({
                variables: {
                  id: activity.id,
                  input: {
                    chainStepId: activity.chainStepId,
                    incomingLinks: activity.incomingLinks,
                  },
                },
              }),
            onActivityDelete: activityId => deleteActivity({ variables: { id: activityId } }),
          },
        });
        break;
      case 'ADD_STEPS':
        openDialog({
          type: 'ADD_STEPS',
          props: {
            chainType: 'COMPONENT_CHAIN',
            steps: supplyChain.chainSteps,
            addStepAtIndex: event.targetIndex,
            onAddSteps: steps =>
              createSteps({
                variables: {
                  input: {
                    chainId: chainId,
                    steps: steps.map(step => omit(step, 'isDeletable')),
                  },
                },
              }),
          },
        });
        break;
      case 'EDIT_STEP': {
        const selectedStep = supplyChain.chainSteps.find(({ id }) => id === event.elementId);
        if (!selectedStep) {
          // eslint-disable-next-line no-console
          console.error('Step not found');
          break;
        }
        openDialog({
          type: 'EDIT_STEP',
          props: {
            steps: supplyChain.chainSteps,
            editStepId: event.elementId,
            isDeletable: selectedStep.isDeletable,
            hasActivities: !!getActivitiesInBoundsOfSteps(
              event.elementId,
              supplyChain.chainStepActivities
            ).length,
            onDeleteStep: (stepId: string) => deleteStep({ variables: { id: stepId } }),
            onEditStep: (step: IChainStep) =>
              updateStep({ variables: { input: { id: step.id, title: step.title } } }),
          },
        });
        break;
      }
      case 'IMPORT':
        openDialog({
          type: 'IMPORT_COMPONENT_CHAINS',
          props: {
            // Exclude the sub chain that were already imported into the chain
            chain: supplyChain,
            onImport: (importChainIds: string[]) =>
              importSubChains({
                variables: { id: chainId, importChainIds },
              }),
          },
        });
        break;
      case 'REMOVE_SUB_CHAIN':
        const subChain = supplyChain.subChains.find(({ id }) => id === event.elementId);
        openDialog({
          type: 'ALERT',
          props: {
            title: 'Remove component chain',
            itemTitle: subChain?.title,
            text: 'Are you sure you want to remove this component chain from this chain? The component chain will remain in your component library and can be imported again.',
            submitText: 'Remove',
            onCancel: () => undefined,
            onSubmit: () => {
              removeSubChain({
                variables: { id: event.elementId },
              });
            },
          },
        });
        break;
      case 'REORDER':
        const subChainIdsOrdered = event.subChainOrder;
        reorderSubChains({ variables: { id: chainId, subChainIdsOrdered } });
        break;
      case 'EDIT_SUB_CHAIN': {
        const subChain = supplyChain.subChains.find(({ id }) => id === event.elementId);
        if (!subChain) {
          // eslint-disable-next-line no-console
          console.error('SubChain not found');
          break;
        }
        navigate(`/component-chains/${subChain.childChainId}/editor`);
        break;
      }
      case 'ADD_SUB_CHAIN_FROM_ACTIVITY': {
        // Backend expects an array of all included step title and their activity ids
        // Ids of sub chain step activities need to be excluded from the array
        const pointPositionToStepActivityMap = new Map<number, string[]>();
        supplyChain.chainStepActivities
          .filter(({ id }) => event.stepActivityIds.includes(id))
          .forEach(({ id, pointPosition }) => {
            const isSubChainStepActivity = supplyChain.subChains.some(({ boundingBoxPosition }) =>
              isInBoundsOfSubChain(boundingBoxPosition, pointPosition)
            );
            if (pointPositionToStepActivityMap.has(pointPosition.x)) {
              // Skip adding the activity id if it is a sub chain step activity
              if (!isSubChainStepActivity) {
                pointPositionToStepActivityMap.get(pointPosition.x)?.push(id);
              }
            } else {
              // Add empty array if it is a sub chain step activity
              pointPositionToStepActivityMap.set(
                pointPosition.x,
                isSubChainStepActivity ? [] : [id]
              );
            }
          });
        const stepsActivities = Array.from(pointPositionToStepActivityMap).map(
          ([xIndex, stepActivityIds]) => ({
            stepActivityIds,
            stepTitle: getStepFromXPosition(xIndex, supplyChain.chainSteps)?.title || 'Unknown', // In the rare case that the step is not found the title will be 'Unknown' and produce a backend error
          })
        );
        createSubChainFromStepActivities({
          variables: {
            input: { chainId, stepsActivities, subChainIds: event.subChainIds },
          },
        });
        break;
      }
      case 'OPEN_SITE_CLUSTER': {
        // TODO: We need a general sites route or add the ownership here
        navigate(`/sites/external/${event.elementId}`);
        break;
      }
    }
  };

  const handleCreateByCopyChainClick = () => {
    openDialog({
      type: 'COPY_CHAIN',
      props: { chainId: chainId, productVersionId: supplyChain?.productId },
    });
  };

  const handleCreateNewChainClick = () => {
    initialiseChainMapping({ variables: { id: chainId } });
  };

  useEffect(() => {
    if (chainId) {
      logEvent('VISIT_SUPPLY_CHAIN_MAPPING', { chainId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainId]);

  return {
    queryLoading: loading || loadingOnboarding,
    mutationLoading,
    supplyChain: displayOnboardingTour ? onboardingSupplyChain : supplyChain,
    error: error || errorOnboarding,
    /** if onboarding is active and we are on step 8, we want to expand the list to demonstrate the step by step list */
    expandList: !!(expandList || (displayOnboardingTour && onboardingTourStep === 8)),
    handleChainMappingAction,
    handleCreateNewChainClick,
    handleCreateByCopyChainClick,
    handleExpandList: setExpandList,
    handleClickOnboardingTour: handleStartOnboardingTour,
  };
};

export default useSupplyChainMapping;
