import React, { FC, useEffect, useMemo } from 'react';
import usePageTitle from '../components/hooks/usePageTitle';
import { PageTitle, PageContainer, ErrorState, InfiniteScrollWrapper } from 'components/Structure';
import { styled, Box } from '@mui/material';
import NumberContainer from 'components/Payments/NumberContainer';
import { PaymentsConnection } from 'types/payment.types';
import PaymentAccordion from 'components/Payments/PaymentAccordion';
import Breadcrumbs from 'components/Payments/Breadcrumbs';
import Icon from 'designSystem/Primitives/Icon/Icon';
import { ThemeTypography } from 'designSystem';
import { useQuery } from '@apollo/client';
import { GET_PAYMENTS } from 'graphql/queries/payment.queries';
import { Loader } from 'components/Forms';

const NumbersContainer = styled(Box)(({ theme }) => ({
  margin: theme.spacing(3, 0),
  display: 'flex',
  gap: theme.spacing(2),
  padding: theme.spacing(3),
  borderRadius: 6,
  border: `1px solid ${theme.custom.themeColors.grayScale[20]}`,
  background: theme.custom.themeColors.white,
  flexWrap: 'wrap',
}));

const TransactionsContainer = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.custom.themeColors.grayScale[20]}`,
  background: theme.custom.themeColors.white,
  padding: theme.spacing(8.5, 3, 3),
  borderRadius: 6,
  position: 'relative',
}));

const BreadcrumbsContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(2),
  left: 0,
}));

const PaymentsList = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}));

export const StyledInfiniteScrollWrapper = styled(InfiniteScrollWrapper)(({ theme }) => ({
  margin: theme.spacing(-2, -2),
  padding: theme.spacing(2, 2),
  overflow: 'auto',
}));

const Payments: FC = () => {
  usePageTitle('Payments');

  const { data, loading, error, fetchMore } = useQuery<{ payments: PaymentsConnection }>(
    GET_PAYMENTS,
    {
      variables: {
        first: 15,
      },
    }
  );

  const payments = useMemo(() => {
    const nodeDocuments = data?.payments?.edges || [];
    return nodeDocuments.map(item => item.node);
  }, [data]);

  const summary = data?.payments?.summary;

  const hasNextPage: boolean =
    !!data?.payments?.pageInfo?.hasNextPage && data?.payments?.edges?.length === payments.length;

  const endCursor: string | undefined = data?.payments?.pageInfo?.endCursor;

  const handlePageEndReached = () => {
    if (endCursor) {
      fetchMore({
        variables: {
          after: endCursor,
        },
      });
    }
  };

  useEffect(() => {
    if (!payments?.length && hasNextPage && endCursor) {
      fetchMore({
        variables: {
          after: endCursor,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payments]);

  if (error) {
    return <ErrorState />;
  }

  if (loading || !payments || !summary) {
    return <Loader />;
  }

  return (
    <PageContainer>
      <PageTitle title="Payments" />
      <NumbersContainer>
        <NumberContainer number={summary.totalPaid} text="Total paid to farmers" currency="₵" />
        <NumberContainer number={summary.totalPremiumPaid} text="Total premium paid" currency="₵" />
        <NumberContainer
          number={data.payments.summary.averagePayPerPayee}
          text="Average payment per worker annually"
          currency="₵"
        />
      </NumbersContainer>
      <TransactionsContainer>
        <BreadcrumbsContainer>
          <Breadcrumbs breadcrumbs={['Origin Transactions']} />
        </BreadcrumbsContainer>
        <Box display="flex" gap={2} mb={2}>
          <Box display="flex" gap={1} alignItems="center">
            <Icon name="farm" />
            <ThemeTypography variant="BODY_SMALL" color="ALMOST_BLACK">
              {summary.farmCount} farms
            </ThemeTypography>
          </Box>
          <Box display="flex" gap={1} alignItems="center">
            <Icon name="workers" />
            <ThemeTypography variant="BODY_SMALL" color="ALMOST_BLACK">
              {summary.payeeCount} workers
            </ThemeTypography>
          </Box>
          <Box display="flex" gap={1} alignItems="center">
            <Icon name="payments" />
            <ThemeTypography variant="BODY_SMALL" color="ALMOST_BLACK">
              {summary.paymentCount} payments
            </ThemeTypography>
          </Box>
        </Box>
        <StyledInfiniteScrollWrapper hasMore={hasNextPage} next={handlePageEndReached}>
          <PaymentsList>
            {payments.map(payment => {
              return <PaymentAccordion key={payment.blockchainId} payment={payment} />;
            })}
          </PaymentsList>
        </StyledInfiniteScrollWrapper>
      </TransactionsContainer>
    </PageContainer>
  );
};

export default Payments;
