import React, { FC } from 'react';
import { styled } from '@mui/material/styles';
import { Fade, Zoom, Box } from '@mui/material';
import { TextControlBar } from 'components/WelcomeSlides';
import {
  PageContainer,
  Headline,
  Item,
  ItemIcon,
  ItemIconContainer,
  ItemText,
  ContentContainer,
  ImageContainer,
} from './styles';
import pulseLine from 'assets/img/welcomeSlides/pulse-line-3.svg';
import { ThemeTypography } from 'designSystem';
import magnifier from 'assets/img/welcomeSlides/icon_magnifier.svg';
import AnalyticsImage from 'assets/img/welcomeSlides/analytics.png';

interface IAnalyticsProps {
  active: boolean;
}

const StyledPulse = styled('img')(({ theme }) => ({
  position: 'absolute',
  left: theme.spacing(0),
  width: '380px !important',
  bottom: 50,
}));

const Image = styled('img')(() => ({
  width: '380px !important',
}));

const Analytics: FC<IAnalyticsProps> = ({ active }) => {
  return (
    <>
      <PageContainer>
        <Zoom in={active}>
          <ContentContainer>
            <Box mb={1.5}>
              <Item>
                <ItemIconContainer>
                  <ItemIcon width={26} src={magnifier} alt="magnifier" />
                </ItemIconContainer>
                <ItemText
                  dangerouslySetInnerHTML={{ __html: 'Learn more about <br /> your customer' }}
                />
              </Item>
            </Box>
            <Headline variant="TITLE_LARGE">Leverage analytics</Headline>
            <ThemeTypography variant="TITLE_EXTRA_SMALL">
              Understand how customers interact with your product, and collect feedback to know how
              to improve.
            </ThemeTypography>
            <Zoom in={active}>
              <StyledPulse src={pulseLine} alt="pulse" />
            </Zoom>
          </ContentContainer>
        </Zoom>
        <Fade in={active} timeout={400}>
          <ImageContainer alignItems="flex-end">
            <Image src={AnalyticsImage} />
          </ImageContainer>
        </Fade>
      </PageContainer>
      <TextControlBar />
    </>
  );
};

export default Analytics;
