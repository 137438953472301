import { gql } from '@apollo/client';

export const CMS_CERTIFICATE_CATALOG_ITEM_FRAGMENT = gql`
  fragment certificateCatalogItem on CmsCertificateCatalogItem {
    id
    title
    description
    imageUrl
    status
    requestedByUserEmail
    requestedTimestamp
  }
`;
