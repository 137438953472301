export const REQUEST_HEADER_PARTNER_COLUMNS = [
  {
    id: 'title',
    headerName: 'Title',
    sortable: false,
  },
  {
    id: 'requestedBy',
    headerName: 'Requested by',
    sortable: false,
  },
  {
    id: 'dateRequested',
    headerName: 'Date requested',
    sortable: false,
  },
  {
    id: 'actions',
    headerName: 'Actions',
    sortable: false,
  },
];

export const REQUEST_HEADER_COLUMNS = [
  {
    id: 'title',
    headerName: 'Title',
    sortable: false,
  },
  {
    id: 'dateRequested',
    headerName: 'Date requested',
    sortable: false,
  },
  {
    id: 'actions',
    headerName: 'Actions',
    sortable: false,
  },
];
