import { Box, Grid, InputAdornment, TextField } from '@mui/material';
import { ContentCopy } from '@styled-icons/material/ContentCopy';
import { DangerButton } from 'components/Forms';
import { FieldWrapper, AlertDialog, ThemeButton } from 'designSystem';
import { useClipboard, useLogEvent } from 'components/hooks';
import { applicationHelperTexts } from 'config/applicationHints';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import * as Yup from 'yup';

const CreateSchema = Yup.object().shape({
  apiKey: Yup.string(),
});

const KeysForm = ({ loading, onSubmit, apiKey }) => {
  const { logEvent } = useLogEvent();
  const [isAlertActive, setIsAlertActive] = useState(false);

  const copy = useClipboard('API key copied to clipboard');
  const handleCopy = () => {
    copy(apiKey);
    logEvent('COPY_API_KEY');
  };

  return (
    <Formik
      initialValues={CreateSchema.default()}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={CreateSchema}
      onSubmit={onSubmit}
    >
      {() => (
        <Form>
          <Grid container spacing={3}>
            <Grid item xs={8}>
              <Box>
                <FieldWrapper
                  label="API Key"
                  subtitle="Use the API key to integrate your products with external pages"
                  tooltip={{
                    variant: 'INFO',
                    helperText: applicationHelperTexts.api.keys,
                  }}
                >
                  {apiKey && (
                    <TextField
                      disabled
                      fullWidth
                      name="url"
                      value={apiKey}
                      variant="outlined"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <ThemeButton
                              onClick={handleCopy}
                              startIcon={<ContentCopy size={14} />}
                              size="small"
                              color="WHITE"
                              typographyColor="GRAY"
                            >
                              Copy key
                            </ThemeButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                </FieldWrapper>
              </Box>
            </Grid>
            <Grid item xs={12}>
              {apiKey ? (
                <DangerButton
                  size="large"
                  inverted
                  color="WHITE"
                  typographyColor="WHITE"
                  loading={loading}
                  onClick={() => setIsAlertActive(true)}
                >
                  Regenerate key
                </DangerButton>
              ) : (
                <ThemeButton
                  color="BLUE_ICE"
                  size="large"
                  loading={loading}
                  type="submit"
                  onClick={onSubmit}
                >
                  Generate new key
                </ThemeButton>
              )}

              <AlertDialog
                onClose={() => setIsAlertActive(false)}
                title="Warning!"
                open={isAlertActive}
                text="Regerating the API Key will invalidate the current API Key. If the API key is already used, the key will not work anymore. Only continue if you know what you are doing."
                onSubmit={onSubmit}
                onCancel={() => setIsAlertActive(false)}
                submitText="Generate new key"
                displayCloseButton
              />
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

KeysForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default KeysForm;
