import { Box, Table } from '@mui/material';
import { styled } from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import SelectProductTableBody from './SelectProductTableBody';

export const StyledTable = styled(Table)(() => ({
  borderCollapse: 'collapse',

  '& .MuiTableRow-root': {
    display: 'flex',
    marginBottom: 8,

    '&:first-of-type': {
      marginTop: 8,
    },
  },

  '& .MuiTableCell-root': {
    borderBottom: 'none',
  },
}));

const SelectProductTable = ({ products, setSelectedProductId, selectedProductId }) => {
  return (
    <Box position="relative">
      <StyledTable aria-labelledby="tableTitle" aria-label="enhanced table">
        <SelectProductTableBody
          products={products}
          onSelect={productId => setSelectedProductId(productId)}
          selectedId={selectedProductId}
        />
      </StyledTable>
    </Box>
  );
};

SelectProductTable.propTypes = {
  products: PropTypes.array.isRequired,
  selectedProductId: PropTypes.string,
  setSelectedProductId: PropTypes.func.isRequired,
};

export default SelectProductTable;
