import { Box, DialogActions } from '@mui/material';
import useSiteMutations from 'components/Sites/hooks/useSiteMutations';
import { FlexBox } from 'components/Structure';
import { useDialog } from 'components/hooks';
import { cultivationAreaSchema, ICultivationAreaValues } from 'constants/schemas/sites.schema';
import { DialogDefault, ThemeButton, ThemeTypography } from 'designSystem';
import GeoDataField from 'designSystem/Formik/GeoDataField/GeoDataField';
import Icon from 'designSystem/Primitives/Icon/Icon';
import { Field, Form, Formik, FormikProps } from 'formik';
import isEqual from 'lodash/isEqual';
import React, { FC, useRef } from 'react';
import { IDefaultDialogProps } from 'types/dialog.types';
import { ISite, SiteType } from 'types/site.types';
import { SiteLabelKeys } from 'utils/site.utils';

interface IAddEditCultivationAreaDialogProps extends IDefaultDialogProps {
  site: ISite;
}

const AddEditCultivationAreaDialog: FC<IAddEditCultivationAreaDialogProps> = ({
  open,
  site,
  onClose,
}) => {
  const { updateSite } = useSiteMutations();
  const { openDialog } = useDialog();
  const formRef = useRef<FormikProps<ICultivationAreaValues> | null>(null);

  if (site.siteType !== SiteType.FARM) {
    throw Error('This dialog can only be used for farm sites');
  }

  const initialValues: ICultivationAreaValues = {
    ...cultivationAreaSchema.default(),
    ...(site.siteType === SiteType.FARM &&
    site.cultivatedAreas?.length &&
    site.cultivatedAreas[0].coordinates
      ? { coordinates: site.cultivatedAreas[0].coordinates }
      : { coordinates: [] }),
  };

  const centerPoint = site.cultivatedAreas?.length
    ? site?.cultivatedAreas[0].centerPoint
    : undefined;

  const handleClose = () => {
    if (formRef.current && !isEqual(formRef.current.values, initialValues)) {
      openDialog({
        type: 'ALERT',
        props: {
          title: 'Unsaved changes',
          text: 'Are you sure you want to close this window? All unsaved changes will be lost and you will not be able to undo this action.',
          submitText: 'Close',
          itemTitle: site.title,
          displayCloseButton: true,
          onSubmit: onClose,
          onCancel: () => undefined,
        },
      });
    } else {
      onClose?.();
    }
  };

  const handleDeleteCultivationArea = async () => {
    await updateSite({
      variables: {
        id: site.id,
        input: {
          siteType: site.siteType,
          title: site.title,
          locationCoordinates: site.locationCoordinates,
          farmInput: {
            rawMaterialId: site.rawMaterial?.id,
            size: site.size,
            coordinates: null,
          },
        },
      },
    });
    onClose?.();
  };

  const handleSubmit = async (values: ICultivationAreaValues) => {
    await updateSite({
      variables: {
        id: site.id,
        input: {
          siteType: site.siteType,
          title: site.title,
          locationCoordinates: site.locationCoordinates,
          farmInput: {
            rawMaterialId: site.rawMaterial?.id,
            size: site.size,
            coordinates: values.coordinates,
          },
        },
      },
    });
    onClose?.();
  };

  return (
    <DialogDefault
      title="Edit cultivation area"
      onClose={handleClose}
      data-cy="add-edit-activity-dialog"
      open={open}
      fullWidth
      iconName="edit"
      maxWidth="md"
    >
      <Formik
        innerRef={formRef}
        initialValues={initialValues}
        validateOnChange
        validationSchema={cultivationAreaSchema}
        onSubmit={handleSubmit}
      >
        {({ values, isSubmitting, isValid }) => (
          <Form style={{ width: '100%' }}>
            <Box
              display="flex"
              flexDirection="column"
              p={3}
              height="60vh"
              minWidth={600}
              width="100%"
              gap={2}
            >
              <FlexBox mb={1} gap={1}>
                <FlexBox gap={1}>
                  <Icon name="tag-filled" color="gray-80" size="small" />
                  <ThemeTypography variant="BODY_MEDIUM_BOLD" color="GRAY_80">
                    {SiteLabelKeys[site.siteType]}
                  </ThemeTypography>
                </FlexBox>
                <FlexBox gap={1}>
                  <Icon name="location" size="small" color="gray-80" />
                  <ThemeTypography variant="LABEL_INPUT" color="GRAY_80">
                    {site.locationName}
                  </ThemeTypography>
                </FlexBox>
              </FlexBox>

              <Field
                name="coordinates"
                component={GeoDataField}
                marker={centerPoint ? centerPoint : site.locationCoordinates}
              />
            </Box>

            <DialogActions>
              <FlexBox width="100%" justifyContent="space-between">
                <ThemeButton
                  color="BLUE_ICE"
                  size="large"
                  type="button"
                  onClick={handleDeleteCultivationArea}
                >
                  Delete
                </ThemeButton>

                <FlexBox gap={2}>
                  <ThemeButton color="BLUE_ICE" size="large" type="button" onClick={handleClose}>
                    Cancel
                  </ThemeButton>

                  <ThemeButton
                    data-cy="submit-activity-form-btn"
                    loading={isSubmitting}
                    color="YELLOW"
                    size="large"
                    type="submit"
                    disabled={!isValid || isEqual(values, initialValues)}
                  >
                    Save
                  </ThemeButton>
                </FlexBox>
              </FlexBox>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </DialogDefault>
  );
};

export default AddEditCultivationAreaDialog;
