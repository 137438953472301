import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import { styled } from '@mui/material/styles';
import { millisecondsToMinutes } from 'components/Product/Analytics/utils';

const StyledLabel = styled('span')(({ theme }) => ({
  fontWeight: 'bold',
  textAlign: 'center',
  color: theme.palette.primary.main,
}));

const TimeLabel = styled('span')(() => ({
  textAlign: 'center',
}));

const TimeSpentOnPageTooltip = ({ tooltipState, timeInterval }) => {
  const tooltipDateFormat = timeInterval === 'H' ? 'hh:mm, MMMM D' : 'MMMM D, YYYY';

  return (
    <div>
      {tooltipState.tooltipData &&
        tooltipState.tooltipData.map((d, i) => (
          <div
            key={i}
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <StyledLabel>{millisecondsToMinutes(d.value)} min</StyledLabel>
            <span>
              <strong>Total Visitors:</strong> <StyledLabel>{d.visitorCount}</StyledLabel>
            </span>
            {tooltipState.tooltipData && (
              <TimeLabel>
                <Moment format={tooltipDateFormat}>{tooltipState.tooltipData[0].date}</Moment>
              </TimeLabel>
            )}
          </div>
        ))}
    </div>
  );
};

TimeSpentOnPageTooltip.propTypes = {
  tooltipState: PropTypes.object.isRequired,
  timeInterval: PropTypes.string,
};

export default TimeSpentOnPageTooltip;
