import { Autocomplete, Box, FormControl, FormHelperText, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import defaultPartnerImage from 'assets/img/partners/default-partner-image.png';
import { useDialog } from 'components/hooks';
import usePartners from 'components/Partners/hooks/usePartners';
import { useCompanyPlanContext } from 'contexts/CompanyPlanContext';
import Image from 'designSystem/DataDisplay/Image/Image';
import PaperWithAddItem from 'designSystem/Inputs/Paper/Paper';
import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from 'react';
import { CompanyStatus } from 'types/company.types';
import { IBasePartnerCompany } from 'types/partner.types';

interface IPartnerAutocompleteProps {
  inviteNewPartner?: boolean;
  defaultValue?: IBasePartnerCompany | null;
  placeholder?: string;
  /* Default is true */
  filterByRegisteredPartners?: boolean;
  size?: 'small' | 'medium';
  disabled?: boolean;
  fontSize?: number;
  hasError?: boolean;
  errorMessage?: string;
  onPartnerSelect: (partner: IBasePartnerCompany | null) => void;
}

const StyledAutocomplete = styled(Autocomplete<IBasePartnerCompany>)<{ fontSize?: number }>(
  ({ fontSize }) => ({
    '& .MuiAutocomplete-inputRoot': {
      paddingLeft: '16px !important',
      fontSize: fontSize ? fontSize : 12,
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      padding: 4,
      '& .MuiInputBase-input': {
        fontSize: fontSize ? fontSize : 12,
      },
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"]':
      {
        padding: 4,
        '& .MuiInputBase-input': {
          fontSize: fontSize ? fontSize : 12,
        },
      },
  })
);

const StyledFormHelperText = styled(FormHelperText)(() => ({
  '&.MuiFormHelperText-root': {
    marginTop: -2,
  },
}));

const PartnerAutocomplete: FC<IPartnerAutocompleteProps> = ({
  inviteNewPartner,
  defaultValue = null,
  placeholder = 'Select partner',
  size = 'medium',
  filterByRegisteredPartners = true,
  disabled,
  fontSize,
  hasError,
  errorMessage = 'Please select a partner',
  onPartnerSelect,
}) => {
  const { openDialog } = useDialog();
  const [selectPartnerId, setSelectPartnerId] = useState<string | null>(null);
  const [selectedPartner, setSelectedPartner] = useState<IBasePartnerCompany | null>(defaultValue);

  const { company } = useCompanyPlanContext();
  const { partners, loading, refetch } = usePartners();
  const sizeImage = size === 'small' ? 14 : 17;

  const partnerOptions: IBasePartnerCompany[] = useMemo(() => {
    if (filterByRegisteredPartners) {
      return [...partners.filter(partner => partner.status === CompanyStatus.REGISTERED), company];
    }
    return [...partners, company];
  }, [partners, company, filterByRegisteredPartners]);

  const handleSelectPartner = (
    event: ChangeEvent<unknown>,
    partner: IBasePartnerCompany | null
  ) => {
    setSelectedPartner(partner);
    onPartnerSelect(partner);
  };

  const handleNewPartnerCreated = useCallback(
    async (partner: IBasePartnerCompany) => {
      await refetch();
      setSelectPartnerId(partner.id);
    },
    [refetch, setSelectPartnerId]
  );

  const handleCreatePartner = () => {
    openDialog({
      type: 'ADD_EDIT_PARTNER',
      props: {
        enableInvite: inviteNewPartner,
        skipInvitationEmail: true,
        onPartnerCreated: handleNewPartnerCreated,
      },
    });
  };

  /**
   * Waiting until the partner list got updated and select the correct partner as soon as it exists
   * Removing the selectedPartner from the temporary state variable after the partner got created
   */
  useEffect(() => {
    if (selectPartnerId) {
      const partner = partners.find(({ id }) => id === selectPartnerId);
      if (!partner) {
        // eslint-disable-next-line no-console
        console.error(`Partner with id ${selectPartnerId} not found`);
        setSelectPartnerId(null);
        return;
      }
      setSelectedPartner(partner);
      onPartnerSelect(partner);
      setSelectPartnerId(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectPartnerId, partners]);

  /** Listen to the changes */
  useEffect(() => setSelectedPartner(defaultValue), [defaultValue]);

  return (
    <FormControl fullWidth size={size} error={hasError}>
      <StyledAutocomplete
        loading={loading}
        value={selectedPartner}
        options={partnerOptions}
        data-cy="partner-autocomplete"
        onChange={handleSelectPartner}
        fullWidth
        fontSize={fontSize}
        size={size}
        disabled={disabled}
        PaperComponent={({ children }) => (
          <PaperWithAddItem title="Create new partner" onClick={handleCreatePartner}>
            {children}
          </PaperWithAddItem>
        )}
        getOptionLabel={option => option.name}
        renderOption={(props, option: IBasePartnerCompany) => (
          <li {...props} key={option.id} data-cy="autocomplete-option">
            <Box mr={2} display="flex" alignItems="center">
              <Image
                width={sizeImage}
                height={sizeImage}
                image={option.logo}
                backupImageUrl={defaultPartnerImage}
                borderRadius={0}
              />
            </Box>
            {option.name}
          </li>
        )}
        renderInput={params => (
          <TextField
            {...params}
            error={hasError}
            variant="outlined"
            placeholder={placeholder}
            onKeyPress={keyEvent => {
              // Prevents the form from being submitted on enter.
              if (keyEvent.key === 'Enter') {
                keyEvent.preventDefault();
              }
            }}
            InputProps={{
              style: { height: size === 'medium' ? 40 : 30 },
              ...params.InputProps,
              startAdornment: selectedPartner ? (
                <Image
                  width={sizeImage}
                  height={sizeImage}
                  image={selectedPartner.logo}
                  backupImageUrl={defaultPartnerImage}
                  borderRadius={0}
                />
              ) : (
                <Image
                  width={sizeImage}
                  height={sizeImage}
                  backupImageUrl={defaultPartnerImage}
                  borderRadius={0}
                />
              ),
            }}
          />
        )}
      />
      {hasError && <StyledFormHelperText>{errorMessage}</StyledFormHelperText>}
    </FormControl>
  );
};

export default PartnerAutocomplete;
