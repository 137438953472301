import { EudrComplianceIllustration } from 'assets/img';
import ComplianceEudrImage from 'assets/img/compliance/eudr.png';
import React from 'react';
import { EudrAffectednessType, IComplianceGuide } from 'types/compliance.types';
import AffectedCommodities from './SubSections/AffectedCommodities';
import CompanyLocation from './SubSections/CompanyLocation';
import DeforestationRiskResults from './SubSections/DeforestationRiskResults';
import RequestPartnerSelfAssessment from './SubSections/RequestPartnerSelfAssessment';
import MapGeoDataColumns from './SubSections/MapGeoDataColumns';
import ValidateGeoData from './SubSections/ValidateGeoData';
import GeoDataPractice from './SubSections/GeoDataPractice';
import UploadGeoData from './SubSections/UploadGeoData';
import RiskResultsInProgress from './SubSections/RiskResultsInProgress';
import ConfirmGeoData from './SubSections/ConfirmGeoData';
import SelectDatasets from './SubSections/SelectDatasets';
import AddBatchDetails from './SubSections/AddBatchDetails';
import SelfAffectedResult from './SubSections/SelfAffectedResult';
import AffectedProducer from './SubSections/AffectedProducer';
import AffectedMarketPlacement from './SubSections/AffectedMarketPlacement';
import AffectedCompanySize from './SubSections/AffectedCompanySize';
import PreviewPartnerAssessment from './SubSections/PreviewPartnerAssessment';
import PartnerAssessmentResults from './SubSections/PartnerAssessmentResults';
import DueDiligenceSummary from './SubSections/DueDiligenceSummary';
import PartnerSelection from './SubSections/PartnerSelection';
import MitigationPolygons from './SubSections/MitigationPolygons';
import MitigationPartners from './SubSections/MitigationPartners';

export enum ComplianceType {
  EUDR = 'eudr',
}

export const EUDR_COMPLIANCE_GUIDE: IComplianceGuide = {
  key: ComplianceType.EUDR,
  title: 'EU Deforestation Regulation (EUDR)',
  image: ComplianceEudrImage,
  illustration: EudrComplianceIllustration,
  regulationUrl:
    'https://eur-lex.europa.eu/legal-content/EN/TXT/HTML/?uri=CELEX:32023R1115#d1e1773-206-1',
  sections: [
    {
      key: 'affectedness',
      title: 'Evaluate affectedness',
      subSections: [
        {
          key: 'company-location',
          title: 'Check your affectedness & define regulation responsibility',
          description:
            'The due diligence responsibilities for the EUDR depends on if your company is classified as an Operator or Trader. The size of your company might also be relevant to decide your required due diligence procedure. The following questions will help you find what your specific role and responsibilities are, and what the role and responsibilities are for your partners.',
          content: <CompanyLocation />,
        },
        {
          key: 'affectedness-commodities',
          title: 'Check your affectedness & define regulation responsibility:',
          itemTitle: 'Affected commodities',
          description:
            'Even if you are not directly affected as a EU company, you will still might need to collect certain data in order to sell your product to companies within the EU.',
          content: <AffectedCommodities />,
        },
        {
          key: 'affectedness-producer',
          title: 'Check your affectedness & define regulation responsibility:',
          itemTitle: 'Producer responsibility',
          description:
            'You can still be affected by the regulation if you grow or produce any of the affected commodities within the EU and hence place these products on the EU market.',
          content: <AffectedProducer />,
        },
        {
          key: 'affectedness-market-placement',
          title: 'Check your affectedness & define regulation responsibility:',
          itemTitle: 'Market placement',
          description:
            'Since you are sourcing affected commodities within the EU, it is important to clarify if you are the actor that first introduces the product on the market.',
          content: <AffectedMarketPlacement />,
        },
        {
          key: 'affectedness-company-size',
          title: 'Check your affectedness & define regulation responsibility:',
          itemTitle: 'Company size',
          description:
            'You are classified as a Trader in the EUDR regulatory landscape, but your due diligence responsibilities depend on the number of employees and annual revenue of your company. Answer the question to establish if your company counts as a Small to Medium Enterprise (SME) or not.',
          content: <AffectedCompanySize />,
        },
        {
          key: 'not-affected',
          title: 'Rest assured. You are not affected by the regulation at all',
          content: <SelfAffectedResult affectiveness={EudrAffectednessType.NOT_AFFECTED} />,
        },
        {
          key: 'indirectly-affected',
          title: 'You are indirectly affected by this regulation',
          content: <SelfAffectedResult affectiveness={EudrAffectednessType.INDIRECTLY_AFFECTED} />,
        },
        {
          key: 'partially-affected',
          title: 'You are indirectly affected by this regulation',
          content: <SelfAffectedResult affectiveness={EudrAffectednessType.PARTIALLY_AFFECTED} />,
        },
        {
          key: 'fully-affected',
          title: 'You are fully affected by this regulation',
          content: <SelfAffectedResult affectiveness={EudrAffectednessType.FULLY_AFFECTED} />,
        },
        {
          key: 'select-partners',
          title: 'Select partners to include in due diligence procedure',
          description:
            'You need to make sure to select or add all your partners (from origin to distribution) that are involved in the supply chains of the commodities affected by the regulation (Cocoa, Coffee, Oil palm, Soy, Rubber, Timber, Cattle). The regulation requires contact details to all the involved supply chain actors. You will in later steps need to add required data for each origin and direct supplier.',
          content: <PartnerSelection />,
        },
      ],
    },
    {
      key: 'geo-data',
      title: 'Collect geo-data',
      subSections: [
        {
          key: 'affected-origins',
          title: 'Collect geo-data from your affected origins',
          description:
            'EUDR compliance requires collecting geolocations for the farm plots that are used to grow the affected commodities. If a plot is smaller than 4 hectares, a single point is enough, but when a plot is larger than 4 ha, then a polygon format is required, with several coordinates to specify the boundaries around the cultivation area of the plot.',
          content: <GeoDataPractice />,
        },

        {
          key: 'upload',
          title: 'Upload geo-data',
          description:
            'You can upload your origin data in sheet format (.csv or .xlsx / .xls), or in polygon-adapted file format (.kml or .geojson / .json). You need to confirm and specify which country of origin and commodity each dataset refers to. Select also the partner that is the owner of each dataset (this can for example be the supplier that provided the data, a supplier linked to that origin, or your own company). You should only have one origin per dataset.',
          content: <UploadGeoData />,
        },
        {
          key: 'map-columns',
          title: 'Map columns',
          description:
            'Please link the columns of all your uploaded files with the correct template data type in the Seedtrace system. If there is no matching selection for a column, this data type is currently not relevant.',
          content: <MapGeoDataColumns />,
        },
        {
          key: 'validate',
          title: 'Validate imported sheet data',
          description:
            'Please make sure to correct any visible errors before continuing to deforestation analysis.',
          content: <ValidateGeoData />,
        },
        {
          key: 'confirmation',
          title: 'Confirm geo data',
          description:
            'Before sending the geo-data for deforestation risk analysis, make sure that the data looks correct in the map preview for all data sets. It is important to have readable data in order to perform a credible risk analysis. If there are problems in reading the data during the risk analysis, we will also alert you.',
          content: <ConfirmGeoData />,
        },
      ],
    },
    {
      key: 'risk-analysis',
      title: 'Deforestation risk analysis',
      subSections: [
        {
          key: 'select-datasets',
          title: 'Select which geo-datasets to include in the deforestation risk analysis',
          description:
            'Make sure to select all origin geo-datasets from partners that are involved in the supply chains of commodities affected by the regulation (Cocoa, Coffee, Palm, Soy, Rubber, Timber, Cattle).',
          content: <SelectDatasets />,
        },
        {
          key: 'add-batch-details',
          title: 'Add all batch details for each origin',
          description:
            'For each origin geo-dataset, please add the details of the batches you will import and want to analyse. Harvest date and quantity per batch are required data by the regulation. You can choose to add your internal batch id numbers, or auto-assign a unique id for each batch. You need to add at least one batch per origin geo-dataset.',
          content: <AddBatchDetails />,
        },
        {
          key: 'risk-analysis-in-progress',
          title: 'Data verification & risk analysis in progress',
          description:
            'Your geo-data will go through a verification process. We will notify you if the geo-data has errors. If the data is readable, it will then undergo a detailed remote sensing deforestation risk analysis, checking satellite imagery over time and evaluating the changes in vegetation for the specified regions.',
          content: <RiskResultsInProgress />,
        },
        {
          key: 'risk-analysis-results',
          title: 'Deforestation risk analysis completed',
          description:
            'You can overview the results here for each origin geo-dataset and each polygon. If you have a medium or high risk for any of the origin geo-datasets, you might need to perform some risk mitigation procedures in the next steps.',
          content: <DeforestationRiskResults />,
        },
      ],
    },
    {
      key: 'partner-assessment',
      title: 'Partner self-assessment',
      subSections: [
        {
          key: 'request-partner-assessment',
          title: 'Select which partners to send a self-assessment request',
          description:
            'You need to first make sure to include all your upstream supply chain actors (from origin to distribution) that are involved in the supply chains of your products, containing the commodities affected by the regulation (Cocoa, Coffee, Palm, Soy, Rubber, Timber, Cattle).',
          content: <RequestPartnerSelfAssessment />,
        },
        {
          key: 'preview-partner-assessment',
          title: 'Preview partner self-assessment',
          description:
            'See below a summary of what is contained in this questionnaire. Simply click next to continue to fill in your replies.',
          content: <PreviewPartnerAssessment />,
        },
        {
          key: 'partner-assessment-results',
          title: 'Self-assessment response progress',
          description:
            'See which partners have completed their self-assessment and the overall risk results. Once a partner has completes their self-assessment, more detailed results are available in the next step. You can continue the compliance guide in the meantime',
          content: <PartnerAssessmentResults />,
        },
      ],
    },
    {
      key: 'risk-mitigation',
      title: 'Risk mitigation',
      subSections: [
        {
          key: 'risk-mitigation-polygons',
          title: 'Risk mitigation:',
          itemTitle: 'Deforestion risk measures',
          description: undefined,
          content: <MitigationPolygons />,
        },
        {
          key: 'risk-mitigation-partners',
          title: 'Risk mitigation:',
          itemTitle: 'Partner risk measures',
          description: undefined,
          content: <MitigationPartners />,
        },
      ],
    },
    {
      key: 'due-diligence',
      title: 'Due diligence summary',
      subSections: [
        {
          key: 'overview',
          title: 'Due diligence summary',

          content: <DueDiligenceSummary />,
        },
      ],
    },
  ],
};

export const COMPLIANCE_GUIDES: Record<ComplianceType, IComplianceGuide> = {
  eudr: EUDR_COMPLIANCE_GUIDE,
};
