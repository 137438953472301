import { Box } from '@mui/material';
import { Plus, Trash } from '@styled-icons/bootstrap';
import { Edit } from '@styled-icons/boxicons-solid';
import ComponentChainTable from 'components/ComponentsLibrary/ComponentChainTable/ComponentChainTable';
import { COMPONENT_CHAIN_COLUMN_IDS } from 'components/ComponentsLibrary/constants/headerColumns';
import useComponentChainActions from 'components/ComponentsLibrary/hooks/useComponentChainActions';
import useComponentChains from 'components/ComponentsLibrary/hooks/useComponentChains';
import { ITableRowAction } from 'components/ComponentsLibrary/TableRow';
import { Loader, SearchToolbar } from 'components/Forms';
import { useDialog } from 'components/hooks';
import { ProductSummarySkeleton } from 'components/Product/ProductTable';
import {
  ActionContainer,
  EmptySearchResults,
  ErrorState,
  FlexBox,
  PageContainer,
  ScrollableTableContainer,
} from 'components/Structure';
import { useCompanyPlanContext } from 'contexts/CompanyPlanContext';
import { CardIllustration, EmptyStateCard, ThemeButton } from 'designSystem';
import Icon from 'designSystem/Primitives/Icon/Icon';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { CustomerPlan } from 'types/company.types';
import { AvailableSizes, BackgroundTypes, IllustrationTypes, ItemTypes } from 'types/enums';

const ComponentChains: FC = () => {
  const navigate = useNavigate();
  const {
    loading,
    error,
    filteredComponentChains,
    componentChains,
    searchTerm,
    handleCreateComponentChain,
    setSearchTerm,
  } = useComponentChains();
  const { deleteChain: deleteComponentChain } = useComponentChainActions();
  const { openDialog, closeDialog } = useDialog();
  const { plan } = useCompanyPlanContext();

  const handleDeleteComponentChain = async (id: string) => {
    const componentChain = filteredComponentChains.find(chain => chain.id === id);
    openDialog({
      type: 'ALERT',
      props: {
        title: 'Delete component chain',
        itemTitle: componentChain?.title,
        displayCloseButton: true,
        text: 'Are you sure you want to delete this component chain? All data will be lost and you will not be able to recover this item.',
        submitText: 'Delete',
        onSubmit: () => {
          deleteComponentChain({ variables: { id } });
        },
        onCancel: () => closeDialog('ALERT'),
      },
    });
  };

  const handleCreateChainRequest = () => {
    openDialog({ type: 'PARTNER_REQUEST', props: { requestType: 'CHAIN' } });
  };

  const handleRowClick = (id: string) => {
    navigate(`/component-chains/${id}/editor`);
  };

  const handleEditClick = (id: string) => {
    const componentChain = filteredComponentChains.find(chain => chain.id === id);
    if (!componentChain) {
      console.error('Can not find component chain');
      return;
    }
    openDialog({
      type: 'EDIT_CHAIN',
      props: { chain: componentChain },
    });
  };

  const componentTableActions: ITableRowAction[] = [
    {
      renderIcon: () => <Edit size="16" />,
      tooltip: 'Edit component chain',
      onClick: handleEditClick,
      dataCy: 'update-component-chain-btn',
    },
    {
      renderIcon: () => <Trash size="16" />,
      tooltip: 'Delete component chain',
      onClick: (id: string) => handleDeleteComponentChain(id),
      disableIfUsed: true,
      disableIfPartOfRequest: true,
      disabledTooltip:
        'Component chain is part of another supply chain or part of a partner request',
      dataCy: 'delete-component-chain-btn',
    },
  ];

  if (loading) return <Loader />;
  if (error) return <ErrorState />;

  if (!componentChains.length) {
    return (
      <Box mt={4}>
        <EmptyStateCard
          title="Map your component chains to apply and re-use in your product supply chains"
          onCreateItemClick={handleCreateComponentChain}
          variant={ItemTypes.COMPONENT_CHAIN}
          buttonText="Add component chain"
          background={BackgroundTypes.WORLD}
          size={AvailableSizes.LARGE}
          renderIllustration={
            <CardIllustration image={IllustrationTypes.LOCATIONS} width={300} right={25} top={0} />
          }
          data-cy="component-chains-getting-started-card"
          data-cy-button="component-chains-getting-started-card-button"
        />
      </Box>
    );
  }

  return (
    <PageContainer>
      <ActionContainer>
        <SearchToolbar
          setSearchTerm={setSearchTerm}
          searchTerm={searchTerm || ''}
          className="search-input"
          data-cy="component-chain-search-input"
          placeholder="Search component"
          actions={
            <FlexBox gap={1} ml={1}>
              <ThemeButton
                size="medium"
                color="YELLOW"
                onClick={handleCreateComponentChain}
                startIcon={<Plus size={20} />}
                data-cy="create-component-chain-btn"
              >
                Create new component chain
              </ThemeButton>
              {plan !== CustomerPlan.FREEMIUM && (
                <ThemeButton
                  size="medium"
                  color="BLUE_ICE"
                  onClick={handleCreateChainRequest}
                  startIcon={<Icon name="chain-request-add" />}
                >
                  Request component chain
                </ThemeButton>
              )}
            </FlexBox>
          }
        />
      </ActionContainer>
      <ScrollableTableContainer pb={1}>
        <ComponentChainTable
          displayTableHeader
          columnKeys={[
            COMPONENT_CHAIN_COLUMN_IDS.TITLE,
            COMPONENT_CHAIN_COLUMN_IDS.OWNER,
            COMPONENT_CHAIN_COLUMN_IDS.INVOLVED_PARTNERS,
            COMPONENT_CHAIN_COLUMN_IDS.ACTIONS,
          ]}
          componentChains={filteredComponentChains}
          actions={componentTableActions}
          onRowClick={handleRowClick}
        />
      </ScrollableTableContainer>

      {/* No Result container */}
      {!filteredComponentChains.length && (
        <EmptySearchResults
          message="No search results found"
          data-cy="component-chain-no-results"
          action={{
            label: 'Create component chain',
            onClick: handleCreateComponentChain,
          }}
          skeletonComponent={<ProductSummarySkeleton title={searchTerm} />}
        />
      )}
    </PageContainer>
  );
};

export default ComponentChains;
