import React, { Fragment, FC } from 'react';
import { Box } from '@mui/material';
import { ThemeTypography, ThemeButton } from 'designSystem';

interface IUpgradeRequestedDialogContentProps {
  expired: boolean;
  onClose: () => void;
}

const UpgradeRequestedDialogContent: FC<IUpgradeRequestedDialogContentProps> = ({
  expired,
  onClose,
}) => (
  <Fragment>
    <Box mb={2}>
      <ThemeTypography variant="BODY_LARGE">
        You already requested an update and we will get back to you soon. In case you need urgent
        support, please use the chat functionality in the lower right corner or send us an email to
        contact@seedtrace.org.
      </ThemeTypography>
    </Box>
    <Box mt={1}>
      <ThemeButton onClick={onClose} size="large" color="YELLOW">
        {expired ? 'Logout' : 'Close'}
      </ThemeButton>
    </Box>
  </Fragment>
);

export default UpgradeRequestedDialogContent;
