import { useQuery } from '@apollo/client';
import { GET_TRANSCODING_VIDEOS_CLIENT, GET_VIDEOS_BY_ID } from 'graphql/queries';
import React, { Fragment, useEffect } from 'react';

import Video from './Video';

import PropTypes from 'prop-types';

const VideoList = ({ videos }) => {
  const { data: { getTranscodingVideos: transcodingVideos = [] } = {} } = useQuery(
    GET_TRANSCODING_VIDEOS_CLIENT
  );

  const { startPolling, stopPolling } = useQuery(GET_VIDEOS_BY_ID, {
    skip: !transcodingVideos.length,
    variables: {
      ids: transcodingVideos.map(({ id }) => id),
    },
  });

  useEffect(() => {
    if (transcodingVideos.length) startPolling(2500);
    else stopPolling();
  }, [transcodingVideos.length]); // eslint-disable-line

  return (
    <Fragment>
      {videos.map(({ node: video }) => {
        return <Video key={video.id} video={video} />;
      })}
    </Fragment>
  );
};

VideoList.propTypes = {
  videos: PropTypes.array.isRequired,
};

export default VideoList;
