import { ColDef } from 'ag-grid-community';
import { SYSTEM_COLUMN_DEF } from 'components/DataImport/constants/dataImport.constants';
import { SystemColumns } from '../excelTable.types';
import ValidationCellRenderer from '../CellRenderers/SystemColumns/ValidationCellRenderer';

const VALIDATION_COLUMN: ColDef<SystemColumns> = {
  ...SYSTEM_COLUMN_DEF,
  field: 'validation',
  pinned: 'left',
  cellRenderer: ValidationCellRenderer,
};

export default VALIDATION_COLUMN;
