import { Box, Collapse, TableBody, TableCell } from '@mui/material';
import { styled } from '@mui/material/styles';
import { InfoCircle, Plus } from '@styled-icons/bootstrap';
import { DocumentFulfilledIcon } from 'assets/img/icons';
import { StyledTable, StyledTableRow } from 'components/TableBase';
import { ThemeButton, ThemeTypography } from 'designSystem';
import React, { FC, Fragment, MouseEvent } from 'react';
import { Booleanish, booleanish } from 'types/booleanish.types';
import { IDocument } from 'types/document.types';
import { RequestsTableAction } from './PaginatedRequestsTable';
import DocumentIntroTitle from 'components/DocumentLibrary/DocumentIntroTitle';

interface IInnerDocumentTableRowProps {
  isExpanded: boolean;
  isPartner: boolean;
  isAccepted: boolean;
  document: IDocument;
  onDocumentActionClick: (action: RequestsTableAction) => void;
}

const Table = styled(StyledTable)(() => ({
  borderSpacing: 0,
}));

const InnerClaimTableRow = styled(StyledTableRow)<{ 'is-displayed': booleanish }>(
  ({ 'is-displayed': isDisplayed }) => ({
    backgroundColor: 'transparent',
    boxShadow: 'none',
    display: isDisplayed === 'true' ? 'table-row' : 'none',

    '&:hover': {
      background: 'transparent !important',
      boxShadow: 'none',
      cursor: 'pointer',
    },
  })
);

const InnerDocumentTableRow: FC<IInnerDocumentTableRowProps> = ({
  document,
  isExpanded,
  isPartner,
  isAccepted,
  onDocumentActionClick,
}) => {
  const handleRowClick = (event: MouseEvent) => {
    event.stopPropagation();
    window.open(document.url, '_blank');
  };

  const handleReviewClick = (event: MouseEvent | KeyboardEvent) => {
    event.stopPropagation();
    onDocumentActionClick(RequestsTableAction.REVIEW);
  };

  const handleAddClaimClick = (event: MouseEvent | KeyboardEvent) => {
    event.stopPropagation();
    onDocumentActionClick(RequestsTableAction.ADD_CLAIM);
  };

  const ActionItems = isAccepted ? (
    <ThemeButton
      data-cy="add-claim-to-request-btn"
      variant="contained"
      startIcon={<Plus size={16} />}
      onClick={handleAddClaimClick}
    >
      Add claim
    </ThemeButton>
  ) : (
    <ThemeButton
      data-cy="approve-edit-request-btn"
      startIcon={<DocumentFulfilledIcon height={16} />}
      onClick={handleReviewClick}
    >
      Review & approve
    </ThemeButton>
  );

  return (
    <InnerClaimTableRow
      data-cy="request-table-item"
      data-cy-id={document.id}
      hover
      key={document.id}
      is-displayed={Booleanish(isExpanded)}
      onClick={handleRowClick}
    >
      <TableCell
        style={{
          paddingBottom: 0,
          paddingTop: 0,
        }}
        colSpan={6}
      >
        <Collapse in={isExpanded} timeout="auto" unmountOnExit>
          <Box
            style={{
              margin: '0 -12px 0 8px',
            }}
          >
            <Table size="small" data-cy="requested-document-inner-table">
              <TableBody>
                <StyledTableRow>
                  <TableCell>
                    <Box display="flex" justifyContent="space-between" mr={1}>
                      <DocumentIntroTitle showOpenInNewTab document={document} />
                      {!isPartner ? ActionItems : <Fragment />}
                    </Box>
                  </TableCell>
                </StyledTableRow>
              </TableBody>
            </Table>
          </Box>
          <Box display="flex" alignItems="center" ml={1.5} mt={0.5}>
            <InfoCircle height={14} />
            <Box mr={1} />
            <ThemeTypography variant="BODY_LARGE">
              {isPartner
                ? 'These documents can also be found in your document library.'
                : 'Uploaded partner documents can also be managed in your document library under "Shared with me".'}
            </ThemeTypography>
          </Box>
        </Collapse>
      </TableCell>
    </InnerClaimTableRow>
  );
};

export default InnerDocumentTableRow;
