import { Box } from '@mui/material';
import { Plus } from '@styled-icons/bootstrap';
import { Loader } from 'components/Forms';
import SearchToolbar from 'components/Forms/SearchToolbar';
import {
  ActionContainer,
  EmptySearchResults,
  ErrorState,
  PageContainer,
  ScrollableTableContainer,
} from 'components/Structure';
import PartnerSkeleton from 'components/Partners/Skeleton/PartnerSkeleton';
import ActivitiesTable from 'components/Partners/ActivitiesTable/ActivitiesTable';
import { ACTIVITIES_TABLE_ROWS_PER_PAGE } from 'constants/pagination';
import { CardIllustration, EmptyStateCard, ThemeButton } from 'designSystem';
import useActivities from 'hooks/useActivities';
import React, { FC } from 'react';
import { AvailableSizes, BackgroundTypes, IllustrationTypes, ItemTypes } from 'types/enums';
import { ActivitiesTableColumnKeys } from 'components/Partners/ActivitiesTable/activitiesHeaderColumns';
import { ActivityOwnership } from 'graphql/mutations/types/activity-mutation.types';

const Activities: FC = () => {
  const {
    activities,
    filteredActivities,
    loading,
    error,
    searchTerm,
    handleSearchTermChange,
    handleCreateActivity,
    handleEditActivity,
  } = useActivities({ typeOfActivity: ActivityOwnership.INTERNAL });

  if (loading) return <Loader />;
  if (error) return <ErrorState />;

  if (!activities.length) {
    return (
      <Box mt={4}>
        <EmptyStateCard
          data-cy="activities-getting-started-card"
          data-cy-button="activities-getting-started-card-button"
          title="Add your activities to map your supply chain and promote transparency"
          variant={ItemTypes.LOCATION}
          buttonText="Add activity"
          background={BackgroundTypes.WORLD}
          size={AvailableSizes.LARGE}
          renderIllustration={
            <CardIllustration image={IllustrationTypes.LOCATIONS} width={300} right={25} top={0} />
          }
          onCreateItemClick={handleCreateActivity}
        />
      </Box>
    );
  }

  return (
    <PageContainer>
      <ActionContainer>
        <SearchToolbar
          setSearchTerm={handleSearchTermChange}
          searchTerm={searchTerm || ''}
          className="search-input"
          data-cy="activity-search-input"
          placeholder="Search activity"
          actions={
            <Box ml={1}>
              <ThemeButton
                size="medium"
                color="YELLOW"
                onClick={handleCreateActivity}
                startIcon={<Plus size={20} />}
                data-cy="create-activity-btn"
              >
                Create new activity
              </ThemeButton>
            </Box>
          }
        />
      </ActionContainer>

      {filteredActivities.length > 0 && (
        <ScrollableTableContainer pb={2}>
          <ActivitiesTable
            columns={[
              ActivitiesTableColumnKeys.TITLE,
              ActivitiesTableColumnKeys.PARTNER,
              ActivitiesTableColumnKeys.MODIFIED_TIMESTAMP,
            ]}
            rowsPerPage={ACTIVITIES_TABLE_ROWS_PER_PAGE}
            activities={filteredActivities}
            onSelect={handleEditActivity}
            onEdit={handleEditActivity}
          />
        </ScrollableTableContainer>
      )}

      {!filteredActivities.length && (
        <EmptySearchResults
          message="No search results found"
          action={{
            label: 'Create new activity',
            onClick: handleCreateActivity,
          }}
          skeletonComponent={<PartnerSkeleton title={searchTerm || ''} />}
        />
      )}
    </PageContainer>
  );
};

export default Activities;
