import React from 'react';
import PropTypes from 'prop-types';
import { LineGraph } from 'components/Product/Analytics/LineGraph';
import { useTheme } from '@mui/material/styles';
import RecentActivityTooltip from './RecentActivityTooltip';

const RecentActivityLineChart = ({ data, ...rest }) => {
  const theme = useTheme();
  const colors = [theme.palette.secondary.main, theme.palette.primary.main];

  return (
    <LineGraph
      graphColors={colors}
      data={data}
      graphTooltip={tooltipState => (
        <RecentActivityTooltip
          timeInterval={data.timeInterval}
          tooltipState={tooltipState}
          colors={colors}
        />
      )}
      {...rest}
    />
  );
};

RecentActivityLineChart.propTypes = {
  data: PropTypes.array.isRequired,
  // And other LineGraph props
};

export default RecentActivityLineChart;
