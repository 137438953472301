export enum ComponentItemTableColumnKeys {
  TITLE = 'title',
  PARTNER = 'partner',
  MODIFIED_TIMESTAMP = 'lastEditedTimestamp',
  ACTIONS = 'actions',
}

export const COLUMNS_COMPONENT_ITEMS = [
  {
    id: ComponentItemTableColumnKeys.TITLE,
    headerName: 'Title',
    sortable: false,
  },
  {
    id: ComponentItemTableColumnKeys.PARTNER,
    headerName: 'Owner',
    sortable: false,
  },
  {
    id: ComponentItemTableColumnKeys.MODIFIED_TIMESTAMP,
    headerName: 'Last edited',
    sortable: false,
  },
  {
    id: ComponentItemTableColumnKeys.ACTIONS,
    headerName: 'Actions',
    sortable: false,
  },
];

export enum COMPONENT_CHAIN_COLUMN_IDS {
  SELECTION = 'selection',
  TITLE = 'title',
  OWNER = 'owner',
  INVOLVED_PARTNERS = 'involvedPartners',
  ACTIONS = 'actions',
}

export const COLUMNS_COMPONENT_CHAINS = [
  {
    id: COMPONENT_CHAIN_COLUMN_IDS.SELECTION,
    headerName: '',
    sortable: false,
  },
  {
    id: COMPONENT_CHAIN_COLUMN_IDS.TITLE,
    headerName: 'Title',
    sortable: false,
  },
  {
    id: COMPONENT_CHAIN_COLUMN_IDS.OWNER,
    headerName: 'Owner',
    sortable: false,
  },
  {
    id: COMPONENT_CHAIN_COLUMN_IDS.INVOLVED_PARTNERS,
    headerName: 'Involved partners',
    sortable: false,
  },
  {
    id: COMPONENT_CHAIN_COLUMN_IDS.ACTIONS,
    headerName: 'Actions',
    sortable: false,
  },
];
