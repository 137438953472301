import { Box, FormControlLabel, Radio, styled } from '@mui/material';
import { ThemeTypography } from 'designSystem';
import React, { FC } from 'react';
import { Booleanish, booleanish } from 'types/booleanish.types';

interface IQnAGuideTemplateProps {
  selectedAnswer?: string;
  question: string;
  answers: { id: string; label: string; disabled?: boolean }[];
  onAnswerChange: (id: string) => void;
}

const Answer = styled('div')<{ selected: booleanish; disabled: booleanish }>(
  ({ theme, selected, disabled }) => ({
    cursor: 'pointer',
    padding: theme.spacing(3),
    borderRadius: theme.spacing(1),
    border: `1px solid ${
      selected === 'true'
        ? theme.custom.themeColors.accent[60]
        : theme.custom.colors.lightBorderColor
    }`,
    marginBottom: theme.spacing(1),
    backgroundColor: selected === 'true' ? theme.custom.themeColors.accent[20] : 'white',
    pointerEvents: disabled === 'true' ? 'none' : 'auto',
  })
);

const QnAGuideTemplate: FC<IQnAGuideTemplateProps> = ({
  question,
  answers,
  selectedAnswer,
  onAnswerChange,
}) => {
  return (
    <Box>
      <ThemeTypography variant="BODY_LARGE_BOLD">{question}</ThemeTypography>
      <Box mt={2}>
        {answers.map(({ id, label, disabled }) => (
          <Answer
            key={id}
            disabled={Booleanish(disabled)}
            selected={Booleanish(id === selectedAnswer)}
            onClick={() => onAnswerChange(id)}
          >
            <FormControlLabel
              checked={id === selectedAnswer}
              label={label}
              disabled={disabled}
              control={<Radio />}
              onChange={() => onAnswerChange(id)}
            />
          </Answer>
        ))}
      </Box>
    </Box>
  );
};

export default QnAGuideTemplate;
