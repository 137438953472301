import React, { FC } from 'react';
import { Box } from '@mui/material';
import { PartnerRequest, PartnerRequestStatus, RequestType } from 'types/partner.types';
import { styled } from '@mui/material/styles';
import DismissedTitle from './DismissedTitle';
import { useDialog } from 'components/hooks';
import CardContainer from 'designSystem/Layout/CardContainer/CardContainer';
import ThemeButton from 'designSystem/Buttons/ThemeButton/ThemeButton';
import ClickablePartnerName from './ClickablePartnerName';
import { useNavigate } from 'react-router-dom';
import { REQUEST_STATUS_CLIENT } from 'utils/requestStatus.utils';
import Icon from 'designSystem/Primitives/Icon/Icon';

interface IDeniedRequestCardProps {
  request: PartnerRequest;
}

const Container = styled(CardContainer)(({ theme }) => ({
  background: theme.custom.themeColors.grayScale[10],
  '&:hover': {
    backgroundColor: theme.custom.themeColors.grayScale[10],
  },
}));

const DeniedRequestCard: FC<IDeniedRequestCardProps> = ({ request }) => {
  const { openDialog } = useDialog();
  const navigate = useNavigate();

  const navigateToPartner = () => {
    navigate(`/partners/${request.requestedToCompany.id}`);
  };
  const { requestTitle, requestType } = request;

  return (
    <Container
      key={request.id}
      maxWidth={300}
      minWidth={300}
      hasShadow={false}
      padding={2}
      data-cy="denied-request-card"
    >
      <Box display="flex" flexDirection="column" gap={0.5} height="100%">
        <DismissedTitle title={requestTitle} requestType={RequestType[requestType]} />
        <Box display="flex" justifyContent="space-between" alignItems="center" mt="auto">
          <ClickablePartnerName
            partnerName={request.requestedToCompany.name}
            hasIconBackground
            onClick={navigateToPartner}
          />
          <ThemeButton
            size="medium"
            color="YELLOW"
            startIcon={
              <Icon
                name={
                  REQUEST_STATUS_CLIENT[request.requestType][PartnerRequestStatus.EDITED].iconName
                }
              />
            }
            onClick={() => openDialog({ type: 'REQUEST_DETAILS', props: { request } })}
            data-cy="view-request-details-btn"
          >
            Review
          </ThemeButton>
        </Box>
      </Box>
    </Container>
  );
};

export default DeniedRequestCard;
