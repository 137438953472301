import { Box } from '@mui/material';
import { useConfig } from 'components/hooks';
import { ActionLink, CardContainer, CardIllustration, ThemeTypography } from 'designSystem';
import React from 'react';
import { AvailableSizes, BackgroundTypes, IllustrationTypes } from 'types/enums';
import { forceOpenTawkDialog } from 'utils';

const SupplyChainInspiration: React.FC = () => {
  const { tracingArticle } = useConfig();

  return (
    <CardContainer
      title="Do you want to trace your product's supply chains?"
      size={AvailableSizes.SMALL}
      background={BackgroundTypes.RECTANGLE}
      flex={1}
      data-cy="inspiration-card"
    >
      <ThemeTypography variant="BODY_LARGE">
        We can offer custom solutions with deeper tracing through blockchain technology. View one of
        our previous case studies, or contact us about a pilot.
      </ThemeTypography>
      <Box display="flex" justifyContent="space-between" mt="auto">
        <CardIllustration image={IllustrationTypes.ROCKET} width={160} ml={-3} mb={-5} />
        <Box display="flex" justifyContent="end" mt="auto" pt={3}>
          <Box mr={2}>
            <ActionLink onClick={() => window.open(tracingArticle, '_blank')}>
              See case study
            </ActionLink>
          </Box>
          <ActionLink onClick={forceOpenTawkDialog} target="_blank">
            Contact us
          </ActionLink>
        </Box>
      </Box>
    </CardContainer>
  );
};

export default SupplyChainInspiration;
