import { Checkbox, TableCell, TableRow, TableSortLabel } from '@mui/material';
import { StyledTableHead } from 'components/TableBase';
import React, { ChangeEvent, FC, Fragment } from 'react';
import { HeaderColumn } from 'types/types';

interface SortableTableHeadProps {
  order?: 'asc' | 'desc';
  orderBy?: string;
  headerColumns: HeaderColumn[];
  multiselect?: boolean;
  onSort?: (id: string) => void;
  onSelectAll?: (select: boolean) => void;
}

const SortableTableHead: FC<SortableTableHeadProps> = ({
  order = 'asc',
  orderBy,
  headerColumns,
  multiselect = true,
  onSort,
  onSelectAll,
}) => {
  const handleSelectAll = (event: ChangeEvent<HTMLInputElement>) => {
    onSelectAll?.(event.target.checked);
  };

  return (
    <StyledTableHead>
      <TableRow>
        {onSelectAll ? (
          <TableCell padding="checkbox">
            <Checkbox
              inputProps={{ 'aria-label': 'select all items' }}
              onChange={handleSelectAll}
              disabled={!multiselect}
            />
          </TableCell>
        ) : (
          <Fragment />
        )}
        {headerColumns.map(({ headerName, id, align, sortable, ...headCell }) => (
          <TableCell key={id} align={align === 'right' ? 'right' : 'left'} {...headCell}>
            {sortable ? (
              <TableSortLabel
                active={!!(orderBy && orderBy === id)}
                direction={orderBy && orderBy === id ? order : 'asc'}
                onClick={() => (onSort ? onSort(id) : null)}
              >
                {headerName}
              </TableSortLabel>
            ) : (
              headerName
            )}
          </TableCell>
        ))}
      </TableRow>
    </StyledTableHead>
  );
};

export default SortableTableHead;
