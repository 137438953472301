import { styled } from '@mui/material/styles';
import { CameraVideo } from '@styled-icons/bootstrap/CameraVideo';
import { useSelectable } from 'components/MediaLibrary/hooks';
import PropTypes from 'prop-types';
import React from 'react';
import ReactPlayer from 'react-player';
import ListItem from './ListItem';

const TranscodingItem = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  background: theme.palette.grey[100],
  color: theme.palette.grey[500],
  fontSize: 12,
}));

const VideoContainer = styled(({ children, ...rest }) => <div {...rest}>{children}</div>)(
  ({ theme }) => ({
    position: 'relative',
    width: 160,
    height: 160,
    borderRadius: 4,
    backgroundColor: theme.palette.grey[200],

    '&:after': {
      position: 'absolute',
      content: '""',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
  })
);

const Video = ({ video }) => {
  const { onSelect, selectedItem } = useSelectable();
  const isSelected = selectedItem.id === video.videoId;
  const { videoId: id, isTranscoding, fileName } = video;

  return (
    <ListItem
      selected={isSelected}
      onSelect={() => {
        // If the video is still transcoding it does not completely exist yet,
        // hence we should not allow selection of the item just yet
        if (!isTranscoding) {
          onSelect({ ...video, id });
        }
      }}
      fileName={fileName}
      uploading={false}
      item={video}
    >
      <VideoContainer key={`${id}${isTranscoding}`}>
        {!isTranscoding && (
          <ReactPlayer
            url={`https://vimeo.com/${video.vimeoId}`}
            width="unset"
            height="100%"
            controls={true}
          />
        )}
        {isTranscoding && (
          <TranscodingItem className="shimmer">
            <CameraVideo size={58} />
            <span>Processing</span>
          </TranscodingItem>
        )}
      </VideoContainer>
    </ListItem>
  );
};

Video.propTypes = {
  video: PropTypes.object,
};

export default Video;
