export const PAGE_BLOCKS = {
  MAIN_SCREEN: 'Landing (mobile)',
  PRODUCT_JOURNEY_BLOCK: 'Product journey',
  ABOUT_BLOCK: 'About',
  IMPACT_FACTS_BLOCK: 'Impact facts',
  PRODUCTS_BLOCK: 'Products',
  FEEDBACK_BLOCK: 'Feedback',
  MORE_INFO_BLOCK: 'Additional info',
  IMPACT_CLAIMS: 'Impact claims',
  VERIFIED_PAYMENT_BLOCK: 'Verified payments',
};

export const clickedIds = {
  productSummary: 'ProductSummary:website-link',
  about: 'About:learn-more-button',
  socialMedia: 'SocialMedia',
  onlineShop: 'SidebarMenu:visit-online-shop-link',
};
