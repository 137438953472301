import { CompositeDecorator, ContentBlock, ContentState, DraftDecoratorType } from 'draft-js';
import React, { FC, ReactNode } from 'react';

interface ILinkProps {
  children: ReactNode;
  contentState: ContentState;
  entityKey: string;
}

const findLinkEntities = (
  contentBlock: ContentBlock,
  callback: (start: number, end: number) => void,
  contentState: ContentState
) => {
  contentBlock.findEntityRanges(character => {
    const entityKey = character.getEntity();
    return entityKey !== null && contentState.getEntity(entityKey).getType() === 'LINK';
  }, callback);
};

const Link: FC<ILinkProps> = ({ children, contentState, entityKey }) => {
  const { url } = contentState.getEntity(entityKey).getData();
  return (
    <a href={url} target="_blank" rel="noreferrer">
      {children}
    </a>
  );
};

const Decorator: DraftDecoratorType = new CompositeDecorator([
  {
    strategy: findLinkEntities,
    component: Link,
  },
]);

export default Decorator;
