export const stripValuesToSchema = (values, schema) => {
  if (!schema) return values;

  let strippedValues;

  try {
    strippedValues = schema.validateSync(values, {
      abortEarly: false,
      stripUnknown: true,
    });
  } catch (e) {
    strippedValues = e.value;
  }

  return strippedValues;
};
