import { useMutation } from '@apollo/client';
import useUserData from 'components/hooks/useUserData';
import { LOGOUT } from 'graphql/mutations';
import { useEffect } from 'react';

/**
 * Automatically logout user when user is still found
 * This should be used on un-authenticated pages to make sure the data is cleared
 */
const useAutoLogout = () => {
  const { user } = useUserData();
  const [logout] = useMutation(LOGOUT);

  useEffect(() => {
    if (!!user) {
      logout();
    }
    // No dependencies because checking only on render will be enough, otherwise it will trigger on login
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useAutoLogout;
