import React, { FC } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { LoginForm, AuthLayout } from 'components/Auth';
import { useBackendErrorMessage, useLogEvent } from 'components/hooks';
import { useMutation } from '@apollo/client';
import { LOGIN } from 'graphql/mutations';
import useAutoLogout from 'components/hooks/useAutoLogout';

const Login: FC = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { logEvent } = useLogEvent();

  // Logout user when still found
  useAutoLogout();

  const setBackendError = useBackendErrorMessage();
  const [login] = useMutation<null, { email: string; password: string }>(LOGIN, {
    onError: error => {
      setBackendError(
        error.message,
        'We are experiencing issues logging you in. Please try again or contact our support.'
      );
    },
    onCompleted: () => {
      logEvent('LOGGED_IN');
      const redirect = state?.redirect || '/';
      navigate(redirect);
    },
    fetchPolicy: 'no-cache',
  });

  const onSubmit = ({ email, password }: { email: string; password: string }) => {
    return login({
      variables: { email, password },
    });
  };

  return (
    <AuthLayout title="Login">
      <LoginForm onSubmit={onSubmit} />
    </AuthLayout>
  );
};

export default Login;
