import React, { useState, useEffect } from 'react';
import { useFormikContext } from 'formik';
import Autosave from './Autosave';

const FormikAutosave = props => {
  const [dirtyOnce, setDirtyOnce] = useState(false);
  const { values, dirty } = useFormikContext();

  useEffect(() => {
    // formik resets dirty to false if values are the same as initial values
    // does not trigger autosave e.g. when setting a value from true -> false -> true
    if (dirty) setDirtyOnce(true);
  }, [dirty]);

  return <Autosave values={values} dirty={dirtyOnce} {...props} />;
};

export default FormikAutosave;
