import { useApolloClient } from '@apollo/client';
import { getDimensionsImage } from 'components/MediaLibrary/utils';
import { UPLOAD_IMAGE } from 'graphql/mutations';
import { useCallback, useState } from 'react';
import { urlSafeFileName } from 'utils';

const useSingleImageUpload = () => {
  const client = useApolloClient();
  const [state, setState] = useState({
    uploadTask: null,
    record: null,
    error: null,
  });

  const uploadFile = useCallback(
    async ({ file, onError = () => null, onSuccess = () => null, onProgress = () => null }) => {
      try {
        const fileName = urlSafeFileName(file.name);
        const { width, height } = await getDimensionsImage(file);

        const {
          data: {
            uploadImage: { image },
          },
        } = await client.mutate({
          mutation: UPLOAD_IMAGE,
          variables: {
            input: {
              size: file.size,
              fileName,
              width,
              height,
              exclude: true,
            },
          },
        });

        const storageRef = client.defaultOptions.firebase.getFirebase().storage().ref(image.path);

        const uploadTask = storageRef.put(file);

        uploadTask.on(
          'state_changed',
          ({ bytesTransferred, totalBytes }) => {
            const progress = parseInt(((bytesTransferred / totalBytes) * 100).toFixed(2));

            onProgress(progress);
          },
          // error handler
          () => onError(),
          // success handler
          () => onSuccess()
        );

        const newState = {
          uploadTask,
          record: image,
        };

        setState(newState);
        return newState;
      } catch (error) {
        const newState = {
          ...state,
          error: error.message,
        };

        setState(newState);
        onError(error.message);
        return newState;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return [uploadFile, { ...state }];
};

export default useSingleImageUpload;
