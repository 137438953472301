import { gql } from '@apollo/client';

export const REACTION_FRAGMENT = gql`
  fragment reactionValues on Reaction {
    id
    feedback
    rating
    createdTimestamp
    country
    city
  }
`;
