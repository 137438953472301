import React, { FC } from 'react';
import { Box, styled } from '@mui/material';
import { ThemeTypography } from 'designSystem';

interface INumberContainerProps {
  number: number;
  currency?: string;
  text: string;
}

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(2, 2.5),
  borderRadius: 6,
  background: theme.custom.themeColors.grayScale[10],

  '&:before': {
    content: '""',
    display: 'block',
    width: 4,
    height: '100%',
    borderRadius: 4,
    background: theme.custom.themeColors.grayScale[30],
    marginRight: theme.spacing(1),
  },
}));

const Number = styled(ThemeTypography)(({ theme }) => ({
  fontSize: '24px !important',
}));

const Currency = styled(ThemeTypography)(({ theme }) => ({
  fontSize: '16px !important',
  marginRight: theme.spacing(0.5),
  paddingTop: 3,
}));

const NumberContainer: FC<INumberContainerProps> = ({ number, currency, text }) => {
  return (
    <Container>
      <Box display="flex" flexDirection="column">
        <Box display="flex" alignItems="center">
          {currency && (
            <Currency variant="TITLE_LARGE" color="GRAY_60">
              {currency}
            </Currency>
          )}
          <Number variant="TITLE_LARGE" color="BLUE_100">
            {number.toLocaleString('en-US').replace(/,/g, ' ')}
          </Number>
        </Box>
        <ThemeTypography variant="BODY_SMALLER" color="BLUE_100">
          {text}
        </ThemeTypography>
      </Box>
    </Container>
  );
};

export default NumberContainer;
