import { GeoJsonLayer } from '@deck.gl/layers/typed';
import { Box, styled } from '@mui/material';
import {
  Feature,
  FeatureCollection,
  Polygon,
  area,
  center,
  featureCollection,
  geometry,
  polygon,
} from '@turf/turf';
import { COLOR_PALETTE } from 'constants/colors';
import { ThemeTypography } from 'designSystem';
import CustomMap, { IMapMarker, IMapPopup, MapMarkerClickEvent } from 'designSystem/Map/CustomMap';
import uniqBy from 'lodash/uniqBy';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { ImageVariant } from 'types/media.types';
import { IBaseFarmSite, ICultivatedAreas, IFarmSite } from 'types/site.types';
import { convertHexToRGBarray } from 'utils';
import { v4 as uuid } from 'uuid';
import CultivationFarmAreaInfoPopup from './CultivationFarmAreaInfoPopup';

type ICultivationFarmAreaProps = (
  | { cultivatedAreas: ICultivatedAreas[] }
  | { farms: IFarmSite[] }
) & {
  /**
   * Shows the partner that own the cultivation areas
   */
  showOwner?: boolean;
  onEditCultivatedAreaClick: (activityId: string) => void;
};

const CultivatedAreasSize = styled(Box)(({ theme }) => ({
  position: 'absolute',
  background: theme.custom.themeColors.grayScale[20],
  padding: theme.spacing(1),
  borderRadius: theme.spacing(0.5),
  zIndex: 3,
  right: 46,
  top: 10,
}));

export interface IFeatureProperties {
  id: string;
  color: string;
  title: string;
  name: string;
  /** in square km */
  areaSize: number;
  ownedBy?: {
    id: string;
    name: string;
    logo?: ImageVariant | null;
  };
  outputTitle?: string;
  // Center of this single cultivation area
  polygonCenter: [number, number];
  // Center of the multi polygon all cultivation areas
  multiPolygonCenter?: [number, number];
  // Hide the action button in the info popup
  hideActionButton?: boolean;
}

const CultivationFarmArea: FC<ICultivationFarmAreaProps> = ({
  showOwner,
  onEditCultivatedAreaClick,
  ...props
}) => {
  const [infoPopup, setInfoPopup] = useState<IMapPopup>();
  const [mapZoom, setMapZoom] = useState<number>();

  const handleEditCultivatedArea = (id: string) => {
    setInfoPopup(undefined);
    onEditCultivatedAreaClick(id);
  };

  const handleFeatureClick = useCallback(
    (featureProperties: IFeatureProperties) => {
      setInfoPopup(prev => {
        if (prev && prev.id === featureProperties.id) {
          return undefined;
        }
        return {
          id: featureProperties.id,
          coordinate: featureProperties.polygonCenter,
          content: (
            <CultivationFarmAreaInfoPopup
              showOwner={showOwner}
              infoItem={featureProperties}
              onEditClick={
                !featureProperties.hideActionButton
                  ? () => handleEditCultivatedArea(featureProperties.id)
                  : undefined
              }
            />
          ),
        };
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [infoPopup, setInfoPopup]
  );

  /**
   * Unique farms of the cultivation areas
   */
  const farms = useMemo(
    () =>
      'cultivatedAreas' in props
        ? uniqBy(
            props.cultivatedAreas.map(({ farm }) => farm),
            'id'
          )
        : props.farms,
    [props]
  );

  /**
   * Unique farm ids of the cultivation areas
   */
  const farmIds = useMemo(() => farms.map(({ id }) => id), [farms]);

  /**
   * This feature collections contains all the cultivation areas of the farm as single polygons with their properties
   * To be able to show the info popup when clicking on the cultivation area on a single polygon we need to have the center of each polygon
   * To be able to show centered markers of the one cultivation area we need to have the center of the multi polygon
   */
  const featuresCollection: FeatureCollection<Polygon, IFeatureProperties> = useMemo(() => {
    if ('cultivatedAreas' in props) {
      const features: Feature<Polygon, IFeatureProperties>[] = props.cultivatedAreas
        .map(({ id, farmId, farm: { title, locationName, rawMaterial, ownedBy }, coordinates }) => {
          if (coordinates?.length) {
            const multiPolygonGeometry = geometry('Polygon', coordinates);
            return coordinates.map(singlePolygon => {
              const polygonGeometry = geometry('Polygon', [singlePolygon]);
              return polygon<IFeatureProperties>(
                [singlePolygon],
                {
                  id,
                  // Use same color for all cultivation areas of the same farm
                  color:
                    COLOR_PALETTE[
                      farmIds.findIndex(_farmId => farmId === _farmId) % COLOR_PALETTE.length
                    ],
                  name: locationName,
                  title: title,
                  outputTitle: rawMaterial?.title,
                  ownedBy: ownedBy,
                  areaSize: Math.round(area(polygonGeometry)) / 1000000,
                  multiPolygonCenter: center(multiPolygonGeometry).geometry.coordinates as [
                    number,
                    number
                  ],
                  polygonCenter: center(polygonGeometry).geometry.coordinates as [number, number],
                },
                { id: uuid() }
              );
            });
          }
          return [];
        })
        .flat();

      return featureCollection(features);
    } else {
      const features: Feature<Polygon, IFeatureProperties>[] = props.farms
        .map(({ id, title, locationName, rawMaterial, ownedBy, cultivatedAreas }) => {
          if (cultivatedAreas?.length && cultivatedAreas[0].coordinates) {
            const multiPolygonGeometry = geometry('Polygon', cultivatedAreas[0].coordinates);
            return cultivatedAreas[0].coordinates.map(singlePolygon => {
              const polygonGeometry = geometry('Polygon', [singlePolygon]);
              return polygon<IFeatureProperties>(
                [singlePolygon],
                {
                  id,
                  // Use same color for all cultivation areas of the same farm
                  color:
                    COLOR_PALETTE[
                      farmIds.findIndex(farmId => farmId === id) % COLOR_PALETTE.length
                    ],
                  name: locationName,
                  title: title,
                  outputTitle: rawMaterial?.title,
                  ownedBy: ownedBy,
                  areaSize: Math.round(area(polygonGeometry)) / 1000000,
                  multiPolygonCenter: center(multiPolygonGeometry).geometry.coordinates as [
                    number,
                    number
                  ],
                  polygonCenter: center(polygonGeometry).geometry.coordinates as [number, number],
                },
                { id: uuid() }
              );
            });
          }
          return [];
        })
        .flat();

      return featureCollection(features);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  const layer = useMemo(
    () =>
      new GeoJsonLayer<IFeatureProperties>({
        id: 'polygons',
        data: featuresCollection,
        getLineWidth: 4,
        pickable: true,
        getFillColor: feature => convertHexToRGBarray(feature.properties?.color, 100),
        getLineColor: feature => convertHexToRGBarray(feature.properties?.color, 255),
        onClick: info => handleFeatureClick(info.object.properties),
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [featuresCollection]
  );

  const pointFeatures: IMapMarker[] = useMemo(() => {
    return 'cultivatedAreas' in props
      ? props.cultivatedAreas
          .filter(({ centerPoint, coordinates }) => !coordinates && centerPoint)
          .map(({ centerPoint, farmId }) => ({
            id: farmId,
            coordinate: [centerPoint?.lng || 0, centerPoint?.lat || 0],
            options: {
              customIcon: 'map-marker',
              // Use same color for all cultivation areas of the same farm
              color:
                COLOR_PALETTE[
                  farmIds.findIndex(_farmId => farmId === _farmId) % COLOR_PALETTE.length
                ],
            },
          }))
      : props.farms
          .filter(
            ({ cultivatedAreas }) =>
              cultivatedAreas?.length &&
              !cultivatedAreas[0].coordinates &&
              cultivatedAreas[0].centerPoint
          )
          .map(({ id, cultivatedAreas }) => ({
            id,
            coordinate: [
              cultivatedAreas?.[0].centerPoint?.lng || 0,
              cultivatedAreas?.[0].centerPoint?.lat || 0,
            ],
            options: {
              customIcon: 'map-marker',
              // Use same color for all cultivation areas of the same farm
              color:
                COLOR_PALETTE[farmIds.findIndex(farmId => id === farmId) % COLOR_PALETTE.length],
            },
          }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props, farmIds]);

  const handleMarkerClick = useCallback(
    (event: MapMarkerClickEvent) => {
      const farm: IBaseFarmSite | undefined = farms.find(farm => farm.id === event.id);
      if (!farm) {
        return;
      }
      handleFeatureClick({
        id: farm?.id,
        title: farm?.title,
        color: '', // Actually not needed since the color is not used in the marker
        name: farm?.locationName,
        areaSize: farm?.size,
        ownedBy: farm?.ownedBy,
        outputTitle: farm?.rawMaterial?.title,
        polygonCenter: event.coordinate,
        hideActionButton: true,
      } as IFeatureProperties);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [farms]
  );

  /**
   * Markers are only shown when the zoom level is higher than 10 to identify the cultivation areas if the world is zoomed out
   */
  const markers: IMapMarker[] = useMemo(
    () =>
      mapZoom && mapZoom < 12
        ? featuresCollection.features.reduce((prev, feature) => {
            const coordinate = feature.properties.multiPolygonCenter;
            if (!coordinate) {
              return prev;
            }
            // If marker for that cultivation area was already added, don't add it again
            if (prev.findIndex(prevMarker => prevMarker.coordinate === coordinate) !== -1) {
              return prev;
            }
            return [
              ...prev,
              {
                id: feature.properties.id,
                coordinate,
                options: { customIcon: 'map-marker', color: feature.properties.color },
              },
            ];
          }, [] as IMapMarker[])
        : [],
    [featuresCollection, mapZoom]
  );

  const totalAreaSize = useMemo(
    () => Math.round(area(featuresCollection)) / 1000000,
    [featuresCollection]
  );

  return (
    <Box width="100%" height="100%" position="relative" borderRadius={6}>
      <CultivatedAreasSize>
        <ThemeTypography variant="BODY_MEDIUM_BOLD" color="GRAY_80">
          Total area
        </ThemeTypography>
        <ThemeTypography variant="BODY_SMALL">{totalAreaSize} km²</ThemeTypography>
      </CultivatedAreasSize>
      <CustomMap
        markers={[...markers, ...pointFeatures]}
        layers={[layer]}
        infoPopup={infoPopup}
        mapStyle="satellite"
        style={{
          height: '400px',
          width: '100%',
          borderRadius: 6,
        }}
        config={{
          enableMapStyleToggle: true,
          enableCenterButton: true,
        }}
        onZoom={event => setMapZoom(event.viewState.zoom)}
        onMapLoad={map => setMapZoom(map.getZoom())}
        onMarkerClick={handleMarkerClick}
      />
    </Box>
  );
};

export default CultivationFarmArea;
