import React, { FC } from 'react';
import BatchTable from './BatchTable';
import { styled, Box, Grid, Divider } from '@mui/material';
import { GeoCBatch } from 'types/compliance.types';
import { DropDown } from 'components/Forms';
import { AvailableSizes } from 'types/enums';
import ThemeTypography from 'designSystem/Primitives/Typography/ThemeTypography';

interface IBatchSelectionProps {
  batches: GeoCBatch[];
  selectedBatch: GeoCBatch;
  onSelectBatch: (batch: GeoCBatch) => void;
}

const Container = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.custom.themeColors.grayScale[20]}`,
  background: theme.custom.themeColors.white,
  borderRadius: 6,
  padding: theme.spacing(2),
}));

const DropdownContainer = styled(Box)(({ theme }) => ({
  '& .MuiSelect-select': {
    background: '#fff !important',
    borderRadius: 4,
    border: `1px solid #EEEEEE`,
    paddingTop: '4px !important',
    paddingBottom: '4px !important',
    maxWidth: 120,

    '& .MuiTypography-root': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
}));

const BatchSelection: FC<IBatchSelectionProps> = ({ batches, selectedBatch, onSelectBatch }) => {
  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <BatchTable batches={batches} />
        </Grid>
        <Grid item xs={4}>
          <Box display="flex" alignItems="center" height="100%" gap={2}>
            <Divider orientation="vertical" flexItem />
            <DropdownContainer>
              <ThemeTypography variant="LABEL_INPUT">Show batch</ThemeTypography>
              <Box mb={0.5} />
              <DropDown
                size={AvailableSizes.SMALL}
                options={batches}
                onChange={(item: GeoCBatch) => {
                  onSelectBatch(item);
                }}
                renderItems={(item: GeoCBatch) => (
                  <ThemeTypography variant="BODY_SMALL">{item.id}</ThemeTypography>
                )}
                currentValue={selectedBatch}
              />
            </DropdownContainer>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default BatchSelection;
