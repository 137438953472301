import { useEffect, useState } from 'react';

// eslint-disable-next-line
const useIsOverflow = (ref: React.MutableRefObject<HTMLDivElement | null> | undefined) => {
  const [isOverflow, setIsOverflow] = useState<boolean | undefined>(undefined);

  const trigger = () => {
    if (!ref) {
      return;
    }
    const { current } = ref;
    if (current) {
      const hasOverflow = current.scrollWidth > current.clientWidth;

      setIsOverflow(hasOverflow);
    }
  };

  useEffect(() => {
    trigger();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);

  useEffect(() => {
    window.addEventListener('resize', trigger);

    return () => {
      window.removeEventListener('resize', trigger);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isOverflow;
};

export default useIsOverflow;
