import { gql } from '@apollo/client';

export const VIDEO_FRAGMENT = gql`
  fragment video on Video {
    videoId: id
    fileName
    fileSize
    uri
    vimeoLink
    createdTimestamp
    thumbnail
    vimeoId
    transcodeStatus
    isTranscoding @client
  }
`;

export const IMAGE_VARIANT_FRAGMENT = gql`
  fragment imageVariant on ImageVariant {
    id
    imageId
    path
    url
    width
    height
    size
    crop {
      x
      y
      width
      height
    }
  }
`;
