import React from 'react';
import { styled } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { InputAdornment } from '@mui/material';
import TextField from '@mui/material/TextField';
import { getIn } from 'formik';

const StyledAdornment = styled(InputAdornment)(({ theme }) => ({
  marginRight: 0,

  '& p': {
    fontWeight: 700,
    color: theme.palette.grey[500],
  },
}));

const useStyles = makeStyles(theme => ({
  input: {
    '& .Mui-disabled': {
      color: theme.palette.grey[500],
      background: theme.custom.colors.backgroundMedium,
    },
    '& input': {
      fontWeight: 700,
      color: theme.palette.grey[800],
    },
  },
}));

const TextInputFileExtension = props => {
  const { field, form } = props;
  const { value, name } = field;
  const { setFieldValue, errors, touched } = form;
  const fieldError = getIn(errors, name);
  const showError = getIn(touched, name) && !!fieldError;

  const classes = useStyles();
  // Splits the string on the dot, and takes the last piece of text as the file extension
  const fileExtension = value.split('.').pop();
  // Splits the string on the dot, then glues all the values back together except the last item ( extension )
  const fileName = value.split('.').slice(0, -1).join('.');

  return (
    <TextField
      {...props}
      variant="outlined"
      className={classes.input}
      value={fileName}
      error={showError && !!fieldError}
      helperText={showError && fieldError}
      onChange={event => {
        // Adds the fileExtension back to the changed value so the full extension is always in formik.
        setFieldValue(name, [event.target.value, fileExtension].join('.'));
      }}
      InputProps={{
        endAdornment: <StyledAdornment position="start">{`.${fileExtension}`}</StyledAdornment>,
      }}
    />
  );
};

export default TextInputFileExtension;
