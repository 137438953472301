import React, { Fragment } from 'react';
import { useQuery } from '@apollo/client';
import { GET_TERMS } from 'graphql/queries';
import { Box } from '@mui/material';
import { Link, RichText } from 'prismic-dom';
import { Loader } from 'components/Forms';
import ErrorState from 'components/Structure/ErrorState';
import Moment from 'react-moment';
import { ThemeTypography } from 'designSystem';

const { Elements } = RichText;

const htmlSerializer = function (type, element, content, children) {
  switch (type) {
    case Elements.heading1:
      return `<h1>${children.join('')}</h1>`;
    case Elements.heading2:
      return `<h2>${children.join('')}</h2>`;
    case Elements.heading3:
      return `<h3>${children.join('')}</h3>`;
    case Elements.heading4:
      return `<h4>${children.join('')}</h4>`;
    case Elements.heading5:
      return `<h5>${children.join('')}</h5>`;
    case Elements.heading6:
      return `<h6>${children.join('')}</h6>`;
    case Elements.paragraph:
      return `<p>${children.join('')}</p>`;
    case Elements.preformatted:
      return `<pre>${children.join('')}</pre>`;
    case Elements.strong:
      return `<strong>${children.join('')}</strong>`;
    case Elements.em:
      return `<em>${children.join('')}</em>`;
    case Elements.listItem:
      return `<li>${children.join('')}</li>`;
    case Elements.oListItem:
      return `<li>${children.join('')}</li>`;
    case Elements.list:
      return `<ul>${children.join('')}</ul>`;
    case Elements.oList:
      return `<ol>${children.join('')}</ol>`;
    case Elements.image:
      const imageLinkUrl = element.linkTo
        ? Link.url(element.linkTo, module.exports.linkResolver)
        : null;
      const linkTarget =
        element.linkTo && element.linkTo.target
          ? `target="${element.linkTo.target}" rel="noopener"`
          : '';
      const wrapperClassList = [element.label || '', 'block-img'];
      const img = `<img src="${element.url}" alt="${element.alt || ''}" copyright="${
        element.copyright || ''
      }">`;
      return `
        <p class="${wrapperClassList.join(' ')}">
          ${imageLinkUrl ? `<a ${linkTarget} href="${imageLinkUrl}">${img}</a>` : img}
        </p>
      `;
    case Elements.embed:
      return `
        <div data-oembed="${element.oembed.embed_url}"
          data-oembed-type="${element.oembed.type}"
          data-oembed-provider="${element.oembed.provider_name}"
        >
          ${element.oembed.html}
        </div>
      `;
    case Elements.hyperlink:
      const target = element.data.target ? `target="${element.data.target}" rel="noopener"` : '';
      const linkUrl = Link.url(element.data, linkResolver);
      return `<a ${target} href="${linkUrl}">${children.join('')}</a>`;
    case Elements.label:
      const label = element.data.label ? ` class="${element.data.label}"` : '';
      return `<span ${label}>${children.join('')}</span>`;
    case Elements.span:
      return content ? content.replace(/\n/g, '<br />') : '';
    default:
      return null;
  }
};

const linkResolver = function (doc) {
  return doc.id;
};

const TermsContent = () => {
  const { data: { getTerms: { terms } = {} } = {}, error, loading } = useQuery(GET_TERMS);

  if (loading)
    return (
      <Box p={4}>
        <Loader />
      </Box>
    );

  if (error) return <ErrorState error="Something went wrong fetching the Terms and Conditions" />;

  const {
    last_publication_date,
    data: { text_body },
  } = terms;
  const titleHtml = RichText.asHtml(text_body, linkResolver, htmlSerializer);
  return (
    <Fragment>
      <ThemeTypography variant="BODY_LARGE">
        <Box display="flex">
          <Box>
            <Moment format="MMM DD, YYYY">{last_publication_date}</Moment>
          </Box>
        </Box>
      </ThemeTypography>
      <div
        dangerouslySetInnerHTML={{
          __html: titleHtml,
        }}
      ></div>
    </Fragment>
  );
};
export default TermsContent;
