import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { FC } from 'react';

interface ISelectImpactLevelDescriptionProps {
  description: string;
}

const Description = styled(Typography)(() => ({
  fontWeight: 500,
  fontSize: 14,
}));

const SelectImpactLevelDescription: FC<ISelectImpactLevelDescriptionProps> = ({ description }) => {
  return (
    <Box mt={2} data-cy="impact-level-discription">
      <Description variant="h6" color="textSecondary">
        {description}
      </Description>
    </Box>
  );
};

export default SelectImpactLevelDescription;
