import CloudConvert from 'cloudconvert';
import { impactCategoryConfig } from 'components/ImpactClaims/constants/impactCategoryConfig';
import find from 'lodash/find';

class Config {
  constructor(config) {
    Object.assign(this, config);

    this.fireBaseConfig = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG);
    this.mapBoxAccessToken = process.env.REACT_APP_MAP_BOX_TOKEN;
    this.qrCodeGeneratorAccessToken = process.env.REACT_APP_QR_CODE_GENERATOR_API_KEY;
    this.claimCategories = impactCategoryConfig;
    this.claimCategoriesArray = Object.keys(impactCategoryConfig).map(
      category => impactCategoryConfig[category]
    );
    this.cloudConvert = new CloudConvert(
      process.env.REACT_APP_CLOUD_CONVERT_API_KEY,
      process.env.REACT_APP_CLOUD_CONVERT_SANDBOX_MODE === 'true'
    );
  }

  generatePreviewURL(slug, lang) {
    return `${this.productURL}/${slug}?preview=1${lang ? '&locale=' + lang : ''}`;
  }

  generateProductLiveURL(slug, lang = '') {
    return `${this.productURL}/${slug}${lang ? '?locale=' + lang : ''}`;
  }

  generateMapboxTileUrl() {
    return `${this.tileURL}?access_token=${this.mapBoxAccessToken}`;
  }

  generateGoogleFontsUrl() {
    return `${this.fontsURL}&key=${this.fireBaseConfig.apiKey}`;
  }

  generatePrismicMasterRefUrl() {
    return `${this.prismicUrl}`;
  }

  generatePrismicDocumentUrl(ref) {
    return `${this.prismicUrl}/documents/search?ref=${ref}&q=${this.prismicTermsDocId}`;
  }

  generateQrCodeGeneratorUrl() {
    return `${this.qrCodeGeneratorUrl}?access-token=${this.qrCodeGeneratorAccessToken}`;
  }

  getLanguageConfig(key) {
    return find(this.productLanguages, ['key', key]);
  }

  generateRedirectURL(productId) {
    return `${this.productURL}/r/${productId}?qr=default`;
  }

  generatePreviewConsumerURl(slug, lang) {
    return `${this.productURL}/${slug}?preview=1&mobile=1&iframe=1&locale=${lang}`;
  }
}

export default Config;
