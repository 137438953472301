import React from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Plus } from '@styled-icons/bootstrap/Plus';
import spark from 'assets/img/spark.png';
import ImpactClaimSkeleton from 'components/ImpactClaims/Table/ImpactClaimSkeleton';
import PropTypes from 'prop-types';
import { useDialog } from 'components/hooks';
import { ThemeButton, CardContainer, ActionLink } from 'designSystem';

const CONTAINER_WIDTH = 450;

const Spark = styled('img')(() => ({
  position: 'absolute',
  width: 60,
  height: 60,
  left: CONTAINER_WIDTH - 25,
  top: -25,
}));

const NextStepClaim = ({ handleShowNextCard, minHeight }) => {
  const { openDialog } = useDialog();

  return (
    <CardContainer
      title="Add your claims to prove your social and environmental impact"
      background="WORLD"
      size="MEDIUM"
      minHeight={minHeight}
    >
      <Box mt="auto" pb={2} position="relative">
        <CardContainer padding={1.5} width={CONTAINER_WIDTH}>
          <ImpactClaimSkeleton />
        </CardContainer>
        <Spark src={spark} />
      </Box>

      <Box display="flex" alignItems="center" gap={2.5}>
        <ThemeButton
          onClick={() => {
            openDialog({ type: 'CREATE_IMPACT_CLAIM' });
          }}
          startIcon={<Plus size={20} />}
          data-cy="claims-getting-started-create-btn"
          size="large"
          color="YELLOW"
        >
          Add claim
        </ThemeButton>
        {handleShowNextCard && <ActionLink onClick={handleShowNextCard}>Next tip</ActionLink>}
      </Box>
    </CardContainer>
  );
};

NextStepClaim.propTypes = {
  handleShowNextCard: PropTypes.func,
  minHeight: PropTypes.number,
};

export default NextStepClaim;
