import { Box, Card, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Loader } from 'components/Forms';
import React, { FC, PropsWithChildren, ReactNode } from 'react';

const Container = styled(Card)<{
  flexGrow: number;
  width?: number;
  fullHeight: boolean;
}>(({ theme, flexGrow, width, fullHeight }) => ({
  position: 'relative',
  height: fullHeight ? '100%' : 'auto',
  display: 'flex',
  flexDirection: 'column',
  flexGrow,
  width,
  flexShrink: 0,
  padding: theme.spacing(2),
  margin: theme.spacing(1),
  boxShadow: theme.shadows[28 as number],
}));

const Label = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  fontWeight: 600,
  textTransform: 'uppercase',
  marginRight: theme.spacing(1),
}));

interface Props extends PropsWithChildren {
  label?: string;
  LabelAdornment?: ReactNode;
  ActionAdornment?: ReactNode;
  loading?: boolean;
  flexGrow?: number;
  fullHeight?: boolean;
  width?: number;
  className?: string;
}

const LoadingCard: FC<Props> = ({
  label,
  LabelAdornment,
  ActionAdornment,
  loading,
  flexGrow = 1,
  fullHeight = true,
  width,
  children,
  className,
  ...rest
}) => {
  return (
    <Container
      className={className}
      flexGrow={flexGrow}
      width={width}
      fullHeight={fullHeight}
      {...rest}
    >
      <Box display="flex" justifyContent="space-between" mb={label ? 3 : 0}>
        {label && (
          <Box display="flex">
            <Label>{label}</Label>
            {LabelAdornment}
          </Box>
        )}
        {ActionAdornment}
      </Box>
      {loading && <Loader overlay overlayShimmer spinner={false} />}
      {children}
    </Container>
  );
};

export default LoadingCard;
