import { Box, Grid, styled } from '@mui/material';
import PartnerAutocompleteWrapper from 'components/ChainMapping/Forms/PartnerAutocomplete';
import { DropDown, MediaField } from 'components/Forms';
import TextFieldLimited from 'components/Forms/TextFieldLimited';
import { applicationHints } from 'config/applicationHints';
import { FieldWrapper } from 'designSystem';
import HTMLEditorField from 'designSystem/Formik/HTMLEditorField/HTMLEditorField';
import { FastField as Field, useFormikContext } from 'formik';
import React, { FC } from 'react';
import { SiteType } from 'types/site.types';
import { SiteLabelKeys } from 'utils/site.utils';
import { FormValues } from './AddEditSiteDialog';
import FarmForm from './FarmForm';
import LocationSelectorBlock from './LocationSelectorBlock';

interface ISiteFormProps {
  hidePartnerSelector?: boolean;
  disableSiteSelection?: boolean;
  disableRawMaterial?: boolean;
  defaultCountry?: string;
}

const StyledCard = styled(Grid)(({ theme }) => ({
  borderRadius: 6,
  background: theme.custom.themeColors.white,
  boxShadow: theme.custom.shadows[4],
  minHeight: 150,
  padding: theme.spacing(2),
  maxHeight: 'calc(100vh - 300px)',
  overflowY: 'auto',
}));

export const StyledDropDown = styled(DropDown)(({ theme }) => ({
  '& .MuiSelect-select': {
    height: '30px !important',
    boxSizing: 'border-box',
  },
}));

const SiteForm: FC<ISiteFormProps> = ({
  hidePartnerSelector,
  disableSiteSelection,
  disableRawMaterial,
  defaultCountry,
}) => {
  const { values, setFieldValue, errors } = useFormikContext<FormValues>();
  const siteTypeOptions = Object.entries(SiteType)
    .filter(([key]) => key !== SiteType.CLUSTER)
    .map(([key, value]) => ({
      key,
      value: SiteLabelKeys[value],
    }));

  return (
    <StyledCard container rowSpacing={2}>
      <Grid item xs={3} mt={-2}>
        <FieldWrapper fullWidth>
          <Field
            component={MediaField}
            name="image"
            data-cy="field-site-image"
            legacyImage={false}
            styles={{
              height: 130,
              maxWidth: '90%',
            }}
          />
        </FieldWrapper>
      </Grid>

      <Grid item xs={9} mt={-2}>
        <Grid container spacing={2}>
          <Grid item xs={9}>
            <FieldWrapper label="Site title" fullWidth>
              <Field
                name="title"
                fullWidth
                placeholder="E.g. Farm name"
                component={TextFieldLimited}
                variant="outlined"
                size="small"
                charLimit={50}
                inputProps={{
                  'data-testid': 'field-site-title',
                }}
              />
            </FieldWrapper>
          </Grid>

          <Grid item xs={3}>
            <FieldWrapper
              label="Internal id"
              fullWidth
              tooltip={{
                helperText:
                  'Enter the id that you use in your internal systems, such as your ERP system. Using the same identifiers in the Seedtrace platform will help you  manage the supply chain data overall.',
                variant: 'INFO',
              }}
            >
              <Field
                component={TextFieldLimited}
                placeholder="Enter id"
                fullWidth
                name="externalId"
                variant="outlined"
                size="small"
                lang={null}
                inputProps={{
                  'data-testid': 'internal-id-input',
                }}
              />
            </FieldWrapper>
          </Grid>

          <Grid item xs={hidePartnerSelector ? 12 : 6}>
            <FieldWrapper
              label="Site type"
              required
              tooltip={{
                helperText:
                  'Enter the type that best matches the reality for this site. Certain site types belong at the origin of supply chains (like Farm, Mine and Forest) and some can vary (like Processing site, Factory and Warehouse).',
                variant: 'INFO',
              }}
            >
              <Field
                component={StyledDropDown}
                name="siteType"
                variant="outlined"
                size="small"
                disabled={disableSiteSelection}
                fullWidth
                placeholder="Select site type (e.g. Farm)"
                currentValue={values.siteType}
                options={siteTypeOptions}
                errorMsg={errors.siteType}
                keyField="key"
                data-cy="site-type-dropdown"
                onChange={(item: SiteType) => setFieldValue('siteType', item)}
                renderItems={(item: { key: keyof typeof SiteType; value: SiteType }) => (
                  <Box>
                    <span>{item.value}</span>
                  </Box>
                )}
              />
            </FieldWrapper>
          </Grid>

          {!hidePartnerSelector && (
            <Grid item xs={6}>
              <PartnerAutocompleteWrapper
                name="partner"
                helperText="Select the owner of this site."
                labelVariant="medium"
                size="small"
              />
            </Grid>
          )}
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <LocationSelectorBlock defaultCountry={defaultCountry} />
      </Grid>

      <Grid item xs={12}>
        <FieldWrapper
          label="Description"
          tooltip={{
            variant: 'HINT',
            helperText: applicationHints.site.description,
          }}
          pb={1}
        >
          <Field
            component={HTMLEditorField}
            name="description"
            minHeight={100}
            charLimit={650}
            placeholder="Describe what is happening at this site..."
            data-cy="site-description-input"
          />
        </FieldWrapper>
      </Grid>

      {values.siteType === 'FARM' && (
        <Box mt={2} width="100%">
          <FarmForm disableRawMaterial={disableRawMaterial} />
        </Box>
      )}
    </StyledCard>
  );
};

export default SiteForm;
