import { IconButton, ListItem, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

export const SelectorSearchBox = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
  background: '#fff',
  borderBottomLeftRadius: theme.shape.borderRadius,
  borderBottomRightRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(2),
  borderRadius: 5,
}));

export const SelectorFilterInput = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-input': {
    height: 30,
    padding: theme.spacing(0, 0, 0, 1),
    fontSize: 12,
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.custom.colors.backgroundLight,
  },
}));

export const SelectorListItem = styled(ListItem)(({ theme }) => ({
  padding: theme.spacing(1),
  marginBottom: theme.spacing(1),
  borderLeft: `5px solid transparent`,
  '&:hover': {
    cursor: 'pointer',
    background: '#fff',
  },
  '&.Mui-selected': {
    borderLeft: `5px solid ${theme.palette.secondary.main}`,
    background: '#fff',
    borderRadius: 5,
    '&:hover': {
      background: '#fff',
    },
  },
}));

export const SelectorEmptySearchButton = styled(IconButton)(({ theme }) => ({
  padding: 0,
  margin: 0,
  color: theme.palette.text.primary,
}));
