import { Table, TableBody, TableCell, TableHead, TableRow, styled } from '@mui/material';
import React, { FC } from 'react';

const StyledTable = styled(Table)(({ theme }) => ({
  '& .MuiTableCell-root': {
    border: `1px solid ${theme.custom.themeColors.grayScale[20]}`,
  },
  '& .MuiTableCell-head': {
    backgroundColor: theme.custom.themeColors.grayScale[10],
    fontWeight: 700,
    '&:first-of-type': {
      borderTopLeftRadius: 8,
    },
    '&:last-child': {
      borderTopRightRadius: 4,
    },
  },
  '& .MuiTableHead-root': {},
}));

const AnnexTable: FC = () => {
  return (
    <StyledTable>
      <TableHead>
        <TableRow>
          <TableCell width="15%" valign="top">
            <p>Relevant commodity</p>
          </TableCell>
          <TableCell valign="top">
            <p>Relevant products</p>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell valign="top">
            <p>Cattle</p>
          </TableCell>
          <TableCell valign="top">
            <p>0102&nbsp;21 , 0102&nbsp;29 Live cattle</p>
            <p>ex&nbsp;0201 Meat of cattle, fresh or chilled</p>
            <p>ex&nbsp;0202 Meat of cattle, frozen</p>
            <p>ex&nbsp;0206&nbsp;10 Edible offal of cattle, fresh or chilled</p>
            <p>ex&nbsp;0206&nbsp;22 Edible cattle livers, frozen</p>
            <p>ex&nbsp;0206&nbsp;29 Edible cattle offal (excluding tongues and livers), frozen</p>
            <p>
              ex&nbsp;1602&nbsp;50 Other prepared or preserved meat, meat offal, blood, of cattle
            </p>
            <p>
              ex&nbsp;4101 Raw hides and skins of cattle (fresh, or salted, dried, limed, pickled or
              otherwise preserved, but not tanned, parchment-dressed or further prepared), whether
              or not dehaired or split
            </p>
            <p>
              ex&nbsp;4104 Tanned or crust hides and skins of cattle, without hair on, whether or
              not split, but not further prepared
            </p>
            <p>
              ex&nbsp;4107 Leather of cattle, further prepared after tanning or crusting, including
              parchment-dressed leather, without hair on, whether or not split, other than leather
              of heading 4114{' '}
            </p>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell valign="top">
            <p>Cocoa</p>
          </TableCell>
          <TableCell valign="top">
            <p>1801 Cocoa beans, whole or broken, raw or roasted</p>
            <p>1802 Cocoa shells, husks, skins and other cocoa waste</p>
            <p>1803 Cocoa paste, whether or not defatted</p>
            <p>1804 Cocoa butter, fat and oil</p>
            <p>1805 Cocoa powder, not containing added sugar or other sweetening matter</p>
            <p>1806 Chocolate and other food preparations containing cocoa</p>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell valign="top">
            <p>Coffee</p>
          </TableCell>
          <TableCell valign="top">
            <p>
              0901 Coffee, whether or not roasted or decaffeinated; coffee husks and skins; coffee
              substitutes containing coffee in any proportion
            </p>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell valign="top">
            <p>Oil palm</p>
          </TableCell>
          <TableCell valign="top">
            <p>1207&nbsp;10 Palm nuts and kernels</p>
            <p>
              1511 Palm oil and its fractions, whether or not refined, but not chemically modified
            </p>
            <p>
              1513&nbsp;21 Crude palm kernel and babassu oil and fractions thereof, whether or not
              refined, but not chemically modified
            </p>
            <p>
              1513&nbsp;29 Palm kernel and babassu oil and their fractions, whether or not refined,
              but not chemically modified (excluding crude oil)
            </p>
            <p>
              2306&nbsp;60 Oilcake and other solid residues of palm nuts or kernels, whether or not
              ground or in the form of pellets, resulting from the extraction of palm nut or kernel
              fats or oils
            </p>
            <p>
              ex&nbsp;2905&nbsp;45 Glycerol, with a purity of 95&nbsp;% or more (calculated on the
              weight of the dry product)
            </p>
            <p>2915&nbsp;70 Palmitic acid, stearic acid, their salts and esters</p>
            <p>
              2915&nbsp;90 Saturated acyclic monocarboxylic acids, their anhydrides, halides,
              peroxides and peroxyacids; their halogenated, sulphonated, nitrated or nitrosated
              derivatives (excluding formic acid, acetic acid, mono-, di- or trichloroacetic acids,
              propionic acid, butanoic acids, pentanoic acids, palmitic acid, stearic acid, their
              salts and esters, and acetic anhydride)
            </p>
            <p>3823&nbsp;11 Stearic acid, industrial</p>
            <p>3823&nbsp;12 Oleic acid, industrial</p>
            <p>
              3823&nbsp;19 Industrial monocarboxylic fatty acids; acid oils from refining (excluding
              stearic acid, oleic acid and tall oil fatty acids)
            </p>
            <p>3823&nbsp;70 Industrial fatty alcohols</p>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell valign="top">
            <p>Rubber</p>
          </TableCell>
          <TableCell valign="top">
            <p>
              4001 Natural rubber, balata, gutta-percha, guayule, chicle and similar natural gums,
              in primary forms or in plates, sheets or strip
            </p>
            <p>
              ex&nbsp;4005 Compounded rubber, unvulcanised, in primary forms or in plates, sheets or
              strip
            </p>
            <p>
              ex&nbsp;4006 Unvulcanised rubber in other forms (e.g. rods, tubes and profile shapes)
              and articles (e.g. discs and rings)
            </p>
            <p>ex&nbsp;4007 Vulcanised rubber thread and cord</p>
            <p>
              ex&nbsp;4008 Plates, sheets, strips, rods and profile shapes, of vulcanised rubber
              other than hard rubber
            </p>
            <p>ex&nbsp;4010 Conveyer or transmission belts or belting, of vulcanised rubber</p>
            <p>ex&nbsp;4011 New pneumatic tyres, of rubber</p>
            <p>
              ex&nbsp;4012 Retreaded or used pneumatic tyres of rubber; solid or cushion tyres, tyre
              treads and tyre flaps, of rubber
            </p>
            <p>ex&nbsp;4013 Inner tubes, of rubber</p>
            <p>
              ex&nbsp;4015 Articles of apparel and clothing accessories (including gloves, mittens
              and mitts), for all purposes, of vulcanised rubber other than hard rubber
            </p>
            <p>
              ex&nbsp;4016 Other articles of vulcanised rubber other than hard rubber, not elsewhere
              specified in chapter 40
            </p>
            <p>
              ex&nbsp;4017 Hard rubber (e.g. ebonite) in all forms including waste and scrap;
              articles of hard rubber
            </p>
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell valign="top">
            <p>Soya</p>
          </TableCell>
          <TableCell valign="top">
            <p>1201 Soya beans, whether or not broken</p>
            <p>1208&nbsp;10 Soya bean flour and meal</p>
            <p>
              1507 Soya-bean oil and its fractions, whether or not refined, but not chemically
              modified
            </p>
            <p>
              2304 Oilcake and other solid residues, whether or not ground or in the form of
              pellets, resulting from the extraction of soya-bean oil
            </p>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell valign="top">
            <p>Wood</p>
          </TableCell>
          <TableCell valign="top">
            <p>
              4401 Fuel wood, in logs, in billets, in twigs, in faggots or in similar forms; wood in
              chips or particles; sawdust and wood waste and scrap, whether or not agglomerated in
              logs, briquettes, pellets or similar forms
            </p>
            <p>4402 Wood charcoal (including shell or nut charcoal), whether or not agglomerated</p>
            <p>
              4403 Wood in the rough, whether or not stripped of bark or sapwood, or roughly squared
            </p>
            <p>
              4404 Hoopwood; split poles; piles, pickets and stakes of wood, pointed but not sawn
              lengthwise; wooden sticks, roughly trimmed but not turned, bent or otherwise worked,
              suitable for the manufacture of walking sticks, umbrellas, tool handles or the like;
              chipwood and the like
            </p>
            <p>4405 Wood wool; wood flour</p>
            <p>4406 Railway or tramway sleepers (cross-ties) of wood</p>
            <p>
              4407 Wood sawn or chipped lengthwise, sliced or peeled, whether or not planed, sanded
              or end-jointed, of a thickness exceeding 6&nbsp;mm
            </p>
            <p>
              4408 Sheets for veneering (including those obtained by slicing laminated wood), for
              plywood or for other similar laminated wood and other wood, sawn lengthwise, sliced or
              peeled, whether or not planed, sanded, spliced or end-jointed, of a thickness not
              exceeding 6&nbsp;mm
            </p>
            <p>
              4409 Wood (including strips and friezes for parquet flooring, not assembled)
              continuously shaped (tongued, grooved, rebated, chamfered, V-jointed, beaded, moulded,
              rounded or the like) along any of its edges, ends or faces, whether or not planed,
              sanded or end-jointed
            </p>
            <p>
              4410 Particle board, oriented strand board (OSB) and similar board (for example,
              waferboard) of wood or other ligneous materials, whether or not agglomerated with
              resins or other organic binding substances
            </p>
            <p>
              4411 Fibreboard of wood or other ligneous materials, whether or not bonded with resins
              or other organic substances
            </p>
            <p>4412 Plywood, veneered panels and similar laminated wood</p>
            <p>4413 Densified wood, in blocks, plates, strips or profile shapes</p>
            <p>4414 Wooden frames for paintings, photographs, mirrors or similar objects</p>
            <p>
              4415 Packing cases, boxes, crates, drums and similar packings, of wood; cable-drums of
              wood; pallets, box pallets and other load boards, of wood;
            </p>
            <p>pallet collars of wood</p>
            <p>
              (not including packing material used exclusively as packing material to support,
              protect or carry another product placed on the market)
            </p>
            <p>
              4416 Casks, barrels, vats, tubs and other coopers’ products and parts thereof, of
              wood, including staves
            </p>
            <p>
              4417 Tools, tool bodies, tool handles, broom or brush bodies and handles, of wood;
              boot or shoe lasts and trees, of wood
            </p>
            <p>
              4418 Builders’ joinery and carpentry of wood, including cellular wood panels,
              assembled flooring panels, shingles and shakes
            </p>
            <p>4419 Tableware and kitchenware, of wood</p>
            <p>
              4420 Wood marquetry and inlaid wood; caskets and cases for jewellery or cutlery, and
              similar articles, of wood; statuettes and other ornaments, of wood; wooden articles of
              furniture not falling in Chapter 94
            </p>
            <p>4421 Other articles of wood</p>
            <p>
              Pulp and paper of Chapters 47 and 48 of the Combined Nomenclature, with the exception
              of bamboo-based and recovered (waste and scrap) products
            </p>
            <p>
              ex 49 Printed books, newspapers, pictures and other products of the printing industry,
              manuscripts, typescripts and plans, of paper
            </p>
            <p>
              ex&nbsp;9401 Seats (other than those of heading 9402 ), whether or not convertible
              into beds, and parts thereof, of wood
            </p>
            <p>
              9403&nbsp;30 , 9403&nbsp;40 , 9403&nbsp;50 , 9403&nbsp;60 and 9403&nbsp;91 Wooden
              furniture, and parts thereof
            </p>
            <p>9406&nbsp;10 Prefabricated buildings of wood</p>
          </TableCell>
        </TableRow>
      </TableBody>
    </StyledTable>
  );
};

export default AnnexTable;
