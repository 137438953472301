import { Box, Grid, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { MediaField, TextFieldLimited } from 'components/Forms';
import { FieldWrapper } from 'designSystem';
import { useContentBlock } from 'components/Product/MultiStep/hooks';
import { applicationHints } from 'config/applicationHints';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import FormContainer from './FormContainer';
import Header from './Header';
import { Container } from './styles';
import HTMLEditorField from 'designSystem/Formik/HTMLEditorField/HTMLEditorField';

const About = ({ menu, previewIsActive }) => {
  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up('lg'));
  const [{ enabled, data, id }, schema] = useContentBlock(menu.id);

  const showTextOnTheSide = !previewIsActive && largeScreen;

  return (
    <Container>
      <FormContainer
        initialValues={{
          enabled,
          ...data,
        }}
        schema={schema}
        mutation={menu.mutation}
      >
        {({ setValues }) => (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Header
                title={menu.title}
                helperText={menu.description}
                showEnabled
                contentBlockId={id}
                setContent={setValues}
                contentType={menu.id}
              />
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" flexDirection={showTextOnTheSide ? `row` : 'column'} gap={3}>
                <FieldWrapper
                  label="Image"
                  tooltip={{ helperText: applicationHints.about.image, variant: 'HINT' }}
                >
                  <Field
                    component={MediaField}
                    fullWidth
                    name="image"
                    legacyImage={false}
                    data-cy="field-about-image"
                    styles={{
                      width: 280,
                      height: 280,

                      [theme.breakpoints.down('md')]: {
                        width: 200,
                        height: 200,
                      },
                    }}
                  />
                </FieldWrapper>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <FieldWrapper label="Headline">
                      <Field
                        component={TextFieldLimited}
                        fullWidth
                        name="headline"
                        variant="outlined"
                        placeholder="We care about impact"
                        rows={4}
                        charLimit={50}
                        inputProps={{
                          'data-cy': 'field-about-headline',
                        }}
                      />
                    </FieldWrapper>
                  </Grid>
                  <Grid item xs={12}>
                    <FieldWrapper
                      label="Description"
                      tooltip={{ helperText: applicationHints.about.description, variant: 'HINT' }}
                    >
                      <Field
                        component={HTMLEditorField}
                        name="description"
                        placeholder="Our core business is..."
                        charLimit={650}
                      />
                    </FieldWrapper>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        )}
      </FormContainer>
    </Container>
  );
};

About.propTypes = {
  menu: PropTypes.object.isRequired,
  previewIsActive: PropTypes.bool,
};

export default About;
