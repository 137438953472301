import { Formik, Form } from 'formik';
import React, { FC } from 'react';
import { DialogActions, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ThemeButton, ThemeTypography } from 'designSystem';
import { ILocationVisibility, IVisibilitySettings } from 'types/supplyChain.types';
import LocationVisibilityBlock from './Forms/LocationVisibilityBlock';
import { VisibilitySettingsSchema } from 'constants/schemas';
import useSupplyChainActions from '../../SupplyChainsLibrary/hooks/useSupplyChainActions';

interface IVisibilityFormProps {
  visibilitySettings: IVisibilitySettings;
  onClose?: () => void;
}

interface IVisibilityFormValues {
  locationVisibility: keyof typeof ILocationVisibility;
}

const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  padding: theme.spacing(4, 0, 0, 0),
  justifyContent: 'space-between',
}));

const VisibilityForm: FC<IVisibilityFormProps> = ({ visibilitySettings, onClose }) => {
  const { updateVisibilitySettings } = useSupplyChainActions();

  const handleSubmit = async (values: IVisibilityFormValues) => {
    const visibilityOptions = {
      locationVisibility: values.locationVisibility,
    };

    await updateVisibilitySettings({
      variables: { id: visibilitySettings.id, input: visibilityOptions },
    });
    onClose?.();
  };

  return (
    <Formik
      initialValues={
        { ...VisibilitySettingsSchema.default(), ...visibilitySettings } as IVisibilityFormValues
      }
      validationSchema={VisibilitySettingsSchema}
      enableReinitialize
      onSubmit={handleSubmit}
      validateOnBlur={false}
      validateOnChange={true}
    >
      {({ isSubmitting }) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <LocationVisibilityBlock />
            </Grid>
            <Grid item xs={12}>
              <ThemeTypography variant="BODY_LARGE" color="ALMOST_BLACK">
                Decide the level of detail of locations you would like to communicate in your
                product page. Make sure that you are allowed to show exact farmer locations
                according to data regulations and policy.
              </ThemeTypography>
            </Grid>
          </Grid>
          <StyledDialogActions>
            <ThemeButton
              color="BLUE_ICE"
              size="large"
              onClick={onClose}
              data-cy="button-close-visibility-dialog"
            >
              Cancel
            </ThemeButton>
            <ThemeButton
              size="large"
              loading={isSubmitting}
              type="submit"
              data-cy="button-submit-visibility"
            >
              Save settings
            </ThemeButton>
          </StyledDialogActions>
        </Form>
      )}
    </Formik>
  );
};

export default VisibilityForm;
