import { Box, styled } from '@mui/material';
import Icon from 'designSystem/Primitives/Icon/Icon';
import ThemeTypography from 'designSystem/Primitives/Typography/ThemeTypography';
import React, { FC } from 'react';
import { Booleanish, booleanish } from 'types/booleanish.types';
import { RiskLevel } from 'types/compliance.types';
import { getCountryLabelFromCode } from 'utils/country.utils';
import RiskBadge from '../RiskBadge/RiskBadge';

interface IDatasetItemProps {
  title: string;
  status?: 'valid' | 'error';
  commodity?: string;
  location?: string;
  owner?: string;
  riskLevel?: RiskLevel;
  active?: boolean;
  checked?: boolean;
  disabled?: boolean;
}

const Container = styled(Box)<{ active?: booleanish; disabled?: booleanish }>(
  ({ theme, active, disabled }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: theme.spacing(1),
    border: `1px solid ${theme.custom.colors.lightBorderColor}`,
    borderRadius: 4,
    background: theme.custom.themeColors.white,
    position: 'relative',
    width: 'fit-content',

    ':hover': {
      backgroundColor:
        active === 'true' || disabled === 'true'
          ? theme.custom.themeColors.white
          : theme.custom.themeColors.grayScale[5],
    },

    ...(active === 'true' && {
      '&:before': {
        content: '""',
        display: 'block',
        width: 8,
        height: '100%',
        position: 'absolute',
        left: 0,
        top: 0,
        borderRadius: '4px 0 0 4px',
        backgroundColor: theme.custom.themeColors.primary[100],
      },
      '&:after': {
        content: '""',
        display: 'block',
        height: '100%',
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        border: `2px solid ${theme.custom.themeColors.primary[100]}`,
        borderRadius: 4,
      },
    }),
  })
);

const TextContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
}));

const SubTextRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}));

const SubText = styled(ThemeTypography)(({ theme }) => ({
  marginLeft: 2,
  fontSize: '10px !important',
}));

const SubItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginRight: theme.spacing(1),
}));

const DatasetItem: FC<IDatasetItemProps> = ({
  title,
  status,
  commodity,
  location,
  owner,
  riskLevel,
  active,
  checked,
  disabled,
}) => {
  return (
    <Container active={Booleanish(active)} disabled={Booleanish(status === 'valid' || disabled)}>
      <Box ml={1} />
      <Icon name="polygon-data" size="xx-large" />
      <Box ml={2} />
      <TextContainer>
        <Box display="flex" alignItems="center">
          <ThemeTypography variant="BODY_MEDIUM_BOLD">{title}</ThemeTypography>
          {riskLevel && (
            <Box ml={0.75} mr={1}>
              <RiskBadge riskLevel={riskLevel} />
            </Box>
          )}
          {(status === 'valid' || checked) && (
            <Box ml={0.75}>
              <Icon name="check-filled" color="green" />
            </Box>
          )}
          {status === 'error' && (
            <Box ml={0.75}>
              <Icon name="error-filled" color="red-80" />
            </Box>
          )}
        </Box>
        <SubTextRow>
          {commodity && (
            <SubItem>
              <Icon name="raw-material" size="small" />
              <SubText variant="LABEL_INPUT" color="GRAY_80">
                {commodity}
              </SubText>
            </SubItem>
          )}
          {location && (
            <SubItem>
              <Icon name="activity-default" size="small" />
              <SubText variant="LABEL_INPUT" color="GRAY_80">
                {getCountryLabelFromCode(location)}
              </SubText>
            </SubItem>
          )}
          {owner && (
            <SubItem>
              <Icon name="partner" size="small" />
              <SubText variant="LABEL_INPUT" color="GRAY_80">
                {owner}
              </SubText>
            </SubItem>
          )}
        </SubTextRow>
      </TextContainer>
    </Container>
  );
};

export default DatasetItem;
