import { gql } from '@apollo/client';

export const UPDATE_VISIBILITY_SETTINGS = gql`
  mutation UpdateChainVisibilitySettings($id: UUID!, $input: UpdateChainVisibilitySettingsInput!) {
    updateChainVisibilitySettings(id: $id, input: $input) {
      chainVisibilitySettings {
        id
        chainId
        locationVisibility
      }
    }
  }
`;
