import { Dialog, DialogActions, DialogContent, IconButton, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Close } from '@styled-icons/evaicons-solid/Close';
import ThemeTypography from 'designSystem/Primitives/Typography/ThemeTypography';
import React, { FC, Fragment, useState } from 'react';
import { IDefaultDialogProps } from 'types/dialog.types';
import DialogTitle from '../Dialog/DialogTitle/DialogTitle';
import ThemeButton from 'designSystem/Buttons/ThemeButton/ThemeButton';

const CloseButtonWrapper = styled(Box)(() => ({
  position: 'absolute',
  top: 0,
  right: 0,
  zIndex: 1,
}));

const StyledItemTitle = styled(ThemeTypography)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  textTransform: 'uppercase',
  letterSpacing: '0.1em',
}));

export interface IAlertDialogProps extends IDefaultDialogProps {
  title: string;
  itemTitle?: string;
  text: string | React.ReactElement;
  cancelText?: string;
  submitText?: string;
  preventCloseAfterSubmit?: boolean;
  displayCloseButton?: boolean;
  onSubmit?: () => Promise<void> | void;
  onCancel?: () => void;
}

const AlertDialog: FC<IAlertDialogProps> = ({
  onClose,
  onCancel,
  onSubmit,
  open = false,
  submitText = 'Submit',
  cancelText = 'Cancel',
  text,
  title,
  preventCloseAfterSubmit = false,
  displayCloseButton = false,
  itemTitle,
  ...rest
}) => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    const isAsync = onSubmit?.constructor.name === 'AsyncFunction';

    if (isAsync) {
      setLoading(true);
      const submit = onSubmit();
      if (submit) {
        submit.finally(() => {
          setLoading(false);
          if (!preventCloseAfterSubmit && onClose) {
            onClose();
          }
        });
      }

      return;
    }

    if (onSubmit) onSubmit();
    if (!preventCloseAfterSubmit && onClose) {
      setTimeout(onClose);
    }
  };

  const handleClose = () => {
    if (typeof onCancel === 'function') onCancel();
    if (onClose) setTimeout(onClose);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      data-testid="alert"
      aria-labelledby="form-dialog-title"
      {...rest}
    >
      <Box m={3} display="flex" alignItems="center">
        <DialogTitle id="form-dialog-title">{title ?? ''}</DialogTitle>
        {itemTitle ? (
          <Fragment>
            <StyledItemTitle variant="TITLE_EXTRA_SMALL">-</StyledItemTitle>
            <StyledItemTitle autoOverflow variant="TITLE_EXTRA_SMALL" color="GRAY_40">
              {itemTitle}
            </StyledItemTitle>
          </Fragment>
        ) : null}
      </Box>
      {displayCloseButton && (
        <CloseButtonWrapper>
          <IconButton onClick={onClose}>
            <Close size={18} />
          </IconButton>
        </CloseButtonWrapper>
      )}
      <DialogContent>
        <Box mb={1}>
          <ThemeTypography variant="BODY_LARGE">{text}</ThemeTypography>
        </Box>
      </DialogContent>
      <DialogActions>
        {onCancel && (
          <ThemeButton
            color="BLUE_ICE"
            size="large"
            data-cy="alert-dialog-cancel"
            onClick={handleClose}
          >
            {cancelText}
          </ThemeButton>
        )}
        {onSubmit && (
          <ThemeButton
            loading={loading}
            onClick={handleSubmit}
            color="YELLOW"
            size="large"
            data-testid="alert-dialog-confirm"
            autoFocus
          >
            {submitText}
          </ThemeButton>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialog;
