import { Box, styled } from '@mui/material';
import { FileEarmarkText, Trash } from '@styled-icons/bootstrap';
import {
  ActionButton,
  ErrorMessage,
  FileNameText,
  ProgressContainer,
  ProgressNumber,
  StyledProgress,
} from 'components/FileUpload/styles';
import { useUploadItem, useUploadState } from 'components/hooks';
import React, { FC } from 'react';
import { booleanish } from 'types/booleanish.types';

export const UploadItemContainer = styled('div')<{ error?: booleanish }>(({ theme, error }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(1.5),
  color: theme.palette.text.secondary,
  border: '1px solid',
  borderColor: error ? theme.palette.warning.main : theme.palette.grey[200],
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(1),
  background: '#fff',
}));

const UploadItem: FC<{ id: string }> = ({ id }) => {
  // @ts-ignore
  const { data, progress, error, success } = useUploadItem(id);
  const { removeFile } = useUploadState();

  const uploading = !error && !success;

  return (
    <UploadItemContainer>
      <Box display="flex" alignItems="center" width="90%">
        <FileEarmarkText size={28} />
        <Box display="flex" flexGrow={1} flexDirection="column" width="70%">
          <Box>
            <FileNameText>{data.name}</FileNameText>
          </Box>
          {error && <ErrorMessage error>{error}</ErrorMessage>}
        </Box>
      </Box>
      {uploading && (
        <ProgressContainer>
          <ProgressNumber>{progress}%</ProgressNumber>
          <StyledProgress color="secondary" variant="determinate" value={progress} />
        </ProgressContainer>
      )}
      <ActionButton onClick={() => removeFile(id)}>
        <Trash size={14} />
      </ActionButton>
    </UploadItemContainer>
  );
};

export default UploadItem;
