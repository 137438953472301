import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useUploadItem } from 'components/hooks';
import UploadListItem from './UploadListItem';
import { useUploadState } from 'components/hooks';
import UploadErrorItem from './UploadErrorItem';

const FileUploadItem = ({
  id,
  onDelete,
  onEdit,
  openOnSuccess,
  editableDocument,
  isUpdated,
  ...props
}) => {
  const { data, progress, itemType, error, success, recordId } = useUploadItem(id);
  const { removeFile } = useUploadState();

  useEffect(() => {
    // open the edit form for the uploaded item after successful upload if another one is not already open
    if (openOnSuccess && success && !editableDocument && !isUpdated) {
      onEdit(recordId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  switch (itemType) {
    case 'document': {
      return (
        <UploadListItem
          error={error}
          progress={progress}
          fileName={data.name}
          success={success}
          onDelete={() => onDelete(recordId)}
          onEdit={() => onEdit(recordId)}
          {...props}
        />
      );
    }

    case 'rejected': {
      return <UploadErrorItem fileName={data.name} error={error} onDelete={() => removeFile(id)} />;
    }
    default: {
      return null;
    }
  }
};

FileUploadItem.propTypes = {
  id: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func,
  editableDocument: PropTypes.string,
  openOnSuccess: PropTypes.bool,
  isUpdated: PropTypes.bool,
};

export default FileUploadItem;
