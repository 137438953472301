import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { UseMeasureRef } from 'react-use/lib/useMeasure';

const PageContainer = styled(Box)<{ ref?: UseMeasureRef<HTMLDivElement> }>(({ theme }) => ({
  position: 'relative',
  maxWidth: theme.custom.maxPageWidth,
  margin: '0 auto',
  height: '100%',
}));

export default PageContainer;
