import { gql } from '@apollo/client';
import { QR_CODE_DESIGN_FRAGMENT } from 'graphql/fragments';

// local mutation to fetch from external API
export const UPDATE_QR_DESIGN = gql`
  mutation UpdateQRDesign($qrCodeDesign: QRCodeDesignInput!) {
    updateQRDesign(qrCodeDesign: $qrCodeDesign) @client {
      svg
    }
  }
`;

// save values to database
export const SAVE_QR_DESIGN = gql`
  mutation SaveQRDesign($id: UUID!, $input: UpdateProductQrCodeDesignInput!) {
    updateProductQrCodeDesign(id: $id, input: $input) {
      qrCode {
        id
        tag
        url
        design {
          ...qrCodeDesignValues
        }
      }
    }
  }
  ${QR_CODE_DESIGN_FRAGMENT}
`;
