import { useMutation, useQuery } from '@apollo/client';
import { Grid, Box } from '@mui/material';
import { Loader } from 'components/Forms';
import { AlertDialog, ThemeButton } from 'designSystem';
import { useBackendErrorMessage, useMessages, useLogEvent } from 'components/hooks';
import { Container, InviteUserDialog, UserTable } from 'components/Settings';
import { ErrorState } from 'components/Structure';
import { CHANGE_USER_ROLE, INVITE_USER, REINVITE_USER, DELETE_USER } from 'graphql/mutations';
import { GET_USERS } from 'graphql/queries';
import update from 'immutability-helper';
import findIndex from 'lodash/findIndex';
import React, { useState } from 'react';
import { useCompanyPlanContext } from 'contexts/CompanyPlanContext';

const UserList = () => {
  const { data, loading, error } = useQuery(GET_USERS);
  const [inviteOpen, setInviteOpen] = useState(false);
  const [reinviteUserData, setReinviteUserData] = useState(null);
  const [deleteUserId, setDeleteUserId] = useState(null);
  const { setSuccessMessage, setErrorMessage } = useMessages();
  const setBackendError = useBackendErrorMessage();
  const { logEvent } = useLogEvent();
  const { isPartner } = useCompanyPlanContext();

  const [deleteUser] = useMutation(DELETE_USER, {
    onCompleted: () => setSuccessMessage('User successfully removed!'),
    onError: () => {
      setErrorMessage('There was an error removing this user');
    },
    update: (
      cache,
      {
        data: {
          deleteUser: { id },
        },
      }
    ) => {
      const { companyUsers } = cache.readQuery({
        query: GET_USERS,
      });
      const index = findIndex(companyUsers.edges, ['node.id', id]);

      cache.writeQuery({
        query: GET_USERS,
        data: {
          companyUsers: {
            edges: update(companyUsers.edges, {
              $splice: [[index, 1]],
            }),
          },
        },
      });
    },
  });
  const [inviteUser] = useMutation(INVITE_USER, {
    onCompleted: ({ inviteUser: { email } = {} }) => {
      setInviteOpen(false);
      logEvent('INVITE_USER', {
        user_email: email,
      });
      setSuccessMessage('Invitation sent!');
    },
    onError: error => {
      setBackendError(error.message, 'There was an error sending your invitation');
    },
    update: (cache, { data: { inviteUser } }) => {
      const { companyUsers } = cache.readQuery({
        query: GET_USERS,
      });

      const userForCache = {
        node: inviteUser,
        cursor: inviteUser.id,
      };

      cache.writeQuery({
        query: GET_USERS,
        data: {
          companyUsers: {
            edges: [...companyUsers.edges, userForCache],
          },
        },
      });
    },
  });
  const [reinviteUser] = useMutation(REINVITE_USER, {
    onCompleted: () => {
      setReinviteUserData(null);
      setSuccessMessage('Invitation resent!');
    },
    onError: () => {
      setErrorMessage('There was an error resending your invitation');
    },
  });

  const [changeUserRole] = useMutation(CHANGE_USER_ROLE, {
    onCompleted: () => {
      setSuccessMessage('User role successfully changed!');
    },
    onError: () => {
      setErrorMessage('There was an error changing the user role');
    },
  });

  const handleRemove = async () => {
    await deleteUser({
      variables: {
        id: deleteUserId,
      },
    });
  };

  const handleInvite = ({ email, role }) => {
    return inviteUser({
      variables: {
        email,
        role,
      },
    });
  };

  const handleReinvite = ({ id }) => {
    return reinviteUser({
      variables: {
        id,
      },
    });
  };

  const handleChangeRole = ({ id, role }) => {
    return changeUserRole({
      variables: {
        id,
        role,
      },
    });
  };

  if (error) return <ErrorState />;
  if (!data && loading) return <Loader />;

  return (
    <Container>
      <UserTable
        onReinvite={setReinviteUserData}
        onRemove={setDeleteUserId}
        onChangeRole={handleChangeRole}
        users={data.companyUsers.edges}
        isPartner={isPartner}
      />
      <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
        <Grid item>
          <Box mt={2}>
            <ThemeButton
              color="YELLOW"
              size="large"
              onClick={() => setInviteOpen(true)}
              data-cy="invite-user-btn"
            >
              Invite user
            </ThemeButton>
          </Box>
        </Grid>
      </Grid>
      {inviteOpen && (
        <InviteUserDialog
          onSubmit={handleInvite}
          onClose={() => setInviteOpen(false)}
          isPartner={isPartner}
        />
      )}
      {reinviteUserData && (
        <InviteUserDialog
          userId={reinviteUserData.id}
          prefilledEmail={reinviteUserData.email}
          onSubmit={handleReinvite}
          onClose={() => setReinviteUserData(null)}
        />
      )}
      {deleteUserId && (
        <AlertDialog
          onClose={() => setDeleteUserId(null)}
          title="Remove user"
          open
          text="If you remove this user they will no longer be able to access your account."
          onSubmit={handleRemove}
          onCancel={() => setDeleteUserId(null)}
          submitText="Remove user"
        />
      )}
    </Container>
  );
};

export default React.memo(UserList);
