import { Box, LinearProgress } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ThemeTypography } from 'designSystem';
import React, { FC, ReactElement } from 'react';

interface IItemProgressTitleProps {
  imageUrl?: string;
  backupIcon?: ReactElement;
  title: string;
  taskCompleted: number;
  taskTotal: number;
}

const StyledImage = styled('div')<{ backgroundImage: string }>(({ backgroundImage }) => ({
  width: 42,
  height: 42,
  borderRadius: 4,
  flexShrink: 0,
  backgroundImage: `url(${backgroundImage})`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
}));

const BorderLinearProgress = styled(LinearProgress)<{ percentage: number }>(
  ({ percentage, theme }) => ({
    borderRadius: 2,
    height: 12,
    width: 100,
    backgroundColor: theme.palette.grey[300],
    marginRight: theme.spacing(1),

    '& .MuiLinearProgress-bar': {
      backgroundColor: percentage === 100 ? theme.palette.success.main : theme.palette.primary.main,
    },
  })
);

const ItemProgressTitle: FC<IItemProgressTitleProps> = ({
  imageUrl,
  title,
  backupIcon,
  taskCompleted,
  taskTotal,
}) => {
  const progress = (taskCompleted / taskTotal) * 100;

  return (
    <Box display="flex" alignItems="center">
      {imageUrl && <StyledImage backgroundImage={imageUrl} />}
      {!imageUrl && !!backupIcon && <>{backupIcon}</>}
      <Box ml={2}>
        <ThemeTypography variant="ITEM_TITLE">{title}</ThemeTypography>
        <Box display="flex" alignItems="center">
          <BorderLinearProgress percentage={progress} variant="determinate" value={progress} />
          <ThemeTypography variant="ITEM_SUBTITLE" color="GRAY_80">
            {taskCompleted}/{taskTotal} COMPLETED
          </ThemeTypography>
        </Box>
      </Box>
    </Box>
  );
};

export default ItemProgressTitle;
