import { Box, Button, InputAdornment, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Edit } from '@styled-icons/boxicons-solid/Edit';
import { ContentCopy } from '@styled-icons/material/ContentCopy';
import { LinkWithIcon } from 'components/Forms';
import { useClipboard, useLogEvent } from 'components/hooks';
import EditSlugDialog from 'components/Product/EditSlugDialog/EditSlugDialog';
import { QRCodeTabs } from 'components/Product/QRCode';
import { QRCodeEditorModal } from 'components/Product/QRCode/QRCodeEditor';
import { ThemeTypography, CardContainer } from 'designSystem';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const CopyLinkButton = styled(Button)(({ theme }) => ({
  color: theme.custom.colors.textLight,
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginTop: theme.spacing(1),

  '& .MuiInputBase-root .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.custom.colors.lightBorderColor,
  },

  '& .MuiInputBase-input': {
    padding: theme.spacing(1.2),
  },
}));

const ShareSummaryCard = ({ productId, publicUrl, qrCode, productTitle, slug }) => {
  const { logEvent } = useLogEvent();
  const [qrEditorOpen, setQREditorOpen] = useState(false);
  const [slugEditorOpen, setSlugEditorOpen] = useState(false);
  const copy = useClipboard('Public URL copied to clipboard');
  const handleCopy = () => {
    copy(publicUrl);
    logEvent('COPY_LINK', {
      publicUrl,
    });
  };

  return (
    <CardContainer size="MEDIUM" title="Share your product page">
      <Box mt={1}>
        <ThemeTypography variant="LABEL_INPUT">Sharing link</ThemeTypography>
      </Box>
      <Box display="flex" alignItems="center" mb={2.5}>
        <StyledTextField
          disabled
          fullWidth
          name="url"
          value={publicUrl}
          variant="outlined"
          InputProps={{
            'data-cy': 'share-url',
            endAdornment: (
              <InputAdornment position="end">
                <CopyLinkButton
                  onClick={() => handleCopy(publicUrl)}
                  startIcon={<ContentCopy size={14} />}
                  size="small"
                >
                  Copy Link
                </CopyLinkButton>
              </InputAdornment>
            ),
          }}
        />
        <Box minWidth={100} mt={1} height="100%" display="flex" ml={2}>
          <LinkWithIcon
            onClick={() => setSlugEditorOpen(true)}
            Icon={Edit}
            data-cy="share-url-edit"
          >
            Edit Link
          </LinkWithIcon>
        </Box>
      </Box>

      <QRCodeTabs
        productId={productId}
        setQREditorOpen={setQREditorOpen}
        qrCode={qrCode}
        productTitle={productTitle}
      />
      {qrEditorOpen && (
        <QRCodeEditorModal
          open
          onClose={() => setQREditorOpen(false)}
          productTitle={productTitle}
          productId={productId}
          qrCode={qrCode}
        />
      )}
      {slugEditorOpen && (
        <EditSlugDialog
          open
          productId={productId}
          productSlug={slug}
          onClose={() => setSlugEditorOpen(false)}
        />
      )}
    </CardContainer>
  );
};

ShareSummaryCard.propTypes = {
  productId: PropTypes.string.isRequired,
  publicUrl: PropTypes.string,
  qrCode: PropTypes.object,
  productTitle: PropTypes.string,
};

export default ShareSummaryCard;
