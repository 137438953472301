import { X as Close } from '@styled-icons/bootstrap/X';
import { Loader } from 'components/Forms';
import {
  ControlBar,
  SlidesCloseButton,
  SlidesContainer,
  SlidesDialog,
} from 'components/SliderForm';
import { ErrorState } from 'components/Structure';
import { AlertDialog } from 'designSystem';
import React, { FC, Fragment } from 'react';
import { IDefaultDialogProps } from 'types/dialog.types';
import { IBasePartnerCompany } from 'types/partner.types';
import { useCreateClaim } from '../hooks';
import { ImpactClaimStatus } from 'types/impactClaim.types';

export interface ICreateClaimDialogProps extends IDefaultDialogProps {
  partner?: IBasePartnerCompany;
}

const CreateClaimDialog: FC<ICreateClaimDialogProps> = ({ onClose, open, partner }) => {
  const {
    currentSlide,
    setCurrentSlide,
    SLIDES,
    error,
    loading,
    onSubmit,
    handleCloseDialog,
    setOpenUpdateAlert,
    openUpdateAlert,
  } = useCreateClaim({ onClose, preSelectedPartner: partner });

  const {
    component: SlideComponent,
    props: currentSlideProps,
    button: SubmitButton,
  } = SLIDES[currentSlide];

  const renderBody = () => {
    if (error) return <ErrorState />;
    if (loading) return <Loader />;

    // TODO: Fix types for the slides and remove ts-ignore
    // @ts-ignore
    return <SlideComponent {...currentSlideProps} />;
  };

  return (
    <Fragment>
      <SlidesDialog
        fullWidth
        maxWidth="lg"
        open={!!open}
        onClose={handleCloseDialog}
        data-cy="create-claim-dialog"
      >
        <SlidesCloseButton onClick={handleCloseDialog} data-cy="close-dialog-button">
          <Close size={20} />
        </SlidesCloseButton>
        <SlidesContainer data-cy="create-dialog">{renderBody()}</SlidesContainer>
        <ControlBar
          currentSlide={currentSlide}
          prevSlide={() => setCurrentSlide(currentSlide - 1)}
          setCurrentSlide={setCurrentSlide}
          slideCount={SLIDES.length}
          submitButton={SubmitButton}
        />
      </SlidesDialog>
      <AlertDialog
        onClose={() => setOpenUpdateAlert(false)}
        title="Save as Draft?"
        open={openUpdateAlert}
        text="You have edited this claim. Do you want to save as draft and continue editing later?"
        onSubmit={() => onSubmit(ImpactClaimStatus.CREATED)}
        onCancel={onClose}
        cancelText="Discard changes"
        submitText="Save draft"
      />
    </Fragment>
  );
};

export default CreateClaimDialog;
