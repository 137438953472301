import some from 'lodash/some';
import transform from 'lodash/transform';
import isObject from 'lodash/isObject';
import isArray from 'lodash/isArray';
import set from 'lodash/set';

export const isMutation = operation =>
  some(
    operation.query.definitions,
    definition => definition.kind === 'OperationDefinition' && definition.operation === 'mutation'
  );

export const defaultValidationType = () => ({
  __typename: 'Validation',
  fields: 0,
  errors: [],
  valid: true,
});

export const getFieldList = schema => {
  return Object.entries(schema.fields).map(([fieldName]) => fieldName);
};

export const constructCachedRecord = (schema, readField) => {
  const fieldList = getFieldList(schema);
  return fieldList.reduce(
    (result, fieldName) => ({
      ...result,
      [fieldName]: readField(fieldName),
    }),
    {}
  );
};

export const stripValuesToSchema = (values, schema) => {
  if (!schema) return values;

  let strippedValues;

  try {
    strippedValues = schema.validateSync(values, {
      abortEarly: false,
      stripUnknown: true,
    });
  } catch (e) {
    strippedValues = e.value;
  }

  return strippedValues;
};

export const getFieldCount = (data, schema) => {
  let fieldCount = 0;

  Object.entries(schema.fields).forEach(([fieldName, field]) => {
    if (field.type === 'array' && field.innerType?._nodes) {
      fieldCount += (data[fieldName]?.length || 0) * field.innerType._nodes.length;
    } else {
      fieldCount += 1;
    }
  });

  return fieldCount;
};

export const validateContent = (data, schema, strict = false, options = {}) => {
  const fields = getFieldCount(data, schema);

  let errors = [];

  try {
    schema.validateSync(data, {
      abortEarly: false,
      context: { strict },
      ...options,
    });
  } catch ({ errors: validationErrors }) {
    errors = validationErrors || [];
  }

  return {
    ...defaultValidationType(),
    fields,
    errors,
    valid: errors.length === 0,
  };
};

export const validateStrict = async (values, schema, strict = false) => {
  try {
    await schema.validate(values, {
      abortEarly: false,
      context: { strict },
    });
  } catch (error) {
    if (error.name !== 'ValidationError') {
      throw error;
    }

    return error.inner.reduce((errors, currentError) => {
      errors = set(errors, currentError.path, currentError.message);
      return errors;
    }, {});
  }

  return {};
};

export const validateVersion = validationItems => {
  let totalFields = 0;
  let totalErrors = 0;

  validationItems.forEach(item => {
    let fields = item.validation?.fields || 0;
    const errors = item.validation?.errors || [];

    if (errors.length > fields) {
      fields = errors.length;
    }

    totalFields += fields;
    totalErrors += errors.length;
  });

  const validPercent = 1 - totalErrors / totalFields;
  return Math.round(validPercent * 100);
};

export const transformVariableTypes = (variables, serializers) => {
  return transform(variables, (result, value, key) => {
    const object = isObject(value);
    const array = isArray(value);
    const serializer = val => val?.__typename && serializers[val.__typename];

    if (object && serializer(value)) {
      result[key] = serializer(value)(value);
    } else if (array) {
      result[key] = value.map(item => {
        if (!isObject(item) && !isArray(item)) return item;
        else if (isObject(item) && serializer(item)) {
          item = serializer(item)(item);
        }

        return transformVariableTypes(item, serializers);
      });
    } else if (object) {
      result[key] = transformVariableTypes(value, serializers);
    } else {
      result[key] = value;
    }
  });
};
