import { Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import truncate from 'lodash/truncate';
import React from 'react';

const Container = styled('div')(() => ({
  display: 'flex',
}));

const Pill = styled('div')<{
  displayTitle: boolean;
  variant: Props['variant'];
}>(({ displayTitle, theme, variant }) => ({
  position: 'relative',
  display: 'flex',
  padding: theme.spacing(0.5, 2),
  textAlign: 'center',
  color: '#fff',
  fontWeight: 700,
  fontSize: 12,
  whiteSpace: 'nowrap',
  background: variant === 'ERROR' ? theme.palette.error.main : theme.palette.secondary.main,
  borderTopLeftRadius: 20,
  borderBottomLeftRadius: 20,
  borderTopRightRadius: displayTitle ? 0 : 20,
  borderBottomRightRadius: displayTitle ? 0 : 20,

  '&:after': {
    display: displayTitle ? 'block' : 'none',
    content: '""',
    position: 'absolute',
    borderStyle: 'solid',
    borderWidth: '12px 0 13px 10px',
    borderColor: `transparent transparent transparent ${
      variant === 'ERROR' ? theme.palette.error.main : theme.palette.secondary.main
    }`,
    right: 0,
    transform: 'translateX(100%)',
    top: 0,
    outline: 0,
  },
}));

const Title = styled('div')<{ variant: Props['variant'] }>(({ theme, variant }) => ({
  display: 'inline-block',
  textAlign: 'center',
  fontWeight: 700,
  fontSize: 12,
  whiteSpace: 'nowrap',
  background:
    variant === 'ERROR'
      ? theme.custom.themeColors.error[80]
      : theme.custom.colors.secondaryLightest,
  borderTopRightRadius: 20,
  borderBottomRightRadius: 20,
  padding: theme.spacing(0.5, 2),
  color: variant === 'ERROR' ? theme.custom.themeColors.white : theme.palette.secondary.main,
}));

interface Props {
  claimLevel: string;
  title?: string;
  variant?: 'DEFAULT' | 'ERROR';
}

const LevelPill: React.FC<Props> = ({ claimLevel, title, variant = 'DEFAULT' }) => {
  if (!claimLevel) {
    return null;
  }

  return (
    <Container data-cy="level-pill">
      <Pill displayTitle={!!title} variant={variant}>
        {claimLevel}
      </Pill>
      {title && (
        <Tooltip title={title}>
          <Title variant={variant}>
            {truncate(title, {
              length: 25,
              separator: '',
            })}
          </Title>
        </Tooltip>
      )}
    </Container>
  );
};

export default LevelPill;
