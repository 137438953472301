import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { HexColorInput } from 'react-colorful';

const HexCodeInputBox = styled('div')(() => ({
  position: 'relative',
}));

const HexSymbol = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  display: 'block',
  top: theme.spacing(0.5),
  fontWeight: 600,
  color: theme.palette.secondary.main,
  paddingLeft: theme.spacing(1),
  zIndex: 1,
}));

const StyledHexColorInput = styled(HexColorInput)(({ theme }) => ({
  display: 'block',
  padding: theme.spacing(1),
  textAlign: 'center',
  border: `1px solid ${theme.palette.grey[400]}`,
  borderRadius: theme.spacing(0.5),
  width: 90,
  alignSelf: 'center',
  color: theme.palette.secondary.main,
  fontWeight: 600,

  '&:focus': {
    border: `1px solid ${theme.palette.primary.main}`,
    outline: 'none',
    borderRadius: theme.spacing(0.5),
  },
}));

const HexCodeInput = ({ color, handleChange, onClick }) => {
  return (
    <HexCodeInputBox>
      <HexSymbol>#</HexSymbol>
      <StyledHexColorInput color={color} onChange={handleChange} onClick={onClick} />
    </HexCodeInputBox>
  );
};

HexCodeInput.propTypes = {
  color: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  onClick: PropTypes.func,
};

export default HexCodeInput;
