import React, { FC, Fragment } from 'react';
import { styled } from '@mui/material/styles';
import { Fade, Zoom } from '@mui/material';
import { TextControlBar } from 'components/WelcomeSlides';
import { PageContainer, TextContainer, ImageContainer, Headline } from './styles';
import pulseLine from 'assets/img/welcomeSlides/pulse-line-2.svg';
import { useConfig } from 'components/hooks';
import { ThemeTypography } from 'designSystem';
import PhoneScreen from 'components/PhoneScreen/PhoneScreen';

const StyledPulse = styled('img')(({ theme }) => ({
  position: 'absolute',
  left: theme.spacing(0),
  width: '380px !important',
  bottom: 50,
}));

const CreateProducts: FC<{ active: boolean }> = ({ active }) => {
  const { welcomeSlideGif } = useConfig();

  return (
    <Fragment>
      <PageContainer>
        <Zoom in={active}>
          <TextContainer>
            <Headline variant="TITLE_LARGE">Get started!</Headline>
            <ThemeTypography variant="BODY_LARGE">
              Take your customers on a <br /> journey to the origins of your <br /> products.
            </ThemeTypography>
            <Zoom in={active}>
              <StyledPulse src={pulseLine} alt="pulse" />
            </Zoom>
          </TextContainer>
        </Zoom>
        <Fade in={active} timeout={400}>
          <ImageContainer>
            <PhoneScreen src={welcomeSlideGif} />
          </ImageContainer>
        </Fade>
      </PageContainer>
      <TextControlBar />
    </Fragment>
  );
};

export default CreateProducts;
