import * as Yup from 'yup';

export const createProductSchema = Yup.object().shape({
  title: Yup.string().required('This field is required'),
  articleNumber: Yup.string().nullable().default(''),
  slug: Yup.string()
    .required('This field is required')
    .matches(/^[a-zA-Z0-9-]+$/, 'Cannot contain special characters or spaces'),
  languageConfig: Yup.object().shape({
    default: Yup.string().default('en'),
    enabled: Yup.array().default(['en']),
  }),
});

export const productLanguageSchema = Yup.object().shape({
  languageConfig: Yup.object().shape({
    default: Yup.string().default('en'),
  }),
});
