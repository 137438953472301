import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';

const ProgressContainer = styled('div')({
  position: 'absolute',
  width: '100%',
  left: 0,
  top: 0,
});

const ProgressIndicator = ({ progressValue, className }) => {
  const [internalProgress, setProgress] = useState(null);
  const lastProgress = useRef();
  const hidingTimer = useRef();

  useEffect(() => {
    if (lastProgress.current === 66) {
      setProgress(100);

      hidingTimer.current = setTimeout(() => {
        setProgress(null);
      }, 500);
    } else {
      setProgress(progressValue);
    }

    lastProgress.current = progressValue;

    return () => clearTimeout(hidingTimer.current);
  }, [progressValue]);

  return (
    <ProgressContainer className={className}>
      {internalProgress && <LinearProgress variant="determinate" value={internalProgress} />}
    </ProgressContainer>
  );
};

ProgressIndicator.propTypes = {
  progressValue: PropTypes.number,
};

export default ProgressIndicator;
