import { styled } from '@mui/material';
import { ReactComponent as PinIcon } from 'assets/img/icons/pin.svg';
import React from 'react';
import ActivityItem from '../ActivitiesTable/ActivityItem';
import { ShadowedFieldBlock } from '../Partners.styles';
import { IActivity } from 'types/activity.types';

interface Props {
  activity: IActivity;
  isSelected?: boolean;
  hasShadow?: boolean;
  onSelect?: () => void;
}

export const LocationBlockWrapper = styled(ShadowedFieldBlock)<{
  isSelectable: boolean;
  isSelected: boolean;
  hasShadow: boolean;
}>(({ theme, isSelectable, isSelected, hasShadow }) => ({
  flex: 1,
  cursor: isSelectable ? 'pointer' : 'auto',
  position: 'relative',

  // Showing and hiding the border moves the content of the box, so we use a pseudo element instead
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    border: isSelected ? `2px solid ${theme.palette.secondary.main}` : 'none',
    borderRadius: 4,
    boxShadow: hasShadow ? theme.custom.shadows[1] : 'none',
  },

  '&:hover': {
    background: theme.custom.colors.backgroundLight,
  },
}));

export const Title = styled('div')(() => ({
  fontSize: 16,
  fontWeight: 900,
}));

export const SubTitle = styled('div')(({ theme }) => ({
  fontSize: 12,
  fontWeight: 700,
  color: theme.custom.colors.textLight,
}));

export const BlockWrapper = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const Pin = styled(PinIcon)(({ theme }) => ({
  marginRight: theme.spacing(0.5),
  width: 30,
  height: 30,
  color: theme.custom.themeColors.primary[5],
}));

const ActivityRow: React.FC<Props> = ({ activity, onSelect, isSelected = false, hasShadow }) => {
  return (
    <LocationBlockWrapper
      spacing={1.5}
      onClick={onSelect}
      isSelectable={onSelect !== undefined}
      isSelected={isSelected}
      hasShadow={!!hasShadow}
    >
      <ActivityItem activity={activity} />
    </LocationBlockWrapper>
  );
};

export default ActivityRow;
