import { useApolloClient, useMutation } from '@apollo/client';
import { useMessages } from 'components/hooks';
import { DELETE_DOCUMENTS } from 'graphql/mutations';
import { GET_DOCUMENTS } from 'graphql/queries';

function useUploadDialogActions() {
  const { setSuccessMessage, setErrorMessage } = useMessages();
  const client = useApolloClient();

  const [deleteDocuments] = useMutation(DELETE_DOCUMENTS, {
    onError: () => setErrorMessage('There was an error removing your documents'),
    onCompleted: () => setSuccessMessage('Documents successfully removed'),
    update: (cache, { data: { deleteDocument } }) => {
      const {
        documents: { edges, pageInfo },
      } = cache.readQuery({
        query: GET_DOCUMENTS,
      });
      const updatedDocuments = edges.filter(
        document => !deleteDocument.ids.includes(document.node.id)
      );

      cache.writeQuery({
        query: GET_DOCUMENTS,
        data: {
          documents: {
            edges: updatedDocuments,
            pageInfo: pageInfo,
          },
        },
      });
    },
  });

  const handleSaveDocuments = async ({ documents, deletedDocuments }) => {
    if (deletedDocuments.length) {
      await deleteDocuments({
        variables: {
          ids: deletedDocuments,
        },
      });
    }

    if (documents.length) {
      const { cache } = client;
      const {
        documents: { edges, pageInfo },
      } = cache.readQuery({
        query: GET_DOCUMENTS,
      });

      const newDocuments = documents.map(document => ({
        __typename: 'DocumentEdge',
        node: document,
      }));

      cache.writeQuery({
        query: GET_DOCUMENTS,
        data: {
          documents: {
            edges: [...newDocuments, ...edges],
            pageInfo: pageInfo,
          },
        },
      });
    }
  };

  const handleCancelEditing = async ({ documents, deletedDocuments }) => {
    if (deletedDocuments.length || documents.length) {
      const documentIds = documents.map(({ id }) => id);
      await deleteDocuments({
        variables: {
          ids: [...deletedDocuments, ...documentIds],
        },
      });
    }
  };

  return {
    handleCancelEditing,
    handleSaveDocuments,
    handleDeleteDocuments: deleteDocuments,
  };
}

export default useUploadDialogActions;
