import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import { convertHexToRGBarray } from '../../utils';

const CustomTooltip = styled(Tooltip)(({ theme }) => ({
  tooltip: {
    backgroundColor: `rgba(${convertHexToRGBarray(theme.palette.secondary.main)}, 0.9)`,
  },
}));

export default CustomTooltip;
