import * as Yup from 'yup';
import { createImageSchema, htmlStringHasText } from './shared';
import { ImageVariant } from 'types/media.types';

export interface IPartnerFormValues {
  id?: string;
  title: string;
  email?: string;
  image?: ImageVariant;
  externalId?: string;
}

export const partnerSchema = Yup.object().shape({
  id: Yup.string().nullable(),
  image: createImageSchema({
    message: 'Please add an image of your partnerx',
    required: false,
  }),
  title: Yup.string().default('').required('This field is required'),
  externalId: Yup.string().default('').nullable(),
}) as Yup.ObjectSchema<IPartnerFormValues>;

export const partnerInviteSchema = Yup.object().shape({
  id: Yup.string().nullable(),
  image: createImageSchema({
    message: 'Please add an image of your partnerx',
    required: false,
  }),
  email: Yup.string()
    .default('')
    .email('Please enter a valid email')
    .required('This field is required'),
  title: Yup.string().default('').required('This field is required'),
  externalId: Yup.string().default('').nullable(),
}) as Yup.ObjectSchema<IPartnerFormValues>;

export const PartnerReInviteSchema = Yup.object().shape({
  email: Yup.string()
    .default('')
    .email('Please enter a valid email')
    .required('This field is required'),
});

export const PartnerRequestSchema = Yup.object().shape({
  requestTitle: Yup.string().required('Title is required'),
  requestMessage: Yup.string().default('').label('Message'),
  requestType: Yup.string()
    .default('DOCUMENT')
    .oneOf(['DOCUMENT', 'CHAIN', 'SELF_ASSESSMENT'])
    .required(),
  componentId: Yup.string()
    .default('')
    .nullable()
    .when('requestType', {
      is: 'CHAIN',
      then: Yup.string().required('Please add a component'),
    }),
});

export const PartnerDenyRequestSchema = Yup.object().shape({
  requestMessage: Yup.string().default('').label('Message').test(htmlStringHasText),
});
