import React from 'react';
import PropTypes from 'prop-types';
import { FormHelperText } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ExclamationCircle } from '@styled-icons/bootstrap/ExclamationCircle';

const Message = styled(FormHelperText)(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  lineHeight: 1,
  background: theme.palette.error.light,
  borderRadius: 4,
  color: theme.palette.error.main,
  fontWeight: 600,
  padding: 4,
  border: `1px solid ${theme.palette.error.main}`,
}));

const Icon = styled('span')(({ theme }) => ({
  padding: theme.spacing(0, 1, 0.25, 0),
}));

const ErrorMessage = ({ children, className }) => {
  return (
    <Message error className={className}>
      <Icon>
        <ExclamationCircle size={14} />
      </Icon>
      {children}
    </Message>
  );
};

ErrorMessage.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
};

export default ErrorMessage;
