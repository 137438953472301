import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { TextField } from 'formik-mui';
import { styled } from '@mui/material/styles';
import { TextFieldLimited } from 'components/Forms';

const StyledTextField = styled(({ charLimit, ...props }) =>
  charLimit ? <TextFieldLimited charLimit={charLimit} {...props} /> : <TextField {...props} />
)(() => ({
  '& .MuiOutlinedInput-multiline': {
    padding: '11px 14px',
  },
}));

const FocusedMultilineField = ({ rows, ...props }) => {
  const [focused, setFocused] = useState(false);

  return (
    <Field
      {...props}
      component={StyledTextField}
      InputProps={{
        onFocus: () => setFocused(true),
        onBlur: () => setFocused(false),
      }}
      rows={focused ? rows : 1}
    />
  );
};

FocusedMultilineField.defaultProps = {
  rows: 4,
};

FocusedMultilineField.propTypes = {
  rows: PropTypes.number,
};

export default FocusedMultilineField;
