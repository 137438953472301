import { Box, Divider, styled } from '@mui/material';
import FlexBox from 'components/Structure/FlexBox';
import ThemeTypography from 'designSystem/Primitives/Typography/ThemeTypography';
import React, { FC } from 'react';

interface IInfoDetailNumberProps {
  number?: number | string;
  unit?: string;
  description: string;
}

const StyledDivider = styled(Divider)(({ theme }) => ({
  width: 4,
  height: theme.spacing(6.5),
  borderRadius: theme.spacing(1),
  background: theme.custom.themeColors.grayScale[30],
}));

const InfoDetailNumber: FC<IInfoDetailNumberProps> = ({ number, unit, description }) => {
  return (
    <FlexBox>
      <StyledDivider orientation="vertical" />
      <Box ml={2}>
        <Box display="flex" alignItems="baseline">
          <ThemeTypography autoOverflow variant="TITLE_MEDIUM">
            {number || '-'}
          </ThemeTypography>
          <Box mr={1} />
          {unit && (
            <ThemeTypography variant="BODY_LARGE_BOLD" color="GRAY_60">
              {unit}
            </ThemeTypography>
          )}
        </Box>
        <ThemeTypography variant="BODY_SMALL">{description}</ThemeTypography>
      </Box>
    </FlexBox>
  );
};

export default InfoDetailNumber;
