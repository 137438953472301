import React, { FC, MouseEvent } from 'react';
import { Box, TableCell, Tooltip, styled } from '@mui/material';
import { StyledTableBody } from 'components/TableBase';
import { Trash } from '@styled-icons/bootstrap';
import { PencilSquare } from '@styled-icons/bootstrap/PencilSquare';
import {
  StyledIconButton,
  IconContainer,
  StyledCell,
  TableRow,
  Date,
} from 'components/Partners/PartnersTable/PartnersTableBody/PartnersTableBody.styles';
import { HeaderColumn } from 'types/types';
import { usePartnerMutations } from 'components/Partners/hooks';
import Moment from 'react-moment';
import { IActivity } from 'types/activity.types';
import ActivityItem from './ActivityItem';
import { ActivitiesTableColumnKeys } from './activitiesHeaderColumns';
import { ThemeTypography } from 'designSystem';
import { PartnerPlaceholderImage } from 'assets/img';
import { Booleanish } from 'types/booleanish.types';

interface Props {
  activities: IActivity[];
  rowsPerPage: number;
  currentPage: number;
  isTransparent?: boolean;
  headerColumns: HeaderColumn[];
  onSelect: (activity: IActivity) => void;
  onEdit: (activity: IActivity) => void;
}

const StyledImage = styled('div')<{ backgroundImage: string }>(({ backgroundImage }) => ({
  width: '42px',
  height: '42px',
  flexShrink: 0,
  backgroundImage: `url(${backgroundImage})`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  borderRadius: 4,
}));

const ActivityTableBody: FC<Props> = ({
  activities,
  onSelect,
  onEdit,
  rowsPerPage,
  currentPage,
  isTransparent = false,
  headerColumns,
}) => {
  const { handleRemoveActivity } = usePartnerMutations();

  return (
    <StyledTableBody data-cy="activities-table-body">
      {activities
        .slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage)
        .map((activity, index) => {
          const { id } = activity;

          const labelId = `partner-table-${id}`;

          return (
            <TableRow
              data-cy="activity-table-row"
              data-cy-id={id}
              hover
              id={labelId}
              is-transparent={Booleanish(isTransparent)}
              key={id}
              onClick={(event: MouseEvent) => {
                event.stopPropagation();
                onSelect(activity);
              }}
            >
              {headerColumns.map(({ id: columnId, align }) => {
                switch (columnId) {
                  case ActivitiesTableColumnKeys.TITLE: {
                    return (
                      <StyledCell key={`${id}-${columnId}-title`} scope="row" align={align}>
                        <ActivityItem activity={activity} />
                      </StyledCell>
                    );
                  }

                  case ActivitiesTableColumnKeys.PARTNER: {
                    return (
                      <StyledCell key={`${id}-${index}-partner`} scope="row" align={align}>
                        <Box display="flex" alignItems="center">
                          <StyledImage
                            backgroundImage={activity.ownedBy?.logo?.url || PartnerPlaceholderImage}
                          />
                          <Box ml={2}>
                            <ThemeTypography variant="ITEM_SUBTITLE">
                              {activity.ownedBy?.name || 'Unknown'}
                            </ThemeTypography>
                          </Box>
                        </Box>
                      </StyledCell>
                    );
                  }
                  case ActivitiesTableColumnKeys.MODIFIED_TIMESTAMP: {
                    return (
                      <StyledCell key={`${id}-${index}-created-at`} scope="row" align={align}>
                        <Date variant="subtitle2">
                          <Moment format="LL">{activity.modifiedTimestamp}</Moment>
                        </Date>
                      </StyledCell>
                    );
                  }
                  case ActivitiesTableColumnKeys.ACTIONS: {
                    return (
                      <TableCell
                        key={`${id}-${columnId}-actions`}
                        width={100}
                        scope="row"
                        align={align}
                      >
                        <IconContainer>
                          {[
                            {
                              icon: <PencilSquare size="15" />,
                              tooltip: 'Edit partner location',
                              onClick: onEdit,
                              dataCy: 'edit-activity-btn',
                            },
                            {
                              icon: <Trash size="15" />,
                              tooltip: 'Remove partner location',
                              onClick: () =>
                                handleRemoveActivity({
                                  activityId: activity.id,
                                  alertFirst: true,
                                }),
                              dataCy: 'delete-activity-btn',
                            },
                          ].map((action, index) => {
                            return (
                              <Tooltip key={index} title={action.tooltip}>
                                <StyledIconButton
                                  data-cy={action.dataCy}
                                  onClick={event => {
                                    event.stopPropagation();
                                    action.onClick(activity);
                                  }}
                                >
                                  {action.icon}
                                </StyledIconButton>
                              </Tooltip>
                            );
                          })}
                        </IconContainer>
                      </TableCell>
                    );
                  }
                  default:
                    return <></>;
                }
              })}
            </TableRow>
          );
        })}
    </StyledTableBody>
  );
};

export default ActivityTableBody;
