import { Box, Grid, MenuItem, Select, SelectChangeEvent, styled } from '@mui/material';
import { FlexBox } from 'components/Structure';
import { ThemeTypography } from 'designSystem';
import { Percentage } from 'designSystem/Navigation/Stepper/Stepper';
import React, { FC, PropsWithChildren, ReactNode } from 'react';
import { IComplianceGuideDetails, IComplianceGuideSection } from 'types/compliance.types';
import NavigationItem from './components/NavigationItem';

interface IComplianceGuideContentProps extends PropsWithChildren {
  details: IComplianceGuideDetails;

  /*
   * Addional details for the statement
   * e.g. affectedness type
   */
  complianceAdornment?: ReactNode;

  /**
   * The select value for the dropdown
   */
  selectedId: string;
  selectValues: { id: string; title: string }[];

  /**
   * Navigation items
   */
  sections: (IComplianceGuideSection & {
    disabled: boolean;
    completion: Percentage;
    count: number;
  })[];
  selectedSectionKey?: string;
  onSelectionChange: (newSelection: string) => void;
  onMenuSectionClick: (sectionKey: string) => void;
}

const Image = styled('img')(({ theme }) => ({
  height: theme.spacing(7),
  width: theme.spacing(7),
}));

const StyledContainer = styled(FlexBox)(({ theme }) => ({
  padding: theme.spacing(1.5),
  overflow: 'visible',
  position: 'relative',
  height: '100%',
  borderRadius: 8,
  background: '#fff',
  border: '2px solid',
  borderColor: theme.palette.grey[200],
}));

/**
 * This component relies on the ComplianceGuideContext to provide the sections and the selected section.
 * Please wrap this component with the ComplianceGuideContextProvider.
 */
const ComplianceGuideContent: FC<IComplianceGuideContentProps> = ({
  children,
  details: { image, title },
  complianceAdornment,
  selectedId,
  selectValues,
  selectedSectionKey,
  sections,
  onMenuSectionClick,
  onSelectionChange,
}) => {
  const handleSelectionChange = (event: SelectChangeEvent<string>) => {
    onSelectionChange(event.target.value);
  };

  return (
    <Grid container>
      <Grid item xs={12} mb={2}>
        <StyledContainer>
          <Image src={image} alt={title} />
          <Box ml={2} display="flex">
            <Box display="flex" flexDirection="column">
              <ThemeTypography variant="TITLE_SMALL">{title}</ThemeTypography>
              <Box display="flex">
                <Box mr={1} width={200}>
                  <Select
                    fullWidth
                    size="small"
                    value={selectedId}
                    onChange={handleSelectionChange}
                  >
                    {selectValues.map(({ id, title }) => (
                      <MenuItem key={id} value={id}>
                        {title}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>

                {complianceAdornment}
              </Box>
            </Box>
          </Box>
        </StyledContainer>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={2}>
          {sections.map((section, index) => {
            const isSelected = selectedSectionKey === section.key;
            const isDisabled = section.disabled;
            const isCompleted = section.completion === 100;

            const handleClick = () => {
              if (!isDisabled) {
                onMenuSectionClick(section.key);
              }
            };

            return (
              <NavigationItem
                key={section.key}
                title={section.title}
                state={isSelected ? 'selected' : isDisabled ? 'disabled' : 'default'}
                validationState={isCompleted ? 'valid' : 'default'}
                index={index}
                onClick={handleClick}
              />
            );
          })}
        </Grid>
        <Grid item xs={10}>
          {children}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ComplianceGuideContent;
