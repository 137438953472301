import React from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import ThemeTypography from 'designSystem/Primitives/Typography/ThemeTypography';

const Title = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  padding: '3px 0',
  textTransform: 'uppercase',
  letterSpacing: '0.1em',
}));

interface Props {
  children: React.ReactElement | string;
  icon?: React.ReactElement | React.ReactElement[];
  className?: string;
}

const DialogTitle: React.FC<
  Props & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
> = ({ children, icon: Icon, className = '', ...props }) => {
  return (
    <Title className={className} {...props}>
      {Icon ? Icon : null}
      <Box pl={Icon ? 1 : 0}>
        <ThemeTypography autoOverflow variant="TITLE_EXTRA_SMALL">
          {children}
        </ThemeTypography>
      </Box>
    </Title>
  );
};

export default DialogTitle;
