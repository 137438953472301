import { Can } from 'components/Auth';
import ComponentsLayout from 'components/ComponentsLibrary/ComponentsLayout';
import PartnerComponentsLayout from 'components/ComponentsLibrary/PartnerComponentsLayout';
import DocumentsLayout from 'components/DocumentLibrary/DocumentsLayout';
import NotFoundPage from 'components/NotFoundPage/NotFoundPage';
import PartnerHome from 'components/PartnerIntegration/PartnerHome';
import { Layout as SettingsLayout } from 'components/Settings';
import { Layout } from 'components/Structure';
import ActivityLayout from 'components/Structure/ActivitiesLayout';
import SitesLayout from 'components/Structure/SitesLayout';
import {
  CompanySettings,
  DocumentOverview,
  InternalDocuments,
  MediaLibrary,
  PartnerActivities,
  Partners,
  Requests,
  SharedDocuments,
  UserDetails,
  UserList,
  UserSecurity,
} from 'containers';
import ComponentChainMapping from 'containers/ComponentChainMapping';
import ComponentChains from 'containers/ComponentChains';
import ComponentItemOverview from 'containers/ComponentItemOverview';
import ComponentItems from 'containers/ComponentItems';
import DataImportOverview from 'containers/DataImportOverview';
import DataImports from 'containers/DataImports';
import ExternalActivities from 'containers/ExternalActivities';
import InternalActivities from 'containers/InternalActivities';
import PartnerComponentItems from 'containers/PartnerComponentItems';
import PartnerOverview from 'containers/PartnerOverview';
import Sites from 'containers/Sites';
import UnassignedActivities from 'containers/UnassignedActivities';
import React, { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { SiteOwnership } from 'types/site.types';
import SiteOverview from 'containers/SiteOverview';
import ClusterSites from 'containers/ClusterSites';

const PartnerUIRouter: FC = () => (
  <Routes>
    <Route element={<Layout />}>
      {/* Home */}
      <Route path="/" element={<PartnerHome />} />

      {/* Components */}
      <Route
        path="/components"
        element={
          <ComponentsLayout selectedTab="components">
            <ComponentItems />
          </ComponentsLayout>
        }
      />
      <Route path="components/:id" element={<ComponentItemOverview />} />

      {/* Component chains */}
      <Route
        path="/component-chains"
        element={
          <ComponentsLayout selectedTab="component-chains">
            <ComponentChains />
          </ComponentsLayout>
        }
      />
      <Route path="/component-chains/:id/editor" element={<ComponentChainMapping />} />

      {/* Partners */}
      <Route path="/partners" element={<Partners />} />
      <Route path="/partners/:id" element={<PartnerOverview />} />
      <Route path="/partners/:id/activities" element={<PartnerActivities />} />
      <Route
        path="/partners/:id/components"
        element={
          <PartnerComponentsLayout selectedTab="components">
            <PartnerComponentItems />
          </PartnerComponentsLayout>
        }
      />
      <Route
        path="partners/:id/components/:componentId"
        element={<ComponentItemOverview renderInsidePartnerLibrary />}
      />

      {/* Activities */}
      <Route element={<ActivityLayout />}>
        <Route path="/activities/internal" element={<InternalActivities />} />
        <Route path="/activities/external" element={<ExternalActivities />} />
        <Route path="/activities/unassigned" element={<UnassignedActivities />} />
      </Route>

      {/* Sites */}
      <Route element={<SitesLayout />}>
        <Route path="/sites/internal" element={<Sites siteOwnership={SiteOwnership.INTERNAL} />} />
        <Route path="/sites/external" element={<Sites siteOwnership={SiteOwnership.EXTERNAL} />} />
        <Route
          path="/sites/unassigned"
          element={<Sites siteOwnership={SiteOwnership.UNASSIGNED} />}
        />
      </Route>
      <Route path="/sites/internal/:id" element={<SiteOverview />} />
      <Route path="/sites/external/:id" element={<SiteOverview />} />
      <Route path="/sites/unassigned/:id" element={<SiteOverview />} />
      <Route path="/sites/internal/:id/sites" element={<ClusterSites />} />
      <Route path="/sites/external/:id/sites" element={<ClusterSites />} />
      <Route path="/sites/unassigned/:id/sites" element={<ClusterSites />} />

      {/* Requests */}
      <Route path="/requests" element={<Requests />} />

      {/* Documents */}
      <Route element={<DocumentsLayout />}>
        <Route path="/documents/internal" element={<InternalDocuments />} />
        <Route path="/documents/shared" element={<SharedDocuments />} />
      </Route>
      <Route
        path="/documents/internal/:documentId"
        element={<DocumentOverview documentType="INTERNAL" />}
      />
      <Route
        path="/documents/shared/:documentId"
        element={<DocumentOverview documentType="SHARED" />}
      />
      <Route
        path="/documents/requested/:documentId"
        element={<DocumentOverview documentType="PARTNER" />}
      />

      {/* Partners are deactivates for now until we have partner integration 1.4 */}
      {/* <Route path="/partners" element={<Partners />} />
      <Route path="/partners/:id" element={<PartnerOverview />} />
      <Route path="/partners/:id/activities" element={<PartnerActivities />} /> */}

      {/* Origin data import */}
      <Route path="/imports" element={<DataImports />} />
      <Route path="/imports/farms" element={<DataImportOverview />} />
      <Route path="/imports/farms/:importId" element={<DataImportOverview />} />

      {/* Media */}
      <Route path="/media-library" element={<MediaLibrary />} />

      {/* Settings */}
      <Route element={<SettingsLayout />}>
        <Route path="/settings" element={<UserDetails />} />
        <Route path="/settings/security" element={<UserSecurity />} />
        <Route
          path="/settings/company"
          element={
            <Can rule="companySettings:access" no={() => <Navigate to="/settings" />}>
              <CompanySettings />
            </Can>
          }
        />
        <Route
          path="/settings/users"
          element={
            <Can rule="userSettings:access" no={() => <Navigate to="/settings" />}>
              <UserList />
            </Can>
          }
        />
      </Route>
    </Route>

    {/* 404 */}
    <Route path="*" element={<NotFoundPage />} />
  </Routes>
);

export default PartnerUIRouter;
