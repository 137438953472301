import React, { useState, useMemo, Fragment } from 'react';
import { produce } from 'immer';
import {
  SeeHistoryButton,
  MinimizeButton,
  MiddleHistory,
  ClaimHistoryContainer,
  DotsBefore,
} from './styles';
import ClaimHistoryItem from 'components/ImpactClaims/ClaimHistory/ClaimHistoryItem';
import { ChevronDown } from '@styled-icons/bootstrap/ChevronDown';
import { ChevronUp } from '@styled-icons/bootstrap/ChevronUp';
import { ThreeDotsVertical } from '@styled-icons/bootstrap/ThreeDotsVertical';
import { ImpactClaimLog } from 'types/impactClaim.types';

interface Props {
  history: ImpactClaimLog[];
}

const ClaimHistory: React.FC<Props> = ({ history }) => {
  const [showMiddleLog, setShowMiddelLog] = useState(false);
  const toggleShowMiddleLog = () => setShowMiddelLog(curr => !curr);

  const middleHistory = useMemo(() => {
    return produce(history, draft => {
      if (!draft || draft.length < 3) return [];
      draft.shift();
      draft.pop();
      return draft;
    });
  }, [history]);

  return (
    <Fragment>
      <ClaimHistoryContainer>
        <ClaimHistoryItem logItem={history[0]} showAuthor={showMiddleLog} />

        {middleHistory.length > 0 ? (
          <Fragment>
            <MiddleHistory open={showMiddleLog || middleHistory.length === 1}>
              {middleHistory.map(logItem => (
                <ClaimHistoryItem
                  key={logItem.timestamp.toString()}
                  logItem={logItem}
                  showAuthor={showMiddleLog}
                />
              ))}
            </MiddleHistory>
            {!showMiddleLog && middleHistory.length !== 1 && (
              <SeeHistoryButton onClick={toggleShowMiddleLog}>
                <DotsBefore>
                  <ThreeDotsVertical size={20} />
                </DotsBefore>
                <ChevronDown size={16} />
                See all history
              </SeeHistoryButton>
            )}
          </Fragment>
        ) : null}

        {history.length > 1 && (
          <ClaimHistoryItem
            logItem={history[history.length - 1]}
            showStatusColor
            showAuthor={showMiddleLog}
          />
        )}
      </ClaimHistoryContainer>
      {middleHistory.length > 0 && showMiddleLog && (
        <MinimizeButton onClick={toggleShowMiddleLog}>
          <ChevronUp size={16} />
          Minimize
        </MinimizeButton>
      )}
    </Fragment>
  );
};

export default ClaimHistory;
