import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { Form, Formik } from 'formik';
import { addressSchema } from 'constants/schemas';
import { styled } from '@mui/material/styles';
import AddressFields from './AddressFields';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const FormGrid = styled(({ paddingHorizontal, ...props }) => <Grid {...props} />)(
  ({ paddingHorizontal }) => ({
    padding: paddingHorizontal ? `0 ${paddingHorizontal}px` : 0,
  })
);

const AddressInputs = ({ address, fullWidth, paddingHorizontal, onSubmit, children }) => {
  return (
    <Formik
      initialValues={{
        ...(address || addressSchema.default()),
      }}
      validateOnBlur={false}
      validateOnChange={true}
      validationSchema={addressSchema}
      onSubmit={values =>
        onSubmit({
          address: {
            ...values,
          },
        })
      }
    >
      {({ isSubmitting, submitForm }) => (
        <Form>
          <FormGrid paddingHorizontal={paddingHorizontal} container spacing={3}>
            <AddressFields fullWidth={fullWidth} />
          </FormGrid>
          {children({
            submitForm,
            isSubmitting,
          })}
        </Form>
      )}
    </Formik>
  );
};

AddressInputs.defaultProps = {
  fullWidth: false,
};

AddressInputs.propTypes = {
  address: PropTypes.object,
  fullWidth: PropTypes.bool,
  paddingHorizontal: PropTypes.number,
};

export default AddressInputs;
