import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import Button from 'assets/img/widgetPreview/btn_widget.png';
import Map from 'assets/img/widgetPreview/map_widget.png';
import PropTypes from 'prop-types';
import React from 'react';

const StyledImg = styled('img')(() => ({
  display: 'block',
}));

const WidgetExampleIllustration = ({ image, width, height, ...props }) => {
  return (
    <Box {...props}>
      <StyledImg alt={`widget-${image}`} src={getImage(image)} width={width} height={height} />
    </Box>
  );
};

WidgetExampleIllustration.prototype = {
  image: PropTypes.oneOf(['BUTTON', 'MAP']),
};

export default WidgetExampleIllustration;

const getImage = imageName => {
  return {
    BUTTON: Button,
    MAP: Map,
  }[imageName];
};
