import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import CardContainer from '../../Layout/CardContainer/CardContainer';

interface ITableSkeletonProps {
  maxWidth: string;
  rows?: number;
  cols?: number;
}

const GrayCardContainer = styled(CardContainer)(({ theme }) => ({
  backgroundColor: theme.custom.themeColors.primary[5],
  '&:hover': {
    backgroundColor: theme.custom.themeColors.primary[5],
  },
}));

const TableSkeleton: React.FC<ITableSkeletonProps> = ({ maxWidth, rows = 3, cols = 4 }) => {
  return (
    <GrayCardContainer padding={0.5} maxWidth={maxWidth}>
      {[...Array(rows).keys()].map(row => (
        <Box key={row} display="flex">
          {[...Array(cols).keys()].map(col => (
            <Box key={col} p={2} bgcolor="#fff" flex={1} m={0.2} />
          ))}
        </Box>
      ))}
    </GrayCardContainer>
  );
};

export default TableSkeleton;
