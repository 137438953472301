import { styled } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';

const TabPanel = styled('div')(() => ({
  height: '100%',
}));

export const useTabStyles = makeStyles(theme => ({
  tabs: {
    '& .MuiTabs-indicator': {
      display: 'none',
    },

    '& .MuiTab-textColorPrimary.Mui-selected': {
      background: theme.custom.colors.backgroundMedium,
    },
  },
  tab: {
    padding: theme.spacing(2),
    width: 160,
    fontSize: 14,
    fontWeight: 600,
    textTransform: 'none',
    background: '#EEEEEE',
    marginRight: theme.spacing(2),
    borderTopLeftRadius: theme.spacing(1),
    borderTopRightRadius: theme.spacing(1),

    '&:last-of-type': {
      borderRight: 'none',
    },
  },
}));

const SidebarTab = ({ children, value, index, ...rest }) => (
  <TabPanel
    role="tabpanel"
    hidden={value !== index}
    id={`nav-tabpanel-${index}`}
    aria-labelledby={`nav-tab-${index}`}
    {...rest}
  >
    {value === index && children}
  </TabPanel>
);

SidebarTab.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default SidebarTab;
