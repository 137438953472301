import { PartnerRequest } from 'types/partner.types';
import React from 'react';
import { ThemeTypography } from 'designSystem';
import { CompanyNameLogo } from 'components/Structure';
import { getRequestCompaniesList } from 'components/Home/utils/getRequestCompaniesList';
import { Box } from '@mui/material';
import { AvailableSizes } from 'types/enums';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';

const MAX_COMPANY_WRITTEN_LENGTH = 2;

const ClickablePartnerName = styled(Box)(({ isClickable }: { isClickable: boolean }) => ({
  cursor: isClickable ? 'pointer' : 'unset',
}));

interface Props {
  requests: PartnerRequest[];
  partnerUser: boolean;
}

const HomeRequestTitle: React.FC<Props> = ({ requests, partnerUser }) => {
  const navigate = useNavigate();
  const companies = getRequestCompaniesList(requests, partnerUser ? 'FROM' : 'TO');

  const navigateToPartner = (companyId: string | undefined) => {
    if (!partnerUser && companyId) {
      navigate(`/partners/${companyId}`);
    }
  };

  return (
    <Box px={3} pt={2}>
      <Box display="flex" flexWrap="wrap">
        <Box display="flex" mr={1} data-cy="home-requests-title-companies">
          {companies.length <= MAX_COMPANY_WRITTEN_LENGTH ? (
            companies.map((company, index) => {
              // find one request of the company in order to get the partner id and navigate to the partner overview
              const requestOfCompany = requests.find(
                request => company.id === request.requestedToCompany.id
              );
              return (
                <Box key={company.id} display="flex" alignItems="center">
                  <ClickablePartnerName
                    onClick={() => navigateToPartner(requestOfCompany?.id)}
                    display="flex"
                    alignItems="center"
                    isClickable={!partnerUser}
                  >
                    <CompanyNameLogo
                      size={AvailableSizes.LARGE}
                      companyName={company.name}
                      companyLogoUrl={company.logo?.url}
                    />
                  </ClickablePartnerName>
                  <ThemeTypography variant="TITLE_SMALL">
                    {index < companies.length - 1 && ' and '}
                  </ThemeTypography>
                </Box>
              );
            })
          ) : (
            <ThemeTypography variant="TITLE_SMALL" color={'GRAY_40'}>
              {companies.length} collaborators
            </ThemeTypography>
          )}
        </Box>
        <Box whiteSpace="nowrap" display="flex" alignItems="center">
          <ThemeTypography variant="TITLE_SMALL">
            {companies.length > 1 ? 'have ' : 'has '}
            {partnerUser
              ? 'requested information from you'
              : `responded to your ${requests.length <= 1 ? 'request' : 'requests'}`}
          </ThemeTypography>
        </Box>
      </Box>
    </Box>
  );
};

export default HomeRequestTitle;
