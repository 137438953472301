import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { GET_PRODUCTS_HOME } from 'graphql/queries';
import { sortOnContentStatus } from 'utils';
import { useConfig } from 'components/hooks';
import { removeGraphConnections } from 'utils/graphql.utils';

function useHomeData() {
  const { homeProductsMaxLength } = useConfig();
  const {
    data: {
      products: { edges: products = [] } = {},
      overallProductStatus: { status: overallStatus, homeTitle } = {},
      companyAnalyticsExist,
      partnerRequests: partnerRequestsData,
    } = {},
    error,
    loading,
  } = useQuery(GET_PRODUCTS_HOME, {
    variables: {
      requestFilters: {
        requestStatus: ['DENIED', 'PROVIDED'],
        requestDirection: 'OUTGOING',
      },
    },
  });

  const partnerRequests = partnerRequestsData ? removeGraphConnections(partnerRequestsData) : [];

  const filteredProducts = useMemo(
    () =>
      products
        .map(({ node }) => node)
        .sort(
          (a, b) => new Date(b.createdTimestamp).valueOf() - new Date(a.createdTimestamp).valueOf()
        ) || [],
    [products]
  );

  const productPublishedSingleLanguage = useMemo(
    () =>
      filteredProducts.find(
        product => product.isPublished && product.publishedLanguages.length === 1
      ),
    [filteredProducts]
  );

  const hasPublishedProduct = useMemo(
    () => filteredProducts.some(product => product.isPublished),
    [filteredProducts]
  );

  const highlightedProduct = useMemo(
    () => (filteredProducts.length ? sortOnContentStatus(filteredProducts)[0] : null),
    [filteredProducts]
  );

  const showProductList = useMemo(
    () => filteredProducts.length > 1 || hasPublishedProduct,
    [hasPublishedProduct, filteredProducts]
  );

  return {
    loading,
    error,
    filteredProducts: filteredProducts.slice(0, homeProductsMaxLength),
    productPublishedSingleLanguage,
    hasPublishedProduct,
    overallStatus,
    homeTitle,
    highlightedProduct,
    companyAnalyticsExist,
    showProductList,
    partnerRequests,
  };
}

export default useHomeData;
