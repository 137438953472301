import theme from 'styles/theme';
import sortBy from 'lodash/sortBy';
import { configInstance } from 'config';

const productPolicies = {
  Product: {
    fields: {
      lastEditedLang: value => value || null,
      styledProgress: (value, { readField }) => {
        const status = readField('status');
        const contentStatus = readField('contentStatus');
        const { grey, success } = theme.palette;

        switch (status) {
          case 'PUBLISHED':
            return { status, color: success.main, contentStatus };
          case 'DRAFT':
            return { status, color: grey[600], contentStatus };
          case 'ARCHIVED':
            return { status, color: grey[600], contentStatus };
          default:
            return { status, color: grey[600], contentStatus };
        }
      },
      styledStatus: (value, { readField }) => {
        const status = readField('status');
        const { text, success, primary, grey } = theme.palette;

        if (status === 'ARCHIVED') {
          return ['Archived', null, text.secondary, grey[300]];
        }

        if (status === 'DRAFT') {
          return ['Draft', null, text.secondary, primary.light];
        }

        if (status === 'PUBLISHED') {
          return ['Published', null, success.main, success.light];
        }

        return [];
      },
      isPublished: (value, { readField }) => {
        const status = readField('status');
        return status === 'PUBLISHED';
      },
      isDraft: (value, { readField }) => {
        const status = readField('status');
        return status === 'DRAFT';
      },
      isArchived: (value, { readField }) => {
        const status = readField('status');
        return status === 'ARCHIVED';
      },
      languageConfig: value => {
        if (!value) return value;

        return {
          ...value,
          enabled: sortBy(value?.enabled, lang => value?.default !== lang),
        };
      },
      notEnabledLanguages: (value, { readField }) => {
        const languageConfig = readField('languageConfig');

        return configInstance.productLanguages
          .map(lang => lang.key)
          .filter(lang => !languageConfig?.enabled?.includes(lang));
      },
      supplyChain: () => {
        return {
          id: '7adc7ba6-a468-4a8b-9aab-fb5c5eb881e5',
          title: 'Italian spice mix',
          image: {
            url: 'https://seedtrace-prd.imgix.net/companies/95dadf3b-4b0a-4602-b625-ace68eb38245/images/5ae35d6a-ce00-4183-80d0-c872661fb5e6/RosportMotherbrandNoBaselineWhiteFondGrisajustcopy.png',
            imageId: 1,
            __typename: 'ImageVariant',
          },
          numberOfPartners: 5,
          inUse: true,
          modifiedTimestamp: '2021-05-20T12:00:00.000Z',
          supplyChainTasksDetails: {
            completed: 3,
            total: 8,
          },
          productId: '7adc7ba6-a468-4a8b-9aab-fb5c5eb881e5',
          __typename: 'SupplyChain',
        };
      },
    },
  },
};

export default productPolicies;
