import { gql } from '@apollo/client';

export const VIEWS_FRAGMENT = gql`
  fragment viewsValues on AnalyticsViews {
    current
    previous
    percentageChange
  }
`;

export const SUMMARY_FRAGMENT = gql`
  fragment summaryValues on AnalyticsSummary {
    totalViewsSincePublish
    totalQrViewsSincePublish
    totalUniqueViewsSincePublish
    views {
      ...viewsValues
    }
    qrViews {
      ...viewsValues
    }
    uniqueViews {
      ...viewsValues
    }
    averageTimeSpent {
      ...viewsValues
    }
  }
  ${VIEWS_FRAGMENT}
`;

export const VISITS_FRAGMENT = gql`
  fragment visitValues on Visits {
    value
    date
  }
`;

export const ANALYTICS_ACTIVITY_FRAGMENT = gql`
  fragment analyticsActivityValues on PageActivity {
    total {
      ...visitValues
    }
    totalUnique {
      ...visitValues
    }
    timeInterval
    qr {
      ...visitValues
    }
    qrUnique {
      ...visitValues
    }
    direct {
      ...visitValues
    }
    directUnique {
      ...visitValues
    }
  }
  ${VISITS_FRAGMENT}
`;

export const GEO_DATA_FRAGMENT = gql`
  fragment geoValues on GeoAnalytics {
    lat
    lng
    weight
  }
`;

export const TIME_PER_SECTION_FRAGMENT = gql`
  fragment timePerSectionValues on TimePerSection {
    contentType
    total
  }
`;

export const AVG_TIME_ON_PAGE_FRAGMENT = gql`
  fragment avgTimeOnPageValues on AvgTimeSpent {
    date
    value
    visitorCount
  }
`;

export const CLICK_COUNTS_FRAGMENT = gql`
  fragment clickCountsValues on ClickCount {
    title
    count
  }
`;

export const AVG_JOURNEY_STEP_FRAGMENT = gql`
  fragment avgJourneyStepValues on AvgJourneyStep {
    average
    totalSteps
  }
`;

export const TIME_PER_IMPACT_CLAIM = gql`
  fragment timePerImpactClaimValues on TimePerImpactClaim {
    impactClaimId
    total
    impactClaim {
      id
      impactCatalogItem {
        id
        title
      }
    }
  }
`;
