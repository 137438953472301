import { Grid, MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ColorSelector, FormikEffect } from 'components/Forms';
import { FieldWrapper } from 'designSystem';
import { mergeDefaults } from 'components/Forms/utils';
import { edges, logos, shapes } from 'components/Product/QRCode/QRCodeEditor/constants';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import PropTypes from 'prop-types';
import React from 'react';
import qrCodeSchema from 'constants/schemas/qrCode';

const StyledImage = styled('img')(({ theme }) => ({
  height: 20,
  marginRight: theme.spacing(1),
}));

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ImageButton = styled(({ active, ...props }) => <div {...props} />)(({ active, theme }) => ({
  border: `1px solid ${theme.palette.grey[400]}`,
  borderColor: active ? theme.palette.primary.main : theme.palette.grey[400],
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1),
  marginRight: theme.spacing(1),
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',

  '& img': {
    height: 50,

    [theme.breakpoints.down('md')]: {
      height: 40,
    },
  },
}));

const Container = styled('div')(() => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
}));

const QRGeneratorForm = ({ onSubmit, onChange, qrCode, children }) => {
  const initialValues = mergeDefaults(qrCodeSchema.default(), qrCode.design);

  return (
    <Formik
      initialValues={initialValues}
      validateOnBlur={true}
      validateOnChange={true}
      validationSchema={qrCodeSchema}
      onSubmit={values => {
        return onSubmit(values);
      }}
    >
      {({ submitForm, isSubmitting, setFieldValue, values }) => (
        <Form>
          <FormikEffect onChange={onChange} dirtyCheck={false} validate />
          <Grid container spacing={3}>
            <Grid container item xs={12} spacing={2}>
              <Grid item xs={12}>
                <FieldWrapper label="QR Code Pattern">
                  <Container>
                    {shapes.map(({ displayName, value, img }) => (
                      <ImageButton
                        key={displayName}
                        active={values.qr_code_pattern === value}
                        onClick={() => setFieldValue('qr_code_pattern', value)}
                      >
                        <img alt={displayName} src={img} />
                      </ImageButton>
                    ))}
                  </Container>
                </FieldWrapper>
              </Grid>
              <Grid item xs={6}>
                <FieldWrapper label="Code Color">
                  <Field name="foreground_color" component={ColorSelector} />
                </FieldWrapper>
              </Grid>
              <Grid item xs={6}>
                <FieldWrapper label="Background Color">
                  <Field name="background_color" component={ColorSelector} />
                </FieldWrapper>
              </Grid>
            </Grid>
            <Grid container item xs={12} spacing={3}>
              <Grid item xs={6}>
                <FieldWrapper label="Edge Outer Color">
                  <Field name="marker_bottom_outer_color" component={ColorSelector} />
                </FieldWrapper>
              </Grid>
              <Grid item xs={6}>
                <FieldWrapper label="Edge Inner Color">
                  <Field name="marker_bottom_inner_color" component={ColorSelector} />
                </FieldWrapper>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={12}>
              <FieldWrapper label="Edge">
                <Field
                  component={TextField}
                  type="text"
                  name="marker_bottom_template"
                  label="Edge Version"
                  variant="outlined"
                  select
                  fullWidth
                >
                  {edges.map(({ displayName, value, img }) => (
                    <MenuItem key={value} value={value}>
                      <StyledImage src={img} />
                      {displayName}
                    </MenuItem>
                  ))}
                </Field>
              </FieldWrapper>
            </Grid>
            <Grid item xs={12} lg={12}>
              <FieldWrapper label="Logo">
                <Field
                  component={TextField}
                  type="text"
                  name="qr_code_logo"
                  label="Logo"
                  variant="outlined"
                  select
                  fullWidth
                >
                  {logos.map(({ displayName, value, img }) => (
                    <MenuItem key={value} value={value}>
                      <StyledImage src={img} />
                      {displayName}
                    </MenuItem>
                  ))}
                </Field>
              </FieldWrapper>
            </Grid>
          </Grid>
          {children({
            submitForm,
            isSubmitting,
          })}
        </Form>
      )}
    </Formik>
  );
};

QRGeneratorForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  qrCode: PropTypes.object.isRequired,
};

export default QRGeneratorForm;
