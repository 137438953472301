import React, { useEffect, useState } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import { Navigate, useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { RESEND_USER_VERIFICATION_MAIL, TOKENIZED_LOGIN } from 'graphql/mutations';
import { useMessages } from 'components/hooks';
import { Loader } from 'components/Forms';
import { AuthHeadline, AuthLayout, ResendVerification } from 'components/Auth';

const TokenizedLogin = () => {
  const navigate = useNavigate();
  const { setErrorMessage } = useMessages();
  const [loginCalled] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [token] = useQueryParam('token', StringParam);
  const [email] = useQueryParam('email', StringParam);

  const [login] = useMutation(TOKENIZED_LOGIN, {
    onError: error => {
      setError(error.message);
      setErrorMessage(error.message);
    },
    onCompleted: () => {
      setError(null);
      navigate('/');
    },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (token && !loginCalled) {
      setLoading(true);
      login({ variables: { token } }).then(() => setLoading(false));
    }
    // eslint-disable-next-line
  }, [token, loginCalled]);

  if (loading) return <Loader />;

  if (error) {
    return (
      <AuthLayout title="Register">
        <AuthHeadline text="Welcome to" highlighted="seedtrace" />
        <ResendVerification
          title="Verification link expired"
          subtitle="The link you used is expired. Use the button below to get a new one."
          email={email}
          mutation={RESEND_USER_VERIFICATION_MAIL}
        />
      </AuthLayout>
    );
  }

  return <Navigate to="/" />;
};

export default TokenizedLogin;
