import { Box, Divider, Grid } from '@mui/material';
import { Toggle } from 'components/Forms';
import { FieldWrapper, ThemeButton } from 'designSystem';
import { Field, Form, Formik } from 'formik';
import { Switch } from 'formik-mui';
import PropTypes from 'prop-types';
import React from 'react';
import * as Yup from 'yup';
import WidgetExampleIllustration from './WidgetExampleIllustration';

const WidgetSchema = Yup.object().shape({
  integrationSettings: Yup.object().shape({
    widgetType: Yup.string(),
    showClaims: Yup.bool(),
  }),
});

const WIDGET_TYPES = ['MAP', 'BUTTON'];

const WidgetPreferencesForm = ({ onSubmit, integrationSettings }) => {
  return (
    <Formik initialValues={integrationSettings} validationSchema={WidgetSchema} onSubmit={onSubmit}>
      {({ isSubmitting, values }) => (
        <Form>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="space-between" minHeight={110}>
                <FieldWrapper
                  label="Display format of product journey"
                  subtitle="Select a display format for the product journey"
                >
                  <Field component={Toggle} name="widgetType" options={WIDGET_TYPES} />
                </FieldWrapper>
                <Box display="flex" flexDirection="column" ml={4} justifyContent="center">
                  <WidgetExampleIllustration image={values?.widgetType} width={200} />
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Box mb={2} mt={4}>
            <Divider />
          </Box>
          <Grid container spacing={3}>
            <Grid item xs={8}>
              <FieldWrapper
                label="Display impact claims"
                subtitle="Select if you want to show the impact claims in addition to the product journey"
              >
                <Field component={Switch} type="checkbox" name="showClaims" />
              </FieldWrapper>
            </Grid>
            <Grid container item justifyContent="flex-end" alignItems="center">
              <Grid item>
                <ThemeButton color="YELLOW" size="large" loading={isSubmitting} type="submit">
                  Save
                </ThemeButton>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

WidgetPreferencesForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  integrationSettings: PropTypes.shape({
    widgetType: PropTypes.oneOf(['MAP' | 'BUTTON']),
    showClaims: PropTypes.bool,
  }).isRequired,
};

export default WidgetPreferencesForm;
