import { styled } from '@mui/material';
import React, { FC } from 'react';
import { FlexBox } from 'components/Structure';
import {
  EUDRAffectednessTypeColor,
  EUDRAffectednessTypeLabel,
} from '../utils/eudrCompliance.utils';
import { EudrAffectednessType } from 'types/compliance.types';
import { ThemeTypography } from 'designSystem';

export const Container = styled(FlexBox)(({ theme }) => ({
  borderRadius: 8,
  padding: theme.spacing(0, 2),
}));

interface IAffectivenessIndicatorProps {
  affectednessType: EudrAffectednessType;
}

const AffectivenessIndicator: FC<IAffectivenessIndicatorProps> = ({ affectednessType }) => {
  return (
    <Container bgcolor={EUDRAffectednessTypeColor[affectednessType]}>
      <ThemeTypography variant="BODY_MEDIUM_BOLD">
        {EUDRAffectednessTypeLabel[affectednessType]}
      </ThemeTypography>
    </Container>
  );
};

export default AffectivenessIndicator;
