import { Tooltip, Typography, TypographyProps, useTheme } from '@mui/material';
import useIsOverflow from 'components/hooks/useIsOverflow';
import {
  ThemeTypographyColor,
  getThemeTypographyColor,
} from 'designSystem/utils/designSystemUtils';
import React, { MutableRefObject, useRef } from 'react';

const titleFontFamily = ['Montserrat', 'Lato', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(',');
const baseFontFamily = ['Lato', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(',');

export enum ThemeTypographyVariant {
  TITLE_LARGE = 'TITLE_LARGE',
  TITLE_MEDIUM = 'TITLE_MEDIUM',
  TITLE_SMALL = 'TITLE_SMALL',
  TITLE_EXTRA_SMALL = 'TITLE_EXTRA_SMALL',
  BODY_LARGER_BOLD = 'BODY_LARGER_BOLD',
  BODY_LARGE = 'BODY_LARGE',
  BODY_LARGE_BOLD = 'BODY_LARGE_BOLD',
  BODY_MEDIUM = 'BODY_MEDIUM',
  BODY_MEDIUM_BOLD = 'BODY_MEDIUM_BOLD',
  BODY_SMALL = 'BODY_SMALL',
  BODY_SMALL_BOLD = 'BODY_SMALL_BOLD',
  BODY_SMALLER = 'BODY_SMALLER',
  BODY_SMALLER_BOLD = 'BODY_SMALLER_BOLD',
  BUTTON_LARGE = 'BUTTON_LARGE',
  BUTTON_SMALL = 'BUTTON_SMALL',
  ITEM_TITLE = 'ITEM_TITLE',
  ITEM_SUBTITLE = 'ITEM_SUBTITLE',
  LABEL_INPUT = 'LABEL_INPUT',
}

export const TypographyTypes = {
  [ThemeTypographyVariant.TITLE_LARGE]: {
    fontSize: 34,
    fontWeight: 700,
    fontFamily: titleFontFamily,
  },
  [ThemeTypographyVariant.TITLE_MEDIUM]: {
    fontSize: 28,
    fontWeight: 700,
    fontFamily: baseFontFamily,
  },
  [ThemeTypographyVariant.TITLE_SMALL]: {
    fontSize: 20,
    fontWeight: 700,
    fontFamily: baseFontFamily,
  },
  [ThemeTypographyVariant.TITLE_EXTRA_SMALL]: {
    fontSize: 16,
    fontWeight: 300,
    fontFamily: titleFontFamily,
  },
  [ThemeTypographyVariant.BODY_LARGER_BOLD]: {
    fontSize: 16,
    fontWeight: 700,
    fontFamily: baseFontFamily,
  },
  [ThemeTypographyVariant.BODY_LARGE]: {
    fontSize: 14,
    fontWeight: 400,
    fontFamily: baseFontFamily,
  },
  [ThemeTypographyVariant.BODY_LARGE_BOLD]: {
    fontSize: 14,
    fontWeight: 700,
    fontFamily: baseFontFamily,
  },
  [ThemeTypographyVariant.BODY_MEDIUM]: {
    fontSize: 12,
    fontWeight: 400,
    fontFamily: baseFontFamily,
  },
  [ThemeTypographyVariant.BODY_MEDIUM_BOLD]: {
    fontSize: 12,
    fontWeight: 700,
    fontFamily: baseFontFamily,
  },
  [ThemeTypographyVariant.BODY_SMALL]: {
    fontSize: 11,
    fontWeight: 400,
    fontFamily: baseFontFamily,
  },
  [ThemeTypographyVariant.BODY_SMALL_BOLD]: {
    fontSize: 11,
    fontWeight: 700,
    fontFamily: baseFontFamily,
  },
  [ThemeTypographyVariant.BODY_SMALLER]: {
    fontSize: 10,
    fontWeight: 400,
    fontFamily: baseFontFamily,
  },
  [ThemeTypographyVariant.BODY_SMALLER_BOLD]: {
    fontSize: 10,
    fontWeight: 700,
    fontFamily: baseFontFamily,
  },
  [ThemeTypographyVariant.BUTTON_LARGE]: {
    fontSize: 14,
    fontWeight: 700,
    fontFamily: baseFontFamily,
  },
  [ThemeTypographyVariant.BUTTON_SMALL]: {
    fontSize: 11,
    fontWeight: 700,
    fontFamily: baseFontFamily,
  },
  [ThemeTypographyVariant.ITEM_TITLE]: {
    fontSize: 16,
    fontWeight: 900,
    fontFamily: baseFontFamily,
  },
  [ThemeTypographyVariant.ITEM_SUBTITLE]: {
    fontSize: 13,
    fontWeight: 400,
    fontFamily: baseFontFamily,
  },
  [ThemeTypographyVariant.LABEL_INPUT]: {
    fontSize: 12,
    fontWeight: 700,
    fontFamily: baseFontFamily,
  },
};

interface Props extends Omit<TypographyProps, 'variant' | 'color'> {
  children?: React.ReactNode;
  variant: keyof typeof ThemeTypographyVariant;
  color?: ThemeTypographyColor;
  autoOverflow?: boolean;
  maxWidth?: number | string;
}

const ThemeTypography: React.FC<Props> = ({
  children,
  variant,
  color,
  autoOverflow,
  maxWidth,
  ...props
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const typographyRef: MutableRefObject<HTMLDivElement | null> | undefined = autoOverflow
    ? ref
    : undefined;

  const hasOverflow = useIsOverflow(typographyRef);
  const { fontSize, fontFamily, fontWeight } = TypographyTypes[variant];
  const theme = useTheme();
  const textColor = getThemeTypographyColor(theme, color);

  const typography = (
    <Typography
      ref={typographyRef}
      style={{
        fontSize,
        fontFamily,
        fontWeight,
        color: textColor,
        maxWidth,
        ...(autoOverflow
          ? { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }
          : {}),
      }}
      {...props}
    >
      {children}
    </Typography>
  );

  if (autoOverflow) {
    return <Tooltip title={hasOverflow ? children : undefined}>{typography}</Tooltip>;
  }

  return typography;
};

export default ThemeTypography;
