import React from 'react';

const Pin = ({ stroke }) => (
  <svg width="18" height="24" viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.7333 9.66669C16.7333 12.6 15.1999 15.8667 13.3999 18.4C12.5333 19.6667 11.5999 20.7334 10.7333 21.5334C10.3333 21.9334 9.93327 22.2 9.6666 22.4C9.33327 22.6 9.13327 22.6667 8.99993 22.6667C8.8666 22.6667 8.6666 22.6 8.33327 22.4C7.99993 22.2 7.6666 21.9334 7.2666 21.5334C6.4666 20.8 5.53327 19.6667 4.59993 18.4C2.79993 15.8667 1.2666 12.6 1.2666 9.66669C1.2666 6.73336 2.13327 4.66669 3.53327 3.33336C4.93327 2.00002 6.8666 1.40002 8.99993 1.40002C11.1333 1.40002 13.0666 2.00002 14.4666 3.33336C15.8666 4.60002 16.7333 6.66669 16.7333 9.66669Z"
      stroke={stroke}
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.93319 11.6667C7.06653 11.1334 6.59986 10.4667 6.46653 9.80003C6.26653 9.13337 6.39986 8.40003 6.73319 7.8667C7.06653 7.2667 7.53319 6.8667 8.1332 6.60003C8.7332 6.40003 9.39986 6.40003 10.1332 6.8667C11.5332 7.73337 11.8665 9.2667 11.3332 10.5334C11.0665 11.1334 10.6665 11.6 10.0665 11.8667C9.46653 12 8.73319 12 7.93319 11.6667Z"
      stroke={stroke}
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Pin;
