import { styled, Box, CircularProgress } from '@mui/material';
import FadeMount from 'components/Forms/FadeMount';
import ThemeTypography from 'designSystem/Primitives/Typography/ThemeTypography';
import React, { FC } from 'react';
import { Booleanish, booleanish } from 'types/booleanish.types';

interface ILoaderProps {
  relativeHeightToScreen?: boolean;
  label?: string;
  size?: number;
  spinner?: boolean;
  overlay?: boolean;
  overlayShimmer?: boolean;
  overlayOpacity?: boolean;
}

const LoadingOpacity = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: theme.custom.colors.backgroundLight,
  opacity: 0.7,
  zIndex: 10,
}));

const Container = styled('div')<{ 'relative-height-to-screen': booleanish }>(
  ({ 'relative-height-to-screen': relativeHeightToScreen }) => ({
    position: 'absolute',
    top: relativeHeightToScreen === 'true' ? '50vh' : '50%',
    left: '50%',
    transform: 'translate3d(-50%, -50%, 0)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    zIndex: 1000000000,
  })
);

const OverlayInner = styled('div')<{ solid: booleanish }>(({ theme, solid }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  background: solid === 'true' ? theme.custom.colors.offWhite : '#000',
  opacity: solid ? '1' : '0.2 !important',
  zIndex: 1000000000,
}));

const Loader: FC<ILoaderProps> = ({
  relativeHeightToScreen,
  label,
  size,
  overlay,
  overlayShimmer,
  spinner = true,
  overlayOpacity,
}) => {
  const content = (
    <>
      {overlay && (
        <FadeMount>
          <OverlayInner solid={Booleanish(!overlayShimmer)}>
            {overlayShimmer && <div className="shimmer" />}
          </OverlayInner>
        </FadeMount>
      )}
      {spinner && (
        <Container relative-height-to-screen={Booleanish(relativeHeightToScreen)}>
          <CircularProgress size={size} />
          {label && <ThemeTypography variant="BODY_LARGE">{label}</ThemeTypography>}
        </Container>
      )}
    </>
  );

  return overlayOpacity ? <LoadingOpacity>{content}</LoadingOpacity> : content;
};

export default Loader;
