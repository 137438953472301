import { Box } from '@mui/material';
import EudrComplianceGuideSubSection from 'components/ComplianceGuide/EudrComplianceGuideSubSection';
import { useEudrComplianceGuide } from 'components/ComplianceGuide/EudrComplianceGuideContext';
import PartnersSelection from 'components/ComplianceGuide/components/PartnerSelection';
import { Loader } from 'components/Forms';
import usePartners from 'components/Partners/hooks/usePartners';
import { ErrorState } from 'components/Structure';
import React, { FC, useState } from 'react';
import { EudrStatementStatus } from 'types/compliance.types';

const PartnerSelection: FC = () => {
  const { selectedStatementId, updateStatement } = useEudrComplianceGuide();
  const { partners, loading, error } = usePartners();
  const [selectedPartners, setSelectedPartners] = useState<string[]>([]);

  const handleNextStepClick = () => {
    if (selectedStatementId) {
      // This will automatically navigate to the next section
      updateStatement(selectedStatementId, {
        involvedPartnerCompaniesIds: selectedPartners,
        status: EudrStatementStatus.COLLECTING_GEO_DATA,
      });
    }
  };

  if (!partners && loading) return <Loader />;
  if (error) return <ErrorState action={undefined} />;

  return (
    <EudrComplianceGuideSubSection
      allowNextStepNavigation={selectedPartners.length > 0}
      disabledPreviousNavigation={false}
      onNextStepClick={handleNextStepClick}
    >
      <Box display="flex" gap={2} flexWrap="wrap">
        <PartnersSelection
          partners={partners}
          selectedPartnerIds={selectedPartners}
          onSelect={setSelectedPartners}
        />
      </Box>
    </EudrComplianceGuideSubSection>
  );
};

export default PartnerSelection;
