import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { HexColorInput } from 'react-colorful';

const StyledHexColorInput = styled(HexColorInput)(({ theme }) => ({
  padding: 11,
  fontSize: theme.typography.htmlFontSize,
  color: theme.palette.text.secondary,
  border: `1px solid ${theme.palette.grey[400]}`,
  borderRadius: theme.spacing(0.5),
  width: '100%',

  '&:focus': {
    border: `1px solid ${theme.palette.primary.main}`,
    outline: 'none',
    borderRadius: theme.spacing(0.5),
  },
}));

const StyledTypography = styled('div')(({ theme }) => ({
  fontSize: theme.typography.h6.fontSize,
  color: theme.palette.text.secondary,
  marginRight: theme.spacing(1),
}));

const HexColorInputContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',
}));

const HexColorInputField = ({ color, handleChange, className }) => {
  return (
    <HexColorInputContainer className={className}>
      <StyledTypography>#</StyledTypography>
      <StyledHexColorInput color={color} onChange={handleChange} />
    </HexColorInputContainer>
  );
};

HexColorInputField.propTypes = {
  color: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default HexColorInputField;
