import { useLogEvent } from 'components/hooks';
import React, { FC, PropsWithChildren, createContext, useContext, useState } from 'react';
import { useBlocker } from 'react-router-dom';

interface ITrackNavigationEventsValues {
  previousPathname: string | undefined;
}

const TrackNavigationEventsContext = createContext<ITrackNavigationEventsValues>({
  previousPathname: undefined,
});

const TrackNavigationEventsProvider: FC<PropsWithChildren> = ({ children }) => {
  const { logEvent } = useLogEvent();

  const [previousPathname, setPreviousPathname] = useState<string | undefined>();

  // Workaround to track navigation events in Amplitude with from and to locations
  useBlocker(({ currentLocation, nextLocation }) => {
    try {
      // To have a generic path for all ids we replace our uuids with :id
      const from = currentLocation.pathname.replaceAll(
        new RegExp(/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/g),
        ':id'
      );
      setPreviousPathname(from);
      const to = nextLocation.pathname.replaceAll(
        new RegExp(/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/g),
        ':id'
      );
      logEvent('NAVIGATION', { from, to });
    } catch {
    } finally {
      return false;
    }
  });

  return (
    <TrackNavigationEventsContext.Provider value={{ previousPathname }}>
      {children}
    </TrackNavigationEventsContext.Provider>
  );
};

const usePreviousPathname = () => {
  const { previousPathname } = useContext(TrackNavigationEventsContext);
  return previousPathname;
};

export { TrackNavigationEventsProvider, usePreviousPathname };

export default TrackNavigationEventsContext;
