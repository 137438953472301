import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Backdrop, Card, DialogTitle, DialogContent } from '@mui/material';

const Container = styled(Card)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate3d(-50%, -50%, 0)',
  textAlign: 'center',
  padding: theme.spacing(4),
}));

const StyledBackdrop = styled(Backdrop)(() => ({
  position: 'absolute',
  zIndex: 2,
}));

const StyledContent = styled(DialogContent)(({ theme }) => ({
  paddingBottom: theme.spacing(2),
}));

const FeatureOverlay = ({ className, title, content }) => {
  return (
    <StyledBackdrop className={className} open>
      <Container>
        <DialogTitle>{title}</DialogTitle>
        <StyledContent dangerouslySetInnerHTML={{ __html: content }} />
      </Container>
    </StyledBackdrop>
  );
};

FeatureOverlay.defaultProps = {
  title: 'This feature is not yet part of your plan!',
  content:
    'If you would like to enable this feature, <br /> write us a message in the chat or shoot an email to contact@seedtrace.org',
};

FeatureOverlay.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.string,
};

export default FeatureOverlay;
