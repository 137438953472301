import { Grid } from '@mui/material';
import { PageContainer, PageTitle } from 'components/Structure';
import { useAuthorization, usePageTitle, useUserData } from 'components/hooks';
import { useCompanyPlanContext } from 'contexts/CompanyPlanContext';
import React, { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { CustomerPlan } from 'types/company.types';
import SettingsSidebar from './SettingsSidebar';

const Layout: FC = () => {
  usePageTitle('Settings');
  const { isAdmin } = useAuthorization();
  const { company } = useUserData();
  const { plan } = useCompanyPlanContext();
  const isFreemium = plan === CustomerPlan.FREEMIUM;

  const menu = [
    {
      label: 'Profile',
      to: '/settings',
      active: true,
    },
    {
      label: 'Security',
      to: '/settings/security',
      active: true,
    },
    {
      label: 'Notifications',
      to: '/settings/notifications',
      active: !isFreemium,
    },
    {
      label: 'Company',
      to: '/settings/company',
      displayWarning: company && !company.dataValid,
      active: isAdmin,
    },
    {
      label: 'Users',
      to: '/settings/users',
      active: isAdmin,
    },
    {
      label: 'Integrations',
      to: '/settings/integrations',
      active: !isFreemium && isAdmin,
    },
    {
      label: 'Branding',
      to: '/settings/branding',
      active: !isFreemium && isAdmin,
    },
    {
      label: 'Google Analytics',
      to: '/settings/google-analytics',
      active: isAdmin && plan === CustomerPlan.PAYING,
    },
  ];

  return (
    <PageContainer>
      <PageTitle title="Settings" />
      <Grid container direction="row" mt={2} spacing={2}>
        <Grid item xs={2}>
          <SettingsSidebar menu={menu} />
        </Grid>
        <Grid item xs={10}>
          <Outlet />
        </Grid>
      </Grid>
    </PageContainer>
  );
};

export default Layout;
