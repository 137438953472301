import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import {
  ApolloClient,
  NormalizedCacheObject,
  ApolloProvider as OriginalApolloProvider,
} from '@apollo/client';
import { useConfig } from 'components/hooks';
import { Loader } from 'components/Forms';
import Firebase from 'contexts/Firebase';
import initializeApolloClient from './apolloClient';

interface IApolloProviderProps extends PropsWithChildren {
  firebase: Firebase;
  graphqlEndpoint?: string;
}

const ApolloProvider: FC<IApolloProviderProps> = ({ children, firebase, graphqlEndpoint }) => {
  const [apolloClient, setApolloClient] = useState<ApolloClient<NormalizedCacheObject>>();
  const config = useConfig();
  const hasClient = !!apolloClient;

  useEffect(() => {
    if (hasClient || !firebase) return;

    async function init() {
      // wait for store to by hydrated from localStorage
      const client = await initializeApolloClient({
        firebase,
        config,
        uri: graphqlEndpoint,
      });
      setApolloClient(client);
    }

    init();
  }, [hasClient]); // eslint-disable-line

  if (!apolloClient) return <Loader />;

  return <OriginalApolloProvider client={apolloClient}>{children}</OriginalApolloProvider>;
};

export default ApolloProvider;
