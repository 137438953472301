import { DocumentCategories } from 'types/document.types';

const dateValidity = {
  [`${DocumentCategories.CERTIFICATE}`]: {
    requireDate: true,
    yearsFromIssuedDate: undefined,
  },
  [`${DocumentCategories.MANAGEMENT_LETTER}`]: {
    requireDate: true,
    yearsFromIssuedDate: 2,
  },
  [`${DocumentCategories.SHAREHOLDER_AGREEMENT}`]: {
    requireDate: true,
    yearsFromIssuedDate: 2,
  },
  [`${DocumentCategories.LABORATORY_TEST}`]: {
    requireDate: true,
    yearsFromIssuedDate: undefined,
  },
  [`${DocumentCategories.REPORT}`]: {
    requireDate: true,
    yearsFromIssuedDate: 1,
  },
  [`${DocumentCategories.STATEMENT}`]: {
    requireDate: false,
    yearsFromIssuedDate: undefined,
  },
  [`${DocumentCategories.OTHER}`]: {
    requireDate: false,
    yearsFromIssuedDate: undefined,
  },
};

export default dateValidity;
