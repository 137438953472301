import React, { useEffect, createContext, useContext, FC, PropsWithChildren } from 'react';
import { useUserData } from 'components/hooks';
import FirebaseContext from './FirebaseContext';
import * as Sentry from '@sentry/react';

const SentryContext = createContext<boolean | null>(null);

const SentryProvider: FC<PropsWithChildren> = ({ children }) => {
  const { user, company } = useUserData();
  const { authenticated } = useContext(FirebaseContext);

  useEffect(() => {
    if (authenticated && user && company) {
      const { id, fullName, email } = user;
      Sentry.setUser(process.env.NODE_ENV === 'production' ? { id } : { id, fullName, email });
    }
  }, [user, company, authenticated]);

  return <SentryContext.Provider value={true}>{children}</SentryContext.Provider>;
};

export { SentryProvider };

export default SentryContext;
