import { Skeleton as MuiSkeleton, SkeletonProps } from '@mui/lab';
import React, { FC } from 'react';
import { styled } from '@mui/material/styles';

const StyledSkeleton = styled(MuiSkeleton)<{ 'background-color'?: string }>(
  ({ theme, 'background-color': backgroundColor }) => ({
    backgroundColor: !!backgroundColor ? backgroundColor : theme.custom.colors.backgroundLight,
  })
);

interface ISkeletonProps extends SkeletonProps {
  backgroundColor?: string;
}

const Skeleton: FC<ISkeletonProps> = ({ backgroundColor, ...rest }) => {
  return <StyledSkeleton background-color={backgroundColor} {...rest} />;
};

export default Skeleton;
