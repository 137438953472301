import React from 'react';

const Bubble = ({ stroke }) => (
  <svg width="18" height="24" viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.4 1.66669H2.6C1.73333 1.66669 1 2.40002 1 3.26669V14.8667C1 15.7334 1.73333 16.4667 2.6 16.4667H5.26667L9 23L12.7333 16.4667H15.4C16.2667 16.4667 17 15.7334 17 14.8667V3.26669C17 2.40002 16.2667 1.66669 15.4 1.66669Z"
      stroke={stroke}
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.66675 6.06671H14.3334"
      stroke={stroke}
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.66675 9.06671H14.3334"
      stroke={stroke}
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.66675 12.0667H14.3334"
      stroke={stroke}
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Bubble;
