import { DialogTitle, DialogContent } from '@mui/material';
import { ThemeTypography } from 'designSystem';
import React, { Fragment } from 'react';

const PublishDialogError: React.FC = () => {
  return (
    <Fragment>
      <DialogTitle>Error</DialogTitle>
      <DialogContent>
        <ThemeTypography variant="BODY_LARGE">
          There was an error publishing your product. Please contact our support to resolve the
          problem.
        </ThemeTypography>
      </DialogContent>
    </Fragment>
  );
};

export default PublishDialogError;
