import { Box, styled } from '@mui/material';

/**
 * Styled Box which already uses flex styles and centers the content
 */
const FlexBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

export default FlexBox;
