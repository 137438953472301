import { useQuery } from '@apollo/client';
import { GET_BASE_PARTNER_COMPANY } from 'graphql/queries/partner.queries';
import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { IBasePartnerCompany } from 'types/partner.types';
import { RouteParamsWithId } from 'types/router.types';

/**
 * Returns the title of the partner, that is fetched from the cache only
 */
const PartnerName: FC = () => {
  const { id } = useParams<RouteParamsWithId>();

  const { data, loading } = useQuery<{
    partner: IBasePartnerCompany;
  }>(GET_BASE_PARTNER_COMPANY, {
    variables: {
      id,
    },
    skip: !id,
    fetchPolicy: 'network-only',
  });

  if (loading) return <span>Loading...</span>;

  return <span>{data?.partner?.name || ''}</span>;
};

export default PartnerName;
