import { styled } from '@mui/material/styles';
import React from 'react';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const DragContainerBox = styled(({ dragging, ...props }) => <div {...props} />)(
  ({ theme, dragging }) => ({
    margin: '0 auto',
    background: dragging ? theme.palette.grey[100] : 'transparent',
    transition: 'background 0.125s ease-in-out',

    '& .smooth-dnd-draggable-wrapper': {
      overflow: 'visible !important',
    },
  })
);

export const DragHandle = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 24,
  height: '100%',

  '&:hover': {
    cursor: 'grab',
  },
}));

export const DragItemContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
}));

export const DragIcon = styled('img')(() => ({
  width: 8,
  pointerEvents: 'none',
}));
