import { configInstance } from 'config';

const impactClaimPolicies = {
  ImpactClaim: {
    fields: {
      isPending: (value, { readField }) => {
        const pending = ['CREATED', 'UPDATED', 'REQUESTED', 'DOCUMENT_MISSING'];
        return pending.includes(readField('status'));
      },
      isApproved: (value, { readField }) => {
        return readField('status') === 'APPROVED';
      },
      isInvalid: (value, { readField }) => {
        return readField('status') === 'DENIED';
      },
      impactLevel: (value, { readField }) => {
        if (readField('product')) {
          return 'PRODUCT';
        }

        if (readField('partner')) {
          return 'PARTNER';
        }

        if (readField('component')) {
          return 'COMPONENT';
        }

        return 'BUSINESS';
      },
      impactLevelTitle: (value, { readField }) => {
        const product = readField('product');
        if (product) {
          return readField('title', product);
        }

        const partner = readField('partner');
        if (partner) {
          return readField('title', partner);
        }

        const component = readField('component');
        if (component) {
          return readField('title', component);
        }

        return null;
      },
    },
  },
  CmsImpactCatalogItem: {
    fields: {
      categoryConfig: (value, { readField }) => {
        const category = readField('category');
        return (
          configInstance.claimCategories[category?.type] ||
          configInstance.claimCategories.ECOLOGICAL
        );
      },
    },
  },
};

export default impactClaimPolicies;
