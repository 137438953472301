import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { Container } from './styles';
import { TextField } from 'formik-mui';
import { Grid } from '@mui/material';
import { MediaField } from 'components/Forms';
import FormContainer from './FormContainer';
import { useContentBlock } from 'components/Product/MultiStep/hooks';
import Header from './Header';
import HTMLEditorField from 'designSystem/Formik/HTMLEditorField/HTMLEditorField';
import { FieldWrapper } from 'designSystem';

const VerifiedPayments = ({ menu }) => {
  const [{ data, enabled, id }, schema] = useContentBlock(menu.id);

  return (
    <Container>
      <FormContainer
        initialValues={{
          enabled,
          ...data,
        }}
        schema={schema}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Header
              title={menu.title}
              helperText={menu.description}
              showEnabled
              contentBlockId={id}
            />
          </Grid>
          <Grid item xs={12}>
            <FieldWrapper label="Image">
              <Field
                component={MediaField}
                fullWidth
                name="image"
                legacyImage={false}
                allowedMedia={['image']}
                styles={{
                  width: 280,
                  height: 280,
                }}
              />
            </FieldWrapper>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FieldWrapper label="Headline">
                  <Field component={TextField} fullWidth name="headline" variant="outlined" />
                </FieldWrapper>
              </Grid>
              <Grid item xs={12}>
                <FieldWrapper label="Text">
                  <Field component={HTMLEditorField} name="description" />
                </FieldWrapper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </FormContainer>
    </Container>
  );
};

VerifiedPayments.propTypes = {
  menu: PropTypes.object.isRequired,
};

export default VerifiedPayments;
