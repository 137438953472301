import { gql } from '@apollo/client';
import {
  EUDR_STATEMENT,
  EUDR_DATASET_FRAGMENT,
  EUDR_DATASET_MAPPING,
} from 'graphql/fragments/compliance.fragments';

export const CREATE_STATEMENT = gql`
  mutation CreateStatement($input: CreateStatementInput!) {
    createStatement(input: $input) {
      statement {
        ...eudrStatementValues
      }
    }
  }
  ${EUDR_STATEMENT}
`;

export const UPDATE_STATEMENT = gql`
  mutation UpdateStatement($id: UUID!, $input: UpdateStatementInput!) {
    updateStatement(id: $id, input: $input) {
      statement {
        ...eudrStatementValues
      }
    }
  }
  ${EUDR_STATEMENT}
`;

export const DELETE_STATEMENTS = gql`
  mutation DeleteStatements($ids: [UUID]!) {
    deleteStatements(ids: $ids) {
      ids
    }
  }
`;

export const CREATE_EUDR_DATASET = gql`
  mutation CreateEUDRDataset($input: CreateEUDRDatasetInput!) {
    createEUDRDataset(input: $input) {
      eudrDataset {
        ...eudrDatasetValues
      }
    }
  }
  ${EUDR_DATASET_FRAGMENT}
`;

export const UPDATE_EUDR_DATASET = gql`
  mutation UpdateEUDRDataset($id: UUID!, $input: UpdateEUDRDatasetInput!) {
    updateEUDRDataset(id: $id, input: $input) {
      eudrDataset {
        ...eudrDatasetValues
      }
    }
  }
  ${EUDR_DATASET_FRAGMENT}
`;

export const DELETE_EUDR_DATASET = gql`
  mutation DeleteEUDRDataset($id: UUID!) {
    deleteEUDRDataset(id: $id) {
      id
    }
  }
`;

export const PROCESS_EUDR_DATASETS = gql`
  mutation ProcessEUDRDatasets($inputs: [ProcessEUDRDatasetInput]!) {
    processEUDRDatasets(inputs: $inputs) {
      datasetMappings {
        ...eudrDatasetMappingValues
      }
      eudrDatasets {
        ...eudrDatasetValues
      }
      datasetProcessingErrors {
        datasetId
        datasetErrors {
          entityId
          entityType
          errorMessage
        }
      }
    }
  }
  ${EUDR_DATASET_FRAGMENT}
  ${EUDR_DATASET_MAPPING}
`;

export const SUBMIT_EUDR_RISK_ANALYSIS = gql`
  mutation SubmitEUDRRiskAnalysis($input: SubmitEUDRRiskAnalysisInput!) {
    submitEUDRRiskAnalysis(input: $input) {
      message
    }
  }
`;
