import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ThemeTypography } from 'designSystem';
import PropTypes from 'prop-types';
import React from 'react';

const Container = styled(Box)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.grey[300]}`,

  '&:last-of-type': {
    borderColor: 'transparent',
  },
}));

const Change = styled('div')(({ theme }) => ({
  fontSize: 12,
  fontWeight: 700,
  color: theme.palette.grey[500],
}));

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Percentage = styled(({ isIncrease, ...props }) => <span {...props} />)(
  ({ theme, isIncrease }) => ({
    fontWeight: 700,
    color: isIncrease ? theme.palette.success.main : theme.palette.warning.main,
  })
);

const MetricNumber = ({ total, change, label, comparedLabel, unit, ...rest }) => {
  const roundedChange = change && Math.round(change);
  const isIncrease = change >= 0;
  const hasChange = change !== undefined;

  return (
    <Container textAlign="center" p={2} {...rest}>
      <ThemeTypography variant="TITLE_LARGE" data-cy="analytics-metric-number" unit={unit}>
        {total || '0'}
      </ThemeTypography>
      <Box>
        <ThemeTypography variant="BODY_LARGE" color="GRAY">
          {label}
        </ThemeTypography>
        {hasChange && (
          <Change>
            <Percentage isIncrease={isIncrease}>
              {isIncrease && '+'}
              {`${roundedChange}% `}
            </Percentage>
            {comparedLabel}
          </Change>
        )}
      </Box>
    </Container>
  );
};

MetricNumber.defaultProps = {
  comparedLabel: 'vs. last week',
  unit: '',
};

MetricNumber.propTypes = {
  total: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  change: PropTypes.number,
  label: PropTypes.string,
  comparedLabel: PropTypes.string,
  unit: PropTypes.string,
  // And other Container props
};

export default MetricNumber;
