import { Box, TablePagination } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { styled } from '@mui/styles';
import { SortableTableHead, StyledTable } from 'components/TableBase';
import React, { FC, MouseEvent, useEffect, useState, useMemo } from 'react';
import { IDocument } from 'types/document.types';
import DocumentTableBody, { DocumentTableBodyAction } from './DocumentTableBody';
import {
  DocumentsTableColumnKeys,
  documentsHeaderColumns,
} from './constants/documentHeaderColumns';

export interface DocumentTableProps {
  columns: DocumentsTableColumnKeys[];
  documents: IDocument[];
  selectedDocumentIds?: string[];
  disableSelection?: boolean;
  actions?: DocumentTableBodyAction[];
  showPagination?: boolean;
  multiselect?: boolean;
  onSelectionChange?: (ids: string[]) => void;
  onDocumentClick?: (document: IDocument) => void;
}

const PaginationContainer = styled('div')<Theme>(({ theme }) => ({
  position: 'absolute',
  right: -10,
  top: 22,

  '& .MuiTablePagination-actions': {
    marginLeft: 0,

    '& svg': {
      color: theme.palette.grey[800],
    },
  },

  '& .MuiTablePagination-caption': {
    color: theme.custom.colors.almostBlack,
  },
}));

const DocumentTable: FC<DocumentTableProps> = ({
  columns,
  documents,
  actions,
  disableSelection,
  showPagination = false,
  selectedDocumentIds = [],
  multiselect = true,
  onSelectionChange,
  onDocumentClick,
}) => {
  const rowsPerPage = 5;
  const [currentPage, setCurrentPage] = useState<number>(0);

  const shownColumns = useMemo(
    () => documentsHeaderColumns.filter(({ id }) => columns.includes(id)),
    [columns]
  );

  const handleSelect = (documentId: string) => {
    if (!multiselect) {
      onSelectionChange?.([documentId]);
      return;
    }

    const isItemSelected = selectedDocumentIds.includes(documentId);
    if (!isItemSelected) {
      onSelectionChange?.([...selectedDocumentIds, documentId]);
    } else {
      const updatedSelection = selectedDocumentIds.filter(document => document !== documentId);
      onSelectionChange?.(updatedSelection);
    }
  };

  const handleSelectAll = (select: boolean) => {
    if (select) {
      const newSelected = documents.map(document => document.id);
      onSelectionChange?.(newSelected);

      return;
    }
    onSelectionChange?.([]);
  };

  const handleChangePage = (event: MouseEvent<HTMLButtonElement> | null, page: number) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    setCurrentPage(0);
  }, [documents]);

  return (
    <Box position="relative">
      {showPagination && (
        <PaginationContainer>
          <TablePagination
            component="div"
            rowsPerPageOptions={[]}
            count={documents.length}
            rowsPerPage={rowsPerPage}
            page={currentPage}
            onPageChange={handleChangePage}
          />
        </PaginationContainer>
      )}
      <StyledTable aria-labelledby="tableTitle" aria-label="enhanced table">
        <SortableTableHead
          headerColumns={shownColumns}
          multiselect={!disableSelection && multiselect}
          onSelectAll={handleSelectAll}
        />
        <DocumentTableBody
          data-cy="document-table"
          showPagination={showPagination}
          rowsPerPage={rowsPerPage}
          currentPage={currentPage}
          documents={documents}
          selectedIds={selectedDocumentIds}
          actions={actions}
          disableSelection={disableSelection}
          headerColumns={shownColumns}
          onSelect={handleSelect}
          handleClickDocument={onDocumentClick}
        />
      </StyledTable>
    </Box>
  );
};

export default DocumentTable;
