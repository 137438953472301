import React, { FC, ReactNode } from 'react';
import { Box, styled } from '@mui/material';
import { Plus } from '@styled-icons/bootstrap/Plus';
import { ItemSkeleton, CardContainer, CardIllustration } from 'designSystem';
import ThemeButton from 'designSystem/Buttons/ThemeButton/ThemeButton';
import SparkImage from 'assets/img/spark.png';
import { BackgroundTypes, ItemTypes, AvailableSizes, IllustrationTypes } from 'types/enums';

const Spark = styled('img')(() => ({
  position: 'absolute',
  width: 60,
  height: 60,
  right: -32,
  top: -27,
}));

interface IEmptyStateCardProps {
  buttonText: string;
  secondaryButtonText?: string;
  variant?: ItemTypes;
  title: string;
  size?: AvailableSizes;
  background?: BackgroundTypes;
  renderIllustration?: ReactNode;
  renderForeground?: ReactNode;
  buttonIcon?: ReactNode;
  /** @default true  */
  showSpark?: boolean;
  'data-cy'?: string;
  'data-cy-button'?: string;
  onCreateItemClick?: () => void;
  onClickSecondaryButton?: () => void;
}

const EmptyStateCard: FC<IEmptyStateCardProps> = ({
  title,
  size = AvailableSizes.MEDIUM,
  background,
  variant,
  buttonText,
  renderForeground,
  renderIllustration,
  secondaryButtonText,
  buttonIcon,
  showSpark = true,
  'data-cy': dataCy = '',
  'data-cy-button': dataCyButton = '',
  onCreateItemClick,
  onClickSecondaryButton,
}) => {
  return (
    <CardContainer
      title={title}
      size={size}
      background={background}
      renderIllustration={renderIllustration}
      data-cy={dataCy}
    >
      <Box pb={2} mt={2} position="relative" maxWidth="550px">
        {!!variant ? (
          variant === ItemTypes.MAP_LAYERS || variant === ItemTypes.CHAIN_MAPPING ? (
            <CardIllustration image={IllustrationTypes[variant]} />
          ) : (
            <ItemSkeleton variant={variant} />
          )
        ) : null}
        {showSpark && <Spark src={SparkImage} />}
        {!!renderForeground && renderForeground}
      </Box>
      <Box display="flex">
        {onCreateItemClick && (
          <ThemeButton
            data-cy={dataCyButton}
            onClick={onCreateItemClick}
            startIcon={buttonIcon ?? <Plus size={20} />}
            size="large"
          >
            {buttonText}
          </ThemeButton>
        )}
        {onClickSecondaryButton && secondaryButtonText && (
          <ThemeButton onClick={onClickSecondaryButton} size="large" color="BLUE_ICE">
            {secondaryButtonText}
          </ThemeButton>
        )}
      </Box>
    </CardContainer>
  );
};

export default EmptyStateCard;
