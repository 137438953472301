import { Box, FormControl, Grid, Radio, RadioGroup } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Edit } from '@styled-icons/boxicons-solid/Edit';
import SelectImpactLevelDescription from 'components/ImpactClaims/ClaimShared/SelectImpactLevelDescription';
import PartnerSelector from 'components/Partners/PartnerSelect/PartnerSelect';
import { ProductSelector } from 'components/Product';
import { SummaryBox } from 'components/SliderForm';
import { useConfig } from 'components/hooks';
import { DialogTitle, ThemeTypography } from 'designSystem';
import React, { FC, useMemo } from 'react';
import { IComponentItem } from 'types/component.types';
import { ImpactCatalogItem } from 'types/impactClaim.types';
import { IBasePartnerCompany } from 'types/partner.types';
import { LevelPill } from '../../ImpactClaims/ClaimShared';
import { impactLevelOptions } from '../../ImpactClaims/constants/impactLevelOptions';
import ComponentSelector from 'components/ComponentsLibrary/ComponentSelector';

interface ImpactLevelSlideProps {
  title: string;
  selectedImpactClaim: ImpactCatalogItem;
  selectedImpactLevel: string;
  selectedPartner?: IBasePartnerCompany;
  selectedComponent?: IComponentItem;
  selectedProductId?: string;
  impactLevelItemTitle?: string;
  hidePartner?: boolean;
  setSelectedImpactLevel: (value: string) => void;
  setSelectedProductId: (value: string | null) => void;
  setSelectedPartner: (value: IBasePartnerCompany) => void;
  setSelectedComponent: (value: IComponentItem) => void;
}

const Container = styled('div')(() => ({
  display: 'flex',
  height: '100%',
  width: '100%',
}));

const Column = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(2),
}));

const LevelColumn = styled(Column)(({ theme }) => ({
  height: '100%',
  width: '50%',
  background: theme.custom.colors.backgroundLight,
}));

const SelectColumn = styled(Column)(({ theme }) => ({
  height: '100%',
  width: '50%',
  padding: theme.spacing(9, 5, 0),
  overflow: 'auto',
}));

const RadioItem = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'start',
  cursor: 'pointer',
  marginBottom: theme.spacing(1),
}));

const StyledRadio = styled(Radio)(({ theme }) => ({
  '&.Mui-checked': {
    color: theme.palette.secondary.main,
  },
}));

const ImpactLevelSlide: FC<ImpactLevelSlideProps> = ({
  title,
  selectedImpactClaim,
  selectedImpactLevel,
  selectedComponent,
  selectedPartner,
  selectedProductId,
  impactLevelItemTitle,
  hidePartner = false,
  setSelectedImpactLevel,
  setSelectedProductId,
  setSelectedPartner,
  setSelectedComponent,
}) => {
  // Uses the selected impact claim as the primary selection
  const { claimCategories } = useConfig();
  const selectedItem = selectedImpactClaim;

  const iconPath = claimCategories[selectedImpactClaim.category.type].icon;

  const selectedImpactLevelInformation = useMemo(() => {
    return impactLevelOptions.find(level => level.value === selectedImpactLevel);
  }, [selectedImpactLevel]);

  const handleChangeImpactLevel = (value: string) => {
    setSelectedImpactLevel(value);
    setSelectedProductId(null);
  };

  return (
    <Container data-cy="impact-level-slide">
      <LevelColumn>
        <Box mb={3}>
          <DialogTitle icon={<Edit size={16} />}>{title}</DialogTitle>
        </Box>
        <Box pl={3} pr={4}>
          <Box mb={2}>
            <ThemeTypography variant="TITLE_SMALL">
              What parts of the production does this impact claim refer to?
            </ThemeTypography>
          </Box>
          <FormControl component="fieldset">
            <RadioGroup aria-label="impact-level" name="impact-level" value={selectedImpactLevel}>
              {impactLevelOptions.map((level, index) => {
                if (level.value === 'PARTNER' && hidePartner) return null;
                return (
                  <RadioItem
                    key={index}
                    onClick={() => handleChangeImpactLevel(level.value)}
                    data-cy={`impact-level-radio-item`}
                  >
                    <StyledRadio
                      value={level.value}
                      data-cy={`impact-level-radio-button-${level.value}`}
                    />
                    <Box flexWrap="wrap" display="flex" mt={1}>
                      <Box mr={1} mb={1}>
                        <LevelPill claimLevel={level.label} />
                      </Box>
                      <ThemeTypography variant="BODY_LARGE">{level.text}</ThemeTypography>
                    </Box>
                  </RadioItem>
                );
              })}
            </RadioGroup>
          </FormControl>
        </Box>
      </LevelColumn>
      <SelectColumn>
        <SummaryBox
          title={selectedItem?.title || ''}
          variant={selectedItem?.iconUrl ? 'IMAGE' : 'BACKGROUND_ICON'}
          levelPillOptions={{
            level: selectedImpactLevel,
            title: impactLevelItemTitle,
          }}
          imageUrl={selectedItem?.iconUrl || iconPath}
        />

        {selectedImpactLevel === 'BUSINESS' &&
          selectedImpactLevelInformation?.props.description && (
            <SelectImpactLevelDescription
              description={selectedImpactLevelInformation?.props.description}
            />
          )}

        {selectedImpactLevel === 'PRODUCT' && (
          <ProductSelector
            selectedProductId={selectedProductId}
            setSelectedProductId={setSelectedProductId}
          />
        )}
        {!hidePartner && selectedImpactLevel === 'PARTNER' && (
          <PartnerSelector
            selectedPartner={selectedPartner}
            setSelectedPartner={setSelectedPartner}
          />
        )}
        {selectedImpactLevel === 'COMPONENT' && (
          <ComponentSelector
            selectedComponent={selectedComponent}
            setSelectedComponent={setSelectedComponent}
          />
        )}
      </SelectColumn>
    </Container>
  );
};

export default ImpactLevelSlide;
