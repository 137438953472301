import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ReactComponent as ProfileImage } from 'assets/img/profile-image.svg';
import PropTypes from 'prop-types';
import React from 'react';

const UserName = styled('p')(() => ({
  fontSize: 13,
  fontWeight: 400,
  margin: 0,
}));

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  background: theme.custom.colors.backgroundLight,
  borderRadius: 4,
  padding: theme.spacing(1),
  width: 'auto',
  alignSelf: 'flex-start',
}));

const SmallUserCard = ({ userName, ...props }) => (
  <Container {...props}>
    <ProfileImage width={26} height={26} color="white" />
    <UserName>{userName}</UserName>
  </Container>
);

SmallUserCard.propTypes = {
  userName: PropTypes.string,
};

export default SmallUserCard;
