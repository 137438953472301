import { ChainMappingEvent } from 'components/ChainMapping/utils/mapping.types';
import { useProduct } from 'components/Product';
import { useDialog } from 'components/hooks';
import { appQueryParams } from 'constants/appQueryParams';
import { useNavigate } from 'react-router-dom';
import { IChain } from 'types/chain.types';
import { AvailableLanguagesType } from 'types/enums';
import { useQueryParam } from 'use-query-params';

const useProductSupplyChainMapping = () => {
  const { openDialog } = useDialog();
  const navigate = useNavigate();
  const { latestProductVersion, product } = useProduct();
  const [lang = product?.languageConfig.default] = useQueryParam<AvailableLanguagesType>(
    appQueryParams.lang
  );

  const supplyChain: IChain | undefined = latestProductVersion?.supplyChain;

  const handleChainMappingAction = (event: ChainMappingEvent) => {
    if (!supplyChain) {
      // eslint-disable-next-line no-console
      console.error('Current supply chain is not defined');
      return;
    }
    switch (event.type) {
      case 'EDIT_ACTIVITY':
        const activity = supplyChain.chainStepActivities.find(
          activity => activity.id === event.elementId
        );
        if (!activity) {
          // eslint-disable-next-line no-console
          console.error('Activity not found');
          return;
        }
        openDialog({
          type: 'ADD_EDIT_ACTIVITY',
          props: {
            mode: 'edit-activity',
            activity: activity.activity,
            disableDelete: true,
            editMode: 'translate',
            hidePartnerSelector: true,
            linkToSupplyChainMapping: `/supply-chains/${supplyChain.productId}/${supplyChain.id}/editor`,
            lang: lang || undefined,
          },
        });
        break;
      case 'EDIT_SUB_CHAIN':
        const subChain = supplyChain.subChains.find(({ id }) => id === event.elementId);
        if (!subChain) {
          // eslint-disable-next-line no-console
          console.error('SubChain not found');
          break;
        }
        navigate(`/component-chains/${subChain.childChainId}/editor`);
        break;
      case 'OPEN_SITE_CLUSTER':
        navigate(`/sites/external/${event.elementId}`);
    }
  };

  return {
    supplyChain,
    handleChainMappingAction,
  };
};

export default useProductSupplyChainMapping;
