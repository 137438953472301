import { Box, MenuItem, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { FC, PropsWithChildren } from 'react';
import { Plus } from '@styled-icons/bootstrap/Plus';
import Icon from 'designSystem/Primitives/Icon/Icon';
import { FlexBox } from 'components/Structure';

const PaperContainer = styled(Paper)(({ theme }) => ({
  paddingBottom: theme.spacing(1),
}));

const AddIcon = styled(Plus)(({ theme }) => ({
  color: theme.palette.secondary.main,
}));

/**
 * This adds an add button to the bottom of the paper
 * Can be used with the component prop PaperComponent -> business-app/src/components/Partners/Form/PartnerAutocomplete.tsx
 */
const PaperWithAddItem: FC<
  {
    title: string;
    infoMessage?: string;
    'data-testid'?: string;
    onClick: () => void;
  } & PropsWithChildren
> = ({ title, infoMessage, children, 'data-testid': dataTestId, onClick }) => (
  <PaperContainer data-testid={dataTestId}>
    {infoMessage && (
      <FlexBox px={2} pt={2} mb={1} gap={1}>
        <Icon name="info" />
        {infoMessage}
      </FlexBox>
    )}
    {children}
    <MenuItem onMouseDown={onClick}>
      <AddIcon size={16} />
      <Box mr={1} />
      {title}
    </MenuItem>
  </PaperContainer>
);

export default PaperWithAddItem;
