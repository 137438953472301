import { Theme } from '@mui/material';
import { styled } from '@mui/styles';

const Flag = styled('img')(({ theme, gutterRight }: { theme: Theme; gutterRight: boolean }) => ({
  width: 20,
  borderRadius: 3,
  marginRight: gutterRight ? theme.spacing(1) : 0,
}));

export default Flag;
