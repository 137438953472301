import * as Yup from 'yup';
import { v4 as uuid } from 'uuid';

export const strictRequired = function (message = 'This field is required') {
  return this.when('$strict', (strict, schema) => {
    if (strict) return schema.required(message);

    return schema;
  });
};

export const strictMin = function (min, message) {
  return this.when('$strict', (strict, schema) => {
    if (strict) return schema.min(min, message);

    return schema;
  });
};

export const strictRequiredAsHTML = function () {
  return this.when('$strict', (strict, schema) => {
    if (strict) return schema.test(htmlStringHasText);

    return schema;
  });
};

Yup.addMethod(Yup.mixed, 'strictRequired', strictRequired);
Yup.addMethod(Yup.mixed, 'strictRequiredAsHTML', strictRequiredAsHTML);
Yup.addMethod(Yup.mixed, 'strictMin', strictMin);

export const requiredIfEnabled = {
  name: 'dependant-enabled',
  test: function (value) {
    if (!this.parent.enabled || value) return true;

    return false;
  },
};

export const optionalUrlTest = {
  name: 'optional-url-test',
  test: function (value) {
    if (!value) return true;

    const schema = Yup.string().url();
    if (schema.isValidSync(value)) return true;

    return this.createError({
      message: `${this.schema._label || this.path} must be a valid URL that includes http://'`,
    });
  },
};

export const htmlStringHasText = {
  name: 'html-string-has-text',
  test: function (value) {
    const text = value.replace(/<[^>]+>/g, '').trim();

    if (!!text) return true;

    return this.createError({
      message: `${this.schema._label || this.path} is a required filed`,
    });
  },
};

export const createMediaSchema = (message, required = true) => {
  const schema = required ? mediaSchema.required(message) : mediaSchema;
  const test = mediaItem => {
    if (!mediaItem) return false;
    const { url, vimeoLink, hexCode, __ref } = mediaItem;
    const value = url || vimeoLink || hexCode || __ref;

    return typeof value === 'string' && value.length > 0;
  };

  return schema.test({
    name: 'Image URL Validation',
    message: message,
    test: function (value) {
      const valid = test(value);

      if (!this.schema._exclusive.required && !valid) return true;
      else if (!valid) return false;

      return true;
    },
  });
};

export const createImageSchema = ({ message, required = false }) => {
  return required ? imageSchema.required(message) : imageSchema;
};

export const createProductDefault = () => ({
  __typename: 'ProductItem',
  id: uuid(),
  title: '',
  description: '',
  link: '',
  image: null,
});

export const createImpactFactDefault = () => ({
  __typename: 'ImpactFact',
  id: uuid(),
  title: '',
  description: '',
  sdgs: [],
});

export const dependantUrlTest = {
  name: 'settings-share',
  test: function (value) {
    if (!value) return true;

    const schema = Yup.string().url();
    if (schema.isValidSync(value)) return true;

    return this.createError({
      message: `${this.schema._label || this.path} must be a valid url`,
    });
  },
};

export const mediaSchema = Yup.object()
  .shape({
    id: Yup.string(),
    imageId: Yup.string(),
    __typename: Yup.string(),
    url: Yup.string().nullable(),
    hexCode: Yup.string().nullable(),
    vimeoLink: Yup.string().nullable(),
    crop: Yup.object()
      .shape({
        y: Yup.number().nullable(),
        x: Yup.number().nullable(),
        width: Yup.number().nullable(),
        height: Yup.number().nullable(),
      })
      .default({
        y: 0,
        x: 0,
        width: 0,
        height: 0,
      })
      .nullable(),
  })
  .nullable()
  .default({
    url: null,
    __typename: 'ImageType',
  });

export const imageSchema = Yup.object()
  .shape({
    imageId: Yup.string().nullable(),
    __typename: Yup.string(),
    crop: Yup.object()
      .shape({
        y: Yup.number().nullable(),
        x: Yup.number().nullable(),
        width: Yup.number().nullable(),
        height: Yup.number().nullable(),
      })
      .default({
        y: 0,
        x: 0,
        width: 0,
        height: 0,
      })
      .nullable(),
  })
  .nullable()
  .default(null);
