import { Grid, Link, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FieldWrapper } from 'designSystem';
import ThemeButton from 'designSystem/Buttons/ThemeButton/ThemeButton';
import { FastField as Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import * as Yup from 'yup';
import AcceptTermsCheckbox from './AcceptTermsCheckbox';
import AuthHeadline from './AuthHeadline';
import PasswordInputField from './PasswordInputField';
import TermsDialog from './TermsDialog';

const Text = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(1),
  color: '#fff',
}));

const RegistrationSchema = Yup.object().shape({
  password: Yup.string()
    .required('This field is required.')
    .min(6, 'Please enter at least 6 characters'),
  firstName: Yup.string().required('This field is required.'),
  lastName: Yup.string().required('This field is required.'),
  termsAccepted: Yup.bool()
    .oneOf([true], 'Please accept the terms and conditions')
    .required('Please accept the terms and conditions'),
});

const RegistrationInvitedForm = ({ onSubmit }) => {
  const [termsOpen, setTermsOpen] = useState(false);

  return (
    <Formik
      initialValues={{
        firstName: '',
        lastName: '',
        password: '',
        termsAccepted: false,
      }}
      validateOnBlur={false}
      validateOnChange={true}
      validationSchema={RegistrationSchema}
      onSubmit={values => onSubmit(values)}
    >
      {({ isSubmitting }) => (
        <Form>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <AuthHeadline text="Welcome to" highlighted="seedtrace" />
              <Text variant="body2">
                You have been invited to join seedtrace! <br /> Enter your details to register.
              </Text>
            </Grid>
            <Grid item xs={4}>
              <FieldWrapper label="First name" inverted>
                <Field
                  component={TextField}
                  name="firstName"
                  placeholder="Jane"
                  variant="outlined"
                />
              </FieldWrapper>
            </Grid>
            <Grid item xs={4}>
              <FieldWrapper label="Last name" inverted>
                <Field component={TextField} placeholder="Doe" name="lastName" variant="outlined" />
              </FieldWrapper>
            </Grid>
            <Grid item xs={8}>
              <FieldWrapper label="Password" inverted>
                <Field
                  fullWidth
                  component={PasswordInputField}
                  name="password"
                  placeholder="Min. 6 characters"
                  variant="outlined"
                />
              </FieldWrapper>
            </Grid>
            <Grid item xs={12}>
              <AcceptTermsCheckbox handleOpenTerms={() => setTermsOpen(true)} />
            </Grid>
            <Grid item xs={12}>
              <ThemeButton
                loading={isSubmitting}
                size="large"
                color="YELLOW"
                type="submit"
                className="button-wide"
              >
                Register
              </ThemeButton>
            </Grid>
            <Grid item xs={12}>
              <Link component={RouterLink} to="/login" color="secondary">
                Already registered? Login
              </Link>
            </Grid>
          </Grid>
          <TermsDialog open={termsOpen} onClose={() => setTermsOpen(false)} />
        </Form>
      )}
    </Formik>
  );
};

RegistrationInvitedForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default RegistrationInvitedForm;
