import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CardContainer, ThemeButton } from 'designSystem';
import spark from 'assets/img/spark.png';
import { Plus } from '@styled-icons/bootstrap/Plus';
import { Search } from '@styled-icons/bootstrap/Search';

const Text = styled(Typography)(() => ({
  fontWeight: 600,
  fontSize: 20,
}));

const HeadlineContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.grey[500],
  marginBottom: theme.spacing(5),
}));

const Container = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: '40%',
  left: '50%',
  transform: 'translate3d(-50%, -50%, 0)',
  display: 'flex',
  flexDirection: 'column',
  color: theme.palette.text.secondary,
  minWidth: 400,
}));

const Spark = styled('img')(() => ({
  position: 'absolute',
  width: 60,
  height: 60,
  right: -35,
  top: -25,
}));

const ListItemContainer = styled('div')(() => ({
  position: 'relative',
  display: 'flex',
}));

const EmptySearchResults = ({
  message = 'There is nothing here yet.',
  center = true,
  action,
  skeletonComponent: Skeleton,
}) => {
  return (
    <Container data-cy="empty-search-list" center={center}>
      <HeadlineContainer>
        <Box mr={2}>
          <Search size={25} />{' '}
        </Box>
        <Text>{message}</Text>
      </HeadlineContainer>
      <ListItemContainer>
        <CardContainer padding={1.5} maxWidth="550px">
          {Skeleton}
        </CardContainer>
        <Spark src={spark} />
      </ListItemContainer>
      {action && (
        <Box mt={2}>
          <ThemeButton
            data-cy="empty-search-btn"
            onClick={action.onClick}
            color="BLUE_ICE"
            startIcon={<Plus size={20} />}
          >
            {action.label}
          </ThemeButton>
        </Box>
      )}
    </Container>
  );
};

EmptySearchResults.propTypes = {
  message: PropTypes.string,
  action: PropTypes.shape({
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  }),
  searchTerm: PropTypes.string,
  skeletonComponent: PropTypes.node.isRequired,
};

export default EmptySearchResults;
