import { Box, Typography, styled } from '@mui/material';

export const OverviewHeaderContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  flex: '1',
  border: `${theme.custom.colors.lightBorderColor} 1px solid`,
  padding: theme.spacing(3),
  backgroundColor: theme.palette.common.white,
  borderRadius: 6,
  overflow: 'hidden',
}));

export const OverviewTitle = styled(Typography)(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontWeight: 900,
  fontSize: 32,
  marginBottom: theme.spacing(1),
}));

interface OverviewImageProps {
  backgroundImage: string;
}

export const OverviewImage = styled('div')<OverviewImageProps>(({ backgroundImage }) => {
  return {
    width: 130,
    height: 130,
    flexShrink: 0,
    backgroundImage: `url(${backgroundImage})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    borderRadius: 4,
  };
});
