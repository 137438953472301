import { styled, Table, TableBody, TableHead, TableRow, TableRowProps } from '@mui/material';
import { booleanish } from 'types/booleanish.types';

export const StyledTable = styled(Table)<{ 'large-spacing'?: booleanish }>(
  ({ 'large-spacing': largeSpacing }) => ({
    borderSpacing: largeSpacing === 'true' ? '0 12px' : '0 8px',
    borderCollapse: 'separate',

    '& .MuiTableCell-root': {
      borderBottom: 'none',
    },
  })
);

export const StyledTableHead = styled(TableHead)(({ theme }) => ({
  textTransform: 'uppercase',
  letterSpacing: 1.5,

  '& th': {
    fontWeight: 200,
    padding: theme.spacing(1.5, 1.5, 0, 1.5),
  },
}));

export const StyledTableRow = styled(TableRow)<
  Exclude<TableRowProps, 'selected'> & {
    'is-selected'?: booleanish;
    'has-highlight-top'?: booleanish;
    'has-more'?: booleanish;
    transparent?: booleanish;
    disabled?: booleanish;
  }
>(
  ({
    theme,
    'is-selected': selected,
    'has-highlight-top': highlightTop,
    transparent,
    disabled,
  }) => ({
    position: 'relative',
    transition: '0.125s',
    width: '100%',
    backgroundColor: '#fff',
    boxShadow: theme.custom.shadows[4],
    cursor: disabled === 'true' ? 'default' : 'pointer',

    // Showing and hiding the border moves the content of the box, so we use a pseudo element instead
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      width: '100%',
      border: selected === 'true' ? `2px solid ${theme.palette.secondary.main}` : 'none',
      borderRadius: 4,
      pointerEvents: 'none',
    },

    '& > td': {
      borderTop: highlightTop === 'true' ? `6px solid ${theme.custom.colors.blueFrost}` : 'none',
    },

    ...(transparent === 'true'
      ? {
          boxShadow: 'none',
          opacity: 0.4,
          filter: 'drop-shadow(0px 4px 26px rgba(0, 0, 0, 0.12))',
          '& > td': {
            borderBottom: `1px dashed ${theme.custom.colors.blueFrost} !important`,
            borderTop: `1px dashed ${theme.custom.colors.blueFrost}`,
            '&:first-of-type': {
              borderLeft: `1px dashed ${theme.custom.colors.blueFrost}`,
            },
            '&:last-child': {
              borderRight: `1px dashed ${theme.custom.colors.blueFrost}`,
            },
          },
        }
      : {}),

    '&:hover': {
      backgroundColor:
        disabled === 'true' ? 'none' : `${theme.custom.colors.backgroundLight} !important`,
    },
  })
);

export const StyledTableBody = styled(TableBody)(({ theme }) => ({
  '& .MuiTableCell-root': {
    borderBottom: 'none',
    padding: theme.spacing(1.5),
  },

  '& td:first-of-type': {
    borderTopLeftRadius: 6,
    borderBottomLeftRadius: 6,
  },

  '& th:first-of-type': {
    borderTopLeftRadius: 6,
    borderBottomLeftRadius: 6,
  },

  '& th:last-child': {
    borderTopRightRadius: 6,
    borderBottomRightRadius: 6,
  },

  '& td:last-child': {
    borderTopRightRadius: 6,
    borderBottomRightRadius: 6,
  },

  '& .MuiTableRow-root.Mui-selected': {
    backgroundColor: '#fff',
  },
}));
