import { gql } from '@apollo/client';

export const FIRST_MILE_IMPORT_FRAGMENT = gql`
  fragment firstMileImportValues on FirstMileImport {
    id
    title
    createdTimestamp
    modifiedTimestamp
    language
  }
`;
