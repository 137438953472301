import React, { FC } from 'react';
import { styled } from '@mui/material/styles';
import { Zoom, Fade } from '@mui/material';
import { TextControlBar } from 'components/WelcomeSlides';
import line from 'assets/img/welcomeSlides/line-pulse.svg';
import chat from 'assets/img/welcomeSlides/customer-support.png';
import { PageContainer, TextContainer, ImageContainer, Headline } from './styles';
import { ThemeTypography } from 'designSystem';

interface IAssistanceProps {
  active: boolean;
}

const ChatImage = styled('img')(() => ({
  width: '220px !important',
}));

const Line = styled('img')(({ theme }) => ({
  position: 'absolute',
  top: 250,
  left: theme.spacing(4),
  zIndex: -1,
  width: '320px !important',

  [theme.breakpoints.up('xl')]: {
    display: 'none',
  },
}));

const Assistance: FC<IAssistanceProps> = ({ active }) => {
  return (
    <>
      <PageContainer>
        <Zoom in={active}>
          <Line src={line} />
        </Zoom>
        <Zoom in={active}>
          <TextContainer>
            <Headline variant="TITLE_LARGE">Need help?</Headline>
            <ThemeTypography variant="TITLE_EXTRA_SMALL">
              If you have any questions, check out our helpdesk, or find us in the live chat in the
              bottom right.
            </ThemeTypography>
          </TextContainer>
        </Zoom>
        <Fade in={active} timeout={400}>
          <ImageContainer>
            <ChatImage alt="Customer support chat" src={chat} />
          </ImageContainer>
        </Fade>
      </PageContainer>
      <TextControlBar />
    </>
  );
};

export default Assistance;
