import React from 'react';
import { useQuery } from '@apollo/client';
import { GET_COMPANY_ANALYTICS_SUMMARY } from 'graphql/queries';
import { useTimeframe } from 'components/Product/hooks';
import { DateRangeMultiSelect } from 'components/Forms';
import { ErrorState, PageSubTitle } from 'components/Structure';
import { RecentActivityCard } from 'components/Product/Analytics/Cards';
import { KeyMetrics, MetricNumber, TimeNumber } from 'components/Product/Analytics';
import { Box, Grid } from '@mui/material';

const HomeAnalytics = () => {
  const { start, end } = useTimeframe();

  const {
    loading,
    data = {},
    error,
  } = useQuery(GET_COMPANY_ANALYTICS_SUMMARY, {
    variables: {
      params: {
        startDate: start,
        endDate: end,
      },
    },
    fetchPolicy: 'no-cache',
  });

  if (error) return <ErrorState error={error} center={false} />;

  const {
    companyProductsActivity: pageActivity = {},
    companyAnalyticsSummary: { views, uniqueViews, qrViews, averageTimeSpent } = {},
  } = data;

  return (
    <Box pt={2} pb={2}>
      <PageSubTitle title="Latest activity">
        <DateRangeMultiSelect showRefreshButton={false} />
      </PageSubTitle>
      <Box display="flex" ml={-1} mr={-1} gap={1.5}>
        <RecentActivityCard data={pageActivity} loading={loading} filled />
        <Grid item xs={4} md={3}>
          <KeyMetrics
            label={null}
            fullHeight={false}
            loading={loading}
            views={[
              <MetricNumber
                total={views?.current}
                change={views?.percentageChange}
                label="Total page views"
                key="views"
              />,
              <MetricNumber
                total={uniqueViews?.current}
                change={uniqueViews?.percentageChange}
                label="New visitors"
                key="uniqueViews"
              />,
              <MetricNumber
                total={qrViews?.current}
                change={qrViews?.percentageChange}
                label="QR code scans"
                key="qrViews"
              />,
              <TimeNumber time={averageTimeSpent} key="averageTimeSpent" />,
            ]}
          />
        </Grid>
      </Box>
    </Box>
  );
};

HomeAnalytics.propTypes = {};

export default HomeAnalytics;
