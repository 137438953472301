import { Box, DialogContent, FormControl, MenuItem, Select, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { useConfig } from 'components/hooks';
import { useAddLanguage } from 'components/Product/LanguageSelect/hooks';
import { SeparatorLine } from 'components/Structure';
import { DialogDefault } from 'designSystem';
import ThemeButton from 'designSystem/Buttons/ThemeButton/ThemeButton';
import React, { FC, Fragment, useState } from 'react';
import { IDefaultDialogProps } from 'types/dialog.types';
import { AvailableLanguages } from 'types/enums';
import { Product } from 'types/types';

export interface IAddLanguageDialogProps extends IDefaultDialogProps {
  product: Product;
  languagesToAdd?: string[];
  defaultLanguage?: AvailableLanguages;
}

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  padding: theme.spacing(2, 0, 1),

  '& .MuiSelect-root': {
    display: 'flex',
    alignItems: 'center',
  },
  '& .MuiInput-underline:before': {
    content: 'none',
  },
  '& .MuiInput-underline:after': {
    content: 'none',
  },
}));

const Flag = styled('img')(({ theme }) => ({
  width: 18,
  marginRight: theme.spacing(0.5),
  borderRadius: 2,
}));

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  paddingBottom: theme.spacing(5),
  paddingTop: 0,
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  marginTop: theme.spacing(-2),
  display: 'block',
}));

const Label = styled('span')(({ theme }) => ({
  fontSize: 12,
  color: theme.custom.colors.textLight,
}));

const EmptyFlag = styled('div')(({ theme }) => ({
  width: 18,
  height: 10,
  background: theme.custom.colors.backgroundLight,
  borderRadius: 2,
  marginRight: theme.spacing(0.5),
}));

const AddedLanguagesWrapper = styled('div')(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(2),
  background: theme.custom.colors.backgroundLight,
  borderRadius: theme.spacing(1),
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
}));

const AddedTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  fontSize: 12,
  marginRight: theme.spacing(1),
}));

const AddLanguageDialog: FC<IAddLanguageDialogProps> = ({
  open,
  product,
  onClose,
  languagesToAdd = [],
  defaultLanguage,
}) => {
  const [autoTranslateLang, setAutoTranslateLang] = useState<AvailableLanguages | undefined>(
    product.languageConfig.enabled.length ? product.languageConfig.enabled[0] : undefined
  );

  const {
    addBlankLanguage,
    addLanguageAndTranslate,
    selectedLang,
    setSelectedLang,
    loadingBlankLanguage,
    loadingLanguageTranslate,
  } = useAddLanguage({
    onClose,
  });
  const theme = useTheme();
  const config = useConfig();

  /**
   * Loops over all the provided languages and calls the translation endpoint for each.
   * Uses for loop to run the calls sequentially
   */

  const addAndTranslateLanguages = async (languages: string[]) => {
    for (const language of languages) {
      await addLanguageAndTranslate({
        product: {
          ...product,
          languageConfig: {
            ...product.languageConfig,
            default: defaultLanguage || product.languageConfig.default, // overrides the defaultLanguage, to make sure it takes the language selected in the language select dialog
          },
        },
        lang: language,
        autoTranslateLang,
        enabledLanguages: [...product.languageConfig.enabled, ...languages],
      });
    }
  };

  return (
    <DialogDefault
      open={open}
      title="Add product language"
      onClose={onClose}
      fullWidth
      maxWidth="xs"
      data-cy="dialog-add-language"
    >
      <StyledDialogContent>
        {!!languagesToAdd.length && (
          <AddedLanguagesWrapper data-cy="added-languages-wrapper">
            <AddedTitle variant="body1">Added:</AddedTitle>

            {languagesToAdd.map(language => {
              const { flag, title } = config.getLanguageConfig(language);

              return (
                <Box key={`preset-language-${language}`} display="flex" alignItems="center" mr={1}>
                  <Flag src={flag} />

                  {title}
                </Box>
              );
            })}
          </AddedLanguagesWrapper>
        )}

        {!languagesToAdd.length && (
          <StyledFormControl fullWidth>
            <StyledSelect
              value={selectedLang}
              onChange={event => setSelectedLang(event.target.value as AvailableLanguages)}
              variant="outlined"
              defaultValue="none"
              data-cy="select-target-language"
            >
              <MenuItem value="none" disabled>
                <EmptyFlag />
                <Label>Select new language</Label>
              </MenuItem>
              {product.notEnabledLanguages.map(lang => {
                const { flag, title } = config.getLanguageConfig(lang);

                return (
                  <MenuItem
                    key={`product-lang-${lang}`}
                    value={lang}
                    data-cy={`option-target-${lang}`}
                  >
                    <Flag src={flag} />
                    <Label>{title}</Label>
                  </MenuItem>
                );
              })}
            </StyledSelect>
          </StyledFormControl>
        )}

        {!!(selectedLang || languagesToAdd.length) && (
          <Fragment>
            <StyledFormControl fullWidth>
              <label>Auto-translate from other language</label>
              <Select
                value={autoTranslateLang}
                onChange={event => setAutoTranslateLang(event.target.value as AvailableLanguages)}
                variant="outlined"
                displayEmpty
                data-cy="select-source-language"
              >
                {product.languageConfig.enabled.map(lang => {
                  const { flag, title } = config.getLanguageConfig(lang);

                  return (
                    <MenuItem
                      key={`product-lang-${lang}`}
                      value={lang}
                      data-cy={`option-source-${lang}`}
                    >
                      <Flag src={flag} />
                      <Label>{title}</Label>
                    </MenuItem>
                  );
                })}
              </Select>
            </StyledFormControl>

            <ThemeButton
              color="YELLOW"
              size="large"
              fullWidth
              onClick={() =>
                // addAndTranslateLanguages takes in an array of languages to add, so we always add the selectedLang to an array to make this work.
                addAndTranslateLanguages(selectedLang ? [selectedLang] : languagesToAdd)
              }
              loading={loadingLanguageTranslate}
              data-cy="button-add-lang-and-translate"
            >
              {selectedLang
                ? `Add & translate into ${config.getLanguageConfig(selectedLang).title}`
                : `Add & translate`}
            </ThemeButton>
            <SeparatorLine label="or" width="80%" backgroundColor={theme.custom.colors.offWhite} />
            <ThemeButton
              color="BLUE_ICE"
              size="large"
              fullWidth
              onClick={() => {
                // if the selectedLang is not available, that means the's multuple languages being added through the languagesToAdd param
                // in that case we can skip the addBlankLanguage call since that's already done in the LanguageSettings component which calls this dialog.
                if (selectedLang) {
                  addBlankLanguage({
                    product,
                    lang: selectedLang,
                  });
                }

                onClose?.();
              }}
              data-cy="button-add-lang-blank"
              loading={loadingBlankLanguage}
            >
              {selectedLang
                ? `Add blank version in ${config.getLanguageConfig(selectedLang).title}`
                : `Add blank ${languagesToAdd.length > 1 ? 'versions' : 'version'}`}
            </ThemeButton>
          </Fragment>
        )}
      </StyledDialogContent>
    </DialogDefault>
  );
};

export default AddLanguageDialog;
