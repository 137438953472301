import { ThemeTypographyColor } from 'designSystem/utils/designSystemUtils';
import { CompanyStatus, CompanyStatusType } from 'types/company.types';

export const getReadableCompanyStatus = (status: CompanyStatusType): string => {
  switch (status) {
    case CompanyStatus.INVITED:
      return 'INVITATION SENT';
    case CompanyStatus.NOT_INVITED:
      return 'NOT INVITED';
    case CompanyStatus.REGISTERED:
      return 'ACTIVE COLLABORATOR';
    default:
      return '';
  }
};

export const getCompanyStatusColor = (status: CompanyStatusType): ThemeTypographyColor => {
  switch (status) {
    case CompanyStatus.INVITED:
      return 'YELLOW';
    case CompanyStatus.NOT_INVITED:
      return 'GRAY';
    case CompanyStatus.REGISTERED:
      return 'GREEN';
    default:
      return 'GRAY';
  }
};
