import { useProduct } from 'components/Product/hooks';
import { contentBlockSchema } from 'constants/schemas';
import find from 'lodash/find';
import { useMemo } from 'react';
import * as Yup from 'yup';

const useContentBlock = contentType => {
  const {
    loading,
    error,
    latestProductVersion: { contentBlocks },
  } = useProduct();

  const schema = contentBlockSchema[contentType].schema;
  // wrap data schema so enabled boolean can be sent separately to backend
  const enhancedScheme = useMemo(
    () =>
      Yup.object()
        .shape({
          enabled: Yup.bool().default(true),
        })
        .concat(schema),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const contentBlock = find(contentBlocks, ['contentType', contentType]);

  return [
    {
      ...contentBlock,
      enabled: !!contentBlock.enabled,
    },
    enhancedScheme,
    { loading, error },
  ];
};

export default useContentBlock;
