import { DialogActions, DialogContent, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { htmlEditorLinkSchema } from 'constants/schemas/htmlEditorLink';
import ThemeButton from 'designSystem/Buttons/ThemeButton/ThemeButton';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import React, { FC } from 'react';
import { IDefaultDialogProps } from 'types/dialog.types';
import DialogDefault from '../Dialog/DialogDefault/DialogDefault';

interface IHtmlLinkDialogProps extends IDefaultDialogProps {
  linkText: string;
  onSubmit: (values: { url?: string }) => void;
}

const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  padding: theme.spacing(3, 0, 0, 0),
}));

const HTMLLinkDialog: FC<IHtmlLinkDialogProps> = ({ open, linkText, onClose, onSubmit }) => (
  <DialogDefault
    open={!!open}
    title={`Add link for ${linkText}`}
    fullWidth
    maxWidth="xs"
    onClose={onClose}
  >
    <DialogContent>
      <Formik
        validationSchema={htmlEditorLinkSchema}
        initialValues={{ ...htmlEditorLinkSchema.default() }}
        onSubmit={onSubmit}
      >
        {() => (
          <Form>
            <Grid container>
              <Grid item xs={12}>
                <Field
                  component={TextField}
                  fullWidth
                  name="url"
                  variant="outlined"
                  placeholder="https://website.com"
                />
              </Grid>
            </Grid>
            <StyledDialogActions>
              <ThemeButton color="YELLOW" size="large" type="submit">
                Insert
              </ThemeButton>
            </StyledDialogActions>
          </Form>
        )}
      </Formik>
    </DialogContent>
  </DialogDefault>
);

export default HTMLLinkDialog;
