import { useMutation } from '@apollo/client';
import { Grid } from '@mui/material';
import PasswordInputField from 'components/Auth/PasswordInputField';
import { FieldWrapper, ThemeButton } from 'designSystem';
import { useMessages } from 'components/hooks';
import { Field, Form, Formik } from 'formik';
import { UPDATE_PASSWORD } from 'graphql/mutations';
import React from 'react';
import * as Yup from 'yup';

const Schema = Yup.object().shape({
  oldPassword: Yup.string()
    .min(8, 'Please enter at least 8 characters')
    .required('This field is required')
    .default(''),
  newPassword: Yup.string()
    .min(8, 'Please enter at least 8 characters')
    .required('This field is required')
    .default(''),
});

const PasswordResetForm = () => {
  const { setErrorMessage, setSuccessMessage } = useMessages();

  const [updatePassword] = useMutation(UPDATE_PASSWORD, {
    onError: error => setErrorMessage(error.message),
    onCompleted: () => {
      setSuccessMessage('Your password has been changed');
    },
    fetchPolicy: 'no-cache',
  });

  const handleUpdatePassword = values =>
    updatePassword({
      variables: {
        ...values,
      },
    });

  return (
    <Formik
      initialValues={Schema.default()}
      validateOnBlur={false}
      validateOnChange={true}
      validationSchema={Schema}
      onSubmit={values => handleUpdatePassword(values)}
    >
      {({ isSubmitting }) => (
        <Form>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <FieldWrapper label="Current Password">
                <Field
                  fullWidth
                  name="oldPassword"
                  render={rest => <PasswordInputField variant="outlined" fullWidth {...rest} />}
                />
              </FieldWrapper>
            </Grid>
            <Grid item xs={6}></Grid>
            <Grid item xs={6}>
              <FieldWrapper label="New Password">
                <Field
                  fullWidth
                  name="newPassword"
                  render={rest => <PasswordInputField variant="outlined" fullWidth {...rest} />}
                />
              </FieldWrapper>
            </Grid>
            <Grid item xs={12} justifyContent="flex-end" alignItems="center">
              <ThemeButton loading={isSubmitting} size="large" color="YELLOW" type="submit">
                Update Password
              </ThemeButton>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default PasswordResetForm;
