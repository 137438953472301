import { useState, useEffect } from 'react';

const useWindowFocus = () => {
  const [focused, setFocus] = useState(true);
  const handleFocus = () => setFocus(true);
  const handleBlur = () => setFocus(false);

  function handleActivity() {
    return document.hidden ? setFocus(false) : setFocus(true);
  }

  useEffect(() => {
    window.addEventListener('focus', handleFocus);
    document.addEventListener('focus', handleFocus);
    window.addEventListener('blur', handleBlur);
    document.addEventListener('blur', handleBlur);
    document.addEventListener('visibilitychange', handleActivity);

    return () => {
      window.removeEventListener('focus', handleFocus);
      document.removeEventListener('focus', handleFocus);
      window.removeEventListener('blur', handleBlur);
      document.removeEventListener('blur', handleBlur);
      document.removeEventListener('visibilitychange', handleActivity);
    };
  });

  return focused;
};

export default useWindowFocus;
