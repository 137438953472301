import React, { FC } from 'react';
import { Zoom, Fade, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { TextControlBar } from 'components/WelcomeSlides';
import qrCode from 'assets/img/welcomeSlides/seedtrace-label-demo.png';
import {
  PageContainer,
  Headline,
  Item,
  ItemIcon,
  ItemIconContainer,
  ItemText,
  ContentContainer,
  ImageContainer,
} from './styles';
import pulseLine from 'assets/img/welcomeSlides/pulse-line-3.svg';
import { ThemeTypography } from 'designSystem';
import impactClaimBanner from 'assets/img/welcomeSlides/impact-claims-banner.png';
import bulb from 'assets/img/welcomeSlides/icon_bulb_plant.svg';

interface IPublishProductsProps {
  active: boolean;
}

const StyledPulse = styled('img')(({ theme }) => ({
  position: 'absolute',
  left: theme.spacing(0),
  width: '380px !important',
  bottom: 50,
}));

const QrCode = styled('img')(() => ({
  width: '176px !important',
}));

const LabelQrCode = styled(ThemeTypography)(() => ({
  color: '#000',
  fontSize: 16,
  fontWeight: '700 !important',
  width: 240,
  textAlign: 'center',
  marginTop: 8,
}));

const ImpactClaimBanner = styled('img')(() => ({
  width: '90% !important',
}));

const PublishProducts: FC<IPublishProductsProps> = ({ active }) => {
  return (
    <>
      <PageContainer>
        <Zoom in={active}>
          <ContentContainer>
            <Box mb={1.5}>
              <Item>
                <ItemIconContainer>
                  <ItemIcon width={20} src={bulb} alt="Bulb" />
                </ItemIconContainer>
                <ItemText dangerouslySetInnerHTML={{ __html: 'Prove your <br /> impact' }} />
              </Item>
            </Box>
            <Headline variant="TITLE_LARGE">Publish your products</Headline>
            <ThemeTypography variant="TITLE_EXTRA_SMALL">
              Publish and share your product pages & impact claims on all channels. Scan the QR code
              yourself to see an example.
            </ThemeTypography>
            <Zoom in={active}>
              <StyledPulse src={pulseLine} alt="pulse" />
            </Zoom>
          </ContentContainer>
        </Zoom>
        <Fade in={active} timeout={400}>
          <ImageContainer>
            <QrCode alt="Seedtrace label with qr code" src={qrCode} />
            <LabelQrCode variant="TITLE_EXTRA_SMALL">
              Scan to see an example of CocoGees’ cocoa beans
            </LabelQrCode>
            <Box width="100%" display="flex" justifyContent="flex-end" mt={4}>
              <ImpactClaimBanner src={impactClaimBanner} alt="Impact claims banner" />
            </Box>
          </ImageContainer>
        </Fade>
      </PageContainer>
      <TextControlBar />
    </>
  );
};

export default PublishProducts;
