import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import ClaimExampleImage from 'assets/img/claims/claim_empty.png';
import ComponentExampleImage from 'assets/img/components/component-example.png';
import ComponentChainExampleImage from 'assets/img/components/component-chain-example.png';
import DocumentExampleImage from 'assets/img/documents/document_empty_state.png';
import LocationExampleImage from 'assets/img/locations/empty_state.png';
import ProductExampleImage from 'assets/img/products/product_empty_state.png';
import PartnerExampleImage from 'assets/img/partners/default-partner-image.png';
import React from 'react';
import { ItemTypes } from 'types/enums';
import Skeleton from '../Skeleton/Skeleton';
import CardContainer from 'designSystem/Layout/CardContainer/CardContainer';
import ThemeTypography from 'designSystem/Primitives/Typography/ThemeTypography';

interface ImageProps {
  isLarge: boolean;
}

const StyledImage = styled('img')(({ isLarge }: ImageProps) => ({
  maxWidth: 'auto',
  height: isLarge ? 40 : 30,
}));

const StyledSkeleton = styled(Skeleton)(({ theme }) => ({
  backgroundColor: theme.custom.themeColors.primary[5],
}));

interface Props {
  title?: string;
  variant: ItemTypes;
}

const ItemSkeleton: React.FC<Props> = ({ title, variant }) => {
  const isImpactClaim = variant === ItemTypes.IMPACT_CLAIM;
  return (
    <CardContainer padding={isImpactClaim ? 0 : 1.5} maxWidth="550px">
      <Box display="flex" alignItems="center">
        <Box display="flex" alignSelf={isImpactClaim ? 'end' : ''} mr={isImpactClaim ? 0.5 : 1.5}>
          <StyledImage isLarge={isImpactClaim} src={getIcon(variant)} />
        </Box>
        <Box padding={variant === ItemTypes.IMPACT_CLAIM ? 1.5 : 0}>
          <ThemeTypography variant="BODY_LARGE_BOLD">{title}</ThemeTypography>
          {!title && <StyledSkeleton width={120} height={17} animation={false} />}
          <Box display="flex" alignItems="center">
            <StyledSkeleton width={90} height={12} animation={false} />
          </Box>
        </Box>
        <Box ml="auto" padding={variant === ItemTypes.IMPACT_CLAIM ? 1.5 : 0}>
          <StyledSkeleton width={120} height={17} animation={false} />
        </Box>
      </Box>
    </CardContainer>
  );
};

export default ItemSkeleton;

const getIcon = (imageType: ItemTypes) => {
  switch (imageType) {
    case 'DOCUMENT':
      return DocumentExampleImage;
    case 'PRODUCT':
      return ProductExampleImage;
    case 'IMPACT_CLAIM':
      return ClaimExampleImage;
    case 'PARTNER':
      return PartnerExampleImage;
    case 'LOCATION':
      return LocationExampleImage;
    case 'COMPONENT':
      return ComponentExampleImage;
    case 'COMPONENT_CHAIN':
      return ComponentChainExampleImage;
    default:
      return DocumentExampleImage;
  }
};
