import { HeaderColumn } from 'types/types';

export enum SitesTableColumnKeys {
  TITLE = 'title',
  OWNER = 'ownedBy',
  CREATOR = 'createdBy',
  MODIFIED_TIMESTAMP = 'modifiedTimestamp',
  ACTIONS = 'actions',
}

export const sitesHeaderColumns: HeaderColumn<SitesTableColumnKeys>[] = [
  { id: SitesTableColumnKeys.TITLE, headerName: 'Title', sortable: false },
  {
    id: SitesTableColumnKeys.OWNER,
    headerName: 'Owner',
    sortable: false,
  },
  {
    id: SitesTableColumnKeys.CREATOR,
    headerName: 'Creator',
    sortable: false,
  },
  {
    id: SitesTableColumnKeys.MODIFIED_TIMESTAMP,
    headerName: 'Last edited',
    sortable: false,
  },
  { id: SitesTableColumnKeys.ACTIONS, headerName: 'Actions', sortable: false, align: 'left' },
];
