import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Cog } from '@styled-icons/heroicons-solid/Cog';
import EnabledLanguages from 'components/Product/ProductTable/EnabledLanguages';
import { useDialog, useProductActions } from 'components/hooks';
import React, { FC } from 'react';
import { Product } from 'types/types';

interface IProductLanguageSettingsProps {
  product: Product;
}

const LanguagesTitle = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 900,
  marginRight: theme.spacing(1),
}));

const LanguagesTitleWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(1),
}));

const StyledCog = styled(Cog)(({ theme }) => ({
  '&:hover': {
    color: theme.palette.secondary.light,
    cursor: 'pointer',
  },
}));

const ProductLanguageSettings: FC<IProductLanguageSettingsProps> = ({ product }) => {
  const { id, languageConfig, publishedLanguages } = product;

  const { navigateToProductEditor } = useProductActions();
  const { openDialog } = useDialog();

  const handleLanguageSettingClick = () => {
    openDialog({
      type: 'LANGUAGE_SETTINGS',
      props: { productId: id },
    });
  };

  return (
    <>
      <LanguagesTitleWrapper>
        <LanguagesTitle variant="h5">Languages</LanguagesTitle>
        <StyledCog data-cy="language-form-toggle" size={16} onClick={handleLanguageSettingClick} />
      </LanguagesTitleWrapper>
      <EnabledLanguages
        languageConfig={languageConfig}
        publishedLanguages={publishedLanguages}
        extended
        navigateToProductEditor={config => navigateToProductEditor(id, config)}
      />
    </>
  );
};

export default ProductLanguageSettings;
