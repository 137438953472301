import { Box, styled } from '@mui/material';
import pageNotFoundIllustration from 'assets/img/page-not-found.png';
import notFoundIllustration from 'assets/img/transport-band.png';
import { ActionLink, ThemeButton, ThemeTypography } from 'designSystem';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

interface IButtonOptions {
  text: string;
  path: string;
  isExternalLink?: boolean;
}

interface INotFoundPageProps {
  title?: string;
  description?: string;
  is404?: boolean;
  buttonOptions?: {
    primary: IButtonOptions;
    secondary: IButtonOptions;
  };
}

const NotFoundContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
  width: '100%',
}));

const ImageWrapper = styled('div')(() => ({
  flex: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '50%',
  background: '#fff',
}));

const StyledImage = styled('img')(() => ({
  padding: 50,
  height: '55%',
}));

const ActionContainer = styled('div')(({ theme }) => ({
  background: theme.custom.colors.backgroundLight,
  height: '50%',
  flex: 1,
  display: 'flex',
  justifyContent: 'center',
  paddingTop: theme.spacing(10),
}));

const ButtonWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingTop: theme.spacing(3),
}));

const NotFoundPage: FC<INotFoundPageProps> = ({
  title = 'Page not found',
  description = 'Are you looking to login to the seedtrace platform?',
  is404 = true,
  buttonOptions = {
    primary: {
      text: 'Login',
      path: '/login',
    },
    secondary: {
      text: 'To website',
      path: 'https://seedtrace.org/',
      isExternalLink: true,
    },
  },
}) => {
  const navigate = useNavigate();

  const navigateToPage = (buttonOption: IButtonOptions) => {
    if (buttonOption.isExternalLink) {
      window.location.replace(buttonOption.path);
    } else {
      navigate(buttonOption.path);
    }
  };

  return (
    <NotFoundContainer>
      <ImageWrapper>
        <StyledImage
          alt="page not found illustration"
          src={is404 ? pageNotFoundIllustration : notFoundIllustration}
        />
      </ImageWrapper>

      <ActionContainer>
        <div>
          <div>
            <Box mb={1}>
              <ThemeTypography variant="TITLE_SMALL">{title}</ThemeTypography>
            </Box>

            <ThemeTypography variant="BODY_LARGE">{description}</ThemeTypography>
          </div>

          <ButtonWrapper>
            <Box mr={5}>
              <ThemeButton
                size="large"
                color="YELLOW"
                onClick={() => navigateToPage(buttonOptions.primary)}
              >
                {buttonOptions.primary.text}
              </ThemeButton>
            </Box>
            <ActionLink onClick={() => navigateToPage(buttonOptions.secondary)}>
              {buttonOptions.secondary.text}
            </ActionLink>
          </ButtonWrapper>
        </div>
      </ActionContainer>
    </NotFoundContainer>
  );
};

export default NotFoundPage;
