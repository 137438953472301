import { gql } from '@apollo/client';
import { FRONTEND_CONFIG_FRAGMENT } from 'graphql/fragments';

export const GET_FRONTEND_CONFIG = gql`
  query GetFrontendConfig {
    frontendConfig {
      ...frontendConfigValues
    }
  }
  ${FRONTEND_CONFIG_FRAGMENT}
`;
