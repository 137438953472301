import defaultShape from 'assets/img/qrGeneatator/pattern/default.png';
import connectHorizontal from 'assets/img/qrGeneatator/pattern/connect-horizontal.png';
import dots from 'assets/img/qrGeneatator/pattern/dots.png';
import rounded1 from 'assets/img/qrGeneatator/pattern/rounded-1.png';
import rounded2 from 'assets/img/qrGeneatator/pattern/rounded-2.png';
import square from 'assets/img/qrGeneatator/pattern/square.png';

const shapes = [
  {
    displayName: 'Default',
    value: null,
    img: defaultShape,
  },
  {
    displayName: 'Rounded 1',
    value: 'rounded-1',
    img: rounded1,
  },
  {
    displayName: 'Rounded 2',
    value: 'rounded-2',
    img: rounded2,
  },
  {
    displayName: 'Dots',
    value: 'dots',
    img: dots,
  },
  {
    displayName: 'Connect Horizontal',
    value: 'connect-horizontal',
    img: connectHorizontal,
  },
  {
    displayName: 'Square',
    value: 'square',
    img: square,
  },
];

export default shapes;
