import {
  FormControl,
  FormHelperText,
  InputAdornment,
  Menu,
  Paper,
  TextField,
  TextFieldVariants,
  styled,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { CalendarEvent } from '@styled-icons/bootstrap/CalendarEvent';
import moment from 'moment';
import React, { FC, useMemo, useRef, useState } from 'react';
import { Calendar } from 'react-date-range';

export interface IDatePickerProps {
  'data-cy'?: string;
  value: string | number | Date;

  /** Custom error message, this error message displayed when value is not valid JSON */
  errorMessage?: string;

  label?: string;
  placeholder?: string;

  /**
   * @default medium
   */
  size?: 'small' | 'medium';

  variant?: TextFieldVariants;

  disabled?: boolean;
  onChange: (formattedValue: string, date: Date) => void;
  onTouch?: () => void;
}

const DATE_FORMAT = 'YYYY-MM-DD';

const StyledPaper = styled(Paper)(({ theme }) => ({
  '& .MuiMenu-list': {
    padding: 0,
  },
}));

const StyledCalendar = styled(Calendar)(() => ({
  boxShadow: '0 1px 3px 1px rgba(0, 0, 0, 0.2)',
  display: 'flex',
}));

const Icon = styled(CalendarEvent)(() => ({
  cursor: 'pointer',
}));

const DatePicker: FC<IDatePickerProps> = ({
  label,
  placeholder,
  value,
  size = 'medium',
  variant = 'outlined',
  'data-cy': dataCy = 'date-picker',
  disabled,
  errorMessage,
  onChange,
  onTouch,
}) => {
  const anchorEl = useRef<HTMLDivElement>(null);
  const { palette } = useTheme();
  const [openDatePicker, setOpenDatePicker] = useState<boolean>(false);

  const formattedDateValue = useMemo(
    () => (value ? moment(value).format(DATE_FORMAT) : ''),
    [value]
  );

  const handleChange = (value: Date) => {
    onChange(moment(value).format(DATE_FORMAT), value);
    setOpenDatePicker(false);
  };

  const handleTextFieldClick = () => {
    if (!disabled) {
      setOpenDatePicker(true);
    }
  };

  const handleClose = () => {
    onTouch?.();
    setOpenDatePicker(false);
  };

  return (
    <FormControl>
      <TextField
        ref={anchorEl}
        label={label}
        size={size}
        value={formattedDateValue}
        variant={variant}
        placeholder={placeholder}
        fullWidth
        disabled={disabled}
        error={!!errorMessage}
        data-cy={dataCy}
        InputLabelProps={{
          shrink: value || openDatePicker ? true : undefined,
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Icon size={size === 'medium' ? 20 : 12} />
            </InputAdornment>
          ),
        }}
        onClick={handleTextFieldClick}
      />

      <Menu
        anchorEl={anchorEl.current}
        open={openDatePicker}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        PaperProps={{ component: StyledPaper }}
        onClose={handleClose}
      >
        <StyledCalendar
          fixedHeight
          color={palette.primary.main}
          date={value ? new Date(value) : new Date()}
          onChange={handleChange}
        />
      </Menu>

      {errorMessage && <FormHelperText error>{errorMessage}</FormHelperText>}
    </FormControl>
  );
};

export default DatePicker;
