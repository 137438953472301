import { useAuthorization } from 'components/hooks';
import { FeatureFlag } from 'components/hooks/useFeatureFlags';
import React, { FC, PropsWithChildren, ReactElement } from 'react';

type ICanProps = { no: () => ReactElement } & ({ featureFlag: FeatureFlag } | { rule: string }) &
  PropsWithChildren;

const Can: FC<ICanProps> = ({ children, no, ...props }) => {
  const { authorized } = useAuthorization(props);
  if (!authorized) return no();

  return <>{children}</>;
};

export default Can;
