import React from 'react';
import PropTypes from 'prop-types';
import { TableCell, Tooltip, Box } from '@mui/material';
import Moment from 'react-moment';
import { CertificateIcon } from 'assets/img/menu';
import { LevelPill } from '../ClaimShared';
import { ClaimTitleAndStatus } from 'components/ImpactClaims/ClaimShared';
import {
  StyledIconButton,
  IconContainer,
  Date,
  StyledCell,
  TableRow,
  OpacityContainer,
} from './styles';
import { impactLevelCategories } from '../constants/impactLevelCategories';
import { ImpactClaimStatus } from 'types/impactClaim.types';

const ImpactClaimTableRow = ({
  impactClaim,
  actions,
  onSelect,
  titleWidth = 450,
  hasShadow = true,
}) => {
  const {
    id,
    createdTimestamp,
    attachmentCount,
    impactCatalogItem,
    status,
    isInvalid,
    impactLevel,
    impactLevelTitle,
  } = impactClaim;
  const labelId = `impact-claim-table-${id}`;
  const claimLevel = impactLevelCategories[impactLevel];
  const transparent = status === ImpactClaimStatus.REQUESTED;

  return (
    <TableRow
      data-cy="impact-claim-table-item"
      data-cy-id={id}
      hover
      transparent={transparent}
      hasShadow={hasShadow}
      isInvalid={isInvalid}
      key={id}
      onClick={event => {
        event.stopPropagation();
        onSelect(impactClaim);
      }}
    >
      <StyledCell id={labelId} scope="row" width={titleWidth}>
        <ClaimTitleAndStatus
          impactCatalogItem={impactCatalogItem}
          transparent={transparent}
          claimStatus={status}
          showSeeComments
        />
      </StyledCell>
      <TableCell width={300} id={labelId} scope="row">
        <OpacityContainer transparent={transparent}>
          <LevelPill claimLevel={claimLevel} title={impactLevelTitle} />
        </OpacityContainer>
      </TableCell>
      <TableCell width={200} id={labelId} scope="row">
        <Box display="flex">
          <CertificateIcon />
          <Box pl={1}>
            {attachmentCount} proof document
            {attachmentCount !== 1 ? 's' : ''}
          </Box>
        </Box>
      </TableCell>
      <TableCell id={labelId} scope="row">
        <Date variant="subtitle2">
          <Moment format="LL">{createdTimestamp}</Moment>
        </Date>
      </TableCell>
      <TableCell width={50} id={labelId} scope="row" align="right">
        <IconContainer>
          {actions.map((action, index) => {
            return (
              <Tooltip key={index} title={action.tooltip}>
                <StyledIconButton
                  data-cy={action.dataCy}
                  onClick={event => {
                    event.stopPropagation();
                    action.onClick(impactClaim);
                  }}
                >
                  {action.icon}
                </StyledIconButton>
              </Tooltip>
            );
          })}
        </IconContainer>
      </TableCell>
    </TableRow>
  );
};

ImpactClaimTableRow.propTypes = {
  impactClaim: PropTypes.object.isRequired,
  actions: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  titleWidth: PropTypes.number,
  hasShadow: PropTypes.bool,
};

export default ImpactClaimTableRow;
