import { Box, Grid, styled, Tooltip, Typography } from '@mui/material';
import DocumentIntroTitle from 'components/DocumentLibrary/DocumentIntroTitle';
import { Loader } from 'components/Forms';
import { useDialog } from 'components/hooks';
import { usePartnerRequest } from 'components/Partners/hooks';
import usePartnerRequestActions from 'components/Partners/PartnerRequests/usePartnerRequestActions';
import {
  CompanyNameLogo,
  ErrorState,
  PageContainer,
  PageSubTitle,
  PageTitle,
} from 'components/Structure';
import FlexBox from 'components/Structure/FlexBox';
import HtmlField from 'components/Structure/HTMLField';
import { useCompanyPlanContext } from 'contexts/CompanyPlanContext';
import { CardContainer, ItemCardSmall, ThemeButton, ThemeTypography } from 'designSystem';
import ItemProgressTitle from 'designSystem/DataDisplay/ItemProgressTitle/ItemProgressTitle';
import Logs from 'designSystem/DataDisplay/Logs/Logs';
import Icon from 'designSystem/Primitives/Icon/Icon';
import truncate from 'lodash/truncate';
import React, { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { booleanish, Booleanish } from 'types/booleanish.types';
import { CustomerPlan } from 'types/company.types';
import { AvailableSizes } from 'types/enums';
import { PartnerRequestStatus, PartnerRequestStatusType, RequestType } from 'types/partner.types';
import { RouteParamsWithId } from 'types/router.types';
import { convertHexToRGBarray } from 'utils';
import {
  REQUEST_LOG_ACTION_TITLES,
  REQUEST_STATUS_CLIENT,
  REQUEST_STATUS_COLOR_MAPPING,
} from 'utils/requestStatus.utils';

const Status = styled(Typography)<{ status: PartnerRequestStatusType }>(({ status }) => ({
  fontSize: 13,
  marginRight: 8,
  textTransform: 'uppercase',
  color: REQUEST_STATUS_COLOR_MAPPING[status],
}));

const RequestItem = styled(Box)<{
  transparent?: booleanish;
}>(({ theme, transparent }) => ({
  width: '100%',
  backgroundColor: '#fff',
  padding: theme.spacing(1, 2),
  borderTopLeftRadius: 4,
  borderTopRightRadius: 4,

  ...(transparent === 'true'
    ? {
        boxShadow: 'none',
        border: `1px dashed rgba(${convertHexToRGBarray(
          theme.custom.colors.blueFrost
        )}, 0.4) !important`,
      }
    : {}),
}));

const MessageBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  backgroundColor: theme.custom.themeColors.grayScale[20],
  padding: theme.spacing(2),
  borderBottomLeftRadius: 4,
  borderBottomRightRadius: 4,
}));

const MessageTitle = styled(ThemeTypography)(({ theme }) => ({
  whiteSpace: 'nowrap',
}));

const LogContainer = styled(Box)(({ theme }) => ({
  backgroundColor: '#fff',
  borderRadius: 6,
  padding: theme.spacing(2),
  border: `1px solid ${theme.custom.themeColors.grayScale[10]}`,
}));

const ItemTitle = styled(ThemeTypography)(() => ({
  textTransform: 'uppercase',
  letterSpacing: 1.5,
}));

const DismissedContainer = styled(Box)(({ theme }) => ({
  backgroundColor: '#fff',
  padding: theme.spacing(2.5),
  borderRadius: 6,
}));

const DismissedMessage = styled(Box)(({ theme }) => ({
  display: 'flex',
  backgroundColor: theme.custom.themeColors.grayScale[20],
  padding: theme.spacing(1),
  borderRadius: 6,
}));

const RequestItemTitle = styled(Box)<{
  transparent?: booleanish;
}>(({ transparent }) => ({
  opacity: transparent === 'true' ? 0.4 : 1,
}));

const PartnerRequestOverview = () => {
  const { error, loading, partnerRequest } = usePartnerRequest();
  const { handleDeletePartnerRequest } = usePartnerRequestActions();
  const { openDialog } = useDialog();
  const navigate = useNavigate();
  const { id } = useParams<RouteParamsWithId>();
  const { plan } = useCompanyPlanContext();
  const isPartner = plan === CustomerPlan.FREEMIUM;

  const isRequested =
    partnerRequest?.requestStatus &&
    PartnerRequestStatus[partnerRequest.requestStatus] === PartnerRequestStatus.REQUESTED;

  const isDeleted =
    partnerRequest?.requestStatus &&
    PartnerRequestStatus[partnerRequest?.requestStatus] === PartnerRequestStatus.DELETED;

  const isDenied =
    partnerRequest?.requestStatus &&
    PartnerRequestStatus[partnerRequest?.requestStatus] === PartnerRequestStatus.DENIED;

  const isAccepted =
    partnerRequest?.requestStatus &&
    PartnerRequestStatus[partnerRequest?.requestStatus] === PartnerRequestStatus.PROVIDED_COMPLETED;

  const isProvided =
    partnerRequest?.requestStatus &&
    PartnerRequestStatus[partnerRequest?.requestStatus] === PartnerRequestStatus.PROVIDED;

  const isEdited =
    partnerRequest?.requestStatus &&
    PartnerRequestStatus[partnerRequest?.requestStatus] === PartnerRequestStatus.EDITED;

  const displayChain =
    partnerRequest?.requestType === RequestType.CHAIN && (isProvided || isAccepted);

  const logs = useMemo(() => {
    if (!partnerRequest) return [];
    return [...partnerRequest.logs].reverse().map(log => ({
      title: REQUEST_LOG_ACTION_TITLES[log.action],
      date: log.timestamp,
      logo: log.company.logo?.url,
      author: `${log.user.firstName} ${log.user.lastName}`,
      completed: log.action === 'COMPLETED',
    }));
  }, [partnerRequest]);

  const handleReviewAndApprove = () => {
    if (!partnerRequest) return;

    openDialog({
      type: 'REVIEW_DOCUMENT_REQUEST',
      props: {
        request: partnerRequest,
      },
    });
  };

  const handleEditRequest = () => {
    if (!partnerRequest) return;

    openDialog({
      type: 'PARTNER_REQUEST',
      props: {
        requestedToCompany: partnerRequest.requestedToCompany,
        initialRequest: partnerRequest,
      },
    });
  };

  const handleDeleteRequest = () => {
    if (!partnerRequest) return;

    handleDeletePartnerRequest(partnerRequest.id);
  };

  const handleClickFulfillRequestButton = () => {
    if (!partnerRequest) return;

    openDialog({
      type: 'REVIEW_CHAIN_REQUEST',
      props: { request: partnerRequest },
    });
  };

  const handleNavigateToChain = () => {
    navigate(`/component-chains/${partnerRequest?.chainId}/editor`);
  };

  if (error) return <ErrorState />;

  if (loading || !partnerRequest) return <Loader />;

  return (
    <PageContainer>
      <PageTitle
        title="Partner requests"
        goBackLabel="All Requests"
        goBackUrl={`/partners/${id}/requests`}
      />
      <Box mt={4}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={8}>
            <RequestItem
              display="flex"
              justifyContent="space-between"
              transparent={Booleanish(isRequested || isEdited)}
              data-cy="request-item"
            >
              <RequestItemTitle
                display="flex"
                alignContent="center"
                transparent={Booleanish(isRequested || isEdited)}
              >
                <FlexBox mr={1.5}>
                  <Icon
                    name={
                      REQUEST_STATUS_CLIENT[partnerRequest.requestType][
                        partnerRequest.requestStatus
                      ].iconName
                    }
                    size="xx-large"
                  />
                </FlexBox>
                <Box>
                  <Box display="flex" alignItems="center">
                    <Tooltip title={partnerRequest.requestTitle || ''}>
                      <ThemeTypography variant="ITEM_TITLE">
                        {truncate(partnerRequest.requestTitle, {
                          length: 45,
                          separator: '',
                        })}
                      </ThemeTypography>
                    </Tooltip>
                  </Box>
                  <Box display="flex" alignItems="center">
                    <Status variant="body2" status={partnerRequest.requestStatus}>
                      {
                        REQUEST_STATUS_CLIENT[partnerRequest.requestType][
                          partnerRequest.requestStatus
                        ].statusTitle
                      }
                    </Status>
                  </Box>
                </Box>
              </RequestItemTitle>
              <Box display="flex">
                {!isDenied && !isDeleted && !isAccepted && (
                  <ThemeButton
                    size="small"
                    color="BLUE_ICE"
                    onClick={handleEditRequest}
                    startIcon={<Icon name="edit" size="small" color="gray-60" />}
                  >
                    Edit
                  </ThemeButton>
                )}
                {isRequested && (
                  <Box ml={1} display="flex" alignItems="center">
                    <ThemeButton
                      size="small"
                      color="BLUE_ICE"
                      onClick={handleDeleteRequest}
                      startIcon={<Icon name="delete" size="small" color="gray-60" />}
                    >
                      Delete
                    </ThemeButton>
                  </Box>
                )}
              </Box>
            </RequestItem>
            <MessageBox>
              <MessageTitle variant="BODY_LARGE_BOLD">Your message:&nbsp;</MessageTitle>
              <HtmlField>{partnerRequest.requestMessage}</HtmlField>
            </MessageBox>
            {partnerRequest.requestStatus === 'DENIED' && (
              <DismissedContainer mt={3} data-cy="dismissed-container">
                <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                  <Box display="flex" alignItems="center">
                    <CompanyNameLogo
                      size={AvailableSizes.MEDIUM}
                      companyName={partnerRequest.requestedToCompany.name}
                    />
                    <ThemeTypography variant="BODY_LARGE_BOLD">
                      &nbsp;has dismissed your request
                    </ThemeTypography>
                  </Box>
                  <ThemeButton
                    variant="contained"
                    size="medium"
                    startIcon={<Icon name="edit" size="medium" />}
                    onClick={handleEditRequest}
                  >
                    Respond & edit request
                  </ThemeButton>
                </Box>
                <DismissedMessage mt={2}>
                  <MessageTitle variant="BODY_LARGE_BOLD">
                    {partnerRequest.requestedToCompany.name}:&nbsp;
                  </MessageTitle>
                  <HtmlField>{partnerRequest?.requestReplyMessage || ''}</HtmlField>
                </DismissedMessage>
              </DismissedContainer>
            )}
            {partnerRequest.document && (
              <Box mt={4}>
                <Box display="flex" alignItems="center" mb={1.5}>
                  {partnerRequest.requestStatus === 'PROVIDED' && (
                    <>
                      <CompanyNameLogo
                        size={AvailableSizes.MEDIUM}
                        companyName={partnerRequest.requestedToCompany.name}
                      />
                      <ThemeTypography variant="BODY_LARGE_BOLD">
                        &nbsp;has uploaded a new document
                      </ThemeTypography>
                    </>
                  )}

                  {partnerRequest.requestStatus === 'PROVIDED_COMPLETED' && (
                    <ItemTitle variant="TITLE_EXTRA_SMALL">Accepted document</ItemTitle>
                  )}
                </Box>

                <ItemCardSmall>
                  <Box display="flex" width="100%" justifyContent="space-between">
                    <DocumentIntroTitle document={partnerRequest.document} showOpenInNewTab />
                    {!isPartner && (
                      <>
                        {partnerRequest.requestStatus === 'PROVIDED_COMPLETED' ? (
                          <ThemeButton
                            variant="contained"
                            size="medium"
                            startIcon={<Icon name="plus" size="small" />}
                            onClick={() => openDialog({ type: 'CREATE_IMPACT_CLAIM' })}
                            data-cy="add-impact-claim-btn"
                          >
                            Add impact claim
                          </ThemeButton>
                        ) : (
                          <ThemeButton
                            variant="contained"
                            size="medium"
                            startIcon={<Icon name="document-request-pending" size="large" />}
                            onClick={handleReviewAndApprove}
                            data-cy="review-approve-btn"
                          >
                            Review & approve
                          </ThemeButton>
                        )}
                      </>
                    )}
                  </Box>
                </ItemCardSmall>
                {!isPartner && (
                  <Box mt={2} display="flex" alignItems="center">
                    <Box mr={1}>
                      <Icon name="info" size="medium" />
                    </Box>
                    <ThemeTypography variant="BODY_LARGE">
                      Uploaded documents can also be managed in your document library under “Shared
                      with me”
                    </ThemeTypography>
                  </Box>
                )}
              </Box>
            )}
            {!!partnerRequest.previouslyUploadedDocuments?.length && (
              <Box mt={2}>
                <PageSubTitle title="Previously uploaded documents" />
                {partnerRequest.previouslyUploadedDocuments.map(previousDocument => (
                  <ItemCardSmall key={previousDocument.id} disabled>
                    <DocumentIntroTitle document={previousDocument} showOpenInNewTab />
                  </ItemCardSmall>
                ))}
              </Box>
            )}
            {displayChain && (
              <Box mt={4}>
                <Box width="100%" display="flex" alignItems="center">
                  <CompanyNameLogo
                    size={AvailableSizes.MEDIUM}
                    companyName={partnerRequest.requestedToCompany.name}
                  />
                  <ThemeTypography variant="BODY_LARGE_BOLD">
                    &nbsp;has finished this chain mapping
                  </ThemeTypography>
                </Box>
                <CardContainer padding={1} mt={2}>
                  <Box display="flex" justifyContent="space-between" position="relative">
                    <Box display="flex" width="100%" justifyContent="space-between">
                      <ItemProgressTitle
                        title={partnerRequest?.chain?.title || ''}
                        imageUrl={partnerRequest?.chain?.image?.url}
                        taskCompleted={partnerRequest?.chain?.chainTasksDetails.completed || 0}
                        taskTotal={partnerRequest?.chain?.chainTasksDetails.total || 0}
                        backupIcon={
                          <Icon name="component-chain-image" size="xx-large" color="blue-ice" />
                        }
                      />
                      {PartnerRequestStatus[partnerRequest.requestStatus] ===
                      PartnerRequestStatus.PROVIDED_COMPLETED ? (
                        <ThemeButton
                          variant="contained"
                          startIcon={<Icon name="chain-request-manage" />}
                          onClick={handleNavigateToChain}
                        >
                          Go to chain
                        </ThemeButton>
                      ) : (
                        <ThemeButton
                          variant="contained"
                          startIcon={<Icon name="chain-request-manage" />}
                          onClick={handleClickFulfillRequestButton}
                        >
                          Review and approve
                        </ThemeButton>
                      )}
                    </Box>
                  </Box>
                </CardContainer>
                {!isPartner && (
                  <Box mt={2} display="flex" alignItems="center">
                    <Box mr={1}>
                      <Icon name="info" size="medium" />
                    </Box>
                    <ThemeTypography variant="BODY_LARGE">
                      Mapped partner chains can also be managed in your <b>Components</b>, or in
                      <b> Partners </b>
                      under “Partner components”.
                    </ThemeTypography>
                  </Box>
                )}
              </Box>
            )}
          </Grid>
          {logs.length > 0 && (
            <Grid item xs={12} sm={4}>
              <LogContainer>
                <Logs logs={logs} data-cy="partner-logs" />
              </LogContainer>
            </Grid>
          )}
          <Grid item xs={12} sm={4}></Grid>
        </Grid>
      </Box>
    </PageContainer>
  );
};

export default PartnerRequestOverview;
