import { Box, Collapse, TableBody, TableCell } from '@mui/material';
import { styled } from '@mui/material/styles';
import { StyledTable, StyledTableRow } from 'components/TableBase';
import PropTypes from 'prop-types';
import React from 'react';
import ImpactClaimTableRow from './ImpactClaimTableRow';

const Table = styled(StyledTable)(() => ({
  minWidth: 1100,
  borderSpacing: 0,
}));

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const InnerClaimTableRow = styled(({ display, ...props }) => <StyledTableRow {...props} />)(
  ({ display }) => ({
    backgroundColor: 'transparent',
    boxShadow: 'none',
    display: display ? 'table-row' : 'none',

    '&:hover': {
      background: 'transparent !important',
      boxShadow: 'none',
      cursor: 'pointer',
    },
  })
);

const InnerTableRow = ({ impactClaim, onSelect, actions, open }) => {
  return (
    <InnerClaimTableRow
      data-cy="impact-claim-table-item"
      data-cy-id={impactClaim?.id}
      hover
      key={impactClaim?.id}
      display={open}
      onClick={event => {
        event.stopPropagation();
        onSelect(impactClaim);
      }}
    >
      <TableCell
        style={{
          paddingBottom: 0,
          paddingTop: 0,
        }}
        colSpan={6}
      >
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box
            style={{
              margin: '0 -12px 0 8px',
            }}
          >
            <Table size="small">
              <TableBody>
                <ImpactClaimTableRow
                  actions={actions}
                  onSelect={onSelect}
                  impactClaim={impactClaim}
                  titleWidth={430}
                />
              </TableBody>
            </Table>
          </Box>
        </Collapse>
      </TableCell>
    </InnerClaimTableRow>
  );
};

InnerTableRow.propTypes = {
  impactClaim: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
  actions: PropTypes.array,
  open: PropTypes.bool,
};

export default InnerTableRow;
