import { Table, TableBody, TableCell, TableHead, TableRow, styled, Box } from '@mui/material';
import React, { FC } from 'react';
import { GeoCBatch, RiskLevel } from 'types/compliance.types';
import RiskBadge from 'designSystem/DataDisplay/RiskBadge/RiskBadge';
import Moment from 'react-moment';

interface IBatchTableProps {
  batches: GeoCBatch[];
}

const StyledTable = styled(Table)(({ theme }) => ({
  '& .MuiTableCell-head': {
    fontSize: 12,
    fontWeight: 400,
    color: theme.custom.themeColors.grayScale[40],
    textTransform: 'uppercase',
  },
  '& .MuiTableCell-root': {
    fontSize: 11,
    border: `none`,
    padding: '0 8px 5px 0',
  },
}));

const BatchTable: FC<IBatchTableProps> = ({ batches }) => {
  return (
    <StyledTable size="small">
      <TableHead>
        <TableRow>
          <TableCell valign="top">Batch</TableCell>
          <TableCell valign="top">Harvest Date</TableCell>
          <TableCell valign="top">Quantity</TableCell>
          <TableCell valign="top">Deforestation</TableCell>
          {/* <TableCell valign="top">Forest degradation</TableCell> */}
        </TableRow>
      </TableHead>
      <TableBody>
        {batches.map(batch => (
          <TableRow key={batch.id}>
            <TableCell valign="top">{batch.id}</TableCell>
            <TableCell valign="top">
              <Moment format="LL">{batch.harvestDate}</Moment>
            </TableCell>
            <TableCell valign="top">{batch.weight} kg</TableCell>
            <TableCell valign="top">
              <Box width="fit-content">
                <RiskBadge riskLevel={RiskLevel[batch.riskStatus]} />
              </Box>
            </TableCell>
            {/* <TableCell valign="top">
              <Box width="fit-content">
                <RiskBadge riskLevel={RiskLevel[batch.riskStatus]} />
              </Box>
            </TableCell> */}
          </TableRow>
        ))}
      </TableBody>
    </StyledTable>
  );
};

export default BatchTable;
