import { Box, Switch, Typography, useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Tour } from '@styled-icons/material/Tour';
import { useEditorState } from 'components/Product/MultiStep/hooks';
import { FlexBox } from 'components/Structure';
import { useOnboardingTour } from 'components/hooks';
import { fullPlanSteps } from 'constants/onboarding/onboardingSteps/editor';
import { usePublishProductContext } from 'contexts/PublishProductContext';
import { InfoTooltip, ThemeButton } from 'designSystem';
import React, { FC } from 'react';
import { AvailableSizes } from 'types/enums';

const SavedMessage = styled('div')(({ theme }) => ({
  fontSize: 11,
  fontWeight: 400,
  color: theme.palette.grey[700],
}));

const GlobalControls = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(1),
  right: 0,
}));

const FormControl = styled(Box)(() => ({
  display: 'flex',
  position: 'relative',
  justifyContent: 'center',
  alignItems: 'center',
  padding: 12,
  background: '#fff',
  borderRadius: 6,
  minWidth: 140,
  height: 36,
}));

const StyledSwitch = styled(Switch)(() => ({
  marginLeft: -10,
}));

const ReviewLabel = styled(Typography)(({ theme }) => ({
  fontSize: 11,
  fontWeight: 400,
  color: theme.palette.grey[700],
}));

const StyledTooltip = styled(InfoTooltip)(({ theme }) => ({
  marginTop: -2,
  color: theme.palette.grey[400],
}));

const TourContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row-reverse',
  marginTop: theme.spacing(1),
}));

const EditorHeaderControls: FC = () => {
  const { handleClickPublish, handleClickUnpublish } = usePublishProductContext();
  const { startTour } = useOnboardingTour();
  const { showValidation, setShowValidation, savedMessage, isCurrentLanguagePublished } =
    useEditorState();
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClickOnboardingTour = () => {
    startTour({
      tourSteps: fullPlanSteps,
      amplitudeEvent: 'DID_ONBOARDING_TOUR_EDITOR',
      onCompleted: () => null,
      firstStepButtonText: `Let's do it!`,
    });
  };

  return (
    <GlobalControls>
      <FlexBox>
        <Box display="flex" alignItems="center" mr={smallScreen ? 1 : 2}>
          <FormControl mr={smallScreen ? 1 : 2} data-tut="onboarding_tour__review">
            <StyledSwitch
              checked={showValidation}
              onChange={() => setShowValidation(!showValidation)}
              data-cy="field-validation-switch"
            />
            <Box display="flex" alignItems="center">
              <ReviewLabel>Review Mode</ReviewLabel>
              <StyledTooltip
                size={AvailableSizes.SMALL}
                text="Activate review mode to see what content is missing in order to publish your product"
              />
            </Box>
          </FormControl>
          <FormControl data-tut="onboarding_tour__save">
            <SavedMessage>{savedMessage}</SavedMessage>
          </FormControl>
        </Box>
        {isCurrentLanguagePublished && (
          <>
            <ThemeButton
              color="WHITE"
              data-cy="editor-unpublish-button"
              size="large"
              onClick={handleClickUnpublish}
            >
              Unpublish {smallScreen ? '' : ' page'}
            </ThemeButton>
            <Box mr={2} />
          </>
        )}
        <ThemeButton
          color="YELLOW"
          size="large"
          onClick={handleClickPublish}
          data-cy="editor-publish-button"
          data-tut="onboarding_tour__publish"
        >
          Publish
          {smallScreen ? '' : ' changes'}
        </ThemeButton>
      </FlexBox>

      <TourContainer>
        <ThemeButton
          color="WHITE"
          data-cy="restart-tour-button"
          size="small"
          onClick={handleClickOnboardingTour}
          startIcon={<Tour size={16} />}
        >
          Restart guided tour
        </ThemeButton>
      </TourContainer>
    </GlobalControls>
  );
};

export default EditorHeaderControls;
