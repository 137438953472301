import { useMutation } from '@apollo/client';
import { Dialog, DialogContent } from '@mui/material';
import { styled } from '@mui/material/styles';
import { AlertDialogHeader } from 'components/Structure';
import { useLogEvent, useMessages } from 'components/hooks';
import { UPDATE_PRODUCT_SLUG } from 'graphql/mutations';
import React, { FC } from 'react';
import { IDefaultDialogProps } from 'types/dialog.types';
import { Product } from 'types/types';
import EditSlugForm from './EditSlugForm';

interface IEditSlugDialogProps extends IDefaultDialogProps {
  productId: string;
  productSlug: string;
}

const StyledDialog = styled(Dialog)(({ theme }) => ({
  padding: theme.spacing(5, 2),

  '& .MuiDialog-paperWidthSm': {
    maxWidth: 650,
  },
}));

const StyledContent = styled(DialogContent)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const EditSlugDialog: FC<IEditSlugDialogProps> = ({ open, productId, productSlug, onClose }) => {
  const { logEvent } = useLogEvent();
  const { setErrorMessage, setSuccessMessage } = useMessages();

  const [updateProductSlug] = useMutation(UPDATE_PRODUCT_SLUG, {
    onCompleted: ({
      updateProductSlug: {
        product: { id },
      },
    }) => {
      onClose?.();
      logEvent('UPDATE_PRODUCT_SLUG', {
        productId: id,
      });
      setSuccessMessage('URL successfully updated!');
    },
    onError: () => {
      setErrorMessage('Something went wrong updating your product URL. Please try again!');
    },
  });

  const onUpdateSlug = ({ slug }: Product) => {
    return updateProductSlug({
      variables: {
        id: productId,
        input: {
          slug: slug.toLowerCase(),
        },
      },
    });
  };

  return (
    <StyledDialog open={!!open} onClose={onClose}>
      <AlertDialogHeader
        title="Update product URL"
        alertMessage="By changing the URL, your product will no longer be available via the old URL"
        onClose={onClose}
      />
      <StyledContent>
        <EditSlugForm onSubmit={onUpdateSlug} slug={productSlug} onCancel={onClose} />
      </StyledContent>
    </StyledDialog>
  );
};

export default EditSlugDialog;
