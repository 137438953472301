import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Edit } from '@styled-icons/boxicons-solid/Edit';
import { useConfig } from 'components/hooks';
import { CatalogItemSdgs, RequirementList } from 'components/ImpactClaims/ClaimCatalog';
import { OtherClaimInformation } from 'components/ImpactClaims/ClaimShared';
import { SummaryBox } from 'components/SliderForm';
import { ThemeTypography, DialogTitle, ThemeButton } from 'designSystem';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { useDialog } from 'components/hooks';
import { ReactComponent as UnknownDocument } from 'assets/img/icons/unknown-document.svg';
import { ProofListItem } from 'components/Partners/PartnerRequests';
import DocumentUpload from 'components/DocumentLibrary/DocumentUpload';
import { UploadProvider } from 'contexts/UploadContext';

export const RequestIcon = styled(UnknownDocument)(() => ({
  marginTop: 2,
  width: 12,
}));

const Container = styled('div')(() => ({
  display: 'flex',
  height: '100%',
  width: '100%',
}));

const Column = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
}));

const UploadColumn = styled(Column)(({ theme }) => ({
  width: '50%',
  height: '100%',
  background: theme.custom.colors.backgroundLight,
  padding: theme.spacing(4, 3),
  overflow: 'auto',
}));

const DetailsColumn = styled(Column)(({ theme }) => ({
  width: '50%',
  height: '100%',
  overflow: 'auto',
  padding: theme.spacing(2, 4, 3),
}));

const UploadContainer = styled('div')(({ theme }) => ({
  background: '#fff',
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.custom.shadows[1],
}));

const DocumentRequest = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
  background: '#fff',
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.custom.shadows[1],
}));

const ProofSlide = ({
  title,
  selectedImpactClaim,
  documents,
  handleDocumentsChange,
  deletedDocuments,
  handleDelete,
  selectedImpactLevel,
  impactLevelItem,
  documentRequests,
  setDocumentRequests,
  handleDeleteRequest,
}) => {
  const { openDialog } = useDialog();
  // Use the impact claim or as the selectedItem
  const selectedItem = selectedImpactClaim;
  const { claimCategories } = useConfig();
  const iconPath = claimCategories[selectedImpactClaim.category.type].icon;

  return (
    <UploadProvider>
      <Container>
        <DetailsColumn>
          <Box mb={2} ml={-2}>
            <DialogTitle icon={<Edit size={16} />}>{title}</DialogTitle>
          </Box>

          <SummaryBox
            title={selectedItem.title}
            imageUrl={selectedItem?.iconUrl || iconPath}
            variant={selectedItem?.iconUrl ? 'IMAGE' : 'BACKGROUND_ICON'}
            body={selectedItem?.definition || selectedItem?.description}
            levelPillOptions={{
              level: selectedImpactLevel,
              title: impactLevelItem,
            }}
            titleTestId="catalog-item-summary-title"
          />

          {selectedItem && selectedImpactClaim && (
            <Fragment>
              <Box mt={2}>
                <Box mb={2}>
                  <ThemeTypography variant="BODY_LARGE_BOLD">Acceptance criteria</ThemeTypography>
                </Box>
                <RequirementList items={selectedItem?.acceptanceCriteria || []} />
              </Box>
              <Box mt={2}>
                <Box mb={2}>
                  <ThemeTypography variant="BODY_LARGE_BOLD">
                    Documentation requirements
                  </ThemeTypography>
                </Box>
                <RequirementList items={selectedItem?.docRequirements || []} />
              </Box>
              {selectedItem?.sustainableDevGoals?.length ? (
                <Box mt={2} mb={1}>
                  <CatalogItemSdgs sdgs={selectedItem?.sustainableDevGoals} />
                </Box>
              ) : null}
              {selectedItem?.thirdParties?.length || selectedItem?.links?.length ? (
                <OtherClaimInformation
                  links={selectedItem?.links || []}
                  thirdParties={selectedItem?.thirdParties || []}
                />
              ) : null}
            </Fragment>
          )}
        </DetailsColumn>

        <UploadColumn>
          <UploadContainer>
            <DocumentUpload
              title="Proof documents"
              handleDocumentsChange={handleDocumentsChange}
              documents={documents}
              deletedDocuments={deletedDocuments}
              handleDelete={handleDelete}
              displaySelector
              data-cy="image-upload"
            />
          </UploadContainer>
          <DocumentRequest>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <ThemeTypography variant="BODY_LARGE_BOLD">Request Document</ThemeTypography>
              <ThemeButton
                onClick={() =>
                  openDialog({
                    type: 'PARTNER_REQUEST',
                    props: {
                      onGetCreatedRequest: newPartnerRequest => {
                        setDocumentRequests([...documentRequests, newPartnerRequest]);
                      },
                    },
                  })
                }
                color="BLUE_ICE"
                data-cy="request-document-btn"
                startIcon={<RequestIcon />}
              >
                Request from partner
              </ThemeButton>
            </Box>
            {documentRequests.length > 0 &&
              documentRequests.map(request => (
                <Box mt={1.5} key={request.id}>
                  <ProofListItem
                    partnerRequest={request}
                    onDelete={() => handleDeleteRequest(request.id)}
                  />
                </Box>
              ))}
          </DocumentRequest>
        </UploadColumn>
      </Container>
    </UploadProvider>
  );
};

ProofSlide.propTypes = {
  title: PropTypes.string.isRequired,
  handleDocumentsChange: PropTypes.func.isRequired,
  selectedImpactLevel: PropTypes.string,
  impactLevelItem: PropTypes.string,
};

export default ProofSlide;
