import { useMutation, useQuery } from '@apollo/client';
import { Trash } from '@styled-icons/bootstrap/Trash';
import { Edit } from '@styled-icons/boxicons-solid/Edit';
import { DELETE_IMPACT_CLAIMS } from 'graphql/mutations';
import { GET_IMPACT_CATALOG, GET_IMPACT_CLAIMS } from 'graphql/queries';
import filter from 'lodash/filter';
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { StringParam, useQueryParam } from 'use-query-params';
import { filterImpactClaims, getImpactClaimGroups } from 'utils';
import { useConfig, useDialog, useMessages } from './index.ts';
import { useLogEvent } from './useLogEvent';

const useImpactClaims = () => {
  const { logEvent } = useLogEvent();
  const {
    data: { impactClaims } = {},
    loading: loadingImpactClaims,
    error: errorImpactClaims,
  } = useQuery(GET_IMPACT_CLAIMS);
  const {
    data: impactCatalogItems = {},
    loading: loadingCatalog,
    error: errorCatalog,
  } = useQuery(GET_IMPACT_CATALOG);
  const { appQueryParams } = useConfig();
  const { openDialog } = useDialog();
  const navigate = useNavigate();
  const [searchTerm = '', setSearchTerm] = useQueryParam(appQueryParams.query, StringParam);
  const hasFilterActive = !!searchTerm;
  const [deletableClaim, setDeletableClaim] = useState(null);
  const { setSuccessMessage, setErrorMessage } = useMessages();

  const handleOpenCreate = () => {
    logEvent('CLICK_ADD_CLAIM');
    openDialog({ type: 'CREATE_IMPACT_CLAIM' });
  };
  const handleOpenEdit = claim => openDialog({ type: 'EDIT_IMPACT_CLAIM', props: { claim } });
  const handleClickClaimRow = claim => navigate(`/impact-claims/${claim.id}`);

  const [deleteImpactClaims] = useMutation(DELETE_IMPACT_CLAIMS, {
    onCompleted: () => setSuccessMessage('Impact claim was successfully removed'),
    onError: () => setErrorMessage('There was an error removing your impact claim'),
    update: (
      cache,
      {
        data: {
          deleteImpactClaims: { ids: removedClaimIds },
        },
      }
    ) => {
      const result = cache.readQuery({
        query: GET_IMPACT_CLAIMS,
      });
      const impactClaims = result?.impactClaims || {
        edges: [],
      };

      cache.writeQuery({
        query: GET_IMPACT_CLAIMS,
        data: {
          impactClaims: {
            edges: filter(impactClaims.edges, ({ node }) => !removedClaimIds.includes(node.id)),
          },
        },
      });

      // If there's a selected partner we should also update the partner cache to remove the deleted impact claim
      // if (selectedPartner) {
      //   const getPartnerCache = cache.readQuery({
      //     query: GET_PARTNER_COMPANY,
      //     variables: {
      //       id: selectedPartner.id,
      //     },
      //   });

      //   const partnerCache = getPartnerCache?.partner;

      //   if (!partnerCache) return;

      //   cache.writeQuery({
      //     query: GET_PARTNER_COMPANY,
      //     data: {
      //       partner: {
      //         ...partnerCache,
      //         impactClaims: {
      //           edges: filter(
      //             partnerCache.impactClaims.edges,
      //             ({ node }) => !removedClaimIds.includes(node.id)
      //           ),
      //         },
      //       },
      //     },
      //   });
      // }
    },
  });

  const handleDelete = async id => {
    return await deleteImpactClaims({
      variables: {
        ids: [id],
      },
    });
  };

  const filteredImpactClaims = useMemo(() => {
    if (!impactClaims) return [];

    return filterImpactClaims(impactClaims.edges, searchTerm);
  }, [impactClaims, searchTerm]);

  const approvedClaims = useMemo(() => {
    const filteredClaims = filter(filteredImpactClaims, ['isApproved', true]);
    return getImpactClaimGroups({
      impactClaims: filteredClaims,
      impactCatalogItems,
    });
  }, [filteredImpactClaims, impactCatalogItems]);

  const pendingClaims = useMemo(() => {
    const filteredClaims = filter(filteredImpactClaims, ['isPending', true]);
    return getImpactClaimGroups({
      impactClaims: filteredClaims,
      impactCatalogItems,
    });
  }, [filteredImpactClaims, impactCatalogItems]);

  const invalidClaims = useMemo(() => {
    const filteredClaims = filter(filteredImpactClaims, ['isInvalid', true]);
    return getImpactClaimGroups({
      impactClaims: filteredClaims,
      impactCatalogItems,
    });
  }, [filteredImpactClaims, impactCatalogItems]);

  const filteredClaimGroups = useMemo(() => {
    return getImpactClaimGroups({
      impactClaims: filteredImpactClaims,
      impactCatalogItems,
    });
  }, [filteredImpactClaims, impactCatalogItems]);

  const actions = [
    {
      icon: <Edit size="17" />,
      tooltip: 'Edit impact claim',
      onClick: handleOpenEdit,
    },
    {
      icon: <Trash size="16" />,
      tooltip: 'Delete impact claim',
      onClick: ({ id }) => setDeletableClaim(id),
      dataCy: 'delete-impact-claim-btn',
    },
  ];
  return {
    impactClaims,
    errorCatalog,
    errorImpactClaims,
    loadingImpactClaims,
    impactCatalogItems,
    loadingCatalog,
    handleOpenCreate,
    setSearchTerm,
    searchTerm,
    hasFilterActive,
    filteredImpactClaims,
    actions,
    handleClickClaimRow,
    filteredClaimGroups,
    approvedClaims,
    invalidClaims,
    pendingClaims,
    setDeletableClaim,
    deletableClaim,
    handleDelete,
  };
};

export default useImpactClaims;
