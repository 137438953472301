import { Tooltip } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import React, { FC } from 'react';

interface IFlagProgressProps {
  published: boolean;
  tooltip: string;
  flag: string;
}

const Flag = styled('img')(() => ({
  width: 20,
  borderRadius: 3,
}));

const StatusIndicator = styled('div')(({ color, theme }) => ({
  width: 24,
  height: 2,
  borderWidth: 1,
  background: color,
  borderRadius: 2,
  marginTop: theme.spacing(0.5),
}));

const FlagContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginRight: theme.spacing(1),
}));

const FlagProgress: FC<IFlagProgressProps> = ({ tooltip, published, flag, ...rest }) => {
  const { palette } = useTheme();

  return (
    <FlagContainer {...rest}>
      <Tooltip title={tooltip}>
        <Flag src={flag} />
      </Tooltip>
      <StatusIndicator color={published ? palette.success.main : palette.primary.main} />
    </FlagContainer>
  );
};

export default FlagProgress;
