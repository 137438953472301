import { Box, IconButton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { GeoAltFill } from '@styled-icons/bootstrap/GeoAltFill';
import { StyledTableRow } from 'components/TableBase';
import { ThemeTypography } from 'designSystem';
import { booleanish } from 'types/booleanish.types';
import { convertHexToRGBarray } from 'utils';

export const PartnerImage = styled('img')(({ theme }) => ({
  width: 44,
  height: 44,
  borderRadius: 4,
  objectFit: 'cover',
  marginRight: theme.spacing(2),
}));

export const Flag = styled('img')(({ theme }) => ({
  width: 18,
  marginRight: theme.spacing(0.5),
  borderRadius: 2,
}));

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

export const Title = styled('div')(({ theme }) => ({
  fontSize: 16,
  fontWeight: 900,
}));

export const Location = styled(ThemeTypography)(({ theme }) => ({
  fontSize: 11,
  fontWeight: 700,
  color: theme.palette.text.secondary,
}));

export const LocationLight = styled(Location)(({ theme }) => ({
  color: theme.custom.colors.textLight,
}));

export const Pin = styled(GeoAltFill)(({ theme }) => ({
  marginRight: theme.spacing(0.5),
  color: '#C4C4C4',
}));

export const IconContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'end',
  width: 90,
}));

export const Date = styled(Typography)(({ theme }) => ({
  width: 140,
}));

// TODO: Fix withoutPadding to valid DOM type
export const StyledCell = styled('td')<{ withoutPadding?: boolean }>(
  ({ theme, withoutPadding }) => ({
    overflow: 'hidden',
    padding: withoutPadding ? 0 : theme.spacing(1.5),

    '&:not(:last-child)': {
      borderRight: 'none',
    },
    '&:not(:first-of-type)': {
      borderLeft: 'none',
    },
  })
);

interface TableRowProps {
  'is-transparent'?: booleanish;
  'is-invalid'?: booleanish;
}

export const TableRow = styled(StyledTableRow)<TableRowProps>(
  ({ theme, 'is-transparent': isTransparent, 'is-invalid': isInvalid }) => ({
    ...(isTransparent === 'true'
      ? { boxShadow: 'none' }
      : {
          backgroundColor:
            isInvalid === 'true'
              ? `rgba(${convertHexToRGBarray(theme.palette.error.main)}, 0.15)`
              : '#fff',
        }),
  })
);

export const OpacityContainer = styled(Box)<{ 'is-transparent': booleanish }>(
  ({ 'is-transparent': isTransparent }) => {
    return {
      opacity: isTransparent === 'true' ? 0.5 : 1,
    };
  }
);
