import React from 'react';
import PropTypes from 'prop-types';
import { TableCell } from '@mui/material';
import { StyledTableBody, StyledTableRow } from 'components/TableBase';
import { ProductSummaryCard } from 'components/Product/ProductTable';
import { Booleanish } from 'types/booleanish.types';

const SelectProductTableBody = ({ products, onSelect, selectedId }) => {
  const handleSelectRow = (event, row) => {
    event.stopPropagation();
    onSelect(row.id);
  };

  return (
    <StyledTableBody data-cy="select-product-table">
      {products.map(product => {
        const { id } = product;
        const isItemSelected = id === selectedId;

        return (
          <StyledTableRow
            hover
            role="checkbox"
            data-cy-id={id}
            aria-checked={isItemSelected}
            key={id}
            is-selected={Booleanish(isItemSelected)}
            onClick={event => handleSelectRow(event, product)}
          >
            <TableCell scope="row">
              <ProductSummaryCard product={product} />
            </TableCell>
          </StyledTableRow>
        );
      })}
    </StyledTableBody>
  );
};

SelectProductTableBody.propTypes = {
  products: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  selectedId: PropTypes.string,
};

export default SelectProductTableBody;
