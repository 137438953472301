import React, { useState, useEffect } from 'react';
import { TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { useDebouncedCallback } from 'use-debounce';

const DebouncedTextInput = ({
  setDebouncedState,
  delay,
  placeholder,
  inputProps,
  initialValue,
  className,
  disabled = false,
  'data-cy': dataCy = '',
}) => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const debouncedFunction = useDebouncedCallback(state => {
    setDebouncedState(state);
  }, delay);

  const onChangeInput = e => {
    setValue(e.target.value);
    debouncedFunction.callback(e.target.value);
  };

  return (
    <TextField
      size="small"
      variant="outlined"
      data-cy={dataCy}
      value={value}
      className={className}
      placeholder={placeholder}
      onChange={onChangeInput}
      InputProps={inputProps}
      disabled={disabled}
    />
  );
};

DebouncedTextInput.defaultProps = {
  placeholder: 'Search for products',
  delay: 250,
};

DebouncedTextInput.propTypes = {
  setDebouncedState: PropTypes.func.isRequired,
  delay: PropTypes.number,
  placeholder: PropTypes.string,
  inputProps: PropTypes.object,
  initialValue: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

export default DebouncedTextInput;
