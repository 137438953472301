import { useApolloClient } from '@apollo/client';
import { DOCUMENT_FRAGMENT } from 'graphql/fragments';
import { useParams } from 'react-router-dom';
import { IDocument } from 'types/document.types';

interface ReturnProps {
  document: IDocument | null;
}

/**
 * Getting the document that is currently active from the cache
 * Used currently to get the document title for the sidebar menu
 */

const useActiveDocument = (): ReturnProps => {
  const client = useApolloClient();
  const { cache } = client;
  const { documentId } = useParams<{ documentId: string }>();
  const cacheKey = cache.identify({
    __typename: 'Document',
    id: documentId,
  });
  const documentInCache = client.readFragment<IDocument>({
    fragment: DOCUMENT_FRAGMENT,
    fragmentName: 'documentsValues',
    id: cacheKey,
  });

  // Getting only the cache version cause right now it will already be fetched on a different location
  return { document: documentInCache };
};

export default useActiveDocument;
