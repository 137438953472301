import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  boxShadow: theme.custom.shadows[1],
  background: '#fff',
  borderRadius: theme.shape.borderRadius,
  marginRight: theme.spacing(0.5),
  marginBottom: theme.spacing(0.5),
  padding: theme.spacing(0.5),
  paddingRight: theme.spacing(1),
}));

const IconContainer = styled('div')(({ theme, hex }) => ({
  display: 'flex',
  backgroundColor: hex,
  padding: 4,
  width: theme.spacing(4),
  height: theme.spacing(4),
  marginRight: theme.spacing(1.5),
  borderRadius: theme.shape.borderRadius,

  '& img': {
    width: '100%',
    height: 'auto',
    margin: '0 auto',
  },
}));

const Title = styled('div')(() => ({
  fontSize: 12,
  lineHeight: 1.1,
  maxWidth: 130,
}));

const SdgItem = ({ sdg }) => {
  return (
    <Container>
      <IconContainer hex={sdg?.color}>
        <img src={sdg?.iconUrl} alt="Sdg logo" />
      </IconContainer>
      <Title>{sdg?.title}</Title>
    </Container>
  );
};

SdgItem.propTypes = {
  sdg: PropTypes.object.isRequired,
};

export default SdgItem;
