import { createImageSchema, imageSchema } from 'constants/schemas/shared';
import * as Yup from 'yup';

const componentCreationSchema = Yup.object().shape({
  image: createImageSchema({
    message: 'Please add an image of your component',
    required: false,
  }),
  title: Yup.string().label('Component title').default('').required('This field is required'),
  ownedBy: Yup.object()
    .shape({ id: Yup.string(), name: Yup.string(), logo: imageSchema })
    .nullable()
    .default(null),
  rawMaterialIds: Yup.array().of(Yup.string()).required('This field is required'),
  articleNumber: Yup.string().nullable(),
}) as Yup.ObjectSchema;

export default componentCreationSchema;
