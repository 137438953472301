import { LinearProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ThemeTypography } from 'designSystem';
import PropTypes from 'prop-types';
import React from 'react';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Container = styled(({ selected, disabled, ...props }) => <div {...props} />)(
  ({ theme, selected, disabled }) => ({
    position: 'relative',
    padding: theme.spacing(2),
    background: '#fff',
    border: '2px solid',
    borderColor: selected ? theme.palette.primary.secondary : theme.palette.grey[200],
    borderRadius: theme.spacing(1),
    overflow: 'hidden',

    ...(!disabled && {
      '&:hover': {
        cursor: 'pointer',
        background: theme.custom.colors.backgroundLight,
      },
    }),

    '&:hover .select-button': {
      opacity: 1,
    },
  })
);

const Footer = styled('div')(({ theme }) => ({
  position: 'relative',
  paddingTop: theme.spacing(2),
}));

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Text = styled(({ visible, ...props }) => (
  <ThemeTypography variant="BODY_MEDIUM_BOLD" {...props} />
))(({ visible }) => ({
  maxWidth: 120,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  visibility: visible ? 'visible' : 'hidden',
}));

const StyledProgress = styled(LinearProgress)({
  height: 4,
});

const UploadingBox = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(1),
  left: theme.spacing(1),
  right: theme.spacing(1),
}));

const ListItem = ({
  fileName,
  progress,
  uploading,
  children,
  selected,
  onSelect,
  disabled = false,
}) => {
  return (
    <Container selected={selected} onClick={!disabled ? onSelect : undefined} disabled={disabled}>
      {children}
      <Footer>
        {uploading && (
          <UploadingBox>
            <Text visible>{progress}%</Text>
            <StyledProgress color="secondary" variant="determinate" value={progress} />
          </UploadingBox>
        )}
        <Text visible={!uploading}>{fileName}</Text>
      </Footer>
    </Container>
  );
};

ListItem.propTypes = {
  fileName: PropTypes.string,
  selected: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  uploading: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default ListItem;
