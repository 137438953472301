import { ErrorView } from 'containers';
import React, { FC, PropsWithChildren } from 'react';
import { useRouteError } from 'react-router-dom';

const ErrorBoundary: FC<PropsWithChildren> = ({ children }) => {
  const error = useRouteError();

  if (error) {
    return <ErrorView />;
  }

  return <>{children}</>;
};

export default ErrorBoundary;
