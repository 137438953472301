import { styled } from '@mui/material/styles';
import { CheckCircleFill } from '@styled-icons/bootstrap/CheckCircleFill';
import { CircleFill } from '@styled-icons/bootstrap/CircleFill';
import { ExclamationCircleFill } from '@styled-icons/bootstrap/ExclamationCircleFill';
import React, { FC, ReactNode } from 'react';
import theme from 'styles/theme';

export type ValidationState = 'valid' | 'invalid' | 'default';

interface IValidationIndicatorProps {
  state?: ValidationState;
}

const CONTAINER_COLOR: Record<ValidationState, string> = {
  valid: theme.palette.success.main,
  invalid: theme.palette.warning.main,
  default: theme.palette.grey[200],
};

const Container = styled('div')<{ state: ValidationState }>(({ theme, state }) => ({
  color: CONTAINER_COLOR[state],
  background: theme.palette.common.white,
  alignSelf: 'center',
  width: 14,
  height: 14,
  borderRadius: '50%',

  '& svg': {
    verticalAlign: 'top',
    marginTop: -1,
    marginBottom: -1,
  },
}));

const ICON_SIZE = 16;

const ValidationIcon: Record<ValidationState, ReactNode> = {
  valid: <CheckCircleFill size={ICON_SIZE} />,
  invalid: <ExclamationCircleFill size={ICON_SIZE} />,
  default: <CircleFill size={ICON_SIZE} />,
};

const ValidationIndicator: FC<IValidationIndicatorProps> = ({ state = 'default' }) => (
  <Container state={state}>{ValidationIcon[state]}</Container>
);
export default ValidationIndicator;
