import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';

import withStyles from '@mui/styles/withStyles';

const StyledTooltip = withStyles(theme => ({
  tooltip: {
    background: theme.palette.common.white,
    borderRadius: 4,
    padding: theme.spacing(1),
    fontWeight: 500,
    boxShadow: '0 0 8px 4px rgba(0, 0, 0, 0.1)',
    color: 'black',
  },
}))(Tooltip);

const ErrorList = styled('ul')(({ theme }) => ({
  marginLeft: theme.spacing(2),
  padding: 0,
}));

const ErrorTooltip = ({ errors = [], children, show, placement = 'right' }) => {
  if (!show || !errors.length) return children;

  return (
    <StyledTooltip
      key={errors.length}
      title={
        <Fragment>
          <ErrorList>
            {errors.map(e => (
              <li key={e}>{e}</li>
            ))}
          </ErrorList>
        </Fragment>
      }
      placement={placement}
    >
      {children}
    </StyledTooltip>
  );
};

ErrorTooltip.propTypes = {
  errors: PropTypes.array,
  children: PropTypes.any,
  show: PropTypes.bool,
};

export default ErrorTooltip;
