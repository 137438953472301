import { IconButton, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CardImage } from '@styled-icons/bootstrap/CardImage';
import { ArrowLeft } from '@styled-icons/bootstrap/ArrowLeft';
import { ArrowRight } from '@styled-icons/bootstrap/ArrowRight';
import { Trash } from '@styled-icons/bootstrap/Trash';
import { Crop } from '@styled-icons/boxicons-regular/Crop';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  padding: theme.spacing(1, 1),
  background: theme.palette.secondary.main,
  borderRadius: 0,
  color: '#ffffff',

  '&:hover': {
    background: theme.palette.secondary.main,
  },

  '&:last-of-type': {
    borderTopRightRadius: theme.spacing(1),
    borderBottomRightRadius: theme.spacing(1),
  },

  '&:first-of-type': {
    borderTopLeftRadius: theme.spacing(1),
    borderBottomLeftRadius: theme.spacing(1),
  },
}));

const ArrowButton = styled(IconButton)(({ theme, left }) => ({
  position: 'absolute',
  top: 10,
  right: 10,
  zIndex: 1,
  padding: theme.spacing(0.6, 1),
  background: theme.palette.secondary.main,
  color: '#ffffff',
  borderRadius: theme.spacing(1),

  '&:hover': {
    background: theme.palette.secondary.main,
  },

  ...(left && {
    left: 10,
    right: 'auto',
  }),
}));

const MovingButtons = styled('div')(() => ({
  opacity: 0,
  transition: 'opacity 0.25s ease-in-out',
  fontWeight: 500,
}));

const ActionButtons = styled('div')(() => ({
  position: 'absolute',
  opacity: 0,
  display: 'flex',
  justifyContent: 'center',
  alignSelf: 'center',
  bottom: 20,
  transition: 'opacity 0.25s ease-in-out',
  fontWeight: 500,
  width: '100%',
}));

const MediaActionButtons = ({
  cropDisabled,
  deleteDisabled,
  selectorDisabled,
  openSelector,
  openCropping,
  onDelete,
  moveLeft,
  moveRight,
}) => {
  return (
    <Fragment>
      <MovingButtons className="moving-buttons">
        {moveLeft && (
          <Tooltip title="Move left" placement="top">
            <ArrowButton onClick={moveLeft} data-cy="move-left" left>
              <ArrowLeft size={15} />
            </ArrowButton>
          </Tooltip>
        )}
        {moveRight && (
          <Tooltip title="Move right" placement="top">
            <ArrowButton onClick={moveRight} data-cy="move-right">
              <ArrowRight size={15} />
            </ArrowButton>
          </Tooltip>
        )}
      </MovingButtons>
      <ActionButtons className="action-buttons">
        {!selectorDisabled && (
          <Tooltip title="Select" placement="top">
            <StyledIconButton onClick={openSelector} data-cy="media-select-button">
              <CardImage size={20} />
            </StyledIconButton>
          </Tooltip>
        )}
        {!cropDisabled && (
          <Tooltip title="Crop" placement="top">
            <StyledIconButton onClick={openCropping} data-cy="media-crop-button">
              <Crop size={21} />
            </StyledIconButton>
          </Tooltip>
        )}
        {!deleteDisabled && (
          <Tooltip title="Delete" placement="top">
            <StyledIconButton onClick={onDelete} data-cy="media-delete-button">
              <Trash size={20} />
            </StyledIconButton>
          </Tooltip>
        )}
      </ActionButtons>
    </Fragment>
  );
};

MediaActionButtons.propTypes = {
  selctorDisabled: PropTypes.bool,
  cropDisabled: PropTypes.bool,
  deleteDisabled: PropTypes.bool,
  openSelector: PropTypes.func,
  openColorPicker: PropTypes.func,
  openCropping: PropTypes.func,
  onDelete: PropTypes.func,
};

export default MediaActionButtons;
