/* eslint-disable react-hooks/exhaustive-deps */
import { DragStartEvent } from '@dnd-kit/core';
import PartnerDefaultIcon from 'assets/img/icons/partner-default.svg';
import ImagePlaceholder from 'assets/img/images.png';
import { appQueryParams } from 'constants/appQueryParams';
import { COLOR_PALETTE } from 'constants/colors';
import React, { Ref, useMemo, useState } from 'react';
import useMeasure from 'react-use/lib/useMeasure';
import { IChain, IChainActivity, IChainStep, ISubChain } from 'types/chain.types';
import { BooleanParam, useQueryParam } from 'use-query-params';
import { getChainPartnerTitle } from 'utils/chainTitle.utils';
import { IGraphActivity } from './components/Activity';
import { IGraphStep } from './components/Step';
import { IGraphSubChain } from './components/SubChain';
import useChainMappingConfig from './components/useChainMappingConfig';
import { ChainMappingConfig } from './utils/mapping.types';
import {
  buildPreviousConnectionIds,
  getActivitiesInBoundsOfSteps,
  getLastStepActivityYIndex,
  getSubChainsInBoundsOfSteps,
  isInBoundsOfSubChain,
  isInYBoundsOfSubChain,
} from './utils/mapping.utils';
import { SiteType } from 'types/site.types';

type IChainMappingHook = (
  chainMapping: IChain,
  config?: Partial<ChainMappingConfig>
) => {
  onboardingTour?: boolean | null;
  showFirstColumn: boolean;
  containerRef: Ref<HTMLDivElement>;
  size: { width: number; height: number };
  orderedSubChains: IGraphSubChain[];
  draggingSubChain?: IGraphSubChain;
  activitiesWithoutSubChain: IGraphActivity[];
  // How many activities are in a step / column
  steps: IGraphStep[];
  isChainStepActivityHighlighted: (stepActivityId: string) => boolean;
  areSubChainStepActivityHighlighted: (subChainId: string) => boolean;
  onSubChainDragStart: (event: DragStartEvent) => void;
  onSubChainDragEnd: () => void;
  onSubChainCreationHighlightToggle: (prevConnectionIds?: IPrevConnectionIds) => void;
};

export interface ISerializedActivityLink {
  sourceStepActivityId?: string; // If source is undefined it means that the activity is in the origin step
  targetStepActivityId: string;
  subChainId?: string;
}

export interface IPrevConnectionIds {
  stepActivityIds: string[];
  subChainIds?: string[];
}

const useChainMapping: IChainMappingHook = (
  { subChains, chainStepActivities, chainSteps },
  config
) => {
  const [onboardingTour] = useQueryParam(appQueryParams.tour, BooleanParam);
  const { SPACING, NODE_HEIGHT, COLUMN_WIDTH, HEADER_HEIGHT, FIRST_COLUMN_WIDTH } =
    useChainMappingConfig();
  const [containerRef, { height: containerHeight, width: containerWidth }] =
    useMeasure<HTMLDivElement>();

  /**
   * This object is used to highlight activities when creating a subChain from an activity
   */
  const [prevConnectionIds, setPrevConnectionIds] = useState<IPrevConnectionIds>();
  const [draggingSubChain, setDraggingSubChain] = useState<IGraphSubChain>();

  const showFirstColumn = config?.canImportSubChains || onboardingTour || subChains.length > 0;

  const size: { width: number; height: number } = useMemo(() => {
    // Add 48px since the plus step icon will be shown after the last step
    const width =
      SPACING * 2 +
      (showFirstColumn ? FIRST_COLUMN_WIDTH + SPACING * 2 : 0) +
      (SPACING * chainSteps.length + COLUMN_WIDTH * chainSteps.length) +
      (config?.canAddSteps ? 48 : 0);
    const stepWithTheMostActivities =
      chainStepActivities.reduce(
        (prevY, { pointPosition: { y: nextY } }) => (nextY > prevY ? nextY : prevY),
        1
      ) + 1;
    // Add 1 to the stepWithTheMostActivities to account for the add activity button if enableEdit is true
    const height =
      HEADER_HEIGHT +
      SPACING * 5 +
      (NODE_HEIGHT + SPACING * 2) *
        (stepWithTheMostActivities + (config?.canAddActivities ? 1 : 0));
    return {
      width: width > containerWidth ? width : containerWidth,
      height: height > containerHeight ? height : containerHeight,
    };
  }, [
    showFirstColumn,
    containerHeight,
    containerWidth,
    chainSteps.length,
    chainStepActivities.length,
    config?.canAddActivities,
    config?.canAddSteps,
  ]);

  /**
   * Store all the incoming links to improve the performance of calculating which activity has outputComponents
   * Constructing a helper array as tree structure to speed up later calculations that depend on the connections between activities
   * It will add links to origin activities with an empty sourceStepActivityId to later on identify subChains on those links
   */
  const allIncomingLinks: ISerializedActivityLink[] = useMemo(
    () =>
      chainStepActivities.reduce((prev, { id, pointPosition, incomingLinks }) => {
        if (incomingLinks.length === 0) {
          return prev;
        }

        incomingLinks.forEach(({ chainStepActivitySourceId }) => {
          const srcStepActivity = chainStepActivities.find(
            ({ id }) => id === chainStepActivitySourceId
          );
          const isSrcOrigin = srcStepActivity?.pointPosition.x === 0;

          // If it is an origin activity, add a link to the origin
          if (isSrcOrigin) {
            prev.push({
              targetStepActivityId: srcStepActivity.id,
              // Find the subChainId of the origin activity
              subChainId: subChains.find(({ boundingBoxPosition }) =>
                isInBoundsOfSubChain(boundingBoxPosition, srcStepActivity.pointPosition)
              )?.id,
            });
          }

          prev.push({
            sourceStepActivityId: chainStepActivitySourceId,
            targetStepActivityId: id,
            // Find the subChainId of the target (current) activity
            subChainId: subChains.find(({ boundingBoxPosition }) =>
              isInBoundsOfSubChain(boundingBoxPosition, pointPosition)
            )?.id,
          });
        });

        return prev;
      }, [] as ISerializedActivityLink[]),
    [chainStepActivities]
  );

  const getOutputComponentColor = (index: number) => {
    return COLOR_PALETTE[index % COLOR_PALETTE.length];
  };

  const getInputComponentColor = (inputComponentId: string) => {
    const index =
      chainStepActivities.find(({ id }) => id === inputComponentId)?.pointPosition.y || 0;
    return COLOR_PALETTE[index % COLOR_PALETTE.length];
  };

  const calculateSubChainPosition = (subChain: ISubChain, index: number): IGraphSubChain => {
    const { id, outputComponents, boundingBoxPosition } = subChain;

    const subStepActivities = chainStepActivities.filter(({ pointPosition }) =>
      isInBoundsOfSubChain(boundingBoxPosition, pointPosition)
    );
    const graphActivities = subStepActivities.map(activity =>
      calculateActivityPosition(activity, boundingBoxPosition.yMin, true)
    );

    return {
      id,
      title: subChain.title,
      companyName: getChainPartnerTitle(outputComponents),
      x: SPACING * 2,
      y:
        HEADER_HEIGHT +
        SPACING * 3.33 +
        (boundingBoxPosition.yMin * SPACING * 2 + NODE_HEIGHT * boundingBoxPosition.yMin),
      width:
        FIRST_COLUMN_WIDTH +
        SPACING * 2 +
        COLUMN_WIDTH * Math.abs(boundingBoxPosition.xMax - boundingBoxPosition.xMin) +
        SPACING * (Math.abs(boundingBoxPosition.xMax - boundingBoxPosition.xMin) - 1),
      height:
        (NODE_HEIGHT + SPACING * 2) *
          Math.abs(boundingBoxPosition.yMax - boundingBoxPosition.yMin) -
        SPACING * 0.66,
      activities: graphActivities,
    };
  };

  const calculateStepPosition = (step: IChainStep, index: number): IGraphStep => {
    const { id, title } = step;
    const editButtonYPositions: number[] = [];
    if (config?.canAddActivities) {
      const subChainsInStepBounds = getSubChainsInBoundsOfSteps(index, subChains);
      const activitiesInStepBounds = getActivitiesInBoundsOfSteps(step.id, chainStepActivities);
      const lastStepActivityYIndex = getLastStepActivityYIndex(
        activitiesInStepBounds,
        subChainsInStepBounds
      );
      let activityInBetween = true;
      // Iterate through all possible activity y positions and check if there is an activity or subchain in that position
      Array.from(Array(lastStepActivityYIndex + 1).keys()).forEach((_, activityYIndex) => {
        if (
          activitiesInStepBounds.findIndex(({ pointPosition: { y } }) => y === activityYIndex) ===
            -1 &&
          subChainsInStepBounds.findIndex(({ boundingBoxPosition }) =>
            isInYBoundsOfSubChain(boundingBoxPosition, activityYIndex)
          ) === -1
        ) {
          // If there is no activity or subChain in the current position, and there is not already a button without an activity in between add the edit button to the current position
          if (activityInBetween) {
            editButtonYPositions.push(activityYIndex);
            activityInBetween = false;
          }
        } else {
          activityInBetween = true;
        }
      });
    }
    return {
      id,
      title: `${index + 1}. ${title}`,
      x:
        SPACING * 2 +
        (showFirstColumn ? FIRST_COLUMN_WIDTH + SPACING * 2 : 0) +
        (SPACING * index + COLUMN_WIDTH * index),
      height: size.height,
      addButtonYIndices: editButtonYPositions,
    };
  };

  /**
   * If componentChainId is set, the activity is part of a component chain which does not need extra spacings
   */
  const calculateActivityPosition = (
    activity: IChainActivity,
    yStartPosition: number = 0,
    isPartOfSubChain?: boolean
  ): IGraphActivity => {
    const {
      id,
      activity: { title, ownedBy: partner, site, component, mediaList },
      pointPosition,
      incomingLinks,
    } = activity;
    // Check if the activity has an outgoing link to decide if the output link color should be set
    const hasOutgoingLink =
      allIncomingLinks.findIndex(({ sourceStepActivityId }) => sourceStepActivityId === id) !== -1;

    let initialX = showFirstColumn ? FIRST_COLUMN_WIDTH + SPACING * 3 : SPACING;
    const initialY = isPartOfSubChain ? SPACING * 0.66 : HEADER_HEIGHT + SPACING * 4;

    initialX += isPartOfSubChain ? 0 : SPACING * 2;

    return {
      id,
      title,
      name: site.locationName,
      // In the partner mode and product page the partner is not shown instead we show the length of the mediaList
      subtitle: config?.canSeePartner ? partner?.name || 'Unknown' : String(mediaList.length),
      subtitleIcon: config?.canSeePartner ? (
        <img
          src={partner?.logo?.url || PartnerDefaultIcon}
          alt="company-logo"
          width="24"
          height="24"
        />
      ) : (
        <img src={ImagePlaceholder} alt="counter" />
      ),
      siteCluster:
        site.siteType === SiteType.CLUSTER
          ? { id: site.id, siteCount: site.sites.count }
          : undefined,
      output: component?.title || 'Unknown',
      x: initialX + (SPACING * pointPosition.x + COLUMN_WIDTH * pointPosition.x),
      y:
        initialY +
        (Math.abs(pointPosition.y - yStartPosition) * SPACING * 2 +
          NODE_HEIGHT * Math.abs(pointPosition.y - yStartPosition)),
      zIndex: chainSteps.length - pointPosition.x + 1,
      outgoingLinkColor: hasOutgoingLink ? getOutputComponentColor(pointPosition.y) : undefined,
      incomingLinkColors: [
        ...incomingLinks.map(({ chainStepActivitySourceId }) =>
          getInputComponentColor(chainStepActivitySourceId)
        ),
      ],
      // We only show the chain creation button on activities that are not already part of a subChain
      prevConnectionIds:
        isPartOfSubChain || !config?.canImportSubChains || !config?.canEditActivities
          ? undefined
          : buildPreviousConnectionIds(id, allIncomingLinks),
    };
  };

  const orderedSubChains = useMemo(
    () => subChains.map(calculateSubChainPosition),
    [subChains, allIncomingLinks]
  );

  /**
   * Filter out all activities that are not part of a component which are mapped and calculated in the chain itself
   * If the chainStepId does not exist in this chain it means that this activity is part of subChain
   */
  const activitiesWithoutSubChain = useMemo(
    () =>
      chainStepActivities
        .filter(({ chainStepId }) => chainSteps.findIndex(({ id }) => chainStepId === id) !== -1)
        .map(activity => calculateActivityPosition(activity)),
    [subChains, chainSteps, chainStepActivities, allIncomingLinks]
  );

  const steps = useMemo(
    () => chainSteps.map(calculateStepPosition),
    [size, chainSteps, chainStepActivities, config?.canAddActivities]
  );

  const onSubChainDragStart = (event: DragStartEvent) => {
    setDraggingSubChain(orderedSubChains.find(({ id }) => id === (event.active.id as string)));
  };

  const onSubChainDragEnd = () => setDraggingSubChain(undefined);

  const isChainStepActivityHighlighted = (activityId: string) =>
    !!prevConnectionIds && prevConnectionIds.stepActivityIds.includes(activityId);

  const areSubChainStepActivityHighlighted = (subChainId: string) =>
    !!prevConnectionIds && !!prevConnectionIds.subChainIds?.includes(subChainId);

  const onSubChainCreationHighlightToggle = (prevConnectionIds?: IPrevConnectionIds) => {
    setPrevConnectionIds(prevConnectionIds);
  };

  return {
    onboardingTour,
    showFirstColumn,
    size,
    steps,
    draggingSubChain,
    orderedSubChains,
    activitiesWithoutSubChain,
    containerRef,
    onSubChainDragStart,
    onSubChainDragEnd,
    isChainStepActivityHighlighted,
    areSubChainStepActivityHighlighted,
    onSubChainCreationHighlightToggle,
  };
};

export default useChainMapping;
