import React, { useState, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  AuthLayout,
  RegistrationInvitedForm,
  ResendVerification,
  RegistrationForm,
  AuthHeadline,
} from 'components/Auth';

import {
  REGISTER_INVITED,
  REGISTER_INVITED_PARTNER,
  RESEND_INVITE_MAIL,
  LOGIN,
} from 'graphql/mutations';
import { useMutation } from '@apollo/client';
import { useConfig } from 'components/hooks';
import { ErrorState } from 'components/Structure';
import { useQueryParam, StringParam } from 'use-query-params';
import { Box, Typography } from '@mui/material';
import { RegisterPartnerValues, RegisterValues } from 'types/types';

const RegisterInvited = () => {
  const { appQueryParams } = useConfig();
  const [hasError, setHasError] = useState(false);
  const navigate = useNavigate();
  const [token] = useQueryParam(appQueryParams.registerToken, StringParam);
  const [email] = useQueryParam('email', StringParam);
  const [partnerNameQueryParam] = useQueryParam('company_name', StringParam);
  const [invitedByCompanyId] = useQueryParam('invited_by_company_id', StringParam);
  const [invitedByUserId] = useQueryParam('invited_by_user_id', StringParam);
  const [invitedCompanyId] = useQueryParam('invited_company_id', StringParam);

  const isPartnerRegistration: boolean = ![
    partnerNameQueryParam && invitedByCompanyId && invitedCompanyId,
  ].includes(undefined);

  const [register] = useMutation<
    Record<string, unknown>,
    {
      password: string;
      token: string;
      input: RegisterValues;
    }
  >(REGISTER_INVITED, {
    onError: () => setHasError(true),
    onCompleted: () => navigate('/'),
    fetchPolicy: 'no-cache',
  });

  const [login] = useMutation(LOGIN, {
    onError: () => setHasError(true),
    onCompleted: () => {
      navigate('/');
    },
    fetchPolicy: 'no-cache',
  });

  const [registerPartner] = useMutation<
    Record<string, unknown>,
    {
      input: RegisterPartnerValues;
    }
  >(REGISTER_INVITED_PARTNER, {
    onError: () => setHasError(true),
    fetchPolicy: 'no-cache',
  });

  const onSubmit = (values: RegisterValues) => {
    const { password } = values;
    delete values.password;

    return register({
      variables: {
        password: password ?? '',
        token: token ?? '',
        input: values,
      },
    });
  };

  // Completes the registration for the partner and then logs
  // them in to the platform.
  const onSubmitPartner = async (values: RegisterValues) => {
    await registerPartner({
      variables: {
        input: {
          email: values.email,
          password: values.password,
          firstName: values.firstName,
          lastName: values.lastName,
          companyName: values.companyName,
          industry: values.industry,
          termsAccepted: values.termsAccepted,
          invitedByCompanyId: invitedByCompanyId ?? '',
          invitedCompanyId: invitedCompanyId ?? '',
          invitedByUserId: invitedByUserId ?? '',
        },
      },
    });

    login({
      variables: { email: values.email, password: values.password },
    });
  };

  return (
    <AuthLayout
      title="Register"
      isPartnerRegistration={isPartnerRegistration}
      partnerRegistrationName={partnerNameQueryParam ?? undefined}
    >
      {!token && (
        <ErrorState
          error="Your invitation is not valid. Please contact your account administrator for a new invitation!"
          action={{
            onClick: () => navigate('/login'),
            label: 'Go to login',
          }}
        />
      )}

      {hasError && (
        <Fragment>
          {isPartnerRegistration ? (
            <Box mt={12}>
              <Typography variant="h5" color="primary">
                Registration Error
              </Typography>
              <Box mt={2}>
                <Typography color="primary">
                  Something went wrong while registering. Please contact your account administrator.
                  It could be that the invitation link was expired, in this case you need to get a
                  new invitation from your client.
                </Typography>
              </Box>
            </Box>
          ) : (
            <ResendVerification
              title="Verification link expired"
              subtitle="The link you used is expired. Use the button below to get a new one."
              email={email ?? ''}
              mutation={RESEND_INVITE_MAIL}
            />
          )}
        </Fragment>
      )}

      {token && !hasError && (
        <Fragment>
          {isPartnerRegistration ? (
            <Box>
              <AuthHeadline text="Welcome to" highlighted="seedtrace" />
              <Box mt={5}>
                <RegistrationForm
                  onSubmit={onSubmitPartner}
                  isPartnerRegistration
                  email={email ?? ''}
                />
              </Box>
            </Box>
          ) : (
            <RegistrationInvitedForm onSubmit={onSubmit} />
          )}
        </Fragment>
      )}
    </AuthLayout>
  );
};

export default RegisterInvited;
