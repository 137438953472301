import { SiteType, LandOwnershipType } from 'types/site.types';

export const SiteLabelKeys: Record<SiteType, string> = {
  [SiteType.FARM]: 'Farm',
  [SiteType.MINE]: 'Mine',
  [SiteType.FOREST]: 'Forest',
  [SiteType.COLLECTION_SITE]: 'Collection Site',
  [SiteType.ORIGIN_SITE]: 'Origin Site',
  [SiteType.PLANT]: 'Plant',
  [SiteType.PROCESSING_SITE]: 'Processing Site',
  [SiteType.FACTORY]: 'Factory',
  [SiteType.MANUFACTURING_SITE]: 'Manufacturing Site',
  [SiteType.WAREHOUSE]: 'Warehouse',
  [SiteType.HANDLING_SITE]: 'Handling Site',
  [SiteType.GROCERY_STORE]: 'Grocery Store',
  [SiteType.DISTRIBUTION_SITE]: 'Distribution Site',
  [SiteType.GENERIC_SITE]: 'Generic Site',
  [SiteType.CLUSTER]: 'Cluster',
};

export const LandOwnershipTypeLabelKeys: Record<string, string> = {
  [LandOwnershipType.PRIVATE_OWNERSHIP]: 'Private ownership',
  [LandOwnershipType.PUBLIC_OWNERSHIP]: 'Public ownership',
  [LandOwnershipType.COMMUNAL_OWNERSHIP]: 'Communal ownership',
  [LandOwnershipType.STATE_OWNERSHIP]: 'State ownership',
  [LandOwnershipType.TENANCY]: 'Tenancy',
  [LandOwnershipType.CONCESSION]: 'Concession',
  [LandOwnershipType.EASEMENT]: 'Easement',
  [LandOwnershipType.COLLECTIVE_FARMS]: 'Collective farms',
  [LandOwnershipType.TRUSTS]: 'Trusts',
  [LandOwnershipType.COMMON_PROPERTY_REGIMES]: 'Common property regimes',
  [LandOwnershipType.ADVERSE_POSSESSION]: 'Adverse possession',
  [LandOwnershipType.LAND_COOPERATIVES]: 'Land cooperatives',
  [LandOwnershipType.USUFRUCT_RIGHTS]: 'Usufruct rights',
  [LandOwnershipType.OTHER]: 'Other',
};
