import { toJpeg, toPng } from 'html-to-image';
import { SupportedImageFromHTMLFormats } from 'types/enums';
import { Cropping } from 'types/media.types';

export const generateImageFromHtml = async (
  format: Exclude<SupportedImageFromHTMLFormats, 'EPS'>,
  element: HTMLDivElement
) => {
  switch (format) {
    case SupportedImageFromHTMLFormats.PNG:
      return await toPng(element, { pixelRatio: 10 });
    case SupportedImageFromHTMLFormats.JPEG:
      return await toJpeg(element, { pixelRatio: 10, backgroundColor: '#fff' });
    case SupportedImageFromHTMLFormats.SVG:
      //get svg source.
      let source = element.innerHTML;
      //add name spaces.
      if (!source.match(/^<svg[^>]+xmlns="http:\/\/www\.w3\.org\/2000\/svg"/)) {
        source = source.replace(/^<svg/, '<svg xmlns="http://www.w3.org/2000/svg"');
      }
      if (!source.match(/^<svg[^>]+"http:\/\/www\.w3\.org\/1999\/xlink"/)) {
        source = source.replace(/^<svg/, '<svg xmlns:xlink="http://www.w3.org/1999/xlink"');
      }
      //add xml declaration
      source = '<?xml version="1.0" standalone="no"?>\r\n' + source;

      //convert svg source to URI data scheme.
      const url = 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(source);

      return url;
    default:
      return await toPng(element, { pixelRatio: 5 });
  }
};

export const createCrop = (crop: Cropping) => {
  if (crop && typeof crop.x === 'number') {
    const { x, y, width, height } = crop;

    if (!x && !y && !width && !height) return '';

    return `${x},${y},${width},${height}`;
  }

  return '';
};
