import { gql } from '@apollo/client';
import { removeClientSetsFromDocument } from '@apollo/client/utilities';
import { contentBlockSchema, generalSchema } from 'constants/schemas';
import { SupplyChainSchema } from 'constants/schemas/supplyChain';
import {
  ABOUT_FRAGMENT,
  CONTENT_BLOCKS_FRAGMENT,
  FEEDBACK_BLOCK_FRAGMENT,
  IMPACT_FACTS_FRAGMENT,
  MORE_INFO_BLOCK_FRAGMENT,
  PRODUCTS_FRAGMENT,
  VERIFIED_PAYMENT_BLOCK_FRAGMENT,
} from 'graphql/fragments';
import { CHAIN_FRAGMENT } from 'graphql/fragments/chain.fragments';
import { defaultValidationType, validateContent, validateVersion } from 'graphql/utils';
import every from 'lodash/every';

const versionPolicies = {
  ProductVersion: {
    fields: {
      versionValidation: {
        read: (value, { readField, cache }) => {
          const versionCacheKey = cache.identify({
            __typename: 'ProductVersion',
            id: readField('id'),
          });
          const generalValidation = readField('generalValidation');

          const cachedData = cache.readFragment({
            fragment: CONTENT_BLOCKS_FRAGMENT,
            id: versionCacheKey,
            fragmentName: 'contentBlocks',
          });

          const contentBlocks = cachedData?.contentBlocks || [];

          const contentStatus = validateVersion([
            ...contentBlocks,
            { validation: generalValidation },
          ]);

          return {
            contentStatus,
            valid: contentStatus === 100,
          };
        },
      },
      contentBlocksValid: {
        read: (value, { readField, cache }) => {
          const versionCacheKey = cache.identify({
            __typename: 'ProductVersion',
            id: readField('id'),
          });

          const data = cache.readFragment({
            fragment: CONTENT_BLOCKS_FRAGMENT,
            id: versionCacheKey,
            fragmentName: 'contentBlocks',
          });

          if (!data?.contentBlocks || !data.contentBlocks.length) return false;

          return every(data.contentBlocks, ({ validation }) => validation?.valid);
        },
      },
      generalValidation: {
        read: (value, { readField }) => {
          const title = readField('title');
          const image = readField('image');

          return validateContent({ image, title }, generalSchema);
        },
      },
      supplyChainValidation: {
        read: (value, { readField, cache }) => {
          const supplyChainId = readField('supplyChain').__ref;
          if (!supplyChainId) return null;

          const latestSupplyChain = cache.readFragment({
            id: supplyChainId,
            fragment: CHAIN_FRAGMENT,
            fragmentName: 'chainValues',
          });

          if (!latestSupplyChain) return null;

          return validateContent(latestSupplyChain, SupplyChainSchema, true);
        },
      },
    },
  },
  ...Object.values(contentBlockSchema).reduce((typePolicies, contentBlock) => {
    const { name, schema } = contentBlock;

    return {
      ...typePolicies,
      [name]: {
        fields: {
          validation: {
            read: (value, { readField }) => {
              if (!readField('enabled')) return defaultValidationType();
              const data = readField('data');
              return validateContent(data, schema);
            },
          },
        },
      },
    };
  }, {}),
  AboutBlock: {
    fields: {
      validation: {
        read: (value, { readField, cache }) => {
          if (!readField('enabled')) return defaultValidationType();

          const cacheKey = cache.identify({
            __typename: 'AboutBlock',
            id: readField('id'),
          });

          const { data } = cache.readFragment({
            fragment: removeClientSetsFromDocument(ABOUT_FRAGMENT),
            id: cacheKey,
          });

          return validateContent(data, contentBlockSchema.ABOUT_BLOCK.schema);
        },
      },
    },
  },
  MoreInfoBlock: {
    fields: {
      validation: {
        read: (value, { readField, cache }) => {
          if (!readField('enabled')) return defaultValidationType();

          const cacheKey = cache.identify({
            __typename: 'MoreInfoBlock',
            id: readField('id'),
          });

          const { data } = cache.readFragment({
            fragment: removeClientSetsFromDocument(MORE_INFO_BLOCK_FRAGMENT),
            id: cacheKey,
          });

          return validateContent(data, contentBlockSchema.MORE_INFO_BLOCK.schema);
        },
      },
    },
  },
  FeedbackBlock: {
    fields: {
      validation: {
        read: (value, { readField, cache }) => {
          if (!readField('enabled')) return defaultValidationType();

          const cacheKey = cache.identify({
            __typename: 'FeedbackBlock',
            id: readField('id'),
          });

          const { data } = cache.readFragment({
            fragment: removeClientSetsFromDocument(FEEDBACK_BLOCK_FRAGMENT),
            id: cacheKey,
          });

          return validateContent(data, contentBlockSchema.FEEDBACK_BLOCK.schema);
        },
      },
    },
  },
  VerifiedPaymentsBlock: {
    fields: {
      validation: {
        read: (value, { readField, cache }) => {
          if (!readField('enabled')) return defaultValidationType();

          const cacheKey = cache.identify({
            __typename: 'VerifiedPaymentsBlock',
            id: readField('id'),
          });

          const paymentCache = cache.readFragment({
            fragment: removeClientSetsFromDocument(VERIFIED_PAYMENT_BLOCK_FRAGMENT),
            id: cacheKey,
          });
          const { data } = paymentCache;
          return validateContent(data, contentBlockSchema.VERIFIED_PAYMENT_BLOCK.schema);
        },
      },
    },
  },
  ProductsBlock: {
    fields: {
      validation: {
        read: (value, { readField, cache }) => {
          if (!readField('enabled')) return defaultValidationType();

          const cacheKey = cache.identify({
            __typename: 'ProductsBlock',
            id: readField('id'),
          });

          const { data } = cache.readFragment({
            fragment: removeClientSetsFromDocument(PRODUCTS_FRAGMENT),
            id: cacheKey,
          });

          return validateContent(data, contentBlockSchema.PRODUCTS_BLOCK.schema);
        },
      },
    },
  },
  ImpactFactsBlock: {
    fields: {
      validation: {
        read: (value, { readField, cache }) => {
          if (!readField('enabled')) return defaultValidationType();

          const cacheKey = cache.identify({
            __typename: 'ImpactFactsBlock',
            id: readField('id'),
          });

          const { data } = cache.readFragment({
            fragment: removeClientSetsFromDocument(IMPACT_FACTS_FRAGMENT),
            id: cacheKey,
          });

          return validateContent(data, contentBlockSchema.IMPACT_FACTS_BLOCK.schema);
        },
      },
    },
  },
  ProductJourneyBlock: {
    fields: {
      validation: {
        read: (value, { readField, cache }) => {
          if (!readField('enabled')) return defaultValidationType();

          const versionCacheKey = cache.identify({
            __typename: 'ProductVersion',
            id: readField('versionId'),
          });

          const { supplyChainValidation } = cache.readFragment({
            fragment: gql`
              fragment supplyChainValidation on ProductVersion {
                supplyChainValidation {
                  __typename
                  valid
                  errors
                  fields
                }
              }
            `,
            id: versionCacheKey,
          });

          return supplyChainValidation;
        },
      },
    },
  },
};

export default versionPolicies;
