import { Grid } from '@mui/material';
import { MediaField, TextInputField } from 'components/Forms';
import { FieldWrapper } from 'designSystem';
import HasAccess from 'components/Structure/HasAccess';
import { FastField as Field } from 'formik';
import React from 'react';
import { CustomerPlan } from 'types/company.types';

const CompanyFields = () => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextInputField
              label="Company name"
              name="name"
              inputProps={{
                'data-cy': 'company-name-field',
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInputField
              label="Company Website"
              name="website"
              placeholder="https://website.com"
              inputProps={{
                'data-cy': 'website-field',
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <HasAccess allowedPlans={[CustomerPlan.PAYING]}>
              <TextInputField
                label="Online Shop (optional)"
                name="onlineShop"
                placeholder="https://onlineshop.com"
                inputProps={{
                  'data-cy': 'online-shop-field',
                }}
              />
            </HasAccess>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <FieldWrapper label="Company logo">
          <Field
            cropDisabled
            component={MediaField}
            fullWidth
            singleUpload
            legacyImage={false}
            name="logo"
            data-cy="company-logo-field"
            styles={{
              width: 200,
              height: 200,
            }}
          />
        </FieldWrapper>
      </Grid>
      <HasAccess allowedPlans={[CustomerPlan.PAYING]}>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TextInputField
                label="Twitter"
                name="social.twitter"
                placeholder="https://twitter.com/seedtrace"
              />
            </Grid>
            <Grid item xs={6}>
              <TextInputField
                label="Facebook"
                name="social.facebook"
                placeholder="https://facebook.com/seedtrace"
              />
            </Grid>
            <Grid item xs={6}>
              <TextInputField
                label="Instagram"
                name="social.instagram"
                placeholder="https://intagram.com/seedtrace"
              />
            </Grid>
          </Grid>
        </Grid>
      </HasAccess>
    </Grid>
  );
};

export default CompanyFields;
