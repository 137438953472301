import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';

const Container = styled('div')(({ theme }) => ({
  borderRadius: 4,
  padding: theme.spacing(0.5),
  background: theme.palette.info.light,
  display: 'inline-flex',
}));

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TabItem = styled(({ active, ...props }) => <div {...props} />)(({ theme, active }) => ({
  background: active ? '#fff' : 'transparent',
  color: theme.palette.info.main,
  fontSize: 14,
  textTransform: 'uppercase',
  padding: theme.spacing(1),
  fontWeight: 700,
  cursor: 'pointer',
  borderRadius: 4,
  width: 180,
  textAlign: 'center',
}));

const TabTopNavigation = ({ items, activeIndex, onChange, className }) => {
  return (
    <Container className={className}>
      {items.map((item, index) => (
        <TabItem
          key={`tab-item-${index}`}
          active={activeIndex === index}
          onClick={() => onChange(index)}
        >
          {item}
        </TabItem>
      ))}
    </Container>
  );
};

TabTopNavigation.propTypes = {
  items: PropTypes.array.isRequired,
  activeIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default TabTopNavigation;
