import {
  ClickAwayListener,
  FormHelperText,
  InputAdornment,
  TextField,
  TextFieldProps,
} from '@mui/material';
import { CalendarEvent } from '@styled-icons/bootstrap/CalendarEvent';
import { getIn, useFormikContext } from 'formik';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { Calendar } from 'react-date-range';
import { styled, useTheme } from '@mui/material/styles';

const DATE_FORMAT = 'YYYY-MM-DD';

const Container = styled('div')(({ theme }) => ({
  position: 'relative',
  '& .Mui-disabled': {
    color: theme.palette.grey[500],
    background: theme.custom.colors.backgroundMedium,
  },
}));

type PlacementOptions = 'TOP' | 'BOTTOM';

type AlignmentOptions = 'LEFT' | 'RIGHT';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CalendarContainer = styled('div')<{ placement: PlacementOptions; align: AlignmentOptions }>(
  ({ placement, align }) => ({
    position: 'absolute',
    top: placement === 'TOP' ? -350 : 35,
    left: align === 'LEFT' ? 0 : 'unset',
    right: align === 'RIGHT' ? 0 : 'unset',
    zIndex: 999,
  })
);

const StyledCalendar = styled(Calendar)(() => ({
  boxShadow: '0 1px 3px 1px rgba(0, 0, 0, 0.2)',
}));

const Icon = styled(CalendarEvent)(() => ({
  cursor: 'pointer',
}));

interface Props {
  name: string;
  placeholder: string;
  /**
   * @default "BOTTOM"
   */
  placement?: PlacementOptions;
  /**
   * @default "LEFT"
   */
  align?: AlignmentOptions;
  'data-cy': string;
  disabled: boolean;
  dependentField?: string;
  yearsAddedFromDependentField?: number | string;
}

/**
 * Badly implemented data picker field which is not reusable for other forms
 * @deprecated -> use `DatePickerField` from `design-system` instead
 */
const DatePickerField: React.FC<Props & TextFieldProps> = ({
  placeholder,
  placement = 'BOTTOM',
  align = 'LEFT',
  'data-cy': dataCy = '',
  disabled = false,
  dependentField = null,
  yearsAddedFromDependentField = null,
  ...props
}) => {
  const { name } = props;
  const { setFieldValue, errors, touched, validateOnMount, values } = useFormikContext<{
    [name: string]: string | undefined;
  }>();
  const value = values[name];
  const { palette } = useTheme();
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const fieldError = getIn(errors, name);
  const showError = (getIn(touched, name) || validateOnMount) && !!fieldError;

  useEffect(() => {
    // sometimes the date is dependent on another date.
    // if the other field and the years to be added to it are specified, this is set here.
    if (yearsAddedFromDependentField && dependentField) {
      const issuedDate = values[dependentField];
      if (issuedDate) {
        setFieldValue(
          name,
          moment(issuedDate).add(yearsAddedFromDependentField, 'years').format(DATE_FORMAT)
        );
      }
    }
  }, [values, yearsAddedFromDependentField, dependentField, setFieldValue, name]);

  const handleChange = (value: Date) => {
    setFieldValue(name, moment(value).format(DATE_FORMAT));
  };

  return (
    <Container>
      <TextField
        value={value ? value : ''}
        onClick={() => !disabled && setOpenDatePicker(true)}
        variant="outlined"
        placeholder={placeholder}
        fullWidth
        disabled={disabled}
        error={showError}
        data-cy={dataCy}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Icon size={20} />
            </InputAdornment>
          ),
        }}
      />
      {openDatePicker && (
        <ClickAwayListener onClickAway={() => setOpenDatePicker(false)}>
          <CalendarContainer placement={placement} align={align}>
            <StyledCalendar
              color={palette.primary.main}
              date={value ? new Date(value) : new Date()}
              onChange={handleChange}
              fixedHeight
            />
          </CalendarContainer>
        </ClickAwayListener>
      )}
      {showError && <FormHelperText error>{fieldError}</FormHelperText>}
    </Container>
  );
};

export default DatePickerField;
