import * as Yup from 'yup';

export type IBatchMitigation = {
  batchId: string;
  regionalRisk: {
    documents: string[];
    comment: string;
    riskNegligible: boolean;
  };
  plotRisk: {
    documents: string[];
    comment: string;
    riskNegligible: boolean;
  };
}[];

export type IPartnerMitigation = {
  partners: {
    companyId: string;
    riskQuestions: {
      id: string;
      question: string;
      answer: string;
      riskNegligible: boolean;
      comment: string;
      documents: string[];
    }[];
  }[];
  generalRiskNegligible: boolean;
  comment: string;
  documents: string[];
};

export const batchMitigationSchema = Yup.object().shape({
  batchId: Yup.string(),
  regionalRisk: Yup.object().shape({
    documents: Yup.array().of(Yup.string()).default([]),
    comment: Yup.string(),
    riskNegligible: Yup.boolean(),
  }),
  plotRisk: Yup.object().shape({
    documents: Yup.array().of(Yup.string()).default([]),
    comment: Yup.string(),
    riskNegligible: Yup.boolean(),
  }),
});

export const batchesMitigationSchema = Yup.array().of(batchMitigationSchema).default([]);

export const riskQuestionSchema = Yup.object().shape({
  id: Yup.string(),
  question: Yup.string(),
  answer: Yup.string(),
  riskNegligible: Yup.boolean(),
  comment: Yup.string(),
  documents: Yup.array().of(Yup.string()).default([]),
});

export const partnerMitigation = Yup.object().shape({
  companyId: Yup.string(),
  riskQuestions: Yup.array().of(riskQuestionSchema).default([]),
});

export const partnerMitigationSchema = Yup.object().shape({
  partners: Yup.array().of(partnerMitigation).default([]),
  generalRiskNegligible: Yup.boolean(),
  comment: Yup.string(),
  documents: Yup.array().of(Yup.string()).default([]),
});
