import { PaginationContainer } from 'components/TableBase';
import React, { FC, useState } from 'react';
import { ISite } from 'types/site.types';
import { Box, TablePagination } from '@mui/material';
import SiteItem from './SiteItem';

interface ISitesPaginatedTableProps {
  sites: ISite[];
  onSiteClick?: (site: ISite) => void;
  onSiteEdit?: (site: ISite) => void;
  onSiteDelete?: (site: ISite) => void;
  onEditCultivationArea?: (site: ISite) => void;
}

const SITE_ROWS_PER_PAGE = 3;

const SitesPaginatedTable: FC<ISitesPaginatedTableProps> = ({
  sites,
  onSiteClick,
  onSiteEdit,
  onSiteDelete,
  onEditCultivationArea,
}) => {
  const [currentPage, setCurrentPage] = useState<number>(0);

  return (
    <>
      {sites
        .slice(
          currentPage * SITE_ROWS_PER_PAGE,
          currentPage * SITE_ROWS_PER_PAGE + SITE_ROWS_PER_PAGE
        )
        .map(site => (
          <Box key={`${site.id}-table-row`} mb={1} width="100%">
            <SiteItem
              site={site}
              onEdit={onSiteEdit}
              onClick={onSiteClick}
              onDelete={onSiteDelete}
              onEditCultivationArea={onEditCultivationArea}
            />
          </Box>
        ))}
      {sites.length > SITE_ROWS_PER_PAGE && (
        <PaginationContainer>
          <TablePagination
            component="div"
            rowsPerPageOptions={[]}
            count={sites.length}
            rowsPerPage={SITE_ROWS_PER_PAGE}
            page={currentPage}
            onPageChange={(_, newPage) => setCurrentPage(newPage)}
            labelDisplayedRows={({ page }) =>
              `${(page + 1) * SITE_ROWS_PER_PAGE - (SITE_ROWS_PER_PAGE - 1)} - ${
                (page + 1) * SITE_ROWS_PER_PAGE
              } of ${sites.length}`
            }
          />
        </PaginationContainer>
      )}
    </>
  );
};

export default SitesPaginatedTable;
