import { gql } from '@apollo/client';
import { IMAGE_VARIANT_FRAGMENT } from './media.fragments';

export const COMPANY_DESIGN_FRAGMENT = gql`
  fragment companyDesignValues on CompanyDesign {
    typography {
      header
    }
    palette {
      primary
    }
  }
`;

export const COMPANY_LOG_FRAGMENT = gql`
  fragment companyLogValues on CompanyLog {
    id
    comment
    userEmail
    user {
      firstName
      lastName
      email
    }
    company {
      id
      name
      logo {
        ...imageVariant
      }
    }
    timestamp
    action
  }
  ${IMAGE_VARIANT_FRAGMENT}
`;

export const COMPANY_PLAN_FRAGMENT = gql`
  fragment companyPlanDetails on CustomerPlan {
    name
    customerPlanId
    expired
    expirationDate
    upgradeRequested
    planType
    features
    daysLeftInTrial @client
  }
`;

export const BASE_COMPANY_FRAGMENT = gql`
  fragment companyBaseValues on Company {
    id
    name
    logo {
      ...imageVariant
    }
    modifiedTimestamp
    createdTimestamp
    status
    externalId
    sitesCount
    activitiesCount
  }
  ${IMAGE_VARIANT_FRAGMENT}
`;

export const COMPANY_FRAGMENT = gql`
  fragment companyValues on Company {
    ...companyBaseValues
    website
    onlineShop
    address {
      street
      streetNumber
      zipCode
      city
      country
    }
    design {
      ...companyDesignValues
    }
    social {
      facebook
      twitter
      instagram
    }
    dataValid
    planDetails {
      ...companyPlanDetails
    }
    gaTrackingId
  }
  ${BASE_COMPANY_FRAGMENT}
  ${COMPANY_DESIGN_FRAGMENT}
  ${COMPANY_PLAN_FRAGMENT}
`;
