import { FieldWrapper } from 'designSystem';
import { FastField as Field, useField } from 'formik';
import React from 'react';
import { StyledDropDown } from '../styles';
import { ILocationVisibility } from 'types/supplyChain.types';

const LocationVisibilityBlock = () => {
  const [field, , helper] = useField<keyof typeof ILocationVisibility | undefined>(
    'locationVisibility'
  );
  const activityVisibilityOptions = Object.keys(ILocationVisibility);

  const handleValueChange = (locationVisibility: keyof typeof ILocationVisibility) => {
    helper.setValue(locationVisibility);
  };

  return (
    <FieldWrapper label="Location visibility">
      <Field
        component={StyledDropDown}
        size="SMALL"
        name="locationVisibility"
        variant="outlined"
        fullWidth
        currentValue={field?.value}
        options={activityVisibilityOptions}
        onChange={handleValueChange}
        renderItems={(item: ILocationVisibility) => {
          const indexOfItem = Object.keys(ILocationVisibility).indexOf(item);
          const valueofItem = Object.values(ILocationVisibility)[indexOfItem];
          return <div>{valueofItem}</div>;
        }}
      />
    </FieldWrapper>
  );
};

export default LocationVisibilityBlock;
