import React from 'react';
import { DialogFormFrame } from 'components/Forms';
import TermsContent from './TermsContent';
import ThemeButton from 'designSystem/Buttons/ThemeButton/ThemeButton';

const TermsDialog = ({ open, onClose }) => {
  return (
    <DialogFormFrame
      title={'Terms and Conditions'}
      open={open}
      onClose={onClose}
      formComponent={<TermsContent />}
      maxSize
      actions={
        <ThemeButton onClick={onClose} color="YELLOW" size="large">
          Close
        </ThemeButton>
      }
    />
  );
};
export default TermsDialog;
