import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { PaginationContainer, StyledTable } from 'components/TableBase';
import ImpactClaimTableBody from './ImpactClaimTableBody';
import { TablePagination } from '@mui/material';
import { SortableTableHead } from 'components/TableBase';
import { headerColumns } from './constants';
import { styled } from '@mui/material/styles';

const Table = styled(StyledTable)(({ theme }) => ({
  minWidth: 1100,
  marginBottom: theme.spacing(1),
}));

const ImpactClaimTable = ({
  actions,
  impactClaimGroups,
  hideHeader,
  rowsPerPage = 5,
  onSelect,
  'data-cy': dataCy = '',
  displayGroup = true,
}) => {
  const [currentPage, setCurrentPage] = useState(0);

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <Fragment>
      <Table aria-labelledby="tableTitle" aria-label="enhanced table" data-cy={dataCy}>
        {!hideHeader && <SortableTableHead headerColumns={headerColumns} />}
        <ImpactClaimTableBody
          data-cy={dataCy}
          rowsPerPage={rowsPerPage}
          currentPage={currentPage}
          actions={actions}
          impactClaimGroups={impactClaimGroups}
          onSelect={onSelect}
          displayGroup={displayGroup}
        />
      </Table>
      {impactClaimGroups.length > rowsPerPage && (
        <PaginationContainer>
          <TablePagination
            component="div"
            rowsPerPageOptions={[]}
            count={impactClaimGroups.length}
            rowsPerPage={rowsPerPage}
            page={currentPage}
            onPageChange={handleChangePage}
            labelDisplayedRows={({ page }) =>
              `Page ${page + 1} of ${Math.ceil(impactClaimGroups.length / rowsPerPage)}`
            }
          />
        </PaginationContainer>
      )}
    </Fragment>
  );
};

ImpactClaimTable.propTypes = {
  impactClaimGroups: PropTypes.array.isRequired,
  rowsPerPage: PropTypes.number,
  hideHeader: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  displayGroup: PropTypes.bool,
};

export default ImpactClaimTable;
