import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { useProductActions } from 'components/hooks';
import { Plus } from '@styled-icons/bootstrap/Plus';
import { Edit } from '@styled-icons/boxicons-solid/Edit';
import { PublishButton } from 'components/Home';
import ThemeButton from 'designSystem/Buttons/ThemeButton/ThemeButton';
import { PublishProductProvider } from 'contexts/PublishProductContext';

const GettingStartedActions = ({ overallStatus, highlightedProduct }) => {
  const { handleCreateProduct, navigateToProductEditor } = useProductActions();

  const isEmpty = overallStatus === 'EMPTY' || !highlightedProduct;
  const isReadyToPublish =
    overallStatus === 'DRAFT_COMPLETE' || overallStatus === 'MULTIPLE_DRAFT_COMPLETE';
  const isDraftStatus = overallStatus === 'DRAFT';
  const isMultipleDraftStatus = overallStatus === 'MULTIPLE_DRAFT';

  const onClickEdit = () => {
    navigateToProductEditor(highlightedProduct.id);
  };

  return (
    <Box display="flex" gap={1.5}>
      {isEmpty && (
        <ThemeButton
          onClick={handleCreateProduct}
          startIcon={<Plus size={20} />}
          data-cy="button-add-product"
          size="large"
        >
          Add product
        </ThemeButton>
      )}
      {highlightedProduct && isReadyToPublish && (
        <Fragment>
          <PublishProductProvider product={highlightedProduct} disableValidation>
            <PublishButton data-cy="button-publish-product" />
            <ThemeButton
              onClick={onClickEdit}
              size="large"
              color="BLUE_ICE"
              data-cy="button-edit-product"
            >
              Edit product
            </ThemeButton>
          </PublishProductProvider>
        </Fragment>
      )}
      {isDraftStatus && (
        <Fragment>
          {
            <ThemeButton
              onClick={onClickEdit}
              startIcon={<Edit size={18} />}
              data-cy="button-edit-product"
              size="large"
            >
              Edit product
            </ThemeButton>
          }
          {
            <ThemeButton
              onClick={handleCreateProduct}
              startIcon={<Plus size={20} />}
              color="BLUE_ICE"
              data-cy="button-add-product"
              size="large"
            >
              Add new
            </ThemeButton>
          }
        </Fragment>
      )}
      {isMultipleDraftStatus && (
        <Fragment>
          {
            <ThemeButton
              onClick={onClickEdit}
              startIcon={<Edit size={18} />}
              data-cy="button-edit-product"
              size="large"
            >
              Edit product
            </ThemeButton>
          }
        </Fragment>
      )}
    </Box>
  );
};

GettingStartedActions.propTypes = {
  overallStatus: PropTypes.oneOf([
    'EMPTY',
    'DRAFT',
    'DRAFT_COMPLETE',
    'MULTIPLE_DRAFT',
    'MULTIPLE_DRAFT_COMPLETE',
    'PUBLISHED',
  ]),
  highlightedProduct: PropTypes.object,
};

export default GettingStartedActions;
