import { IconButton, TableCell, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { StyledTableRow } from 'components/TableBase';
import React from 'react';
import { convertHexToRGBarray } from 'utils';

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

export const IconContainer = styled('div')(() => ({
  display: 'flex',
  width: 90,
}));

export const Date = styled(Typography)(() => ({
  width: 140,
}));

export const StyledCell = styled(TableCell)(() => ({
  overflow: 'hidden',
}));

export const TableRow = styled(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ transparent, isInvalid, hasShadow, ...props }) => <StyledTableRow {...props} />
)(({ theme, transparent, isInvalid, hasShadow }) => {
  if (transparent && !hasShadow) {
    return {
      position: 'relative',
      boxShadow: 'none',
      '& > td': {
        borderBottom: `1px dashed ${theme.custom.colors.blueFrost} !important`,
        borderTop: `1px dashed ${theme.custom.colors.blueFrost}`,
        '&:first-of-type': {
          borderLeft: `1px dashed ${theme.custom.colors.blueFrost}`,
        },
        '&:last-child': {
          borderRight: `1px dashed ${theme.custom.colors.blueFrost}`,
        },
      },
    };
  }

  return {
    position: 'relative',
    backgroundColor: isInvalid
      ? `rgba(${convertHexToRGBarray(theme.custom.themeColors.error[60])}, 0.2)`
      : '#fff',
  };
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const OpacityContainer = styled(({ transparent, ...props }) => <div {...props} />)(
  ({ transparent }) => ({
    opacity: transparent ? 0.5 : 1,
  })
);
