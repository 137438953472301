import { AccordionDetails, AccordionSummary, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Warning } from '@styled-icons/ionicons-outline/Warning';
import { FlexBox } from 'components/Structure';
import { BorderedContainer, ThemeTypography } from 'designSystem';
import { Accordion } from 'designSystem/DataDisplay/Accordion/Accordion';
import React, { FC } from 'react';
import { DisclaimerTarget } from 'types/impactClaim.types';

const StyledWarning = styled(Warning)(({ theme }) => ({
  marginRight: theme.spacing(0.5),
  color: theme.palette.primary.main,
}));

interface IClaimDisclaimer {
  renderedOn?: DisclaimerTarget;
}

const ClaimDisclaimer: FC<IClaimDisclaimer> = ({
  renderedOn = DisclaimerTarget.SELECT_CLAIM_SLIDE,
}: IClaimDisclaimer) => {
  const isRenderedOnSelectSlide = renderedOn === DisclaimerTarget.SELECT_CLAIM_SLIDE;

  const title = 'Regulation disclaimer';
  const description = `When submitting ${
    isRenderedOnSelectSlide ? 'this impact claim' : 'a request'
  }, please be
  aware that you are responsible for the correct management and use of certificates. This
  includes ensuring that all regulations and conditions for the use of the certificate are
  met. It is not seedtrace’s legal obligation to examine the lawfulness of your usage of
  the respective certificate.`;

  if (isRenderedOnSelectSlide) {
    return (
      <Accordion transparent>
        <AccordionSummary>
          <FlexBox>
            <StyledWarning size={16} />
            <Box mr={1} />
            <ThemeTypography variant="BODY_LARGE_BOLD" color="GRAY">
              {title}
            </ThemeTypography>
          </FlexBox>
        </AccordionSummary>
        <AccordionDetails>
          <ThemeTypography variant="BODY_LARGE">{description}</ThemeTypography>
        </AccordionDetails>
      </Accordion>
    );
  }

  return (
    <BorderedContainer>
      <FlexBox mb={1}>
        <StyledWarning size={16} />
        <Box mr={1} />
        <ThemeTypography variant="BODY_LARGE_BOLD" color="GRAY">
          {title}
        </ThemeTypography>
      </FlexBox>
      <ThemeTypography variant="BODY_LARGE">{description}</ThemeTypography>
    </BorderedContainer>
  );
};

export default ClaimDisclaimer;
