import { useConfig } from 'components/hooks';
import queryString from 'query-string';
import React, { FC, PropsWithChildren, useEffect } from 'react';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import { StringParam, useQueryParam } from 'use-query-params';
import { useLastEditedLang } from './hooks';

const EditorRoute: FC<PropsWithChildren> = ({ children }) => {
  const { id } = useParams();
  const { lastEditedLang, lastEditedLangIsDefault, setLastEditedLang } = useLastEditedLang(id);

  const { pathname, search } = useLocation();
  const config = useConfig();
  const [selectedLang] = useQueryParam(config.appQueryParams.lang, StringParam);

  useEffect(() => {
    if (!selectedLang) return;

    setLastEditedLang(id, selectedLang);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLang]);

  if (selectedLang || lastEditedLangIsDefault || !lastEditedLang) {
    return <>{children}</>;
  }

  return (
    <Navigate
      to={{
        pathname,
        search: queryString.stringify({
          ...queryString.parse(search),
          [config.appQueryParams.lang]: lastEditedLang,
        }),
      }}
    />
  );
};

export default EditorRoute;
