import { Box, Tooltip, Typography } from '@mui/material';
import React from 'react';
import truncate from 'lodash/truncate';
import { styled } from '@mui/material/styles';
import { FileEarmarkText, TagFill } from '@styled-icons/bootstrap';
import { ThemeTypography } from 'designSystem';
import { DocumentCategories, DocumentStatus, DocumentVisibilityStatus } from 'types/document.types';
import { IDocument } from 'types/document.types';
import { getVisibilityIcon } from 'components/DocumentLibrary/constants/documentVisibility';
import { NewTabIcon } from 'components/Drawer/StyledDrawerItems';
import { booleanish, Booleanish } from 'types/booleanish.types';

const Status = styled(Typography)<{ status: keyof typeof DocumentStatus }>(({ status, theme }) => ({
  fontSize: 13,
  marginRight: 8,

  color:
    status === 'VALID'
      ? theme.palette.success.main
      : status === 'EXPIRED'
      ? theme.palette.error.main
      : theme.custom.colors.textLight,
}));

const DocumentIcon = styled(FileEarmarkText)(({ theme }) => ({
  marginRight: theme.spacing(1.5),
}));

const TagIcon = styled(TagFill)(({ theme }) => ({
  marginRight: theme.spacing(0.5),
}));
const CertificateLogo = styled('img')(({ theme }) => ({
  width: 30,
  marginRight: theme.spacing(1.5),
}));

const ClickableBox = styled(Box)<{ 'is-clickable': booleanish }>(
  ({ 'is-clickable': isClickable }) => ({
    cursor: isClickable === 'true' ? 'pointer' : 'default',
  })
);

interface IDocumentIntroTitleProps {
  document: IDocument;
  showOpenInNewTab?: boolean;
}

const DocumentIntroTitle: React.FC<IDocumentIntroTitleProps> = ({ document, showOpenInNewTab }) => {
  const { title, category, status, visibility } = document;
  const formattedCategory = category ? DocumentCategories[category] : '';
  const isCertificate =
    category &&
    DocumentCategories[category] === DocumentCategories.CERTIFICATE &&
    document?.certificateCatalogItem;
  const documentTitle = isCertificate && document ? document.certificateCatalogItem?.title : title;

  const handleOpenDocument = () => {
    if (showOpenInNewTab) {
      window.open(document.url || '');
    }
  };

  return (
    <Box display="flex" alignItems="center">
      {isCertificate ? (
        <CertificateLogo src={document?.certificateCatalogItem?.imageUrl} />
      ) : (
        <DocumentIcon size={30} />
      )}

      <Box>
        <ClickableBox
          display="flex"
          alignItems="center"
          is-clickable={Booleanish(showOpenInNewTab)}
          onClick={handleOpenDocument}
        >
          <Tooltip title={documentTitle || ''}>
            <ThemeTypography variant="ITEM_TITLE">
              {truncate(documentTitle, {
                length: 45,
                separator: '',
              })}
            </ThemeTypography>
          </Tooltip>

          <NewTabIcon size={14} />
        </ClickableBox>

        <Box display="flex" alignItems="center" gap={0.8}>
          <Status variant="body2" status={status}>
            {status}
          </Status>

          {formattedCategory && (
            <ThemeTypography variant="BODY_SMALL" color="GRAY_80">
              <Box display="flex" alignItems="center" gap={0.5}>
                <TagIcon size={11} />
                {formattedCategory}
              </Box>
            </ThemeTypography>
          )}

          {visibility && (
            <ThemeTypography variant="BODY_SMALL" color="GRAY_80">
              <Box display="flex" alignItems="center" gap={0.5}>
                {getVisibilityIcon(visibility, 11)}
                {DocumentVisibilityStatus[visibility]}
              </Box>
            </ThemeTypography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default DocumentIntroTitle;
