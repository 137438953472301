const descendingComparator = (a, b, orderBy) => {
  const itemA = typeof a[orderBy] === 'string' ? a[orderBy].toLowerCase() : a[orderBy];
  const itemB = typeof b[orderBy] === 'string' ? b[orderBy].toLowerCase() : b[orderBy];

  if (!itemA) {
    return 1;
  }
  if (!itemB) {
    return -1;
  }

  if (itemB < itemA) {
    return -1;
  }
  if (itemB > itemA) {
    return 1;
  }
  return 0;
};

export const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

export const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;

    return a[1] - b[1];
  });

  return stabilizedThis.map(el => el[0]);
};

export const filterItems = (items, searchTerm) => {
  if (!items) return items;

  if (!searchTerm?.length) return items.map(item => item.node);

  const filtered = items.filter(({ node: { title } }) => {
    return title?.toLowerCase().includes(searchTerm.toLowerCase());
  });

  return filtered.map(item => item.node);
};
