import { styled } from '@mui/material';
import React from 'react';
import { InfoCircleFill } from '@styled-icons/bootstrap/InfoCircleFill';
import hintIcon from 'assets/img/icons/idea.svg';
import { ThemeTypography } from 'designSystem';

interface Props {
  variant: 'INFO' | 'HINT';
  helperText: string;
}

const Icon = styled('div')(({ theme }) => ({
  marginRight: theme.spacing(1),
  display: 'flex',
  alignItems: 'center',
}));

const HintIcon = styled('img')(() => ({
  width: 16,
  pointerEvents: 'none',
}));

const HelperTextWrapper = styled('div')(({ theme }) => ({
  color: theme.palette.grey[500],
  display: 'flex',
  alignItems: 'center',
}));

export const InlineHelperText: React.FC<Props> = ({ variant, helperText }) => {
  return (
    <HelperTextWrapper>
      <Icon>{variant === 'INFO' ? <InfoCircleFill size={14} /> : <HintIcon src={hintIcon} />}</Icon>

      <ThemeTypography variant="BODY_MEDIUM" color="GRAY">
        {helperText}
      </ThemeTypography>
    </HelperTextWrapper>
  );
};
