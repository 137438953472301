import { Box, Link } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React from 'react';

const StyledLink = styled(Link)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const LinkWithIcon = ({ size = 16, Icon, children, ...rest }) => {
  return (
    <StyledLink {...rest}>
      {Icon && (
        <Box mr={0.5} display="flex" alignItems="center">
          <Icon size={size} />
        </Box>
      )}
      {children}
    </StyledLink>
  );
};

LinkWithIcon.propTypes = {
  size: PropTypes.number,
  Icon: PropTypes.object,
  children: PropTypes.node,
  // other link props
};

export default LinkWithIcon;
