import React from 'react';
import { Box } from '@mui/material';
import { ThemeTypography } from 'designSystem';
import { Edit } from '@styled-icons/boxicons-solid/Edit';
import { LinkWithIcon } from 'components/Forms';
import QRDownloadButton from './QRDownloadButton';
import QRCode from './QRCode';
import { styled } from '@mui/material/styles';
import { ProductQrCodeDesign } from 'types/types';

const QRContainer = styled('div')(({ theme }) => ({
  width: 160,
  height: 160,
  margin: theme.spacing(2, 3, 0, 0),
  border: `2px solid ${theme.custom.colors.backgroundLight}`,
  borderRadius: 4,
}));

interface Props {
  productId: string;
  qrCode: ProductQrCodeDesign;
  setQREditorOpen: (open: boolean) => void;
  productTitle: string;
}

const CustomQRCodeTab: React.FC<Props> = ({
  productId,
  qrCode,
  productTitle,
  setQREditorOpen,
}: Props) => {
  return (
    <Box>
      <ThemeTypography variant="BODY_MEDIUM">
        For your product packaging, print material and more.
      </ThemeTypography>
      <Box display="flex" alignItems="center" mt={0.5}>
        <QRContainer>
          <QRCode productId={productId} qrCode={qrCode} />
        </QRContainer>
        <Box>
          <Box mb={2}>
            <LinkWithIcon onClick={() => setQREditorOpen(true)} Icon={Edit}>
              Edit QR design
            </LinkWithIcon>
          </Box>
          <Box>
            <QRDownloadButton productId={productId} qrCode={qrCode} title={productTitle} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CustomQRCodeTab;
