import { Box, BoxProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ChevronBack } from '@styled-icons/ionicons-sharp';
import { usePreviousPathname } from 'contexts/TrackNavigationEvents';
import { ThemeTypography } from 'designSystem';
import React, { FC, FormEvent, Fragment, PropsWithChildren, ReactElement } from 'react';
import { NavLink, matchPath, useNavigate } from 'react-router-dom';
import BreadCrumbs from './BreadCrumbs';

const StyledBackLink = styled(NavLink)(({ theme }) => ({
  display: 'block',
  color: theme.custom.colors.almostBlack,
  textDecoration: 'none',
  transition: theme.custom.defaultTransition('color'),
  fontSize: 14,
  marginTop: theme.spacing(1),
  fontWeight: 400,
  maxWidth: 'fit-content', // Bug: Prevents the link from taking the full width of the container and being clickable outside of the text
  '&:hover': {
    color: theme.custom.colors.actionButtonHover,
  },
}));

const StyledCrumbs = styled(BreadCrumbs)({
  position: 'absolute',
  top: 0,
  transform: 'translateY(-110%)',

  '& .MuiBreadcrumbs-ol': {
    flexWrap: 'nowrap',
  },

  '& a': {
    whiteSpace: 'nowrap',
  },
});

/**
 * If true, the back link will navigate back in history instead of going to the specified goBackUrl
 * If you also set goBackUrl, the back in history will compare the url first and then navigate back if it matches
 */
type GoBackType = { goBackUrl?: string; autoBackNavigation?: boolean };

type IPageTitleProps = {
  title: string;
  titleExtension?: string;
  breadcrumbs?: { label: string; to: string }[];
  className?: string;
  goBackLabel?: string;
  TitleAdornment?: ReactElement;
  goBackExtension?: ReactElement;
} & GoBackType;

const PageTitle: FC<PropsWithChildren<IPageTitleProps> & BoxProps> = ({
  title,
  titleExtension,
  breadcrumbs,
  className,
  children,
  TitleAdornment,
  goBackLabel,
  goBackExtension,
  ...props
}) => {
  const previousPathname = usePreviousPathname();
  const navigate = useNavigate();

  const { goBackUrl, autoBackNavigation } = {
    goBackUrl: undefined,
    autoBackNavigation: false,
    ...props,
  };

  /**
   * In case of autoBackNavigation, the link will navigate back in history instead of going to the specified goBackUrl, if the previous pathname matches the goBackUrl
   * This has the advantage of restoring the previous state and scroll position
   */
  const handleAutoBackNavigation = (event: FormEvent<HTMLAnchorElement>) => {
    if (
      autoBackNavigation &&
      previousPathname &&
      (!goBackUrl || (goBackUrl && matchPath(previousPathname, goBackUrl)))
    ) {
      // Cancel the default behavior of the link navigation to goBackUrl
      event.preventDefault();
      navigate(-1);
    }
  };

  return (
    <Fragment>
      <Box
        display="flex"
        justifyContent="space-between"
        position="relative"
        className={className}
        mt={1}
        {...props}
      >
        {breadcrumbs && <StyledCrumbs breadcrumbs={breadcrumbs} />}
        <Box display="flex" alignItems="center">
          <ThemeTypography variant="TITLE_MEDIUM" data-cy="page-title">
            {title}
          </ThemeTypography>
          {titleExtension && (
            <Box ml={2}>
              <ThemeTypography variant="TITLE_MEDIUM" color="GRAY" data-cy="page-title-extension">
                - {titleExtension}
              </ThemeTypography>
            </Box>
          )}
          {TitleAdornment}
        </Box>
        {children}
      </Box>
      <Box display="flex" alignItems="baseline" gap={2}>
        {(goBackUrl || autoBackNavigation) && (
          <StyledBackLink
            to={goBackUrl ? goBackUrl : ''}
            onClick={handleAutoBackNavigation}
            data-testid="navigate-back-link"
          >
            <ChevronBack size={16} data-tut="onboarding_tour__exit" /> {goBackLabel || 'Go back'}
          </StyledBackLink>
        )}
        {goBackExtension && <Box>{goBackExtension}</Box>}
      </Box>
    </Fragment>
  );
};

export default PageTitle;
