import { gql } from '@apollo/client';
import { IMAGE_FRAGMENT } from 'graphql/fragments';

export const GET_IMAGES = gql`
  query GetImages($first: Int!, $after: String) {
    getImages(first: $first, after: $after) {
      edges {
        node {
          ...imageValues
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${IMAGE_FRAGMENT}
`;

export const GET_IMAGE = gql`
  query GetImage($id: UUID!) {
    getImage(id: $id) {
      ...imageValues
    }
  }
  ${IMAGE_FRAGMENT}
`;

export const GET_IMAGE_CLIENT = gql`
  query GetImageClient($id: UUID) {
    getImageClient(id: $id) @client(always: true) {
      ...imageValues
    }
  }
  ${IMAGE_FRAGMENT}
`;
