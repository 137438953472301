import * as Yup from 'yup';

const registrationSchema = Yup.object().shape({
  email: Yup.string()
    .default('')
    .email('Please enter a valid email')
    .required('This field is required'),
  password: Yup.string()
    .default('')
    .required('This field is required.')
    .min(6, 'Please enter at least 6 characters'),
  firstName: Yup.string().default('').required('This field is required.'),
  lastName: Yup.string().default('').required('This field is required.'),
  companyName: Yup.string().default('').required('This field is required.'),
  industry: Yup.string().default('').required('This field is required.'),
  termsAccepted: Yup.bool()
    .default(false)
    .oneOf([true], 'Please accept the terms and conditions')
    .required('Please accept the terms and conditions'),
});

export default registrationSchema;
