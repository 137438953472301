import React from 'react';
import { styled } from '@mui/material/styles';
import { useSelectable } from 'components/MediaLibrary/hooks';
import isEmpty from 'lodash/isEmpty';
import ThemeButton from 'designSystem/Buttons/ThemeButton/ThemeButton';

const ButtonWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100',
  marginTop: theme.spacing(2),
}));

// !-- Make sure this component is being used within a SelectableProvider Context, otherwise the hooks won't work --!!
const LibraryActionButtons = ({ onClose }) => {
  const { onSubmit, selectedItem } = useSelectable();

  return (
    <ButtonWrapper>
      <ThemeButton
        onClick={() => {
          onClose();
        }}
        color="BLUE_ICE"
        size="large"
      >
        Cancel
      </ThemeButton>
      <ThemeButton
        onClick={() => onSubmit(selectedItem)}
        color="YELLOW"
        size="large"
        disabled={isEmpty(selectedItem)}
        data-cy="select-media-lib-item-btn"
      >
        Select
      </ThemeButton>
    </ButtonWrapper>
  );
};

export default LibraryActionButtons;
