import React, { useCallback } from 'react';
import { useQueryReturn } from 'components/hooks';
import { GET_FONTS } from 'graphql/queries';
import PropTypes from 'prop-types';
import AsyncAutocomplete from './AsyncAutocomplete';

const FontsAutocomplete = ({ resultLimit, ...props }) => {
  const getFonts = useQueryReturn(GET_FONTS, {
    fetchPolicy: 'no-cache',
  });
  const request = useCallback(
    query =>
      getFonts({
        variables: {
          query,
        },
      }),
    [getFonts]
  );

  const getOptionSelected = useCallback((option, value) => option === value, []);

  const getOptionLabel = useCallback(option => option, []);

  return (
    <AsyncAutocomplete
      label=""
      queryKey="getFonts"
      request={request}
      getOptionSelected={getOptionSelected}
      getOptionLabel={getOptionLabel}
      resultLimit={resultLimit}
      fieldProps={props}
      fetchDelay={2000}
    />
  );
};

AsyncAutocomplete.propTypes = {
  resultLimit: PropTypes.number,
};

export default FontsAutocomplete;
