import React, { FC } from 'react';
import { DialogDefault } from 'designSystem';
import { FieldWrapper, ThemeButton } from 'designSystem';
import { Form, Formik, Field } from 'formik';
import { Grid, Box } from '@mui/material';
import { PartnerDenyRequestSchema } from 'constants/schemas/partners.schema';
import { ReactComponent as UnknownDocument } from 'assets/img/icons/unknown-document.svg';
import usePartnerRequestActions from 'components/Partners/PartnerRequests/usePartnerRequestActions';
import { useMessages } from 'components/hooks';
import { Loader } from 'components/Forms';
import { GET_PARTNER_REQUEST } from 'graphql/queries';
import { useQuery } from '@apollo/client';
import { GetPartnerRequestData } from 'graphql/queries/partner.queries';
import RequestTitle from './RequestTitle';
import { ReceivedRequestCard } from 'components/Home/Requests';
import { PartnerRequest } from 'types/partner.types';
import { removeRequestFromCachePartnerHome } from 'components/Partners/utils/partnerUtils';
import HTMLEditorField from 'designSystem/Formik/HTMLEditorField/HTMLEditorField';
import { IDefaultDialogProps } from 'types/dialog.types';

interface FormInputs {
  requestMessage: string;
}

interface IDenyRequestDialogProps extends IDefaultDialogProps {
  requestId: string | undefined | null;
}

const DenyRequestDialog: FC<IDenyRequestDialogProps> = ({ open, onClose, requestId }) => {
  const { updatePartnerRequest, loading } = usePartnerRequestActions();
  const { setErrorMessage, setSuccessMessage } = useMessages();
  const { data, loading: loadingRequest } = useQuery<GetPartnerRequestData>(GET_PARTNER_REQUEST, {
    variables: {
      id: requestId,
    },
  });

  const handleDismissRequest = (values: FormInputs) => {
    updatePartnerRequest({
      variables: {
        id: requestId as string,
        input: {
          requestStatus: 'DENIED',
          requestReplyMessage: values.requestMessage,
        },
      },

      onError: () =>
        setErrorMessage('Something went wrong dismissing the request. Please try again later.'),
      onCompleted: () => {
        setSuccessMessage('You have dismissed the request');
        onClose?.();
      },
      update: (cache, { data }) => {
        if (data) {
          removeRequestFromCachePartnerHome(cache, data.updatePartnerRequest.partnerRequest);
        }
      },
    });
  };

  return (
    <DialogDefault
      open={!!open}
      title="Dismiss request"
      onClose={onClose}
      maxWidth="sm"
      icon={UnknownDocument}
      data-cy="dismiss-request-dialog"
    >
      <Box px={3} py={1} mb={2} minWidth={480} minHeight={300}>
        {loadingRequest && !data ? (
          <Loader />
        ) : (
          <Box>
            <Box mb={3}>
              <RequestTitle request={data?.partnerRequest as PartnerRequest} />
            </Box>
            <Box mb={3}>
              <ReceivedRequestCard
                request={data?.partnerRequest as PartnerRequest}
                displayActions={false}
              />
            </Box>
            <Formik
              initialValues={PartnerDenyRequestSchema.default() as FormInputs}
              validateOnBlur={false}
              validateOnChange={true}
              validationSchema={PartnerDenyRequestSchema}
              onSubmit={handleDismissRequest}
            >
              {({ values, isValid }) => (
                <Form>
                  <Grid container direction="column" spacing={3}>
                    <Grid item xs={12}>
                      <FieldWrapper label="Describe why you cannot fulfill this request">
                        <Field
                          component={HTMLEditorField}
                          name="requestMessage"
                          minHeight={122}
                          placeholder="Write your message..."
                          data-cy="request-form-message"
                        />
                      </FieldWrapper>
                    </Grid>
                    <Grid item xs={12}>
                      <Box display="flex" justifyContent="space-between">
                        <ThemeButton size="medium" onClick={onClose} color="BLUE_ICE">
                          Cancel
                        </ThemeButton>
                        <ThemeButton
                          loading={loading}
                          disabled={!values['requestMessage'] || !isValid}
                          size="medium"
                          color="YELLOW"
                          type="submit"
                          data-cy="dismiss-send-btn"
                        >
                          Dismiss & Send message
                        </ThemeButton>
                      </Box>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Box>
        )}
      </Box>
    </DialogDefault>
  );
};

export default DenyRequestDialog;
