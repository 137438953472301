export const convertHexToRGBarray = (
  hexCode: string,
  opacity?: number
): [number, number, number] | [number, number, number, number] => {
  let hex = hexCode.replace('#', '');
  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
  }
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  if (opacity !== undefined) {
    return [r, g, b, opacity];
  }

  return [r, g, b];
};

/**
 *  Apply opacity to a hex color without being transparent
 */
export const convertColorWithoutOpacity = (hexCode: string, opacity: number): string => {
  let hex = hexCode.replace('#', '');

  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
  }

  const r = Math.floor(parseInt(hex.substring(0, 2), 16) * opacity + 0xff * (1 - opacity));
  const g = Math.floor(parseInt(hex.substring(2, 4), 16) * opacity + 0xff * (1 - opacity));
  const b = Math.floor(parseInt(hex.substring(4, 6), 16) * opacity + 0xff * (1 - opacity));

  return `#${((r << 16) | (g << 8) | b).toString(16)}`;
};
