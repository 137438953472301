import { useMutation } from '@apollo/client';
import { useDocumentUpload } from 'components/DocumentLibrary/hooks';
import { useImageUpload, useVideoUpload } from 'components/MediaLibrary/hooks';
import { DELETE_IMAGE, DELETE_VIDEO } from 'graphql/mutations';
import { GET_IMAGES, GET_VIDEOS } from 'graphql/queries';
import update from 'immutability-helper';
import findIndex from 'lodash/findIndex';
import remove from 'lodash/remove';
import React, { createContext, useEffect, useMemo, useReducer, useState } from 'react';
import { v4 as uuid } from 'uuid';

const initialState = {
  fileMap: {},
  fileIDs: [],
  createdRecords: [],
  addFiles: () => undefined,
  cleanFiles: () => undefined,
  removeFile: () => undefined,
  handleCleanCreatedRecords: () => undefined,
  undoFileUploads: () => undefined,
};

const UploadContext = createContext(initialState);

const reducer = (state, { type, payload }) => {
  switch (type) {
    // Cleans up the files, can be used to reset the local state to be used for a new upload cycle.
    case 'cleanFiles': {
      const { fileIDs, fileMap } = state;
      const uploadingFiles = remove(fileIDs, id => {
        const { error, success } = fileMap[id];
        return !error && !success;
      });

      return {
        fileMap,
        fileIDs: uploadingFiles,
      };
    }

    // Removes files from the local state, does NOT remove the file from the backend.
    case 'removeFile': {
      const { id } = payload;
      const { fileMap, fileIDs } = state;

      const updatedFileIDs = fileIDs.filter(fileId => fileId !== id);
      const updatedFileMap = {
        ...fileMap,
      };
      delete updatedFileMap[id];
      return {
        fileMap: updatedFileMap,
        fileIDs: updatedFileIDs,
      };
    }

    // Updates the file in the local state
    case 'updateFile': {
      const { id, data } = payload;
      const { fileMap } = state;

      return {
        ...state,
        fileMap: {
          ...fileMap,
          [id]: {
            ...fileMap[id],
            ...data,
          },
        },
      };
    }

    // Adds a temporary file to the state.
    // This get's a locally generated uuid which is a placeholder representation of the file.
    case 'addFiles':
      const files = payload.files.map(({ file, itemType }) => ({
        data: file,
        id: uuid(),
        recordId: null,
        itemType,
        tempURL: URL.createObjectURL(file),
        progress: 0,
        error: null,
        success: null,
        uploadTask: null,
        uploadStarted: false,
      }));

      const rejected = payload.rejected.map(({ error, file }) => ({
        data: file,
        id: uuid(),
        itemType: 'rejected',
        error,
      }));

      const contextObject = payload.contextObject;

      const items = [...rejected, ...files];

      return {
        fileIDs: [...state.fileIDs, ...items.map(({ id }) => id)],
        fileMap: {
          ...state.fileMap,
          ...items.reduce(
            (result, item) => ({
              ...result,
              [item.id]: {
                ...item,
                contextObject,
              },
            }),
            {}
          ),
        },
      };
    default:
      return state;
  }
};

const UploadProvider = ({ children }) => {
  const [createdRecords, setCreatedRecords] = useState([]);
  const [{ fileIDs, fileMap }, dispatch] = useReducer(reducer, {
    fileIDs: [],
    fileMap: {},
  });
  const [uploadImage] = useImageUpload();
  const [uploadVideo] = useVideoUpload();
  const [uploadDocument] = useDocumentUpload();

  const getUploadHook = itemType => {
    switch (itemType) {
      case 'image':
        return uploadImage;
      case 'video':
        return uploadVideo;
      // todo: consolidate to pdf type
      case 'document':
        return uploadDocument;
      default:
        return uploadImage;
    }
  };

  const addFiles = (files = [], rejected = [], contextObject = {}) =>
    dispatch({
      type: 'addFiles',
      payload: {
        files,
        rejected,
        contextObject,
      },
    });

  const updateFile = (id, data) => {
    return dispatch({
      type: 'updateFile',
      payload: {
        id,
        data,
      },
    });
  };

  const removeFile = id =>
    dispatch({
      type: 'removeFile',
      payload: {
        id,
      },
    });

  const [deleteImageMutation] = useMutation(DELETE_IMAGE, {
    update: (cache, data) => {
      const {
        getImages: { edges: images, pageInfo },
      } = cache.readQuery({
        query: GET_IMAGES,
      });

      const removedIndex = findIndex(images, ['node.id', data?.data?.deleteImage?.imageId]);

      cache.writeQuery({
        query: GET_IMAGES,
        data: {
          getImages: {
            edges: update(images, {
              $splice: [[removedIndex, 1]],
            }),
            pageInfo,
          },
        },
      });
    },
  });

  const [deleteVideoMutation] = useMutation(DELETE_VIDEO, {
    update: (cache, data) => {
      const { getVideos: videos, pageInfo } = cache.readQuery({
        query: GET_VIDEOS,
      });
      const removedIndex = findIndex(videos.edges, ['node.id', data?.data?.deleteVideo?.videoId]);
      cache.writeQuery({
        query: GET_VIDEOS,
        data: {
          getVideos: {
            edges: update(videos.edges, {
              $splice: [[removedIndex, 1]],
            }),
            pageInfo,
          },
        },
      });
    },
  });

  // Undoes any file uploads that have been made. Clears them locally and also executes calls to delete the images from the backend.
  const undoFileUploads = async () => {
    Object.entries(fileMap).forEach(async ([, value]) => {
      if (value.success !== true) {
        // If value.success is not true, then the item is still uploading
        // If the item is still uploading we should not try to remove it since it does not exist yet.
        return;
      }

      if (value.itemType === 'video') {
        await deleteVideoMutation({
          variables: {
            id: value.recordId,
          },
        });
      } else if (value.itemType === 'image') {
        await deleteImageMutation({
          variables: {
            id: value.recordId,
            // imageInput: {
            // The recordId is the id that the backend returns. The id on the object is a locally generated temporary uuid()
            // Therefore to delete, we need to use the recordId
            // },
          },
        });
      }
    });

    // Once the images are deleted, clean up the upload container state as well.
    // This then clears all the ids and locally stored uploads
    cleanFiles();
  };

  const cleanFiles = () =>
    dispatch({
      type: 'cleanFiles',
    });

  const filesToUpload = useMemo(() => {
    return fileIDs
      .filter(id => {
        const { uploadStarted, error } = fileMap[id];

        return !uploadStarted && !error;
      })
      .map(id => fileMap[id]);
  }, [fileIDs]); // eslint-disable-line

  useEffect(() => {
    const uploadedRecords = [];

    filesToUpload.forEach(async ({ id, data, itemType, uploadStarted, contextObject }) => {
      // Only continue with upload, if upload of the document has not started yet.
      // At each intermediate step the local state of the document is updated (e.g. upload started, upload progress and upload successful).
      if (uploadStarted) return;

      updateFile(id, {
        uploadStarted: true,
      });
      // get upload hook for specific item type (we distinguish between image, video and document)
      const uploadFile = getUploadHook(itemType);
      const onError = () =>
        updateFile(id, {
          error: 'Upload failed',
        });

      const { uploadTask, error } = await uploadFile({
        file: data,
        uploadItemId: id,
        ...contextObject,
        onError,
        onProgress: progress =>
          updateFile(id, {
            progress,
          }),
        onSuccess: () =>
          updateFile(id, {
            success: true,
          }),
        // This is triggered when the file was actually created on the backend and returned
        // The record represents the actual image that was returned from the backend.
        onRecordCreated: record => {
          uploadedRecords.push(record);
          const { id: recordId, url } = record;
          // Updates the file with the recordID ( which is the id actually in the backend )
          // Also adds the url attribute to the uploadItem to an image can be showed in the frontend for the upload item.
          updateFile(id, {
            recordId,
            url,
          });
        },
      });
      setCreatedRecords([...createdRecords, ...uploadedRecords]);

      if (error) {
        onError();
        return;
      }

      updateFile(id, {
        uploadTask,
      });
    });
  }, [filesToUpload]); // eslint-disable-line

  const handleCleanCreatedRecords = () => {
    setCreatedRecords([]);
  };

  return (
    <UploadContext.Provider
      value={{
        fileMap,
        fileIDs,
        createdRecords,
        cleanFiles,
        addFiles,
        removeFile,
        handleCleanCreatedRecords,
        undoFileUploads,
      }}
    >
      {children}
    </UploadContext.Provider>
  );
};

export { UploadProvider };

export default UploadContext;
