import { useEffect, memo } from 'react';
import { useFormikContext } from 'formik';

const FormikEffect = ({ onChange, validate, dirtyCheck = true }) => {
  const { values, dirty, isValid } = useFormikContext();

  useEffect(() => {
    if (!dirty && dirtyCheck) return;
    if (validate && !isValid) return;

    onChange(values);
  }, [values]); //eslint-disable-line

  return null;
};

export default memo(FormikEffect);
