import { Box } from '@mui/material';
import { Tour } from '@styled-icons/material/Tour';
import ChainMapping from 'components/ChainMapping/ChainMapping';
import ChainTasksDetails from 'components/ChainMapping/components/ChainTasksDetails';
import CreateChainPlaceholder from 'components/ComponentsLibrary/CreateChainPlaceholder';
import { Loader } from 'components/Forms';
import { ErrorState, PageContainer, PageTitle } from 'components/Structure';
import useSupplyChainMapping from 'components/SupplyChainsLibrary/hooks/useSupplyChainMapping';
import { STEP_BY_STEP_LIST_HEIGHT } from 'constants/chainMapping';
import { ThemeButton } from 'designSystem';
import React, { FC } from 'react';

const SupplyChainMapping: FC = () => {
  const {
    supplyChain,
    queryLoading,
    mutationLoading,
    error,
    expandList,
    handleChainMappingAction,
    handleCreateNewChainClick,
    handleCreateByCopyChainClick,
    handleExpandList,
    handleClickOnboardingTour,
  } = useSupplyChainMapping();

  if (queryLoading || !supplyChain) {
    return <Loader />;
  }
  if (error || !supplyChain) {
    return <ErrorState />;
  }

  return (
    <PageContainer>
      <Box display="flex" alignItems="end">
        <Box width="100%">
          <PageTitle
            title="Supply chain mapping"
            titleExtension={supplyChain.title}
            goBackLabel="Supply chain overview"
            goBackUrl={`/supply-chains/${supplyChain.productId}/${supplyChain.id}}`}
          />
        </Box>
        <ThemeButton
          color="WHITE"
          data-cy="restart-tour-button"
          size="small"
          onClick={handleClickOnboardingTour}
          startIcon={<Tour size={16} />}
        >
          Restart guided tour
        </ThemeButton>
      </Box>
      <Box position="relative" mt={2}>
        <ChainTasksDetails
          hideEdit
          expanded={expandList}
          height={STEP_BY_STEP_LIST_HEIGHT}
          title={supplyChain.title}
          image={supplyChain.image}
          isSupplyChain
          chainTasksDetails={supplyChain.chainTasksDetails}
          onExpand={handleExpandList}
        />
      </Box>
      {mutationLoading && <Loader overlayOpacity />}
      <Box height="80%" mt={`${STEP_BY_STEP_LIST_HEIGHT + 35}px`}>
        {!supplyChain.mappingInitialised ? (
          <CreateChainPlaceholder
            isCopyButtonVisible={false} // Disabled until the proper duplication feature is implemented in the backend
            startFromScratchButtonLabel="Empty chain"
            onStartFromSketchClick={handleCreateNewChainClick}
            onCopyChainClick={handleCreateByCopyChainClick}
          />
        ) : (
          <ChainMapping
            viewMode="default"
            chainMapping={supplyChain}
            onActionClick={handleChainMappingAction}
          />
        )}
      </Box>
    </PageContainer>
  );
};

export default SupplyChainMapping;
