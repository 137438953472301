import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const ActionContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  top: 10,
  zIndex: 1,
  display: 'flex',
  alignItems: 'center',
  background: theme.palette.background.default,
}));

export default ActionContainer;
