const columns = [
  {
    id: 'title',
    headerName: 'Title',
    sortable: false,
  },
  {
    id: 'level',
    headerName: 'Impact Level',
    sortable: false,
  },
  {
    id: 'attachmentCount',
    headerName: 'Proof',
    sortable: false,
  },
  {
    id: 'createdTimestamp',
    headerName: 'Created on',
    sortable: false,
  },
  {
    id: 'actions',
    headerName: 'Actions',
    sortable: false,
    size: 'small',
  },
];

export default columns;
