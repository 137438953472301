import { Skeleton } from 'designSystem';
import React, { ComponentProps, FC } from 'react';
import theme from 'styles/theme';
import { BarElementPath } from '@mui/x-charts';

const CustomBarElement: FC<ComponentProps<typeof BarElementPath>> = props => {
  const { ownerState, style } = props;
  if (
    ownerState.id === 'loading' &&
    style &&
    style?.x &&
    style?.y &&
    style?.height &&
    style?.width
  ) {
    return (
      <foreignObject
        // @ts-ignore
        x={style.x.animation.to}
        // @ts-ignore
        y={style.y.animation.to}
        // @ts-ignore
        width={style.width.animation.to}
        // @ts-ignore
        height={style.height.animation.to}
      >
        <Skeleton
          backgroundColor={theme.custom.themeColors.grayScale[80]}
          variant="rectangular"
          height="100%"
          width="100%"
        />
      </foreignObject>
    );
  }

  return <BarElementPath {...props} />;
};

export default CustomBarElement;
