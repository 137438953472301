import { styled } from '@mui/material/styles';
import ThemeButton from 'designSystem/Buttons/ThemeButton/ThemeButton';

const DangerButton = styled(ThemeButton)(({ theme }) => ({
  borderColor: theme.palette.error.light,
  background: theme.palette.error.main,
  color: theme.palette.error.light,

  '&:hover': {
    background: theme.palette.error.dark,
    color: theme.palette.error.light,
    borderColor: theme.palette.error.dark,
  },
}));

export default DangerButton;
