import { gql } from '@apollo/client';
import { FIRST_MILE_IMPORT_FRAGMENT } from 'graphql/fragments/import.fragments';

export const CREATE_FIRST_MILE_IMPORT = gql`
  mutation CreateFirstMileImport($input: CreateFirstMileImportInput!, $lang: Language) {
    createFirstMileImport(input: $input, lang: $lang) {
      firstMileImport {
        ...firstMileImportValues
      }
    }
  }
  ${FIRST_MILE_IMPORT_FRAGMENT}
`;

export const DELETE_DATA_IMPORT = gql`
  mutation DeleteDataImport($id: UUID!) {
    deleteFirstMileImport(id: $id) {
      id
    }
  }
`;
