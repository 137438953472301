import React, { FC } from 'react';
import { Box, styled } from '@mui/material';
import { ThemeTypography } from 'designSystem';
import { booleanish, Booleanish } from 'types/booleanish.types';

interface IBreadcrumbProps {
  breadcrumbs: string[];
}

const Breadcrumb = styled(Box)<{ last?: booleanish; isWhite?: booleanish }>(
  ({ theme, last, isWhite }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: last === 'true' ? '0 20px 20px 0' : 'none',
    background: isWhite === 'true' ? 'white' : theme.custom.themeColors.grayScale[20],
    padding: theme.spacing(1.5, 2.5, 1.5, 2),
    boxShadow: theme.custom.shadows[5],
    position: 'relative',
    height: 40,

    '&:after':
      last === 'false'
        ? {
            content: '""',
            position: 'absolute',
            top: 0,
            right: -10,
            borderTop: '20px solid transparent', // Half of the breadcrumb height
            borderBottom: '20px solid transparent', // Half of the breadcrumb height
            borderLeft: '10px solid white',
          }
        : {},
  })
);

const Text = styled(ThemeTypography)(({ theme }) => ({
  textTransform: 'uppercase',
}));

const Breadcrumbs: FC<IBreadcrumbProps> = ({ breadcrumbs }) => {
  return (
    <Box display="flex">
      {breadcrumbs.length <= 1 && (
        <Breadcrumb isWhite={Booleanish(true)} last={Booleanish(true)}>
          <Text variant="LABEL_INPUT" color="BLACK">
            {breadcrumbs[0]}
          </Text>
        </Breadcrumb>
      )}
      {breadcrumbs.length > 1 &&
        breadcrumbs.map((breadcrumb, index) => (
          <Breadcrumb
            isWhite={Booleanish(index !== breadcrumbs.length - 1)}
            zIndex={breadcrumbs.length - index}
            last={Booleanish(index === breadcrumbs.length - 1)}
            key={index}
          >
            <Text variant="LABEL_INPUT" color="BLACK">
              {breadcrumb}
            </Text>
          </Breadcrumb>
        ))}
    </Box>
  );
};

export default Breadcrumbs;
