import { Box, IconButton, useMediaQuery } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { Edit } from '@styled-icons/boxicons-solid/Edit';
import { ExternalLink } from '@styled-icons/evaicons-solid/ExternalLink';
import ProductExampleImage from 'assets/img/product-placeholder-image-big.svg';
import { useProductActions } from 'components/hooks';
import { EditStatus } from 'components/Product/Overview/ProductStatus';
import HasAccess from 'components/Structure/HasAccess';
import ThemeButton from 'designSystem/Buttons/ThemeButton/ThemeButton';
import React, { FC } from 'react';
import ContentStatus from '../../ProductTable/ContentStatus';
import ProductLanguageSettings from '../ProductLanguageSettings/ProductLanguageSettings';
import { FlexBox } from 'components/Structure';
import { ThemeTypography } from 'designSystem';
import { Product } from 'types/types';
import Image from 'designSystem/DataDisplay/Image/Image';
import { CustomerPlan } from 'types/company.types';

interface IProductStatusCardProps {
  product: Product;
}

const Container = styled(Box)(({ theme }) => ({
  position: 'relative',
  flex: '1',
  border: `${theme.custom.colors.lightBorderColor} 1px solid`,
  padding: theme.spacing(2),
  backgroundColor: theme.palette.common.white,
  borderRadius: 6,
}));

const ProductTitle = styled(ThemeTypography)(({ theme }) => ({
  fontFamily: `${theme.typography.fontFamily} !important`,
  fontWeight: '900 !important',
  fontSize: '32px !important',
}));

const VerticalDivider = styled('div')(({ theme }) => ({
  display: 'block',
  width: 1,
  background: theme.custom.colors.lightestBorder,
  height: 100,
}));

const StyledEditIcon = styled(Edit)(({ theme }) => ({
  color: theme.custom.themeColors.grayScale[40],
}));

const ProductStatusCard: FC<IProductStatusCardProps> = ({ product }) => {
  const { title, image, styledProgress, id, isPublished, slug, articleNumber } = product;

  const { navigateToProductEditor, openPreview, openProductLive, handleEditProduct } =
    useProductActions();

  const theme = useTheme();
  const mediumScreenAndSmaller = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Container>
      <Box
        display="flex"
        flexDirection={mediumScreenAndSmaller ? 'column' : 'row'}
        alignItems={mediumScreenAndSmaller ? 'flex-start' : 'center'}
        justifyContent="space-between"
        data-cy="product-status-card"
        gap={1}
      >
        <Box display="flex" alignItems="center">
          <Image image={image} backupImageUrl={ProductExampleImage} width={150} height={150} />
          <Box ml={2}>
            <FlexBox mb={1}>
              <ProductTitle variant="TITLE_LARGE">{title}</ProductTitle>
              {articleNumber && (
                <FlexBox ml={2}>
                  <ThemeTypography variant="TITLE_EXTRA_SMALL" color="GRAY_40">
                    |
                  </ThemeTypography>
                  <Box mr={1} />
                  <ThemeTypography
                    variant="TITLE_EXTRA_SMALL"
                    autoOverflow
                    maxWidth={200}
                    color="GRAY_40"
                  >
                    {articleNumber}
                  </ThemeTypography>
                </FlexBox>
              )}
              <IconButton onClick={() => handleEditProduct(product)}>
                <StyledEditIcon size={20} />
              </IconButton>
            </FlexBox>
            {styledProgress && <ContentStatus {...styledProgress} />}
            <Box display="flex" gap={2} mt={2}>
              <ThemeButton
                onClick={() => navigateToProductEditor(id)}
                color="YELLOW"
                size="large"
                data-cy="button-edit-product"
                startIcon={<Edit size={14} />}
              >
                {styledProgress?.contentStatus === 100 && !isPublished
                  ? 'Edit & publish product page'
                  : 'Edit product page'}
              </ThemeButton>
              {isPublished ? (
                <HasAccess allowedPlans={[CustomerPlan.PAYING]}>
                  <ThemeButton
                    onClick={() =>
                      openProductLive({
                        productId: id,
                        slug,
                      })
                    }
                    color="BLUE_ICE"
                    size="large"
                    data-cy="button-view-product-live"
                    startIcon={<ExternalLink size={14} />}
                  >
                    View product page live
                  </ThemeButton>
                </HasAccess>
              ) : (
                <HasAccess allowedPlans={[CustomerPlan.PAYING]}>
                  <ThemeButton
                    onClick={() =>
                      openPreview({
                        productId: id,
                        slug,
                      })
                    }
                    color="BLUE_ICE"
                    size="large"
                    data-cy="button-view-product-preview"
                    startIcon={<ExternalLink size={14} />}
                  >
                    View preview
                  </ThemeButton>
                </HasAccess>
              )}
            </Box>
          </Box>
        </Box>
        <Box display="flex" alignItems="center">
          {!mediumScreenAndSmaller && <VerticalDivider />}
          <Box pr={4} pl={mediumScreenAndSmaller ? 0 : 4}>
            <EditStatus product={product} />
          </Box>
          <VerticalDivider />
          <Box px={4}>
            <ProductLanguageSettings product={product} />
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default ProductStatusCard;
