import { ClickAwayListener } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { FC, useState } from 'react';
import { HexColorPicker } from 'react-colorful';

interface IColorPickerProps {
  color: string;
  handleChange: (color: string) => void;
}

const ColorPickerCircle = styled('div')<{ backgroundColor: string }>(
  ({ backgroundColor, theme }) => ({
    backgroundColor: backgroundColor,
    borderRadius: theme.spacing(4),
    width: 40,
    height: 40,
    border: `1px solid ${theme.palette.grey[400]}`,
    cursor: 'pointer',
  })
);

const ColorPickerContainer = styled('div')(() => ({
  position: 'relative',

  '&  .react-colorful': {
    position: 'absolute',
    top: -195,
    left: -190,
    zIndex: 1,
  },
}));

const ColorPicker: FC<IColorPickerProps> = ({ color, handleChange }) => {
  const [open, setOpen] = useState(false);

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <ColorPickerContainer>
        <ColorPickerCircle backgroundColor={color} onClick={() => setOpen(!open)} />
        {open && <HexColorPicker color={color} onChange={handleChange} />}
      </ColorPickerContainer>
    </ClickAwayListener>
  );
};

export default ColorPicker;
