/**
 * Get the state of the home page based on the products
 * @param {Array} products
 * @returns {'EMPTY' | 'DRAFT' | 'DRAFT_COMPLETE' | 'MULTIPLE_DRAFT' | 'MULTIPLE_DRAFT_COMPLETE' | 'PUBLISHED'}
 */
export function getOverallStatus(products) {
  if (!products || !products.length) {
    // No products
    return 'EMPTY';
  }

  const includesPublished = products.some(product => product.status === 'PUBLISHED');
  if (includesPublished) {
    return 'PUBLISHED';
  }

  if (products.length === 1) {
    // Only one product available
    const singleProduct = products[0];
    if (singleProduct.contentStatus === 100) {
      return 'DRAFT_COMPLETE';
    } else return 'DRAFT';
  }

  // Multiple products
  const includesCompletedDraft = products.some(product => product.contentStatus === 100);
  if (includesCompletedDraft) {
    return 'MULTIPLE_DRAFT_COMPLETE';
  } else return 'MULTIPLE_DRAFT';
}

/**
 * Get the title based on the status
 * @param {'EMPTY' | 'DRAFT' | 'DRAFT_COMPLETE' | 'MULTIPLE_DRAFT' | 'MULTIPLE_DRAFT_COMPLETE' | 'PUBLISHED'} status
 * @returns {string}
 */
export function getHomeTitle(status) {
  return {
    EMPTY: 'Create your first product to start telling the story of your supply chains',
    DRAFT: 'Complete and publish your product to start telling the story of your supply chains',
    MULTIPLE_DRAFT:
      'Complete and publish your product to start telling the story of your supply chains',
    DRAFT_COMPLETE: 'Publish your product to start telling the story of your supply chains',
    MULTIPLE_DRAFT_COMPLETE:
      'Publish your product to start telling the story of your supply chains',
    PUBLISHED: null, // Handled in different spot, since there's multiple cards for this scenario
  }[status];
}

/**
 * Sort the products by highest content status
 * @param {object} products
 * @returns
 */
export function sortOnContentStatus(products) {
  return products.sort((a, b) => b.contentStatus - a.contentStatus);
}

/**
 * Find product with status DRAFT
 * @param {object} products
 * @returns
 */
export function getProductsInDraft(products) {
  return products.filter(product => product.status === 'DRAFT');
}
