import { Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { GeoAlt } from '@styled-icons/bootstrap/GeoAlt';
import { ThemeTypography } from 'designSystem';
import isNull from 'lodash/isNull';
import PropTypes from 'prop-types';
import React from 'react';
import Moment from 'react-moment';
import StarRatings from 'react-star-ratings';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Container = styled(({ first, ...props }) => <div {...props} />)(({ theme, first }) => ({
  padding: first ? theme.spacing(0, 0, 2) : theme.spacing(2, 0),
  borderBottom: `1px solid ${theme.palette.grey[300]}`,
}));

const Header = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

const Location = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[400],
  fontWeight: 300,
  display: 'flex',
  marginBottom: theme.spacing(1),
  alignItems: 'center',
  fontSize: 14,
}));

const Date = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[500],
}));

const Pin = styled(GeoAlt)(({ theme }) => ({
  marginRight: theme.spacing(0.5),
}));

const FeedbackListItem = ({
  reaction: { rating, feedback, city, country, createdTimestamp },
  first,
}) => {
  const { palette } = useTheme();
  const location = (
    <Location variant="body2">
      <Pin size={14} />
      <span>
        {city}, {country}
      </span>
    </Location>
  );
  const hasRating = !isNull(rating);

  return (
    <Container first={first}>
      <Header>
        {!hasRating ? (
          location
        ) : (
          <StarRatings
            rating={rating}
            starEmptyColor={palette.grey[300]}
            starRatedColor={palette.primary.main}
            starHoverColor={palette.primary.main}
            numberOfStars={5}
            name="rating"
            starDimension="16px"
            disabled
          />
        )}
        <Date variant="body2">
          <Moment format="DD/MM/YYYY">{createdTimestamp}</Moment>
        </Date>
      </Header>
      {hasRating && location}
      <ThemeTypography variant="BODY_LARGE">{feedback}</ThemeTypography>
    </Container>
  );
};

FeedbackListItem.propTypes = {
  reaction: PropTypes.shape({
    rating: PropTypes.number,
    feedback: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string,
    createdTimestamp: PropTypes.string.isRequired,
  }),
  first: PropTypes.bool,
};

export default FeedbackListItem;
