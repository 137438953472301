import { useMutation } from '@apollo/client';
import { styled } from '@mui/material/styles';
import { Loader } from 'components/Forms';
import { useConfig } from 'components/hooks';
import { ErrorState } from 'components/Structure';
import { UPDATE_QR_DESIGN } from 'graphql/mutations';
import React, { FC, useEffect, useState } from 'react';
import { ProductQrCodeDesign } from 'types/types';

interface IQRCodeProps {
  qrCode: ProductQrCodeDesign;
  productId: string;
}

const Container = styled('div')({
  position: 'relative',
  width: '100%',
  height: '100%',
});

const QRCode: FC<IQRCodeProps> = ({ qrCode, productId }) => {
  const config = useConfig();
  const [internalData, setInternalData] = useState<string>();

  const [updateQRDesign, { loading, error }] = useMutation<{ updateQRDesign: string }>(
    UPDATE_QR_DESIGN,
    {
      onCompleted: ({ updateQRDesign }) => {
        setInternalData(updateQRDesign);
      },
    }
  );

  useEffect(() => {
    updateQRDesign({
      variables: {
        qrCodeDesign: {
          design: {
            ...qrCode.design,
            qr_code_text: config.generateRedirectURL(productId),
            image_format: 'SVG',
          },
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(qrCode)]);

  if (loading && !internalData) {
    return (
      <Container>
        <Loader />
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <ErrorState />
      </Container>
    );
  }

  return (
    <Container
      data-cy="qr-code"
      className={loading ? 'shimmer' : ''}
      dangerouslySetInnerHTML={
        internalData
          ? {
              __html: internalData,
            }
          : undefined
      }
    />
  );
};

export default QRCode;
