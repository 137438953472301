import { Box, List, ListItem, SvgIcon, styled } from '@mui/material';
import { ActiveMenuBefore } from 'assets/img/menu';
import FlexBox from 'components/Structure/FlexBox';
import ThemeTypography from 'designSystem/Primitives/Typography/ThemeTypography';
import { CSSProperties, FC } from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { ExternalLink as ExternalLinkIcon } from '@styled-icons/evaicons-solid/ExternalLink';
import { themeColors } from 'styles/themeColors';
import { Booleanish, booleanish } from 'types/booleanish.types';
import React from 'react';

export const StyledSubMenuIcon = styled('svg')<{
  activeClassStyle?: CSSProperties | { [key: string]: CSSProperties };
}>(({ theme, activeClassStyle }) => ({
  color: theme.palette.common.white,
  alignSelf: 'middle',

  '&.active': activeClassStyle ? activeClassStyle : { color: theme.palette.secondary.main },
}));

export const ExternalLink = styled('a')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  color: theme.palette.common.white,
  textDecoration: 'none',
  transition: '0.125s',

  '&:hover': {
    boxShadow: theme.custom.menuHoverShadow,
  },
}));

export const MainNavigationButtonExternal = styled(ExternalLink)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  color: theme.palette.common.white,
  textDecoration: 'none',
  transition: '0.125s',
  background: theme.palette.secondary.main,
  cursor: 'pointer',

  '&.active, &:hover': {
    '&:before': {
      content: '""',
      position: 'absolute',
      height: '100%',
      width: 8,
    },
  },

  '&:hover': {
    background: theme.palette.secondary.light,

    '&.active': {
      background: theme.palette.secondary.light,

      '&:before': {
        backgroundColor: theme.palette.primary.main,
      },
    },
  },

  '&.active': {
    background: theme.palette.secondary.light,

    '&:before': {
      backgroundColor: theme.palette.primary.main,
    },
  },

  '&.active-submenu': {
    background: theme.palette.secondary.light,

    '&:before': {
      content: '""',
      position: 'absolute',
      width: 8,
      height: 40,
      background: theme.palette.primary.main,
      top: 0,
      zIndex: 1,
    },

    '&:after': {
      position: 'absolute',
      content: '""',
      width: 8,
      background: `url(${ActiveMenuBefore}) no-repeat`,
      top: 38,
      zIndex: 1,
      height: 62,
    },
  },
}));

export const MainNavigationButton = styled('div')(({ theme }) => ({
  '& > a': {
    position: 'relative',
    display: 'flex',
    color: theme.palette.common.white,
    textDecoration: 'none !important',
    transition: '0.125s',
    background: theme.palette.secondary.main,

    '&.active, &:hover': {
      '&:before': {
        content: '""',
        position: 'absolute',
        height: '100%',
        width: 8,
      },
    },

    '&:hover': {
      background: theme.palette.secondary.light,

      '&.active': {
        background: theme.palette.secondary.light,

        '&:before': {
          backgroundColor: theme.palette.primary.main,
        },
      },
    },

    '&.active': {
      background: theme.palette.secondary.light,

      '&:before': {
        backgroundColor: theme.palette.primary.main,
      },
    },

    '&.active-submenu': {
      background: theme.palette.secondary.light,

      '&:before': {
        content: '""',
        position: 'absolute',
        width: 8,
        height: 40,
        background: theme.palette.primary.main,
        top: 0,
        zIndex: 1,
      },

      '&:after': {
        position: 'absolute',
        content: '""',
        width: 8,
        background: `url(${ActiveMenuBefore}) no-repeat`,
        top: 38,
        zIndex: 1,
        height: 62,
      },
    },
  },
}));

/**
 * Bug in NavLink component that it can not be styled and className used as function
 */
export const MainNavigationButtonWrapper: FC<NavLinkProps> = props => {
  return (
    <MainNavigationButton>
      <NavLink {...props} />
    </MainNavigationButton>
  );
};

/**
 * Nth-child (or child index) is used to calculate the position of the prefix arrow of the active menu item
 */
export const StyledNavSubLink = styled('div')<{
  'nth-child': number;
  'drawer-expanded'?: booleanish;
}>(({ theme, 'nth-child': nthChild, 'drawer-expanded': drawerExpanded }) => ({
  '& > a': {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.common.white,
    textDecoration: 'none',
    transition: '0.125s',
    borderRadius: 4,
    alignItems: 'center',
    marginBottom: theme.spacing(0.5),
    minWidth: '30px',
    minHeight: 28,

    '& .ReactIcon-root svg': {
      color: theme.palette.common.white,
    },

    '&:hover': {
      background: theme.custom.colors.secondaryLighter,
    },

    '&.active': {
      background: theme.palette.common.white,
      color: theme.palette.secondary.main,
      minWidth: 30,

      '& .ReactIcon-root svg': {
        color: theme.palette.secondary.main,
      },

      '&:before': {
        content: drawerExpanded === 'true' ? '""' : '""',
        position: 'absolute',
        width: 8,
        top: `calc(5px - ${nthChild} * 36px)`,
        height: `calc(12px + ${nthChild} * 36px)`,
        left: -14,
        borderBottomLeftRadius: 4,
        borderLeft: `1.8px solid ${themeColors.primary[60]}`,
        borderBottom: `1.8px solid ${themeColors.primary[60]}`,
      },
    },
  },
}));

/**
 * Bug in NavLink component that it can not be styled and className used as function
 */
export const StyledNavSubLinkWrapper: FC<
  NavLinkProps & {
    nthChild: number;
    drawerExpanded: boolean;
  }
> = ({ drawerExpanded, nthChild, ...props }) => {
  return (
    <StyledNavSubLink drawer-expanded={Booleanish(drawerExpanded)} nth-child={nthChild}>
      <NavLink {...props} />
    </StyledNavSubLink>
  );
};

export const StyledSvgIcon = styled(SvgIcon)<{ 'is-child'?: booleanish }>(
  ({ theme, 'is-child': isChild = 'false' }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 19,
    height: 20,
    marginTop: isChild === 'true' ? theme.spacing(1) : 0,
  })
);

export const StyledList = styled(List)<{ 'is-child'?: booleanish; 'drawer-expanded'?: booleanish }>(
  ({ theme, 'is-child': isChild = 'false', 'drawer-expanded': drawerExpanded = 'false' }) => {
    const childPadding =
      drawerExpanded === 'true' ? theme.spacing(0, 2, 1, 6) : theme.spacing(0, 2.4, 1, 2.6);

    return {
      padding: isChild === 'true' ? childPadding : 0,
      background: isChild === 'true' ? theme.palette.secondary.light : 'transparent',
    };
  }
);

export const StyledListItem = styled(ListItem)<{ 'is-child'?: booleanish }>(
  ({ theme, 'is-child': isChild = 'false' }) => ({
    padding: isChild === 'true' ? theme.spacing(0.8, 0, 0.8, 0) : theme.spacing(2, 0, 1.8, 3.5),
  })
);

export const StyledListItemText = styled('span')<{
  'drawer-expanded': booleanish;
  'is-child'?: booleanish;
}>(({ theme, 'is-child': isChild = 'false', 'drawer-expanded': drawerExpanded }) => ({
  fontSize: isChild === 'true' ? 12 : 14,
  opacity: 1,
  fontWeight: isChild === 'true' ? 400 : 700,
  transition: 'opacity 0.125s ease, padding-left .4s ease',
  alignSelf: 'flex-end',
  paddingLeft: drawerExpanded === 'true' ? theme.spacing(isChild ? 1 : 2) : theme.spacing(4),
  whiteSpace: 'nowrap',
}));

export const SelectedBackground = styled(Box)<{
  'drawer-expanded': booleanish;
  'has-overflow': booleanish;
}>(({ theme, 'drawer-expanded': drawerExpanded, 'has-overflow': hasOverflow }) => ({
  background: theme.palette.secondary.light,
  padding: theme.spacing(0, 2, 1.25, drawerExpanded === 'true' ? 3 : 2.3),
  transition: 'padding, .3s ease',
  // Fix padding issue when scrollbar is shown inside the drawer
  marginRight: hasOverflow === 'true' ? -4 : 0,
}));

export const SubmenuLabelContainer = styled(FlexBox)<{ 'drawer-expanded': booleanish }>(
  ({ theme, 'drawer-expanded': drawerExpanded }) => ({
    position: 'relative',
    padding: theme.spacing(0.75, 0.75, 0.75, drawerExpanded ? 0.75 : 2),
    background: theme.palette.secondary.main,
    border: `1px solid ${themeColors.primary[60]}`,
    borderRadius: 4,
    justifyContent: drawerExpanded === 'true' ? 'flex-start' : 'center',
    '& .MuiTypography-root': {
      marginLeft: theme.spacing(1),
      fontWeight: '900 !important',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
  })
);

export const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  paddingRight: theme.spacing(2),
}));

export const StyledHr = styled('hr')<{ 'drawer-expanded'?: booleanish; 'is-section'?: booleanish }>(
  ({ theme, 'drawer-expanded': drawerExpanded, 'is-section': isSection }) => ({
    border: 'none',
    borderTop: '2px solid rgba(255,255,255,0.1)',
    width: drawerExpanded === 'true' ? '75%' : '40%',
    margin: isSection === 'true' ? theme.spacing(3, 'auto') : theme.spacing(4, 'auto'),
    transition: 'width .2s ease',
  })
);

export const NewTabIcon = styled(ExternalLinkIcon)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  opacity: 0.5,
}));

export const SectionLabel = styled(ThemeTypography)(() => ({
  opacity: 0.7,
  textTransform: 'uppercase',
  padding: '20px 0px 10px 28px',
}));

export const SelectionLabelContainer = styled(Box)(({ theme }) => ({
  background: theme.palette.secondary.main,
}));
