import { Box, LinearProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import ThemeTypography from 'designSystem/Primitives/Typography/ThemeTypography';
import React, { FC } from 'react';

export interface IContentStatusProps {
  status?: 'PUBLISHED' | 'DRAFT' | 'ARCHIVED';
  color?: string;
  contentStatus: number;
  size?: 'small' | 'large';
}

const BorderLinearProgress = styled(LinearProgress)<{
  percentage: number;
  height: number;
  width: number;
}>(({ theme, percentage, height, width }) => ({
  borderRadius: 2,
  height,
  width,
  backgroundColor: theme.palette.grey[300],

  '& .MuiLinearProgress-bar': {
    backgroundColor: percentage === 100 ? theme.palette.success.main : theme.palette.primary.main,
  },
}));

const Container = styled(Box)(() => ({
  display: 'flex',
  alignSelf: 'end',
}));

const SelfCenteredBox = styled(Box)(() => ({
  alignSelf: 'center',
}));

const DIMENSIONS = {
  small: {
    containerHeight: 15,
    height: 12,
    width: 108,
  },
  large: {
    containerHeight: 28,
    height: 24,
    width: 240,
  },
};

const getStatusColor = (status?: 'PUBLISHED' | 'DRAFT' | 'ARCHIVED') => {
  switch (status) {
    case 'PUBLISHED':
      return 'GREEN';
    case 'DRAFT':
    case 'ARCHIVED':
      return 'GRAY';
    default:
      return 'GRAY';
  }
};

const ContentStatus: FC<IContentStatusProps> = ({
  contentStatus,
  status,
  size = 'small',
  ...props
}) => {
  const displayDraft = status === 'DRAFT' || contentStatus !== 100;
  const { containerHeight, ...dimensions } = DIMENSIONS[size];
  const statusColor = getStatusColor(status);

  return (
    <Container {...props} height={containerHeight}>
      {displayDraft && contentStatus !== 0 && (
        <SelfCenteredBox mr={2}>
          <BorderLinearProgress
            variant="determinate"
            value={contentStatus}
            percentage={contentStatus}
            {...dimensions}
          />
        </SelfCenteredBox>
      )}
      <SelfCenteredBox>
        <ThemeTypography variant="ITEM_SUBTITLE" color={statusColor}>
          {status}
        </ThemeTypography>
      </SelfCenteredBox>
      {displayDraft && contentStatus !== 0 && (
        <SelfCenteredBox ml={1}>
          <ThemeTypography color={'GRAY'} variant="ITEM_SUBTITLE">
            - {contentStatus || 0}% COMPLETE
          </ThemeTypography>
        </SelfCenteredBox>
      )}
    </Container>
  );
};

export default ContentStatus;
