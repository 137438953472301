import React from 'react';
import { Formik, Form, FastField as Field } from 'formik';
import { Box, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FieldWrapper, ThemeButton, ThemeTypography } from 'designSystem';
import { DatePickerField, DropDown, TextInputFileExtension } from 'components/Forms';
import { documentSchema } from 'constants/schemas/documents';
import CertificationAutoComplete from '../CertificationAutoComplete';
import DocumentDateValidity from '../constants/dateValidity';
import { DocumentOverviewType } from 'types/enums';
import { DocumentCategories, DocumentVisibilityStatus } from 'types/document.types';
import { DocumentInput } from 'types/graphql';
import { IDocument } from 'types/document.types';
import { mergeDefaults } from 'components/Forms/utils';
import { OTHER_CERTIFICATE_ID } from 'constants/documents';
import useFeatureFlags, { FeatureFlag } from 'components/hooks/useFeatureFlags';
import documentVisibilityOptions, {
  VisibilityStatusKeyValue,
} from 'components/DocumentLibrary/constants/documentVisibility';
import { Trash } from '@styled-icons/bootstrap/Trash';
import { useDialog } from 'components/hooks';
import isEqual from 'lodash/isEqual';

const CertificationContainer = styled(Grid)(({ theme }) => ({
  margin: '0 4px',
}));

const CertificationBox = styled(Box)(({ theme }) => ({
  background: '#fff',
  borderRadius: 4,
  paddingTop: 12,
  paddingBottom: 12,
}));

const InUseContainer = styled(Box)(({ theme }) => ({
  background: '#fff',
  borderRadius: 4,
  borderColor: theme.custom.colors.blueFrost,
  borderWidth: 1,
  borderStyle: 'solid',
}));

const FormContainer = styled(Box)(({ theme }) => ({
  '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.custom.colors.actionButtonHover,
  },
}));

interface IDocumentForm {
  onDelete: (documentId: string) => void;
  onSubmit: (values: DocumentInput) => void;
  document: IDocument;
  documentType?: keyof typeof DocumentOverviewType;
}

const DocumentForm: React.FC<IDocumentForm> = ({ document, onSubmit, onDelete, documentType }) => {
  const { isFeatureEnabled } = useFeatureFlags();
  const { openDialog, closeDialog } = useDialog();
  const visibilitySettingEnabled = isFeatureEnabled(FeatureFlag.DOCUMENT_VISIBILITY_SETTING);
  const documentCategoryOptions = Object.values(DocumentCategories);

  const certificateCatalogItemId =
    !document.certificateCatalogItem && document.category === 'CERTIFICATE'
      ? OTHER_CERTIFICATE_ID
      : document.certificateCatalogItem?.id;

  const initialValues = mergeDefaults(documentSchema.default(), {
    ...document,
    category: document.category ? DocumentCategories[document.category] : '',
    certificateCatalogItemId: certificateCatalogItemId || null,
  });
  const documentIsUsed = !!document.usageCount && document.usageCount > 0;
  const formIsDisabled = documentIsUsed || documentType !== 'INTERNAL';

  const handleDeleteDocument = () => {
    openDialog({
      type: 'ALERT',
      props: {
        title: 'Delete document',
        displayCloseButton: true,
        text: 'Are you sure you want to delete this document? All data will be lost and you will not be able to recover this document.',
        submitText: 'Delete',
        onSubmit: () => {
          onDelete(document.id);
        },
        onCancel: () => {
          closeDialog();
        },
      },
    });
  };

  return (
    <Formik<DocumentInput>
      initialValues={initialValues}
      validationSchema={documentSchema}
      enableReinitialize
      onSubmit={values => onSubmit(values)}
    >
      {({ isSubmitting, setFieldValue, values, errors }) => {
        const isCertificate = values.category === DocumentCategories.CERTIFICATE;
        const disableDateFields = !values.category;

        const yearsFromIssuedDate = values.category
          ? DocumentDateValidity[values.category]?.yearsFromIssuedDate
          : undefined;

        return (
          <Form>
            {documentIsUsed && documentType === 'INTERNAL' && (
              <InUseContainer maxWidth={450} p={2} mb={3}>
                <ThemeTypography variant="BODY_SMALL">
                  This document is used as proof in {document.usageCount} impact claims, and can
                  therefore not be modified or deleted until the document is replaced for each
                  impact claim, or until these impact claims have been archived or deleted.
                </ThemeTypography>
              </InUseContainer>
            )}
            <FormContainer maxWidth={285}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <FieldWrapper label="Document title">
                    <Field
                      component={TextInputFileExtension}
                      name="title"
                      variant="outlined"
                      fullWidth
                      data-cy="document-name-input"
                      disabled={formIsDisabled}
                    />
                  </FieldWrapper>
                </Grid>
                <Grid item xs={12}>
                  <FieldWrapper label="Document type">
                    <Field
                      component={DropDown}
                      errorMsg={errors['category']}
                      name="category"
                      label="Select type"
                      variant="outlined"
                      fullWidth
                      currentValue={values?.category}
                      options={documentCategoryOptions}
                      onChange={(item: DocumentCategories) => setFieldValue('category', item)}
                      data-cy="document-category-dropdown"
                      renderItems={(item: DocumentCategories) => <div>{item}</div>}
                      disabled={formIsDisabled}
                    />
                  </FieldWrapper>
                </Grid>
                {isCertificate && !formIsDisabled && (
                  <CertificationContainer item xs={12}>
                    <CertificationBox px={1.5}>
                      <FieldWrapper label="Select certification">
                        <Field
                          name="certificateCatalogItemId"
                          component={CertificationAutoComplete}
                        />
                      </FieldWrapper>
                    </CertificationBox>
                  </CertificationContainer>
                )}
                {visibilitySettingEnabled && (
                  <Grid item xs={12}>
                    <FieldWrapper
                      label="Confidentiality"
                      tooltip={{
                        helperText: (
                          <div>
                            <p>
                              The confidentiality level decides who can view your document. Only
                              users from your organisation can edit this setting.
                            </p>
                            <p>
                              <b>Public</b>: Visible to everyone, including consumers when document
                              is used as proof for an impact claim.
                            </p>
                            <p>
                              <b>Private</b>: Users from your organisation can view this document.
                              It can also be reviewed by seedtrace when used as proof for impact
                              claims, but is hidden to all others.
                            </p>
                          </div>
                        ),
                        variant: 'INFO',
                      }}
                    >
                      <Field
                        component={DropDown}
                        name="visibility"
                        variant="outlined"
                        fullWidth
                        disabled={formIsDisabled}
                        currentValue={values?.visibility}
                        options={documentVisibilityOptions}
                        keyField="key"
                        onChange={(item: DocumentVisibilityStatus) =>
                          setFieldValue('visibility', item)
                        }
                        data-cy="document-visibility-dropdown"
                        renderItems={(item: VisibilityStatusKeyValue) => (
                          <Box display="flex" gap={1} alignItems="center">
                            <span>{item.icon}</span>
                            <span>{item.value}</span>
                          </Box>
                        )}
                      />
                    </FieldWrapper>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <FieldWrapper label="Issued Date">
                    <DatePickerField
                      disabled={disableDateFields || formIsDisabled}
                      name="issuedDate"
                      placeholder="Select date"
                      data-cy="date-picker-issued-date"
                      placement="TOP"
                    />
                  </FieldWrapper>
                </Grid>
                <Grid item xs={12}>
                  <FieldWrapper
                    label="Expiry Date"
                    info={
                      !!yearsFromIssuedDate
                        ? `${yearsFromIssuedDate} year(s) validity period`
                        : undefined
                    }
                  >
                    <DatePickerField
                      disabled={disableDateFields || !!yearsFromIssuedDate || formIsDisabled}
                      name="expiryDate"
                      placeholder="Select date"
                      data-cy="date-picker-expiry-date"
                      dependentField="issuedDate"
                      yearsAddedFromDependentField={yearsFromIssuedDate}
                      placement="TOP"
                      align="LEFT"
                    />
                  </FieldWrapper>
                </Grid>
              </Grid>
            </FormContainer>
            <Box mt={4} display="flex">
              <ThemeButton
                size="large"
                color="WHITE"
                onClick={handleDeleteDocument}
                disabled={formIsDisabled}
                startIcon={<Trash size={14} />}
                data-cy="delete-document-btn"
              >
                Delete document
              </ThemeButton>
              <Box ml={2}>
                <ThemeButton
                  loading={isSubmitting}
                  size="large"
                  color="YELLOW"
                  type="submit"
                  data-cy="save-document-btn"
                  disabled={formIsDisabled || isEqual(initialValues, values)}
                >
                  Save changes
                </ThemeButton>
              </Box>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};

export default DocumentForm;
