import { LinearProgress, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import truncate from 'lodash/truncate';
import PropTypes from 'prop-types';
import React from 'react';

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  border: '1px solid',
  background: '#fff',
  borderColor: theme.palette.grey[200],
  borderRadius: 6,
  padding: theme.spacing(1),
  maxWidth: '100%',
  minWidth: 180,
  alignItems: 'center',
  zIndex: 1,
}));

const Preview = styled('img')(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  width: '26px !important',
  height: 26,
  objectFit: 'cover',
}));

const FileName = styled(Typography)(() => ({
  fontSize: 12,
  minHeight: 12,
  fontWeight: 700,
  marginBottom: 6,
  lineHeight: 1,
}));

const ProgressContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  paddingLeft: theme.spacing(1),
}));

const StyledProgress = styled(LinearProgress)(() => ({
  height: 4,
  width: '100%',
}));

const Percent = styled('div')(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(1),
  fontSize: 10,
  top: 14,
  color: '#000',
  fontWeight: 700,
}));

const ProgressIndicator = ({ className = '', previewURL, fileName = ' ', progress = 0 }) => {
  return (
    <Container className={className}>
      <Preview src={previewURL} alt="upload thumbnail" />
      <ProgressContainer>
        <FileName>
          {truncate(fileName, {
            length: 16,
            separator: '',
          })}
        </FileName>
        <StyledProgress color="secondary" variant="determinate" value={progress} />
        <Percent>{progress}%</Percent>
      </ProgressContainer>
    </Container>
  );
};

ProgressIndicator.propTypes = {
  className: PropTypes.string,
  previewURL: PropTypes.string,
  progress: PropTypes.number,
  fileName: PropTypes.string,
};

export default ProgressIndicator;
