import { FetchResult, useMutation } from '@apollo/client';
import { IGeoUploadFormValue, IGeoUploadFormValues } from 'constants/schemas/geoUpload.schema';
import { EUDR_STATEMENT } from 'graphql/fragments/compliance.fragments';
import { DELETE_DOCUMENTS } from 'graphql/mutations';
import {
  CREATE_EUDR_DATASET,
  DELETE_EUDR_DATASET,
  PROCESS_EUDR_DATASETS,
  SUBMIT_EUDR_RISK_ANALYSIS,
  UPDATE_EUDR_DATASET,
} from 'graphql/mutations/compliance.mutations';
import {
  ICreateEUDRDatasetInput as CreateEudrDatasetInput,
  IProcessEUDRDatasetInput,
  ISubmitEUDRRiskAnalysisInput,
  IUpdateEUDRDatasetInput,
} from 'graphql/mutations/types/compliance-mutation.types';
import { GET_STATEMENT } from 'graphql/queries/compliance.queries';
import { useParams } from 'react-router-dom';
import { EudrDataset, EudrStatement, ProcessEUDRDatasetsPayload } from 'types/compliance.types';
import { RouteParamsWithId } from 'types/router.types';

const useEudrStatementDatasets = () => {
  const { id: statementId } = useParams<RouteParamsWithId>();

  const [_createEUDRDataset] = useMutation<
    { createEUDRDataset: { eudrDataset: EudrDataset } },
    { input: CreateEudrDatasetInput }
  >(CREATE_EUDR_DATASET, {
    update: (cache, { data }) => {
      if (data?.createEUDRDataset.eudrDataset) {
        const newDataset = data.createEUDRDataset.eudrDataset;
        const statementCacheKey = cache.identify({
          __typename: 'EUDRStatement',
          id: statementId,
        });
        const statement = cache.readFragment<EudrStatement>({
          id: statementCacheKey,
          fragment: EUDR_STATEMENT,
          fragmentName: 'eudrStatementValues',
        });
        cache.writeQuery({
          query: GET_STATEMENT,
          data: {
            statement: {
              ...statement,
              eudrDatasets: statement?.eudrDatasets.length
                ? [...statement.eudrDatasets, newDataset]
                : [newDataset],
            },
          },
        });
      }
    },
  });

  const [_updateEUDRDataset] = useMutation<
    { updateEUDRDataset: { eudrDataset: EudrDataset } },
    { id: string; input: IUpdateEUDRDatasetInput }
  >(UPDATE_EUDR_DATASET, {
    // update: (cache, { data }) => {
    //   if (data?.updateEUDRDataset.eudrDataset) {
    //   const eudrDatasetCacheKey = cache.identify({
    //     __typename: 'EUDRDataset',
    //     id: data.updateEUDRDataset.eudrDataset.id,
    //   });
    //   const existingDataset = cache.readFragment<EudrDataset>({
    //     id: eudrDatasetCacheKey,
    //     fragment: EUDR_DATASET,
    //     fragmentName: 'eudrDatasetValues',
    //   });
    //   cache.writeFragment({
    //     id: eudrDatasetCacheKey,
    //     fragment: EUDR_DATASET,
    //     fragmentName: 'eudrDatasetValues',
    //     data: {
    //       ...existingDataset,
    //       documents: existingDataset?.documents.filter(
    //         ({ id }) => !data?.deleteDocument.ids.includes(id)
    //       ),
    //     },
    //   });
    // }
    // },
  });

  const [_deleteEUDRDataset] = useMutation<{ id: string }, { id: string }>(DELETE_EUDR_DATASET, {
    update: (cache, { data }) => {
      if (data?.id) {
        const statementCacheKey = cache.identify({
          __typename: 'EUDRStatement',
          id: statementId,
        });
        const statement = cache.readFragment<EudrStatement>({
          id: statementCacheKey,
          fragment: EUDR_STATEMENT,
          fragmentName: 'eudrStatementValues',
        });
        cache.writeQuery({
          query: GET_STATEMENT,
          data: {
            statement: {
              ...statement,
              eudrDatasets: statement?.eudrDatasets.filter(dataset => dataset.id !== data.id),
            },
          },
        });
      }
    },
  });

  const [_deleteEudrDatasetDocument] = useMutation<
    { deleteDocument: { ids: string } },
    { ids: string[] }
  >(DELETE_DOCUMENTS);

  const [_processEUDRDatasets] = useMutation<
    { processEUDRDatasets: ProcessEUDRDatasetsPayload },
    { inputs: IProcessEUDRDatasetInput[] }
  >(PROCESS_EUDR_DATASETS);

  const [_submitEUDRRiskAnalysis] = useMutation<unknown, { input: ISubmitEUDRRiskAnalysisInput }>(
    SUBMIT_EUDR_RISK_ANALYSIS
  );

  const startEudrDatasetProcessing = (inputs: IProcessEUDRDatasetInput[]) =>
    _processEUDRDatasets({
      variables: {
        inputs,
      },
    });

  const createEUDRDatasets = async (
    statementId: string,
    values: IGeoUploadFormValues
  ): Promise<
    FetchResult<{
      createEUDRDataset: {
        eudrDataset: EudrDataset;
      };
    }>[]
  > => {
    if (!values.length) {
      return Promise.resolve([]);
    }
    try {
      return await Promise.all(
        values.map(
          async value =>
            await _createEUDRDataset({
              variables: {
                input: {
                  title: value.title,
                  originCountry: value.countryCode,
                  rawMaterialId: value.rawMaterialId,
                  ownedById: value.ownedBy.id,
                  documentIds: value.files.map(({ id }) => id),
                  eudrStatementIds: [statementId],
                },
              },
            })
        )
      );
    } catch (error) {
      console.error('Something went wrong creating datasets', error);
      return [];
    }
  };

  const updateEudrDatasets = async (
    values: (IGeoUploadFormValue & { datasetId: string })[] // datasetid is required
  ): Promise<
    FetchResult<{
      updateEUDRDataset: {
        eudrDataset: EudrDataset;
      };
    }>[]
  > => {
    if (!values.length) {
      return Promise.resolve([]);
    }
    try {
      return await Promise.all(
        values.map(
          async value =>
            await _updateEUDRDataset({
              variables: {
                id: value.datasetId,
                input: {
                  title: value.title,
                  originCountry: value.countryCode,
                  rawMaterialId: value.rawMaterialId,
                  ownedById: value.ownedBy.id,
                  documentIds: value.files.map(({ id }) => id),
                },
              },
            })
        )
      );
    } catch (error) {
      console.error('Something went wrong creating datasets', error);
      return [];
    }
  };

  const deleteEUDRDataset = async (id: string) => {
    try {
      _deleteEUDRDataset({ variables: { id } });
    } catch (error) {
      console.error('Something went wrong deleting datasets', error);
      return [];
    }
  };

  const deleteEudrDatasetDocument = async (datasetId: string, documentId: string) => {
    _deleteEudrDatasetDocument({
      variables: { ids: [documentId] },
      update: (cache, { data }) => {
        //   const eudrDatasetCacheKey = cache.identify({
        //     __typename: 'EUDRDataset',
        //     id: datasetId,
        //   });
        //   const existingDataset = cache.readFragment<EudrDataset>({
        //     id: eudrDatasetCacheKey,
        //     fragment: EUDR_DATASET,
        //     fragmentName: 'eudrDatasetValues',
        //   });
        //   cache.writeFragment({
        //     id: eudrDatasetCacheKey,
        //     fragment: EUDR_DATASET,
        //     fragmentName: 'eudrDatasetValues',
        //     data: {
        //       ...existingDataset,
        //       documents: existingDataset?.documents.filter(
        //         ({ id }) => !data?.deleteDocument.ids.includes(id)
        //       ),
        //     },
        //   });
      },
    });
  };

  const submitEudrRiskAnalysis = async (datasetBatches: ISubmitEUDRRiskAnalysisInput) =>
    _submitEUDRRiskAnalysis({
      variables: {
        input: datasetBatches,
      },
    });

  return {
    createEUDRDatasets,
    deleteEUDRDataset,
    startEudrDatasetProcessing,
    deleteEudrDatasetDocument,
    updateEudrDatasets,
    submitEudrRiskAnalysis,
  };
};

export default useEudrStatementDatasets;
