import { useEffect, useRef, useState } from 'react';

const useSticky = (distance: number = 0) => {
  const stickyRef = useRef();
  const [isSticky, setSticky] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      if (!stickyRef.current) return;
      setSticky(window.scrollY >= distance);
    };

    window.addEventListener('scroll', onScroll);
    onScroll();
    return () => window.removeEventListener('scroll', onScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [
    stickyRef,
    {
      isSticky,
    },
  ];
};

export default useSticky;
