import { Box, Dialog, Grid, IconButton, styled, Typography } from '@mui/material';

export const SlidesDialog = styled(Dialog)(() => ({
  margin: 'auto',
  '& .MuiDialog-paper': {},
}));

export const SlidesContainer = styled(Box)(() => ({
  height: 550,
  background: '#fff',
  overflow: 'auto',
}));

export const SlidesCloseButton = styled(IconButton)(({ theme }) => ({
  color: theme.custom.colors.actionButtonHover,
  position: 'absolute',
  top: theme.spacing(0),
  right: theme.spacing(0),
  zIndex: 2,
}));

export const SlidesNextButton = styled('div')(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(4),
}));

export const SlideContentContainer = styled('div')(() => ({
  display: 'flex',
  height: '100%',
  width: '100%',
}));

export const Column = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(2),
  height: '100%',
  overflow: 'auto',
}));

export const SideColumn = styled(Column)(({ theme }) => ({
  background: theme.custom.colors.backgroundLight,
  display: 'flex',
  flexDirection: 'column',
}));

export const ContentColumn = styled(Column)(({ theme }) => ({
  paddingTop: theme.spacing(3),
}));

export const EmptyStateWrapper = styled(Box)({
  height: '100%',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const StyledImageText = styled(Typography)(({ theme }) => ({
  marginTop: 20,
  fontWeight: 700,
  color: theme.palette.text.disabled,
}));
