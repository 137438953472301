import { gql } from '@apollo/client';
import { IMAGE_VARIANT_FRAGMENT } from './media.fragments';
import { BASE_PARTNER_COMPANY_FRAGMENT } from './partner.fragments';

export const RAW_MATERIAL_FRAGMENT = gql`
  fragment rawMaterialValues on RawMaterial {
    id
    title
  }
`;

export const COMPONENT_FRAGMENT = gql`
  fragment componentValues on Component {
    id
    title
    articleNumber
    image {
      ...imageVariant
    }
    modifiedTimestamp
    ownedById
    ownedBy {
      ...basePartnerCompanyValues
    }
    inUse
    rawMaterials {
      ...rawMaterialValues
    }
  }
  ${IMAGE_VARIANT_FRAGMENT}
  ${RAW_MATERIAL_FRAGMENT}
  ${BASE_PARTNER_COMPANY_FRAGMENT}
`;
