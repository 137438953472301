import { gql } from '@apollo/client';
import { BASE_COMPANY_FRAGMENT } from 'graphql/fragments/company.fragments';
import { DOCUMENT_FRAGMENT } from 'graphql/fragments/document.fragments';

const IMAGE_VARIANT_FRAGMENT = gql`
  fragment imageVariant on ImageVariant {
    id
    imageId
    path
    url
    width
    height
    size
    crop {
      x
      y
      width
      height
    }
  }
`;

export const PARTNER_DOCUMENT_REQUEST_LOG = gql`
  fragment partnerRequestLogValues on PartnerRequestLog {
    id
    comment
    userEmail
    user {
      firstName
      lastName
      email
    }
    company {
      id
      name
      logo {
        ...imageVariant
      }
    }
    timestamp
    action
  }
  ${IMAGE_VARIANT_FRAGMENT}
`;

export const BASE_PARTNER_COMPANY_FRAGMENT = gql`
  fragment basePartnerCompanyValues on Company {
    ...companyBaseValues
  }
  ${BASE_COMPANY_FRAGMENT}
`;

export const BASE_PARTNER_REQUEST_FRAGMENT = gql`
  fragment basePartnerRequestValues on PartnerRequest {
    id
    requestedByUserId
    direction
    documentId
    componentId
    chainId
    requestTitle
    requestMessage
    requestReplyMessage
    requestStatus
    requestedTimestamp
    requestedToCompany {
      ...basePartnerCompanyValues
    }
    requestedFromCompany {
      ...basePartnerCompanyValues
    }
    document {
      ...documentsValues
    }
    logs {
      ...partnerRequestLogValues
    }
    latestLog {
      ...partnerRequestLogValues
    }
    previouslyUploadedDocuments {
      ...documentsValues
    }
    requestType
  }
  ${BASE_PARTNER_COMPANY_FRAGMENT}
  ${BASE_COMPANY_FRAGMENT}
  ${DOCUMENT_FRAGMENT}
  ${PARTNER_DOCUMENT_REQUEST_LOG}
`;

export const PARTNER_REQUEST_FRAGMENT = gql`
  fragment partnerRequestValues on PartnerRequest {
    ...basePartnerRequestValues
    chain {
      id
      title
      image {
        ...imageVariant
      }
      chainTasksDetails {
        completed
        total
      }
    }
  }
  ${IMAGE_VARIANT_FRAGMENT}
  ${BASE_PARTNER_REQUEST_FRAGMENT}
`;
