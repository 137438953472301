import { Assistance, PublishProducts, Welcome } from 'components/WelcomeSlides/Slides';
import TracingGetStarted from '../Slides/TracingGetStarted';
import TracingCollectData from '../Slides/TracingCollectData';
import VisualizeChain from '../Slides/VisualizeChain';
import Analytics from '../Slides/Analytics';
import { FC } from 'react';

export const FULL_PLAN_SLIDES_CONTENT: FC<{ active: boolean }>[] = [
  Welcome,
  TracingGetStarted,
  TracingCollectData,
  VisualizeChain,
  PublishProducts,
  Analytics,
  Assistance,
];

export const PARTNER_UI_SLIDES_CONTENT: FC<{ active: boolean }>[] = [Welcome, Assistance];
