import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useMenu } from 'components/Product/MultiStep/hooks';
import PropTypes from 'prop-types';
import React from 'react';
import ValidationIndicator from './ValidationIndicator';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MenuLink = styled(({ active, disabled, child, ...props }) => <div {...props} />)(
  ({ theme, active, disabled, child }) => {
    let color = theme.palette.text.primary;

    if (disabled) color = theme.palette.grey[500];
    else if (active) color = theme.palette.secondary.main;

    return {
      position: 'relative',
      background: '#fff',
      flexGrow: 1,
      boxShadow: disabled ? 'none' : theme.custom.shadows[2],
      cursor: 'pointer',
      paddingTop: 14,
      paddingBottom: 14,
      marginBottom: theme.spacing(1),
      paddingLeft: child ? theme.spacing(5) : theme.spacing(3),
      paddingRight: theme.spacing(2),
      color,
      transition: 'all 0.125s',
      overflow: 'hidden',
      borderRadius: theme.shape.borderRadius,

      '&:hover': {
        background: theme.custom.colors.backgroundLight,
      },

      '&:after': {
        content: '""',
        position: 'absolute',
        width: 8,
        height: '100%',
        left: 0,
        top: 0,
        background: active ? theme.palette.secondary.main : 'transparent',
      },
    };
  }
);

const Title = styled('div')(() => ({
  fontSize: 14,
  fontWeight: 400,
}));

const MenuItem = ({
  step,
  title,
  disabled,
  child,
  valid,
  showWarning = false,
  noValidation,
  isContentBlock,
}) => {
  const { activeStep, setStep } = useMenu();
  const active = activeStep === step;

  const handleStepChange = (event, step) => {
    event.stopPropagation();
    setStep(step);
  };

  return (
    <MenuLink
      onClick={event => handleStepChange(event, step)}
      active={active}
      disabled={disabled}
      child={child}
      data-cy={`editor-menu-item-${step}`}
      {...(isContentBlock
        ? {
            'data-cy-editor-cb-menu-item': step,
          }
        : {})}
    >
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <Title>{title}</Title>
        </Box>
        {!noValidation && (
          <ValidationIndicator valid={valid} disabled={disabled} showWarning={showWarning} />
        )}
      </Box>
    </MenuLink>
  );
};

MenuItem.propTypes = {
  step: PropTypes.string,
  valid: PropTypes.bool,
  disabled: PropTypes.bool,
  invalidOnly: PropTypes.bool,
  showWarning: PropTypes.bool,
  noValidation: PropTypes.bool,
  title: PropTypes.string,
  child: PropTypes.bool,
  isContentBlock: PropTypes.bool,
};

export default MenuItem;
