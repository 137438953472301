import React, { FC } from 'react';
import { Box, Grid } from '@mui/material';
import {
  PageSubTitle,
  PageTitle,
  PageContainer,
  BorderedContainer,
  ErrorState,
} from 'components/Structure';
import useImpactClaim from 'components/ImpactClaims/hooks/useImpactClaim';
import ClaimHeader from 'components/ImpactClaims/ClaimOverview/ClaimHeader';
import { InvalidReason } from 'components/ImpactClaims/ClaimShared';
import { RequirementList, CatalogItemSdgs } from 'components/ImpactClaims/ClaimCatalog';
import ClaimHistory from 'components/ImpactClaims/ClaimHistory/ClaimHistory';
import { UploadListItem } from 'components/FileUpload';
import OtherClaimInformation from 'components/ImpactClaims/ClaimShared/OtherClaimInformation';
import Moment from 'react-moment';
import { ImpactClaimLog } from 'types/impactClaim.types';
import { Loader } from 'components/Forms';

const getComment = (log?: ImpactClaimLog): string => {
  return log?.comment ?? '';
};

const ImpactClaimOverview: FC = () => {
  const { impactClaim, loading, error } = useImpactClaim();

  if (loading) {
    return <Loader />;
  }
  if (error || !impactClaim) {
    return <ErrorState />;
  }

  return (
    <PageContainer>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <PageTitle
            title="Impact claim overview"
            goBackLabel="All claims"
            goBackUrl="/impact-claims"
            breadcrumbs={undefined}
            className={undefined}
            TitleAdornment={undefined}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={7} lg={8}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <ClaimHeader impactClaim={impactClaim} loading={loading} />
                </Grid>
                {impactClaim?.isInvalid && (
                  <Grid item xs={12}>
                    <InvalidReason>
                      {getComment(impactClaim.logs[impactClaim.logs.length - 1])}
                    </InvalidReason>
                  </Grid>
                )}

                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} lg={6}>
                      <Box marginY={2}>
                        <PageSubTitle title="Acceptance criteria" />
                      </Box>
                      <BorderedContainer>
                        <RequirementList
                          items={impactClaim?.impactCatalogItem.acceptanceCriteria || []}
                        />
                      </BorderedContainer>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <Box marginY={2}>
                        <PageSubTitle title="Documentation requirements" />
                      </Box>
                      <BorderedContainer>
                        <RequirementList
                          items={impactClaim?.impactCatalogItem.docRequirements || []}
                        />
                      </BorderedContainer>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={5} lg={4}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <BorderedContainer>
                    {/* The reverse() method of Array instances only work with arrays that are not readonly */}
                    {impactClaim && impactClaim.logs.length > 0 && (
                      <ClaimHistory history={[...impactClaim.logs].reverse()} />
                    )}
                  </BorderedContainer>
                </Grid>
                <Grid item xs={12}>
                  <Box marginY={1}>
                    <Box marginY={2}>
                      <PageSubTitle title="Proof documents" />
                    </Box>
                    {impactClaim?.attachments?.edges.map(
                      ({ node: { id, title, url, expiryDate } }) => (
                        <UploadListItem
                          key={id}
                          fileName={title}
                          success
                          onOpen={() => window.open(url, '_blank')}
                          subTitle={
                            expiryDate ? (
                              <Moment format="LL">{expiryDate}</Moment>
                            ) : (
                              'Expiry date unknown'
                            )
                          }
                        />
                      )
                    )}
                  </Box>
                </Grid>

                {!!impactClaim?.impactCatalogItem?.sustainableDevGoals?.length && (
                  <Grid item xs={12}>
                    <CatalogItemSdgs
                      sdgs={impactClaim?.impactCatalogItem?.sustainableDevGoals || []}
                      usePageTitle
                    />
                  </Grid>
                )}

                {!!(
                  impactClaim?.impactCatalogItem.thirdParties?.length ||
                  impactClaim?.impactCatalogItem.links?.length
                ) && (
                  <Grid item xs={12}>
                    <Box marginY={2}>
                      <PageSubTitle title="Other" />
                    </Box>
                    <OtherClaimInformation
                      thirdParties={impactClaim?.impactCatalogItem.thirdParties}
                      links={impactClaim?.impactCatalogItem.links}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </PageContainer>
  );
};

export default ImpactClaimOverview;
