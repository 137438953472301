import { Box, CircularProgress, styled } from '@mui/material';
import { FlexBox } from 'components/Structure';
import { ThemeTypography } from 'designSystem';
import { ButtonColor } from 'designSystem/Buttons/ThemeButton/ThemeButton';
import Stepper from 'designSystem/Navigation/Stepper/Stepper';
import { IconNameType } from 'designSystem/Primitives/Icon/Icon';
import React, { FC, PropsWithChildren, ReactNode } from 'react';
import { useEudrComplianceGuide } from './EudrComplianceGuideContext';

interface IEudrComplianceGuideSubSectionProps extends PropsWithChildren {
  disableNavigation?: boolean;
  disabledPreviousNavigation?: boolean;
  allowNextStepNavigation?: boolean;
  nextStepLoading?: boolean;
  titleAdornment?: ReactNode;
  titleLoading?: boolean;
  /**
   * Custom text which will display a normal button instead of the next step button
   */
  customNextStepButton?: { text: string; startIcon?: IconNameType; color?: ButtonColor };
  /**
   * Optional: If not passed and enabled it will perform the default navigateToNextSubSection
   */
  onNextStepClick?: () => void;
  /**
   * Optional: If not passed and enabled it will perform the default navigateToPreviousSubSection
   */
  onPreviousStepClick?: () => void;
}

const Container = styled(Box)(({ theme }) => ({
  borderRadius: 6,
  border: `1px solid ${theme.custom.colors.lightBorderColor}`,
  background: theme.custom.themeColors.white,
  padding: theme.spacing(2),
}));

const Pill = styled('div')(({ theme }) => ({
  color: theme.palette.secondary.main,
  background: theme.custom.themeColors.grayScale[20],
  borderRadius: '50%',
  minWidth: theme.spacing(3.5),
  height: theme.spacing(3.5),
  fontSize: 12,
  lineHeight: 11,
  boxShadow: theme.custom.shadows[1],
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontWeight: 700,
  marginRight: theme.spacing(2),
}));

const TitleLoaderContainer = styled(Box)(({ theme }) => ({
  '& .MuiCircularProgress-svg': {
    color: theme.custom.themeColors.grayScale[30],
  },
}));

const EudrComplianceGuideSubSection: FC<IEudrComplianceGuideSubSectionProps> = ({
  children,
  titleAdornment,
  allowNextStepNavigation,
  nextStepLoading,
  titleLoading,
  customNextStepButton,
  disableNavigation,
  disabledPreviousNavigation,
  onNextStepClick,
  onPreviousStepClick,
}) => {
  const {
    sections,
    selectedSectionIndex,
    selectedSubSection,
    selectedSubSectionIndex,
    navigateToNextSubSection,
    navigateToPreviousSubSection,
  } = useEudrComplianceGuide();

  /**
   * Passing the selected section and sub section index to the parent component since they can not be undefined here
   */
  const handleNextStepClick = () => {
    if (onNextStepClick) {
      onNextStepClick();
    } else {
      navigateToNextSubSection();
    }
  };

  const handlePreviousStepClick = () => {
    if (onPreviousStepClick) {
      onPreviousStepClick();
    } else {
      navigateToPreviousSubSection();
    }
  };

  return (
    <Container>
      <Stepper
        disablePreviousStep={
          disabledPreviousNavigation || disableNavigation || selectedSubSectionIndex === 0
        }
        disableNextStep={disableNavigation || !allowNextStepNavigation}
        selectedIndex={selectedSectionIndex}
        customNextStepButton={customNextStepButton}
        steps={sections}
        loading={nextStepLoading}
        onNextStepClick={handleNextStepClick}
        onPreviousStepClick={handlePreviousStepClick}
      />

      <Box p={2} mt={2}>
        <FlexBox mb={2} mr={1}>
          <Pill>
            {selectedSectionIndex + 1}.{selectedSubSectionIndex + 1}
          </Pill>
          <ThemeTypography variant="TITLE_SMALL">{selectedSubSection.title}</ThemeTypography>

          {selectedSubSection.itemTitle && (
            <Box ml={1}>
              <ThemeTypography variant="TITLE_SMALL" color="GRAY_60">
                {selectedSubSection.itemTitle}
              </ThemeTypography>
            </Box>
          )}

          {titleAdornment && <Box ml={1}>{titleAdornment}</Box>}

          {titleLoading && (
            <TitleLoaderContainer ml={1} display="flex" alignItems="center">
              <CircularProgress size={16} thickness={8} color="info" />
            </TitleLoaderContainer>
          )}
        </FlexBox>
        <ThemeTypography variant="BODY_MEDIUM">{selectedSubSection.description}</ThemeTypography>
        <Box mb={2} />
        {children}
      </Box>
    </Container>
  );
};

export default EudrComplianceGuideSubSection;
