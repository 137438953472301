import { styled, Autocomplete, TextField } from '@mui/material';
import useComponentChains from 'components/ComponentsLibrary/hooks/useComponentChains';
import React, { ChangeEvent, FC } from 'react';
import { IBaseChain } from 'types/chain.types';

interface IComponentChainAutocompleteProps {
  /* Removes the current chain from the selection list */
  filterChainId: string;
  onComponentChainSelect: (componentChainId?: string) => void;
}

const StyledAutocomplete = styled(Autocomplete<IBaseChain>)({
  '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"]':
    {
      padding: 4,
      '& .MuiAutocomplete-input': {
        fontSize: 12,
      },
    },
});

const ComponentChainAutocomplete: FC<IComponentChainAutocompleteProps> = ({
  filterChainId,
  onComponentChainSelect,
}) => {
  const { loading, componentChains } = useComponentChains();

  const handleSelectPartner = (event: ChangeEvent<unknown>, componentChain: IBaseChain | null) => {
    onComponentChainSelect(componentChain?.id);
  };

  return (
    <StyledAutocomplete
      loading={loading}
      options={componentChains.filter(
        ({ id, mappingInitialised }) => id !== filterChainId && mappingInitialised
      )}
      size="small"
      data-cy="component-chain-autocomplete"
      onChange={handleSelectPartner}
      getOptionLabel={option => option.title}
      renderOption={(props, option) => <span {...props}>{option.title}</span>}
      renderInput={params => (
        <TextField {...params} variant="outlined" placeholder="Select component chain" />
      )}
    />
  );
};

export default ComponentChainAutocomplete;
