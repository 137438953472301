import { Box, Grid } from '@mui/material';
import { CommunicationIcon } from 'assets/img/icons';
import { MediaListField } from 'components/Forms';
import { applicationHints } from 'config/applicationHints';
import { FieldWrapper, ThemeTypography } from 'designSystem';
import { Field } from 'formik';
import React, { FC } from 'react';
import { CommunicationInfoContainer } from './styles';
import HTMLEditorField from 'designSystem/Formik/HTMLEditorField/HTMLEditorField';

const ActivityCommunicationBlocks: FC<{ disabled?: boolean }> = ({ disabled }) => (
  <>
    <CommunicationInfoContainer>
      <ThemeTypography variant="BODY_MEDIUM" color="ALMOST_BLACK">
        This information is for external communication. Hover the icon
      </ThemeTypography>
      <Box mx={1}>
        <CommunicationIcon width={16} />
      </Box>
      <ThemeTypography variant="BODY_MEDIUM" color="ALMOST_BLACK">
        for some tips.
      </ThemeTypography>
    </CommunicationInfoContainer>

    <Grid item xs={12}>
      <FieldWrapper
        label="Images & video"
        tooltip={{
          variant: 'HINT',
          helperText: applicationHints.activity.mediaList,
        }}
      >
        <Field
          maxItems={4}
          component={MediaListField}
          legacyImage={false}
          name="activity.mediaList"
          dataCy="activity-medialist-input"
          disabled={disabled}
        />
      </FieldWrapper>
    </Grid>

    <Grid item xs={12}>
      <FieldWrapper
        label="Description"
        tooltip={{
          variant: 'HINT',
          helperText: applicationHints.activity.description,
        }}
      >
        <Field
          component={HTMLEditorField}
          name="activity.description"
          minHeight={100}
          charLimit={650}
          placeholder="Describe what is happening at this location in this step..."
          data-cy="activity-description-input"
          disabled={disabled}
        />
      </FieldWrapper>
    </Grid>
  </>
);

export default ActivityCommunicationBlocks;
