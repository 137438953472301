import { Box } from '@mui/material';
import { BoxArrowUpRight } from '@styled-icons/bootstrap/BoxArrowUpRight';
import { PencilSquare } from '@styled-icons/bootstrap/PencilSquare';
import { Plus } from '@styled-icons/bootstrap/Plus';
import { Trash } from '@styled-icons/bootstrap/Trash';
import { Upload } from '@styled-icons/bootstrap/Upload';
import DocumentSkeleton from 'components/DocumentLibrary/DocumentSkeleton';
import { DocumentTable } from 'components/DocumentLibrary/Table';
import { DocumentTableBodyAction } from 'components/DocumentLibrary/Table/DocumentTableBody';
import { DocumentsTableColumnKeys } from 'components/DocumentLibrary/Table/constants/documentHeaderColumns';
import { Loader, SearchToolbar } from 'components/Forms';
import {
  EmptySearchResults,
  ErrorState,
  InfiniteScrollWrapper,
  ActionContainer,
} from 'components/Structure';
import { useDialog, useInternalDocuments } from 'components/hooks';
import { EmptyStateCard, ThemeButton } from 'designSystem';
import Icon from 'designSystem/Primitives/Icon/Icon';
import React, { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { AvailableSizes, BackgroundTypes, ItemTypes } from 'types/enums';
import { IDocument } from 'types/document.types';

const InternalDocuments: FC = () => {
  const {
    error,
    loading,
    documents,
    selectedForDeletion,
    usageSelectedDocuments,
    searchTerm,
    hasNextPage,
    setSelectedForDeletion,
    setSearchTerm,
    handleClickDelete,
    handlePrepareDeletion,
    handlePageEndReached,
  } = useInternalDocuments();
  const navigate = useNavigate();

  const { openDialog } = useDialog();

  const openEditDocumentDialog = useCallback(
    (document: IDocument) => {
      if (!document.usageCount) {
        openDialog({
          type: 'EDIT_DOCUMENT',
          props: {
            document,
            onDelete: () => handleClickDelete(document),
          },
        });
      }
    },
    [openDialog, handleClickDelete]
  );

  const openUploadDocumentDialog = () => {
    openDialog({ type: 'UPLOAD_DOCUMENTS' });
  };

  const opeDocumentOverview = (document: IDocument) => {
    navigate(`/documents/internal/${document.id}`);
  };

  const documentTableActions: DocumentTableBodyAction[] = [
    {
      icon: <BoxArrowUpRight size="15" />,
      tooltip: 'Open in new tab',
      onClick: (document: IDocument) => window.open(document.url, '_blank'),
      dataCy: 'download-document-btn',
    },
    {
      icon: <PencilSquare size="16" />,
      tooltip: 'Edit Document',
      dataCy: 'edit-document-btn',
      disableIfDocumentUsed: true,
      disabledTooltip: 'Document is used as proof in an impact claim',
      onClick: (document: IDocument) => openEditDocumentDialog(document),
    },
    {
      icon: <Trash size="18" />,
      tooltip: 'Delete Document',
      dataCy: 'delete-document-btn',
      disableIfDocumentUsed: true,
      disabledTooltip: 'Document is used as proof in an impact claim',
      onClick: handleClickDelete,
    },
  ];

  if (error) {
    return <ErrorState />;
  }
  if (loading) {
    return <Loader />;
  }

  if (!documents.length && !searchTerm) {
    return (
      <Box mt={4}>
        <EmptyStateCard
          onCreateItemClick={openUploadDocumentDialog}
          variant={ItemTypes.DOCUMENT}
          background={BackgroundTypes.DETAILED_DOCUMENTS}
          buttonText="Upload documents"
          size={AvailableSizes.LARGE}
          title="Upload your documents & certificates to manage your impact & footprint"
          buttonIcon={<Upload size={14} />}
          data-cy="documents-getting-started-card"
          data-cy-button="create-documents-btn-getting-started"
        />
      </Box>
    );
  }

  return (
    <>
      <Box position="relative">
        <ActionContainer>
          <SearchToolbar
            setSearchTerm={setSearchTerm}
            searchTerm={searchTerm}
            data-cy="document-search-input"
            placeholder="Search document"
            className="search-input"
            actions={
              <Box display="flex">
                <Box px={1}>
                  <ThemeButton
                    size="medium"
                    color="YELLOW"
                    startIcon={<Plus size={20} />}
                    data-cy="create-document-btn"
                    onClick={openUploadDocumentDialog}
                  >
                    Add new documents
                  </ThemeButton>
                </Box>
                {selectedForDeletion.length !== 0 && (
                  <ThemeButton
                    size="medium"
                    color="WHITE"
                    tooltip={'Documents are used as proof in impact claims'}
                    disabled={usageSelectedDocuments > 0}
                    startIcon={<Icon name="delete" size="small" />}
                    onClick={handlePrepareDeletion}
                  >
                    Delete {selectedForDeletion.length}
                  </ThemeButton>
                )}
              </Box>
            }
          />
        </ActionContainer>

        {!!documents.length && (
          <InfiniteScrollWrapper hasMore={hasNextPage} next={handlePageEndReached}>
            <DocumentTable
              columns={[
                DocumentsTableColumnKeys.TITLE,
                DocumentsTableColumnKeys.USAGE,
                DocumentsTableColumnKeys.ISSUE_DATE,
                DocumentsTableColumnKeys.EXPIRY_DATE,
                DocumentsTableColumnKeys.ACTIONS,
              ]}
              documents={documents}
              selectedDocumentIds={selectedForDeletion}
              onSelectionChange={setSelectedForDeletion}
              onDocumentClick={opeDocumentOverview}
              actions={documentTableActions}
            />
          </InfiniteScrollWrapper>
        )}
      </Box>

      {!documents?.length && (
        <EmptySearchResults
          message="No search results found"
          action={{
            label: 'Create document',
            onClick: openUploadDocumentDialog,
          }}
          skeletonComponent={<DocumentSkeleton title={searchTerm} />}
        />
      )}
    </>
  );
};

export default InternalDocuments;
