import { Box } from '@mui/material';
import { useDialog } from 'components/hooks';
import { IGeoUploadFormValues, geoUploadDatasetSchema } from 'constants/schemas/geoUpload.schema';
import { Tabs } from 'designSystem';
import { ITab } from 'designSystem/Primitives/Tabs/Tabs';
import { useField, useFormikContext } from 'formik';
import React, { FC, useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';

interface IFormikTabsProps {
  selectedIndex: number;
  onChange: (newSelectedIndex: number) => void;
  onDeleteDataset: (datasetId: string) => void;
}

const FormikTabs: FC<IFormikTabsProps> = ({ selectedIndex, onChange, onDeleteDataset }) => {
  const [initialized, setInitialized] = useState(false);
  const { initialValues, values, errors, setValues } = useFormikContext<IGeoUploadFormValues>();
  const [{ value }] = useField(`[${selectedIndex}].title`);
  const { openDialog } = useDialog();

  const [tabs, setTabs] = useState<ITab<string>[]>([]);

  /** Build tabs based on initial state */
  useEffect(() => {
    setTabs([
      ...initialValues.map(dataset => ({
        key: dataset.datasetId || uuid(),
        label: dataset.title,
        removable: true,
      })),
      { key: 'new', label: 'Add dataset', startIconName: 'plus' },
    ]);
    setInitialized(true);
  }, [initialValues]);

  // Listen to changes in the dataset title and adjust the tab title accordingly
  useEffect(() => {
    if (!initialized) {
      return;
    }
    setTabs(existing =>
      existing.map((tab, index) => {
        if (index === selectedIndex) {
          return { ...tab, label: value || `Dataset ${selectedIndex + 1}` };
        }
        return tab;
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, initialized]);

  // Update tabs and the error indicators on each tab
  useEffect(() => {
    setTabs(existing =>
      existing.map((tab, index) => {
        // Check if an error exists for the current tab
        if (errors[index]) {
          return { ...tab, error: true, startIconName: 'error-filled' };
        } else if (tab.key === 'new') {
          return tab;
        }
        return { ...tab, error: false, startIconName: undefined };
      })
    );
  }, [errors]);

  const handleTabChange = (newTab: string) => {
    if (newTab === 'new') {
      setTabs(existing => {
        existing.splice(existing.length - 1, 0, {
          key: `dataset-${uuid()}`,
          label: `Dataset ${existing.length}`,
          removable: true,
        });
        return existing;
      });
      onChange(tabs.length - 2);

      // Add new dataset to the form
      setValues([...values, geoUploadDatasetSchema.default()]);
    } else {
      onChange(tabs.findIndex(tab => tab.key === newTab));
    }
  };

  const handleRemoveDataset = (indexToRemove: number) => {
    onChange(0);
    setTabs(prev => prev.filter((_, index) => index !== indexToRemove));
    // Make sure to delete the dataset on the backend if it exists
    const datasetIdToDelete = values[indexToRemove].datasetId;
    if (datasetIdToDelete) {
      onDeleteDataset(datasetIdToDelete);
    }
    setValues(values.filter((_, index) => index !== indexToRemove));
  };

  const handleTabRemove = (key: string) => {
    const index = tabs.findIndex(tab => tab.key === key);
    openDialog({
      type: 'ALERT',
      props: {
        title: 'Remove dataset',
        itemTitle: values[index]?.title || tabs[index]?.label,
        text: 'Are you sure you want to remove this dataset? This will delete all the data and files associated with this dataset.',
        submitText: 'Remove',
        cancelText: 'Cancel',
        onCancel: () => undefined,
        onSubmit: () => handleRemoveDataset(index),
      },
    });
  };

  if (!initialized) {
    <></>;
  }

  return (
    <Box mt={2}>
      <Tabs
        tabs={tabs}
        enableRemove={tabs.length > 2}
        selectedTab={tabs[selectedIndex]?.key}
        onChange={handleTabChange}
        onRemoveClick={handleTabRemove}
      />
    </Box>
  );
};

export default FormikTabs;
