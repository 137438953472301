import React, { Fragment } from 'react';
import { styled } from '@mui/material/styles';
import { usePageTitle } from 'components/hooks';
import { Loader, SearchToolbar } from 'components/Forms';
import { AlertDialog, ThemeButton } from 'designSystem';
import {
  ErrorState,
  PageSubTitle,
  EmptySearchResults,
  PageContainer,
  ScrollableTableContainer,
  ActionContainer,
  PageTitle,
} from 'components/Structure';
import { ImpactClaimTable, ImpactClaimSkeleton } from 'components/ImpactClaims';
import { Box } from '@mui/material';
import { Plus } from '@styled-icons/bootstrap/Plus';
import { useImpactClaims } from 'components/hooks';
import { EmptyStateCard } from 'designSystem';
import { BackgroundTypes, ItemTypes, AvailableSizes } from 'types/enums';

const Container = styled('div')(() => ({
  position: 'relative',
  minHeight: 50,
  marginTop: 16,

  '& .MuiGrid-item': {
    display: 'flex',
  },
}));

const ImpactClaims = () => {
  usePageTitle('Impact Claims');
  const {
    impactClaims,
    errorCatalog,
    errorImpactClaims,
    loadingImpactClaims,
    impactCatalogItems,
    loadingCatalog,
    handleOpenCreate,
    setSearchTerm,
    searchTerm,
    hasFilterActive,
    filteredImpactClaims,
    actions,
    handleClickClaimRow,
    filteredClaimGroups,
    approvedClaims,
    invalidClaims,
    pendingClaims,
    setDeletableClaim,
    deletableClaim,
    handleDelete,
  } = useImpactClaims();

  if (errorImpactClaims || errorCatalog) return <ErrorState />;
  if ((!impactClaims && loadingImpactClaims) || (!impactCatalogItems && loadingCatalog))
    return <Loader />;

  return (
    <PageContainer>
      <PageTitle title="Impact claims" />
      {!impactClaims?.edges.length ? (
        <Box mt={4}>
          <EmptyStateCard
            title="Add your claims to demonstrate and prove your social and environmental impact"
            size={AvailableSizes.LARGE}
            data-cy="claims-getting-started-card"
            background={BackgroundTypes.WORLD}
            variant={ItemTypes.IMPACT_CLAIM}
            onCreateItemClick={handleOpenCreate}
            buttonText="Add claim"
            data-cy-button="claims-getting-started-create-btn"
          />
        </Box>
      ) : (
        <Fragment>
          <Box>
            <Container>
              <ActionContainer>
                <SearchToolbar
                  setSearchTerm={setSearchTerm}
                  searchTerm={searchTerm}
                  className="search-input"
                  data-cy="impact-claim-search-input"
                  placeholder="Search product"
                  actions={
                    <Box ml={1}>
                      <ThemeButton
                        size="medium"
                        onClick={handleOpenCreate}
                        startIcon={<Plus size={20} />}
                        data-cy="create-impact-claim-btn"
                        color="YELLOW"
                      >
                        Add new claim
                      </ThemeButton>
                    </Box>
                  }
                />
              </ActionContainer>

              {hasFilterActive && !!filteredImpactClaims.length ? (
                <ScrollableTableContainer pb={1}>
                  <ImpactClaimTable
                    filter={searchTerm}
                    impactClaimGroups={filteredClaimGroups}
                    actions={actions}
                    onSelect={handleClickClaimRow}
                    data-cy="approved-claims-table"
                  />
                </ScrollableTableContainer>
              ) : filteredImpactClaims.length ? (
                <Fragment>
                  <ScrollableTableContainer pb={1}>
                    {approvedClaims.length ? (
                      <ImpactClaimTable
                        filter={searchTerm}
                        impactClaimGroups={approvedClaims}
                        actions={actions}
                        onSelect={handleClickClaimRow}
                        data-cy="approved-claims-table"
                        rowsPerPage={4}
                      />
                    ) : null}
                    {invalidClaims.length ? (
                      <Box position="relative" zIndex={0}>
                        <PageSubTitle title="Invalid claims" />
                        <ImpactClaimTable
                          filter={searchTerm}
                          hideHeader={!!approvedClaims.length}
                          impactClaimGroups={invalidClaims}
                          onSelect={handleClickClaimRow}
                          actions={actions}
                          displayGroup={false}
                          data-cy="invalid-claims-table"
                        />
                      </Box>
                    ) : null}
                    {pendingClaims.length ? (
                      <Box position="relative" zIndex={0}>
                        {approvedClaims.length ? <PageSubTitle title="In Progress" /> : null}
                        <ImpactClaimTable
                          filter={searchTerm}
                          hideHeader={!!approvedClaims.length || !!invalidClaims.length}
                          transparent
                          impactClaimGroups={pendingClaims}
                          onSelect={handleClickClaimRow}
                          actions={actions}
                          data-cy="pending-claims-table"
                        />
                      </Box>
                    ) : null}
                  </ScrollableTableContainer>
                </Fragment>
              ) : null}
            </Container>
          </Box>
          {searchTerm && !filteredImpactClaims.length && (
            <EmptySearchResults
              message="No claim matches your search"
              action={{
                onClick: handleOpenCreate,
                label: 'Create new',
              }}
              skeletonComponent={<ImpactClaimSkeleton />}
            />
          )}
        </Fragment>
      )}
      <AlertDialog
        onClose={() => setDeletableClaim(null)}
        title="Delete impact claim"
        open={!!deletableClaim}
        text="Deleting this claim will remove it from any content."
        onSubmit={() => handleDelete(deletableClaim)}
        onCancel={() => setDeletableClaim(null)}
        submitText="Delete"
      />
    </PageContainer>
  );
};

export default ImpactClaims;
