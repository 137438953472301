import { useMutation } from '@apollo/client';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { AuthHeadline, AuthLayout, RegistrationForm, ResendVerification } from 'components/Auth';
import { useLogEvent, useLogEventOnMount, useMessages } from 'components/hooks';
import useAutoLogout from 'components/hooks/useAutoLogout';
import { REGISTER_USER, RESEND_USER_VERIFICATION_MAIL } from 'graphql/mutations';
import React, { FC } from 'react';
import { RegisterValues } from 'types/types';
import { BooleanParam, StringParam, useQueryParam } from 'use-query-params';

export const Subtitle = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(2),
  color: '#fff',
}));

const Register: FC = () => {
  useLogEventOnMount('VIEW_REGISTER');
  const { logEvent } = useLogEvent();

  const [signedUp, setSignedUp] = useQueryParam('registered', BooleanParam);
  const [email, setEmail] = useQueryParam('email', StringParam);

  // Logout user when still found
  useAutoLogout();

  const { setErrorMessage } = useMessages();

  const [register] = useMutation<{ input: RegisterValues }>(REGISTER_USER, {
    onError: error => setErrorMessage(error.message),
    onCompleted: () => {
      setSignedUp(true);
      logEvent('USER_REGISTERED', {
        email,
      });
    },
    fetchPolicy: 'no-cache',
  });

  const onSubmit = (values: RegisterValues) => {
    setEmail(values.email);

    return register({ variables: { input: values } });
  };

  return (
    <AuthLayout title="Register">
      <AuthHeadline text="Welcome to" highlighted="seedtrace" />
      {signedUp ? (
        <ResendVerification
          title="Verify your email address"
          subtitle={`We have sent a verification link to ${email}. It might take a
            moment for the mail to arrive. Please check your inbox and spam
            folder.`}
          email={email || ''}
          mutation={RESEND_USER_VERIFICATION_MAIL}
        />
      ) : (
        <Box mt={5}>
          <RegistrationForm onSubmit={onSubmit} />
        </Box>
      )}
    </AuthLayout>
  );
};

export default Register;
