import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ThemeTypography } from 'designSystem';

const Link = styled('a')(() => ({
  wordBreak: 'break-all',
}));

const CatalogItemLinks = ({ items = [] }) => {
  return (
    <Box>
      <Box mb={1}>
        <ThemeTypography variant="BODY_LARGE_BOLD">Links</ThemeTypography>
      </Box>
      {items.map(link => (
        <Box key={link}>
          <Link href={link} key={link} target="_blank" rel="noreferrer">
            {link}
          </Link>
        </Box>
      ))}
    </Box>
  );
};

CatalogItemLinks.propTypes = {
  items: PropTypes.array.isRequired,
};

export default CatalogItemLinks;
