import { Snackbar, styled, Alert } from '@mui/material';
import React, {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useEffect,
  useState,
} from 'react';
import { Booleanish, booleanish } from 'types/booleanish.types';

export interface MessageContextValues {
  setErrorMessage: Dispatch<SetStateAction<string | null>>;
  setSuccessMessage: Dispatch<SetStateAction<string | null>>;
}

const MessageContext = createContext<MessageContextValues>({
  setErrorMessage: () => console.warn('Provider not implemented'),
  setSuccessMessage: () => console.warn('Provider not implemented'),
});

const StyledAlert = styled(Alert)<{
  'is-success': booleanish;
}>(({ theme, 'is-success': isSuccess }) => ({
  backgroundColor:
    isSuccess === 'true'
      ? theme.custom.themeColors.success[100]
      : theme.custom.themeColors.error[60],
  color: isSuccess === 'true' ? 'white' : theme.custom.themeColors.error[100],
  borderRadius: 50,

  '& .MuiAlert-icon': {
    color: isSuccess === 'true' ? 'white' : theme.custom.themeColors.error[100],
  },
}));

const MessageProvider: FC<PropsWithChildren> = ({ children }) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [open, setOpen] = useState(false);

  const resetMessage = () => {
    setErrorMessage(null);
    setSuccessMessage(null);
  };

  const message: string | null = errorMessage || successMessage;

  useEffect(() => setOpen(!!message), [message]);

  const onClose = () => {
    setOpen(false);
    setTimeout(() => resetMessage(), 500);
  };

  return (
    <MessageContext.Provider
      value={{
        setErrorMessage,
        setSuccessMessage,
      }}
    >
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={onClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <StyledAlert
          is-success={Booleanish(!!successMessage)}
          onClose={onClose}
          severity={successMessage ? 'success' : 'error'}
          data-cy={`alert-${successMessage ? 'success' : 'error'}`}
        >
          {message}
        </StyledAlert>
      </Snackbar>
      {children}
    </MessageContext.Provider>
  );
};

export { MessageProvider };

export default MessageContext;
