import { useEffect, useRef, useState } from 'react';
import { Location, useBlocker, useNavigate } from 'react-router-dom';

const useBlockNavigation = ({ shouldBlock }: { shouldBlock?: boolean }) => {
  const navigate = useNavigate();
  const [blockedTarget, setBlockedTarget] = useState<Location>();
  const prevShouldBlock = useRef();

  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    if (
      (currentLocation.pathname !== nextLocation.pathname ||
        currentLocation.search !== nextLocation.search) &&
      shouldBlock
    ) {
      setBlockedTarget(nextLocation);
      return true;
    }

    return false;
  });

  const forceRouteChange = () => {
    blocker.proceed?.();
    navigate({ pathname: blockedTarget?.pathname, search: blockedTarget?.search });
    setBlockedTarget(undefined);
  };

  useEffect(() => {
    if (blockedTarget && prevShouldBlock && !shouldBlock) {
      forceRouteChange();
    }

    // @ts-ignore
    prevShouldBlock.current = shouldBlock;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldBlock, blockedTarget]);

  return {
    unblock: forceRouteChange,
    navigationBlocked: !!blockedTarget,
    reset: () => setBlockedTarget(undefined),
  };
};

export default useBlockNavigation;
