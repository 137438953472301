import React, { Fragment } from 'react';
import { MenuItem, FormControl, FormHelperText, Checkbox } from '@mui/material';
import { styled } from '@mui/material/styles';
import sdgs from 'constants/sdgConfig';
import { getIn } from 'formik';
import { StyledSelect, StyledInput } from './StyledMultiSelect';
import { DeletableChip } from 'components/Forms';

const SdgContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  overflow: 'hidden',
  borderRadius: 4,
  padding: 0,
  alignItems: 'center',
  paddingRight: theme.spacing(1),
  color: theme.custom.themeColors.grayScale[90],
  fontWeight: 300,
}));

const Selected = styled(StyledSelect)({
  '& .MuiSelect-select': {
    padding: '0 !important',
  },
});

const IconContainer = styled('div')(({ theme, hex }) => ({
  backgroundColor: hex,
  width: 32,
  height: 32,
  display: 'block',
  padding: 3,
  marginRight: theme.spacing(1),
}));

const StyledControl = styled(FormControl)({
  position: 'relative',
  width: '100%',
});

const Error = styled(FormHelperText)({
  position: 'absolute',
  bottom: -20,
});

const StyledCheckbox = styled(Checkbox)({
  margin: 0,
  padding: 4,
});

const SdgSelector = ({ ...fieldProps }) => {
  const {
    field: { value, name },
    form: { errors, touched, setFieldValue },
  } = fieldProps;

  const fieldError = getIn(errors, name);
  const showError = getIn(touched, name) && typeof fieldError === 'string';

  const handleRemove = sdg => {
    const rest = value.filter(item => item !== sdg);
    setFieldValue(name, rest);
  };

  return (
    <StyledControl>
      <Selected
        fullWidth
        type="text"
        multiple
        error={showError}
        input={<StyledInput />}
        renderValue={selected => (
          <Fragment>
            {selected &&
              selected.map(sdgIndex => {
                const { title, iconWhite: Icon, hex } = sdgs[sdgIndex];

                return (
                  <DeletableChip
                    key={sdgIndex}
                    padding="0"
                    label={
                      <SdgContainer>
                        <IconContainer hex={hex}>
                          <Icon width="auto" height="auto" />
                        </IconContainer>
                        {title}
                      </SdgContainer>
                    }
                    onDelete={() => handleRemove(sdgIndex)}
                  />
                );
              })}
          </Fragment>
        )}
        {...fieldProps}
      >
        {sdgs.map(({ title }, index) => (
          <MenuItem key={title} value={index} data-cy="impact-facts-sdg-option">
            <StyledCheckbox checked={value.includes(index)} size="small" />
            {title}
          </MenuItem>
        ))}
      </Selected>
      {showError && <Error error>{fieldError}</Error>}
    </StyledControl>
  );
};

export default SdgSelector;
