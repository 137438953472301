import { styled } from '@mui/material/styles';
import { EditorState } from 'draft-js';
import React, { FC } from 'react';
import HTMLEditorButton from './HTMLEditorButton';

interface IInlineStyleControlsProps {
  editorState: EditorState;
  onToggle: (style: string) => void;
}

const ButtonContainer = styled('span')<{ 'text-style': string }>(
  ({ 'text-style': textStyle, theme }) => ({
    marginRight: theme.spacing(1),
    fontWeight: textStyle === 'BOLD' ? 700 : 400,
    fontStyle: textStyle === 'ITALIC' ? 'italic' : 'normal',
  })
);

const INLINE_STYLES = [
  {
    label: 'Bold',
    style: 'BOLD',
  },
  {
    label: 'Italic',
    style: 'ITALIC',
  },
];

const InlineStyleControls: FC<IInlineStyleControlsProps> = ({ editorState, onToggle }) => {
  const currentStyle = editorState.getCurrentInlineStyle();

  return (
    <div>
      {INLINE_STYLES.map(type => (
        <ButtonContainer key={type.label} text-style={type.style}>
          <HTMLEditorButton<string>
            active={currentStyle.has(type.style)}
            label={type.label}
            onToggle={onToggle}
            style={type.style}
          />
        </ButtonContainer>
      ))}
    </div>
  );
};

export default InlineStyleControls;
