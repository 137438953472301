import { styled } from '@mui/material/styles';
import logo from 'assets/img/seedtrace_logo_black.svg';
import React, { PropsWithChildren } from 'react';
import { Helmet } from 'react-helmet';
import partnerRegistrationImage from 'assets/img/auth-partner-image.png';
import defaultRegistrationImage from 'assets/img/auth-product-illustration.png';
import ThemeTypography from 'designSystem/Primitives/Typography/ThemeTypography';
import { Box } from '@mui/material';
import { useConfig } from 'components/hooks';

const Layout = styled('div')({
  display: 'flex',
  height: '100vh',
  minHeight: 800,
});

const AuthIllustration = styled('img')(() => ({
  width: '80%',
  maxWidth: 700,
  maxHeight: '90%',
}));

const InviteName = styled('span')(({ theme }) => ({
  color: theme.custom.themeColors.grayScale[40],
}));

const ImageContainer = styled('div')({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  width: '60%',
  height: '100%',
});

const FormContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexGrow: 1,
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(4, 11, 4, 11),
  background: theme.palette.secondary.main,

  '& a': {
    color: theme.custom.colors.secondaryLighter,
  },
}));

const Logo = styled('img')(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(4.5),
  left: theme.spacing(4.5),
  width: 146,

  '&:hover': {
    cursor: 'pointer',
  },
}));

const Form = styled('div')(() => ({
  maxWidth: '500px',
  width: '100%',
}));

interface Props {
  title: string;
  isPartnerRegistration?: boolean;
  partnerRegistrationName?: string;
}

const AuthLayout: React.FC<PropsWithChildren<Props>> = ({
  title,
  isPartnerRegistration = false,
  partnerRegistrationName,
  children,
}) => {
  const config = useConfig();

  const handleLogoClick = () => {
    window.open(config?.seedtraceWebsite || '');
  };

  return (
    <Layout>
      <Helmet>
        <title>{title} - seedtrace</title>
      </Helmet>
      <ImageContainer>
        <Logo src={logo} alt="seedtrace logo" onClick={handleLogoClick} />

        {partnerRegistrationName && isPartnerRegistration && (
          <Box mb={4}>
            <ThemeTypography variant="TITLE_LARGE">
              <InviteName>{partnerRegistrationName}</InviteName>
              {` has invited you to collaborate`}
            </ThemeTypography>
          </Box>
        )}

        <AuthIllustration
          sizes="40vw"
          src={isPartnerRegistration ? partnerRegistrationImage : defaultRegistrationImage}
          alt="seedtrace product illustration"
        />
      </ImageContainer>
      <FormContainer>
        <Form>{children}</Form>
      </FormContainer>
    </Layout>
  );
};

export default AuthLayout;
