export const impactLevelOptions = [
  {
    value: 'BUSINESS',
    label: 'Business level',
    text: 'All operations along all parts of our supply chains',
    props: {
      description: 'The claim covers all products and partners. You may continue.',
    },
  },
  {
    value: 'PRODUCT',
    label: 'Product level',
    text: 'The supply chain of a specific product',
    props: {},
  },
  {
    value: 'PARTNER',
    label: 'Partner level',
    text: 'Operations of a specific partner, like for example a supplier.',
    component: () => 'Partner level',
    props: {},
  },
  {
    value: 'COMPONENT',
    label: 'Component level',
    text: 'The supply chain of a specific component of our products.',
    component: () => 'Component level',
    props: {},
  },
];
