import { Box } from '@mui/material';
import defaultPartnerImage from 'assets/img/partners/default-partner-image.png';
import { Title } from 'components/Partners/ActivityRow/ActivityRow';
import { FlexBox } from 'components/Structure';
import { CardContainer, ThemeTypography } from 'designSystem';
import RiskBadge from 'designSystem/DataDisplay/RiskBadge/RiskBadge';
import Icon from 'designSystem/Primitives/Icon/Icon';
import React, { FC } from 'react';
import { RiskLevel } from 'types/compliance.types';
import { IBasePartnerCompany } from 'types/partner.types';
import { PartnerImage } from './PartnerCard';
import Moment from 'react-moment';

type IPartnerRowItemProps = {
  partner: IBasePartnerCompany;
  onSelect?: (id: string) => void;
} & ({ completedDate: Date } | { requestedDate: Date });

const PartnerRowItem: FC<IPartnerRowItemProps> = ({
  partner: { id, name: title, logo },
  onSelect,
  ...props
}) => {
  return (
    <CardContainer
      key={id}
      padding={1.5}
      width="100%"
      sx={{ cursor: onSelect ? 'pointer' : 'default' }}
      onClick={() => onSelect?.(id)}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <PartnerImage alt={`Logo of ${title}`} src={logo?.url || defaultPartnerImage} />
          <Box display="flex" flexDirection="column" justifyContent="center">
            <FlexBox>
              <Title>{title}</Title>
              <Box mr={1} />
              <RiskBadge riskLevel={RiskLevel.LOW} />
            </FlexBox>
            <Box display="flex" alignItems="center">
              <Box display="flex" alignItems="center" mr={1}>
                <Icon name="tag-filled" color="gray-80" size="small" mr={1} />
                <ThemeTypography variant="BODY_MEDIUM_BOLD" color="GRAY_80">
                  Supplier
                </ThemeTypography>
              </Box>
            </Box>
          </Box>
        </Box>
        <FlexBox>
          {'completedDate' in props ? (
            <>
              <ThemeTypography variant="BODY_MEDIUM_BOLD" color="GRAY_100">
                Completed on:
              </ThemeTypography>
              <Box mr={0.5} />
              <ThemeTypography variant="BODY_MEDIUM" color="GRAY_100">
                <Moment format="LL">{props.completedDate}</Moment>
              </ThemeTypography>
            </>
          ) : (
            <>
              <ThemeTypography variant="BODY_MEDIUM_BOLD" color="GRAY_100">
                Request on:
              </ThemeTypography>
              <Box mr={0.5} />
              <ThemeTypography variant="BODY_MEDIUM" color="GRAY_100">
                <Moment format="LL">{props.requestedDate}</Moment>
              </ThemeTypography>
            </>
          )}
        </FlexBox>
      </Box>
    </CardContainer>
  );
};

export default PartnerRowItem;
