import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useEditorState } from 'components/Product/MultiStep/hooks';
import { useDebouncedCallback } from 'use-debounce';
import { useProduct } from '../hooks';

const Autosave = ({ values, dirty, delay = 2000, pause = false, handleSave }) => {
  const {
    product: { id },
  } = useProduct();
  const { setUnsavedChanges, onError, onCompleted, lang, setSaving } = useEditorState();

  // Triggers after delay ( 2s ) if the values change.
  const debouncedSubmit = useDebouncedCallback(async values => {
    setSaving(true);
    // Fixme
    delete values['enabled'];

    await handleSave({
      variables: {
        id: values.id || id, // content block id or product id
        lang,
        input: {
          // the input should not have the id passed to the mutation
          ...values,
          id: undefined,
        },
      },
    }).then(onCompleted, onError);
    setSaving(false);
  }, delay);

  useEffect(() => {
    if (!dirty || pause) return;

    debouncedSubmit.callback(values);
    setUnsavedChanges(true);
  }, [debouncedSubmit, values, pause, dirty]); // eslint-disable-line

  return null;
};

Autosave.propTypes = {
  values: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  dirty: PropTypes.bool.isRequired,
  handleSave: PropTypes.func.isRequired,
  pause: PropTypes.bool,
  delay: PropTypes.number,
};

export default Autosave;
