import { gql } from '@apollo/client';

export const GET_VIDEO_UPLOAD_LINK = gql`
  mutation getVideoUploadLink($input: GetVideoUploadLinkInput!) {
    getVideoUploadLink(input: $input) {
      uploadLink
      video {
        id
      }
    }
  }
`;

export const UPDATE_VIDEO_UPLOAD_STATUS = gql`
  mutation UpdateVideoUploadStatus($id: UUID!, $input: UpdateVideoStatusInput!) {
    updateVideoStatus(id: $id, input: $input) {
      video {
        id
      }
    }
  }
`;

export const DELETE_VIDEO = gql`
  mutation DeleteVideo($id: UUID!) {
    deleteVideo(id: $id) {
      id
    }
  }
`;
