import EudrComplianceGuideSubSection from 'components/ComplianceGuide/EudrComplianceGuideSubSection';
import { useEudrComplianceGuide } from 'components/ComplianceGuide/EudrComplianceGuideContext';
import { ThemeButton } from 'designSystem';
import React, { FC, useState } from 'react';
import { EudrAffectednessType, EudrResponsibilityType } from 'types/compliance.types';
import QnAGuideTemplate from '../../QnAGuideTemplate';

const AffectedCompanySize: FC = () => {
  const {
    formData,
    selectedSubSection,
    selectedStatementId,
    setFormData,
    setSelectedSubSection,
    updateStatement,
  } = useEudrComplianceGuide();

  const [selectedAnswer, setSelectedAnswer] = useState<string | undefined>();

  const handleNextStepClick = () => {
    setFormData({
      ...formData,
      previousSubSectionKey: selectedSubSection?.key,
      affectedCompanySize: selectedAnswer === 'yes',
    });
    // If answer is no, then the company is partially affected as trader, if answer is yes, then the company is fully affected as trader with Operator responsibility
    setSelectedSubSection(selectedAnswer === 'yes' ? 'fully-affected' : 'partially-affected');

    if (selectedStatementId) {
      updateStatement(selectedStatementId, {
        companyAffectednessType:
          selectedAnswer === 'yes'
            ? EudrAffectednessType.FULLY_AFFECTED
            : EudrAffectednessType.PARTIALLY_AFFECTED,
        companyResponsibilityType:
          selectedAnswer === 'yes'
            ? EudrResponsibilityType.TRADER_OPERATOR
            : EudrResponsibilityType.TRADER,
      });
    }
  };

  return (
    <EudrComplianceGuideSubSection
      allowNextStepNavigation={!!selectedAnswer}
      titleAdornment={
        <ThemeButton color="BLUE_ICE" disabled>
          Skip & apply previous affectedness
        </ThemeButton>
      }
      onNextStepClick={handleNextStepClick}
    >
      <QnAGuideTemplate
        selectedAnswer={selectedAnswer}
        question="Does your company have more than 250 employees and annual sales of more than 50 million EUR? (Non-SME)"
        answers={[
          { id: 'yes', label: 'Yes' },
          {
            id: 'no',
            label: 'No',
          },
        ]}
        onAnswerChange={setSelectedAnswer}
      />
    </EudrComplianceGuideSubSection>
  );
};

export default AffectedCompanySize;
