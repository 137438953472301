import React from 'react';
import PropTypes from 'prop-types';
import { LoadingCard } from 'components/Forms';
import { LineGraph } from 'components/Product/Analytics/LineGraph';
import { styled, useTheme } from '@mui/material/styles';

const StyledLabel = styled('span')(({ color }) => ({
  fontWeight: 'bold',
  textAlign: 'center',
  color: color,
}));

const InteractionsWithPage = ({ loading, data }) => {
  const theme = useTheme();
  const colors = [theme.palette.primary.main, theme.palette.error.main];

  const tooltip = ({ tooltipState }) => (
    <div>
      {tooltipState.tooltipData &&
        tooltipState.tooltipData.map((d, i) => (
          <div
            key={i}
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <StyledLabel color={colors[i]}>
              {d.__typename}: {d.value}
            </StyledLabel>
          </div>
        ))}
    </div>
  );

  return (
    <LoadingCard loading={loading} label="Interactions with page">
      <LineGraph
        height={320}
        interval={data.timeInterval}
        data={data}
        graphColors={colors}
        graphLegend={null}
        graphTooltip={tooltip}
        filled
      />
    </LoadingCard>
  );
};

InteractionsWithPage.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.array.isRequired,
};

export default InteractionsWithPage;
