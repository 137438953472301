import { useQuery } from '@apollo/client';
import { Box } from '@mui/material';
import { styled } from '@mui/styles';
import { Loader, SearchToolbar } from 'components/Forms';
import { EmptySearch, ErrorState } from 'components/Structure';
import { GET_ALL_PRODUCTS } from 'graphql/queries';
import React, { Fragment, useMemo, useState } from 'react';
import { Product, GraphQlConnection } from 'types/types';
import { filterItems } from 'utils';
import { SelectProductTable } from './Table';

const SearchbarContainer = styled(Box)(() => ({
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  top: -35,
  left: 0,
  zIndex: 9,
}));

interface Props {
  selectedProductId?: Product['id'];
  setSelectedProductId: (productId: Product['id']) => void;
}

const ProductSelector: React.FC<Props> = ({ selectedProductId, setSelectedProductId }) => {
  const { data, loading, error } = useQuery<{ products: GraphQlConnection<Product> }>(
    GET_ALL_PRODUCTS
  );
  const products = useMemo(() => data?.products.edges || [], [data]);

  const [searchTerm, setSearchTerm] = useState('');

  const filteredProducts = useMemo(() => {
    return filterItems(products, searchTerm);
  }, [products, searchTerm]);

  if (error) return <ErrorState />;
  else if (!products && loading) return <Loader />;

  return (
    <Fragment>
      <Box position="relative" mt={7} mb={3}>
        <SearchbarContainer>
          <SearchToolbar
            setSearchTerm={setSearchTerm}
            searchTerm={searchTerm}
            data-cy="document-search-input"
            placeholder="Search products"
            className="search-input"
          />
        </SearchbarContainer>
        <SelectProductTable
          products={filteredProducts}
          selectedProductId={selectedProductId}
          setSelectedProductId={setSelectedProductId}
        />
      </Box>
      {!filteredProducts.length && <EmptySearch />}
    </Fragment>
  );
};

export default ProductSelector;
