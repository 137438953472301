import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { PatchQuestion } from '@styled-icons/bootstrap/PatchQuestion';
import ThemeButton from 'designSystem/Buttons/ThemeButton/ThemeButton';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Container = styled(({ center, ...props }) => <div {...props} />)(({ theme, center }) => {
  let styles = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    color: theme.palette.text.secondary,
    fontWeight: 500,
  };

  if (center) {
    styles = {
      ...styles,
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate3d(-50%, -50%, 0)',
    };
  }

  return styles;
});

const EmptyState = ({ message, center, className, action, icon: Icon }) => {
  if (!message) message = 'There is nothing here yet.';

  return (
    <Container center={center} className={className} data-cy="empty-state">
      <Box mb={1}>{Icon ? <Icon /> : <PatchQuestion size={24} />}</Box>
      {message}
      {action && (
        <Box mt={2}>
          <ThemeButton size="medium" onClick={action.onClick} color="BLUE_ICE">
            {action.label}
          </ThemeButton>
        </Box>
      )}
    </Container>
  );
};

EmptyState.defaultProps = {
  center: true,
};

EmptyState.propTypes = {
  message: PropTypes.string,
  center: PropTypes.bool,
  className: PropTypes.string,
  action: PropTypes.shape({
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  }),
  icon: PropTypes.node,
};

export default EmptyState;
