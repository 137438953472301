import React from 'react';
import PropTypes from 'prop-types';
import { DialogTitle, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Close } from '@styled-icons/evaicons-solid/Close';
import { ExclamationTriangle } from '@styled-icons/bootstrap/ExclamationTriangle';
import { ThemeTypography } from 'designSystem';

const CloseButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.primary,
  position: 'absolute',
  top: theme.spacing(1),
  right: theme.spacing(1),
}));

const StyledTitle = styled(DialogTitle)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  color: theme.palette.error.main,
  padding: theme.spacing(0, 0, 2, 0),
}));

const Icon = styled(ExclamationTriangle)(({ theme }) => ({
  marginRight: theme.spacing(1),
  verticalAlign: 'top',
}));

const Container = styled('div')(({ theme }) => ({
  background: theme.palette.warning.light,
  padding: theme.spacing(4),
}));

const AlertDialogHeader = ({ onClose, title, alertMessage }) => {
  return (
    <Container open onClose={onClose}>
      <CloseButton onClick={onClose}>
        <Close size={23} />
      </CloseButton>
      <StyledTitle id="form-dialog-title">
        <Icon size={18} /> {title}
      </StyledTitle>
      <ThemeTypography color="RED" variant="BODY_LARGE">
        {alertMessage}
      </ThemeTypography>
    </Container>
  );
};

AlertDialogHeader.propTypes = {
  onClose: PropTypes.func,
  title: PropTypes.string.isRequired,
  alertMessage: PropTypes.string.isRequired,
};

export default AlertDialogHeader;
