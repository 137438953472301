import { Box } from '@mui/material';
import useRawMaterials from 'components/ComponentsLibrary/hooks/useRawMaterials';
import { Loader } from 'components/Forms';
import { FieldWrapper } from 'designSystem';
import AutocompleteSelection from 'designSystem/Inputs/AutocompleSelection/AutocompleteSelection';
import { useField } from 'formik';
import React, { FC } from 'react';

interface IRawMaterialSelectionProps {
  name: string;
  size?: 'small' | 'medium';
  helperText?: string;
  isRequired?: boolean;
  multiple?: boolean; // Default is true
  label?: string;
  labelVariant?: 'small' | 'medium' | 'large';
  disabled?: boolean;
}

const RawMaterialSelection: FC<IRawMaterialSelectionProps> = ({
  name,
  size = 'medium',
  helperText,
  isRequired,
  multiple = true,
  label = 'Raw material',
  labelVariant,
  disabled,
}) => {
  const { rawMaterials, loading, createRawMaterial } = useRawMaterials();

  const [{ value }, { touched, error }, { setValue, setTouched }] = useField<
    string[] | string | undefined
  >(name);

  const handleSelectionChange = async (selection: string[] | string | undefined) => {
    await setTouched(true);
    setValue(selection || undefined, true);
  };

  const handleAddNewItemClick = (title: string) => {
    createRawMaterial({ variables: { title } });
  };

  return (
    <FieldWrapper
      label={`${label}${multiple ? 's' : ''}`}
      labelIcon="raw-material"
      variant={labelVariant || size}
      required={isRequired}
      tooltip={
        helperText
          ? {
              helperText,
              variant: 'INFO',
            }
          : undefined
      }
    >
      {loading ? (
        <Box position="relative">
          <Loader size={16} />
        </Box>
      ) : (
        <AutocompleteSelection
          label="Select raw material"
          disabled={disabled}
          closeAfterSelection
          enableAddNewItem
          multiple={multiple}
          data-cy="raw-material-autocomplete"
          size={size}
          itemName="raw material"
          items={rawMaterials}
          errorMessage={error}
          hasError={!!error && touched}
          selected={value || (multiple ? [] : '')}
          onAddNewItemClick={handleAddNewItemClick}
          onSelectionChange={handleSelectionChange}
        />
      )}
    </FieldWrapper>
  );
};

export default RawMaterialSelection;
