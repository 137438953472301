import { getClientTime } from 'components/Product/utils';
import { useConfig } from 'components/hooks';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';

export type TimeFrame = 'last-day' | 'last-7-days' | 'last-month' | 'last-3-months' | 'custom';

interface ITimeFrame {
  start?: string;
  end?: string;
  label: string;
  value: TimeFrame;
  unit: string;
}

const timeFrames: Record<TimeFrame, ITimeFrame> = {
  'last-day': {
    start: getClientTime('days', 1),
    end: getClientTime(),
    label: 'Last day',
    value: 'last-day',
    unit: 'day',
  },
  'last-7-days': {
    start: getClientTime('days', 7),
    end: getClientTime(),
    label: 'Last week',
    value: 'last-7-days',
    unit: 'week',
  },
  'last-month': {
    start: getClientTime('months', 1),
    end: getClientTime(),
    label: 'Last month',
    value: 'last-month',
    unit: 'month',
  },
  'last-3-months': {
    start: getClientTime('months', 3),
    end: getClientTime(),
    label: 'Last 3 months',
    value: 'last-3-months',
    unit: '3 months',
  },
  custom: {
    label: 'Custom',
    value: 'custom',
    unit: 'period',
  },
};

export const timeFramekeys = Object.keys(timeFrames) as TimeFrame[];

const getTimeFrame = (value: TimeFrame) => {
  if (!timeFrames[value]) throw new Error('Timestamp value does not exist in config');

  return timeFrames[value];
};

export const useTimeframe = () => {
  const { appQueryParams } = useConfig();
  const {
    value: defaultValue,
    start: defaultStart,
    end: defaultEnd,
  } = getTimeFrame('last-7-days') as Required<ITimeFrame>;

  const [query, setQuery] = useQueryParams({
    [appQueryParams.selectedTimeFrame]: withDefault(StringParam, defaultValue),
    [appQueryParams.selectedTimeFrameStart]: withDefault(StringParam, defaultStart),
    [appQueryParams.selectedTimeFrameEnd]: withDefault(StringParam, defaultEnd),
  });

  const handleTimeFrameChange = (selectedTimeFrame: TimeFrame, start?: string, end?: string) => {
    const timeFrame = timeFrames[selectedTimeFrame];

    setQuery(
      {
        [appQueryParams.selectedTimeFrame]: selectedTimeFrame,
        [appQueryParams.selectedTimeFrameStart]:
          selectedTimeFrame !== 'custom' ? timeFrame.start : start,
        [appQueryParams.selectedTimeFrameEnd]: selectedTimeFrame !== 'custom' ? timeFrame.end : end,
      },
      'pushIn'
    );
  };

  const defaultValues = {
    selectedTimeFrame: defaultValue,
    start: defaultStart,
    end: defaultEnd,
  };

  return {
    timeFramekeys,
    handleTimeFrameChange,
    getTimeFrame,
    ...defaultValues,
    ...query,
  };
};
