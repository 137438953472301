import GeoData from 'designSystem/Inputs/GeoData/GeoData';
import { FieldProps, useField } from 'formik';
import React, { FC } from 'react';
import { MultiPolygon } from 'types/map.types';
import { Coordinates } from 'types/types';

interface IGeoDataFieldProps {
  marker?: Coordinates;
  defaultCenter?: Coordinates;
}

const GeoDataField: FC<
  Omit<IGeoDataFieldProps, 'value' | 'onChange'> & FieldProps<MultiPolygon>
> = ({ field: { value, name }, ...props }) => {
  const [, , { setValue }] = useField<MultiPolygon>(name);

  return <GeoData defaultValue={value} {...props} onChange={setValue} />;
};

export default GeoDataField;
