import React, { FC } from 'react';
import { useImpactClaim } from 'components/ImpactClaims/hooks';

const ClaimTitle: FC = () => {
  const { impactClaim } = useImpactClaim();

  return <span>{impactClaim?.impactCatalogItem?.title || ''}</span>;
};

export default ClaimTitle;
