import { Box, styled } from '@mui/material';
import EudrComplianceGuideSubSection from 'components/ComplianceGuide/EudrComplianceGuideSubSection';
import { useEudrComplianceGuide } from 'components/ComplianceGuide/EudrComplianceGuideContext';
import { PageSubTitle } from 'components/Structure';
import { ThemeButton, ThemeTypography } from 'designSystem';
import React, { FC, useState } from 'react';
import { EudrAffectednessType, EudrResponsibilityType } from 'types/compliance.types';
import QnAGuideTemplate from '../../QnAGuideTemplate';
import AnnexTable from '../AnnexTable';

const HintContainer = styled(Box)(({ theme }) => ({
  background: theme.custom.themeColors.primary[5],
  borderRadius: theme.spacing(1),
  padding: theme.spacing(2),
  height: 'fit-content',
}));

const AffectedMarketPlacement: FC = () => {
  const {
    formData,
    selectedSubSection,
    selectedStatementId,
    setFormData,
    setSelectedSubSection,
    updateStatement,
    navigateToNextSubSection,
  } = useEudrComplianceGuide();

  const [selectedAnswer, setSelectedAnswer] = useState<string | undefined>();

  const handleNextStepClick = () => {
    setFormData({
      ...formData,
      previousSubSectionKey: selectedSubSection?.key,
      affectedMarkedPlacement: selectedAnswer === 'yes',
    });
    // If answer is no, then  next question, if answer is yes, then the company is fully affected as trader with operator responsibilities
    if (selectedAnswer === 'yes') {
      setSelectedSubSection('fully-affected');
      if (selectedStatementId) {
        updateStatement(selectedStatementId, {
          companyAffectednessType: EudrAffectednessType.FULLY_AFFECTED,
          companyResponsibilityType: EudrResponsibilityType.TRADER_OPERATOR,
        });
      }
    } else {
      navigateToNextSubSection();
    }
  };

  return (
    <EudrComplianceGuideSubSection
      allowNextStepNavigation={!!selectedAnswer}
      titleAdornment={
        <ThemeButton color="BLUE_ICE" disabled>
          Skip & apply previous affectedness
        </ThemeButton>
      }
      onNextStepClick={handleNextStepClick}
    >
      <QnAGuideTemplate
        selectedAnswer={selectedAnswer}
        question="Is your company sourcing any of the products listed in Annex I from outside the EU?"
        answers={[
          { id: 'yes', label: 'Yes' },
          {
            id: 'no',
            label:
              'No. Products may originate from outside EU, but we buy it from suppliers based within the EU.',
          },
        ]}
        onAnswerChange={setSelectedAnswer}
      />
      <HintContainer mt={2}>
        <ThemeTypography variant="BODY_MEDIUM" color="BLUE_80">
          If your company handle different commodities for which the answer here varies, you need to
          do a separate due diligence procedure for each commodity where your answer is different.
        </ThemeTypography>
      </HintContainer>

      <Box mt={2} />
      <PageSubTitle title="Annex I" />
      <Box mt={1} />
      <AnnexTable />
    </EudrComplianceGuideSubSection>
  );
};

export default AffectedMarketPlacement;
