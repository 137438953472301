import DatePicker, { IDatePickerProps } from 'designSystem/Inputs/DatePicker/DatePicker';
import { FieldProps, useField } from 'formik';
import moment from 'moment';
import React, { FC } from 'react';

const DatePickerField: FC<Omit<IDatePickerProps, 'value' | 'onChange'> & FieldProps<string>> = ({
  field: { value, name },
  ...props
}) => {
  const [, { error, touched }, { setValue, setTouched }] = useField<string>(name);

  return (
    <DatePicker
      {...props}
      value={value}
      errorMessage={touched ? error : undefined}
      onChange={(_, date) => setValue(moment(date).utc().toISOString())}
      onTouch={() => setTouched(true)}
    />
  );
};

export default DatePickerField;
