import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ThemeTypography, ThemeButton } from 'designSystem';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import React, { Fragment, FC } from 'react';
import * as Yup from 'yup';

interface IRequestPlanUpgradeFormProps {
  onSubmit: (values: { message: string }) => void;
}

const UpgradeSchema = Yup.object().shape({
  message: Yup.string().required('This field is required'),
});

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.custom.colors.lightBorderColor,
  },

  '& .MuiOutlinedInput-multiline': {
    padding: theme.spacing(1),
  },
}));

const RequestPlanUpgradeForm: FC<IRequestPlanUpgradeFormProps> = ({ onSubmit }) => {
  return (
    <Fragment>
      <ThemeTypography variant="BODY_LARGE">
        To find the right fit for you, please tell us how many users will contribute or how many
        products you aim to publish.
      </ThemeTypography>
      <Formik
        initialValues={{ message: '' }}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={UpgradeSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <Box my={2}>
              <Field
                component={StyledTextField}
                multiline={true}
                rows={5}
                fullWidth
                name="message"
                variant="outlined"
                placeholder="Let us know your needs..."
                data-cy="upgrade-plan-input"
              />
            </Box>
            <ThemeTypography variant="BODY_LARGE">This request is not binding.</ThemeTypography>
            <Box mt={2}>
              <ThemeButton
                type="submit"
                color="YELLOW"
                size="large"
                loading={isSubmitting}
                data-cy="request-upgrade-plan-btn"
              >
                Send Request
              </ThemeButton>
            </Box>
          </Form>
        )}
      </Formik>
    </Fragment>
  );
};

export default RequestPlanUpgradeForm;
