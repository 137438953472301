import React, { FC, ReactNode } from 'react';

interface IConditionalRenderProps {
  /**
   * The condition to render the children
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  condition: boolean | any;
  children: (args: {
    True: FC<{ children: ReactNode | ReactNode[] }>;
    False: FC<{ children: ReactNode | ReactNode[] }>;
  }) => ReactNode | ReactNode[];
}

const Render: FC<{ children: ReactNode }> = ({ children }) => <>{children}</>;
const Empty: FC = () => <></>;

const ConditionalRender: FC<IConditionalRenderProps> = ({ children, condition }) => {
  if (Boolean(condition)) {
    return <>{children({ True: Render, False: Empty })}</>;
  } else {
    return <>{children({ True: Empty, False: Render })}</>;
  }
};

export default ConditionalRender;
