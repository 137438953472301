import { Box, Tooltip } from '@mui/material';
import defaultPartnerImage from 'assets/img/partners/default-partner-image.png';
import { PageSubTitle } from 'components/Structure';
import { ItemCardSmall, ThemeTypography } from 'designSystem';
import Image from 'designSystem/DataDisplay/Image/Image';
import truncate from 'lodash/truncate';
import React, { FC } from 'react';
import { IBasePartnerCompany } from 'types/partner.types';
import { getCompanyStatusColor, getReadableCompanyStatus } from 'utils/partner.utils';

interface IPartnerListProps {
  partners: IBasePartnerCompany[];
}

const PartnerList: FC<IPartnerListProps> = ({ partners }) => {
  return (
    <Box flex={1} data-cy="partner-list">
      <PageSubTitle title="Connected partners" mb={2} />
      <Box>
        {partners.map(partner => (
          <ItemCardSmall key={partner.id}>
            <Box display="flex" alignItems="center" gap={2.5}>
              <Box pl={1}>
                <Image
                  alt={`Logo of ${partner.name}`}
                  image={partner.logo}
                  backupImageUrl={defaultPartnerImage}
                  width={42}
                  height={42}
                />
              </Box>
              <Box>
                <Tooltip title={partner.name || ''}>
                  <ThemeTypography variant="ITEM_TITLE">
                    {truncate(partner.name, {
                      length: 45,
                      separator: '',
                    })}
                  </ThemeTypography>
                </Tooltip>
                {partner.status && (
                  <Box display="flex" alignItems="center" gap={0.8}>
                    <ThemeTypography
                      variant="ITEM_SUBTITLE"
                      color={getCompanyStatusColor(partner.status)}
                    >
                      {getReadableCompanyStatus(partner.status)}
                    </ThemeTypography>
                  </Box>
                )}
              </Box>
            </Box>
          </ItemCardSmall>
        ))}
      </Box>
    </Box>
  );
};

export default PartnerList;
