import { IconButton, Tooltip, styled } from '@mui/material';
import {
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  MouseSensor,
  PointerSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToWindowEdges } from '@dnd-kit/modifiers';
import { PlusIcon } from 'assets/img/icons';
import { ThemeButton } from 'designSystem';
import React, { FC, Fragment } from 'react';
import { IChain } from 'types/chain.types';
import AbsoluteElement from './components/AbsoluteElement';
import Activity from './components/Activity';
import DropZone from './components/DropZone';
import Step from './components/Step';
import SubChain from './components/SubChain';
import useChainMappingConfig from './components/useChainMappingConfig';
import useChainMapping, { IPrevConnectionIds } from './useChainMapping';
import { ChainMappingEvent, VIEW_MODE_CONFIG, ViewModeType } from './utils/mapping.types';

interface IChainMappingProps {
  chainMapping: IChain;
  width?: number;
  height?: number;
  viewMode?: ViewModeType;
  onActionClick: (event: ChainMappingEvent) => void;
}

const Container = styled(AbsoluteElement)(({ theme }) => ({
  userSelect: 'none',
  position: 'relative',
  height: '100%',
  width: '100%',
  overflow: 'auto',
  backgroundColor: '#fff',
  borderRadius: 6,
  border: `1px solid ${theme.custom.colors.lightestBorder}`,
}));

const Background = styled(AbsoluteElement)(({ theme }) => ({
  backgroundColor: theme.custom.colors.lightestBorder,
}));

const HeaderColumns = styled(AbsoluteElement)({
  '&:hover .add-step-btn': {
    display: 'flex',
  },
});

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  height: '100%',
  width: '100%',
  backgroundColor: theme.custom.themeColors.primary[5],
  color: theme.palette.primary.main,
  borderRadius: 8,
  '&:hover': {
    backgroundColor: theme.custom.themeColors.primary[5],
    color: theme.custom.colors.secondaryLighter,
  },
}));

const ChainMapping: FC<IChainMappingProps> = ({
  chainMapping,
  viewMode = 'default',
  onActionClick,
}) => {
  const viewModeConfig = VIEW_MODE_CONFIG[viewMode];
  const {
    onboardingTour,
    showFirstColumn,
    size,
    containerRef,
    activitiesWithoutSubChain,
    draggingSubChain,
    orderedSubChains,
    steps,
    onSubChainDragStart,
    onSubChainDragEnd,
    onSubChainCreationHighlightToggle,
    isChainStepActivityHighlighted,
    areSubChainStepActivityHighlighted,
  } = useChainMapping(chainMapping, viewModeConfig);
  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(PointerSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor)
  );
  const { SPACING, HEADER_HEIGHT, COLUMN_WIDTH, FIRST_COLUMN_WIDTH, NODE_HEIGHT } =
    useChainMappingConfig();

  const handleImportClick = () => onActionClick({ type: 'IMPORT' });
  const handleEditStepClick = (elementId: string) =>
    onActionClick({ type: 'EDIT_STEP', elementId });
  const handleAddStepClick = (targetIndex: number) =>
    onActionClick({ type: 'ADD_STEPS', targetIndex });
  const handleEditActivityClick = (elementId: string) =>
    onActionClick({ type: 'EDIT_ACTIVITY', elementId });
  const handleAddActivityClick = (stepId: string) =>
    onActionClick({ type: 'ADD_ACTIVITY', stepId });
  const handleSubChainRemoveClick = (elementId: string) =>
    onActionClick({ type: 'REMOVE_SUB_CHAIN', elementId });
  const handleSubChainOrderChange = (event: DragEndEvent) => {
    const srcId = event.active.id as string;
    const targetId = event.over?.id as string;
    const subChainOrder = orderedSubChains.map(({ id }) => id);
    if (
      srcId &&
      targetId &&
      srcId !== targetId &&
      subChainOrder.includes(srcId) &&
      subChainOrder.includes(targetId)
    ) {
      const newSubChainOrder = subChainOrder.filter(id => id !== srcId);
      const insertAtIndex = newSubChainOrder.findIndex(id => id === targetId);
      newSubChainOrder.splice(insertAtIndex, 0, srcId);
      onActionClick({ type: 'REORDER', subChainOrder: newSubChainOrder });
    }
    onSubChainDragEnd();
  };
  const handleEditSubChainClick = (elementId: string) =>
    onActionClick({ type: 'EDIT_SUB_CHAIN', elementId });
  const handleSubChainCreationClick = (prevConnectionIds: IPrevConnectionIds) =>
    onActionClick({
      type: 'ADD_SUB_CHAIN_FROM_ACTIVITY',
      ...prevConnectionIds,
    });
  const handleSiteClusterClick = (elementId: string) =>
    onActionClick({
      type: 'OPEN_SITE_CLUSTER',
      elementId,
    });

  return (
    <DndContext
      sensors={sensors}
      modifiers={[restrictToWindowEdges]}
      collisionDetection={closestCenter}
      onDragStart={onSubChainDragStart}
      onDragEnd={handleSubChainOrderChange}
      onDragCancel={onSubChainDragEnd}
    >
      <Container ref={containerRef} data-cy="chain-mapping-container">
        {/* First column background is shown when user can import or subChains are shown */}
        {showFirstColumn && (
          <Background width={FIRST_COLUMN_WIDTH + SPACING * 2} height={size.height} />
        )}

        {/* Import button */}
        {viewModeConfig.canImportSubChains && (
          <AbsoluteElement
            x={SPACING * 2}
            y={SPACING * 2}
            width={FIRST_COLUMN_WIDTH - SPACING * 2}
            height={HEADER_HEIGHT}
          >
            <ThemeButton
              autoOverflow
              color="WHITE"
              onClick={handleImportClick}
              data-cy="import-chains-btn"
              startIcon={<PlusIcon width={12} />}
            >
              Import component chains
            </ThemeButton>
          </AbsoluteElement>
        )}

        {/* Highlighted elements for onboarding tour */}
        {onboardingTour && (
          <Fragment>
            {/* Highlight 3 steps with 5 activities */}
            <AbsoluteElement
              x={FIRST_COLUMN_WIDTH + SPACING * 4}
              y={SPACING * 2}
              width={COLUMN_WIDTH * steps.length + SPACING * 2}
              height={HEADER_HEIGHT + NODE_HEIGHT * 2 + SPACING * 4}
              data-tut="onboarding_tour_editor_step_activities"
            />
            {/* Highlight 5 steps with 5 activities */}
            <AbsoluteElement
              x={FIRST_COLUMN_WIDTH + SPACING * 4}
              y={SPACING * 2}
              width={COLUMN_WIDTH * steps.length + SPACING * 4}
              height={HEADER_HEIGHT + NODE_HEIGHT * 2 + SPACING * 4}
              data-tut="onboarding_tour_editor_step_activities_big"
            />
            {/* Highlight first column */}
            <AbsoluteElement
              x={SPACING * 2}
              y={SPACING * 2}
              width={FIRST_COLUMN_WIDTH - SPACING * 2}
              height="100%"
              data-tut="onboarding_tour_editor_step_import_chain"
            />
            {/* Highlight 5 steps with 2 subchains */}
            <AbsoluteElement
              x={SPACING * 2}
              y={SPACING * 2}
              height={HEADER_HEIGHT + NODE_HEIGHT * 2 + SPACING * 5}
              width={FIRST_COLUMN_WIDTH + COLUMN_WIDTH * steps.length + SPACING * 6}
              data-tut="onboarding_tour_editor_step_sub_chain_big"
            />
            {/* Highlight 3 steps with 2 subchains */}
            <AbsoluteElement
              x={SPACING * 2}
              y={SPACING * 2}
              height={HEADER_HEIGHT + NODE_HEIGHT * 2 + SPACING * 5}
              width={FIRST_COLUMN_WIDTH + COLUMN_WIDTH * steps.length + SPACING * 4}
              data-tut="onboarding_tour_editor_step_sub_chain"
            />
          </Fragment>
        )}

        {/* Steps / Columns */}
        <HeaderColumns data-cy="step-columns">
          {steps.map((step, xIndex) => (
            <Step
              key={step.id}
              {...step}
              canEdit={viewModeConfig.canEditSteps}
              canAddStepBefore={viewModeConfig.canAddSteps && xIndex > 0}
              disableEdit={xIndex === 0}
              onAddActivityClick={stepId => handleAddActivityClick(stepId)}
              onEditStepClick={handleEditStepClick}
              onAddStepClick={() => handleAddStepClick(xIndex)}
            />
          ))}
        </HeaderColumns>

        {/* Last add step button */}
        {viewModeConfig.canAddSteps && (
          <AbsoluteElement
            x={
              (showFirstColumn ? FIRST_COLUMN_WIDTH + SPACING * 4 : SPACING * 2) +
              SPACING * steps.length +
              COLUMN_WIDTH * steps.length
            }
            y={SPACING * 2}
            width={HEADER_HEIGHT}
            height={HEADER_HEIGHT}
            data-cy="add-step-btn"
          >
            <Tooltip title="Add step">
              <StyledIconButton onClick={() => handleAddStepClick(steps.length)} disableRipple>
                <PlusIcon width={12} />
              </StyledIconButton>
            </Tooltip>
          </AbsoluteElement>
        )}

        {/* Activities */}
        {activitiesWithoutSubChain.map(activity => (
          <Activity
            // Guarantee uniqueness since multiple activities can have the same id if imported multiple times
            key={`${activity.id}-${activity.x}-${activity.y}`}
            {...activity}
            // The indicator can only be shown if enough space is available from the import button
            showClusterIndicator={showFirstColumn}
            highlight={isChainStepActivityHighlighted(activity.id)}
            canEdit={viewModeConfig.canEditActivities}
            onEditClick={handleEditActivityClick}
            onSubChainCreationHighlightToggle={onSubChainCreationHighlightToggle}
            onSubChainCreationClick={handleSubChainCreationClick}
            onClusterClick={handleSiteClusterClick}
          />
        ))}

        {/* SubChains */}
        {orderedSubChains.map(chain => (
          <>
            {draggingSubChain && (
              <DropZone {...chain} isCancel={draggingSubChain.id === chain.id} />
            )}
            <SubChain
              {...chain}
              key={chain.id}
              canSeePartner={viewModeConfig.canSeePartner}
              canReorder={viewModeConfig.canEditSubChains && orderedSubChains.length > 1}
              canDelete={viewModeConfig.canEditSubChains}
              onRemoveClick={handleSubChainRemoveClick}
              onEditClick={handleEditSubChainClick}
            >
              {chain.activities.map(activity => (
                <Activity
                  // Guarantee uniqueness since multiple activities can have the same id if imported multiple times
                  key={`${activity.id}-${activity.x}-${activity.y}`}
                  {...activity}
                  // The indicator can only be shown if enough space is available from the import button
                  showClusterIndicator={showFirstColumn}
                  canEdit={viewModeConfig.canEditSubChainActivities}
                  highlight={areSubChainStepActivityHighlighted(chain.id)}
                  onEditClick={handleEditActivityClick}
                  onClusterClick={handleSiteClusterClick}
                />
              ))}
            </SubChain>
          </>
        ))}
      </Container>
    </DndContext>
  );
};

export default ChainMapping;
