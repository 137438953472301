import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React from 'react';

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  margin: theme.spacing(1, 'auto'),
}));

const Label = styled('label')(({ theme, background }) => ({
  textAlign: 'center',
  background: background || '#fff',
  padding: theme.spacing(2),
  zIndex: 2,
}));

const StyledCustomLine = styled('hr')(({ theme }) => ({
  position: 'absolute',
  backgroundColor: theme.custom.colors.lightBorderColor,
  height: 1,
  border: 'none',
  left: 0,
  right: 0,
}));

const SeparatorLine = ({ label, backgroundColor, ...props }) => {
  return (
    <Container {...props}>
      {label && <Label background={backgroundColor}>{label}</Label>}
      <StyledCustomLine />
    </Container>
  );
};

SeparatorLine.propTypes = {
  label: PropTypes.string,
  backgroundColor: PropTypes.string,
};

export default SeparatorLine;
