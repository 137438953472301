import authResolvers from './auth.resolvers';
import imageResolvers from './image.resolvers';
import mapboxResolvers from './mapbox.resolvers';
import companyResolvers from './company.resolvers';
import qrCodeResolvers from './qrCode.resolvers';
import productResolver from './product.resolvers';
import merge from 'lodash/merge';
import imageConverterResolvers from './imageConverter.resolvers';
import chainsResolver from './chain.resolvers';

export default merge(
  authResolvers,
  imageResolvers,
  mapboxResolvers,
  companyResolvers,
  qrCodeResolvers,
  productResolver,
  imageConverterResolvers,
  chainsResolver
);
