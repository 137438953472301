import { gql } from '@apollo/client';
import { CHAIN_FRAGMENT } from 'graphql/fragments/chain.fragments';

export const UPDATE_CHAIN_STEP = gql`
  mutation UpdateChainStep($input: UpdateChainStepInput!) {
    updateChainStep(input: $input) {
      chain {
        ...chainValues
      }
    }
  }
  ${CHAIN_FRAGMENT}
`;

export const CREATE_CHAIN_STEPS = gql`
  mutation CreateChainStep($input: CreateChainStepsInput!) {
    createChainSteps(input: $input) {
      chain {
        ...chainValues
      }
    }
  }
  ${CHAIN_FRAGMENT}
`;

export const DELETE_CHAIN_STEP = gql`
  mutation DeleteChainStep($id: UUID!) {
    deleteChainStep(id: $id) {
      chain {
        ...chainValues
      }
    }
  }
  ${CHAIN_FRAGMENT}
`;

export const REMOVE_SUB_CHAIN = gql`
  mutation RemoveSubChain($id: UUID!) {
    removeSubChain(id: $id) {
      chain {
        ...chainValues
      }
    }
  }
  ${CHAIN_FRAGMENT}
`;

export const REORDER_SUB_CHAINS = gql`
  mutation ReorderSubChains($id: UUID!, $subChainIdsOrdered: [UUID]!) {
    reorderSubChains(id: $id, ids: $subChainIdsOrdered) {
      chain {
        ...chainValues
      }
    }
  }
  ${CHAIN_FRAGMENT}
`;

export const IMPORT_CHAINS = gql`
  mutation ImportChains($id: UUID!, $importChainIds: [UUID]!) {
    importChains(id: $id, ids: $importChainIds) {
      chain {
        ...chainValues
      }
    }
  }
  ${CHAIN_FRAGMENT}
`;

export const DUPLICATE_CHAIN = gql`
  mutation DuplicateChain($input: DuplicateChainInput!) {
    duplicateChain(input: $input) {
      chain {
        ...chainValues
      }
    }
  }
  ${CHAIN_FRAGMENT}
`;

export const CREATE_CHAIN_ACTIVITY = gql`
  mutation CreateChainActivity($input: CreateChainActivityInput!) {
    createChainActivity(input: $input) {
      chain {
        ...chainValues
      }
    }
  }
  ${CHAIN_FRAGMENT}
`;

export const UPDATE_CHAIN_ACTIVITY = gql`
  mutation UpdateChainActivity($id: UUID!, $input: UpdateChainActivityInput!) {
    updateChainActivity(id: $id, input: $input) {
      chain {
        ...chainValues
      }
    }
  }
  ${CHAIN_FRAGMENT}
`;

export const DELETE_CHAIN_ACTIVITY = gql`
  mutation DeleteChainActivity($id: UUID!) {
    deleteChainActivity(id: $id) {
      chain {
        ...chainValues
      }
    }
  }
  ${CHAIN_FRAGMENT}
`;

export const INITIALISE_CHAIN_MAPPING = gql`
  mutation InitialiseChainMapping($id: UUID!) {
    initialiseChainMapping(id: $id) {
      chain {
        ...chainValues
      }
    }
  }
  ${CHAIN_FRAGMENT}
`;

export const CREATE_SUB_CHAIN_FROM_STEP_ACTIVITIES = gql`
  mutation CreateSubChainFromStepActivity($input: CreateSubChainFromStepActivityInput!) {
    createSubChainFromStepActivity(input: $input) {
      chain {
        ...chainValues
      }
    }
  }
  ${CHAIN_FRAGMENT}
`;

// ############################### COMPONENT CHAIN RELATED MUTATIONS ###############################

export const CREATE_COMPONENT_CHAIN = gql`
  mutation CreateComponentChain($input: CreateChainInput) {
    createChain(input: $input) {
      chain {
        ...chainValues
      }
    }
  }
  ${CHAIN_FRAGMENT}
`;

export const DELETE_COMPONENT_CHAIN = gql`
  mutation DeleteComponentChain($id: UUID!) {
    deleteChain(id: $id) {
      id
    }
  }
`;

export const UPDATE_COMPONENT_CHAIN = gql`
  mutation UpdateComponentChain($id: UUID!, $input: UpdateChainInput!) {
    updateChain(id: $id, input: $input) {
      chain {
        ...chainValues
      }
    }
  }
  ${CHAIN_FRAGMENT}
`;
