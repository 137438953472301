import { Box, Divider } from '@mui/material';
import { FormikAutoValidate } from 'components/Forms';
import { companySchemaCombined } from 'constants/schemas/company';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { stripValuesToSchema } from 'utils/form.utils';
import AddressFields from './AddressFields';
import CompanyFields from './CompanyFields';

const CompanyFormCombined = ({ data, children, onSubmit }) => {
  return (
    <Formik
      initialValues={{
        ...data,
        address: data.address || {},
      }}
      validationSchema={companySchemaCombined}
      validateOnBlur={false}
      validateOnChange={true}
      onSubmit={values => {
        return onSubmit(stripValuesToSchema(values, companySchemaCombined));
      }}
    >
      {({ isSubmitting, values }) => (
        <Form>
          <CompanyFields />
          <Box pt={2} pb={2}>
            <Divider />
          </Box>
          <AddressFields fullWidth accessor="address." />
          <FormikAutoValidate showValidation />
          {children &&
            children({
              isSubmitting,
              values,
            })}
        </Form>
      )}
    </Formik>
  );
};

CompanyFormCombined.propTypes = {
  children: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
};

export default CompanyFormCombined;
