import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import FileUploadItem from '../MediaLibrary/FileUploadItem';

const AllowedType = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginRight: theme.spacing(4),

  '&:last-of-type': {
    marginRight: 0,
  },
}));

const AllowedTitle = styled(Typography)({
  fontSize: '12px',
});

const AllowedDescription = styled(Typography)({
  whiteSpace: 'nowrap',
  fontSize: '12px',
});

const AllowedIcon = styled('div')(({ theme }) => ({
  color: theme.palette.grey[400],
  marginRight: theme.spacing(1),
}));

const AllowedFileType = ({ icon, description, title }) => {
  return (
    <AllowedType>
      <AllowedIcon>{icon}</AllowedIcon>
      <Box display="flex" flexDirection="column">
        <AllowedTitle color="inherit" variant="h6">
          {title}
        </AllowedTitle>
        <AllowedDescription color="inherit" variant="subtitle2">
          {description}
        </AllowedDescription>
      </Box>
    </AllowedType>
  );
};

FileUploadItem.propTypes = {
  icon: PropTypes.node,
  description: PropTypes.string,
  title: PropTypes.string,
};

export default AllowedFileType;
