/* eslint-disable @typescript-eslint/no-explicit-any */
import reduce from 'lodash/reduce';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';

/**
 * Returns the keys of both object/arrays a and b
 * @param a
 * @param b
 * @returns
 */
export const difference = (a: any, b: any) =>
  reduce(
    a,
    (result: string[], value: any, key: string) =>
      isEqual(value, b[key]) ? result : result.concat(key),
    []
  );

export const isDeepEmpty = (input: any) => {
  if (isEmpty(input)) {
    return true;
  }
  if (typeof input === 'object') {
    for (const item of Object.values(input)) {
      // if item is not undefined and is a primitive, return false
      // otherwise dig deeper
      if ((item !== undefined && typeof item !== 'object') || !isDeepEmpty(item)) {
        return false;
      }
    }
    return true;
  }
  return isEmpty(input);
};
