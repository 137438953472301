import { useMutation } from '@apollo/client';
import { LOGOUT } from 'graphql/mutations';
import React, {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import useLocalStorage from 'react-use/lib/useLocalStorage';
import FirebaseContext from './FirebaseContext';

const VersionContext = createContext<null | true>(null);
const STORAGE_KEY = 'seedtrace-cms-build-id';

const VersionProvider: FC<PropsWithChildren> = ({ children }) => {
  // States for local build id check
  const { authenticated } = useContext(FirebaseContext);
  const avoidBuildIdCheck = !authenticated || process.env.NODE_ENV === 'development';

  const [logout] = useMutation(LOGOUT);
  const [didCheckVersion, setDidCheckVersion] = useState(false);
  const [storedBuildId, setStoredBuildId] = useLocalStorage(STORAGE_KEY);

  useEffect(() => {
    // Only on new window open
    async function checkBuildId() {
      const currentBuildId = process.env.REACT_APP_BUILD_ID;

      // Store build id if none is in local storage or if user just visited
      if (!storedBuildId || !authenticated) {
        setStoredBuildId(currentBuildId);
      }

      if (avoidBuildIdCheck) {
        setDidCheckVersion(true);
        return;
      }

      if (storedBuildId !== currentBuildId) {
        setStoredBuildId(currentBuildId);
        await logout();
      }

      setDidCheckVersion(true);
    }

    checkBuildId();
  }, []); //eslint-disable-line

  if (!didCheckVersion) {
    return <></>;
  }

  return <VersionContext.Provider value={didCheckVersion}>{children}</VersionContext.Provider>;
};

export { VersionContext, VersionProvider };

export default VersionContext;
