import { useMutation } from '@apollo/client';
import {
  Box,
  Button,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { CaretDownFill } from '@styled-icons/bootstrap/CaretDownFill';
import { useConfig, useLogEvent, useMessages } from 'components/hooks';
import ThemeButton from 'designSystem/Buttons/ThemeButton/ThemeButton';
import { UPDATE_QR_DESIGN } from 'graphql/mutations';
import React, { FC, Fragment, PropsWithChildren, useRef, useState } from 'react';
import { SupportedImageCustomFormats } from 'types/enums';
import { ProductQrCodeDesign } from 'types/types';

interface IQRDownloadButtonProps extends PropsWithChildren {
  qrCode: ProductQrCodeDesign;
  productId: string;
  title: string;
}

const ImageTypeButton = styled(Button)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  marginRight: theme.spacing(1),
  border: 'none',
  minWidth: 80,
}));

// TODO: Move this to a util function and merge with the other download functions
const handleDownload = (fileURL: string, fileName: string) => {
  const save = document.createElement('a');
  save.href = fileURL;
  save.target = '_blank';
  const filename = fileURL.substring(fileURL.lastIndexOf('/') + 1);
  save.download = fileName || filename;

  if (
    navigator.userAgent.toLowerCase().match(/(ipad|iphone)/) &&
    navigator.userAgent.search('Chrome') < 0
  ) {
    document.location = save.href;
  } else {
    const evt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: false,
    });
    save.dispatchEvent(evt);
    (window.URL || window.webkitURL).revokeObjectURL(save.href);
  }
};

const options = Object.values(SupportedImageCustomFormats);

const QRDownloadButton: FC<IQRDownloadButtonProps> = ({ qrCode, productId, title }) => {
  const { logEvent } = useLogEvent();
  const { setErrorMessage } = useMessages();
  const config = useConfig();

  const anchorRef = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const suffix = options[selectedIndex] === SupportedImageCustomFormats.EPS ? '.eps' : '';
  const [updateQRDesign, { loading }] = useMutation(UPDATE_QR_DESIGN, {
    onCompleted: ({ updateQRDesign: blob }) => {
      const url = URL.createObjectURL(blob);
      // The EPS image extension is not added to the file name automatically. For other formats it will be added by the browser.
      handleDownload(url, `${title}_qr_code${suffix}`);
      logEvent('DOWNLOAD_QR_CODE');
    },
    onError: () => {
      setErrorMessage('We could not retrieve your QR code. Please contact our support.');
    },
  });

  const handleMenuItemClick = (index: number) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as Node)) {
      return;
    }
    setOpen(false);
  };

  const handleUpdate = async () => {
    const selectedFormat = options[selectedIndex];
    return updateQRDesign({
      variables: {
        qrCodeDesign: {
          design: {
            ...qrCode.design,
            qr_code_text: config.generateRedirectURL(productId),
            // api accepts only JPG
            image_format: selectedFormat === 'JPEG' ? 'JPG' : selectedFormat,
            image_width: 512,
          },
          responseType: 'blob',
        },
      },
    });
  };

  return (
    <Fragment>
      <Box display="flex">
        <ImageTypeButton
          ref={anchorRef}
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
          variant="outlined"
          color="inherit"
          size="small"
          endIcon={<CaretDownFill size={12} />}
          data-cy="select-image-type-btn"
        >
          <Box mr={0.5}>{`${options[selectedIndex]}`}</Box>
        </ImageTypeButton>

        <ThemeButton
          loading={loading}
          size="small"
          color="BLUE_ICE"
          data-cy="download-custom-qr-btn"
          onClick={handleUpdate}
        >
          Download QR code
        </ThemeButton>
      </Box>

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        style={{
          zIndex: 1400,
        }}
        transition
        data-cy="image-type-popper"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      selected={index === selectedIndex}
                      onClick={() => handleMenuItemClick(index)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Fragment>
  );
};

export default QRDownloadButton;
