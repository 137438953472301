import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { BoxArrowUpRight } from '@styled-icons/bootstrap/BoxArrowUpRight';
import DocumentSkeleton from 'components/DocumentLibrary/DocumentSkeleton';
import { DocumentTable } from 'components/DocumentLibrary/Table';
import { DocumentTableBodyAction } from 'components/DocumentLibrary/Table/DocumentTableBody';
import { DocumentsTableColumnKeys } from 'components/DocumentLibrary/Table/constants/documentHeaderColumns';
import { Loader, SearchToolbar } from 'components/Forms';
import {
  EmptySearchResults,
  ErrorState,
  InfiniteScrollWrapper,
  PageContainer,
  PageTitle,
  ScrollableTableContainer,
  ActionContainer,
} from 'components/Structure';
import { usePartnerDocuments } from 'components/hooks';
import { ThemeButton } from 'designSystem';
import Icon from 'designSystem/Primitives/Icon/Icon';
import React, { FC, Fragment } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { RouteParamsWithId } from 'types/router.types';
import { IDocument } from 'types/document.types';

const StyledInfiniteScrollWrapper = styled(InfiniteScrollWrapper)(({ theme }) => ({
  margin: theme.spacing(0, -2),
  padding: theme.spacing(0, 2),
  overflow: 'auto',
}));

const PartnerDocuments: FC = () => {
  const {
    documents,
    selectedDocumentIds,
    error,
    loading,
    searchTerm,
    hasNextPage,
    allowBulkDeletion,
    setSearchTerm,
    handlePageEndReached,
    setSelectedDocumentIds,
    handleDeleteSelectedDocuments,
  } = usePartnerDocuments();
  const navigate = useNavigate();
  const { id } = useParams<RouteParamsWithId>();

  const documentTableActions: DocumentTableBodyAction[] = [
    {
      icon: <BoxArrowUpRight size="15" />,
      tooltip: 'Open in new tab',
      onClick: (document: IDocument) => window.open(document.url, '_blank'),
      dataCy: 'download-document-btn',
    },
  ];

  /**
   * Route to main overview and open create new request dialog
   */
  const handleNewRequestClick = () => {
    if (id) {
      navigate(`/partners/${id}?createNewRequest=1`);
    } else {
      console.error('No partner id found');
    }
  };

  const opeDocumentOverview = (document: IDocument) => {
    navigate(`/partners/${id}/documents/${document.id}`);
  };

  if (error) {
    return <ErrorState />;
  }

  if (loading) {
    return <Loader />;
  }

  if (!documents.length && !searchTerm) {
    return (
      <PageContainer>
        <PageTitle title="Partner documents" goBackLabel="All Partners" goBackUrl="/partners" />
        <EmptySearchResults skeletonComponent={<DocumentSkeleton />} />
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      <PageTitle title="Partner documents" goBackLabel="All Partners" goBackUrl="/partners" />
      <Box position="relative">
        <ActionContainer>
          <SearchToolbar
            setSearchTerm={setSearchTerm}
            searchTerm={searchTerm}
            data-cy="partner-document-search-input"
            placeholder="Search document"
            className="search-input"
            actions={
              <Box display="flex">
                <Box px={2}>
                  <ThemeButton
                    data-cy="new-request-btn"
                    startIcon={<Icon name="document-request-pending" />}
                    onClick={handleNewRequestClick}
                  >
                    New request
                  </ThemeButton>
                </Box>
                {selectedDocumentIds.length !== 0 && (
                  <ThemeButton
                    size="medium"
                    color="WHITE"
                    tooltip={'Documents are used as proof in impact claims'}
                    disabled={!allowBulkDeletion}
                    startIcon={<Icon name="delete" size="small" />}
                    onClick={handleDeleteSelectedDocuments}
                  >
                    Delete {selectedDocumentIds.length}
                  </ThemeButton>
                )}
              </Box>
            }
          />
        </ActionContainer>

        <StyledInfiniteScrollWrapper hasMore={hasNextPage} next={handlePageEndReached}>
          <ScrollableTableContainer>
            <DocumentTable
              columns={[
                DocumentsTableColumnKeys.TITLE,
                DocumentsTableColumnKeys.OWNER,
                DocumentsTableColumnKeys.USAGE,
                DocumentsTableColumnKeys.ISSUE_DATE,
                DocumentsTableColumnKeys.EXPIRY_DATE,
                DocumentsTableColumnKeys.ACTIONS,
              ]}
              documents={documents}
              actions={documentTableActions}
              selectedDocumentIds={selectedDocumentIds}
              onDocumentClick={opeDocumentOverview}
              onSelectionChange={setSelectedDocumentIds}
            />
          </ScrollableTableContainer>
        </StyledInfiniteScrollWrapper>
      </Box>

      {!documents?.length ? (
        <EmptySearchResults
          message="No search results found"
          skeletonComponent={<DocumentSkeleton title={searchTerm} />}
        />
      ) : (
        <Fragment />
      )}
    </PageContainer>
  );
};

export default PartnerDocuments;
