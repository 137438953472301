import { Box, Typography, useMediaQuery } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { useEditorState } from 'components/Product/MultiStep/hooks';
import ContentStatus, { IContentStatusProps } from 'components/Product/ProductTable/ContentStatus';
import { useProduct } from 'components/Product/hooks';
import React, { FC, useMemo } from 'react';
import LanguageSelect from '../LanguageSelect';
import { Loader } from 'components/Forms';
import Image from 'designSystem/DataDisplay/Image/Image';
import ProductExampleImage from 'assets/img/product-placeholder-image-big.svg';

const StyledContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(1.5),
  overflow: 'visible',
  position: 'relative',
  height: '100%',
  borderRadius: 8,
  background: '#fff',
  border: '2px solid',
  borderColor: theme.palette.grey[200],
}));

const Title = styled(Typography)(({ theme }) => ({
  fontWeight: 900,
  lineHeight: 1,
  minHeight: theme.spacing(3),
}));

const Summary: FC = () => {
  const { lang } = useEditorState();
  const { product, latestProductVersion, loading } = useProduct();
  const theme = useTheme();
  const mediumScreen = useMediaQuery(theme.breakpoints.down('lg'));

  const styledProgress: Omit<IContentStatusProps, 'size'> = useMemo(() => {
    const published = product?.publishedLanguages?.includes(lang);
    const contentStatus = latestProductVersion?.versionValidation.contentStatus || 0;

    if (published) {
      return {
        status: 'PUBLISHED',
        color: theme.palette.success.main,
        contentStatus,
      };
    } else {
      return {
        status: 'DRAFT',
        color: theme.palette.grey[600],
        contentStatus,
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang, product, latestProductVersion]);

  if (loading || !product) {
    return <Loader />;
  }

  return (
    <StyledContainer>
      <Image
        image={latestProductVersion?.image}
        width={60}
        height={60}
        backupImageUrl={ProductExampleImage}
      />
      <Box ml={2} display="flex">
        <Box display="flex" flexDirection="column">
          <Title variant="h5">{latestProductVersion?.title || product?.title}</Title>
          <Box mt={1} display="flex">
            <Box mr={1}>
              <LanguageSelect product={product} />
            </Box>
            <ContentStatus {...styledProgress} size={mediumScreen ? 'small' : 'large'} />
          </Box>
        </Box>
      </Box>
    </StyledContainer>
  );
};

export default Summary;
