import { gql } from '@apollo/client';
import { ACTIVITY_FRAGMENT } from 'graphql/fragments/activity.fragments';

export const GET_ACTIVITY = gql`
  query GetActivity($id: UUID!, $lang: Language) {
    activity(id: $id, lang: $lang) {
      ...activityValues
    }
  }
  ${ACTIVITY_FRAGMENT}
`;

export const GET_ACTIVITIES = gql`
  query GetActivities($lang: Language, $filters: ActivitiesFilters) {
    activities(lang: $lang, filters: $filters) {
      edges {
        node {
          ...activityValues
        }
      }
    }
  }
  ${ACTIVITY_FRAGMENT}
`;
