import { styled } from '@mui/material/styles';
import { Trash } from '@styled-icons/bootstrap/Trash';
import unarchive from 'assets/img/icons/unarchive.svg';
import { AlertDialog } from 'designSystem';
import { useProductActions } from 'components/hooks';
import ProductTable from 'components/Product/ProductTable/ProductTable';
import React, { FC, Fragment, useState } from 'react';
import { Product } from 'types/types';

interface IArchivedProductTableProps {
  products: Product[];
  hideHeader?: boolean;
}

export const UnarchiveIcon = styled('img')(() => ({
  width: 18,
}));

const ArchivedProductTable: FC<IArchivedProductTableProps> = ({ products, hideHeader }) => {
  const { handleDeleteProduct, handleUnarchiveProduct } = useProductActions();

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);

  const handleDelete = () => {
    if (!selectedProduct) return;

    handleDeleteProduct(selectedProduct.id);
    setDeleteDialogOpen(false);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  const handleOpenDeleteDialog = (product: Product) => {
    setDeleteDialogOpen(true);
    setSelectedProduct(product);
  };

  return (
    <Fragment>
      <ProductTable
        data-cy="archived-product-table"
        hideHeader={hideHeader}
        archived
        products={products}
        actions={[
          {
            icon: <UnarchiveIcon src={unarchive} />,
            tooltip: 'Unarchive Product',
            onClick: ({ id }) => handleUnarchiveProduct(id),
            dataCy: 'unarchive-product-btn',
          },
          {
            icon: <Trash size="18" />,
            tooltip: 'Delete Product',
            onClick: handleOpenDeleteDialog,
            dataCy: 'delete-product-btn',
          },
        ]}
      />
      <AlertDialog
        onClose={handleCloseDeleteDialog}
        title="Delete product"
        open={deleteDialogOpen}
        text="Are you sure you want to delete this product? All data will be lost and you will not be able to recover this product."
        onSubmit={handleDelete}
        submitText="Yes, Delete"
        onCancel={handleCloseDeleteDialog}
      />
    </Fragment>
  );
};

export default ArchivedProductTable;
