import { ColDef } from 'ag-grid-community';
import { SYSTEM_COLUMN_DEF } from 'components/DataImport/constants/dataImport.constants';
import { SystemColumns } from '../excelTable.types';
import DeleteCellRenderer from '../CellRenderers/SystemColumns/DeleteCellRenderer';

const DELETE_COLUMN: ColDef<SystemColumns> = {
  ...SYSTEM_COLUMN_DEF,
  field: 'delete',
  pinned: 'right',
  cellRenderer: DeleteCellRenderer,
};

export default DELETE_COLUMN;
