import { Chip, ChipTypeMap } from '@mui/material';
import { styled } from '@mui/material/styles';
import { X as Close } from '@styled-icons/bootstrap/X';
import React, { Fragment } from 'react';
import { AvailableLanguages } from 'types/enums';

const StyledChip = styled(Chip)<{ padding?: number }>(({ theme, padding }) => ({
  fontSize: 12,
  padding: padding ? padding : theme.spacing(0.3),
  height: 'auto',
  borderRadius: theme.spacing(0.5),
  margin: 0,
  marginRight: theme.spacing(0.5),
  marginBottom: theme.spacing(0.5),
  background: theme.custom.colors.backgroundLight,

  '& .MuiChip-label': {
    padding: padding ? padding : 'inherit',
  },

  '& .MuiChip-deleteIcon': {
    width: 18,
    height: 18,
    color: theme.palette.grey[600],
    marginLeft: 1,
  },
}));

interface Props {
  onDelete?: () => void;
  lang?: AvailableLanguages;
  padding?: number;
}

const DeletableChip: React.FC<Props & ChipTypeMap['props']> = ({
  onDelete,
  lang,
  padding,
  ...props
}) => {
  return (
    <StyledChip
      {...props}
      padding={padding}
      onDelete={() => null}
      deleteIcon={
        onDelete ? (
          <Close
            data-cy="delete-button"
            data-cy-lang={`delete-button-${lang}`}
            size={8}
            onMouseDown={event => {
              event.stopPropagation();
              onDelete();
            }}
          />
        ) : (
          <Fragment />
        )
      }
    />
  );
};

export default DeletableChip;
