import {
  Accordion,
  AccordionDetails,
  AccordionSlots,
  AccordionSummary,
  Box,
  Collapse,
  IconButton,
  LinearProgress,
  styled,
} from '@mui/material';
import ComplianceEudrImage from 'assets/img/compliance/eudr.png';
import { Loader } from 'components/Forms';
import { ErrorState, FlexBox, PageContainer, PageTitle } from 'components/Structure';
import { usePageTitle } from 'components/hooks';
import { CardContainer, ThemeButton, ThemeTypography } from 'designSystem';
import Icon from 'designSystem/Primitives/Icon/Icon';
import useEudrComplianceStatements from 'hooks/useEudrComplianceStatements';
import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { EudrStatementStatus } from 'types/compliance.types';
import { AvailableSizes } from 'types/enums';

export const StyledImage = styled('div')<{ backgroundImage: string }>(({ backgroundImage }) => ({
  width: '42px',
  height: '42px',
  flexShrink: 0,
  backgroundImage: `url(${backgroundImage})`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  borderRadius: 4,
}));

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  background: 'transparent',
  boxShadow: 'none',

  '&:not(:last-child)': {
    margin: 0,
    marginBottom: theme.spacing(1),
  },
  '&::before': {
    display: 'none',
  },
}));

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  backgroundColor: theme.custom.themeColors.white,
  borderRadius: 8,
  boxShadow: theme.custom.shadows[4],
  '& .MuiAccordionSummary-content': {
    justifyContent: 'space-between',
    paddingRight: theme.spacing(2),
  },
}));

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  backgroundColor: theme.custom.themeColors.white,
  borderRadius: 8,
  borderTopRightRadius: 0,
  borderTopLeftRadius: 0,
  boxShadow: theme.custom.shadows[4],
}));

const BorderLinearProgress = styled(LinearProgress)<{ percentage: number }>(
  ({ percentage, theme }) => ({
    borderRadius: 2,
    height: 12,
    width: 100,
    backgroundColor: theme.palette.grey[300],
    marginRight: theme.spacing(1),

    '& .MuiLinearProgress-bar': {
      backgroundColor: percentage === 100 ? theme.palette.success.main : theme.palette.primary.main,
    },
  })
);

const Compliance: FC = () => {
  usePageTitle('Compliance');
  const navigate = useNavigate();
  const { statements, loading, error } = useEudrComplianceStatements();

  const handleComplianceGuideClick = (regulation: 'eudr', statementId: string) =>
    navigate(`/compliance/${regulation}/${statementId}/guide`);
  const handleComplianceOverviewClick = (regulation: 'eudr', statementId?: string) =>
    navigate(`/compliance/${regulation}${statementId ? `/${statementId}` : ''}`);

  const [expanded, setExpanded] = useState<boolean>(false);

  if (loading) {
    return <Loader />;
  }
  if (error || !statements) {
    return <ErrorState />;
  }

  return (
    <PageContainer>
      <PageTitle title="Compliance" />
      <Box mb={3} />

      <StyledAccordion
        expanded={expanded}
        slots={{ transition: Collapse as AccordionSlots['transition'] }}
        slotProps={{ transition: { timeout: 100 } }}
      >
        <StyledAccordionSummary
          expandIcon={
            statements.length ? (
              <IconButton
                onClick={event => {
                  event.stopPropagation();
                  event.preventDefault();
                  setExpanded(prev => !prev);
                }}
              >
                <Icon name="chevron-down" color="gray-40" />
              </IconButton>
            ) : undefined
          }
          onClick={() => handleComplianceOverviewClick('eudr')}
        >
          <FlexBox>
            <StyledImage backgroundImage={ComplianceEudrImage} />
            <Box ml={2}>
              <ThemeTypography variant="ITEM_TITLE">
                EU Deforestation regulation (EUDR)
              </ThemeTypography>
              <ThemeTypography variant="BODY_MEDIUM" color="GRAY_80">
                Due diligence progress
              </ThemeTypography>
            </Box>
          </FlexBox>
          <FlexBox justifyContent="flex-end">
            {/* <Tooltip title="Start new due diligence statement">
              <IconButton>
                <Icon name="due-diligence-statement-add" />
              </IconButton>
            </Tooltip> */}
            {/* <Tooltip title="Compliance guide"> // I don't believe this makes a lot of sense when multiple guides can be in progress 
                    <IconButton>
                      <Icon name="guide" />
                    </IconButton>
                  </Tooltip> */}
          </FlexBox>
        </StyledAccordionSummary>
        <StyledAccordionDetails>
          <Box display="flex" gap={1} flexDirection="column" flexWrap="wrap">
            {statements.map(({ id, title, year, status }) => (
              <CardContainer
                key={id}
                blueHover
                padding={1}
                size={AvailableSizes.SMALL}
                onClick={() => handleComplianceOverviewClick('eudr', id)}
              >
                <FlexBox justifyContent="space-between">
                  <FlexBox>
                    <Icon name="statement" color="gray-100" size="xxx-large" mr={2} />
                    <Box ml={2}>
                      <ThemeTypography variant="ITEM_TITLE">
                        {title} - {year}
                      </ThemeTypography>
                      <Box display="flex" alignItems="center">
                        <BorderLinearProgress percentage={40} variant="determinate" value={40} />
                        <ThemeTypography variant="ITEM_SUBTITLE" color="GRAY_80">
                          1/6 COMPLETED
                        </ThemeTypography>
                      </Box>
                    </Box>
                  </FlexBox>

                  <FlexBox gap={2}>
                    {status !== EudrStatementStatus.SUBMITTED_EU ? (
                      <ThemeButton
                        startIcon={<Icon name="guide" />}
                        onClick={event => {
                          event.stopPropagation();
                          handleComplianceGuideClick('eudr', id);
                        }}
                      >
                        Continue compliance guide
                      </ThemeButton>
                    ) : (
                      <ThemeButton color="BLUE_ICE" startIcon={<Icon name="guide" />}>
                        Overview results
                      </ThemeButton>
                    )}
                  </FlexBox>
                </FlexBox>
              </CardContainer>
            ))}
          </Box>
        </StyledAccordionDetails>
      </StyledAccordion>
    </PageContainer>
  );
};

export default Compliance;
