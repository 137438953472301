import { SupportedFileTypes } from 'components/FileUpload/Dropzone';
import React, { useState, createContext, PropsWithChildren, FC } from 'react';

interface ISelectableProviderProps extends PropsWithChildren {
  selectableMedia?: SupportedFileTypes[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onMediaSelect?: (item: any) => void;
  setUploadActive?: (active: boolean) => void;
  onClose?: () => void;
}

/**
 * This context is really badly implemented and should be soon refactored
 * @deprecated
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const SelectableContext = createContext<any>(null);

/**
 * This provider is really badly implemented and should be soon refactored
 * @deprecated
 */
const SelectableProvider: FC<ISelectableProviderProps> = ({
  children,
  selectableMedia = ['image', 'video'],
  onMediaSelect,
  setUploadActive,
  onClose,
}) => {
  const isSelectable = typeof onMediaSelect === 'function';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [selectedItem, setSelectedItem] = useState<any>({});

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit = (item: any) => {
    if (isSelectable) onMediaSelect(item);
    onClose?.();
  };

  return (
    <SelectableContext.Provider
      value={{
        onSelect: setSelectedItem,
        selectedItem,
        selectableMedia,
        isSelectable,
        setUploadActive,
        onSubmit,
        onUnselect: () => setSelectedItem({}),
      }}
    >
      {children}
    </SelectableContext.Provider>
  );
};

export { SelectableProvider };

export default SelectableContext;
