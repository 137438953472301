import { DialogActions, DialogContent } from '@mui/material';
import { DialogDefault, FieldWrapper, ThemeButton, ThemeTypography } from 'designSystem';
import React, { FC, useState } from 'react';
import { IDefaultDialogProps } from 'types/dialog.types';
import ComponentChainAutocomplete from '../Forms/ComponentChainAutocomplete';
import SupplyChainAutocomplete from '../Forms/SupplyChainAutocomplete';
import useComponentChainActions from '../hooks/useComponentChainActions';
import useSupplyChainActions from 'components/SupplyChainsLibrary/hooks/useSupplyChainActions';

interface ICopyChainDialogProps extends IDefaultDialogProps {
  chainId: string;
  /**
   * If this is set it indicates that the chain is a supply chain
   * The productId is needed if the chain is a supply chain
   */
  productVersionId?: string;
}

const CopyChainDialog: FC<ICopyChainDialogProps> = ({
  open,
  chainId,
  productVersionId,
  onClose,
}) => {
  const { copySupplyChain, initialiseChainMapping: initialiseSupplyChainMapping } =
    useSupplyChainActions();
  const { copyComponentChain, initializeChainMapping: initializeComponentChainMapping } =
    useComponentChainActions();

  const [selectedSupplyChainId, setSelectedSupplyChainId] = useState<string>();
  const [selectedComponentChainId, setSelectedComponentChainId] = useState<string>();
  const [hasError, setHasError] = useState<boolean>();

  const handleSupplyChainChange = (selectedChainId?: string) => {
    setSelectedSupplyChainId(selectedChainId);
    setHasError(!selectedChainId);
  };

  const handleComponentChainChange = (selectedChainId?: string) => {
    setSelectedComponentChainId(selectedChainId);
    setHasError(!selectedChainId);
  };

  const handleCopyClick = async () => {
    const selectedChainId = productVersionId ? selectedSupplyChainId : selectedComponentChainId;
    if (!selectedChainId) {
      setHasError(true);
      return;
    }
    const mutation = productVersionId ? copySupplyChain : copyComponentChain;
    const { errors } = await mutation({
      variables: {
        input: {
          sourceChainId: selectedChainId,
          targetChainId: chainId,
          productVersionId,
        },
      },
    });
    if (!errors) {
      const initialiseMutation = productVersionId
        ? initialiseSupplyChainMapping
        : initializeComponentChainMapping;

      await initialiseMutation({ variables: { id: chainId } });
      onClose?.();
    }
  };

  return (
    <DialogDefault
      open={open}
      title={`Copy ${productVersionId ? 'supply' : 'component'} chain`}
      onClose={onClose}
      fullWidth
      maxWidth="xs"
      data-cy="copy-chains-dialog"
    >
      <DialogContent>
        {productVersionId ? (
          <FieldWrapper label="Copy chain from supply chain" variant="small">
            <SupplyChainAutocomplete
              filterChainId={chainId}
              onSupplyChainSelect={handleSupplyChainChange}
            />
            {hasError && (
              <ThemeTypography variant="ITEM_SUBTITLE" color="RED">
                Please select a supply chain
              </ThemeTypography>
            )}
          </FieldWrapper>
        ) : (
          <FieldWrapper label="Copy chain from component chain" variant="small">
            <ComponentChainAutocomplete
              filterChainId={chainId}
              onComponentChainSelect={handleComponentChainChange}
            />
            {hasError && (
              <ThemeTypography variant="ITEM_SUBTITLE" color="RED">
                Please select a component chain
              </ThemeTypography>
            )}
          </FieldWrapper>
        )}
      </DialogContent>
      <DialogActions>
        <ThemeButton
          color="BLUE_ICE"
          size="large"
          data-cy="copy-chain-dialog-cancel"
          onClick={onClose}
        >
          Cancel
        </ThemeButton>
        <ThemeButton size="large" data-cy="copy-chain-dialog-import" onClick={handleCopyClick}>
          Copy
        </ThemeButton>
      </DialogActions>
    </DialogDefault>
  );
};

export default CopyChainDialog;
