import { Box, styled } from '@mui/material';
import EudrComplianceGuideSubSection from 'components/ComplianceGuide/EudrComplianceGuideSubSection';
import { useEudrComplianceGuide } from 'components/ComplianceGuide/EudrComplianceGuideContext';
import ExcelTable, { IGridApiHandle } from 'components/DataImport/DataImportTable/ExcelTable';
import HeaderColumn from 'components/DataImport/DataImportTable/HeaderColumn';
import { ColDefWithValidator } from 'components/DataImport/DataImportTable/excelTable.types';
import { createColumnDefinition } from 'components/DataImport/utils/dataImport.utils';
import {
  createPositiveNumberValidator,
  validateCoordinates,
  validateNotEmpty,
  validateUniqueness,
} from 'components/DataImport/utils/dataImport.validators';
import { Loader } from 'components/Forms';
import { ErrorState } from 'components/Structure';
import DatasetItem from 'designSystem/DataDisplay/DatasetItem/DatasetItem';
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { booleanish } from 'types/booleanish.types';
import { EUDRDatasetStatus } from 'types/compliance.types';

type TableCell = {
  value: string;
  error?: string;
  isValid?: boolean;
};

type TableRowData = {
  name: TableCell;
  farmId: TableCell;
  coordinates: TableCell;
  size?: TableCell;
};

export const COLUMN_DEFINITIONS: ColDefWithValidator<TableRowData>[] = [
  createColumnDefinition('farmId', () => <HeaderColumn title="Farm Id" />, validateUniqueness),
  createColumnDefinition('name', () => <HeaderColumn title="Name" />, validateNotEmpty),
  createColumnDefinition(
    'coordinates',
    () => <HeaderColumn title="Coordinates (Lat,Lng)" />,
    validateCoordinates
  ),
  createColumnDefinition(
    'size',
    () => <HeaderColumn title="Size (km²)" />,
    createPositiveNumberValidator('Farm size needs to be a number, in e.g. square km.')
  ),
];

const DatasetContainer = styled('div')<{ disabled?: booleanish }>(({ disabled }) => ({
  cursor: disabled === 'true' ? 'initial' : 'pointer',
}));

const ValidateGeoData: FC = () => {
  const gridApiRef = useRef<IGridApiHandle>(null);
  const { statement } = useEudrComplianceGuide();
  const { error, loading } = { error: false, loading: false };

  const [selectedDatasetId, setSelectedDatasetId] = useState<string>();

  useEffect(() => {
    if (statement && statement.eudrDatasets.length) {
      setSelectedDatasetId(statement.eudrDatasets[0].id);
    }
  }, [statement]);

  const shownColumnKeys = useMemo(() => {
    const selectedDataset = statement?.eudrDatasets.find(({ id }) => id === selectedDatasetId);
    if (selectedDataset && selectedDataset.tableRepresentation?.length) {
      return selectedDataset.tableRepresentation?.[0].columnsNames || [];
    }
    return [];
  }, [statement, selectedDatasetId]);

  const selectedDatasetRowData = useMemo(() => {
    const selectedDataset = statement?.eudrDatasets.find(({ id }) => id === selectedDatasetId);
    if (selectedDatasetId && selectedDataset) {
      return (
        selectedDataset.tableRepresentation?.[0]?.rows.map(row => ({
          id: uuid(),
          name: {
            value: row.name.value,
            isValid: !row.name.error?.length,
            errorMessage: row.name.error?.[0].errorMessage,
          },
          farmId: {
            value: row.farmId.value,
            isValid: !row.farmId.error?.length,
            errorMessage: row.farmId.error?.[0].errorMessage,
          },
          coordinates: {
            value: row.coordinates.value,
            isValid: !row.coordinates.error?.length,
            errorMessage: row.coordinates.error?.[0].errorMessage,
          },
          ...(row.size
            ? {
                size: {
                  value: row.size.value,
                  isValid: !row.size.error?.length,
                  errorMessage: row.size.error?.[0].errorMessage,
                },
              }
            : {}),
        })) || []
      );
    }
  }, [statement, selectedDatasetId]);

  const columns = useMemo(
    () => COLUMN_DEFINITIONS.filter(({ field }) => field && shownColumnKeys.includes(field)),
    [shownColumnKeys]
  );

  if (loading) return <Loader />;
  if (error) return <ErrorState action={undefined} />;

  const handleSubmit = () => {
    if (gridApiRef.current) {
      if (gridApiRef.current.runValidations()) {
        gridApiRef.current.getDataAsCsv();
      }
    }
  };

  return (
    <>
      <EudrComplianceGuideSubSection allowNextStepNavigation onNextStepClick={handleSubmit}>
        <Box display="flex" gap={2} flexWrap="wrap">
          {statement?.eudrDatasets.map(
            ({ status, id, title, rawMaterial, originCountry, ownedBy: partner }) => (
              <DatasetContainer key={id} onClick={() => setSelectedDatasetId(id)}>
                <DatasetItem
                  title={title}
                  commodity={rawMaterial.title}
                  location={originCountry}
                  owner={partner.name}
                  active={id === selectedDatasetId}
                  status={
                    status === EUDRDatasetStatus.VALIDATED ||
                    status === EUDRDatasetStatus.NORMALISED
                      ? 'valid'
                      : 'error'
                  }
                />
              </DatasetContainer>
            )
          )}
        </Box>
      </EudrComplianceGuideSubSection>

      <Box mt={2}>
        {selectedDatasetRowData && (
          <ExcelTable<TableRowData>
            mode="validation"
            gridRef={gridApiRef}
            rowData={selectedDatasetRowData}
            columnDefs={columns}
          />
        )}
      </Box>
    </>
  );
};

export default ValidateGeoData;
