import { Box, Divider, DialogContent } from '@mui/material';
import { ThemeButton } from 'designSystem';
import React, { FC } from 'react';
import { IDefaultDialogProps } from 'types/dialog.types';
import { PartnerRequest } from 'types/partner.types';
import { ReceivedRequestCard } from 'components/Home/Requests';
import { ThemeTypography, CardContainer, DialogDefault } from 'designSystem';
import CompanyNameLogo from 'components/Structure/CompanyNameLogo';
import { AvailableSizes } from 'types/enums';
import DocumentIntroTitle from 'components/DocumentLibrary/DocumentIntroTitle';
import Icon from 'designSystem/Primitives/Icon/Icon';
import { useDialog } from 'components/hooks';
import usePartnerRequestActions from 'components/Partners/PartnerRequests/usePartnerRequestActions';
import { useMessages } from 'components/hooks';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import ItemProgressTitle from 'designSystem/DataDisplay/ItemProgressTitle/ItemProgressTitle';

interface IReviewChainRequestDialogProps extends IDefaultDialogProps {
  request: PartnerRequest;
}

const ActionBar = styled(Box)(({ theme }) => ({
  width: '100%',
  right: 0,
  backgroundColor: '#fff',
  height: theme.spacing(10),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(0, 3),
  boxShadow: theme.custom.shadows[1],
}));

const StyledDialog = styled(DialogDefault)(({ theme }) => ({
  '& .MuiDialog-paper': {
    minWidth: 550,
  },
  '& h2': {
    padding: '16px 24px 0 !important',
  },
}));

const ReviewChainRequestDialog: FC<IReviewChainRequestDialogProps> = ({
  open,
  onClose,
  request,
}) => {
  const { openDialog } = useDialog();
  const navigate = useNavigate();
  const { updatePartnerRequest } = usePartnerRequestActions();
  const { setErrorMessage } = useMessages();

  const handleAccept = () => {
    openDialog({
      type: 'ALERT',
      props: {
        title: 'Accept chain',
        submitText: 'Accept and close request',
        text: `Are you sure you want to accept this chain? The request will be closed and considered completed.`,
        onSubmit: handleAcceptConfirm,
        onCancel: () => undefined,
      },
    });
  };

  const handleAcceptConfirm = () => {
    updatePartnerRequest({
      variables: {
        id: request.id,
        input: {
          requestStatus: 'PROVIDED_COMPLETED',
        },
      },
      onError: () =>
        setErrorMessage('Something went wrong finishing the request. Please try again later.'),
      onCompleted: onClose,
      refetchQueries: ['GetChain'],
    });
  };

  const handleEditRequest = () => {
    openDialog({
      type: 'PARTNER_REQUEST',
      props: {
        onClose,
        initialRequest: request,
        requestedToCompany: request.requestedToCompany,
      },
    });
  };

  const handleNavigateToChain = () => {
    onClose?.();
    navigate(`/component-chains/${request?.chain?.id}/editor`);
  };

  return (
    <StyledDialog
      title="Review chain mapping response"
      iconName="chain-request-manage"
      open={open}
      onClose={onClose}
      data-cy="accept-request-dialog"
    >
      <DialogContent>
        <Box mt={2} />
        <ReceivedRequestCard displayYourMessage request={request} displayActions={false} />
        <CardContainer padding={1} mt={3}>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            {request.chain && (
              <ItemProgressTitle
                title={request?.chain.title}
                taskCompleted={request?.chain.chainTasksDetails.completed}
                taskTotal={request?.chain.chainTasksDetails.total}
                backupIcon={<Icon name="component-chain-image" size="xx-large" color="blue-ice" />}
              />
            )}
            <ThemeButton
              color="BLUE_ICE"
              onClick={handleNavigateToChain}
              startIcon={<Icon name="chain-request-manage" size="small" />}
              data-cy="view-chain-btn"
            >
              Review chain
            </ThemeButton>
          </Box>
        </CardContainer>

        <Box mt={4} mb={3}>
          <Divider />
        </Box>
        <Box display="flex" alignItems="center" mb={1.5}>
          <CompanyNameLogo
            size={AvailableSizes.MEDIUM}
            companyName={request.requestedToCompany.name}
          />
          <ThemeTypography variant="BODY_LARGE_BOLD">
            &nbsp;has confirmed this chain mapping
          </ThemeTypography>
        </Box>
        {request.document && (
          <Box mb={2}>
            <CardContainer padding={1.5} selected>
              <DocumentIntroTitle document={request.document} showOpenInNewTab />
            </CardContainer>
          </Box>
        )}
      </DialogContent>
      <ActionBar>
        <ThemeButton color="BLUE_ICE" type="button" onClick={onClose}>
          Cancel
        </ThemeButton>
        <Box display="flex" justifyContent="end">
          <ThemeButton
            color="BLUE_ICE"
            type="button"
            onClick={handleEditRequest}
            startIcon={<Icon name="edit" />}
            data-cy="edit-request-btn"
          >
            Edit request
          </ThemeButton>
          <Box mr={1} />
          <ThemeButton
            data-cy="accept-chain-mapping-btn"
            color="YELLOW"
            type="submit"
            startIcon={<Icon name="chain-request-fulfilled" />}
            onClick={handleAccept}
          >
            Accept chain mapping
          </ThemeButton>
        </Box>
      </ActionBar>
    </StyledDialog>
  );
};

export default ReviewChainRequestDialog;
