import EudrComplianceGuideSubSection from 'components/ComplianceGuide/EudrComplianceGuideSubSection';
import { useEudrComplianceGuide } from 'components/ComplianceGuide/EudrComplianceGuideContext';
import QnAGuideTemplate from 'components/ComplianceGuide/components/QnAGuideTemplate';
import { ThemeButton } from 'designSystem';
import React, { FC, useEffect, useState } from 'react';
import { EudrAffectednessType } from 'types/compliance.types';

const CompanyLocation: FC = () => {
  const { statement, navigateToNextSubSection, setSelectedSubSection, setFormData } =
    useEudrComplianceGuide();

  const [selectedAnswer, setSelectedAnswer] = useState<string | undefined>();

  // Route to the correct sub section if parts were already done
  useEffect(() => {
    if (statement) {
      if (statement.companyAffectednessType === EudrAffectednessType.NOT_AFFECTED) {
        setSelectedSubSection('not-affected');
      }
      if (statement.companyAffectednessType === EudrAffectednessType.INDIRECTLY_AFFECTED) {
        setSelectedSubSection('indirectly-affected');
      }
      if (statement.companyAffectednessType === EudrAffectednessType.PARTIALLY_AFFECTED) {
        setSelectedSubSection('partially-affected');
      }
      if (statement.companyAffectednessType === EudrAffectednessType.FULLY_AFFECTED) {
        setSelectedSubSection('fully-affected');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statement]);

  const handleNextStepClick = () => {
    setFormData({ sourceOutsideOfEU: selectedAnswer === 'yes' });
    navigateToNextSubSection();
  };

  return (
    <EudrComplianceGuideSubSection
      allowNextStepNavigation={!!selectedAnswer}
      titleAdornment={
        <ThemeButton color="BLUE_ICE" disabled>
          Skip & apply previous affectedness
        </ThemeButton>
      }
      onNextStepClick={handleNextStepClick}
    >
      <QnAGuideTemplate
        selectedAnswer={selectedAnswer}
        question="Is your company sourcing any of the affected commodities from outside the EU?"
        answers={[
          { id: 'yes', label: 'Yes' },
          {
            id: 'no',
            label:
              'No. Products may originate from outside EU, but we buy it from suppliers based within EU.',
          },
        ]}
        onAnswerChange={setSelectedAnswer}
      />
    </EudrComplianceGuideSubSection>
  );
};

export default CompanyLocation;
