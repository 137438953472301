import React, { FC, Fragment, useState } from 'react';
import { AlertDialog } from 'designSystem';
import { Archive } from '@styled-icons/bootstrap/Archive';
import { BarChart } from '@styled-icons/bootstrap/BarChart';
import { Edit } from '@styled-icons/boxicons-solid/Edit';
import { Files } from '@styled-icons/bootstrap/Files';
import { useProductActions } from 'components/hooks';
import { ProductTable } from 'components/Product/ProductTable';
import { Product } from 'types/types';

interface IActiveProductTableProps {
  products: Product[];
}

const ActiveProductTable: FC<IActiveProductTableProps> = ({ products }) => {
  const { handleDuplicateProduct, goToProductAnalytics, handleArchiveProduct, handleEditProduct } =
    useProductActions();

  const [archiveDialogOpen, setArchiveDialogOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);

  const handleOpenArchiveDialog = (product: Product) => {
    setArchiveDialogOpen(true);
    setSelectedProduct(product);
  };

  const handleCloseArchiveDialog = () => {
    setArchiveDialogOpen(false);
  };

  const handleArchive = () => {
    if (!selectedProduct) return;

    handleArchiveProduct(selectedProduct.id);
    setArchiveDialogOpen(false);
  };

  return (
    <Fragment>
      <ProductTable
        data-cy="active-product-table"
        hideHeader={!products.length}
        rowsPerPage={6}
        products={products}
        actions={[
          {
            icon: <Archive size="18" />,
            tooltip: 'Archive product',
            onClick: handleOpenArchiveDialog,
            dataCy: 'archive-product-btn',
          },
          {
            icon: <Files size="17" />,
            tooltip: 'Duplicate product',
            onClick: handleDuplicateProduct,
            dataCy: 'duplicate-product-btn',
          },
          {
            icon: <Edit size="17" />,
            tooltip: 'Edit product',
            onClick: product => handleEditProduct(product),
          },
          {
            icon: <BarChart size="18" />,
            tooltip: 'View analytics',
            onClick: ({ id }) => goToProductAnalytics(id),
          },
        ]}
      />
      <AlertDialog
        onClose={handleCloseArchiveDialog}
        title="Archive product"
        open={archiveDialogOpen}
        text="Are you sure you want to archive this product? Your product will not be available anymore to any visits or QR code scans. You can unarchive your product and republish it."
        onSubmit={handleArchive}
        submitText="Yes, Archive"
        onCancel={handleCloseArchiveDialog}
      />
    </Fragment>
  );
};

export default ActiveProductTable;
