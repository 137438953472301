import Config from 'config/Config';

export interface ImageConverterResolverVariables {
  outputFormat: 'eps' | 'pdf' | 'png' | 'jpg' | 'svg' | 'gif' | 'tiff';
  inputBase64: string;
  fileName: string;
}
interface ImageConverterResolvers {
  Query: {
    convertImage: (
      _: unknown,
      variables: ImageConverterResolverVariables,
      context: { client: { defaultOptions: { config: Config } } }
    ) => Promise<string | undefined>;
  };
}

const imageConverterResolvers: ImageConverterResolvers = {
  Query: {
    convertImage: async (_, { outputFormat, inputBase64, fileName }, { client }) => {
      const { config } = client.defaultOptions;
      try {
        const job = await config.cloudConvert.jobs.create({
          tasks: {
            'import-file': {
              operation: 'import/base64',
              filename: fileName,
              file: inputBase64,
            },
            'convert-file': {
              operation: 'convert',
              input: 'import-file',
              output_format: outputFormat,
            },
            'export-file': {
              operation: 'export/url',
              input: 'convert-file',
            },
          },
        });

        const finishedJob = await config.cloudConvert.jobs.wait(job.id);
        const file = config.cloudConvert.jobs.getExportUrls(finishedJob)[0];
        if (!file.url) {
          console.error('Something went wrong');
          return;
        }
        return file.url;
      } catch (error) {
        console.error(error);
        return;
      }
    },
  },
};

export default imageConverterResolvers;
