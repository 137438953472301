import { Box, Checkbox, IconButton, TableCell, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Check2Circle } from '@styled-icons/bootstrap';
import { PartnerPlaceholderImage } from 'assets/img';
import DocumentIntroTitle from 'components/DocumentLibrary/DocumentIntroTitle';
import { StyledTableBody, StyledTableRow } from 'components/TableBase';
import includes from 'lodash/includes';
import moment from 'moment';
import React, { FC, MouseEvent, ReactNode } from 'react';
import Moment from 'react-moment';
import { Booleanish } from 'types/booleanish.types';
import { IDocument } from 'types/document.types';
import { HeaderColumn } from 'types/types';
import { DocumentsTableColumnKeys } from './constants/documentHeaderColumns';

export interface DocumentTableBodyProps {
  documents: IDocument[];
  currentPage: number;
  rowsPerPage: number;
  showPagination: boolean;
  actions?: DocumentTableBodyAction[];
  selectedIds?: string[];
  disableSelection?: boolean;
  displayOwner?: boolean;
  headerColumns: HeaderColumn[];
  'data-cy': string;
  handleClickDocument?: (document: IDocument) => void;
  onSelect: (id: string) => void;
}

export interface DocumentTableBodyAction {
  tooltip: string;
  icon: ReactNode;
  disabledTooltip?: string;
  disableIfDocumentUsed?: boolean;
  onClick: (document: IDocument) => void;
  dataCy?: string;
}

const UsageIcon = styled(Check2Circle)(({ theme }) => ({
  marginRight: theme.spacing(1),
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  padding: theme.spacing(0, 4, 0, 0),
  color: theme.palette.text.secondary,
}));

const ExpiredDate = styled('div')(({ theme }) => ({
  color: theme.palette.error.main,
}));

const StyledImage = styled('div')<{ backgroundImage: string }>(({ backgroundImage }) => ({
  width: '42px',
  height: '42px',
  flexShrink: 0,
  backgroundImage: `url(${backgroundImage})`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  borderRadius: 4,
}));

const DocumentTableBody: FC<DocumentTableBodyProps> = ({
  'data-cy': dataCy = '',
  rowsPerPage,
  currentPage,
  documents,
  selectedIds,
  actions,
  showPagination,
  disableSelection,
  headerColumns,
  onSelect,
  handleClickDocument,
}) => {
  const handleSelectRow = (event: MouseEvent, rowDocument: IDocument) => {
    event.stopPropagation();
    onSelect(rowDocument.id);
  };

  const filteredDocuments = showPagination
    ? documents.slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage)
    : documents;

  return (
    <StyledTableBody data-cy={dataCy}>
      {filteredDocuments.map(doc => {
        const { id, usageCount, issuedDate, expiryDate } = doc;
        const isItemSelected = includes(selectedIds, id);
        const labelId = `enhanced-table-checkbox-${id}`;

        return (
          <StyledTableRow
            hover
            role="checkbox"
            data-cy="document-table-item"
            data-cy-id={id}
            aria-checked={Booleanish(isItemSelected)}
            key={id}
            is-selected={Booleanish(isItemSelected)}
            onClick={(event: MouseEvent) =>
              handleClickDocument ? handleClickDocument(doc) : handleSelectRow(event, doc)
            }
          >
            <TableCell padding="checkbox">
              <Checkbox
                onClick={(event: MouseEvent<HTMLButtonElement>) => handleSelectRow(event, doc)}
                checked={isItemSelected}
                inputProps={{
                  'aria-labelledby': labelId,
                }}
                disabled={disableSelection}
                data-cy="document-table-checkbox"
              />
            </TableCell>
            <>
              {headerColumns.map(({ id: columnId }) => {
                switch (columnId) {
                  case DocumentsTableColumnKeys.TITLE: {
                    return (
                      <TableCell id={labelId} scope="row">
                        <DocumentIntroTitle document={doc} />
                      </TableCell>
                    );
                  }
                  case DocumentsTableColumnKeys.OWNER: {
                    return (
                      <TableCell id={labelId} scope="row">
                        <Box display="flex" alignItems="center">
                          <StyledImage
                            backgroundImage={doc.createdBy?.logo?.url || PartnerPlaceholderImage}
                          />
                          <Box ml={1} />
                          {doc.createdBy.name}
                        </Box>
                      </TableCell>
                    );
                  }
                  case DocumentsTableColumnKeys.USAGE: {
                    return (
                      <TableCell id={labelId} scope="row">
                        <Box display="flex" alignItems="center">
                          <UsageIcon size={16} />
                          {usageCount}
                        </Box>
                      </TableCell>
                    );
                  }
                  case DocumentsTableColumnKeys.ISSUE_DATE: {
                    return (
                      <TableCell id={labelId} scope="row">
                        {issuedDate && <Moment format="LL">{issuedDate}</Moment>}
                      </TableCell>
                    );
                  }
                  case DocumentsTableColumnKeys.EXPIRY_DATE: {
                    return (
                      <TableCell id={labelId} scope="row">
                        {expiryDate &&
                          (moment(expiryDate).isBefore(moment(new Date())) ? (
                            <Tooltip title="The document has expired">
                              <ExpiredDate>
                                <Moment format="LL">{expiryDate}</Moment>
                              </ExpiredDate>
                            </Tooltip>
                          ) : (
                            <Moment format="LL">{expiryDate}</Moment>
                          ))}
                      </TableCell>
                    );
                  }
                  case DocumentsTableColumnKeys.ACTIONS: {
                    return (
                      <TableCell id={labelId} scope="row" width={150}>
                        {actions && (
                          <Box display="flex" alignItems="center" justifyContent="center">
                            {actions.map((action, index) => {
                              const disabled =
                                action.disableIfDocumentUsed && (!doc || !!doc.usageCount);

                              return (
                                <Tooltip
                                  key={index}
                                  title={
                                    disabled && action.disabledTooltip
                                      ? action.disabledTooltip
                                      : action.tooltip
                                  }
                                >
                                  {/* Wrapper to show the tooltip even when disabled */}
                                  <Box component="span">
                                    <StyledIconButton
                                      disabled={disabled}
                                      data-cy={action.dataCy}
                                      onClick={event => {
                                        event.stopPropagation();
                                        action.onClick(doc);
                                      }}
                                    >
                                      {action.icon}
                                    </StyledIconButton>
                                  </Box>
                                </Tooltip>
                              );
                            })}
                          </Box>
                        )}
                      </TableCell>
                    );
                  }
                  default:
                    return null;
                }
              })}
            </>
          </StyledTableRow>
        );
      })}
    </StyledTableBody>
  );
};

export default DocumentTableBody;
