import { FieldFunctionOptions } from '@apollo/client';
import { SafeReadonly } from '@apollo/client/cache/core/types/common';
import startCase from 'lodash/startCase';
import moment from 'moment';

const companyPolicies = {
  CustomerPlan: {
    fields: {
      daysLeftInTrial: (_: SafeReadonly<null>, { readField }: FieldFunctionOptions) => {
        if (!readField('expirationDate')) {
          return null;
        }
        const expirationDate = moment(readField<string | null | undefined>('expirationDate'));
        const diff = expirationDate.diff(moment(), 'days');
        return diff > 0 ? diff : 0;
      },
    },
  },
  CompanyUser: {
    fields: {
      fullName: (_: SafeReadonly<null>, { readField }: FieldFunctionOptions) => {
        return `${readField('firstName')} ${readField('lastName')}`;
      },
      role: (role = '') => {
        if (role === 'SUPER_ADMIN') {
          return 'Admin';
        }

        return startCase(role.toLowerCase());
      },
      isAdmin: (_: SafeReadonly<null>, { readField }: FieldFunctionOptions) => {
        const role = readField<string>('role');
        return role && role.toLowerCase() === 'admin';
      },
      status: (_: SafeReadonly<null>, { readField }: FieldFunctionOptions) => {
        return readField('isRegistered') ? 'REGISTERED' : 'INVITED';
      },
    },
  },
};

export default companyPolicies;
