import { AccordionDetails, AccordionSummary, Box, styled } from '@mui/material';
import { Title } from 'components/Partners/PartnersTable/PartnersTableBody/PartnersTableBody.styles';
import { FlexBox } from 'components/Structure';
import { DatasetBatchRelation, batchDetailSchema } from 'constants/schemas/riskAnalysis.schema';
import { ThemeButton, ThemeTypography } from 'designSystem';
import { Accordion } from 'designSystem/DataDisplay/Accordion/Accordion';
import Icon from 'designSystem/Primitives/Icon/Icon';
import { Field, FieldProps, useField } from 'formik';
import React, { FC, MouseEvent } from 'react';
import { getCountryLabelFromCode } from 'utils/country.utils';
import BatchCardField from './BatchCardField';

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  '& .MuiAccordionSummary-content': {
    justifyContent: 'space-between',
    paddingRight: theme.spacing(2),
  },
}));

const DatasetAccordionField: FC<FieldProps<DatasetBatchRelation>> = ({
  field: { name, value },
}) => {
  const [, , { setValue }] = useField<DatasetBatchRelation>(name);

  const handleAddBatch = (event: MouseEvent | KeyboardEvent) => {
    event.stopPropagation();
    setValue({ ...value, batches: [...value.batches, { ...batchDetailSchema.default() }] });
  };

  const handleDeleteBatch = (batchIndex: number) => {
    setValue({ ...value, batches: [...value.batches.filter((_, index) => index !== batchIndex)] });
  };

  return (
    <Accordion defaultExpanded elevated>
      <StyledAccordionSummary>
        <FlexBox>
          <Icon name="polygon-data" color="gray-100" size="xxx-large" mr={2} />
          <Box display="flex" flexDirection="column">
            <Title>{value.title}</Title>
            <FlexBox>
              <FlexBox mr={1}>
                <Icon name="raw-material" color="gray-80" size="small" mr={0.5} />
                <ThemeTypography variant="BODY_MEDIUM_BOLD" color="GRAY_80">
                  {value.rawMaterial.title}
                </ThemeTypography>
              </FlexBox>
              <FlexBox mr={1}>
                <Icon name="location" color="gray-80" size="small" mr={0.5} />
                <ThemeTypography variant="BODY_MEDIUM_BOLD" color="GRAY_80">
                  {getCountryLabelFromCode(value.originCountry)}
                </ThemeTypography>
              </FlexBox>
              <FlexBox>
                <Icon name="partner" color="gray-80" size="small" mr={0.5} />
                <ThemeTypography variant="BODY_MEDIUM_BOLD" color="GRAY_80">
                  {value.ownedBy.name}
                </ThemeTypography>
              </FlexBox>
            </FlexBox>
          </Box>
        </FlexBox>
        <FlexBox>
          <ThemeButton color="BLUE_ICE" startIcon={<Icon name="plus" />} onClick={handleAddBatch}>
            Add batch
          </ThemeButton>
        </FlexBox>
      </StyledAccordionSummary>
      <AccordionDetails>
        <Box display="flex" gap={1} flexDirection="column" flexWrap="wrap">
          {value.batches.map((_, batchIndex) => (
            <Field
              key={`${name}.batches.[${batchIndex}]`}
              name={`${name}.batches.[${batchIndex}]`}
              enableDelete={value.batches.length > 1}
              component={BatchCardField}
              onBatchDelete={() => handleDeleteBatch(batchIndex)}
            />
          ))}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default DatasetAccordionField;
