import { gql } from '@apollo/client';
import {
  CONTENT_BLOCKS_FRAGMENT,
  VERSION_PROPS_FRAGMENT,
  PRODUCT_LANGUAGE_CONFIG,
} from 'graphql/fragments';

export const UPDATE_VERSION_DEFAULTS = gql`
  mutation UpdateVersionDefaults(
    $id: UUID!
    $lang: Language
    $input: ProductVersionDefaultsInput!
  ) {
    updateVersionDefaults(id: $id, input: $input, lang: $lang) {
      version {
        ...versionProperties
      }
    }
  }
  ${VERSION_PROPS_FRAGMENT}
`;

export const UPDATE_CONTENT_BLOCKS_ORDER = gql`
  mutation UpdateContentBlocksOrder($id: UUID!, $input: UpdateContentBlocksOrderInput!) {
    updateContentBlocksOrder(id: $id, input: $input) {
      contentBlocksMeta {
        id
        productVersionId
        contentType
        order
        enable
      }
    }
  }
`;

export const UPDATE_CONTENT_BLOCK_ENABLED = gql`
  mutation UpdateContentBlockEnabled($enabled: Boolean!, $id: UUID!) {
    updateContentBlockEnabled(enabled: $enabled, id: $id) {
      contentBlockMeta {
        id
        productVersionId
        contentType
        order
        enable
      }
    }
  }
`;

export const ADD_LANGUAGE_AND_TRANSLATE = gql`
  mutation TranslateProduct(
    $id: UUID!
    $translateInput: TranslationParams!
    $languageInput: UpdateProductLanguagesInput!
  ) {
    updateProductLanguageConfig(id: $id, input: $languageInput) {
      product {
        id
        languageConfig {
          ...languageConfigValues
        }
      }
    }
    translateProduct(id: $id, input: $translateInput) {
      version {
        ...versionProperties
        ...contentBlocks
      }
    }
  }
  ${VERSION_PROPS_FRAGMENT}
  ${CONTENT_BLOCKS_FRAGMENT}
  ${PRODUCT_LANGUAGE_CONFIG}
`;
