import * as Yup from 'yup';
import { htmlStringHasText, createMediaSchema, createImpactFactDefault } from './shared';

export default Yup.object().shape({
  headline: Yup.string().label('Headline').required().default(''),
  description: Yup.string().label('Impact description').default('').test(htmlStringHasText),
  image: createMediaSchema('Impact image is missing'),
  impactFacts: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.string(),
        title: Yup.string().label('Title').required().default(''),
        description: Yup.string().label('Description').required().default(''),
        sdgs: Yup.array().of(Yup.number()).min(1, 'Please add at least one item'),
      })
    )
    .default([createImpactFactDefault()])
    .required('Please add at least one item')
    .min(1)
    .max(6),
});
