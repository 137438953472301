import symbolEco from 'assets/img/claims/claim-symbol-eco.svg';
import iconEco from 'assets/img/claims/eco-icon.svg';
import symbolSocial from 'assets/img/claims/claim-symbol-social.svg';
import iconSocial from 'assets/img/claims/social-icon.svg';

export const impactCategoryConfig = {
  ECOLOGICAL: {
    title: 'Environmental Claims',
    titleSingular: 'Environmental Claim',
    titleShort: 'Environmental',
    icon: symbolEco,
    claimIcon: iconEco,
    catalogQueryAlias: 'ecologicalItems',
    color: '#2DB875',
  },
  SOCIAL: {
    title: 'Social Claims',
    titleSingular: 'Social Claim',
    titleShort: 'Social',
    icon: symbolSocial,
    claimIcon: iconSocial,
    catalogQueryAlias: 'socialItems',
    color: '#FFB801',
  },
};
