import { createImageSchema, imageSchema } from 'constants/schemas/shared';
import { MultiPolygon } from 'types/map.types';
import * as Yup from 'yup';

export interface ICultivationAreaValues {
  coordinates: MultiPolygon;
}

export const cultivationAreaSchema = Yup.object()
  .shape({
    coordinates: Yup.array()
      .of(Yup.array().of(Yup.array().of(Yup.number())))
      .default([]),
  })
  .required()
  .default({ coordinates: [] }) as Yup.ObjectSchema<ICultivationAreaValues>;

export const farmSchema = Yup.object().shape({
  rawMaterialId: Yup.string().default('').required('This field is required'),
  size: Yup.number().positive('Must be a positive value').nullable(),
  numberOfFarmers: Yup.number()
    .positive('Must be a positive value')
    .integer('Must be an integer without decimal values')
    .nullable(),
  additionalRawMaterialIds: Yup.array().of(Yup.string()).default([]),
  landOwnership: Yup.string().nullable(),
}) as Yup.ObjectSchema;

export const siteSchema = Yup.object().shape({
  image: createImageSchema({
    message: 'Please add an image of your site',
    required: false,
  }),
  title: Yup.string().default('').required('This field is required'),
  partner: Yup.object()
    .shape({ id: Yup.string(), title: Yup.string(), logo: imageSchema })
    .nullable()
    .default(null),
  siteType: Yup.string().default('').required('This field is required'),
  locationCoordinates: Yup.object()
    .shape({
      lat: Yup.number(),
      lng: Yup.number(),
    })
    .typeError('Please add a location to the partner')
    .required('Location is a required field'),
  locationName: Yup.string().label('Location name').default('').required('This field is required'),
  mapboxId: Yup.string().default('').nullable(),
  externalId: Yup.string().default('').nullable(),
  description: Yup.string().default('').nullable(),
  farmData: Yup.mixed()
    .when('siteType', {
      is: 'FARM',
      then: farmSchema,
      otherwise: Yup.object().shape({}).notRequired(),
    })
    .default(farmSchema.default()),
}) as Yup.ObjectSchema;

export const siteClusterSchema = Yup.object().shape({
  image: createImageSchema({
    message: 'Please add an image of your site',
    required: false,
  }),
  title: Yup.string().default('').required('This field is required'),
  partner: Yup.object()
    .shape({ id: Yup.string(), title: Yup.string(), logo: imageSchema })
    .nullable()
    .default(null),
  locationCoordinates: Yup.object()
    .shape({
      lat: Yup.number(),
      lng: Yup.number(),
    })
    .required('Location is a required field'),
  locationName: Yup.string().label('Location name').default('').required('This field is required'),
}) as Yup.ObjectSchema;
