import { FormHelperText } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Field, FieldArray, getIn } from 'formik';
import isNil from 'lodash/isNil';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import MediaField from './MediaField';

const size = 200;

const Container = styled('div')(({ theme }) => ({
  height: size,
  width: '100%',
  overflowX: 'auto',
  display: 'flex',
  padding: theme.spacing(1),
  borderRadius: 4,
  border: `1px solid ${theme.custom.colors.lightestBorder}`,
  background: '#fff',
}));

const MediaListField = ({
  legacyImage = true,
  maxItems,
  dataCy,
  field: { name, value = [] },
  form: { touched, errors, validateOnMount },
  disabled,
}) => {
  const fieldError = getIn(errors, name);
  const showError = (getIn(touched, name) || validateOnMount) && !!fieldError;
  const hideAdd = !isNil(maxItems) && value?.length >= maxItems;

  // Make sure the media that is selected will not be selected again, duplicate id's will mess up the ordering
  const hideMediaIds = value.map(({ videoId, id }) => videoId || id);

  return (
    <Container data-cy={dataCy}>
      <FieldArray
        name={name}
        render={({ remove, move }) => {
          return (
            <Fragment>
              {!hideAdd && (
                <Field
                  key={`${name}-new`}
                  name={`${name}[${value?.length}]`}
                  component={MediaField}
                  hideMediaIds={hideMediaIds}
                  allowedMedia={value?.length === 0 ? ['image'] : ['image', 'video']}
                  legacyImage={legacyImage}
                  disabled={disabled}
                  styles={{
                    width: size,
                    height: '100%',
                    marginLeft: 8,
                    marginRight: 8,
                  }}
                />
              )}
              {value?.map((item, index) => (
                <Field
                  key={`${name}-${item.id || item.videoId}`}
                  name={`${name}[${index}]`}
                  component={MediaField}
                  onRemove={() => remove(index)}
                  hideMediaIds={hideMediaIds}
                  allowedMedia={value.length === 0 ? ['image'] : ['image', 'video']}
                  moveLeft={index !== 0 ? () => move(index, index - 1) : undefined}
                  moveRight={index + 1 !== value?.length ? () => move(index, index + 1) : undefined}
                  legacyImage={legacyImage}
                  disabled={disabled}
                  styles={{
                    width: size,
                    height: '100%',
                    marginLeft: 8,
                    marginRight: 8,
                  }}
                />
              ))}
            </Fragment>
          );
        }}
      />
      {showError && <FormHelperText error>{fieldError}</FormHelperText>}
    </Container>
  );
};

MediaListField.propTypes = {
  legacyImage: PropTypes.bool,
  maxItems: PropTypes.number,
};

export default MediaListField;
