import React from 'react';
import { styled, Theme, useTheme } from '@mui/material/styles';
import { ChevronDown } from '@styled-icons/bootstrap/ChevronDown';
import { ChevronUp } from '@styled-icons/bootstrap/ChevronUp';

const getColor = (theme: Theme) => theme.custom.colors.textLight;

const OpenDetailsText = styled('span')(({ theme }) => ({
  fontSize: 14,
  marginLeft: theme.spacing(2),
  color: getColor(theme),
}));

const Container = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  cursor: 'pointer',
}));

interface Props {
  showMore: boolean;
  setShowMore: React.Dispatch<React.SetStateAction<boolean>>;
  /** @default: "See all" */
  showMoreText?: string;
  /** @default: "See less" */
  showLessText?: string;
  amount?: number;
}

const ShowMoreText: React.FC<Props> = ({
  showMore,
  showMoreText = 'See all',
  showLessText = 'See less',
  setShowMore,
  amount,
}) => {
  const theme = useTheme();
  const arrowColor = getColor(theme);

  if (showMore) {
    return (
      <Container onClick={() => setShowMore(false)}>
        <ChevronUp size={14} color={arrowColor} />
        <OpenDetailsText>{showLessText}</OpenDetailsText>
      </Container>
    );
  }

  return (
    <Container onClick={() => setShowMore(true)}>
      <ChevronDown size={14} color={arrowColor} />
      <OpenDetailsText>
        {showMoreText}
        {amount === undefined ? '' : ` (${amount})`}
      </OpenDetailsText>
    </Container>
  );
};

export default React.memo(ShowMoreText);
