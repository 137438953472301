import { Checkbox, InputAdornment, List, ListItemSecondaryAction, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Dot } from '@styled-icons/bootstrap/Dot';
import { Search } from '@styled-icons/bootstrap/Search';
import { Close } from '@styled-icons/evaicons-solid/Close';
import { useConfig } from 'components/hooks';
import flatten from 'lodash/flatten';
import xor from 'lodash/xor';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';

import {
  SelectorEmptySearchButton,
  SelectorFilterInput,
  SelectorListItem,
  SelectorSearchBox,
} from 'components/SliderForm/';
import { RequestCatalogItemBlock } from 'components/Structure';

const ClaimCategoryCheck = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const ImpactClaimCategoryIcon = styled('img')(() => ({
  width: 18,
}));

const ImpactClaimIcon = styled('img')(({ theme }) => ({
  width: 20,
  marginRight: theme.spacing(1),
}));

const ClaimTitleContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  border: `1px solid ${theme.custom.colors.backgroundLight}`,
  borderRadius: 3,
  display: 'flex',
  gap: theme.spacing(0.5),
  padding: theme.spacing(0.2, 0.5),
}));

const ClaimCheckTitle = styled(Typography)(({ theme }) => ({
  marginRight: theme.spacing(1),
}));

const CheckWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  marginTop: theme.spacing(2),
  justifyContent: 'space-between',
}));

const ClaimSelectorWrapper = styled('div')(() => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  overflow: 'hidden',
}));

const StyledList = styled(List)(() => ({
  overflow: 'auto',
}));

const ClaimSelector = ({ selectedItem, handleSelect, data }) => {
  const { claimCategoriesArray } = useConfig();
  const [searchText, setSearchText] = useState('');
  const [filteredCategories, setFilteredCategories] = useState([]);

  // Gets all the claim category items and filters them based on the searchQuery and filtered categories.
  const claimCategoryItems = useMemo(() => {
    // Takes all the nodes / edges from the data and flatmap it into a single array.
    return (
      flatten(
        claimCategoriesArray.map(({ catalogQueryAlias }) => {
          const items = data[catalogQueryAlias]?.edges;

          return items.map(({ node }) => {
            return node;
          });
        })
      )
        // Filter any categories that are selected to be filtered out.
        .filter(item => !filteredCategories.includes(item.categoryConfig.title))
        // Filter by title, case insensitive.
        .filter(item => item.title.toLowerCase().includes(searchText.toLowerCase()))
    );
  }, [claimCategoriesArray, data, searchText, filteredCategories]);

  return (
    <ClaimSelectorWrapper data-cy="claim-selector">
      <SelectorSearchBox>
        <SelectorFilterInput
          value={searchText}
          onChange={event => {
            setSearchText(event.target.value);
          }}
          variant="outlined"
          placeholder="Search"
          size="small"
          fullWidth
          data-cy="claim-list-search-box"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {searchText === '' ? (
                  <Search size={12} />
                ) : (
                  <SelectorEmptySearchButton>
                    <Close
                      size={16}
                      onClick={() => {
                        setSearchText('');
                      }}
                    />
                  </SelectorEmptySearchButton>
                )}
              </InputAdornment>
            ),
          }}
        />

        <CheckWrapper>
          {claimCategoriesArray.map(claimCategory => {
            return (
              <ClaimCategoryCheck key={`check-${claimCategory.title}`}>
                <Checkbox
                  checked={!filteredCategories.includes(claimCategory.title)}
                  color="secondary"
                  size="small"
                  style={{
                    padding: 0,
                    marginRight: 2,
                  }}
                  data-cy="claim-checkbox"
                  onChange={() => {
                    setFilteredCategories(xor(filteredCategories, [claimCategory.title]));
                  }}
                />

                <ClaimTitleContainer>
                  <ImpactClaimCategoryIcon
                    src={claimCategory.claimIcon}
                    alt="claim category icon"
                  />
                  <ClaimCheckTitle variant="caption">{claimCategory.titleShort}</ClaimCheckTitle>
                </ClaimTitleContainer>
              </ClaimCategoryCheck>
            );
          })}
        </CheckWrapper>
      </SelectorSearchBox>

      <StyledList data-cy="claim-list" spacing="small">
        {claimCategoryItems.map(claimCategoryItem => {
          const isSelected = selectedItem?.id === claimCategoryItem.id;
          return (
            <SelectorListItem
              key={claimCategoryItem.title}
              onClick={() => {
                handleSelect(claimCategoryItem);
              }}
              selected={isSelected}
              data-cy="claim-list-item"
              data-cy-active={isSelected}
            >
              {claimCategoryItem.iconUrl && (
                <ImpactClaimIcon src={claimCategoryItem.iconUrl} alt="claim icon" />
              )}
              {claimCategoryItem.title}
              <ListItemSecondaryAction
                style={{
                  right: 5,
                }}
              >
                <Dot size={30} color={claimCategoryItem.categoryConfig.color} />
              </ListItemSecondaryAction>
            </SelectorListItem>
          );
        })}
        <RequestCatalogItemBlock variant="CLAIM" />
      </StyledList>
    </ClaimSelectorWrapper>
  );
};

ClaimSelector.propTypes = {
  data: PropTypes.object.isRequired,
  selectedItem: PropTypes.object,
  handleSelect: PropTypes.func.isRequired,
};

export default ClaimSelector;
