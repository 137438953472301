import { Box, IconButton, TableCell } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ChevronDown } from '@styled-icons/bootstrap/ChevronDown';
import { ChevronUp } from '@styled-icons/bootstrap/ChevronUp';
import { CertificateIcon } from 'assets/img/menu';
import { ClaimTitleAndStatus } from 'components/ImpactClaims/ClaimShared';
import PropTypes from 'prop-types';
import React, { Fragment, useRef, useState } from 'react';
import Moment from 'react-moment';
import {
  calculateAttachmentCountOfGroup,
  getCreatedTimestampOfGroup,
  isSafariBrowser,
} from 'utils';
import InnerTableRow from './InnerTableRow';
import { Date, StyledCell, TableRow } from './styles';

const ChevronIconButton = styled(IconButton)(({ theme }) => ({
  marginRight: theme.spacing(2),
}));

// Creates a row with 0 height, but has the global table border-spacing applied so it acts as an additional spaceing item.
// Used to have enough spacing after the innerTableRows in the collapsed group within the shadow container.
const SpacingRow = styled('tr')(() => ({
  height: 0,
}));

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ShadowContainer = styled(({ height, open, display, ...props }) => <div {...props} />)(
  ({ theme, height, open, display }) => ({
    display: display ? 'box' : 'none',
    position: 'absolute',
    background: '#fff',
    top: 0,
    left: 8,
    right: 8,
    bottom: open ? -height : -8,
    zIndex: -1,
    borderRadius: 6,
    boxShadow: theme.custom.shadows[1],
  })
);

const ImpactGroupTableRow = ({ impactGroup, actions, onSelect }) => {
  const [open, setOpen] = useState(false);
  const { id, impactClaims } = impactGroup;
  const labelId = `impact-claim-table-${id}`;
  const createdTimestamp = getCreatedTimestampOfGroup({
    impactClaims,
  });
  const attachmentCountOfGroup = calculateAttachmentCountOfGroup({
    impactClaims,
  });
  const isSafari = isSafariBrowser();
  // use table cell to calculate the height of shadow container when opening impact claim group
  const firstTableCell = useRef();

  return (
    <Fragment>
      <TableRow
        data-cy="impact-claim-table-item"
        data-cy-id={id}
        hover
        key={id}
        onClick={event => {
          event.stopPropagation();
          setOpen(!open);
        }}
      >
        <StyledCell ref={firstTableCell} id={labelId} scope="row" width={450}>
          <ClaimTitleAndStatus
            impactCatalogItem={impactGroup}
            showSeeComments
            claimStatus="GROUP"
            transparent={false}
          />
        </StyledCell>
        <TableCell width={300} id={labelId} scope="row"></TableCell>
        <TableCell width={200} id={labelId} scope="row">
          <Box display="flex">
            <CertificateIcon />
            <Box pl={1}>
              {attachmentCountOfGroup} proof document
              {attachmentCountOfGroup !== 1 ? 's' : ''}
            </Box>
          </Box>
        </TableCell>
        <TableCell id={labelId} scope="row">
          <Date variant="subtitle2">
            <Moment format="LL">{createdTimestamp}</Moment>
          </Date>
        </TableCell>
        <TableCell width={50} id={labelId} scope="row" align="right">
          <ChevronIconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <ChevronUp size={18} /> : <ChevronDown size={18} />}
          </ChevronIconButton>
          <ShadowContainer
            display={!isSafari}
            open={open}
            height={impactClaims.length * (firstTableCell.current?.clientHeight + 12)}
          />
        </TableCell>
      </TableRow>
      {impactClaims.map((impactClaim, index) => {
        return (
          <InnerTableRow
            key={index}
            impactClaim={impactClaim}
            actions={actions}
            onSelect={onSelect}
            open={open}
          />
        );
      })}
      <SpacingRow />
    </Fragment>
  );
};

ImpactGroupTableRow.propTypes = {
  impactGroup: PropTypes.object.isRequired,
  actions: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default ImpactGroupTableRow;
