import { Box, styled } from '@mui/material';
import { FieldProps } from 'formik';
import { TextField as FormikTextField } from 'formik-mui';
import React, { FC, useCallback, useState } from 'react';
import { Booleanish, booleanish } from 'types/booleanish.types';
import { AvailableLanguagesType } from 'types/enums';
import { LANGUAGES } from 'utils/language.utils';

const Flag = styled('img')<{ visible: booleanish }>(({ visible }) => ({
  position: 'absolute',
  right: 0,
  top: -22,
  borderRadius: 2,
  width: 20,
  opacity: visible === 'true' ? 1 : 0,
  transform: visible === 'true' ? 'translateY(0)' : 'translateY(22px)',
  transition: 'all 0.06s ease-out',
}));

const TextField: FC<FieldProps<string>> = props => {
  const [focused, setFocused] = useState<boolean>(false);

  const lang: AvailableLanguagesType = props.form.status?.lang;

  const handleFocus = useCallback(() => setFocused(true), [setFocused]);
  const handleBlur = useCallback(() => setFocused(false), [setFocused]);

  return (
    <Box position="relative" width="100%">
      <FormikTextField
        variant="outlined"
        {...props}
        // @ts-ignore
        inputProps={{ onBlur: handleBlur, onFocus: handleFocus, ...props.inputProps }}
      />
      {lang && <Flag visible={Booleanish(focused)} src={LANGUAGES[lang].flag} />}
    </Box>
  );
};

export default TextField;
