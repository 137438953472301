import React, { useState } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { LoadingCard } from 'components/Forms';
import { Select, MenuItem, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import RecentActivityLegend from './RecentActivityLegend';
import RecentActivityLineChart from './RecentActivityLineChart';
import { InfoTooltip } from 'designSystem';

const StyledSelect = styled(Select)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  boxShadow: theme.shadows[1],

  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
}));

const defaultTooltipText = `
  This graph shows the recent activity on your product over the
  selected time frame. You can choose to display all visits,
  only QR code scans or direct visits (e.g. through link).
`;

const RecentActivityCard = ({
  loading,
  data,
  showSelect,
  tooltipText = defaultTooltipText,
  filled,
  label,
}) => {
  const [selectedData, setSelectedData] = useState(0);
  const hasData = isEmpty(data);

  const selectConfig = [
    {
      value: 0,
      label: 'All visitor origins',
      data: [data.total, data.totalUnique],
    },
    {
      value: 1,
      label: 'Direct only',
      data: [data.direct, data.directUnique],
    },
    {
      value: 2,
      label: 'QR only',
      data: [data.qr, data.qrUnique],
    },
  ];

  return (
    <LoadingCard
      loading={loading}
      label={label}
      LabelAdornment={label ? <InfoTooltip text={tooltipText} /> : null}
      fullHeight={false}
    >
      {!hasData && (
        <RecentActivityLineChart
          interval={data.timeInterval}
          data={selectConfig[selectedData].data}
          showSelect={showSelect}
          renderHeader={
            <Box display="flex" justifyContent="space-between" mb={1}>
              <RecentActivityLegend />
              {showSelect ? (
                <StyledSelect
                  value={selectedData}
                  variant="outlined"
                  onChange={event => setSelectedData(event.target.value)}
                >
                  {selectConfig.map(({ value, label }, index) => {
                    return (
                      <MenuItem key={`${index}-data-select-item`} value={value}>
                        {label}
                      </MenuItem>
                    );
                  })}
                </StyledSelect>
              ) : null}
            </Box>
          }
          filled={filled}
        />
      )}
    </LoadingCard>
  );
};

RecentActivityCard.propTypes = {
  loading: PropTypes.bool,
  showSelect: PropTypes.bool,
  data: PropTypes.object.isRequired,
  filled: PropTypes.bool,
  label: PropTypes.string,
};

export default RecentActivityCard;
