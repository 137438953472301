import { Button, styled } from '@mui/material';
import { ThemeTypography } from 'designSystem';

export const PageContainer = styled('div')(() => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'row',
  height: 'calc(100% - 72px)',
  overflow: 'hidden',
}));

export const TextContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  padding: theme.spacing(11, 5, 0, 5),
  width: '45%',
}));

export const ImageContainer = styled('div')<{ alignItems?: string }>(
  ({ theme, alignItems = 'center' }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: alignItems,
    justifyContent: 'center',
    height: '100%',
    width: '55%',
    background: theme.custom.colors.backgroundLight,
  })
);

export const Headline = styled(ThemeTypography)(({ theme }) => ({
  textAlign: 'initial',
  marginBottom: theme.spacing(2),
  lineHeight: 1.1,
}));

export const Pulse = styled('img')(({ theme }) => ({
  maxWidth: 90,
}));

export const SkipButton = styled(Button)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(3),
  right: theme.spacing(3),
  background: '#fff',
  color: theme.palette.secondary.main,
  fontSize: 12,
  minWidth: 120,
}));

export const ItemIcon = styled('img')<{ width: number }>(({ width = 40 }) => ({
  maxWidth: width,
}));

export const ItemText = styled('p')(({ theme }) => ({
  fontSize: 11,
  fontWeight: 700,
  color: theme.palette.secondary.main,
  margin: 0,
}));

export const ItemIconContainer = styled('div')(() => ({
  width: 45,
}));

export const Item = styled('div')(({ theme }) => ({
  display: 'flex',
  background: '#fff',
  alignItems: 'center',
  padding: theme.spacing(2),
  justifySelf: 'center',
  width: 170,
  borderRadius: '12px',
  boxShadow: theme.custom.shadows[3],
  marginBottom: theme.spacing(2),
}));

export const ContentContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  padding: theme.spacing(5, 5, 0, 5),
  width: '45%',
}));
