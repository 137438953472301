import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field, getIn } from 'formik';
import { DialogActions, Grid, InputAdornment } from '@mui/material';
import { styled } from '@mui/material/styles';
import { SlugField } from 'components/Forms';
import * as Yup from 'yup';
import { TextField } from 'formik-mui';
import { useConfig } from 'components/hooks';
import { Check } from '@styled-icons/entypo/Check';
import { FieldWrapper, ThemeButton } from 'designSystem';

const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  padding: theme.spacing(4, 0, 0, 0),
}));

const StyledAdornment = styled(InputAdornment)(({ theme }) => ({
  marginRight: 0,
  color: theme.palette.grey[600],
}));

const StyledCheck = styled(Check)(({ theme }) => ({
  color: theme.palette.success.main,
}));

const StyledTextField = styled(TextField)(() => ({
  '& input': {
    paddingLeft: '0 !important',
  },
}));

const EditSlugForm = ({ onSubmit, slug, onCancel }) => {
  const { productURL } = useConfig();

  const DefaultUpdateSlugSchema = Yup.object().shape({
    slug: Yup.string()
      .required('This field is required')
      .matches(/^[a-zA-Z0-9-]+$/, 'Cannot contain special characters or spaces'),
    oldSlug: Yup.string()
      .required('This field is required')
      .oneOf([slug], 'The input does not match the old product URL'),
  });

  return (
    <Formik
      initialValues={{
        slug: '',
        oldSlug: '',
      }}
      validationSchema={DefaultUpdateSlugSchema}
      onSubmit={values => onSubmit(values)}
      initialStatus={{
        slugValidationDone: false,
      }}
      validateOnBlur
    >
      {({ status, isValid, errors, values, isSubmitting }) => {
        const oldSlugValidationError = getIn(errors, 'oldSlug');
        const oldSlugValue = getIn(values, 'oldSlug');

        return (
          <Form>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FieldWrapper
                  label="New product URL"
                  subtitle="Enter the new path under which your page should be found. You can use <b>letters</b>, <b>numbers</b> and <b>hyphens</b>"
                  data-cy="new-product-slug-container"
                >
                  <SlugField
                    name="slug"
                    variant="outlined"
                    disableAutocomplete
                    fullWidth
                    data-cy="slug-field"
                  />
                </FieldWrapper>
              </Grid>
              <Grid item xs={12}>
                <FieldWrapper
                  label="Old product URL"
                  subtitle={`Confirm that you want to update the URL by typing in the old path: <b>${slug}</b>`}
                  data-cy="old-product-slug-container"
                >
                  <Field
                    component={StyledTextField}
                    variant="outlined"
                    fullWidth
                    name="oldSlug"
                    data-cy="slug-field"
                    InputProps={{
                      startAdornment: (
                        <StyledAdornment position="start">{productURL}/</StyledAdornment>
                      ),
                      endAdornment: (
                        <StyledAdornment position="end">
                          {!oldSlugValidationError && !!oldSlugValue && <StyledCheck size={14} />}
                        </StyledAdornment>
                      ),
                    }}
                  />
                </FieldWrapper>
              </Grid>
            </Grid>
            <StyledDialogActions>
              <ThemeButton color="WHITE" size="large" onClick={onCancel}>
                Cancel
              </ThemeButton>
              <ThemeButton
                loading={isSubmitting}
                color="YELLOW"
                type="submit"
                disabled={!status.slugValidationDone || !isValid}
                data-cy="submit-slug-change"
                size="large"
              >
                Save new URL
              </ThemeButton>
            </StyledDialogActions>
          </Form>
        );
      }}
    </Formik>
  );
};

EditSlugForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  slug: PropTypes.string.isRequired,
};

export default EditSlugForm;
