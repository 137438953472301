import useFeatureFlags, { FeatureFlag } from './useFeatureFlags';
import useUserData from './useUserData';

const RULES = {
  Admin: [
    'userSettings:access',
    'companySettings:access',
    'designSettings:access',
    'keys:access',
    'googleAnalytics:access',
  ],
};

/**
 * Check if the user is authorized to access a feature by featureFlag or rule
 * @param queryBy An object with either a featureFlag or a rule
 * @returns an object with isAdmin and an optional authorized flag as boolean
 */
const useAuthorization = (queryBy?: { featureFlag: FeatureFlag } | { rule: string }) => {
  const { user } = useUserData();
  const { isFeatureEnabled } = useFeatureFlags();

  if (queryBy) {
    const featureFlag = 'featureFlag' in queryBy && queryBy.featureFlag;
    const rule = 'rule' in queryBy && queryBy.rule;

    return {
      isAdmin: !!user?.isAdmin,
      authorized:
        (rule && user?.role && user.role in RULES && Object(RULES)[user.role].includes(rule)) ||
        (featureFlag && isFeatureEnabled(featureFlag)),
    };
  }
  return {
    isAdmin: !!user?.isAdmin,
  };
};

export default useAuthorization;
