import React, { FC } from 'react';
import { SeparatorLine } from 'components/Structure';
import { Box, DialogContent, styled, useTheme } from '@mui/material';
import ThemeTypography from 'designSystem/Primitives/Typography/ThemeTypography';
import emailSendIcon from 'assets/img/icons/email-send.svg';
import ThemeButton from 'designSystem/Buttons/ThemeButton/ThemeButton';
import { useDialog } from 'components/hooks';
import { DialogDefault } from 'designSystem';
import { IDefaultDialogProps } from 'types/dialog.types';

interface IPartnerSelectDecisionDialogProps extends IDefaultDialogProps {
  connectToCompanyId?: string;
}

const ButtonIcon = styled('img')(() => ({
  width: 18,
  pointerEvents: 'none',
}));

const ContentWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
}));

const PartnerSelectDecisionDialog: FC<IPartnerSelectDecisionDialogProps> = ({
  onClose,
  connectToCompanyId,
  open = true,
}) => {
  const theme = useTheme();
  const { openDialog } = useDialog();

  const handleCreatePartnerManually = () => {
    openDialog({
      type: 'ADD_EDIT_PARTNER',
      props: { connectToCompanyId, skipInvitationEmail: true },
    });
    onClose?.();
  };

  const handleInvitePartner = () => {
    openDialog({ type: 'ADD_EDIT_PARTNER', props: { connectToCompanyId, enableInvite: true } });
    onClose?.();
  };

  return (
    <DialogDefault
      title="Add partner"
      onClose={onClose}
      open={open}
      fullWidth
      maxWidth="xs"
      background="#fff"
      data-cy="partner-form-dialog"
    >
      <DialogContent>
        <ContentWrapper>
          <ThemeButton
            color="YELLOW"
            fullWidth
            startIcon={<ButtonIcon src={emailSendIcon} />}
            size="large"
            onClick={handleInvitePartner}
          >
            Invite partner
          </ThemeButton>

          <Box mt={1}>
            <ThemeTypography variant="BODY_SMALL" color="GRAY">
              Allows the partner to add their own information, and for you to easily request
              information from them.
            </ThemeTypography>
          </Box>

          <SeparatorLine label="or" width="80%" backgroundColor={theme.custom.colors.offWhite} />

          <ThemeButton
            color="BLUE_ICE"
            fullWidth
            size="large"
            data-cy="add-partner-manually-btn"
            onClick={handleCreatePartnerManually}
          >
            Add partner manually
          </ThemeButton>

          <Box mt={1}>
            <ThemeTypography variant="BODY_SMALL" color="GRAY">
              You can still invite your partner later on.
            </ThemeTypography>
          </Box>
        </ContentWrapper>
      </DialogContent>
    </DialogDefault>
  );
};

export default PartnerSelectDecisionDialog;
