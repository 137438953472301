import { gql } from '@apollo/client';
import {
  ANALYTICS_ACTIVITY_FRAGMENT,
  AVG_TIME_ON_PAGE_FRAGMENT,
  GEO_DATA_FRAGMENT,
  VIEWS_FRAGMENT,
  TIME_PER_SECTION_FRAGMENT,
  CLICK_COUNTS_FRAGMENT,
  AVG_JOURNEY_STEP_FRAGMENT,
  SUMMARY_FRAGMENT,
  TIME_PER_IMPACT_CLAIM,
} from 'graphql/fragments';

export const GET_PRODUCT_ANALYTICS = gql`
  query GetProductAnalytics($params: GetAnalyticsParams!, $id: UUID!) {
    productAnalyticsExist(id: $id)
    geoAnalytics(params: $params) {
      ...geoValues
    }
    pageActivity(params: $params) {
      ...analyticsActivityValues
    }
    productAnalyticsSummary(params: $params) {
      ...summaryValues
    }
    topCities(params: $params) {
      value
      city
    }
    deviceInfo(params: $params) {
      ios
      android
      desktop
      others
    }
  }
  ${GEO_DATA_FRAGMENT}
  ${ANALYTICS_ACTIVITY_FRAGMENT}
  ${SUMMARY_FRAGMENT}
`;

export const GET_PRODUCT_ANALYTICS_OVERVIEW = gql`
  query GetProductAnalyticsOverview($params: GetAnalyticsParams!, $id: UUID!) {
    productAnalyticsExist(id: $id)
    pageActivity(params: $params) {
      ...analyticsActivityValues
    }
    productAnalyticsSummary(params: $params) {
      views {
        ...viewsValues
      }
    }
  }
  ${ANALYTICS_ACTIVITY_FRAGMENT}
  ${VIEWS_FRAGMENT}
`;

export const GET_COMPANY_ANALYTICS_SUMMARY = gql`
  query GetCompanyAnalyticsSummary($params: GetCompanyAnalyticsParams!) {
    companyAnalyticsExist
    companyAnalyticsSummary(params: $params) {
      views {
        ...viewsValues
      }
      qrViews {
        ...viewsValues
      }
      uniqueViews {
        ...viewsValues
      }
      averageTimeSpent {
        ...viewsValues
      }
    }
    companyProductsActivity(params: $params) {
      ...analyticsActivityValues
    }
  }
  ${VIEWS_FRAGMENT}
  ${ANALYTICS_ACTIVITY_FRAGMENT}
`;

export const USER_BEHAVIOR_ANALYTICS = gql`
  query UserBehaviorAnalytics($params: GetAnalyticsParams!, $id: UUID!) {
    productAnalyticsExist(id: $id)
    timePerSection(params: $params) {
      ...timePerSectionValues
    }
    avgTimeSpent(params: $params) {
      ...avgTimeOnPageValues
    }
    avgJourneyStep(params: $params) {
      ...avgJourneyStepValues
    }
    clickCounts(params: $params) {
      ...clickCountsValues
    }
    timePerImpactClaim(params: $params) {
      ...timePerImpactClaimValues
    }
  }
  ${TIME_PER_SECTION_FRAGMENT}
  ${AVG_TIME_ON_PAGE_FRAGMENT}
  ${AVG_JOURNEY_STEP_FRAGMENT}
  ${CLICK_COUNTS_FRAGMENT}
  ${TIME_PER_IMPACT_CLAIM}
`;

export const EMBEDDED_ANALYTICS = gql`
  query EmbeddedAnalytics($params: GetAnalyticsParams!, $id: UUID!) {
    productAnalyticsExist(id: $id, embedded: true)
    pageActivity(params: $params) {
      ...analyticsActivityValues
    }
    productAnalyticsSummary(params: $params) {
      ...summaryValues
    }
    avgTimeSpent(params: $params) {
      ...avgTimeOnPageValues
    }
    avgJourneyStep(params: $params) {
      ...avgJourneyStepValues
    }
  }
  ${ANALYTICS_ACTIVITY_FRAGMENT}
  ${SUMMARY_FRAGMENT}
  ${ANALYTICS_ACTIVITY_FRAGMENT}
  ${VIEWS_FRAGMENT}
  ${AVG_TIME_ON_PAGE_FRAGMENT}
  ${AVG_JOURNEY_STEP_FRAGMENT}
`;
