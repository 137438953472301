import React from 'react';
import PropTypes from 'prop-types';
import { LoadingCard } from 'components/Forms';
import { LineGraph } from 'components/Product/Analytics/LineGraph';
import TimeSpentOnPageTooltip from './TimeSpentOnPageTooltip';
import { useTheme } from '@mui/material/styles';
import { millisecondsToMinutes } from 'components/Product/Analytics/utils';
import { InfoTooltip } from 'designSystem';

const defaultTooltipText = `
  This graph shows the average time your 
  visitors spent per day on this product. 
  Hover over the graph to see details and more information.
`;

const defaultLabel = 'Average time spent on page';

const TimeSpentOnPageCard = ({
  loading,
  data,
  tooltipText = defaultTooltipText,
  label = defaultLabel,
}) => {
  const theme = useTheme();
  const colors = [theme.palette.primary.main];

  return (
    <LoadingCard
      loading={loading}
      label={label}
      LabelAdornment={<InfoTooltip text={tooltipText} />}
    >
      <LineGraph
        height={400}
        interval={data.timeInterval}
        data={data}
        graphColors={colors}
        tickFormatY={millisecondsToMinutes}
        graphTooltip={tooltipState => (
          <TimeSpentOnPageTooltip timeInterval={data.timeInterval} tooltipState={tooltipState} />
        )}
        graphNumTicks={4}
      />
    </LoadingCard>
  );
};

TimeSpentOnPageCard.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.array.isRequired,
  tooltipText: PropTypes.string,
};

export default TimeSpentOnPageCard;
