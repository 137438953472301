import { Box, styled } from '@mui/material';
import { ThemeTypography } from 'designSystem';
import Icon from 'designSystem/Primitives/Icon/Icon';
import React, { FC } from 'react';
import { Booleanish, booleanish } from 'types/booleanish.types';

interface IMitigationCheckboxProps {
  selected: boolean;
  label: string;
  onClick: () => void;
}

const Answer = styled(Box)<{ selected: booleanish }>(({ theme, selected }) => ({
  cursor: 'pointer',
  padding: theme.spacing(3),
  borderRadius: theme.spacing(1),
  border: `1px solid ${
    selected === 'true'
      ? theme.custom.themeColors.success[60]
      : theme.custom.colors.lightBorderColor
  }`,
  marginBottom: theme.spacing(1),
  backgroundColor: selected === 'true' ? theme.custom.themeColors.success[20] : 'white',
  pointerEvents: 'auto',
}));

const MitigationCheckbox: FC<IMitigationCheckboxProps> = ({ label, selected, onClick }) => {
  return (
    <Answer
      display="flex"
      alignItems="center"
      gap={2}
      selected={Booleanish(selected)}
      onClick={onClick}
    >
      {selected ? <Icon name="check-filled" color="green" /> : <Icon name="unselected" />}
      <ThemeTypography variant="BODY_MEDIUM" color={selected ? 'GREEN' : 'BLACK'}>
        {label}
      </ThemeTypography>
      {/* <FormControlLabel checked={selected} label={label} control={<Radio />} onChange={onClick} /> */}
    </Answer>
  );
};

export default MitigationCheckbox;
