import { useEffect } from 'react';

const useCutHandler = (onCut: () => void) => {
  useEffect(() => {
    const handleCut = () => {
      onCut();
    };

    document.addEventListener('cut', handleCut);
    return () => {
      document.removeEventListener('cut', handleCut);
    };
  }, [onCut]);
};

export default useCutHandler;
