import { Box, DialogActions, Grid, styled } from '@mui/material';
import { LanguageSelectorItem, SlugField } from 'components/Forms';
import { useConfig } from 'components/hooks';
import { createProductSchema } from 'constants/schemas/product';
import { FieldWrapper, ThemeButton } from 'designSystem';
import { FastField as Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import isEqual from 'lodash/isEqual';
import React, { FC, useMemo } from 'react';
import { LanguageConfig, LanguageConfigOption, Product } from 'types/types';
import CreateFormGeneralSection from './CreateFormGeneralSection';

const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  padding: theme.spacing(4, 0, 0, 0),
}));

const StyledTextField = styled(TextField)(() => ({
  '&.MuiTextField-root': {
    '& .MuiInputBase-input': {
      padding: '10px 12px',
    },
  },
}));

export interface ProductFormValues {
  title: string;
  slug: string;
  articleNumber: string;
  languageConfig: LanguageConfig;
}

export type ProductFormType =
  | {
      action: 'CREATE';
      product?: undefined;
    }
  | {
      action: 'DUPLICATE' | 'EDIT';
      product: Pick<Product, 'id' | 'title' | 'slug' | 'articleNumber' | 'languageConfig'>;
    };

interface IProductFormProps {
  submitButtonText?: string;
  onSubmit: (values: ProductFormValues) => void;
}

const ProductForm: FC<IProductFormProps & ProductFormType> = ({
  action,
  product,
  submitButtonText,
  onSubmit,
}) => {
  const config = useConfig();
  const productLanguages: LanguageConfigOption[] = config.productLanguages;

  const filteredProductLanguages = useMemo(() => {
    // You can only change the default and not add a new one here
    if (action === 'EDIT') {
      return productLanguages.filter(({ key }) => product.languageConfig.enabled.includes(key));
    }
    return productLanguages;
  }, [productLanguages, product, action]);

  /**
   * The values are added from the default schema, but types can not be inferred from the schema.
   */
  // @ts-ignore
  const initialValues: ProductFormValues = useMemo(() => {
    switch (action) {
      case 'CREATE':
        return createProductSchema.default();
      case 'DUPLICATE':
        return {
          ...createProductSchema.default(),
          title: `${product.title}-Copy`,
          slug: `${product.slug}-copy`,
        };
      case 'EDIT':
        return {
          ...createProductSchema.default(),
          title: product.title,
          slug: product.slug,
          articleNumber: product.articleNumber,
          languageConfig: product.languageConfig,
        };
      default:
        return createProductSchema.default();
    }
  }, [action, product]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={createProductSchema}
      onSubmit={values => onSubmit(values)}
      initialStatus={{
        slugValidationDone: action === 'EDIT',
        slugAvailable: true,
      }}
      validateOnChange
    >
      {({ status, isSubmitting, values, isValid }) => (
        <Form data-cy="create-product-form">
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <CreateFormGeneralSection />
            </Grid>
            {action !== 'DUPLICATE' && (
              <Grid item xs={12}>
                <FieldWrapper
                  label="Default language"
                  subtitle="Set the default language for your product. You can add more content languages later."
                >
                  <Box maxWidth={400}>
                    <Field
                      variant="outlined"
                      fullWidth
                      component={StyledTextField}
                      name="languageConfig.default"
                      type="text"
                      select
                      data-cy="product-default-language-selector"
                      SelectProps={{
                        renderValue: (value: string) => {
                          const { flag, title } = config.getLanguageConfig(value);

                          return (
                            <LanguageSelectorItem
                              value={value}
                              flag={flag}
                              title={title}
                              noPadding
                              hoverBackground={undefined}
                            />
                          );
                        },
                      }}
                    >
                      {filteredProductLanguages.map(
                        ({ title, key, flag }: { title: string; key: string; flag: string }) => (
                          <LanguageSelectorItem
                            key={`product-lang-${key}`}
                            value={key}
                            flag={flag}
                            title={title}
                            hoverBackground={undefined}
                            noPadding={undefined}
                          />
                        )
                      )}
                    </Field>
                  </Box>
                </FieldWrapper>
              </Grid>
            )}
            {action !== 'EDIT' && (
              <Grid item xs={12}>
                <FieldWrapper
                  label="Product URL"
                  subtitle="This is the path under which your product page will be found on the seedtrace platform. You edit using <b>letters</b>, <b>numbers</b> and <b>hyphens (-)</b>."
                >
                  <SlugField
                    allowValue={product?.slug}
                    disableAutocomplete={action !== 'CREATE'}
                    name="slug"
                    dependency="title"
                    variant="outlined"
                    fullWidth
                    data-cy="product-slug-input"
                  />
                </FieldWrapper>
              </Grid>
            )}
          </Grid>
          <StyledDialogActions>
            <ThemeButton
              loading={isSubmitting}
              color="YELLOW"
              size="large"
              className="button-wide"
              type="submit"
              disabled={
                (action !== 'DUPLICATE' && isEqual(initialValues, values)) ||
                (action === 'EDIT'
                  ? !isValid
                  : !status.slugValidationDone || !status.slugAvailable || !isValid)
              }
              data-cy="create-product-button"
            >
              {submitButtonText}
            </ThemeButton>
          </StyledDialogActions>
        </Form>
      )}
    </Formik>
  );
};

export default ProductForm;
