import { Grid } from '@mui/material';
import { TextInputField } from 'components/Forms';
import ThemeButton from 'designSystem/Buttons/ThemeButton/ThemeButton';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import * as Yup from 'yup';

const CreateSchema = Yup.object().shape({
  firstName: Yup.string().required('This field is required'),
  lastName: Yup.string().required('This field is required'),
  email: Yup.string().required('This field is required'),
});

const UserEditForm = ({ onSubmit, user }) => {
  const { firstName, lastName, email } = user;

  return (
    <Formik
      initialValues={{
        firstName,
        lastName,
        email,
      }}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={CreateSchema}
      onSubmit={values => onSubmit(values)}
    >
      {({ isSubmitting }) => (
        <Form>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TextInputField label="First name" name="firstName" />
            </Grid>
            <Grid item xs={6}>
              <TextInputField label="Last name" name="lastName" />
            </Grid>
            <Grid item xs={6}>
              <TextInputField
                disabled
                label="Email"
                subtitle="We will send notifications and updates to this email. Contact our support to change it."
                name="email"
              />
            </Grid>
            <Grid container item spacing={2} justifyContent="flex-end" alignItems="center">
              <Grid item>
                <ThemeButton size="large" color="YELLOW" loading={isSubmitting} type="submit">
                  Save
                </ThemeButton>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

UserEditForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default UserEditForm;
