import { useQuery } from '@apollo/client';
import { useDialog, useFeatureFlags } from 'components/hooks';
import { FeatureFlag } from 'components/hooks/useFeatureFlags';
import useSiteMutations from 'components/Sites/hooks/useSiteMutations';
import { PREVIEW_LATEST_ITEMS } from 'constants/pagination';
import { useCompanyPlanContext } from 'contexts/CompanyPlanContext';
import { ILogItem } from 'designSystem/DataDisplay/LogItem/LogItem';
import { GET_PARTNER_OVERVIEW } from 'graphql/queries/partner.queries';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IActivity } from 'types/activity.types';
import { CompanyStatus, CustomerPlan } from 'types/company.types';
import { IComponentItem } from 'types/component.types';
import { IBasePartnerCompany, IPartnerCompany } from 'types/partner.types';
import { RouteParamsWithId } from 'types/router.types';
import { ISite, SiteType } from 'types/site.types';
import { removeGraphConnections } from 'utils/graphql.utils';

const usePartnerOverview = () => {
  const { id } = useParams<RouteParamsWithId>();
  const { openDialog } = useDialog();
  const { isFeatureEnabled } = useFeatureFlags();
  const isPartnerInvitationsEnabled: boolean = isFeatureEnabled(FeatureFlag.PARTNER_INVITATIONS);
  const { plan } = useCompanyPlanContext();
  const [skipInviteStepCard, setSkipInviteStepCard] = useState(false);
  const { deleteSite } = useSiteMutations();
  const { data, loading, error } = useQuery<
    {
      partner: IPartnerCompany;
    },
    {
      id: string;
      numberOfItems: number;
    }
  >(GET_PARTNER_OVERVIEW, {
    variables: {
      // If id is undefined, the query will be skipped
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      id: id!,
      numberOfItems: PREVIEW_LATEST_ITEMS,
    },
    skip: !id,
    fetchPolicy: 'network-only',
  });

  const isFreemium = plan === CustomerPlan.FREEMIUM;

  const partner:
    | (IPartnerCompany & {
        componentsCount?: number;
        sharedDocumentsCount?: number;
        requestsCount?: number;
        cultivationAreaCount?: number;
      })
    | undefined = useMemo(
    () =>
      data?.partner
        ? {
            ...data.partner,
            componentsCount: data.partner.components.count,
            sharedDocumentsCount: data.partner.sharedDocuments.count,
            requestsCount: data.partner.requests?.count,
            cultivationAreaCount: data.partner.cultivatedAreas?.count,
          }
        : undefined,
    [data]
  );

  const latestLog: Omit<ILogItem, 'title'> = useMemo(
    () => ({
      date: partner?.latestLog?.timestamp || new Date(),
      logo: partner?.latestLog?.company.logo?.url,
      author: `${partner?.latestLog?.user.firstName} ${partner?.latestLog?.user.lastName}`,
    }),
    [partner]
  );

  const isAllowedToSendRequests =
    isPartnerInvitationsEnabled && partner?.status === CompanyStatus.REGISTERED && !isFreemium;

  const impactClaims = partner?.impactClaims ? removeGraphConnections(partner.impactClaims) : [];
  const requests = partner?.requests ? removeGraphConnections(partner.requests) : [];
  const documents = partner?.sharedDocuments ? removeGraphConnections(partner.sharedDocuments) : [];
  const sites = partner?.sites ? removeGraphConnections(partner.sites) : [];
  const activities = partner?.activities ? removeGraphConnections(partner.activities) : [];
  const components = partner?.components ? removeGraphConnections(partner.components) : [];
  const cultivatedAreas = partner?.cultivatedAreas
    ? removeGraphConnections(partner.cultivatedAreas)
    : [];

  const showInvitePartner: boolean =
    partner?.status === CompanyStatus.NOT_INVITED && isPartnerInvitationsEnabled && !isFreemium; // Currently only possible for PAYING customer

  const showReinvitePartnerNextStep: boolean =
    partner?.status === CompanyStatus.INVITED && isPartnerInvitationsEnabled && !isFreemium; // Currently only possible for PAYING customer

  const showInvitePartnerNextStep: boolean = showInvitePartner && skipInviteStepCard === false;

  const hasAnyData =
    impactClaims.length > 0 ||
    requests.length > 0 ||
    documents.length > 0 ||
    sites.length > 0 ||
    activities.length > 0 ||
    components.length > 0;

  const handleAddActivity = () => {
    openDialog({
      type: 'ADD_EDIT_ACTIVITY',
      props: { mode: 'add-activity', partner, hidePartnerSelector: true },
    });
  };

  const handleEditActivity = (activity: IActivity) => {
    openDialog({
      type: 'ADD_EDIT_ACTIVITY',
      props: {
        mode: 'edit-activity',
        activity: {
          ...activity,
        },
      },
    });
  };

  const handleEditCultivatedArea = (id: string) => {
    let site: ISite | undefined;
    if ((site = sites.find(site => site.id === id)) && site.siteType === SiteType.FARM) {
      openDialog({
        type: 'ADD_EDIT_CULTIVATION_AREA',
        props: {
          site,
        },
      });
    } else {
      console.error('Site not found or invalid type');
      return;
    }
  };

  const handleResendInvite = () => {
    if (!partner) return;
    openDialog({
      type: 'PARTNER_RESEND_INVITE',
      props: {
        companyId: partner.id,
      },
    });
  };

  const handleEditPartner = (enableInvite = false) => {
    openDialog({
      type: 'ADD_EDIT_PARTNER',
      props: { partner, enableInvite },
    });
  };

  const handleAddSite = () => {
    openDialog({
      type: 'ADD_EDIT_SITE',
      props: { partner },
    });
  };

  const handleEditSite = (site: ISite) => {
    openDialog({
      type: 'ADD_EDIT_SITE',
      props: { site, partner },
    });
  };

  const handleAddComponent = () => {
    openDialog({
      type: 'ADD_EDIT_COMPONENT',
      props: { title: 'Add new component', partner },
    });
  };

  const handleEditComponent = (component: IComponentItem) => {
    openDialog({
      type: 'ADD_EDIT_COMPONENT',
      props: { title: 'Edit component', componentItem: component, partner },
    });
  };

  const handleDeleteSite = (siteId: string) => {
    const siteTitle = sites.find(site => site.id === siteId)?.title;
    openDialog({
      type: 'ALERT',
      props: {
        title: 'Delete site',
        text: 'Are you sure you want to delete this site? All data will be lost and you will not be able to recover this item.',
        submitText: 'Delete',
        itemTitle: siteTitle,
        displayCloseButton: true,
        onSubmit: () => {
          deleteSite({ variables: { id: siteId } });
        },
        onCancel: () => undefined,
      },
    });
  };

  return {
    partner: partner as IBasePartnerCompany & {
      componentsCount?: number;
      sharedDocumentsCount?: number;
      requestsCount?: number;
      cultivationAreaCount?: number;
    },
    loading,
    error,
    hasAnyData,
    requests,
    documents,
    sites,
    activities,
    components,
    cultivatedAreas,
    impactClaims,
    latestLog,
    isAllowedToSendRequests,
    isFreemium,
    isPartnerInvitationsEnabled,
    showInvitePartner,
    showInvitePartnerNextStep,
    showReinvitePartnerNextStep,
    handleSkipInviteStep: setSkipInviteStepCard,
    handleAddActivity,
    handleEditActivity,
    handleEditCultivatedArea,
    handleResendInvite,
    handleEditPartner,
    handleAddSite,
    handleEditSite,
    handleAddComponent,
    handleEditComponent,
    handleDeleteSite,
  };
};

export default usePartnerOverview;
