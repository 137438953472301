import { Input } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Select } from 'formik-mui';

export const StyledSelect = styled(Select)<{ error: boolean; size?: 'small' | 'medium' }>(
  ({ theme, error, size = 'medium' }) => ({
    border: '1px solid',
    borderColor: error ? theme.palette.error.main : 'rgba(0, 0, 0, 0.23)',
    borderRadius: 4,
  })
);

export const StyledInput = styled(Input)(({ theme }) => ({
  borderRadius: 4,
  position: 'relative',
  backgroundColor: '#fff !important',
  padding: theme.spacing(0.5),
  width: '100%',
  '& .MuiInputBase-input': {
    display: 'flex',
    flexWrap: 'wrap',
    paddingTop: 0,
    paddingBottom: 0,
    minHeight: 30,
    gap: theme.spacing(0.5),

    '&:focus': {
      backgroundColor: '#fff',
    },
  },
  '&:focus': {
    backgroundColor: '#fff',
  },
  '&:before': {
    content: 'none',
  },
  '&:after': {
    content: 'none',
  },
}));
