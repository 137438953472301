import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { ArrowClockwise } from '@styled-icons/bootstrap/ArrowClockwise';
import { Trash } from '@styled-icons/bootstrap/Trash';
import { DropDown } from 'components/Forms';
import { useConfig, useUserData } from 'components/hooks';
import PropTypes from 'prop-types';
import React from 'react';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Status = styled(({ isRegistered, ...props }) => <span {...props} />)(
  ({ theme, isRegistered }) => ({
    color: isRegistered && theme.palette.success.main,
    fontWeight: 600,
    fontSize: 12,
  })
);

const DropDownContainer = styled('div')(({ theme }) => ({
  '& .MuiSelect-select': {
    paddingLeft: '0 !important',
    backgroundColor: `${theme.custom.themeColors.grayScale[0]} !important`,
  },
}));

const DropDownItem = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
}));

const UserTable = ({ users, onRemove, onReinvite, onChangeRole, isPartner }) => {
  const {
    user: { id: uid },
  } = useUserData();
  const { userRoles } = useConfig();

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="left">First Name</TableCell>
            <TableCell align="left">Last Name</TableCell>
            <TableCell align="left">Email</TableCell>
            <TableCell align="left">Status</TableCell>
            {!isPartner && <TableCell align="left">Role</TableCell>}
            <TableCell align="left">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody data-cy="user-table">
          {users.map(user => {
            const {
              node: { id, firstName, lastName, email, isRegistered, role, status },
            } = user;

            return (
              <TableRow key={id} data-cy-id={id}>
                <TableCell component="th" scope="row">
                  {firstName}
                </TableCell>
                <TableCell component="th" scope="row">
                  {lastName}
                </TableCell>
                <TableCell component="th" scope="row">
                  {email}
                </TableCell>
                <TableCell component="th" scope="row">
                  <Status isRegistered={isRegistered}>{status}</Status>
                </TableCell>
                {!isPartner && (
                  <TableCell component="th" scope="row">
                    {id !== uid ? (
                      <DropDownContainer>
                        <DropDown
                          className="user-role-dropdown"
                          data-cy="user-role-dropdown"
                          currentValue={role}
                          options={userRoles}
                          onChange={item => onChangeRole({ id, role: item.toUpperCase() })}
                          renderItems={item => <DropDownItem variant="body2">{item}</DropDownItem>}
                        />
                      </DropDownContainer>
                    ) : (
                      role
                    )}
                  </TableCell>
                )}
                <TableCell component="th" scope="row">
                  {id !== uid && (
                    <Tooltip title="Remove user">
                      <IconButton
                        onClick={() => onRemove(id)}
                        data-cy="remove-user-btn"
                        size="large"
                      >
                        <Trash size={16} />
                      </IconButton>
                    </Tooltip>
                  )}
                  {!isRegistered && (
                    <Tooltip title="Resend invitation">
                      <IconButton onClick={() => onReinvite({ ...user.node })} size="large">
                        <ArrowClockwise size={16} />
                      </IconButton>
                    </Tooltip>
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

UserTable.propTypes = {
  users: PropTypes.array.isRequired,
  onRemove: PropTypes.func.isRequired,
  onChangeRole: PropTypes.func.isRequired,
  onReinvite: PropTypes.func.isRequired,
  isPartner: PropTypes.bool.isRequired,
};

export default UserTable;
