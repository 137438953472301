import React, { FC, ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import { Box, IconButton, InputAdornment } from '@mui/material';
import DebouncedTextInput from './DebouncedTextInput';
import { Search } from '@styled-icons/bootstrap/Search';
import { Close } from '@styled-icons/evaicons-solid/Close';

export const SearchInput = styled(DebouncedTextInput)(({ theme }) => ({
  minWidth: 180,
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.custom.themeColors.grayScale[20],
  },
  '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.primary.main,
  },

  '& .MuiInputBase-input': {
    height: 30,
    padding: theme.spacing(0, 0, 0, 1),
    fontSize: 12,
  },

  '& .MuiOutlinedInput-adornedEnd': {
    paddingRight: 8,
  },

  '& .MuiInputAdornment-positionStart': {
    marginRight: 0,
  },
}));

const CloseButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.primary,
  padding: 0,
  margin: 0,
}));

interface ISearchToolbarProps {
  searchTerm: string;
  setSearchTerm: (searchTerm: string) => void;
  actions?: ReactNode;
  'data-cy'?: string;
  className?: string;
  placeholder?: string;
}

const SearchToolbar: FC<ISearchToolbarProps> = ({
  searchTerm,
  setSearchTerm,
  actions,
  'data-cy': dataCy = '',
  className,
  placeholder,
}) => {
  return (
    <Box display="flex" alignItems="center">
      <SearchInput
        setDebouncedState={setSearchTerm}
        className={className}
        data-cy={dataCy}
        delay={200}
        placeholder={placeholder}
        initialValue={searchTerm}
        inputProps={{
          endAdornment: (
            <InputAdornment position="start">
              {searchTerm === '' ? (
                <Search size={12} />
              ) : (
                <CloseButton onClick={() => setSearchTerm('')}>
                  <Close size={16} />
                </CloseButton>
              )}
            </InputAdornment>
          ),
        }}
      />
      {actions}
    </Box>
  );
};

export default SearchToolbar;
