import { useDraggable } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';
import { Box, IconButton, Tooltip, styled } from '@mui/material';
import { FlexBox } from 'components/Structure';
import { ThemeTypography } from 'designSystem';
import Icon from 'designSystem/Primitives/Icon/Icon';
import React, { CSSProperties, FC, ReactNode } from 'react';
import { Booleanish, booleanish } from 'types/booleanish.types';
import AbsoluteElement from './AbsoluteElement';
import { IGraphActivity } from './Activity';
import useChainMappingConfig from './useChainMappingConfig';
export interface IGraphSubChain {
  id: string;
  x: number;
  y: number;
  height: number;
  width: number;
  title: string;
  companyName: string;
  activities: IGraphActivity[];
  highlight?: boolean;
  /*
   * Visibility of the activity to the consumer side (default: true)
   * If not visible opacity is added to the activity
   */
  isVisible?: boolean;
}

interface ISubChainProps extends IGraphSubChain {
  canReorder?: boolean;
  canDelete?: boolean;
  canSeePartner?: boolean;
  children: ReactNode[]; // SubChain activities
  onDragStart?: (event: unknown) => void;
  onDragMove?: (event: unknown, componentId: string) => void;
  onDragEnd?: (event: unknown, componentId: string) => void;
  onRemoveClick?: (subChainId: string) => void;
  onEditClick?: (subChainId: string) => void;
}

const Container = styled(AbsoluteElement)<{ faded: booleanish }>(({ faded }) => ({
  opacity: faded === 'true' ? 0.4 : 1,
  outline: 'none',
  '&.dragging': {
    zIndex: 100,
    '& *': { cursor: 'grabbing !important' },
  },
}));

const ActivitiesContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  top: 0,
  left: 0,
  padding: theme.spacing(1),
  zIndex: 1,
}));

const SubChainBackground = styled(AbsoluteElement)<{ highlight: booleanish }>(
  ({ theme, highlight }) => ({
    backgroundColor: theme.custom.themeColors.accent[60],
    opacity: highlight === 'true' ? 0.8 : 0.4,
    zIndex: 0,
  })
);

const Content = styled(AbsoluteElement)(({ theme }) => ({
  padding: theme.spacing(1),
  '&:hover .hover-btn': {
    display: 'block',
  },
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  padding: 4,
  color: theme.custom.colors.textLight,
  display: 'none',
  '&:hover': {
    color: theme.custom.colors.almostBlack,
  },
}));

const StyledDragIcon = styled(IconButton)(({ theme }) => ({
  color: theme.custom.colors.textLight,
  cursor: 'grab',
  '&:hover': {
    color: theme.custom.colors.almostBlack,
  },
}));

const SubChain: FC<ISubChainProps> = ({
  id,
  x,
  y,
  height,
  width,
  title,
  companyName,
  isVisible = true,
  children,
  canReorder,
  canDelete,
  highlight,
  canSeePartner,
  onRemoveClick,
  onEditClick,
}) => {
  const { SPACING, NODE_HEIGHT, FIRST_COLUMN_WIDTH } = useChainMappingConfig();
  const { isDragging, attributes, listeners, transform, setNodeRef } = useDraggable({ id });

  // This is a workaround to change the cursor while dragging, since the elements gets cloned and moved to the top of the DOM
  // const styles = makeStyles(() => ({
  // dragging: {
  //   zIndex: 100,
  //   '& *': { cursor: 'grabbing !important' },
  // },
  // }))();

  /* 40px is the size of the icon */
  const reorderIconSpacing = canReorder ? 40 : 0;

  const handleRemoveClick = () => onRemoveClick?.(id);
  const handleEditSubChainClick = () => onEditClick?.(id);

  /**
   * The scaling should not be applied
   */
  const style: CSSProperties = {
    transform: CSS.Transform.toString(transform ? { ...transform, scaleX: 1, scaleY: 1 } : null),
  };

  return (
    <Container
      ref={setNodeRef}
      x={x}
      y={y}
      width={width}
      height={height}
      style={style}
      /**
       * Workaround for the cursor to be changed while dragging
       */
      className={isDragging ? 'dragging' : ''}
      faded={Booleanish(!isVisible)}
      data-cy="sub-chain"
      {...attributes}
    >
      <ActivitiesContainer>{children}</ActivitiesContainer>

      {/* Background for the activities */}
      <SubChainBackground
        highlight={Booleanish(highlight)}
        x={FIRST_COLUMN_WIDTH + SPACING * 2}
        width={width - FIRST_COLUMN_WIDTH - SPACING * 2}
        height={height}
      />

      {/* Reorder icon before the FIRST COLUMN */}
      {canReorder && (
        <AbsoluteElement y={10} width={40} height={40}>
          <StyledDragIcon {...listeners}>
            <Icon name="move-handle" size="large" />
          </StyledDragIcon>
        </AbsoluteElement>
      )}

      {/* Background in the FIRST COLUMN */}
      <SubChainBackground
        highlight={Booleanish(highlight)}
        x={reorderIconSpacing}
        width={FIRST_COLUMN_WIDTH + SPACING * 2 - reorderIconSpacing}
        height={NODE_HEIGHT / 2}
      />

      {/* Content of the FIRST COLUMN */}
      <Content x={reorderIconSpacing} width={FIRST_COLUMN_WIDTH - reorderIconSpacing}>
        <FlexBox height={20}>
          <ThemeTypography
            variant="BODY_MEDIUM_BOLD"
            autoOverflow
            maxWidth={FIRST_COLUMN_WIDTH * 0.7}
          >
            {title}
          </ThemeTypography>
          <Box mr={`${SPACING / 2}px`} />

          <Tooltip title="Edit chain">
            <StyledIconButton
              className="hover-btn"
              size="small"
              onClick={handleEditSubChainClick}
              data-cy="edit-sub-chain-btn"
            >
              <Icon name="edit" size="small" color="gray-60" />
            </StyledIconButton>
          </Tooltip>

          {canDelete && (
            <Tooltip title="Remove chain">
              <StyledIconButton
                className="hover-btn"
                size="small"
                onClick={handleRemoveClick}
                data-cy="remove-sub-chain-btn"
              >
                <Icon name="delete" size="small" color="gray-60" />
              </StyledIconButton>
            </Tooltip>
          )}
        </FlexBox>

        {canSeePartner && (
          <ThemeTypography
            variant="BODY_SMALL"
            color="ALMOST_BLACK"
            autoOverflow
            maxWidth={FIRST_COLUMN_WIDTH - SPACING * 4}
          >
            {companyName}
          </ThemeTypography>
        )}
      </Content>
    </Container>
  );
};

export default SubChain;
