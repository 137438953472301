import { Box, styled } from '@mui/material';

const ScrollableTableContainer = styled(Box)(({ theme }) => ({
  marginRight: theme.spacing(-2),
  marginLeft: theme.spacing(-2),
  paddingRight: theme.spacing(2),
  paddingLeft: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  overflowY: 'auto',
  overflowX: 'hidden',
}));

export default ScrollableTableContainer;
