import { styled } from '@mui/material/styles';
import React, { FC } from 'react';

interface PhoneScreenProps {
  src: string;
  width?: string;
  height?: string;
}

const DEFAULT_PHONE_HEIGHT = '400px';
const PHONE_RATIO = 1.85;

const Frame = styled('div')<{ width?: string; height?: string }>(({ width, height }) => ({
  position: 'relative',
  zIndex: 1,
  height: height || DEFAULT_PHONE_HEIGHT,
  width: width || `calc(${height || DEFAULT_PHONE_HEIGHT} / ${PHONE_RATIO})`,
}));

const Side = styled('div')(() => ({
  background: '#1c1c1c',
  borderRadius: '25px',
  boxShadow:
    'inset 0 0 2px 2px dimgrey, inset 0 0 0 6px #e2e3e9, 0px -1px 13px 9px rgba(255,255,255,0.8) inset',
  height: '100%',
  width: '100%',
  padding: '5px 8px',
  zIndex: 1,
}));

const Screen = styled('div')(() => ({
  background: '#000',
  backgroundPosition: 'center center',
  backgroundSize: 'cover',
  borderRadius: '20px',
  position: 'relative',
  height: '98%',
  top: '1%',
  width: '100%',
  zIndex: 198,
  overflow: 'hidden',
}));

const VolumeButton = styled('div')(() => ({
  background: '#c8cacb',
  height: '12%',
  left: '-2px',
  position: 'absolute',
  top: '22%',
  width: '3px',
  '& ::after, & ::before': {
    content: '""',
    position: 'absolute',
    background: '#c8cacb',
    height: '50px',
    left: 0,
    width: '3px',
  },
  '& ::after': {
    top: '48px',
  },
  '& ::before': {
    top: '112px',
  },
}));

const PowerButton = styled('div')(() => ({
  background: '#c8cacb',
  height: '8%',
  right: '-2px',
  position: 'absolute',
  top: '22%',
  width: '3px',
}));

const PhoneScreen: FC<PhoneScreenProps> = ({ src, width, height }) => (
  <Frame width={width} height={height}>
    <Side>
      <Screen>
        <img src={src} height="100%" width="100%" alt="screen-preview" />
      </Screen>
    </Side>
    <VolumeButton />
    <PowerButton />
  </Frame>
);

export default PhoneScreen;
