import moment from 'moment';

export const getClientTime = (unit, value) => {
  const clientTime = moment().add(moment().utcOffset(), 'minutes');
  if (unit) clientTime.subtract(value, unit);

  return clientTime.utc().toISOString();
};

export const filterProducts = (products, searchTerm) => {
  if (!products) return products;

  if (!searchTerm.length) return products.map(item => item.node);

  const filtered = products.filter(({ node: { title } }) => {
    return title.toLowerCase().includes(searchTerm.toLowerCase());
  });

  return filtered.map(item => item.node);
};
