import { ChartsText, ChartsTextProps } from '@mui/x-charts';

import { FlexBox } from 'components/Structure';
import { ThemeTypography } from 'designSystem';

import React, { FC } from 'react';

const Y_AXIS_LABEL_WIDTH = 200;
const Y_AXIS_LABEL_HEIGHT = 30;

const Axis: FC<ChartsTextProps> = ({ text, x, y, ...props }) => {
  const width = Y_AXIS_LABEL_WIDTH - 12; // 12 px is the size of the dots

  // We need to find a way to identify if the axis is x or y
  const isXAxis = !Number.isNaN(Number(text));

  if (isXAxis) {
    return <ChartsText x={x} y={y} text={text} {...props} />;
  }

  return (
    <foreignObject
      x={isXAxis ? x : Number(x) - width}
      y={isXAxis ? y : Number(y) - Y_AXIS_LABEL_HEIGHT / 2}
      height={Y_AXIS_LABEL_HEIGHT}
      width={width}
      textAnchor="start"
      dominantBaseline="central"
    >
      <FlexBox height="100%">
        <ThemeTypography variant="BODY_SMALL" autoOverflow>
          {text}
        </ThemeTypography>
      </FlexBox>
    </foreignObject>
  );
};

export default Axis;
