import { Box, Dialog, Grid, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { X as Close } from '@styled-icons/bootstrap/X';
import RequestPlanUpgradeForm from 'components/Settings/RequestPlanUpgradeForm';
import UpgradeRequestedDialogContent from 'components/Settings/UpgradeRequestedDialogContent';
import { ThemeTypography, CardIllustration } from 'designSystem';
import React, { FC } from 'react';
import { IllustrationTypes } from 'types/enums';

interface ICompanyPlanDialogProps {
  expired: boolean;
  isInactive: boolean;
  open: boolean;
  requestSent: boolean;
  daysLeftInTrial: number;
  handleLogout: () => void;
  handleClose: () => void;
  handleSubmit: (values: { message: string }) => void;
}

const StyledDialog = styled(Dialog)(() => ({
  '& .MuiDialog-paperWidthMd': {
    maxWidth: 750,
    height: 500,
  },
}));

const ColoredText = styled('span')(({ theme }) => ({
  color: theme.palette.primary.main,
}));

const CloseButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.primary,
  position: 'absolute',
  top: theme.spacing(1),
  right: theme.spacing(1),
  padding: 0,
}));

const TitleContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 4, 1),
}));

const GridContainer = styled(Grid)(() => ({
  position: 'relative',
  height: '100%',
}));

const DialogContentContainer = styled(Box)(({ theme }) => ({
  height: '100%',
  padding: 40,
  background: theme.custom.colors.backgroundLight,
}));

const CompanyPlanDialog: FC<ICompanyPlanDialogProps> = ({
  open,
  requestSent,
  isInactive,
  expired,
  daysLeftInTrial,
  handleLogout,
  handleClose,
  handleSubmit,
}) => {
  return (
    <StyledDialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="md"
      data-cy="expired-trial-dialog"
    >
      <GridContainer container>
        <CloseButton
          data-cy="close-expired-dialog-btn"
          onClick={expired || isInactive ? handleLogout : handleClose}
        >
          <Close size={25} />
        </CloseButton>
        <Grid item xs={6}>
          <Box display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
            <TitleContainer>
              {isInactive ? (
                <ThemeTypography variant="BODY_LARGE">
                  You no longer have access to the Seedtrace platform as a partner to a paid
                  account. Explore our paid plans to keep your data and enable the full benefits of
                  Seedtrace.
                </ThemeTypography>
              ) : (
                <ThemeTypography variant="BODY_LARGE">
                  {expired
                    ? 'Your 14 day trial has expired'
                    : `You have ${daysLeftInTrial} ${daysLeftInTrial === 1 ? 'day' : 'days'} left`}
                </ThemeTypography>
              )}
              <Box mt={2}>
                <ThemeTypography variant="TITLE_SMALL">
                  Upgrade your <ColoredText> subscription plan</ColoredText>
                </ThemeTypography>
              </Box>
            </TitleContainer>
            <CardIllustration image={IllustrationTypes.ROCKET} width={320} bottom={0} />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <DialogContentContainer display="flex" flexDirection="column" justifyContent="center">
            {requestSent ? (
              <UpgradeRequestedDialogContent
                expired={expired || isInactive}
                onClose={expired || isInactive ? handleLogout : handleClose}
              />
            ) : (
              <RequestPlanUpgradeForm onSubmit={handleSubmit} />
            )}
          </DialogContentContainer>
        </Grid>
      </GridContainer>
    </StyledDialog>
  );
};

export default CompanyPlanDialog;
