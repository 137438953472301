import React, { useMemo, memo, useCallback, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Bar } from '@vx/shape';
import { Group } from '@vx/group';
import { AxisLeft, AxisRight } from '@vx/axis';
import { scaleLinear, scaleBand } from '@vx/scale';
import { max } from 'd3-array';
import { useTheme } from '@mui/material/styles';

const defaultMargin = {
  top: 0,
  right: 110,
  bottom: 20,
  left: 55,
};

const BarChartHorizontal = ({
  width = 600,
  height = 260,
  data,
  x,
  xDomain,
  yRight,
  y,
  margin = defaultMargin,
  shadowBars = true,
  labelPropsRight,
}) => {
  const theme = useTheme();
  const graphColor = theme.palette.grey[400];
  const xMax = width - margin.left - margin.right;
  const yMax = height - margin.top - margin.bottom;

  const yScale = useMemo(
    () =>
      scaleBand({
        range: [0, yMax],
        domain: data.map(y),
        padding: 0.4,
      }),
    [yMax, data, y]
  );

  const yScaleRight = useMemo(
    () =>
      scaleBand({
        range: [0, yMax],
        domain: data.map((_, i) => i),
        padding: 0.4,
      }),
    [yMax, data]
  );

  const xScale = useMemo(
    () =>
      scaleLinear({
        domain: xDomain || [0, max(data, x)],
        rangeRound: [xMax, 0],
      }),
    [xMax, data, x, xDomain]
  );

  const tickLabelProps = useCallback(
    () => ({
      fill: graphColor,
      fontFamily: theme.typography.fontFamily,
      fontSize: 10,
      textAnchor: 'start',
      fontWeight: '600',
      dx: '1em',
    }),
    [theme, graphColor]
  );

  const tickLabelPropsRight = useCallback(
    () => ({
      fill: theme.palette.text.primary,
      fontFamily: theme.typography.fontFamily,
      fontSize: 14,
      textAnchor: 'end',
      fontWeight: '600',
      dx: '1em',
      dy: '0.2em',
    }),
    [theme]
  );

  return (
    <svg width={width} height={height}>
      <Group top={margin.top}>
        <Group top={margin.top} left={margin.left}>
          {data.map((d, i) => {
            const barHeight = 10;
            const barWidth = xMax - xScale(x(d));
            const barY = yScale(y(d));
            const barX = margin.left;
            const transform = `translate(0, ${yScale.bandwidth() / 2 - barHeight})`;

            return (
              <Fragment key={`bar-${i}`}>
                {shadowBars && (
                  <Bar
                    x={barX}
                    y={barY}
                    width={xMax}
                    height={barHeight}
                    rx={4}
                    fill={theme.palette.primary.light}
                    transform={transform}
                  />
                )}
                <Bar
                  x={barX}
                  y={barY}
                  width={barWidth}
                  height={barHeight}
                  rx={4}
                  fill={theme.palette.primary.main}
                  transform={transform}
                />
              </Fragment>
            );
          })}
        </Group>
        <AxisLeft
          scale={yScale}
          stroke="none"
          hideAxisLine
          hideTicks
          tickLabelProps={tickLabelProps}
        />
        <AxisRight
          left={width - margin.right / 2}
          scale={yScaleRight}
          tickFormat={index => yRight(data[index])}
          stroke="none"
          hideAxisLine
          hideTicks
          tickLabelProps={labelPropsRight || tickLabelPropsRight}
        />
      </Group>
    </svg>
  );
};

BarChartHorizontal.propTypes = {
  data: PropTypes.array.isRequired,
  x: PropTypes.func.isRequired,
  y: PropTypes.func.isRequired,
  yRight: PropTypes.func.isRequired,
  xDomain: PropTypes.array,
};

export default memo(BarChartHorizontal);
