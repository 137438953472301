import { Box, styled } from '@mui/material';
import ProductExampleImage from 'assets/img/product-placeholder-image-big.svg';
import SparkImage from 'assets/img/spark.svg';
import { FieldWrapper } from 'designSystem';
import { FastField as Field } from 'formik';
import { TextField } from 'formik-mui';
import React from 'react';
import { GeneralSectionContainer, ProductPlaceholderImage, Spark, TitleField } from './styles';

const StyledTextField = styled(TextField)(() => ({
  '& .MuiInputLabel-outlined': {
    fontSize: 11,
    fontWeight: 300,
  },
}));

const CreateFormGeneralSection = () => {
  return (
    <Box pr={6} position="relative">
      <Spark src={SparkImage} alt="spark" />
      <GeneralSectionContainer>
        <Box display="flex" width="100%" gap={2} alignItems="stretch">
          <ProductPlaceholderImage src={ProductExampleImage} />
          {/* For now disabled, since the backend doesn't support it yet */}
          {/* <Field
            component={MediaField}
            fullWidth
            name="productImage"
            styles={{
              width: 140,
              height: 140,
            }}
          /> */}
          <FieldWrapper flex={1}>
            <Field
              component={TitleField}
              placeholder="Product name"
              fullWidth
              name="title"
              variant="outlined"
              data-cy="product-title-input"
            />
          </FieldWrapper>
          <FieldWrapper width={110}>
            <Field
              component={StyledTextField}
              placeholder="1234567"
              fullWidth
              name="articleNumber"
              variant="outlined"
              label="Article number"
              data-cy="product-title-input"
              inputProps={{
                style: { fontSize: 11, height: 19 },
              }}
            />
          </FieldWrapper>
        </Box>
      </GeneralSectionContainer>
    </Box>
  );
};

export default CreateFormGeneralSection;
