import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import iconSdgs from 'assets/img/icon_sdgs.png';
import SdgItem from './SdgItem';
import { PageSubTitle } from 'components/Structure';
import { ThemeTypography } from 'designSystem';

const Icon = styled('img')(({ theme }) => ({
  width: 28,
  marginRight: theme.spacing(1),
}));

const CatalogItemSdgs = ({ sdgs, className = '', usePageTitle = false }) => {
  return (
    <Box className={className}>
      <Box display="flex" alignItems="center">
        <Icon src={iconSdgs} alt="icon sdgs" />
        {usePageTitle ? (
          <PageSubTitle title="Connected global goals" />
        ) : (
          <ThemeTypography variant="BODY_LARGE_BOLD">Connected global goals</ThemeTypography>
        )}
      </Box>
      <Box paddingY={1} display="flex" flexWrap="wrap">
        {sdgs.map(sdg => (
          <SdgItem key={`sdg-${sdg?.number}`} sdg={sdg} />
        ))}
      </Box>
    </Box>
  );
};

CatalogItemSdgs.propTypes = {
  className: PropTypes.string,
  sdgs: PropTypes.array.isRequired,
  usePageTitle: PropTypes.bool,
};

export default CatalogItemSdgs;
