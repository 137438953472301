import { styled } from '@mui/material/styles';
import { ChevronDown } from '@styled-icons/bootstrap/ChevronDown';
import { ChevronUp } from '@styled-icons/bootstrap/ChevronUp';
import { ThreeDotsVertical } from '@styled-icons/bootstrap/ThreeDotsVertical';
import React, { FC, useMemo, useState } from 'react';
import { Booleanish, booleanish } from 'types/booleanish.types';
import LogItem, { ILogItem } from '../LogItem/LogItem';

interface ILogsProps {
  logs: ILogItem[];
  showSummary?: boolean;
  'data-cy'?: string;
}

const LogContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  maxWidth: 1200,

  '&:before': {
    content: '""',
    display: 'block',
    position: 'absolute',
    zIndex: 0,
    width: '2px',
    left: '5px',
    top: '20px',
    bottom: '20px',
    background: theme.palette.background.default,
  },
}));

const MiddleHistory = styled('div')<{ open: booleanish }>(({ open }) => ({
  transition: 'all 0.4s ease',
  maxHeight: open === 'true' ? 1200 : 0,
  opacity: open === 'true' ? 1 : 0,
  overflow: 'hidden',
}));

const DotsBefore = styled('div')(({ theme }) => ({
  background: '#fff',
  marginLeft: '-4px',
  padding: '3px 0',
  zIndex: 1,
  color: theme.palette.background.default,
}));

const LogButton = styled('button')(({ theme }) => ({
  background: 'none',
  color: theme.palette.grey[400],
  border: 'none',
  fontSize: theme.typography.fontSize + 'px',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  gridGap: theme.spacing(1),
}));

const SeeHistoryButton = styled(LogButton)(({ theme }) => ({
  padding: theme.spacing(1.5, 1, 1.5, 0),
}));

const MinimizeButton = styled(LogButton)(({ theme }) => ({
  padding: theme.spacing(2, 1, 2, 2),
  justifyContent: 'center',
  width: '100%',
}));

interface ILogsProps {
  logs: ILogItem[];
  'data-cy'?: string;
}

const Logs: FC<ILogsProps> = ({ logs, 'data-cy': dataCy = 'logs' }) => {
  const [showMiddleLog, setShowMiddelLog] = useState<boolean>(false);

  const toggleShowMiddleLog = () => setShowMiddelLog(curr => !curr);

  const firstLog = logs && logs.length > 0 ? logs[0] : null;
  const lastLog = useMemo(() => {
    if (!logs || logs.length < 1) return null;

    // If the last log has the attribute completed = true, create a new log with title "Completed"
    if (logs[logs.length - 1].completed) {
      return {
        title: 'Completed',
        date: logs[logs.length - 1].date,
        author: undefined,
        logo: undefined,
        completed: true,
      };
    }

    return logs[logs.length - 1];
  }, [logs]);

  const middleLogs = useMemo(() => {
    if (!logs || logs.length < 3) return [];
    const draft = [...logs];

    // Remove the first and last elements
    draft.shift();
    draft.pop();

    // If the last log has the attribute completed = true, append it to middleLogs
    if (logs[logs.length - 1] && logs[logs.length - 1].completed) {
      draft.push(logs[logs.length - 1]);
    }

    return draft;
  }, [logs]);

  return (
    <>
      <LogContainer data-cy={dataCy}>
        {firstLog && (
          <LogItem
            key={firstLog.title}
            title={firstLog.title}
            date={firstLog.date}
            logo={firstLog.logo}
            author={firstLog.author}
          />
        )}

        {middleLogs.length > 0 ? (
          <>
            <MiddleHistory open={Booleanish(showMiddleLog || middleLogs.length === 1)}>
              {middleLogs.map((log, index) => (
                <LogItem
                  key={`${index} - ${log.title}`}
                  title={log.title}
                  date={log.date}
                  logo={log.logo}
                  author={log.author}
                />
              ))}
            </MiddleHistory>
            {!showMiddleLog && middleLogs.length !== 1 && (
              <SeeHistoryButton onClick={toggleShowMiddleLog}>
                <DotsBefore>
                  <ThreeDotsVertical size={20} />
                </DotsBefore>
                <ChevronDown size={16} />
                See all history
              </SeeHistoryButton>
            )}
          </>
        ) : null}

        {logs.length > 1 && !!lastLog && (
          <LogItem
            key={`${logs.length - 1} - ${lastLog.title}`}
            title={lastLog.title}
            date={lastLog.date}
            logo={lastLog.logo}
            author={lastLog.author}
            completed={lastLog.completed}
          />
        )}
      </LogContainer>
      {middleLogs.length > 0 && showMiddleLog && (
        <MinimizeButton onClick={toggleShowMiddleLog} data-cy="minimize-button">
          <ChevronUp size={16} />
          Minimize
        </MinimizeButton>
      )}
    </>
  );
};

export default Logs;
