import React, { FC, PropsWithChildren, createContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

interface HeadContextValues {
  setTitle: (value: string) => void;
}

const HeadContext = createContext<HeadContextValues>({
  setTitle: () => undefined,
});

export const HeadProvider: FC<PropsWithChildren> = ({ children }) => {
  const [title, setTitle] = useState<string>();

  useEffect(() => {
    let tawkWidget;

    if (process.env.NODE_ENV !== 'development') {
      const existCondition = setInterval(() => {
        tawkWidget = document.getElementById('chat-bubble')?.parentElement;

        if (tawkWidget) {
          clearInterval(existCondition);
          tawkWidget.style.cssText = `
            display: block !important;
            z-index: 1200 !important;
          `;
        }
      }, 100);

      return () => clearInterval(existCondition);
    }
  }, []);

  return (
    <HeadContext.Provider
      value={{
        setTitle,
      }}
    >
      <Helmet>
        <title>
          {title ? `${title} - ` : ''}
          seedtrace
        </title>
      </Helmet>
      {children}
    </HeadContext.Provider>
  );
};

export default HeadContext;
