import { useMediaQuery, useTheme } from '@mui/material';
import { useConfig, useLogEvent } from 'components/hooks';
import { useProduct } from 'components/Product/hooks';
import findIndex from 'lodash/findIndex';
import PropTypes from 'prop-types';
import React, { createContext, useCallback, useMemo, useState } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import { getContentBlocks } from '../constants/menu';

const MenuContext = createContext(null);

const MenuProvider = ({ children }) => {
  const { logEvent } = useLogEvent();
  const { appQueryParams } = useConfig();
  const [step, setStepParam] = useQueryParam(appQueryParams.productStep, StringParam);
  const [onStepChange, setOnStepChange] = useState();
  const [previewIsActive, setPreviewIsActive] = useState(false);
  const menu = getContentBlocks();

  const setStep = useCallback(
    value => {
      const handleStepChange = () => {
        setStepParam(value);
        logEvent(`VISIT_EDITOR_${value.toUpperCase()}`);
      };

      if (typeof onStepChange !== 'function') return handleStepChange();

      onStepChange();
      setOnStepChange(null);
      handleStepChange();
    },
    [setStepParam, logEvent, onStepChange]
  );
  const activeStep = step || 'General';
  const { latestProductVersion: version } = useProduct({
    includeExpensiveFields: true,
  });

  const { versionValidation: { contentStatus } = {} } = version;

  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const activeMenuIndex = useMemo(() => findIndex(menu, ['id', activeStep]), [menu, activeStep]);

  const activeMenu = menu[activeMenuIndex];

  return (
    <MenuContext.Provider
      value={{
        activeMenu,
        activeStep,
        contentStatus,
        setStep,
        setOnStepChange,
        previewIsActive: previewIsActive && largeScreen,
        setPreviewIsActive,
        // passing down version so we only have to query expensive validation fields once
        version,
      }}
    >
      {children}
    </MenuContext.Provider>
  );
};

MenuContext.propTypes = {
  children: PropTypes.any,
  productId: PropTypes.string,
};

export { MenuContext, MenuProvider };

export default MenuContext;
