import { Theme } from '@mui/material/styles';
import { RequestType, RequestTypeType } from 'types/partner.types';
import { IconNameType } from 'designSystem/Primitives/Icon/Icon';

export type ThemeTypographyColor =
  | 'WHITE'
  | 'BLACK'
  | 'ALMOST_BLACK'
  | 'YELLOW'
  | 'GRAY'
  | 'GRAY_40'
  | 'GRAY_60'
  | 'GRAY_80'
  | 'GRAY_100'
  | 'BLUE_100'
  | 'BLUE_80'
  | 'BLUE_60'
  | 'BLUE_FROST'
  | 'GREEN'
  | 'RED';

export const getThemeTypographyColor = (theme: Theme, color?: ThemeTypographyColor) => {
  const themeColors = theme.custom?.themeColors;
  if (!theme.custom) {
    console.error('No custom theme found, wrong import used?');
    return '#000000';
  }

  switch (color) {
    case 'WHITE':
      return themeColors.white;
    case 'YELLOW':
      return themeColors.accent[100];
    case 'GRAY':
      return themeColors.grayScale[60];
    case 'GRAY_40':
      return themeColors.grayScale[40];
    case 'GRAY_60':
      return themeColors.grayScale[60];
    case 'GRAY_80':
      return themeColors.grayScale[80];
    case 'GRAY_100':
      return themeColors.grayScale[100];
    case 'BLUE_100':
      return themeColors.primary[100];
    case 'BLUE_80':
      return themeColors.primary[80];
    case 'BLUE_60':
      return themeColors.primary[60];
    case 'BLUE_FROST':
      return theme.custom.colors.blueFrost;
    case 'GREEN':
      return themeColors.success[100];
    case 'RED':
      return themeColors.error[100];
    case 'ALMOST_BLACK':
      return theme.custom.colors.almostBlack;
    case 'BLACK':
    default:
      return themeColors.black;
  }
};

export const REQUEST_ICON_NAME: Record<RequestTypeType, IconNameType> = {
  [RequestType.DOCUMENT]: 'document-request-manage',
  [RequestType.CHAIN]: 'chain-request-pending',
  [RequestType.SELF_ASSESSMENT]: 'document-request-manage',
};
