import React, { FC } from 'react';
import { useDrawingArea } from '@mui/x-charts/hooks';
import { styled } from '@mui/material';

interface IRiskPieLabelProps {
  label: string;
  subLabel: string;
}

const StyledLabel = styled('text')(({ theme }) => ({
  fill: theme.palette.text.primary,
  textAnchor: 'middle',
  dominantBaseline: 'central',
  fontSize: 30,
  fontFamily: theme.custom.fontFamilyTitle,
  fontWeight: 700,
}));

const StyledSubLabel = styled('tspan')(({ theme }) => ({
  fontFamily: theme.custom.fontFamilyBase,
  fontSize: 15,
  fontWeight: 400,
}));

const RiskPieLabel: FC<IRiskPieLabelProps> = ({ label, subLabel }) => {
  const { width, height, left, top } = useDrawingArea();
  return (
    <StyledLabel x={left + width / 2} y={top + height / 2}>
      <tspan x={left + width / 2} dy="-0.2em">
        {label}
      </tspan>
      <StyledSubLabel x={left + width / 2} dy="1.4em">
        {subLabel}
      </StyledSubLabel>
    </StyledLabel>
  );
};

export default RiskPieLabel;
