import React from 'react';
import { Box } from '@mui/material';
import { CardIllustration, CardContainer, ThemeTypography, ActionLink } from 'designSystem';
import { AvailableSizes, IllustrationTypes } from 'types/enums';
import { styled } from '@mui/material/styles';

const Highlight = styled('span')(({ theme }) => ({
  color: theme.palette.primary.main,
}));

const SeedtraceIntro = () => {
  return (
    <CardContainer
      size={AvailableSizes.MEDIUM}
      data-cy="home-getting-started-block"
      title={
        <div>
          Welcome to the platform for credible supply chain <Highlight>transparency</Highlight>
        </div>
      }
      renderIllustration={
        <CardIllustration image={IllustrationTypes.SEEDTRACE} width={535} right={-25} top={0} />
      }
    >
      <Box mb={3}>
        <ThemeTypography variant="BODY_LARGE">
          The place to trace, prove and overview your sustainable impact, to achieve 100%
          transparency, at your own pace.
        </ThemeTypography>
      </Box>

      <Box display="flex" justifyContent="space-between" mt="auto">
        <ActionLink onClick={() => window.open('https://seedtrace.org', '_blank')}>
          Read more on our website
        </ActionLink>
      </Box>
    </CardContainer>
  );
};

export default SeedtraceIntro;
