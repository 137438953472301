import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { Subtitle } from 'containers/Register';
import { useMutation } from '@apollo/client';
import { useMessages } from 'components/hooks';
import { ErrorState } from 'components/Structure';
import { ThemeTypography, ThemeButton } from 'designSystem';

const ResendVerification = ({ email, title, subtitle, mutation }) => {
  const { setErrorMessage } = useMessages();
  const [error, setError] = useState(null);

  const [resentVerificationMail, setResentVerificationMail] = useState(false);

  const [resendVerificationEmail] = useMutation(mutation, {
    variables: { email },
    onError: error => {
      setErrorMessage(error.message);
    },
    onCompleted: () => {
      setError(null);
      setResentVerificationMail(true);
    },
    fetchPolicy: 'no-cache',
  });

  if (error) return <ErrorState>{error}</ErrorState>;

  if (!resentVerificationMail) {
    return (
      <Box mt={12}>
        <ThemeTypography variant="TITLE_SMALL" color="YELLOW">
          {title}
        </ThemeTypography>
        <Subtitle variant="subtitle1">{subtitle}</Subtitle>
        <Box mt={2}>
          <ThemeButton onClick={resendVerificationEmail} color="YELLOW" size="large">
            Resend email
          </ThemeButton>
        </Box>
      </Box>
    );
  }

  return (
    <Box mt={12}>
      <ThemeTypography variant="TITLE_SMALL" color="YELLOW">
        Updated verification link sent
      </ThemeTypography>
      <Subtitle variant="subtitle1">
        {`We sent an email with an updated link to ${email}.`}
      </Subtitle>
    </Box>
  );
};

ResendVerification.propTypes = {
  email: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
};

export default ResendVerification;
