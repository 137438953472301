import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { useConfig, useMessages } from 'components/hooks';
import { UPDATE_PRODUCT_LANGUAGES, ADD_LANGUAGE_AND_TRANSLATE } from 'graphql/mutations';
import { StringParam, useQueryParam } from 'use-query-params';
import { Product } from 'types/types';
import { AvailableLanguages } from 'types/enums';

const useAddLanguage = ({ onClose }: { onClose?: () => void }) => {
  const { setErrorMessage, setSuccessMessage } = useMessages();
  const [selectedLang, setSelectedLang] = useState<AvailableLanguages>();

  const config = useConfig();
  const [, setSelectedLangQuery] = useQueryParam(config.appQueryParams.lang, StringParam);

  const [updateProductLanguages, { loading: loadingBlankLanguage }] = useMutation(
    UPDATE_PRODUCT_LANGUAGES,
    {
      onError: () =>
        setErrorMessage('Something went wrong adding the language. Please try again later.'),
      onCompleted: () => {
        setSuccessMessage('Language has been added');
        onClose?.();
        setSelectedLangQuery(selectedLang);
      },
    }
  );

  const [addLangAndTranslate, { loading: loadingLanguageTranslate }] = useMutation(
    ADD_LANGUAGE_AND_TRANSLATE,
    {
      onError: () =>
        setErrorMessage('Something went wrong adding the language. Please try again later.'),
      onCompleted: () => {
        setSuccessMessage('Your content has been successfully translated');
        onClose?.();
        setSelectedLangQuery(selectedLang);
      },
    }
  );

  const addBlankLanguage: (args: { product: Product; lang: string }) => void = async ({
    product,
    lang,
  }) => {
    updateProductLanguages({
      variables: {
        id: product.id,
        input: {
          languageConfig: {
            ...product.languageConfig,
            enabled: [...product.languageConfig.enabled, lang],
          },
        },
      },
    });
  };

  const addLanguageAndTranslate: (args: {
    autoTranslateLang: AvailableLanguages | undefined;
    product: Product;
    lang: string;
    enabledLanguages: string[];
  }) => void = async ({ autoTranslateLang, product, lang, enabledLanguages }) => {
    await addLangAndTranslate({
      variables: {
        id: product.id,
        translateInput: {
          sourceLang: autoTranslateLang,
          targetLang: lang,
          forceOverwrite: true,
        },
        languageInput: {
          languageConfig: {
            ...product.languageConfig,
            enabled: enabledLanguages,
          },
        },
      },
    });
  };

  return {
    selectedLang,
    loadingBlankLanguage,
    loadingLanguageTranslate,
    setSelectedLang,
    addBlankLanguage,
    addLanguageAndTranslate,
  };
};

export default useAddLanguage;
