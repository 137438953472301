import React, { FC, useState } from 'react';
import { Library, UploadDropZone } from 'components/MediaLibrary';
import { PageTitle, PageContainer } from 'components/Structure';
import { Dialog, DialogContent, DialogTitle, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useUploadState } from 'components/hooks';
import ThemeButton from 'designSystem/Buttons/ThemeButton/ThemeButton';
import { SelectableProvider } from 'components/MediaLibrary/Contexts/SelectableContext';

const UploadContainer = styled('div')({
  width: 850,
});

const ButtonWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100',
  marginTop: theme.spacing(2),
}));

const MediaLibrary: FC = () => {
  const [isUploadActive, setIsUploadActive] = useState(false);
  const { undoFileUploads } = useUploadState();

  return (
    <PageContainer>
      <PageTitle title="Media" />

      <Box className="scrollwrapper">
        <SelectableProvider setUploadActive={() => setIsUploadActive(true)}>
          <Library windowScroll={true} withUploadModal={true} />
        </SelectableProvider>
      </Box>

      <Dialog
        open={isUploadActive}
        onClose={() => {
          setIsUploadActive(false);
        }}
        maxWidth="lg"
        aria-labelledby="form-dialog-title"
        data-cy="dialog-upload-media-library"
      >
        <DialogTitle id="form-dialog-title">Upload Media</DialogTitle>
        <DialogContent>
          <UploadContainer>
            <UploadDropZone />

            <ButtonWrapper>
              <ThemeButton
                onClick={() => {
                  undoFileUploads();
                  setIsUploadActive(false);
                }}
                color="BLUE_ICE"
                size="medium"
              >
                Cancel
              </ThemeButton>
              <ThemeButton
                onClick={() => setIsUploadActive(false)}
                color="YELLOW"
                size="medium"
                data-cy="upload-dialog-save-button"
              >
                Save
              </ThemeButton>
            </ButtonWrapper>
          </UploadContainer>
        </DialogContent>
      </Dialog>
    </PageContainer>
  );
};

export default MediaLibrary;
