export const PARTNER_REQUEST_ROWS_PER_PAGE = 5;

export const PARTNER_REQUEST_RECEIVED_ROWS_PER_PAGE = 10;

export const COMPONENTS_TABLE_ROWS_PER_PAGE = 6;

export const SUPPLY_CHAIN_TABLE_ROWS_PER_PAGE = 5;

export const ACTIVITIES_TABLE_ROWS_PER_PAGE = 10;

export const PREVIEW_LATEST_ITEMS = 3;
