import {
  sitesHeaderColumns,
  SitesTableColumnKeys,
} from 'components/Sites/SitesTable/sitesHeaderColumns';
import { SortableTableHead, StyledTable } from 'components/TableBase';
import { appQueryParams } from 'constants/appQueryParams';
import React, { FC, useMemo } from 'react';
import { IClusterSite, ISite } from 'types/site.types';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import { getComparator, stableSort } from 'utils';
import SitesTableBody from './SitesTableBody';
import { useMediaQuery } from '@mui/material';
import theme from 'styles/theme';

interface ISitesTableProps {
  columns: SitesTableColumnKeys[];
  sites: ISite[];
  hideHeader?: boolean;
  rowsPerPage?: number;
  onSiteClick?: (site: ISite) => void;
  onSiteEdit?: (site: ISite) => void;
  onSiteClusterEdit?: (site: IClusterSite) => void;
  onSiteClusterDelete?: (siteId: string) => void;
  onSiteDelete?: (siteId: string) => void;
  onManageClusterSites?: (siteClusterId: string) => void;
  onEditCultivationArea?: (site: ISite) => void;
}

const SitesTable: FC<ISitesTableProps> = ({
  columns,
  sites,
  hideHeader,
  onSiteClick,
  onSiteEdit,
  onSiteClusterEdit,
  onSiteClusterDelete,
  onSiteDelete,
  onManageClusterSites,
  onEditCultivationArea,
}) => {
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('xl'));
  const isExtraSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));

  const [query, setQuery] = useQueryParams({
    [appQueryParams.order]: withDefault(StringParam, 'desc'),
    [appQueryParams.orderBy]: withDefault(StringParam, 'lastEditedTimestamp'),
  });
  const order = query[appQueryParams.order] as 'asc' | 'desc' | undefined;
  const orderBy = query[appQueryParams.orderBy];

  const shownColumns = useMemo(
    () =>
      sitesHeaderColumns.filter(
        ({ id }) =>
          columns.includes(id) &&
          (isSmallScreen || isExtraSmallScreen
            ? id !== SitesTableColumnKeys.MODIFIED_TIMESTAMP
            : true) &&
          (isExtraSmallScreen ? id !== SitesTableColumnKeys.ACTIONS : true)
      ),
    [columns, isSmallScreen, isExtraSmallScreen]
  );

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setQuery({
      [appQueryParams.order]: isAsc ? 'desc' : 'asc',
    });
    setQuery({
      [appQueryParams.orderBy]: property,
    });
  };

  const sortedSites = useMemo(
    () => stableSort(sites, getComparator(order, orderBy)),
    [order, orderBy, sites]
  );

  return (
    <>
      <StyledTable
        aria-labelledby="tableTitle"
        aria-label="enhanced table"
        data-cy="partners-table"
      >
        {!hideHeader && (
          <SortableTableHead
            order={order}
            orderBy={orderBy}
            onSort={handleRequestSort}
            headerColumns={shownColumns}
          />
        )}
        <SitesTableBody
          sites={sortedSites}
          headerColumns={shownColumns}
          onSiteEdit={onSiteEdit}
          onSiteClick={onSiteClick}
          onSiteClusterEdit={onSiteClusterEdit}
          onManageClusterSites={onManageClusterSites}
          onSiteClusterDelete={onSiteClusterDelete}
          onSiteDelete={onSiteDelete}
          onEditCultivationArea={onEditCultivationArea}
        />
      </StyledTable>
    </>
  );
};

export default SitesTable;
