import React, { useState } from 'react';
import { IconButton, InputAdornment } from '@mui/material';
import { Eye } from '@styled-icons/bootstrap/Eye';
import { EyeSlash } from '@styled-icons/bootstrap/EyeSlash';
import { TextField } from 'formik-mui';

const PasswordInputField = ({ ...props }) => {
  const [showHidePassword, setShowHidePassword] = useState(false);

  return (
    <TextField
      id={'outlined-adornment-password'}
      type={showHidePassword ? 'text' : 'password'}
      {...props}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setShowHidePassword(!showHidePassword)}
              edge="end"
              size="large"
            >
              {showHidePassword ? <EyeSlash size={16} /> : <Eye size={16} />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default PasswordInputField;
