/* eslint-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  IComponentChainRes,
  ONBOARDING_CHAIN_1,
  ONBOARDING_CHAIN_2,
  ONBOARDING_CHAIN_3,
  ONBOARDING_CHAIN_4,
} from 'constants/onboarding/mockComponentChainResponses';

interface ChainsResolver {
  Query: {
    onboardingChain: (
      _: unknown,
      variables: { id: string; onboardingStep: number }
    ) => Promise<IComponentChainRes>;
  };
}

const chainsResolver: ChainsResolver = {
  Query: {
    onboardingChain: async (_: unknown, variables: { id: string; onboardingStep: number }) => {
      switch (variables.onboardingStep) {
        case 1:
        case 2:
          return ONBOARDING_CHAIN_1;
        case 3:
          return ONBOARDING_CHAIN_2;
        case 4:
        case 5:
        case 6:
          return ONBOARDING_CHAIN_3;
        case 7:
        case 8:
          return ONBOARDING_CHAIN_4;
        default:
          return ONBOARDING_CHAIN_1;
      }
    },
  },
};

export default chainsResolver;
