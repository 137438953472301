import { useCallback } from 'react';
import { useApolloClient } from '@apollo/client';

/**
 * This is a really bad hook and should be removed in the future.
 * @deprecated
 */
const useQueryReturn = (query, options = {}) => {
  const client = useApolloClient();

  return useCallback(
    ({ variables }) =>
      client.query({
        query,
        variables,
        ...options,
      }),
    [client, query, options]
  );
};

export default useQueryReturn;
