import { useLazyQuery } from '@apollo/client';
import loadable from '@loadable/component';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Loader } from 'components/Forms';
import { useConfig, useLogEventOnMount, usePageTitle } from 'components/hooks';
import { EditorStateProvider, MenuProvider } from 'components/Product/MultiStep/Contexts';
import { ErrorState, PageContainer, PageTitle } from 'components/Structure';
import { GET_LATEST_VERSION_AND_PRODUCT } from 'graphql/queries';
import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { StringParam, useQueryParam } from 'use-query-params';

const Container = styled(Box)(() => ({
  height: '100%',
  width: '100%',
}));

const ProductEditorLayout: FC<PropsWithChildren<{ productId: string; title?: string }>> = ({
  title,
  productId,
  children,
}) => (
  <PageContainer>
    <Container>
      <PageTitle
        title="Product page editor"
        titleExtension={title}
        goBackLabel="Product page overview"
        goBackUrl={`/products/${productId}`}
      />
      <Box pt={2} pb={2}>
        {children}
      </Box>
    </Container>
  </PageContainer>
);

const LoadableEditor = loadable(() => import('components/Product/MultiStep/Editor'), {
  fallback: <Loader />,
});

const ProductEditor: FC = () => {
  usePageTitle('Editor');
  const { id: productId } = useParams();
  const { appQueryParams } = useConfig();
  const [lang] = useQueryParam(appQueryParams.lang, StringParam);
  // when switching languages loading does not immediately become true
  // so there are issues with reading version from cache. display loading state until
  // language parameter matches the actual content lang
  const [fetchedLang, setFetchedLang] = useState(lang);
  const [initialLoading, setIntitialLoading] = useState(true);

  useLogEventOnMount('VIEW_PRODUCT_EDITOR', { productId });

  const [fetch, { error, loading, data }] = useLazyQuery(GET_LATEST_VERSION_AND_PRODUCT, {
    onCompleted: () => {
      setIntitialLoading(false);
      // if no lang param then we can be sure that default language was fetched
      setFetchedLang(lang);
    },
  });

  useEffect(() => {
    LoadableEditor.preload();
  }, []);

  useEffect(() => {
    fetch({
      variables: {
        id: productId,
        lang,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  if (!productId) {
    return <ErrorState error={error} />;
  }

  if (error) {
    return (
      <ProductEditorLayout productId={productId}>
        <ErrorState error={error} />
      </ProductEditorLayout>
    );
  }

  const isFetching = !data && (loading || initialLoading);
  const langMatchesParam = !lang || lang === fetchedLang;

  if (isFetching || !langMatchesParam) {
    return (
      <ProductEditorLayout productId={productId}>
        <Loader />
      </ProductEditorLayout>
    );
  }

  return (
    <ProductEditorLayout title={data.latestProductVersion?.title} productId={productId}>
      <MenuProvider>
        <EditorStateProvider refreshing={loading}>
          <LoadableEditor />
        </EditorStateProvider>
      </MenuProvider>
    </ProductEditorLayout>
  );
};

export default ProductEditor;
