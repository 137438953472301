import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  background: '#fff',
  border: '2px solid',
  borderColor: theme.palette.grey[200],
  padding: theme.spacing(2),
  backgroundColor: theme.palette.common.white,
  borderRadius: 6,
  height: '100%',
}));

export const FrameContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'block',
  border: `6px solid ${theme.palette.secondary.main}`,
  borderRadius: 36,
  overflow: 'hidden',
  marginTop: theme.spacing(2),
}));

export const ButtonsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(3),
  justifyContent: 'flex-end',
  width: '100%',
  paddingTop: theme.spacing(1),
  whiteSpace: 'nowrap',

  [theme.breakpoints.up('lg')]: {
    justifyContent: 'space-between',
  },
}));

export const DisabledMenuMessage = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  background: 'rgba(255,255,255,0.95)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  whiteSpace: 'nowrap',
  color: theme.custom.colors.actionButtonHover,
  fontWeight: 700,
}));

export const DisabledMessageIcon = styled('div')(({ theme }) => ({
  display: 'inline-block',
  marginRight: theme.spacing(1),
}));

export const LoadingIframe = styled('div')(({ theme }) => ({
  position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: theme.spacing(1),
  marginTop: theme.spacing(1),
  width: '100%',
  height: '100%',
  color: theme.custom.colors.actionButtonHover,
}));

export const StyledIFrame = styled('iframe')(() => ({
  display: 'block',
  width: 320,
  height: 660,
}));

export const TogglePreviewButton = styled(Button)(({ theme }) => ({
  color: theme.custom.colors.textLight,
  background: 'none',
  whiteSpace: 'nowrap',

  '&:hover': {
    background: 'none',
  },
}));

export const PreviewToggleIcon = styled('img')(({ theme }) => ({
  marginRight: theme.spacing(1),
}));
