import { FormHelperText } from '@mui/material';
import { styled } from '@mui/material/styles';
import { getIn } from 'formik';
import React, { Fragment } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import ColorPicker from 'designSystem/Inputs/ColorPicker/ColorPicker';
import HexColorInputField from './HexColorInputField';

const Container = styled('div')(() => ({
  display: 'inline-flex',
  flexDirection: 'row',
  alignItems: 'flex-end',
  width: '100%',
}));

const StyledHexColorInputField = styled(HexColorInputField)(({ theme }) => ({
  marginRight: theme.spacing(1),
}));

const ColorSelector = ({ ...props }) => {
  const {
    field: { value, name },
    form: { errors, touched, setFieldValue },
  } = props;

  const fieldError = getIn(errors, name);
  const showError = getIn(touched, name) && typeof fieldError === 'string';

  const handleChange = useDebouncedCallback(input => {
    setFieldValue(name, input);
  }, 250);

  return (
    <Fragment>
      <Container>
        <StyledHexColorInputField color={value} handleChange={handleChange.callback} />
        <ColorPicker color={value} handleChange={handleChange.callback} />
      </Container>
      {showError && <FormHelperText error>{fieldError}</FormHelperText>}
    </Fragment>
  );
};

export default ColorSelector;
