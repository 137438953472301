import React from 'react';
import { Box, useTheme } from '@mui/material';
import { Skeleton } from 'designSystem';
import { MapMarker } from '@styled-icons/open-iconic/MapMarker';

const ProductSummarySkeleton = () => {
  const theme = useTheme();

  return (
    <Box display="flex" alignItems="center">
      <MapMarker size={30} color={theme.palette.grey[300]} />
      <Box ml={1} gap={2.5}>
        <Skeleton width={150} height={25} animation={false} />
        <Skeleton width={105} height={20} animation={false} />
      </Box>
      <Box ml="auto">
        <Skeleton width={63} height={25} animation={false} />
      </Box>
    </Box>
  );
};

export default ProductSummarySkeleton;
