import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { Box, DialogContent, styled } from '@mui/material';
import { Alert } from '@mui/material';
import emailSendIcon from 'assets/img/icons/email-send.svg';
import { Loader } from 'components/Forms';
import { ErrorState } from 'components/Structure';
import { useLogEvent, useMessages } from 'components/hooks';
import { PartnerReInviteSchema } from 'constants/schemas';
import { DialogDefault, FieldWrapper, ThemeButton } from 'designSystem';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import { REINVITE_PARTNER } from 'graphql/mutations/partner.mutations';
import { GET_INVITED_PARTNER_USER } from 'graphql/queries/user.queries';
import React, { FC, useState } from 'react';
import { User } from 'types/company.types';
import { IDefaultDialogProps } from 'types/dialog.types';

const ButtonIcon = styled('img')(() => ({
  width: 18,
  pointerEvents: 'none',
}));

const ContentWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
}));

interface FormInputs {
  email: string;
}

export interface IPartnerResendInviteDialogProps extends IDefaultDialogProps {
  companyId: string;
  resend?: boolean;
}

const PartnerResendInviteDialog: FC<IPartnerResendInviteDialogProps> = ({
  companyId,
  onClose,
  open = true,
}) => {
  const [error, setError] = useState<string>();
  const { setSuccessMessage } = useMessages();
  const { logEvent } = useLogEvent();
  const { data, loading } = useQuery<{ invitedPartnerCompanyUser: User }>(
    GET_INVITED_PARTNER_USER,
    {
      variables: { id: companyId },
    }
  );

  const [reInvitePartner] = useMutation<
    { invitePartner: { email: string; companyId: string } },
    { email: string; companyId: string }
  >(REINVITE_PARTNER, {
    onError: (error: ApolloError) => {
      if (error.message === 'USER_NOT_FOUND') {
        setError('The email address entered does not match any partner');
      } else {
        setError('There was an error re-inviting your partner');
      }
    },
    onCompleted: () => {
      setSuccessMessage('The partner was successfully re-invited to the platform.');
      logEvent('PARTNER_REINVITED');
      onClose?.();
    },
  });

  const user = data?.invitedPartnerCompanyUser;

  if (!data && loading) return <Loader />;
  if (!user) return <ErrorState action={undefined} />;

  const handleOnSubmit = async (values: FormInputs) => {
    if (values.email) {
      await reInvitePartner({
        variables: {
          email: values.email,
          companyId,
        },
      });
    }
  };

  return (
    <DialogDefault
      title="Re-invite partner"
      onClose={onClose}
      open={open}
      fullWidth
      maxWidth="xs"
      background="#fff"
      preventCloseOnBackground
      data-cy="re-invite-partner-dialog"
    >
      <DialogContent>
        <ContentWrapper>
          <Formik
            initialValues={{ email: user.email }}
            validationSchema={PartnerReInviteSchema}
            onSubmit={handleOnSubmit}
          >
            {({ handleSubmit, isSubmitting }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  {error && (
                    <Box mb={3}>
                      <Alert severity="error">{error}</Alert>
                    </Box>
                  )}
                  <Box mt={1} mb={4}>
                    <FieldWrapper label="Partner Email">
                      <Field
                        disabled
                        component={TextField}
                        fullWidth
                        name="email"
                        variant="outlined"
                      />
                    </FieldWrapper>
                  </Box>
                  <Box display="flex">
                    <Box mr={2}>
                      <ThemeButton color="BLUE_ICE" fullWidth size="large" onClick={onClose}>
                        Cancel
                      </ThemeButton>
                    </Box>
                    <ThemeButton
                      color="YELLOW"
                      fullWidth
                      loading={isSubmitting}
                      startIcon={<ButtonIcon src={emailSendIcon} />}
                      size="large"
                      type="submit"
                      data-cy="re-send-invite-submit"
                    >
                      Re-send Invite
                    </ThemeButton>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </ContentWrapper>
      </DialogContent>
    </DialogDefault>
  );
};

export default PartnerResendInviteDialog;
