import { Box } from '@mui/material';
import { styled } from '@mui/styles';
import { convertHexToRGBarray } from 'utils';

export const HeaderContainer = styled(Box)(({ theme, invalid, pending }) => ({
  backgroundColor:
    invalid === 'true' ? `rgba(${convertHexToRGBarray(theme.palette.error.main)}, 0.15)` : '#fff',
  padding: theme.spacing(1.5),
  borderRadius: '6px 6px 0 0',
  position: 'relative',
  overflow: 'hidden',

  '&:before': {
    content: '""',
    display: 'block',
    width: '100%',
    height: '100%',
    position: 'absolute',
    left: 0,
    top: 0,
    borderColor: pending === 'true' ? '#d5daee' : '#F4F5FA',
    borderRadius: '6px 6px 0 0',
    borderWidth: invalid === 'true' ? '0' : '1px',
    borderStyle: pending === 'true' ? 'dashed' : 'solid',
  },
}));

export const Description = styled('div')(({ theme }) => ({
  background: theme.palette.grey[200],
  padding: theme.spacing(2),
  borderRadius: '0 0 6px 6px',
}));
