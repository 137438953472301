import { Box, Grid } from '@mui/material';
import { useConfig } from 'components/hooks';
import { applicationHelperTexts } from 'config/applicationHints';
import { gaTrackingIdSchema } from 'constants/schemas';
import { ThemeButton, FieldWrapper } from 'designSystem';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import PropTypes from 'prop-types';
import React from 'react';
import { ActionLink } from 'designSystem';

const GATrackingIdForm = ({ onSubmit, gaTrackingId }) => {
  const { helpDeskGoogleAnalytics } = useConfig();
  const onLearnMoreClick = () => window.open(helpDeskGoogleAnalytics, '_blank');

  return (
    <Formik
      initialValues={{
        gaTrackingId: gaTrackingId,
      }}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={gaTrackingIdSchema}
      onSubmit={values => onSubmit(values)}
    >
      {({ isSubmitting }) => (
        <Form>
          <Grid container spacing={3}>
            <Grid item xs={8}>
              <Box>
                <FieldWrapper
                  label="Tracking-ID or Measurement-ID"
                  subtitle="Use the Google Tracking-ID/Measurement-ID to add Google Analytics to your products"
                  tooltip={{
                    variant: 'INFO',
                    helperText: applicationHelperTexts.tracking.trackingId,
                  }}
                >
                  <Field
                    component={TextField}
                    fullWidth
                    placeholder="G-XXXXXXXXXX"
                    name="gaTrackingId"
                    variant="outlined"
                    data-cy="ga-tracking-id-input"
                  />
                </FieldWrapper>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" alignItems="center" gap={2.5}>
                <ThemeButton
                  color="YELLOW"
                  size="large"
                  loading={isSubmitting}
                  type="submit"
                  data-cy="ga-tracking-id-submit"
                >
                  Update
                </ThemeButton>
                <ActionLink onClick={onLearnMoreClick} target="_blank">
                  Learn more
                </ActionLink>
              </Box>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

GATrackingIdForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  gaTrackingId: PropTypes.string,
};

export default GATrackingIdForm;
