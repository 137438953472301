import { useMutation, useQuery } from '@apollo/client';
import { Loader } from 'components/Forms';
import { useMessages, useLogEvent } from 'components/hooks';
import { Container, KeysForm, SettingsHeader, WidgetPreferencesForm } from 'components/Settings';
import { ErrorState } from 'components/Structure';
import { GENERATE_API_KEY, UPDATE_COMPANY_CONFIGS } from 'graphql/mutations';
import { GET_INTEGRATION_SETTINGS } from 'graphql/queries';
import React, { Fragment } from 'react';

const IntegrationSettings = () => {
  const { setSuccessMessage, setErrorMessage } = useMessages();
  const { logEvent } = useLogEvent();

  const { data: { company } = {}, loading, error } = useQuery(GET_INTEGRATION_SETTINGS);

  const [generateKey, { loading: generatingKey }] = useMutation(GENERATE_API_KEY, {
    onCompleted: () => {
      setSuccessMessage('Success! API Key generated.');
    },
    onError: () => {
      setErrorMessage('Something went wrong generating your API Key.');
    },
    refetchQueries: ['IntegrationSettings'],
  });

  const [updateCompanyConfig] = useMutation(UPDATE_COMPANY_CONFIGS, {
    onCompleted: () => {
      logEvent('CHANGE_INTEGRATION_SETTING');
      setSuccessMessage('Success! Widget preferences were updated.');
    },
    onError: () => {
      setErrorMessage('Something went wrong updating your widget preferences.');
    },
  });

  const handleSubmitWidgetPreferences = values => {
    return updateCompanyConfig({
      variables: {
        input: {
          integrationSettings: {
            ...values,
          },
        },
      },
    });
  };

  const handleSubmitKey = async () => {
    await generateKey();
  };

  if (error) return <ErrorState />;
  if (!company && loading) return <Loader />;

  return (
    <Fragment>
      <Container>
        <SettingsHeader title="API Key" />
        <KeysForm loading={generatingKey} onSubmit={handleSubmitKey} apiKey={company?.apiKey} />
      </Container>
      {/* Without the api key the integration settings don't exist, so we show them only if they exist */}
      {company?.integrationSettings && (
        <Container>
          <SettingsHeader title="Integration Settings" />
          <WidgetPreferencesForm
            onSubmit={handleSubmitWidgetPreferences}
            integrationSettings={company?.integrationSettings}
          />
        </Container>
      )}
    </Fragment>
  );
};

export default IntegrationSettings;
