import { useLazyQuery, useMutation } from '@apollo/client';
import { Box, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { DropDown } from 'components/Forms';
import { useConfig, useMessages } from 'components/hooks';
import { ThemeButton, ThemeTypography } from 'designSystem';
import { UPDATE_QR_DESIGN } from 'graphql/mutations';
import { GET_CONVERTED_IMAGE } from 'graphql/queries/imageConverter.queries';
import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { SupportedImageFromHTMLFormats } from 'types/enums';
import { generateImageFromHtml } from 'utils';
import { DefaultSeedtraceQRLabel, SmallSeedtraceQRLabel } from './SeedtraceQRLabels';

const LabelContainer = styled('div')(({ theme }) => ({
  padding: 16,
  background: theme.custom.themeColors.grayScale[20],
  borderRadius: 6,
  width: 'fit-content',
}));

const Container = styled('div')(({ theme }) => ({
  position: 'relative',
  width: 130,
}));

const DownloadButtons = styled('div')(({ theme }) => ({
  display: 'flex',

  '& .dropdown-label-format': {
    background: `${theme.palette.primary.main} !important`,
    height: 36,
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    marginRight: 4,
  },
  '& .download-label-button': {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
}));

// used toggle instead of a simple switch because of the design
const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  background: theme.custom.themeColors.grayScale[20],
  padding: 2,

  '& .MuiToggleButtonGroup-grouped': {
    border: 0,
    textTransform: 'none',
    fontSize: 11,
    fontWeight: 700,

    '&:not(:first-of-type)': {
      borderRadius: theme.shape.borderRadius,
    },

    '&:first-of-type': {
      borderRadius: theme.shape.borderRadius,
    },
  },

  '& .MuiToggleButton-root.Mui-selected': {
    backgroundColor: '#fff',
    color: '#000',
  },
}));

interface Props {
  productId: string;
}

const LabelQRCodeTab: React.FC<Props> = ({ productId }: Props) => {
  const { setErrorMessage } = useMessages();
  const config = useConfig();
  const { palette } = useTheme();
  const [qrCode, setQrCode] = useState<string>('');
  const [selectedFormat, setSelectedFormat] = useState<SupportedImageFromHTMLFormats>(
    SupportedImageFromHTMLFormats.PNG
  );
  const [hasBlueLabel, setHasBlueLabel] = useState<boolean>(true);
  const [hasDetails, setHasDetails] = useState<boolean>(true);
  const [isSmallLabel, setIsSmallLabel] = useState<boolean>(false);
  const [updateQRDesign, { loading }] = useMutation(UPDATE_QR_DESIGN, {
    onCompleted: ({ updateQRDesign }) => {
      setQrCode(updateQRDesign);
    },
  });

  const qrCodeLabel = useRef<HTMLDivElement>() as MutableRefObject<HTMLInputElement>;

  const [convertImage, { loading: imageConverting }] = useLazyQuery<{ convertImage: string }>(
    GET_CONVERTED_IMAGE
  );

  const convertImageToEPS = async () => {
    const el = qrCodeLabel.current.firstChild;
    if (!el) return undefined;
    const stringEncoded = new XMLSerializer().serializeToString(el);
    const base64 = window.btoa(stringEncoded);
    const response = await convertImage({
      variables: { inputBase64: base64, fileName: 'seedtrace-label.svg', outputFormat: 'eps' },
    });
    return response?.data?.convertImage;
  };

  const handleImageDownload = async () => {
    if (!qrCodeLabel.current) {
      setErrorMessage("Something went wrong, couldn't download image");
      return;
    }
    const link = document.createElement('a');
    if (selectedFormat === SupportedImageFromHTMLFormats.EPS) {
      const url = await convertImageToEPS();
      if (!url) {
        setErrorMessage("Something went wrong, couldn't convert image to EPS");
        return;
      }
      link.href = url;
      link.download = 'seedtrace-label.eps';
    } else {
      link.href = await generateImageFromHtml(selectedFormat, qrCodeLabel.current);
      link.download = 'seedtrace-label';
    }
    link.click();
  };

  useEffect(() => {
    updateQRDesign({
      variables: {
        qrCodeDesign: {
          design: {
            marker_bottom_template: 'version11',
            marker_left_template: 'version11',
            marker_right_template: 'version11',
            foreground_color: hasBlueLabel ? palette.secondary.main : '#000',
            qr_code_pattern: 'dots',
            qr_code_text: config.generateRedirectURL(productId),
            image_format: 'SVG',
          },
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasBlueLabel]);

  return (
    <Box>
      <ThemeTypography variant="BODY_MEDIUM" data-cy="seedtrace-label-description">
        For your product packaging, print material and more.
      </ThemeTypography>
      <Box mt={2.5} display="flex">
        <LabelContainer>
          <Container ref={qrCodeLabel} className={loading || imageConverting ? 'shimmer' : ''}>
            {isSmallLabel ? (
              <SmallSeedtraceQRLabel
                qrCode={qrCode}
                hasDetails={hasDetails}
                hasBlueLabel={hasBlueLabel}
              />
            ) : (
              <DefaultSeedtraceQRLabel
                qrCode={qrCode}
                hasDetails={hasDetails}
                hasBlueLabel={hasBlueLabel}
              />
            )}
          </Container>
        </LabelContainer>
        <Box ml={2}>
          <Box mb={2}>
            <StyledToggleButtonGroup
              size="small"
              value={isSmallLabel}
              exclusive
              onChange={(event: React.MouseEvent<HTMLElement>, displaySmallLabel: boolean) => {
                if (displaySmallLabel !== null) {
                  setIsSmallLabel(displaySmallLabel);
                }
              }}
            >
              <ToggleButton value={false}>Full label</ToggleButton>
              <ToggleButton value={true} data-cy="toggle-button-small-label">
                Small label
              </ToggleButton>
            </StyledToggleButtonGroup>
          </Box>
          <Box mb={2}>
            <StyledToggleButtonGroup
              size="small"
              value={hasBlueLabel}
              exclusive
              onChange={(event: React.MouseEvent<HTMLElement>, isBlue: boolean) => {
                if (isBlue !== null) {
                  setHasBlueLabel(isBlue);
                }
              }}
            >
              <ToggleButton value={true}>Blue</ToggleButton>
              <ToggleButton value={false}>Black</ToggleButton>
            </StyledToggleButtonGroup>
          </Box>
          <Box mb={2}>
            <StyledToggleButtonGroup
              size="small"
              value={hasDetails}
              exclusive
              onChange={(event: React.MouseEvent<HTMLElement>, hasYellowDetails: boolean) => {
                if (hasYellowDetails !== null) {
                  setHasDetails(hasYellowDetails);
                }
              }}
            >
              <ToggleButton value={true}>Yellow details</ToggleButton>
              <ToggleButton value={false}>All white details</ToggleButton>
            </StyledToggleButtonGroup>
          </Box>
          <DownloadButtons>
            <DropDown
              data-cy="dropdown-label-format"
              options={Object.values(SupportedImageFromHTMLFormats)}
              renderItems={(item: SupportedImageFromHTMLFormats) => (
                <ThemeTypography variant="BUTTON_LARGE">{item}</ThemeTypography>
              )}
              currentValue={selectedFormat}
              onChange={(item: SupportedImageFromHTMLFormats) => setSelectedFormat(item)}
              className="dropdown-label-format"
            />
            <ThemeButton
              loading={loading || imageConverting}
              size="large"
              onClick={handleImageDownload}
              data-cy="download-label-button"
              className="download-label-button"
            >
              Download QR Label
            </ThemeButton>
          </DownloadButtons>
        </Box>
      </Box>
    </Box>
  );
};

export default LabelQRCodeTab;
