import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Plus } from '@styled-icons/bootstrap';
import { Trash } from '@styled-icons/bootstrap/Trash';
import { Close } from '@styled-icons/evaicons-solid/Close';
import { CHAIN_STEPS_OPTIONS, TChainStepOption } from 'components/ChainMapping/constants/steps';
import { AlertBanner, DropDown } from 'components/Forms';
import { InfoTooltip, ThemeButton } from 'designSystem';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { ChainType, IChainStep } from 'types/chain.types';
import { IDefaultDialogProps } from 'types/dialog.types';
import { AvailableSizes } from 'types/enums';
import { triggerMouseDown } from 'utils/event.utils';
import { v4 as uuid } from 'uuid';
import { StepIndexNumber } from '../Forms/styles';
import FlexBox from 'components/Structure/FlexBox';

interface IAddStepsDialogProps extends IDefaultDialogProps {
  steps: IChainStep[];
  addStepAtIndex: number;
  chainType: ChainType;
  onAddSteps: (newSteps: (IChainStep & { isNewStep?: boolean })[]) => void;
}

const CloseButton = styled(IconButton)(({ theme }) => ({
  color: theme.custom.colors.actionButtonHover,
  position: 'absolute',
  top: theme.spacing(1),
  right: theme.spacing(1),
  zIndex: 1,
}));

const DeleteIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.secondary,
  '&:hover': {
    color: theme.custom.colors.secondaryLighter,
  },
}));

const AddIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.secondary.main,
  background: theme.custom.colors.backgroundLight,
  borderRadius: '50%',
  position: 'absolute',
  bottom: -32,
  left: 2,
  zIndex: 10,
  width: 20,
  height: 20,
  '&:hover': {
    color: theme.custom.colors.secondaryLighter,
    background: theme.custom.colors.backgroundLight,
  },
}));

const StyledDialog = styled(Dialog)(({ theme }) => ({
  padding: theme.spacing(4),
  minWidth: '500px',

  '& .MuiPaper-root:not(.MuiAlert-root)': {
    minWidth: '500px',
  },
}));

const StyledItemContainer = styled(FlexBox)(({ theme }) => ({
  padding: theme.spacing(2),
  paddingBottom: 0,
  marginBottom: theme.spacing(2),
}));

const TextParagraph = styled('p')<{ mt?: number }>(({ mt = 0 }) => ({
  marginTop: mt,
  marginBottom: 0,
}));

const StepItem: FC<
  Omit<IChainStep, 'isDeletable'> & {
    index: number;
    isDisabled?: boolean;
    // Indicate that a selection has to be made if there is none
    showError: boolean;
    onValueChange: (id: string, newValue: TChainStepOption) => void;
    onRemoveClick: (id: string) => void;
    onAddNewClick: (addIndex: number) => void;
  }
> = ({ id, title, index, isDisabled, showError, onValueChange, onRemoveClick, onAddNewClick }) => {
  // Workaround until we remove the old custom step names
  const selectedOption =
    title === ''
      ? title
      : ['Origin', ...CHAIN_STEPS_OPTIONS].find(
          step => step.toLowerCase() === title.toLowerCase()
        ) || 'Other';
  // If the selected option is 'Other', we have to add 'Other' option to the list of options otherwise its not selectable
  const options: string[] = [
    selectedOption.toLocaleLowerCase() === 'origin' ? 'Origin' : undefined,
    ...CHAIN_STEPS_OPTIONS,
    selectedOption.toLocaleLowerCase() === 'other' ? 'Other' : undefined,
  ].filter(option => option !== undefined) as string[];

  const handleValueChange = (newValue: TChainStepOption) => onValueChange(id, newValue);
  const handleRemoveClick = () => onRemoveClick(id);
  const handleAddNewClick = () => onAddNewClick(index + 1);

  return (
    <StyledItemContainer>
      <Box position="relative">
        <StepIndexNumber>{index + 1}</StepIndexNumber>

        <Tooltip title="Add new step">
          <AddIconButton size="small" data-cy="steps-add-new" onClick={handleAddNewClick}>
            <Plus size={14} />
          </AddIconButton>
        </Tooltip>
      </Box>

      <Box mr={2} />
      <DropDown<string, TChainStepOption>
        fullWidth
        data-cy={`chain-step-dropdown-${id}`}
        disabled={isDisabled}
        size={AvailableSizes.SMALL}
        variant="outlined"
        currentValue={selectedOption}
        placeholder="Select activity category"
        options={options}
        onChange={handleValueChange}
        renderItems={title => title}
        errorMsg={!title && showError ? 'Select a step type' : undefined}
      />

      {!isDisabled && (
        <>
          <Box mr={0.75} />
          <Tooltip title="Remove step and all containing activities">
            <DeleteIconButton
              className="delete-button"
              onClick={handleRemoveClick}
              data-cy="step-delete-button"
            >
              <Trash size={14} />
            </DeleteIconButton>
          </Tooltip>
        </>
      )}
    </StyledItemContainer>
  );
};

const AddStepsDialog: FC<IAddStepsDialogProps> = ({
  open,
  steps,
  addStepAtIndex,
  chainType,
  onAddSteps,
  onClose,
}) => {
  const [editStepId, setEditStepId] = useState<string | undefined>(undefined);
  const [currentSteps, setCurrentSteps] = useState<(IChainStep & { isNewStep?: boolean })[]>(steps);
  const [hasEmptyError, setEmptyError] = useState<boolean>(false);
  const [hasOrderError, setOrderError] = useState<boolean>(false);

  /**
   * Workaround to open the dropdown when the dialog is opened
   *
   */
  useEffect(() => {
    if (editStepId) {
      setTimeout(() => {
        const selectElement = document.getElementById(
          `chain-step-dropdown-${editStepId || uuid()}`
        );
        if (selectElement) {
          triggerMouseDown(selectElement);
        }
      }, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editStepId]);

  const validateOrder = () => {
    const currentStepNames = currentSteps.map(({ title }) => title);
    // We do not care about the 'Other' step (can have every position)
    const filteredKey = currentStepNames.filter(key => CHAIN_STEPS_OPTIONS.includes(key));
    // Create an index mapping to check if the order is correct
    const indexKeyMapping = filteredKey.map(key => CHAIN_STEPS_OPTIONS.indexOf(key));
    // Checking if the index of the current key is always smaller or equal than the next key
    const isValidOrder = indexKeyMapping.every(
      (keyIndex, index, array) => index === 0 || array[index - 1] <= keyIndex
    );
    setOrderError(!isValidOrder);
    return isValidOrder;
  };

  useEffect(() => {
    validateOrder();
    setEmptyError(currentSteps.filter(step => !step.title || step.title === '').length > 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSteps]);

  useEffect(() => {
    handleAddNewStep(addStepAtIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addStepAtIndex]);

  const onSaveChange = () => {
    const isStepEmpty = currentSteps.filter(step => !step.title || step.title === '').length > 0;
    if (isStepEmpty) {
      setEmptyError(isStepEmpty);
      return;
    }
    setEmptyError(false);
    onAddSteps(currentSteps);
    onClose?.();
  };

  const handleAddNewStep = (addToIndex: number) => {
    const newStepId = uuid();
    setCurrentSteps(prev => {
      const copyOfPrev = [...prev];
      copyOfPrev.splice(addToIndex, 0, {
        id: newStepId,
        title: '',
        isNewStep: true,
        isDeletable: true,
      });
      return copyOfPrev;
    });
    setEmptyError(currentSteps.filter(step => !step.title || step.title === '').length > 0);
    setEditStepId(newStepId);
  };

  const handleDeleteStep = useCallback(
    (stepId: string) => {
      setCurrentSteps(items => items.filter(item => item.id !== stepId));
    },
    [setCurrentSteps]
  );

  const handleValueChange = (id: string, newValue: TChainStepOption) => {
    setCurrentSteps(items =>
      items.map(item =>
        item.id === id
          ? { id: item.id, title: newValue, isNewStep: true, isDeletable: item.isDeletable }
          : item
      )
    );
  };

  return (
    <StyledDialog open={!!open} onClose={onClose} data-cy="add-steps-dialog">
      <CloseButton onClick={onClose}>
        <Close size={23} />
      </CloseButton>
      <DialogTitle>
        <Box display="flex">
          Add steps
          <InfoTooltip
            size={AvailableSizes.SMALL}
            text={
              <>
                Map your product&apos;s
                {chainType === 'COMPONENT_CHAIN' ? 'component chains' : 'supply chains'} into
                standardised steps. For each step, you can add activities chronologically.
                <TextParagraph mt={3}>
                  There can be several of one step type, for example multiple processing steps. The
                  chain step types are, and have to adopt the following order:
                </TextParagraph>
                <TextParagraph>
                  <b>Origin:</b> The first step in the supply chain (E.g. Harvest).
                </TextParagraph>
                <TextParagraph>
                  <b>Processing:</b> Refinement of a component (E.g. Grinding).
                </TextParagraph>
                <TextParagraph>
                  <b>Handling:</b>Movement of a component without material change (E.g. Export).
                </TextParagraph>
                <TextParagraph>
                  <b>Manufacturing:</b> Manufacturing of the end product (E.g. Mixing & Moulding).
                </TextParagraph>
                <TextParagraph>
                  <b>Distribution:</b> Logistics and selling of the end product (E.g. Retail).
                </TextParagraph>
                <TextParagraph>
                  <b>Return:</b> After selling & distribution of the end product (E.g. Recycling).
                </TextParagraph>
              </>
            }
          />
        </Box>
      </DialogTitle>
      <DialogContent>
        {currentSteps.map((step, index) => (
          <StepItem
            key={step.id}
            index={index}
            isDisabled={!step.isNewStep}
            id={step.id}
            title={step.title}
            showError={hasEmptyError}
            onValueChange={handleValueChange}
            onRemoveClick={handleDeleteStep}
            onAddNewClick={handleAddNewStep}
          />
        ))}

        {hasOrderError && (
          <Box mt={5}>
            <AlertBanner severity="warning">
              Please check the order of the step types. ‘Origin’ always has to be the first step,
              followed by ‘Processing’, ’Handling’, ‘Manufacturing’, ’Distribution’, and ‘Return’,
              in that order. You can create as many of each step type as you like.
            </AlertBanner>
          </Box>
        )}
      </DialogContent>

      <DialogActions>
        <ThemeButton color="BLUE_ICE" size="large" type="button" onClick={onClose}>
          Cancel
        </ThemeButton>
        <ThemeButton
          disabled={hasEmptyError || hasOrderError || currentSteps.length === steps.length}
          size="large"
          color="YELLOW"
          data-cy="steps-submit-button"
          onClick={onSaveChange}
        >
          Apply changes
        </ThemeButton>
      </DialogActions>
    </StyledDialog>
  );
};

export default AddStepsDialog;
