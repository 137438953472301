import { Box } from '@mui/material';
import ProductExampleImage from 'assets/img/product-placeholder-image.png';
import ThemeTypography from 'designSystem/Primitives/Typography/ThemeTypography';
import React, { FC } from 'react';
import { Product } from 'types/types';
import ContentStatus from './ContentStatus';
import Image from 'designSystem/DataDisplay/Image/Image';

interface IProductSummaryCardProps {
  product: Product;
}

const ProductSummaryCard: FC<IProductSummaryCardProps> = ({ product }) => {
  const { styledProgress, title, articleNumber, image } = product;

  return (
    <Box display="flex">
      <Image image={image} width={44} height={44} backupImageUrl={ProductExampleImage} />
      <Box ml={2}>
        <Box display="flex" alignItems="center">
          <ThemeTypography variant="ITEM_TITLE">{title}</ThemeTypography>
          {articleNumber && (
            <Box ml={0.8}>
              <ThemeTypography variant="BODY_MEDIUM" autoOverflow maxWidth={150} color="GRAY_80">
                | {articleNumber}
              </ThemeTypography>
            </Box>
          )}
        </Box>
        <ContentStatus {...styledProgress} />
      </Box>
    </Box>
  );
};

export default ProductSummaryCard;
