import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import successBg from 'assets/img/bg_blue_line.svg';

export const SuccessTitle = styled('div')(({ theme }) => ({
  width: '50%',
  height: 180,
  padding: theme.spacing(4),
  background: `url(${successBg})`,
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  display: 'flex',
  justifyContent: 'center',

  '& > *': {
    color: theme.palette.common.white,
  },
}));

export const HeadlinePublishDialog = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  fontSize: '34px',
  color: '#fff',
  fontFamily: theme.custom.fontFamilyTitle,
  textTransform: 'none',
  letterSpacing: '-0.02em',
}));
