import React from 'react';

const Equal = ({ stroke }) => (
  <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.36737 14.8866H12.8127C13.6963 14.8866 14.4127 15.6029 14.4127 16.4866V17.0538H5.76737V16.4866C5.76737 15.6029 6.48372 14.8866 7.36737 14.8866Z"
      stroke={stroke}
      strokeWidth="0.8"
    />
    <path
      d="M10.0839 4.84326V13.1701M10.0839 13.1701H9.25691C8.31356 13.1701 7.54883 13.9349 7.54883 14.8782V14.8782H12.6191V14.8782C12.6191 13.9349 11.8543 13.1701 10.911 13.1701H10.0839Z"
      stroke={stroke}
      strokeWidth="0.8"
    />
    <path
      d="M2.0563 1L3.74638 1.48802M18.3233 5.69721L16.422 5.14819M16.422 5.14819L18.9571 10.8214M16.422 5.14819L13.8869 10.8214M16.422 5.14819L11.7743 3.80613M18.9571 10.8214H13.8869M18.9571 10.8214C18.9571 10.8214 18.9571 13.5971 16.422 13.5971C13.8869 13.5971 13.8869 10.8214 13.8869 10.8214M3.74638 1.48802L6.2815 7.19178M3.74638 1.48802L1 7.19178M3.74638 1.48802L8.3941 2.83008M6.2815 7.19178H1M6.2815 7.19178C6.2815 7.19178 6.2815 9.96741 3.74638 9.96741C1 9.96741 1 7.19178 1 7.19178"
      stroke={stroke}
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.5854 3.34859C11.5854 4.19295 10.9093 4.87018 10.0841 4.87018C9.2589 4.87018 8.58274 4.19295 8.58274 3.34859C8.58274 2.50423 9.2589 1.827 10.0841 1.827C10.9093 1.827 11.5854 2.50423 11.5854 3.34859Z"
      stroke={stroke}
      strokeWidth="0.8"
    />
  </svg>
);

export default Equal;
