import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/remote-config';
import 'firebase/storage';

const firebaseConfig = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG);

class Firebase {
  constructor() {
    firebase.initializeApp(firebaseConfig);
    this.firebase = firebase;
  }

  getFirebase = () => this.firebase;
}

export default Firebase;
