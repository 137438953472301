import { styled } from '@mui/material/styles';

export const Container = styled('div')<{
  fullHeight?: never;
  borderTop?: boolean;
}>(({ borderTop, fullHeight, theme }) => ({
  position: 'relative',
  padding: theme.spacing(3),
  overflow: 'hidden',
  height: fullHeight && '100%',
  borderRadius: 8,
  background: '#fff',
  border: '2px solid',
  borderColor: theme.palette.grey[200],

  '&:before': {
    content: borderTop ? '""' : '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: 8,
    background: theme.palette.secondary.main,
  },
}));
