import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import ItemSearch from 'assets/img/item_search.svg';
import React from 'react';

const Image = styled('img')(({ theme }) => ({
  width: 150,
  marginBottom: theme.spacing(0.5),
}));

const Title = styled(Typography)(({ theme }) => ({
  color: theme.custom.colors.textLight,
  fontWeight: 700,
  fontSize: 17,
}));

const Message = styled(Typography)(({ theme }) => ({
  color: theme.custom.colors.textLight,
}));

interface Props {
  /** @default "No search results found" */
  title?: string;
  message?: string;
}

const EmptySearch: React.FC<Props> = ({ title = 'No search results found', message }) => {
  return (
    <div>
      <Box alignContent="center" justifyContent="center">
        <Image src={ItemSearch} />
        <Title variant="h6">{title}</Title>
        {message && <Message variant="body2">{message}</Message>}
      </Box>
    </div>
  );
};

export default EmptySearch;
