import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import ProductExampleImage from 'assets/img/product-placeholder-image.png';
import Skeleton from 'designSystem/DataDisplay/Skeleton/Skeleton';
import React, { FC } from 'react';
import Image from 'designSystem/DataDisplay/Image/Image';

interface IProductSummarySkeletonProps {
  loading?: boolean;
  title?: string | null;
}

const Title = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  marginBottom: theme.spacing(0.5),
}));

const ProductSummarySkeleton: FC<IProductSummarySkeletonProps> = ({ title, loading }) => {
  return (
    <Box display="flex" alignItems="center">
      <Image backupImageUrl={ProductExampleImage} width={42} height={42} />
      <Box ml={1} gap={2.5}>
        {title ? (
          <Title variant="body1">{title}</Title>
        ) : (
          <Box mb={1}>
            <Skeleton width={150} height={25} animation={loading ? 'pulse' : false} />
          </Box>
        )}
        <Skeleton width={105} height={20} animation={loading ? 'pulse' : false} />
      </Box>
      <Box ml="auto">
        <Skeleton width={63} height={25} animation={loading ? 'pulse' : false} />
      </Box>
    </Box>
  );
};

export default ProductSummarySkeleton;
