import { HeaderColumn } from 'types/types';

export enum PartnerHeaderColumnEnum {
  TITLE = 'title',
  SITES_COUNT = 'sitesCount',
  OWNER = 'owner',
  MODIFIED_TIMESTAMP = 'modifiedTimestamp',
  ACTIONS = 'actions',
}

export type PartnerHeaderColumn = keyof typeof PartnerHeaderColumnEnum;

export const getPartnerHeaderColumns = (enabledColumns: PartnerHeaderColumnEnum[]) => {
  const columns: HeaderColumn<PartnerHeaderColumnEnum>[] = [];

  enabledColumns.forEach(column => {
    switch (column) {
      case PartnerHeaderColumnEnum.TITLE:
        columns.push({ id: PartnerHeaderColumnEnum.TITLE, headerName: 'Title', sortable: false });
        break;

      case PartnerHeaderColumnEnum.SITES_COUNT:
        columns.push({
          id: PartnerHeaderColumnEnum.SITES_COUNT,
          headerName: 'Site count',
          sortable: false,
        });
        break;

      case PartnerHeaderColumnEnum.OWNER:
        columns.push({
          id: PartnerHeaderColumnEnum.OWNER,
          headerName: 'Owner',
          sortable: false,
        });
        break;

      case PartnerHeaderColumnEnum.MODIFIED_TIMESTAMP:
        columns.push({
          id: PartnerHeaderColumnEnum.MODIFIED_TIMESTAMP,
          headerName: 'Last edited',
          sortable: true,
        });
        break;

      case PartnerHeaderColumnEnum.ACTIONS:
        columns.push({
          id: PartnerHeaderColumnEnum.ACTIONS,
          headerName: 'Actions',
          sortable: false,
          align: 'right',
        });
        break;
    }
  });

  return columns;
};
