import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useSticky } from 'components/hooks';
import PropTypes from 'prop-types';
import React from 'react';
import ImageDetail from './ImageDetail';
import VideoDetail from './VideoDetail';

const Container = styled('div')(({ theme, styles }) => ({
  position: 'relative',
  width: '100%',
  flexShrink: 0,
  alignSelf: 'flex-start',
  padding: theme.spacing(3),
  backgroundColor: theme.palette.common.white,
  border: `1px solid ${theme.palette.grey[200]}`,
  background: '#fff',
  borderRadius: theme.spacing(1),
  ...styles,
}));

const sitckyStyles = {
  position: 'fixed',
  top: 24,
  right: 24,
  width: 375,
};

const Placeholder = styled('div')(({ theme }) => ({
  width: '100%',
  flexShrink: 0,
  marginLeft: theme.spacing(2),
}));

const LibraryItemDetail = ({ item, sticky = false }) => {
  const isImage = item.__typename === 'Image';
  const isVideo = item.__typename === 'Video';
  const [ref, { isSticky }] = useSticky(147);
  const makeSticky = sticky && isSticky;

  return (
    <Box display="flex" flexDirection="column" data-cy={`media-library-detail-${item.__typename}`}>
      <Placeholder />
      <Container styles={makeSticky ? sitckyStyles : {}}>
        <div ref={ref} />
        {isImage && <ImageDetail image={item} />}
        {isVideo && <VideoDetail video={item} />}
      </Container>
    </Box>
  );
};

LibraryItemDetail.propTypes = {
  item: PropTypes.object.isRequired,
  sticky: PropTypes.bool,
};

export default LibraryItemDetail;
