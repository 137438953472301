import React from 'react';
import { SettingsHeader, Container } from 'components/Settings';
import { GATrackingIdForm } from 'components/Settings';
import { UPDATE_COMPANY_CONFIGS } from 'graphql/mutations';
import { useMutation, useQuery } from '@apollo/client';
import { GET_COMPANY_DETAILS } from 'graphql/queries';
import { ErrorState } from 'components/Structure';
import { Loader } from 'components/Forms';
import { useMessages } from 'components/hooks';

const GoogleAnalyticsTrackingId = () => {
  const { data: { company } = {}, error, loading } = useQuery(GET_COMPANY_DETAILS);

  const { setSuccessMessage, setErrorMessage } = useMessages();
  const [updateCompanyConfigs] = useMutation(UPDATE_COMPANY_CONFIGS, {
    onCompleted: () => {
      setSuccessMessage('Success! Google Analytics tacking id was updated.');
    },
    onError: () => {
      setErrorMessage('Something went wrong updating your Google Analytics tracking id.');
    },
  });

  const handleSubmit = ({ gaTrackingId }) => {
    return updateCompanyConfigs({
      variables: {
        input: {
          gaTrackingId,
        },
      },
    });
  };

  if (error) return <ErrorState error={error} />;
  if (!company && loading) return <Loader />;

  return (
    <Container>
      <SettingsHeader title="Google Analytics Tracking ID" />
      <GATrackingIdForm onSubmit={handleSubmit} gaTrackingId={company?.gaTrackingId} />
    </Container>
  );
};

export default GoogleAnalyticsTrackingId;
