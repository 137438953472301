import { FieldWrapper } from 'designSystem';
import { FastField as Field, useField } from 'formik';
import { TextField } from 'formik-mui';
import React, { FC, useEffect } from 'react';

interface ITextInputFieldProps {
  disabled?: boolean;
  label: string;
  name: string;
  subtitle?: string;
  placeholder?: string;
  size?: 'small' | 'medium';
  helperText?: string;
  isRequired?: boolean;
}

const TextInputField: FC<ITextInputFieldProps> = ({
  disabled,
  label,
  subtitle,
  helperText,
  isRequired,
  size = 'medium',
  ...props
}) => {
  const [{ value }, , { setValue }] = useField(props.name);

  // Workaround for clearing the field when the value is undefined
  useEffect(() => {
    if (!value) {
      setValue('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <FieldWrapper
      label={label}
      subtitle={subtitle}
      variant={size}
      required={isRequired}
      tooltip={
        helperText
          ? {
              helperText,
              variant: 'INFO',
            }
          : undefined
      }
    >
      <Field
        disabled={disabled}
        component={TextField}
        fullWidth
        size={size}
        variant="outlined"
        {...props}
      />
    </FieldWrapper>
  );
};

export default TextInputField;
