import AmplitudeContext from 'contexts/AmplitudeContext';
import { useContext, useEffect } from 'react';

export const useLogEvent = () => {
  return useContext(AmplitudeContext);
};

export const useLogEventOnMount = (event: string, eventProperties?: unknown) => {
  const { logEvent } = useLogEvent();

  useEffect(() => {
    logEvent(event, eventProperties);
  }, [logEvent]); // eslint-disable-line
};
