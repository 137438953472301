import { Box, Typography, styled } from '@mui/material';
import React, { FC } from 'react';
import Moment from 'react-moment';
import { ILogItem } from '../LogItem/LogItem';
import Icon from 'designSystem/Primitives/Icon/Icon';
import ThemeTypography from 'designSystem/Primitives/Typography/ThemeTypography';
import { booleanish, Booleanish } from 'types/booleanish.types';

interface ILogSummaryProps {
  createdTimestamp: Date;
  latestLog: Omit<ILogItem, 'title'>;
}

const DateTypography = styled(Typography)<{ 'is-first-item'?: booleanish }>(
  ({ theme, 'is-first-item': isFirstItem }) => ({
    position: 'relative',
    fontWeight: 400,
    fontSize: 14,
    padding: isFirstItem === 'true' ? theme.spacing(1, 0) : 0,

    '&:before': {
      position: 'absolute',
      content: '""',
      width: 2,
      background: theme.custom.colors.backgroundLight,
      left: -20 + 4.5,

      ...(isFirstItem === 'true'
        ? {
            top: 'calc(50% + 5.5px)',
            bottom: 0,
          }
        : {
            top: 0,
            bottom: 'calc(50% - 5.5px)',
          }),
    },

    '&:after': {
      position: 'absolute',
      content: '""',
      width: 11,
      height: 11,
      background:
        isFirstItem === 'true' ? theme.palette.secondary.main : theme.custom.colors.backgroundLight,
      borderRadius: '50%',
      left: -20,
      top: 'calc(50% - 5.5px)',
    },
  })
);

const NameBox = styled('div')(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  background: theme.custom.themeColors.primary[5],
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  marginTop: theme.spacing(1),
  width: 'fit-content',
}));

const AuthorIcon = styled('img')(({ theme }) => ({
  width: 16,
  marginRight: theme.spacing(1),
}));

const EditStatus: FC<ILogSummaryProps> = ({ latestLog, createdTimestamp }) => {
  return (
    <Box display="flex" flexDirection="column" pl={3}>
      <DateTypography is-first-item={Booleanish(true)}>
        Added on <Moment format="LL">{createdTimestamp}</Moment>
      </DateTypography>
      <DateTypography>
        Last edited <Moment format="LL">{latestLog.date}</Moment>
      </DateTypography>
      <NameBox>
        {latestLog.logo ? (
          <AuthorIcon src={latestLog.logo} />
        ) : (
          <Box mr={1}>
            <Icon name="profile-image" size="medium" />
          </Box>
        )}
        <ThemeTypography variant="BODY_MEDIUM">{latestLog.author}</ThemeTypography>
      </NameBox>
    </Box>
  );
};

export default EditStatus;
