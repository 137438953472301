import { styled } from '@mui/material/styles';
import { ActionLink } from 'designSystem';

export const PrevButton = styled(ActionLink)(({ theme }) => ({
  '&.action-link': {
    position: 'absolute',
    left: theme.spacing(4),
  },
}));

export const NextButton = styled(ActionLink)(({ theme }) => ({
  '&.action-link': {
    position: 'absolute',
    right: theme.spacing(4),
  },
}));

export const ControlBarContainer = styled('div')(({ theme }) => ({
  background: theme.palette.secondary.main,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  padding: theme.spacing(5),
}));
