import { gql } from '@apollo/client';

export const GET_CERTIFICATES_CATALOG_ITEMS = gql`
  query GetCertificateCatalogItems {
    certificateCatalogItems {
      edges {
        node {
          id
          title
          description
          imageUrl
        }
      }
    }
  }
`;
