import React from 'react';
import { Loader } from 'components/Forms';
import { useMessages } from 'components/hooks';
import { SettingsHeader, UserEditForm, Container } from 'components/Settings';
import { useMutation, useQuery } from '@apollo/client';
import { GET_USER_DETAILS } from 'graphql/queries';
import { EDIT_USER } from 'graphql/mutations';
import { ErrorState } from 'components/Structure';

const UserDetails = () => {
  const { setSuccessMessage, setErrorMessage } = useMessages();

  const { data: { user } = {}, loading, error } = useQuery(GET_USER_DETAILS);

  const [editUser] = useMutation(EDIT_USER, {
    onCompleted: () => {
      setSuccessMessage('Success! Your details were updated.');
    },
    onError: () => {
      setErrorMessage('Something went wrong updating your details.');
    },
    refetchQueries: ['GetUserDetails'],
  });

  const handleSubmit = async ({ firstName, lastName }) => {
    await editUser({
      variables: {
        firstName,
        lastName,
      },
    });
  };

  if (error) return <ErrorState />;
  if (!user && loading) return <Loader />;

  return (
    <Container>
      <SettingsHeader title="Profile" />
      <UserEditForm onSubmit={handleSubmit} user={user} />
    </Container>
  );
};

export default UserDetails;
