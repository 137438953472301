import React, { Fragment, useState } from 'react';
import { Box, Link } from '@mui/material';
import { PageSubTitle } from 'components/Structure';
import { useConfig, useDialog } from 'components/hooks';
import { Link as RouterLink } from 'react-router-dom';
import { Plus } from '@styled-icons/bootstrap/Plus';
import { ProductSummarySkeleton } from 'components/Product/ProductTable';
import { CardContainer, ItemCardSmall, ThemeButton, AlertDialog } from 'designSystem';
import { IDocument } from 'types/document.types';
import DocumentIntroTitle from 'components/DocumentLibrary/DocumentIntroTitle';
import { useUploadDialogActions } from 'components/DocumentLibrary/hooks';

interface Props {
  documents: IDocument[];
  loading?: boolean;
}

const HomeDocumentOverview: React.FC<Props> = ({ documents, loading }) => {
  const { openDialog } = useDialog();
  const { handleDeleteDocuments } = useUploadDialogActions();
  const [showDeleteAlertForId, setShowDeleteAlertForId] = useState<IDocument['id'] | undefined>(
    undefined
  );

  const openUploadDocumentDialog = () => {
    openDialog({ type: 'UPLOAD_DOCUMENTS' });
  };

  const openDocument = (url: IDocument['url']) => {
    window.open(url, '_blank');
  };

  const openEditDocumentDialog = (document: IDocument) => {
    openDialog({
      type: 'EDIT_DOCUMENT',
      props: {
        document,
        onDelete: () => setShowDeleteAlertForId(document.id),
      },
    });
  };

  const { homeProductsMaxLength } = useConfig();

  return (
    <React.Fragment>
      <Box flex={1} data-cy="home-documents-overview">
        <PageSubTitle title="Documents Overview" mb={2}>
          <Box display="flex" alignItems="center" gap={2.5}>
            <Link
              component={RouterLink}
              to="/documents/internal"
              color="secondary"
              className="small"
              data-cy="link-see-all-documents"
            >
              See all documents
            </Link>
            <ThemeButton
              onClick={openUploadDocumentDialog}
              startIcon={<Plus size={20} />}
              size="small"
              color="WHITE"
              data-cy="button-overview-create-new"
            >
              Add new
            </ThemeButton>
          </Box>
        </PageSubTitle>
        {loading && !documents?.length ? (
          <Fragment>
            {[...Array(homeProductsMaxLength).keys()].map(key => (
              <CardContainer key={key} padding={1.5} mt={1}>
                <ProductSummarySkeleton loading />
              </CardContainer>
            ))}
          </Fragment>
        ) : (
          <Box data-cy="home-document-container">
            {documents.slice(0, homeProductsMaxLength).map(document => (
              <ItemCardSmall
                key={document.id}
                onCardClick={() => openDocument(document.url)}
                onClickOpen={() => openDocument(document.url)}
                onClickEdit={() => openEditDocumentDialog(document)}
                onClickDelete={() => setShowDeleteAlertForId(document.id)}
              >
                <DocumentIntroTitle document={document} />
              </ItemCardSmall>
            ))}
          </Box>
        )}
      </Box>
      <AlertDialog
        onClose={() => setShowDeleteAlertForId(undefined)}
        title="Delete document"
        open={!!showDeleteAlertForId}
        text="Are you sure you want to delete this document?"
        onSubmit={() => {
          if (showDeleteAlertForId)
            handleDeleteDocuments({ variables: { ids: [showDeleteAlertForId] } });
        }}
        onCancel={() => setShowDeleteAlertForId(undefined)}
        submitText="Yes, delete"
      />
    </React.Fragment>
  );
};

export default HomeDocumentOverview;
