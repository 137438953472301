import { gql } from '@apollo/client';

export const LOGIN = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) @client
  }
`;

export const TOKENIZED_LOGIN = gql`
  mutation TokenizedLogin($token: String!) {
    tokenizedLogin(token: $token) @client
  }
`;

export const REGISTER_INVITED = gql`
  mutation RegisterInvited(
    $token: String!
    $password: String!
    $input: InvitedUserRegistrationInput!
  ) {
    registerInvited(token: $token, password: $password, input: $input) @client
  }
`;

export const REGISTER_INVITED_PARTNER = gql`
  mutation RegisterInvitedPartner($input: PartnerRegistrationInput!) {
    registerInvitedPartner(input: $input)
  }
`;

export const LOGOUT = gql`
  mutation Logout {
    logout @client
  }
`;

export const UPDATE_PASSWORD = gql`
  mutation UpdatePassword($oldPassword: String!, $newPassword: String!) {
    updatePassword(oldPassword: $oldPassword, newPassword: $newPassword) @client
  }
`;

export const SEND_PASSWORD_RESET_MAIL = gql`
  mutation SendPasswordResetMail($email: String!) {
    sendPasswordResetMail(email: $email) @client
  }
`;

export const RESEND_USER_VERIFICATION_MAIL = gql`
  mutation ResendVerificationEmail($email: String!) {
    resendVerificationEmail(email: $email)
  }
`;

export const RESEND_INVITE_MAIL = gql`
  mutation ResendInviteEmail($email: String!) {
    resendInviteEmail(email: $email)
  }
`;
