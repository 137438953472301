import React, { FC } from 'react';
import { styled } from '@mui/material/styles';
import Icon from 'designSystem/Primitives/Icon/Icon';
import { ITableSettings } from 'types/dataImport.types';

export interface IHeaderColumnProps {
  title: string;
  subtitle?: string;
  settings?: ITableSettings;
}

const SubHeader = styled('span')(({ theme }) => ({
  color: theme.custom.themeColors.grayScale[60],
}));

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
}));

const Title = styled('span')(() => ({
  overflowX: 'hidden',
  textOverflow: 'ellipsis',
}));

const HeaderColumn: FC<IHeaderColumnProps> = ({ title, subtitle }) => {
  return (
    <Container>
      <Title>
        {title} {subtitle && <SubHeader>{subtitle}</SubHeader>}
      </Title>
      <Icon name="drag-handle" color="gray-60" />
    </Container>
  );
};

export default HeaderColumn;
