import React, { FC } from 'react';
import { styled } from '@mui/material/styles';
import { Fade, Zoom, Box } from '@mui/material';
import { TextControlBar } from 'components/WelcomeSlides';
import {
  PageContainer,
  Headline,
  Item,
  ItemIcon,
  ItemIconContainer,
  ItemText,
  ContentContainer,
  ImageContainer,
} from './styles';
import pulseLine from 'assets/img/welcomeSlides/pulse-line-2.svg';
import { ThemeTypography } from 'designSystem';
import tracing from 'assets/img/welcomeSlides/icon_tracing.svg';
import Tracing from 'assets/img/welcomeSlides/supply-chains.png';

interface TracingGetStartedProps {
  active: boolean;
}

const StyledPulse = styled('img')(({ theme }) => ({
  position: 'absolute',
  left: theme.spacing(0),
  width: '380px !important',
  bottom: 35,
}));

const TracingImage = styled('img')(() => ({
  width: '380px !important',
}));

const TracingGetStarted: FC<TracingGetStartedProps> = ({ active }) => {
  return (
    <>
      <PageContainer>
        <Zoom in={active}>
          <ContentContainer>
            <Box mb={1.5}>
              <Item>
                <ItemIconContainer>
                  <ItemIcon width={26} src={tracing} alt="Supply chain tracing icon" />
                </ItemIconContainer>
                <ItemText dangerouslySetInnerHTML={{ __html: 'Supply chain <br /> tracing' }} />
              </Item>
            </Box>
            <Headline variant="TITLE_LARGE">Get started</Headline>
            <ThemeTypography variant="TITLE_EXTRA_SMALL">
              Manage your supply chains and their components with ease. Overview and re-use a
              flexible library of supply chain information.
            </ThemeTypography>
            <Zoom in={active}>
              <StyledPulse src={pulseLine} alt="pulse" />
            </Zoom>
          </ContentContainer>
        </Zoom>
        <Fade in={active} timeout={400}>
          <ImageContainer alignItems="flex-end">
            <TracingImage src={Tracing} />
          </ImageContainer>
        </Fade>
      </PageContainer>
      <TextControlBar />
    </>
  );
};

export default TracingGetStarted;
