import React, { useState, useEffect, FC, PropsWithChildren } from 'react';
import { Fade } from '@mui/material';

const FadeMount: FC<PropsWithChildren> = ({ children }) => {
  const [fadeIn, setFadeIn] = useState(false);

  useEffect(() => {
    setFadeIn(true);

    return () => setFadeIn(false);
  }, []); //eslint-disable-line

  return (
    <Fade in={fadeIn}>
      <div>{children}</div>
    </Fade>
  );
};

export default FadeMount;
