import { IActivityFormValue } from 'constants/schemas/activities.schema';
import { IChainActivityFormValue } from 'constants/schemas/chains.schema';
import omit from 'lodash/omit';

export const extractActivityMutationValues = (
  values?:
    | IChainActivityFormValue
    | {
        activity?: IActivityFormValue;
      }
) => ({
  ownedById: values?.activity?.ownedBy?.id || null,
  componentId: values?.activity?.component?.id || null,
  siteId: values?.activity?.site?.id || null,
  ...omit(values?.activity, [
    'id',
    'ownedBy',
    'component',
    'langs',
    'cultivatedAreas',
    'modifiedTimestamp',
    'createdTimestamp',
    'site',
  ]),
});
