import React, { useEffect, useState } from 'react';
import { appQueryParams } from 'constants/appQueryParams';
import { useQueryParam, NumberParam, BooleanParam } from 'use-query-params';
import { useCompanyPlanContext } from 'contexts/CompanyPlanContext';
import { useMutation } from '@apollo/client';
import { UPDATE_ONBOARDING } from 'graphql/mutations';
import { useOnboardingTour, useUserData } from 'components/hooks';
import { PREFIX_CREATE_SUB_CHAIN_BTN } from 'components/ChainMapping/components/Activity';
import { EXAMPLE_COMPONENT_CHAIN_ACTIVITIES_STEP_3 } from 'constants/onboarding/mockComponentChainResponses';
import { CustomerPlan } from 'types/company.types';

const useComponentChainOnboardingSteps = () => {
  const [displayOnboardingTour, setOnboardingTour] = useQueryParam(
    appQueryParams.tour,
    BooleanParam
  );
  const [onboardingTourStep, setOnboardingTourStep] = useQueryParam(
    appQueryParams.step,
    NumberParam
  );
  const { plan } = useCompanyPlanContext();
  const isPartner = plan === CustomerPlan.FREEMIUM;

  const [highlightedActivity, setHighlightedActivity] = useState<string | undefined>(undefined);
  const [updateOnboarding] = useMutation(UPDATE_ONBOARDING);
  const { startTour } = useOnboardingTour();
  const { user } = useUserData();

  /**
   * This is used to highlight the activity that the user should click on to create a sub-chain. We simulate a hover effect on the activity card.
   * The timeout is used to make sure the activity card is rendered and has an id before we simulate the hover effect.
   * */
  useEffect(() => {
    if (highlightedActivity && displayOnboardingTour && onboardingTourStep === 6) {
      setTimeout(() => {
        const element = document.getElementById(PREFIX_CREATE_SUB_CHAIN_BTN + highlightedActivity);
        if (element) {
          const clickEvent = new MouseEvent('mouseover', {
            bubbles: true,
            cancelable: true,
            view: window,
          });
          element.className += ' hovered';
          element.dispatchEvent(clickEvent);
        }
      }, 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [highlightedActivity, onboardingTourStep, displayOnboardingTour]);

  const welcomeStep = {
    action: () => setOnboardingTourStep(1),
    content: () => (
      <div>
        <b>Welcome to the Component chain mapping tool!</b>
        <p>
          This is where you can map the material flow of a component
          {!isPartner && ', or something else that makes up your end products'}. Let&apos;s take a
          quick tour to get you up to speed.
        </p>
      </div>
    ),
    style: {
      maxWidth: 420,
    },
  };

  const stepColumn = {
    content: () => (
      <div>
        <p>
          The columns are your <b>component chain steps</b>. They indicate the overall type of
          activity that happen in each step (E.g. Origin, Processing or Manufacturing). You can have
          as many steps of each type as you need.
        </p>
      </div>
    ),
    selector: '[data-tut="onboarding_tour_editor_step_column"]',
    action: () => setOnboardingTourStep(2),
    style: {
      top: -16,
    },
  };

  const stepActivity = {
    content: () => (
      <div>
        <p>
          The cards within each column are <b>activities </b>. Each card describes an activity that
          happens in the component chain, including
          {!isPartner && ' who is the partner that performs this activity,'} the material (output)
          component that gets created from this activity, and the physical location of this
          activity.
        </p>
      </div>
    ),
    selector: '[data-tut="onboarding_tour_editor_step_activity"]',
    resizeObservables: ['[data-tut="onboarding_tour_editor_step_activity"]'],
    action: () => setOnboardingTourStep(3),
    style: {
      top: -16,
    },
  };

  const stepMultipleActivities = {
    content: `See this simplified component chain example with two processing steps.  Activities have connections to other activities to map the material flow of your product components. The incoming connection for each activity is shown by the coloured connection points.`,
    selector: '[data-tut="onboarding_tour_editor_step_activities"]',
    resizeObservables: ['[data-tut="onboarding_tour_editor_step_activities"]'],
    action: () => setOnboardingTourStep(4),
    style: {
      top: -16,
    },
  };

  const stepImportChain = {
    content: () => (
      <div>
        <p>
          This section is where you manage your <b>sub-component chains</b>. A component chain can
          contain other component chains (E.g. When an ingredient is a mix of other ingredients).
          Component chains are optional, but you can import them from other component chains to
          re-use in several {isPartner ? 'component' : 'product'} flows and it will help you keep a
          clean structure.
        </p>
      </div>
    ),
    selector: '[data-tut="onboarding_tour_editor_step_import_chain"]',
    action: () => setOnboardingTourStep(5),
    style: {
      top: -16,
      maxWidth: 420,
    },
  };

  const stepCreateSubChain = {
    content: () => (
      <div>
        <p>
          From the activities you have already mapped, you can create new <b>component chains</b>.
          Just hover and click the top right corner of an activity to make a chain including the
          previous connections.
        </p>
        <p>
          This will help you keep a better structure in your supply chain mapping view, and allows
          you to re-use the component chains in your other product’s supply chains.
        </p>
      </div>
    ),
    selector: '[data-tut="onboarding_tour_editor_step_activities_big"]',
    action: () => {
      setHighlightedActivity(EXAMPLE_COMPONENT_CHAIN_ACTIVITIES_STEP_3[3]?.id);
      setOnboardingTourStep(6);
    },
    style: {
      top: -16,
      maxWidth: 420,
    },
  };

  const stepSubChain = {
    content:
      'This is an example of what a component chain could look like when you have added sub-component chains for different ingredients. You can drag to re-order your component chains and toggle to hide and show their contents.',
    selector: '[data-tut="onboarding_tour_editor_step_sub_chain"]',
    resizeObservables: ['[data-tut="onboarding_tour_editor_step_sub_chain"]'],
    action: () => setOnboardingTourStep(7),
    style: {
      maxWidth: 420,
    },
  };

  const stepChecklist = {
    content: () => (
      <div>
        <p>
          You can always see the recommended next step in the top banner, but feel free to map your
          component chain in the order that works best for you.
        </p>
        <p>
          <b>Happy mapping!</b>
        </p>
      </div>
    ),
    selector: '[data-tut="onboarding_tour_editor_step_by_step_list"]',
    resizeObservables: ['[data-tut="onboarding_tour_editor_step_by_step_list"]'],
    action: () => setOnboardingTourStep(8),
    style: {
      maxWidth: 420,
    },
  };

  const componentEditorSteps = [
    welcomeStep,
    stepColumn,
    stepActivity,
    stepMultipleActivities,
    stepImportChain,
    stepCreateSubChain,
    stepSubChain,
    stepChecklist,
  ];

  const handleCompleteOnboardingTour = () => {
    setOnboardingTourStep(undefined);
    setOnboardingTour(undefined);
    updateOnboarding({
      variables: {
        input: {
          flag: 'sawComponentChainTour',
        },
      },
    });
  };

  const startOnboardingTour = () => {
    setOnboardingTour(true);
    startTour({
      tourSteps: componentEditorSteps,
      amplitudeEvent: 'DID_COMPONENT_ONBOARDING_TOUR_EDITOR',
      onCompleted: handleCompleteOnboardingTour,
      firstStepButtonText: `Let's do it!`,
    });
  };

  useEffect(() => {
    if (user?.onboarding && !user.onboarding.sawComponentChainTour) {
      startOnboardingTour();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return {
    displayOnboardingTour,
    onboardingTourStep,
    handleStartOnboardingTour: startOnboardingTour,
  };
};

export default useComponentChainOnboardingSteps;
