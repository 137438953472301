import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  tooltip: {
    background: props => props.color,
    borderRadius: 4,
    padding: theme.spacing(1),
    fontWeight: 500,
    boxShadow: theme.shadows[5],
  },
  arrow: {
    color: props => props.color,
  },
}));

const CustomTooltip = ({
  title,
  disableHoverListener,
  children,
  open,
  onClose,
  onOpen,
  color,
  placement,
}) => {
  const classes = useStyles({ color });

  return (
    <Tooltip
      classes={classes}
      title={title}
      interactive
      placement={placement}
      disableFocusListener
      disableHoverListener={disableHoverListener}
      disableTouchListener
      arrow
      open={open}
      onClose={onClose}
      onOpen={onOpen}
    >
      {children}
    </Tooltip>
  );
};

CustomTooltip.defaultProps = {
  color: '#fff',
  placement: 'right',
};

CustomTooltip.propTypes = {
  title: PropTypes.any.isRequired,
  children: PropTypes.node.isRequired,
  disableHoverListener: PropTypes.bool,
  color: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  placement: PropTypes.string,
};

export default CustomTooltip;
