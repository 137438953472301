import { useQuery } from '@apollo/client';
import { Box, DialogContent, lighten, styled } from '@mui/material';
import { Loader } from 'components/Forms';
import { LevelPill } from 'components/ImpactClaims/ClaimShared';
import PartnerSummary from 'components/Partners/PartnerSummary/PartnerSummary';
import {
  CardContainer,
  DialogDefault,
  ImpactClaimBadge,
  ThemeButton,
  ThemeTypography,
} from 'designSystem';
import { GET_PARTNER_WITH_COMPANY_IMPACT_CLAIMS } from 'graphql/queries/partner.queries';
import React, { Fragment } from 'react';
import { AvailableSizes } from 'types/enums';
import { ImpactClaim } from 'types/impactClaim.types';
import { IBasePartnerCompany, IPartnerCompany } from 'types/partner.types';
import { removeGraphConnections } from 'utils/graphql.utils';

const ContentWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 1),
}));

const DeletionBox = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
  background: lighten(theme.custom.themeColors.accent[60], 0.6),
  borderRadius: theme.shape.borderRadius,
}));

interface Props {
  /**
   * This partner only contains the partner base values
   */
  partner: IBasePartnerCompany;
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

const PartnerDeleteDialog: React.FC<Props> = ({ open, onClose, partner, onSubmit }) => {
  const { data: fullPartnerData, loading } = useQuery<{ partner: IPartnerCompany }>(
    GET_PARTNER_WITH_COMPANY_IMPACT_CLAIMS,
    {
      variables: { id: partner.id },
    }
  );

  const impactClaims: ImpactClaim[] = fullPartnerData
    ? removeGraphConnections(fullPartnerData.partner.impactClaims)
    : [];

  if (loading) {
    return <Loader />;
  }

  return (
    <DialogDefault
      title="Delete partner"
      onClose={onClose}
      open={open}
      fullWidth
      maxWidth="sm"
      background="#fff"
      preventCloseOnBackground
    >
      <DialogContent>
        <ContentWrapper>
          <ThemeTypography variant="BODY_MEDIUM">
            <b>Are you sure you want to delete this partner from the library?</b> The partner data
            will be lost forever and the partner will not be able to access the platform anymore.
            Any documents this partner has shared with you will also not be accessible anymore.
          </ThemeTypography>

          <Box mt={3} mb={3}>
            <CardContainer padding={2} size={AvailableSizes.SMALL}>
              <PartnerSummary partner={partner} />
            </CardContainer>
          </Box>

          {impactClaims.length > 0 && (
            <Fragment>
              <ThemeTypography variant="BODY_MEDIUM">
                All partner level impact claims connected to this partner will also be deleted.
              </ThemeTypography>
              <Box mt={1}>
                <DeletionBox>
                  <Box>
                    <LevelPill claimLevel="P Level" title={partner.name} />
                  </Box>
                  <Box mt={2} display="flex" flexWrap="wrap">
                    {impactClaims.map(claim => {
                      return (
                        <Box key={claim.id} mb={1} mr={1}>
                          <ImpactClaimBadge impactClaim={claim}></ImpactClaimBadge>
                        </Box>
                      );
                    })}
                  </Box>
                </DeletionBox>
              </Box>
            </Fragment>
          )}

          <Box mt={2} display="flex" alignItems="center" justifyContent="flex-end">
            <ThemeButton size="large" color="BLUE_ICE" onClick={onClose}>
              Cancel
            </ThemeButton>
            <Box ml={2}>
              <ThemeButton
                size="large"
                color="YELLOW"
                onClick={onSubmit}
                data-testid="delete-partner-confirm"
              >
                Delete
              </ThemeButton>
            </Box>
          </Box>
        </ContentWrapper>
      </DialogContent>
    </DialogDefault>
  );
};

export default PartnerDeleteDialog;
