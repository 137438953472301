import useMessages from './useMessages';

const useClipboard = (successMessage: string) => {
  const { setSuccessMessage } = useMessages();

  return async (value: string) => {
    await navigator.clipboard.writeText(value);
    setSuccessMessage(successMessage);
  };
};

export default useClipboard;
