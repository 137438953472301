import axios from 'axios';
import qs from 'query-string';

const qrCodeResolvers = {
  Mutation: {
    updateQRDesign: async (_, variables, { client }) => {
      const {
        qrCodeDesign: { responseType, design },
      } = variables;
      const { config } = client.defaultOptions;

      const options = {
        method: 'POST',
        responseType: responseType,
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        data: qs.stringify(design),
        url: config.generateQrCodeGeneratorUrl(),
      };

      try {
        const { data } = await axios(options);
        return data;
      } catch (error) {
        throw new Error(error.message);
      }
    },
  },
};

export default qrCodeResolvers;
