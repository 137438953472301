import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { Box } from '@mui/material';
import { useEnableReinitialize } from 'components/Product/MultiStep/hooks';
import { FormikAutosave, AutoValidate } from 'components/Product/MultiStep';
import { useMutation } from '@apollo/client';
import { useMenu, useEditorState } from '../hooks';

const FormContainer = ({ initialValues, containerStyles = {}, schema, children }) => {
  const { enableReinitialize } = useEnableReinitialize();
  const { activeMenu } = useMenu();
  const [handleSave] = useMutation(activeMenu.mutation || {});
  const { lang } = useEditorState();

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={() => null}
      enableReinitialize={enableReinitialize}
      initialStatus={{
        lang,
      }}
    >
      {formikBag => (
        <Form>
          <Box {...containerStyles}>
            {typeof children === 'function' ? children(formikBag) : children}
          </Box>
          <FormikAutosave handleSave={activeMenu.mutation ? handleSave : null} />
          <AutoValidate />
        </Form>
      )}
    </Formik>
  );
};

FormContainer.propTypes = {
  containerStyles: PropTypes.object,
  initialValues: PropTypes.object.isRequired,
  schema: PropTypes.any,
  children: PropTypes.any,
};

export default FormContainer;
