import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { TextField } from 'formik-mui';
import { TextInputFileExtension } from 'components/Forms';
import { FieldWrapper, ThemeButton } from 'designSystem';
import { Grid, Box } from '@mui/material';

const ItemSchema = Yup.object().shape({
  fileName: Yup.string().required('This field is required'),
  attribution: Yup.string().notRequired(),
});

const LibraryItemEditForm = ({ fileName, attribution, onSubmit, onCancel }) => {
  return (
    <Formik
      initialValues={{
        fileName,
        attribution,
      }}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={ItemSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, submitForm }) => (
        <Form>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <FieldWrapper label="File Name">
                <Field
                  component={TextInputFileExtension}
                  fullWidth
                  name="fileName"
                  variant="outlined"
                />
              </FieldWrapper>
            </Grid>
            <Grid item xs={12}>
              <FieldWrapper label="Attribution/Copyright">
                <Field
                  component={TextField}
                  fullWidth
                  name="attribution"
                  variant="outlined"
                  placeholder="e.g. “Winter in town”, by David J, licensed under CC BY 2.0"
                />
              </FieldWrapper>
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="flex-end">
                <ThemeButton color="WHITE" size="large" onClick={onCancel}>
                  Cancel
                </ThemeButton>
                <Box pl={1}>
                  <ThemeButton
                    onClick={submitForm}
                    loading={isSubmitting}
                    size="large"
                    color="YELLOW"
                  >
                    Save
                  </ThemeButton>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

LibraryItemEditForm.defaultProps = {
  fileName: '',
  attribution: '',
};

LibraryItemEditForm.propTypes = {
  fileName: PropTypes.string,
  attribution: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default LibraryItemEditForm;
