import { gql } from '@apollo/client';
import { ACTIVITY_FRAGMENT } from 'graphql/fragments/activity.fragments';

export const CREATE_ACTIVITY = gql`
  mutation CreateActivity($input: CreateActivityInput!, $lang: Language) {
    createActivity(input: $input, lang: $lang) {
      activity {
        ...activityValues
      }
    }
  }
  ${ACTIVITY_FRAGMENT}
`;

export const UPDATE_ACTIVITY = gql`
  mutation UpdateActivity($id: UUID!, $input: UpdateActivityInput!, $lang: Language) {
    updateActivity(id: $id, input: $input, lang: $lang) {
      activity {
        ...activityValues
      }
    }
  }

  ${ACTIVITY_FRAGMENT}
`;

export const TRANSLATE_ACTIVITY = gql`
  mutation TranslateActivity($id: UUID!, $input: TranslationParams!) {
    translateActivity(id: $id, input: $input) {
      activity {
        ...activityValues
      }
    }
  }
  ${ACTIVITY_FRAGMENT}
`;

export const DELETE_ACTIVITIES = gql`
  mutation DeleteActivities($ids: [UUID]!) {
    deleteActivities(ids: $ids) {
      ids
    }
  }
`;
