import { useQuery } from '@apollo/client';
import { GET_PARTNER_REQUEST } from 'graphql/queries/partner.queries';
import { useParams } from 'react-router-dom';
import { GetPartnerRequestData } from 'graphql/queries/partner.queries';

const usePartnerRequest = () => {
  const { requestId } = useParams<{ requestId: string | undefined }>();
  const { data, loading, error } = useQuery<GetPartnerRequestData>(GET_PARTNER_REQUEST, {
    variables: {
      id: requestId,
    },
  });

  return {
    error,
    loading: loading,
    partnerRequest: data?.partnerRequest,
  };
};

export default usePartnerRequest;
