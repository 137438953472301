import GeoLocationAutocomplete, {
  IGeolocationAutocompleteProps,
} from 'designSystem/Inputs/GeoLocationAutocomplete/GeoLocationAutocomplete';
import { FieldProps, useField } from 'formik';
import React, { FC } from 'react';

type IGeolocationAutocompleteFieldProps = Omit<
  IGeolocationAutocompleteProps,
  'value' | 'onChange'
> &
  FieldProps<string>;

const GeoLocationAutocompleteField: FC<IGeolocationAutocompleteFieldProps> = ({
  field: { value, name },
  ...props
}) => {
  const [, , { setValue }] = useField<string>(name);

  return <GeoLocationAutocomplete defaultValue={value} {...props} onChange={setValue} />;
};

export default GeoLocationAutocompleteField;
