import { gql } from '@apollo/client';

export const GET_CONVERTED_IMAGE = gql`
  query GetConvertedImage($outputFormat: String!, $inputBase64: String!, $fileName: String!) {
    convertImage(outputFormat: $outputFormat, inputBase64: $inputBase64, fileName: $fileName)
      @client {
      url
    }
  }
`;
