import { gql } from '@apollo/client';
import { VIDEO_FRAGMENT } from 'graphql/fragments';

export const GET_VIDEOS = gql`
  query GetVideos {
    getVideos {
      edges {
        node {
          ...video
        }
      }
    }
  }
  ${VIDEO_FRAGMENT}
`;

export const GET_TRANSCODING_VIDEOS_CLIENT = gql`
  query GetTranscodingVideos {
    getTranscodingVideos @client(always: true) {
      ...video
    }
  }
  ${VIDEO_FRAGMENT}
`;

export const GET_VIDEOS_BY_ID = gql`
  query GetVideosById($ids: [UUID]!) {
    getVideosById(ids: $ids) {
      edges {
        node {
          ...video
        }
      }
    }
  }
  ${VIDEO_FRAGMENT}
`;
