import { ReactComponent as grey_1 } from './1_grey.svg';
import { ReactComponent as grey_2 } from './2_grey.svg';
import { ReactComponent as grey_3 } from './3_grey.svg';
import { ReactComponent as grey_4 } from './4_grey.svg';
import { ReactComponent as grey_5 } from './5_grey.svg';
import { ReactComponent as grey_6 } from './6_grey.svg';
import { ReactComponent as grey_7 } from './7_grey.svg';
import { ReactComponent as grey_8 } from './8_grey.svg';
import { ReactComponent as grey_9 } from './9_grey.svg';
import { ReactComponent as grey_10 } from './10_grey.svg';
import { ReactComponent as grey_11 } from './11_grey.svg';
import { ReactComponent as grey_12 } from './12_grey.svg';
import { ReactComponent as grey_13 } from './13_grey.svg';
import { ReactComponent as grey_14 } from './14_grey.svg';
import { ReactComponent as grey_15 } from './15_grey.svg';
import { ReactComponent as grey_16 } from './16_grey.svg';
import { ReactComponent as grey_17 } from './17_grey.svg';
import { ReactComponent as white_1 } from './1_white.svg';
import { ReactComponent as white_2 } from './2_white.svg';
import { ReactComponent as white_3 } from './3_white.svg';
import { ReactComponent as white_4 } from './4_white.svg';
import { ReactComponent as white_5 } from './5_white.svg';
import { ReactComponent as white_6 } from './6_white.svg';
import { ReactComponent as white_7 } from './7_white.svg';
import { ReactComponent as white_8 } from './8_white.svg';
import { ReactComponent as white_9 } from './9_white.svg';
import { ReactComponent as white_10 } from './10_white.svg';
import { ReactComponent as white_11 } from './11_white.svg';
import { ReactComponent as white_12 } from './12_white.svg';
import { ReactComponent as white_13 } from './13_white.svg';
import { ReactComponent as white_14 } from './14_white.svg';
import { ReactComponent as white_15 } from './15_white.svg';
import { ReactComponent as white_16 } from './16_white.svg';
import { ReactComponent as white_17 } from './17_white.svg';

export const grey = [
  grey_1,
  grey_2,
  grey_3,
  grey_4,
  grey_5,
  grey_6,
  grey_7,
  grey_8,
  grey_9,
  grey_10,
  grey_11,
  grey_12,
  grey_13,
  grey_14,
  grey_15,
  grey_16,
  grey_17,
];

export const white = [
  white_1,
  white_2,
  white_3,
  white_4,
  white_5,
  white_6,
  white_7,
  white_8,
  white_9,
  white_10,
  white_11,
  white_12,
  white_13,
  white_14,
  white_15,
  white_16,
  white_17,
];
