import { gql } from '@apollo/client';

export const QR_CODE_DESIGN_FRAGMENT = gql`
  fragment qrCodeDesignValues on ProductQrCodeDesign {
    qr_code_logo
    qr_code_pattern
    qr_code_rotation
    frame_template
    background_color
    foreground_color
    marker_bottom_template
    marker_bottom_inner_color
    marker_bottom_outer_color
    marker_left_template
    marker_left_inner_color
    marker_left_outer_color
    marker_right_template
    marker_right_inner_color
    marker_right_outer_color
  }
`;
