import { Box, styled } from '@mui/material';
import { FlexBox } from 'components/Structure';
import { ThemeButton, ThemeTypography } from 'designSystem';
import React, { FC } from 'react';

interface IComplianceGuideWelcomeProps {
  message: string;
  illustration: string;
  regulationUrl: string;
  onStartGuideClick: () => void;
}

const Container = styled(Box)(({ theme }) => ({
  height: '100%',
  borderRadius: 6,
  border: `1px solid ${theme.custom.colors.lightBorderColor}`,
  background: theme.custom.themeColors.white,
  display: 'flex',
  padding: theme.spacing(2),
  flexDirection: 'column',
  minHeight: '100%',

  '> img': {
    // TODO: move to image
    maxHeight: '50%',
  },
}));

const ComplianceGuideWelcome: FC<IComplianceGuideWelcomeProps> = ({
  message,
  illustration,
  regulationUrl,
  onStartGuideClick,
}) => {
  const handleViewRegulationClick = () => window.open(regulationUrl, '_blank');

  return (
    <Container justifyContent="center" alignItems="center">
      <ThemeTypography variant="TITLE_SMALL" color="BLUE_80">
        {message}
      </ThemeTypography>
      <Box mt={4} />
      <img src={illustration} alt="Compliance illustration" />
      <FlexBox mt={4}>
        <ThemeButton size="large" color="BLUE_ICE" onClick={handleViewRegulationClick}>
          View regulation
        </ThemeButton>
        <Box mr={2} />

        <ThemeButton size="large" color="YELLOW" onClick={onStartGuideClick}>
          Start guide
        </ThemeButton>
      </FlexBox>
    </Container>
  );
};

export default ComplianceGuideWelcome;
