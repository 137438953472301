import { useMutation } from '@apollo/client';
import { Box, Tooltip } from '@mui/material';
import { styled } from '@mui/styles';
import { useMessages, useUploadState } from 'components/hooks';
import { ConfirmationSlide, ProofSlide } from 'components/SliderForm';
import ThemeButton from 'designSystem/Buttons/ThemeButton/ThemeButton';
import { UPDATE_IMPACT_CLAIM } from 'graphql/mutations';
import React, { useEffect, useMemo, useState } from 'react';
import { impactLevelOptions } from '../constants/impactLevelOptions';

const StyledButton = styled(ThemeButton)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(4),
}));

function useEditClaim({ onClose, claim }) {
  const [currentSlide, setCurrentSlide] = useState(0);
  const { cleanFiles, handleCleanCreatedRecords } = useUploadState();
  const { setSuccessMessage, setErrorMessage } = useMessages();

  const selectedItem = claim.impactCatalogItem;
  const [documents, setDocuments] = useState(
    claim.attachments?.edges.map(({ node }) => node) || []
  );
  const [deletedDocuments, setDeletedDocuments] = useState([]);
  const [documentRequests, setDocumentRequests] = useState(
    claim.PartnerRequests?.edges.map(({ node }) => node) || []
  );
  const handleDelete = recordId => {
    setDeletedDocuments([...deletedDocuments, recordId]);
  };
  const handleDeleteRequest = requestId => {
    const updatedDocumentRequests = documentRequests.filter(
      documentRequest => documentRequest.id !== requestId
    );
    setDocumentRequests(updatedDocumentRequests);
  };
  const [openUpdateAlert, setOpenUpdateAlert] = useState(false);
  const claimIsUpdated = documents.length !== claim.attachments?.edges?.length;
  const impactLevelItem = claim?.impactLevelTitle;
  const selectedImpactLevel = useMemo(() => {
    return impactLevelOptions.find(level => level.value === claim?.impactLevel);
  }, [claim]);
  const [updateImpactClaim, { loading: isSubmitting }] = useMutation(UPDATE_IMPACT_CLAIM, {
    onCompleted: ({ updateImpactClaim: { impactClaim } = {} }) => {
      if (impactClaim?.status === 'UPDATED') {
        setSuccessMessage('Your draft has been successfully saved');
      } else {
        setSuccessMessage('Your claim was submitted and is waiting for approval');
      }
      onClose();
      setOpenUpdateAlert(false);
    },
    onError: () => setErrorMessage('There was an error submitting your claim'),
  });
  const onSubmit = async status => {
    await updateImpactClaim({
      variables: {
        id: claim.id,
        input: {
          impactCatalogItemId: selectedItem.id,
          attachments: documents.map(({ id }) => id),
          status: status,
          requestIds: documentRequests.map(({ id }) => id),
        },
      },
    });
  };

  const handleCloseDialog = () => {
    if (claimIsUpdated) {
      setOpenUpdateAlert(true);
    } else {
      onClose();
    }
  };

  const NextButton = (
    <StyledButton
      size="large"
      color="YELLOW"
      data-cy="claim-dialog-next-btn"
      onClick={() => setCurrentSlide(currentSlide + 1)}
    >
      Next
    </StyledButton>
  );

  const SLIDES = [
    {
      component: ProofSlide,
      button: NextButton,
      props: {
        selectedImpactClaim: selectedItem,
        title: 'Edit documentation',
        documents,
        deletedDocuments,
        handleDelete,
        handleDocumentsChange: setDocuments,
        selectedImpactLevel: selectedImpactLevel.value,
        impactLevelItem,
        handleDeleteRequest,
        documentRequests,
        setDocumentRequests,
      },
    },
    {
      component: ConfirmationSlide,
      button: (
        <Tooltip
          title={
            documents.length || documentRequests.length
              ? ''
              : 'You have to upload proof in order to submit your claim'
          }
        >
          <Box position="absolute" right={32} display="flex">
            <Tooltip
              title={
                documents.length
                  ? 'Update your edited claim without submitting it for approval. ' +
                    'If this claim is already approved it will become a draft and taken off your consumer facing pages,' +
                    ' until it is approved again. '
                  : ''
              }
            >
              <Box mr={1}>
                <ThemeButton
                  color="BLUE_ICE"
                  disabled={!documents.length && !documentRequests.length}
                  onClick={() => onSubmit('UPDATED')}
                  size="large"
                  data-cy="edit-claim-submit-btn"
                >
                  Update
                </ThemeButton>
              </Box>
            </Tooltip>
            <Tooltip
              title={
                documents.length
                  ? 'Update your edited claim and submitting it for approval. ' +
                    'If this claim is already approved it will become a draft and taken off your consumer facing pages,' +
                    ' until it is approved again. '
                  : documentRequests.length
                  ? 'You can send this claim for approval as soon as your requested documents have been uploaded by your partner, or if you remove the document requests from this claim.'
                  : ''
              }
            >
              <Box>
                <ThemeButton
                  color="YELLOW"
                  size="large"
                  disabled={!documents.length}
                  loading={isSubmitting}
                  onClick={() => onSubmit('REQUESTED')}
                  data-cy="edit-and-request-claim-submit-btn"
                >
                  Update and request
                </ThemeButton>
              </Box>
            </Tooltip>
          </Box>
        </Tooltip>
      ),
      props: {
        selectedImpactClaim: selectedItem,
        title: 'Submit impact claim',
        documents,
        selectedImpactLevel: selectedImpactLevel.value,
        impactLevelItem,
        documentRequests,
      },
    },
  ];

  useEffect(() => {
    return () => {
      cleanFiles();
      handleCleanCreatedRecords();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    SLIDES,
    currentSlide,
    setCurrentSlide,
    openUpdateAlert,
    setOpenUpdateAlert,
    onSubmit,
    handleCloseDialog,
  };
}

export default useEditClaim;
