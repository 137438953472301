import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CloseOutline } from '@styled-icons/evaicons-outline';
import { NumberInput } from 'components/Forms';
import ThemeButton from 'designSystem/Buttons/ThemeButton/ThemeButton';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

const ControlBar = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(2),
  backgroundColor: theme.palette.secondary.main,
  width: '100%',
}));

const StyledNumberInput = styled(props => <NumberInput {...props} />)(() => ({
  width: 120,

  '& input': {
    textAlign: 'end',
  },
}));

const Headline = styled(Typography)(() => ({
  color: 'white',
}));

const StyledCloseIcon = styled(CloseOutline)(({ theme }) => ({
  margin: theme.spacing(1),
}));

const ControlBarCropping = ({ onClose, onSubmit }) => {
  return (
    <ControlBar>
      <Box display="flex" alignItems="center">
        <Box mr={2.5}>
          <Headline variant="h6">Crop Image</Headline>
        </Box>
        <Box>
          <Field
            name="width"
            variant="outlined"
            type="number"
            unit="px"
            component={StyledNumberInput}
          />
          <StyledCloseIcon color="white" size={20} />
          <Field
            name="height"
            type="number"
            variant="outlined"
            unit="px"
            component={StyledNumberInput}
          />
        </Box>
      </Box>
      <Box display="flex">
        <Box mr={1.5}>
          <ThemeButton color="BLUE_ICE" onClick={onClose} size="large">
            Cancel
          </ThemeButton>
        </Box>
        <ThemeButton color="YELLOW" type="submit" onClick={onSubmit} size="large">
          Apply
        </ThemeButton>
      </Box>
    </ControlBar>
  );
};

ControlBarCropping.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ControlBarCropping;
