import { Autocomplete, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import ComponentChainPlaceholderImage from 'assets/img/components/component-chain-placeholder.svg';
import defaultPartnerImage from 'assets/img/partners/default-partner-image.png';
import { FlexBox } from 'components/Structure';
import PaperWithAddItem from 'designSystem/Inputs/Paper/Paper';
import Icon from 'designSystem/Primitives/Icon/Icon';
import React, { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import { IComponentItem } from 'types/component.types';
import { IBasePartnerCompany } from 'types/partner.types';
import AddEditComponentDialog from '../Dialogs/AddEditComponentDialog';
import useComponentItems from '../hooks/useComponentItems';

const Image = styled('img')<{ size: 'small' | 'medium' }>(({ theme, size }) => ({
  height: size === 'small' ? 14 : 17,
  width: size === 'small' ? 14 : 17,
  objectFit: 'contain',
}));

const StyledAutocomplete = styled(Autocomplete<IComponentItem>)({
  '& .MuiAutocomplete-inputRoot': {
    paddingLeft: '16px !important',
  },
  '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
    padding: 4,
  },
  '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"]':
    {
      padding: 4,
      '& .MuiAutocomplete-input': {
        fontSize: 12,
      },
    },
});

interface IComponentAutocompleteProps {
  defaultSelectedId?: string;
  placeholder?: string;
  size?: 'small' | 'medium';
  disabled?: boolean;
  /**
   * Will filter the components by the raw material id and only show the components that have this raw material
   */
  filterByRawMaterialId?: string;
  showFilterHintMessage?: boolean;
  /**
   * This will preselect the partner in the creation form
   */
  company?: IBasePartnerCompany;
  /**
   * If true, only the unassigned components and components of the partner will be shown
   */
  hideOtherPartnerComponents?: boolean;
  onSelect: (component: IComponentItem | null) => void;
}

const ComponentAutocomplete: FC<IComponentAutocompleteProps> = ({
  company,
  defaultSelectedId = null,
  placeholder = 'Type or select component',
  size = 'medium',
  disabled,
  filterByRawMaterialId,
  showFilterHintMessage,
  hideOtherPartnerComponents,
  onSelect,
}) => {
  const [selectComponentId, setSelectComponentId] = useState<string | null>(defaultSelectedId);
  const [selectedComponent, setSelectedComponent] = useState<IComponentItem | null>(null);
  const [createComponentDialogOpen, setCreateComponentDialogOpen] = useState<boolean>(false);
  const { components, loading, refetch } = useComponentItems();
  const isLoadingComponent = loading && selectComponentId;

  const filteredComponents = useMemo(() => {
    return components.filter(
      component =>
        !hideOtherPartnerComponents ||
        (hideOtherPartnerComponents &&
          (!component.ownedBy || !company || component.ownedBy.id === company.id) &&
          (!filterByRawMaterialId ||
            (filterByRawMaterialId &&
              component.rawMaterials.some(({ id }) => id === filterByRawMaterialId))))
    );
  }, [components, filterByRawMaterialId, hideOtherPartnerComponents, company]);

  const handleSelect = (event: ChangeEvent<unknown>, component: IComponentItem | null) => {
    setSelectedComponent(component);
    onSelect(component);
  };

  const handleCreateComponent = () => setCreateComponentDialogOpen(true);
  const handleCloseCreateComponentDialog = () => setCreateComponentDialogOpen(false);

  const handleNewComponentCreated = async (componentId: string) => {
    await refetch();
    setSelectComponentId(componentId);
  };

  /**
   * Waiting until the components list got updated and select the correct component as soon as it exists
   * Removing the selectedComponent from the temporary state variable after the component got created
   */
  useEffect(() => {
    if (selectComponentId) {
      setCreateComponentDialogOpen(false);
      const component = components.find(({ id }) => id === selectComponentId);
      if (!component) {
        // eslint-disable-next-line no-console
        console.error(`Component with id ${selectComponentId} not found`);
        return;
      }
      setSelectedComponent(component);
      onSelect(component);
      setSelectComponentId(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectComponentId, components]);

  /** Listen to the changes */
  useEffect(() => {
    if (!defaultSelectedId) {
      setSelectedComponent(null);
    } else {
      setSelectComponentId(defaultSelectedId);
    }
  }, [defaultSelectedId]);

  return (
    <>
      <StyledAutocomplete
        loading={loading}
        value={selectedComponent}
        options={filteredComponents}
        data-cy="component-autocomplete"
        onChange={handleSelect}
        disabled={disabled}
        size={size}
        PaperComponent={({ children }) => (
          <PaperWithAddItem
            title="Create new component"
            infoMessage={
              showFilterHintMessage && (hideOtherPartnerComponents || filterByRawMaterialId)
                ? `Showing only components that ${
                    hideOtherPartnerComponents ? 'have the same owner as this activity' : ''
                  } ${hideOtherPartnerComponents && filterByRawMaterialId ? 'and which' : ''} ${
                    filterByRawMaterialId
                      ? 'share the raw material of the selected site focus material'
                      : ''
                  }.`
                : undefined
            }
            onClick={handleCreateComponent}
          >
            {children}
          </PaperWithAddItem>
        )}
        getOptionLabel={option => option.title}
        renderOption={(props, option) => (
          <li {...props}>
            <FlexBox gap={2} data-cy="autocomplete-option">
              <FlexBox gap={1}>
                <Image
                  size={size}
                  loading="lazy"
                  src={option.image?.url || ComponentChainPlaceholderImage}
                />
                {option.title}
              </FlexBox>

              {option.ownedBy?.name && (
                <FlexBox gap={1}>
                  <Image
                    size={size}
                    loading="lazy"
                    src={option.ownedBy?.logo?.url || defaultPartnerImage}
                  />
                  {option.ownedBy.name}
                </FlexBox>
              )}

              <FlexBox gap={0.5}>
                <Icon name="raw-material" />
                {option.rawMaterials.reduce(
                  (prev, { title }) => (prev.length ? `${prev}, ${title}` : title),
                  ''
                )}
              </FlexBox>
            </FlexBox>
          </li>
        )}
        renderInput={params => (
          <TextField
            {...params}
            variant="outlined"
            placeholder={isLoadingComponent ? 'Loading...' : placeholder}
            onKeyPress={keyEvent => {
              // Prevents the form from being submitted on enter.
              if (keyEvent.key === 'Enter') {
                keyEvent.preventDefault();
              }
            }}
            data-cy="component-autocomplete-input"
            InputProps={{
              style: { height: 40 },
              ...params.InputProps,
              startAdornment: selectedComponent ? (
                <Image
                  size={size}
                  loading="lazy"
                  src={selectedComponent.image?.url || ComponentChainPlaceholderImage}
                />
              ) : (
                <Image size={size} src={ComponentChainPlaceholderImage} />
              ),
            }}
          />
        )}
      />

      {/* Workaround: This dialog needs to live here and NOT in the context since the callback is changing the state */}
      <AddEditComponentDialog
        open={createComponentDialogOpen}
        partner={company}
        title="Create component"
        onComponentCreated={handleNewComponentCreated}
        onClose={handleCloseCreateComponentDialog}
      />
    </>
  );
};

export default ComponentAutocomplete;
