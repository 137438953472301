import { IChainActivity, IChainStep, ISubChain } from 'types/chain.types';
import { ISerializedActivityLink, IPrevConnectionIds } from '../useChainMapping';
import uniq from 'lodash/uniq';

export const isInXBoundsOfSubChain = (
  boundingBoxPosition: { xMin: number; xMax: number },
  xIndex: number
) => boundingBoxPosition.xMin <= xIndex && boundingBoxPosition.xMax > xIndex;

export const isInYBoundsOfSubChain = (
  boundingBoxPosition: { yMin: number; yMax: number },
  YIndex: number
) => boundingBoxPosition.yMin <= YIndex && boundingBoxPosition.yMax > YIndex;

export const isInBoundsOfSubChain = (
  boundingBoxPosition: { xMax: number; xMin: number; yMax: number; yMin: number },
  pointPosition: { x: number; y: number }
) =>
  isInXBoundsOfSubChain(boundingBoxPosition, pointPosition.x) &&
  isInYBoundsOfSubChain(boundingBoxPosition, pointPosition.y);

export const getSubChainsInBoundsOfSteps = (stepIndex: number, subChains: ISubChain[]) =>
  subChains.filter(({ boundingBoxPosition }) =>
    isInXBoundsOfSubChain(boundingBoxPosition, stepIndex)
  );

/**
 * Warning: This will only work for the real activities of that chain
 * Activities in sub chains do not have the same stepId as the this chain
 */
export const getActivitiesInBoundsOfSteps = (stepId: string, activities: IChainActivity[]) =>
  activities.filter(({ chainStepId }) => chainStepId === stepId);

export const getStepFromXPosition = (xPosition: number, steps: IChainStep[]) => {
  if (!steps.length || xPosition >= steps.length) {
    return undefined;
  }
  return steps[xPosition];
};

/**
 * Returns the last item + 1 (either activity or sub chain) in the step. This is used to determine the y position of the edit button (if enabled
 * @param stepActivities the activities in the step
 * @param stepSubChains the sub chains in the step
 * @returns
 */
export const getLastStepActivityYIndex = (
  stepActivities: IChainActivity[],
  stepSubChains: ISubChain[]
) => {
  // Find the last activity based on the y indices
  const lastStepActivityYIndex =
    stepActivities.reduce(
      (prevYPosition, { pointPosition }) =>
        prevYPosition < pointPosition.y ? pointPosition.y : prevYPosition,
      -1
    ) + 1;
  // Check if there are sub chains in the step and if so, get the last sub chain's y position. Override the lastStepActivityYIndex if the sub chain's y position is greater
  return stepSubChains.reduce(
    (prevYPosition, { boundingBoxPosition: { yMax } }) =>
      yMax > prevYPosition ? yMax : prevYPosition,
    lastStepActivityYIndex
  );
};

/**
 * This function returns the previous step activity ids and sub chain ids that are connected to the current step activity
 * See ISerializedActivityLink
 */
export const buildPreviousConnectionIds = (
  stepActivityId: string,
  allStepActivityLinks: ISerializedActivityLink[]
): IPrevConnectionIds | undefined => {
  // Map through all the connected activities, add newly found connected once to the temporary id array and add the found activity to the new step array
  const filteredStepActivityLinkIds: string[] = [];

  let previousMatchedActivityIds = [stepActivityId];
  // Find all the activityIds previous to the current one
  while (previousMatchedActivityIds.length > 0) {
    const matchedActivityId = previousMatchedActivityIds.pop();
    if (!matchedActivityId) {
      continue;
    }

    // Find all the activityIds previous to the current one
    const previousStepActivitiesToCurrentStep = (
      allStepActivityLinks.filter(
        ({ sourceStepActivityId, targetStepActivityId }) =>
          !!sourceStepActivityId && matchedActivityId === targetStepActivityId
      ) as Required<ISerializedActivityLink>[]
    ).map(({ sourceStepActivityId }) => sourceStepActivityId);

    // Ensure uniqueness
    previousMatchedActivityIds = uniq([
      ...previousMatchedActivityIds,
      ...previousStepActivitiesToCurrentStep,
    ]);

    filteredStepActivityLinkIds.push(matchedActivityId);
  }

  // Check if the link goes back to an activity that is in the origin step
  const hasOriginActivity = filteredStepActivityLinkIds.some(id => {
    const linkIndex = allStepActivityLinks.findIndex(
      ({ targetStepActivityId }) => targetStepActivityId === id
    );
    return linkIndex !== -1
      ? allStepActivityLinks[linkIndex]?.sourceStepActivityId === undefined
      : false;
  });

  if (hasOriginActivity && filteredStepActivityLinkIds.length > 1) {
    return {
      // Ensure uniqueness, exclude the activities that are already in a sub chain, and use only the once that are included in the filtered ids
      stepActivityIds: uniq(
        allStepActivityLinks
          .filter(
            ({ sourceStepActivityId, targetStepActivityId, subChainId }) =>
              (!sourceStepActivityId ||
                filteredStepActivityLinkIds.includes(sourceStepActivityId)) &&
              filteredStepActivityLinkIds.includes(targetStepActivityId)
          )
          .map(({ targetStepActivityId }) => targetStepActivityId)
      ),
      // Ensure uniqueness, filter the once that are in a sub chain, and use only the once that are included in the filtered ids
      subChainIds: uniq(
        (
          allStepActivityLinks.filter(
            ({ sourceStepActivityId, targetStepActivityId, subChainId }) =>
              !!subChainId &&
              !sourceStepActivityId &&
              filteredStepActivityLinkIds.includes(targetStepActivityId)
          ) as Required<ISerializedActivityLink>[]
        ).map(({ subChainId }) => subChainId)
      ),
    };
  }

  return undefined;
};
