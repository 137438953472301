import { Checkbox, FormHelperText } from '@mui/material';
import { styled } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { getIn } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const Container = styled('div')(() => ({
  position: 'relative',
  marginLeft: -5,

  '& .Mui-error': {
    bottom: 0,
    position: 'absolute',
    transform: 'translateY(100%)',
    marginLeft: 5,
  },
}));

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const StyledCheckbox = styled(({ inverted, hovered, ...props }) => <Checkbox {...props} />)(
  ({ theme, hovered, inverted, checked }) => {
    let defaultStyles = {
      padding: 5,
      backgroundColor: inverted && hovered && theme.custom.colors.blueFrost,

      '&:hover': {
        backgroundColor: inverted && theme.custom.colors.blueFrost,
      },
    };

    if (checked) {
      defaultStyles.backgroundColor = 'transparent';
    }

    return defaultStyles;
  }
);

const Text = styled('span')(({ theme }) => ({
  paddingLeft: theme.spacing(0.5),
}));

const useStyles = makeStyles(theme => ({
  icon: {
    borderRadius: 2,
    border: '2px solid',
    borderColor: theme.palette.grey[900],
    width: 16,
    height: 16,
    backgroundColor: '#fff',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: theme.custom.colors.blueFrost,
    borderColor: 'transparent',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',

    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      marginTop: -2,
      marginLeft: -2,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: theme.custom.colors.blueFrost,
    },
  },
}));

const CheckboxField = ({ field, form, childComponent, inverted, className = '', ...other }) => {
  const { name, value } = field;
  const { errors, touched } = form;
  const fieldError = getIn(errors, name);
  const showError = getIn(touched, name) && typeof fieldError === 'string';
  const [hovered, setHovered] = useState(false);
  const classes = useStyles();

  return (
    <Container
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      className={className}
    >
      <StyledCheckbox
        checkedIcon={<span className={`${classes.icon} ${classes.checkedIcon}`} />}
        icon={<span className={classes.icon} />}
        color="secondary"
        checked={value}
        inverted={inverted}
        hovered={hovered}
        {...field}
        {...other}
      />
      <Text>{childComponent}</Text>
      {showError && <FormHelperText error>{fieldError}</FormHelperText>}
    </Container>
  );
};

CheckboxField.propTypes = {
  inverted: PropTypes.bool,
  childComponent: PropTypes.node,
};

export default CheckboxField;
