import { Dialog, DialogContent, IconButton, Tab, Tabs } from '@mui/material';
import { styled } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Plus } from '@styled-icons/bootstrap/Plus';
import { Close } from '@styled-icons/evaicons-solid/Close';
import { useLogEventOnMount, useUploadState } from 'components/hooks';
import Library from 'components/MediaLibrary/Library';
import LibraryTab, { useTabStyles } from 'components/MediaLibrary/LibraryTab';
import UploadDropZone from 'components/MediaLibrary/UploadDropZone';
import { DialogTitle } from 'designSystem';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import LibraryActionButtons from './LibraryActionButtons';
import { SelectableProvider } from './Contexts/SelectableContext';

const CloseButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.primary,
  position: 'absolute',
  top: theme.spacing(1),
  right: theme.spacing(1),
  zIndex: 1,
}));

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  background: '#fff',
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(3),
}));

const StyledDialog = styled(Dialog)(() => ({
  '& .MuiDialog-paperFullScreen': {
    maxHeight: 800,
  },
}));

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  height: 750,
  padding: theme.spacing(3),
  paddingTop: 0,
  background: '#fff',
}));

const DialogContentInnerWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
}));

const ScrollContainer = styled('div')(({ theme }) => ({
  flex: 1,
  overflow: 'auto',
  borderRadius: theme.spacing(2),
  borderTopLeftRadius: 0,
  padding: theme.spacing(2),
  background: theme.custom.colors.backgroundMedium,
}));

const useStyles = makeStyles({
  topScrollPaper: {
    alignItems: 'flex-start',
  },
});

const LibraryModal = ({
  open,
  onMediaSelect,
  onClose,
  selectableMedia,
  hideMediaIds,
  preventCleanFiles = false,
}) => {
  useLogEventOnMount('OPEN_MEDIA_LIBRARY');
  const classes = useTabStyles();
  const [activeTab, setActiveTab] = useState(0);
  const dialogClasses = useStyles();
  const { cleanFiles } = useUploadState();

  // Cleanup effect which makes sure any uploaded files are cleared from the state.
  // Should only run when unmounting the component
  // Used because we set `shouldCleanupFilesOnUnmount=false` on the uploadDropZone so we need to do the cleanup in this component.
  useEffect(() => {
    return () => {
      if (!preventCleanFiles) {
        cleanFiles();
      }
    };
  }, []); // eslint-disable-line

  return (
    <SelectableProvider
      onMediaSelect={onMediaSelect}
      selectableMedia={selectableMedia}
      setUploadActive={() => setActiveTab(1)}
      onClose={onClose}
    >
      <StyledDialog
        maxWidth="lg"
        fullWidth
        open={open}
        onClose={onClose}
        classes={{
          scrollPaper: dialogClasses.topScrollPaper,
        }}
      >
        <StyledDialogTitle icon={<Plus size={20} />}>Add Media</StyledDialogTitle>

        <CloseButton onClick={onClose}>
          <Close size={23} />
        </CloseButton>

        <StyledDialogContent>
          <DialogContentInnerWrapper>
            <Tabs
              className={classes.tabs}
              value={activeTab}
              onChange={(event, tab) => setActiveTab(tab)}
              textColor="primary"
            >
              <Tab className={classes.tab} label="Media Library" />
              <Tab className={classes.tab} label="Upload" />
            </Tabs>
            <ScrollContainer>
              <LibraryTab value={activeTab} index={0}>
                <Library windowScroll={false} withUploadModal={false} hideMediaIds={hideMediaIds} />
              </LibraryTab>
              <LibraryTab value={activeTab} index={1}>
                <UploadDropZone bgWhite shouldCleanupFilesOnUnmount={false} />
              </LibraryTab>
            </ScrollContainer>

            <LibraryActionButtons onClose={onClose} />
          </DialogContentInnerWrapper>
        </StyledDialogContent>
      </StyledDialog>
    </SelectableProvider>
  );
};

LibraryModal.propTypes = {
  open: PropTypes.bool,
  onMediaSelect: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  selectableMedia: PropTypes.arrayOf(PropTypes.string),
  hideMediaIds: PropTypes.arrayOf(PropTypes.string),
  preventCleanFiles: PropTypes.bool,
};

export default LibraryModal;
