import React from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ExclamationTriangle } from '@styled-icons/bootstrap/ExclamationTriangle';
import ThemeButton from 'designSystem/Buttons/ThemeButton/ThemeButton';

const Container = styled('div')<{ center: boolean }>(({ theme, center }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  color: theme.palette.text.secondary,
  fontWeight: 500,

  ...(center && {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate3d(-50%, -50%, 0)',
  }),
}));

interface Props {
  error?: unknown;
  center?: boolean;
  className?: string;
  action?: {
    label: string;
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | KeyboardEvent) => void;
  };
}

const ErrorState: React.FC<Props> = ({ error, center = true, className, action }) => {
  let displayError = '';

  if (error instanceof Error) displayError = error.message;
  if (typeof error === 'string') displayError = error;
  if (!error) displayError = 'Something went wrong fetching your data. Please reload.';

  return (
    <Container center={center} className={className}>
      <Box mb={1}>
        <ExclamationTriangle size={24} />
      </Box>
      {displayError}
      {action && (
        <Box mt={2}>
          <ThemeButton size="medium" onClick={action.onClick} color="BLUE_ICE">
            {action.label}
          </ThemeButton>
        </Box>
      )}
    </Container>
  );
};

export default ErrorState;
