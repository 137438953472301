import axios from 'axios';

const companyResolvers = {
  Query: {
    getFonts: async (_, __, { client }) => {
      const STORAGE_KEY = 'google-fonts';
      const { config } = client.defaultOptions;

      const googleFonts = JSON.parse(localStorage.getItem(STORAGE_KEY));

      if (googleFonts) return googleFonts;

      try {
        const res = await axios.get(config.generateGoogleFontsUrl());

        const fonts = res.data.items.map(({ family }) => family);
        localStorage.setItem(STORAGE_KEY, JSON.stringify(fonts));

        return fonts;
      } catch (error) {
        throw new Error(error.message);
      }
    },
  },
};

export default companyResolvers;
