import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthLayout, PasswordForgotForm } from 'components/Auth';
import { useMutation } from '@apollo/client';
import { SEND_PASSWORD_RESET_MAIL } from 'graphql/mutations';
import useAutoLogout from 'components/hooks/useAutoLogout';

const PasswordForgot = () => {
  const navigate = useNavigate();

  const [mailSent, setMailSent] = useState<boolean>(false);
  const [userMail, setUserMail] = useState<string | undefined>(undefined);

  // Logout user when still found
  useAutoLogout();

  const [resetMail] = useMutation<null, { email: string }>(SEND_PASSWORD_RESET_MAIL, {
    onCompleted: () => setMailSent(true),
    onError: () => setMailSent(true),
    fetchPolicy: 'no-cache',
  });

  const handleEmailSubmit = ({ email }: { email: string }) => {
    setUserMail(email);
    resetMail({ variables: { email } });
  };

  const routeLogin = () => {
    setUserMail(undefined);
    setMailSent(false);
    navigate('/login');
  };

  return (
    <AuthLayout title="Password Forgot">
      <PasswordForgotForm
        onSubmit={handleEmailSubmit}
        onRouteChange={routeLogin}
        email={userMail}
        success={mailSent}
      />
    </AuthLayout>
  );
};

export default PasswordForgot;
