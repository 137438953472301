import { useQuery } from '@apollo/client';
import { Box, FormHelperText, LinearProgress, Typography } from '@mui/material';
import { lighten, styled } from '@mui/material/styles';
import { CameraVideo } from '@styled-icons/bootstrap/CameraVideo';
import { Check } from '@styled-icons/bootstrap/Check';
import { ImageFill } from '@styled-icons/bootstrap/ImageFill';
import { useUploadItem } from 'components/hooks';
import { useSelectable } from 'components/MediaLibrary/hooks';
import { GET_IMAGE, GET_TRANSCODING_VIDEOS_CLIENT, GET_VIDEOS_BY_ID } from 'graphql/queries';
import truncate from 'lodash/truncate';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { MAX_MEDIA_RETRIES, MEDIA_RETRY_TIMEOUT } from 'config/mediaConfig';
import { ThemeTypography } from 'designSystem';

const ITEM_SIZE = 200;

const UploadListItemContainer = styled('div')(({ theme }) => ({
  border: `2px solid ${theme.palette.grey[200]}`,
  background: '#fff',
  borderRadius: theme.spacing(2),
  padding: theme.spacing(0, 2),
  height: ITEM_SIZE,
  width: ITEM_SIZE,
  marginBottom: theme.spacing(1),
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  overflow: 'hidden',

  '&.selected': {
    border: `2px solid ${theme.palette.secondary.main}`,
  },
}));

const ErrorUploadListItemContainer = styled(UploadListItemContainer)(({ theme }) => ({
  border: `1px solid ${lighten(theme.palette.error.main, 0.8)}`,
}));

const IconContainer = styled('div')(({ theme }) => ({
  color: theme.palette.grey[400],
  marginRight: theme.spacing(1),
}));

const IconImageContainer = styled('img')(({ theme }) => ({
  width: 30,
  height: 30,
  objectFit: 'cover',
  marginRight: theme.spacing(1),
  borderRadius: theme.spacing(1),
}));

const ErrorIconContainer = styled(IconContainer)(({ theme }) => ({
  color: lighten(theme.palette.error.main, 0.8),
}));

const FileName = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontWeight: 600,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  fontSize: '12px',
  '-webkit-line-clamp': 1,
  '-webkit-box-orient': 'vertical',
}));

const StatusWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  bottom: 20,
  left: 20,
  right: 20,
  border: `1px solid ${theme.palette.grey[200]}`,
  borderRadius: theme.spacing(1),
  padding: theme.spacing(1),
  background: '#fff',
}));

const ErrorMessage = styled(FormHelperText)(() => ({
  marginTop: -6,
}));

const StyledProgress = styled(LinearProgress)({
  height: 6,
});

const LoadingIconWrapper = styled('div')(({ theme }) => ({
  animation: 'enter 5s infinite',
  position: 'absolute',
  top: theme.spacing(3),
  left: 'calc(50% - 37px)',
}));

const LoadingIcon = styled('div')(({ theme }) => ({
  color: theme.palette.grey[300],
}));

const ErrorIconWrapper = styled(LoadingIconWrapper)(({ theme }) => ({
  color: lighten(theme.palette.error.main, 0.8),
}));

const UploadBackgroundImage = styled('img')(() => ({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  width: ITEM_SIZE,
  height: ITEM_SIZE,
  objectFit: 'cover',
}));

const UploadBackgroundVideo = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  width: ITEM_SIZE,
  height: ITEM_SIZE,
  backgroundColor: theme.palette.grey[200],
}));

const getMediaTypeIcon = mediaType => {
  switch (mediaType) {
    case 'image':
      return ImageFill;
    case 'video':
      return CameraVideo;
    default:
      return ImageFill;
  }
};

const checkIfImageExists = (imageUrl, onSucces, onError) => {
  const image = new Image();
  image.src = imageUrl;
  image.onload = () => {
    onSucces();
  };
  image.onerror = () => {
    onError();
  };
};

const UploadListItem = ({
  progress,
  uploadedItemUrl,
  fileName,
  icon: Icon,
  error,
  success,
  itemType,
  recordId,
}) => {
  const [retries, setRetries] = useState(0);
  const [imageLoadedSuccesfully, setImageLoadedSuccesfully] = useState(false);
  const showShimmerLoadingEffect = error || !success || !uploadedItemUrl || !imageLoadedSuccesfully;
  const selectionContext = useSelectable();

  const imageData = useQuery(GET_IMAGE, {
    skip: itemType === 'video' || !recordId,
    variables: {
      id: recordId,
    },
  });

  // TODO: Use GET SINGLE VIDEO get call once it's available in the backend.
  const videoData = useQuery(GET_VIDEOS_BY_ID, {
    skip: itemType === 'image',
    variables: {
      ids: [recordId],
    },
  });

  // Gets the videos that are  uploaded and still transcoding.
  const { data: { getTranscodingVideos: transcodingVideos = [] } = {} } = useQuery(
    GET_TRANSCODING_VIDEOS_CLIENT,
    {
      skip: itemType !== 'video',
    }
  );

  // Based on if there's transcoding videos, this will keep polling the get videos by id call.
  const { startPolling, stopPolling } = useQuery(GET_VIDEOS_BY_ID, {
    skip: !transcodingVideos.length,
    variables: {
      ids: transcodingVideos.map(({ id }) => id),
    },
  });

  // If the transcoding videos no longer contain any videos, this will stop the polling mechanism so videos won't be fetched
  useEffect(() => {
    if (transcodingVideos.length) startPolling(2500);
    else stopPolling();
  }, [transcodingVideos.length]); // eslint-disable-line

  const uploadItemImage = imageData?.data?.getImage;
  const uploadItemVideo = videoData?.data?.getVideosById.edges[0]?.node;

  const currentItemId = itemType === 'image' ? uploadItemImage?.id : uploadItemVideo?.id;

  const isSelected = selectionContext && selectionContext.selectedItem?.id === currentItemId;

  useEffect(() => {
    // UseEffect to try refetching the image if the initial fetching has failed.
    // Tries up to 5 times to get the image, otherwise it will fail and it will just show a loading animation.
    // The image sometimes is not available right away ( 403 ), so needs a few seconds to be able to properly load into the page.
    if (uploadedItemUrl && imageLoadedSuccesfully === false) {
      checkIfImageExists(
        uploadedItemUrl,
        () => {
          setImageLoadedSuccesfully(true);
        },
        () => {
          setTimeout(() => {
            if (retries < MAX_MEDIA_RETRIES) {
              setRetries(retries + 1);
            }
          }, MEDIA_RETRY_TIMEOUT);
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [retries, uploadedItemUrl]);

  return (
    <UploadListItemContainer
      className={isSelected ? 'selected' : 'unselected'}
      onClick={() => {
        if (selectionContext) {
          if (itemType === 'image' && uploadItemImage) {
            selectionContext.onSelect(uploadItemImage);
          }
          if (itemType === 'video' && uploadItemVideo) {
            selectionContext.onSelect(uploadItemVideo);
          }
        }
      }}
    >
      {/* The shimmer className makes the icon animate with a wave effect, check the source in src/styles/app.css */}
      <LoadingIconWrapper className={showShimmerLoadingEffect ? 'shimmer' : ''}>
        {((itemType === 'image' && !imageLoadedSuccesfully) || itemType === 'video') && (
          <LoadingIcon>
            <Icon size={75} />
          </LoadingIcon>
        )}
        {uploadItemVideo && uploadItemVideo.isTranscoding && (
          <ThemeTypography variant="BODY_LARGE">Processing...</ThemeTypography>
        )}
      </LoadingIconWrapper>

      {uploadedItemUrl && imageLoadedSuccesfully && (
        <UploadBackgroundImage key={retries} src={uploadedItemUrl} />
      )}

      {uploadItemVideo && uploadItemVideo.isTranscoding === false && (
        <UploadBackgroundVideo>
          <ReactPlayer
            url={`https://vimeo.com/${uploadItemVideo.vimeoId}`}
            width="100%"
            height="100%"
          />
        </UploadBackgroundVideo>
      )}

      <StatusWrapper>
        {uploadedItemUrl && imageLoadedSuccesfully ? (
          <IconImageContainer src={uploadedItemUrl} key={retries} />
        ) : (
          <IconContainer>
            <Icon size={28} />
          </IconContainer>
        )}
        <Box display="flex" flexGrow={1} flexDirection="column">
          <Box display="flex" justifyContent="space-between">
            <FileName variant="subtitle2">
              {truncate(fileName, {
                length: 15,
                separator: '',
              })}
            </FileName>
            {!success && <ThemeTypography variant="BODY_MEDIUM">{progress}%</ThemeTypography>}
            {success && (
              <Box>
                <Check size={16} />
              </Box>
            )}
          </Box>
          {!error && <StyledProgress color="secondary" variant="determinate" value={progress} />}
          {error && <ErrorMessage error>{error}</ErrorMessage>}
        </Box>
      </StatusWrapper>
    </UploadListItemContainer>
  );
};

const UploadErrorItem = ({ fileName, error, icon: Icon }) => {
  return (
    <div>
      <ErrorUploadListItemContainer>
        <ErrorIconWrapper>
          <Icon size={75} />
        </ErrorIconWrapper>

        <StatusWrapper>
          <ErrorIconContainer>
            <Icon size={20} />
          </ErrorIconContainer>
          <Box overflowX="hidden" display="flex" flexGrow={1} flexDirection="column">
            <FileName variant="subtitle2">{fileName}</FileName>
            <ErrorMessage error>{error}</ErrorMessage>
          </Box>
        </StatusWrapper>
      </ErrorUploadListItemContainer>
    </div>
  );
};

const FileUploadItem = ({ id }) => {
  const { data, url, progress, itemType, error, success, recordId, tempURL } = useUploadItem(id);
  const icon = getMediaTypeIcon(itemType);

  if (error) {
    return <UploadErrorItem fileName={data.name} error={error} icon={icon} />;
  }

  switch (itemType) {
    case 'video':
    case 'image': {
      return (
        <div>
          <UploadListItem
            error={error}
            progress={progress}
            fileName={data.name}
            icon={icon}
            success={success}
            uploadedItemUrl={tempURL || url}
            itemType={itemType}
            recordId={recordId}
          />
        </div>
      );
    }

    case 'rejected': {
      return <UploadErrorItem fileName={data.name} error={error} icon={icon} />;
    }

    default: {
      return null;
    }
  }
};

FileUploadItem.propTypes = {
  id: PropTypes.string.isRequired,
};

export default FileUploadItem;
