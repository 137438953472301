import { Box } from '@mui/material';
import React, { ReactNode } from 'react';
import { OverviewHeaderContainer, OverviewImage } from './OverviewHeader.styles';
import Image from 'designSystem/DataDisplay/Image/Image';
import { ImageVariant } from 'types/media.types';

interface OverviewHeaderProps {
  image?: ImageVariant | null;
  imageUrl?: string;
  children: ReactNode;
}

const OverviewHeader: React.FC<OverviewHeaderProps> = ({ imageUrl, image, children }) => {
  return (
    <OverviewHeaderContainer>
      <Box display="flex">
        {image && <Image image={image} width={130} height={130} />}
        {imageUrl && !image && <OverviewImage backgroundImage={imageUrl} />}
        <Box ml={2} flex={1}>
          {children}
        </Box>
      </Box>
    </OverviewHeaderContainer>
  );
};

export default OverviewHeader;
