import React, { useState, useCallback } from 'react';
import {
  DragContainerBox,
  DragHandle,
  DragIcon,
  DragItemContainer,
} from './ContentBlockMenu.styles';
import { Container as DragContainer, Draggable } from 'react-smooth-dnd';
import MenuItem from '../MenuItem';
import { useLogEvent } from 'components/hooks';
import { move } from 'components/Product/MultiStep/utils';
import dragIcon from 'assets/img/icons/drag-handle.svg';

const DragAndDropMenu = ({ items, contentBlocks, setDirty, setContentBlocks, showValidation }) => {
  const [dragging, setDragging] = useState(false);
  const { logEvent } = useLogEvent();

  const onDrop = useCallback(
    dropResult => {
      if (!dropResult) return;
      const { removedIndex, addedIndex } = dropResult;

      if (removedIndex !== null && addedIndex !== null) {
        setDirty(true);
        setContentBlocks(move(contentBlocks, removedIndex, addedIndex));

        logEvent('MOVE_CONTENT_BLOCK');
      }
    },
    [contentBlocks] // eslint-disable-line
  );

  const onDragEnd = useCallback(() => {
    setDragging(false);
  }, [setDragging]);

  const onDragStart = useCallback(() => {
    setDragging(true);
  }, [setDragging]);

  return (
    <DragContainerBox dragging={dragging}>
      <DragContainer
        onDrop={onDrop}
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
        groupName="CONTENT_BLOCKS"
      >
        {items.map(item => {
          if (!item) {
            return null;
          }

          return (
            <Draggable key={item.id}>
              <DragItemContainer>
                <DragHandle className="drag-handle" data-cy-cb-drag-handle={item.contentType}>
                  <DragIcon src={dragIcon} alt="drag handle" />
                </DragHandle>
                <MenuItem
                  step={item.contentType}
                  title={item.title}
                  valid={item.validation?.valid}
                  disabled={!item.enabled}
                  showWarning={showValidation}
                  isContentBlock
                />
              </DragItemContainer>
            </Draggable>
          );
        })}
      </DragContainer>
    </DragContainerBox>
  );
};

export default DragAndDropMenu;
