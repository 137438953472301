import { ImageVariant } from 'types/media.types';
import { ISite } from 'types/site.types';
import { MediaItem } from 'types/media.types';
import * as Yup from 'yup';
import { createMediaSchema } from './shared';

export type IActivityFormValue = {
  id: string | null;
  title: string;
  description: string;
  site: ISite;
  mediaList?: MediaItem[];
  ownedBy?: {
    id: string;
    name: string;
    logo?: ImageVariant | null;
  } | null;
  component?: {
    id: string;
    title: string;
    rawMaterials: { id: string }[];
  } | null;
};

export const activitySchema = Yup.object().shape({
  id: Yup.string().nullable(),
  title: Yup.string().label('Activity detail').default('').required(),
  description: Yup.string().label('Description').default(''),
  site: Yup.object()
    .shape({
      id: Yup.string().required(),
      title: Yup.string().required(),
      siteType: Yup.string().required(),
      locationName: Yup.string().required(),
      ownedBy: Yup.object()
        .shape({
          id: Yup.string().required(),
          name: Yup.string().required(),
          logo: createMediaSchema('', false).nullable(),
        })
        .nullable()
        .default(null),
      locationCoordinates: Yup.object()
        .shape({
          lat: Yup.number(),
          lng: Yup.number(),
        })
        .typeError('Please add a location to the partner')
        .required('Location is a required field'),
      description: Yup.string().nullable().default(''),
    })
    .nullable()
    .default(null)
    .required(),
  mediaList: Yup.array().of(createMediaSchema('', false)).nullable().default([]),
  ownedBy: Yup.object()
    .shape({
      id: Yup.string().required(),
      name: Yup.string().required(),
      logo: createMediaSchema('', false).nullable(),
    })
    .nullable()
    .default(null),
  component: Yup.object()
    .shape({
      id: Yup.string().required(),
      title: Yup.string().required(),
      rawMaterials: Yup.array().of(Yup.object().shape({ id: Yup.string() })),
    })
    .nullable()
    .default(null),
}) as Yup.ObjectSchema<IActivityFormValue>;
