const mediaPolicies = {
  // ImageType: {
  //   fields: {
  //     crop: (value) => {
  //       if (value) return value;

  //       return {
  //         x: null,
  //         y: null,
  //         width: null,
  //         height: null,
  //       };
  //     },
  //   },
  // },
  Image: {
    fields: {
      uploadItemId: (id = '') => id,
    },
  },
  Video: {
    fields: {
      isTranscoding: (_, { readField }) => {
        return readField('transcodeStatus') === 'in_progress';
      },
    },
  },
  ImageVariant: {
    keyFields: ['imageId'],
  },
};

export default mediaPolicies;
