import { Input } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledInput = styled(Input)(({ theme }) => ({
  borderRadius: 4,
  position: 'relative',
  backgroundColor: '#fff !important',
  padding: theme.spacing(0.5),
  width: '100%',
  '& .MuiInputBase-input': {
    display: 'flex',
    flexWrap: 'wrap',
    paddingTop: 0,
    paddingBottom: 0,
    minHeight: 30,
    gap: theme.spacing(0.5),

    '&:focus': {
      backgroundColor: '#fff',
    },
  },
  '&:focus': {
    backgroundColor: '#fff',
  },
  '&:before': {
    content: 'none',
  },
  '&:after': {
    content: 'none',
  },
}));

export default StyledInput;
