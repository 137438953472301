import React, { FC } from 'react';

import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Plus } from '@styled-icons/bootstrap';

import SparkImage from 'assets/img/spark.png';

import { CardContainer, CardIllustration, ThemeButton } from 'designSystem';
import TableSkeleton from 'designSystem/DataDisplay/TableSkeleton/TableSkeleton';

import { AvailableSizes, BackgroundTypes, IllustrationTypes } from 'types/enums';

interface IDataImportOverviewCardProps {
  title: string;
  onAddTableClick: () => void;
}

const Spark = styled('img')(() => ({
  position: 'absolute',
  width: 60,
  height: 60,
  right: -32,
  top: -27,
}));

const DataImportOverviewCard: FC<IDataImportOverviewCardProps> = ({ title, onAddTableClick }) => {
  // Do not pass the mouse event
  const handleAddTableClick = () => onAddTableClick();

  return (
    <CardContainer
      title={title}
      size={AvailableSizes.LARGE}
      background={BackgroundTypes.WORLD}
      loading={false}
      renderIllustration={
        <CardIllustration image={IllustrationTypes.LOCATIONS} width={260} right={0} top={0} />
      }
    >
      <Box pb={2} mt="auto" position="relative" maxWidth="550px">
        <TableSkeleton maxWidth="550px" />
        <Spark src={SparkImage} />
      </Box>
      <ThemeButton onClick={handleAddTableClick} startIcon={<Plus size={20} />} size="large">
        Add new data set
      </ThemeButton>
    </CardContainer>
  );
};

export default DataImportOverviewCard;
