import { IconButton, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { EditPenIcon, PlusIcon } from 'assets/img/icons';
import { ThemeTypography } from 'designSystem';
import React, { FC, MouseEvent } from 'react';
import { Booleanish, booleanish } from 'types/booleanish.types';
import AbsoluteElement from './AbsoluteElement';
import useChainMappingConfig from './useChainMappingConfig';

export interface IGraphStep {
  id: string;
  x: number;
  height: number;
  title: string;
  // If not set edit is not enabled
  addButtonYIndices?: number[];
}

interface IStepProps extends IGraphStep {
  canAddStepBefore?: boolean;
  canEdit?: boolean;
  disableEdit?: boolean;
  onAddActivityClick: (stepId: string, targetYIndex: number) => void;
  onEditStepClick: (stepId: string) => void;
  onAddStepClick: () => void;
}

const Container = styled(AbsoluteElement)(({ theme }) => ({
  backgroundColor: theme.custom.themeColors.primary[5],
  borderRadius: 8,
  '&:hover .add-activity-btn': {
    display: 'flex',
  },
}));

const Header = styled(AbsoluteElement)<{ disabled: booleanish }>(({ theme, disabled }) => ({
  backgroundColor: theme.custom.colors.blueFrost,
  borderTopLeftRadius: 8,
  borderTopRightRadius: 8,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: disabled === 'true' ? 'default' : 'pointer',
  '& > *': {
    cursor: disabled === 'true' ? 'default' : 'pointer',
  },
  '&:hover .hover-btn, &:hover .add-step-btn': {
    display: 'flex',
  },
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  padding: 4,
  color: theme.palette.primary.main,
  backgroundColor: theme.custom.themeColors.primary[5],
  width: theme.spacing(2),
  height: theme.spacing(2),
  justifyContent: 'center',
  display: 'none',
  '&:hover': {
    backgroundColor: 'white',
  },
  '&.Mui-disabled': {
    backgroundColor: theme.custom.themeColors.primary[5],
  },
}));

const AddActivityIconButton = styled(IconButton)(({ theme }) => ({
  padding: 6,
  color: theme.palette.primary.main,
  backgroundColor: 'white',
  display: 'none',
  borderRadius: 4,

  '&:hover': {
    backgroundColor: 'white',
    color: theme.custom.colors.secondaryLighter,
  },
}));

const Step: FC<IStepProps> = ({
  id,
  x,
  height,
  title,
  addButtonYIndices,
  canEdit,
  canAddStepBefore,
  disableEdit,
  onAddActivityClick,
  onAddStepClick,
  onEditStepClick,
}) => {
  const { SPACING, HEADER_HEIGHT, COLUMN_WIDTH, NODE_HEIGHT } = useChainMappingConfig();

  // For the activity will always be added to the first possible index
  const handleAddActivityClick = () => onAddActivityClick(id, addButtonYIndices?.[0] || 0);
  const handleEditStepClick = () => onEditStepClick(id);
  const handleAddStepClick = (event: MouseEvent) => {
    event.stopPropagation();
    onAddStepClick();
  };

  return (
    <Container
      x={x}
      y={SPACING * 2}
      width={COLUMN_WIDTH}
      height={height - 4 * SPACING}
      data-tut="onboarding_tour_editor_step_column"
      data-cy="chain-mapping-step"
    >
      {/* Header container */}
      <Header
        width={COLUMN_WIDTH}
        height={HEADER_HEIGHT}
        disabled={Booleanish(!canEdit || disableEdit)}
        onClick={canEdit && !disableEdit ? handleEditStepClick : undefined}
      >
        <ThemeTypography autoOverflow maxWidth="80%" variant="BODY_MEDIUM_BOLD">
          {title}
        </ThemeTypography>

        {canAddStepBefore && (
          <AbsoluteElement x={-12} y={HEADER_HEIGHT / 2 - 8} width={16} height={16}>
            <Tooltip title="Add step">
              <StyledIconButton className="add-step-btn" onClick={handleAddStepClick} disableRipple>
                <PlusIcon width={8} />
              </StyledIconButton>
            </Tooltip>
          </AbsoluteElement>
        )}

        {canEdit && (
          <AbsoluteElement x={COLUMN_WIDTH / 2 - 12} y={-8} width={16} height={16}>
            <Tooltip title={disableEdit ? 'You cannot edit the origin step' : 'Edit step'}>
              {/* Normally tooltips are not shown when the button is disabled. Workaround to fix that */}
              <span>
                <StyledIconButton
                  disabled={disableEdit}
                  className="hover-btn"
                  data-cy="edit-step-btn"
                  onClick={handleEditStepClick}
                  disableRipple
                >
                  <EditPenIcon width={8} />
                </StyledIconButton>
              </span>
            </Tooltip>
          </AbsoluteElement>
        )}
      </Header>

      {/* Add new activity */}
      {addButtonYIndices?.length &&
        addButtonYIndices.map(yIndex => (
          <AbsoluteElement
            key={yIndex}
            x={COLUMN_WIDTH / 2 - 13}
            y={
              SPACING * 2 +
              HEADER_HEIGHT +
              NODE_HEIGHT * yIndex +
              2 +
              SPACING * 2 * yIndex +
              2 +
              NODE_HEIGHT / 2 -
              16
            }
            width={26}
            height={26}
          >
            <Tooltip title="Add activity">
              <AddActivityIconButton
                className="add-activity-btn"
                onClick={handleAddActivityClick}
                data-cy="add-activity-btn"
                disableRipple
              >
                <PlusIcon width={12} />
              </AddActivityIconButton>
            </Tooltip>
          </AbsoluteElement>
        ))}
    </Container>
  );
};

export default Step;
