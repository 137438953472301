import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  from,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { loadDevMessages, loadErrorMessages } from '@apollo/client/dev';
import { setContext } from '@apollo/link-context';
import { onError } from '@apollo/link-error';
import * as Sentry from '@sentry/browser';
import { CachePersistor } from 'apollo-cache-persist';
import Firebase from 'contexts/Firebase';
import { GET_INITIAL_STATE, GET_RESET_STATE } from 'graphql/queries';
import resolvers from 'graphql/resolvers';
import typeDefs from 'graphql/typeDefs';
import typePolicies from 'graphql/typePolicies';
import omitDeep from 'omit-deep-lodash';
import typeSerializers from './typeSerializers';
import { transformVariableTypes } from './utils';

const cache = new InMemoryCache({
  typePolicies,
});

const initialState = {
  drawerOpen: true,
};

const resetState = {
  settingsSidebarOpen: false,
};

export const cachePersistor = new CachePersistor({
  cache,
  debounce: 1000,
  // @ts-ignore Check later again why this is not typed correctly
  storage: window.localStorage,
});

if (process.env.NODE_ENV === 'development') {
  // Adds messages only in a dev environment
  loadDevMessages();
  loadErrorMessages();
}

const initializeApolloClient: (props: {
  firebase: Firebase;
  uri?: string;
  config: unknown;
}) => Promise<ApolloClient<NormalizedCacheObject>> = async ({ firebase, uri, config }) => {
  // write initial state so local queries dont error out
  cache.writeQuery({
    query: GET_INITIAL_STATE,
    data: initialState,
  });

  // restore state from localStorage
  await cachePersistor.restore();

  // reset state so e.g. create modal does not open
  cache.writeQuery({
    query: GET_RESET_STATE,
    data: resetState,
  });

  const httpLink = createHttpLink({ uri });

  const authLink = setContext(async (_, { headers }) => {
    let customHeaders = {};

    try {
      const token = await firebase.getFirebase().auth().currentUser?.getIdToken();

      customHeaders = {
        authorization: token ? `Bearer ${token}` : '',
      };
    } catch (e) {}

    return {
      headers: {
        ...headers,
        ...customHeaders,
      },
    };
  });

  // remove '__typename' property for mutations since input types do
  // not equal types in schema to avoid breaking mutations
  const omitTypenameLink = new ApolloLink((operation, forward) => {
    if (operation.variables) {
      operation.variables = omitDeep(operation.variables, '__typename');
    }

    return forward(operation);
  });

  const middlewareLink = new ApolloLink((operation, forward) => {
    if (operation.variables) {
      operation.variables = transformVariableTypes(operation.variables, typeSerializers);
    }

    return forward(operation);
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path }) => {
        const error = `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`;
        Sentry.captureMessage(error);
        return console.error(error);
      });
    }

    if (networkError) console.error(`[Network error]: ${networkError}`);
  });

  // @ts-ignore
  const link = from([errorLink, middlewareLink, omitTypenameLink, authLink.concat(httpLink)]);

  const apolloClient = new ApolloClient({
    cache,
    link,
    resolvers,
    typeDefs,
    connectToDevTools: process.env.NODE_ENV === 'development',
    defaultOptions: {
      // @ts-ignore This does not makes any sense here but it is still used somewhere
      firebase,
      config,
      watchQuery: {
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
      },
    },
  });

  return apolloClient;
};

export default initializeApolloClient;
