import { useMutation } from '@apollo/client';
import { Dialog, DialogContent, DialogTitle, Divider, Grid, IconButton } from '@mui/material';
import Alert from '@mui/material/Alert';
import { styled } from '@mui/material/styles';
import { Close } from '@styled-icons/evaicons-solid/Close';
import { useMessages } from 'components/hooks';
import { QRCode, QRDownloadButton } from 'components/Product/QRCode';
import ThemeButton from 'designSystem/Buttons/ThemeButton/ThemeButton';
import { SAVE_QR_DESIGN } from 'graphql/mutations';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import QRCodeForm from './QRCodeForm';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  padding: theme.spacing(4),
  overflow: 'visible',

  '& .MuiDialog-paperFullScreen': {
    maxWidth: 880,
    maxHeight: 620,
    overflow: 'visible',
  },
}));

const Footer = styled('div')(({ theme }) => ({
  position: 'absolute',
  display: 'flex',
  justifyContent: 'flex-end',
  bottom: 0,
  left: 0,
  right: 0,
  padding: theme.spacing(2, 3),
}));

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  padding: theme.spacing(2),
}));

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: theme.spacing(4),
  height: '100%',
  overflow: 'visible',
}));

const CloseButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.primary,
  position: 'absolute',
  top: theme.spacing(1),
  right: theme.spacing(1),
  zIndex: 1,
}));

const Container = styled('div')(() => ({
  height: '100%',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
}));

const QRContainer = styled('div')(() => ({
  height: '240px',
  width: '240px',
}));

const StyledWarning = styled(Alert)(({ theme, marginTop }) => ({
  marginTop: marginTop ? marginTop : theme.spacing(2),
  backgroundColor: theme.custom.themeColors.error[20],
}));

const CenteredGridItem = styled(Grid)(() => ({
  display: 'flex',
  justifyContent: 'center',
}));

const valuesToOutput = ({
  marker_bottom_template,
  marker_bottom_inner_color,
  background_color,
  foreground_color,
  marker_bottom_outer_color,
  qr_code_pattern,
  qr_code_logo,
}) => {
  return {
    qr_code_pattern,
    foreground_color,
    background_color,
    marker_bottom_template,
    marker_left_template: marker_bottom_template,
    marker_right_template: marker_bottom_template,
    marker_bottom_outer_color,
    marker_left_outer_color: marker_bottom_outer_color,
    marker_right_outer_color: marker_bottom_outer_color,
    marker_bottom_inner_color,
    marker_right_inner_color: marker_bottom_inner_color,
    marker_left_inner_color: marker_bottom_inner_color,
    qr_code_logo: qr_code_logo,
  };
};

const QRGeneratorModal = ({ open, onClose, qrCode, productTitle, productId }) => {
  const { setSuccessMessage, setErrorMessage } = useMessages();
  const [qrCodeDesign, setQrCodeDesign] = useState(qrCode);

  const handleChange = values => {
    const output = valuesToOutput(values);

    setQrCodeDesign({
      ...qrCode,
      design: {
        ...qrCode.design,
        ...output,
      },
    });
  };

  const [saveDesign] = useMutation(SAVE_QR_DESIGN, {
    onCompleted: () => {
      setSuccessMessage('QR code design successfully updated');
      onClose();
    },
    onError: () => {
      setErrorMessage('Something went wrong saving your QR design');
    },
  });

  const handleSave = values => {
    return saveDesign({
      variables: {
        id: qrCode.id,
        input: {
          design: {
            ...valuesToOutput(values),
          },
        },
      },
    });
  };

  return (
    <StyledDialog open={open} onClose={onClose} fullScreen>
      <CloseButton onClick={onClose}>
        <Close size={23} />
      </CloseButton>
      <StyledDialogTitle>Edit your QR Code</StyledDialogTitle>
      <StyledDialogContent>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <Container>
              <QRContainer>
                <QRCode productId={productId} qrCode={qrCodeDesign} />
              </QRContainer>
              <QRDownloadButton productId={productId} qrCode={qrCodeDesign} title={productTitle} />
              <StyledWarning severity="warning">
                Make sure the contrast of your QR code is high enough and always test before
                sharing!
              </StyledWarning>
              {qrCodeDesign.design.qr_code_logo !== 'no-logo' && (
                <StyledWarning marginTop={8} severity="warning">
                  Be aware that some Android phones might experience issues with scanning the QR
                  code with a logo in the middle.
                </StyledWarning>
              )}
            </Container>
          </Grid>
          <CenteredGridItem item xs={1}>
            <Divider orientation="vertical" />
          </CenteredGridItem>
          <Grid item xs={7}>
            <QRCodeForm onSubmit={handleSave} onChange={handleChange} qrCode={qrCode}>
              {({ submitForm, isSubmitting }) => (
                <Footer>
                  <ThemeButton color="WHITE" size="large" onClick={onClose}>
                    Cancel
                  </ThemeButton>
                  <ThemeButton
                    color="YELLOW"
                    size="large"
                    loading={isSubmitting}
                    type="submit"
                    onClick={submitForm}
                  >
                    Save design settings
                  </ThemeButton>
                </Footer>
              )}
            </QRCodeForm>
          </Grid>
        </Grid>
      </StyledDialogContent>
    </StyledDialog>
  );
};

QRGeneratorModal.propTypes = {
  open: PropTypes.bool,
  productTitle: PropTypes.string,
  productId: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  qrCode: PropTypes.object,
};

export default QRGeneratorModal;
