import { Box, DialogTitle, styled, DialogContent } from '@mui/material';
import { Edit } from '@styled-icons/boxicons-solid';
import useComponentChainActions from 'components/ComponentsLibrary/hooks/useComponentChainActions';
import { TopBackground } from 'components/Product/Create/styles';
import { InlineHelperText } from 'components/Product/InlineHelperText/InlineHelperText';
import { PageSubTitle } from 'components/Structure';
import { StyledDialog } from 'designSystem/Overlays/Dialog/DialogDefault/DialogDefault';
import React, { FC } from 'react';
import { IDefaultDialogProps } from 'types/dialog.types';
import EditComponentChainForm from '../Forms/EditComponentChainForm';
import { IBaseChain } from 'types/chain.types';
import { Cropping } from 'types/media.types';

export interface IEditComponentChainDialogProps extends IDefaultDialogProps {
  chain: IBaseChain;
}

const DialogTitleStyled = styled(DialogTitle)(() => ({
  zIndex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingRight: 40,
  paddingBottom: 0,
}));

export const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: theme.spacing(2, 5, 5, 5),
  overflow: 'visible',
  zIndex: 2,
  minWidth: 500,
}));

const EditComponentChainDialog: FC<IEditComponentChainDialogProps> = ({ open, chain, onClose }) => {
  const { updateComponentChain } = useComponentChainActions();

  const handleSubmit = async (values: {
    title: string;
    image: { imageId: string; crop?: Cropping };
    ownedById?: string;
  }) => {
    const partner = values.ownedById;
    await updateComponentChain({
      variables: {
        id: chain.id,
        input: { title: values.title, image: values.image, ownedById: partner || null },
      },
    });
    onClose?.();
  };

  return (
    <StyledDialog
      open={!!open}
      onClose={onClose}
      maxWidth="md"
      data-cy="edit-component-chain-dialog"
    >
      <DialogTitleStyled>
        <Box display="flex" alignItems="center">
          <Box mr={1}>
            <Edit size={16} />
          </Box>
          <PageSubTitle title="Edit component chain" />
        </Box>
      </DialogTitleStyled>
      <TopBackground />
      <StyledDialogContent>
        <Box display="flex" alignItems="center" mb={2}>
          <InlineHelperText
            variant="INFO"
            helperText="Component chains are sub-supply chains that can be re-used in multiple end product supply chains."
          />
        </Box>
        <EditComponentChainForm chain={chain} onSubmit={handleSubmit} onCancel={onClose} />
      </StyledDialogContent>
    </StyledDialog>
  );
};

export default EditComponentChainDialog;
