import React from 'react';
import PropTypes from 'prop-types';
import { FormHelperText } from '@mui/material';
import { styled } from '@mui/material/styles';
import { convertHexToRGBarray } from 'utils';

const Message = styled(FormHelperText)(({ theme }) => ({
  fontSize: 14,
  borderRadius: 4,
  color: theme.palette.error.main,
  padding: theme.spacing(2),
  backgroundColor: `rgba(${convertHexToRGBarray(theme.palette.error.main)}, 0.15)`,
}));

const InvalidReason = ({ children }) => {
  return (
    <Message error data-cy="invalid-reason">
      <b>Reason for rejection</b>: {children}
    </Message>
  );
};

InvalidReason.propTypes = {
  children: PropTypes.node.isRequired,
};

export default InvalidReason;
