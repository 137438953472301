import React from 'react';
import { PasswordResetForm } from 'components/Auth';
import { Container, SettingsHeader } from 'components/Settings';

const Security = () => {
  return (
    <Container>
      <SettingsHeader title="Password" />
      <PasswordResetForm />
    </Container>
  );
};

export default Security;
