import { gql } from '@apollo/client';
import { COMPANY_FRAGMENT, USER_FRAGMENT, MEMBER_FRAGMENT } from 'graphql/fragments';

export const GET_USER_DETAILS = gql`
  query GetUserDetails {
    user {
      ...userValues
    }
  }
  ${USER_FRAGMENT}
`;

export const GET_USER_LOGIN_DETAILS = gql`
  query GetUserLoginDetails {
    user {
      ...userValues
    }
    company {
      ...companyValues
    }
  }
  ${USER_FRAGMENT}
  ${COMPANY_FRAGMENT}
`;

export const GET_USERS = gql`
  query GetUsers {
    companyUsers {
      edges {
        cursor
        node {
          ...memberValues
        }
      }
    }
  }
  ${MEMBER_FRAGMENT}
`;

export const GET_INVITED_PARTNER_USER = gql`
  query GetInvitedPartnerUser($id: UUID!) {
    invitedPartnerCompanyUser(id: $id) {
      ...memberValues
    }
  }
  ${MEMBER_FRAGMENT}
`;
