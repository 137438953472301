import { gql } from '@apollo/client';
import { PAYMENT_FRAGMENT } from '../fragments/payment.fragments';

export const GET_PAYMENTS = gql`
  query GetPayments($first: Int!, $after: String) {
    payments(first: $first, after: $after) {
      edges {
        node {
          ...paymentValues
        }
      }
      summary {
        paymentCount
        payeeCount
        farmCount
        totalPaid
        totalPremiumPaid
        averagePayPerPayee
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${PAYMENT_FRAGMENT}
`;
