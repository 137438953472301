import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

const Container = styled(Box)(({ theme }) => ({
  position: 'relative',
  flex: '1',
  border: `${theme.custom.colors.lightBorderColor} 1px solid`,
  padding: theme.spacing(2),
  backgroundColor: theme.palette.common.white,
  borderRadius: 6,
}));

interface Props {
  children: React.ReactNode;
}

const BorderedContainer: React.FC<Props> = ({ children }) => {
  return <Container>{children}</Container>;
};

export default BorderedContainer;
