import { useLazyQuery, useMutation } from '@apollo/client';
import { AlertDialog } from 'designSystem';
import { useWindowFocus } from 'components/hooks';
import { LOGOUT } from 'graphql/mutations';
import { GET_FRONTEND_CONFIG } from 'graphql/queries';
import React, { createContext, FC, Fragment, PropsWithChildren, useEffect, useState } from 'react';
import useLocalStorage from 'react-use/lib/useLocalStorage';

const GOOGLE_BUILD_KEY = 'seedtrace-cms-google-build';
const FrontendVersionContext = createContext<null | string>(null);

const FrontendVersionProvider: FC<PropsWithChildren> = ({ children }) => {
  // States for backend stored google build id
  const [versionIsOutdated, setVersionIsOutdated] = useState(false);
  const [checkedOnce, setCheckedOnce] = useState(false);
  const [storedGoogleBuild, setStoredGoogleBuild] = useLocalStorage(GOOGLE_BUILD_KEY);
  const focused = useWindowFocus();

  const [refetch, { data }] = useLazyQuery<{ frontendConfig?: { frontendVersion: string } }>(
    GET_FRONTEND_CONFIG,
    {
      fetchPolicy: 'network-only',
    }
  );

  const [logout] = useMutation(LOGOUT);

  useEffect(() => {
    // Re-fetch when window just got focussed
    // and not on initial => the data will already be fetched once at the start
    if (focused) refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focused]);

  useEffect(() => {
    // Dont do anything if we can not fetch the frontend version
    if (!data?.frontendConfig?.frontendVersion) return;

    if (!checkedOnce) {
      // Initilizing, don't yet check if it's correct, we only want that on tab focus
      // Otherwise it will always force a user to refresh when a new version is released
      // But the user just opened the page, so no need for that here
      setCheckedOnce(true);
      setStoredGoogleBuild(data.frontendConfig.frontendVersion);
      return;
    }

    if (data?.frontendConfig?.frontendVersion !== storedGoogleBuild) {
      // Received a different version after focussing on the tab
      // New frontend version must be released in between
      setStoredGoogleBuild(data.frontendConfig.frontendVersion);
      setVersionIsOutdated(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleClickRefresh = () => {
    logout();
    window.location.reload();
  };

  return (
    <FrontendVersionContext.Provider value={data?.frontendConfig?.frontendVersion || null}>
      <Fragment>
        <AlertDialog
          title="A newer version has been detected"
          open={versionIsOutdated}
          text="We have released a new version of the Seedtrace app. Please reload to get all the new goodness!"
          onSubmit={handleClickRefresh}
          submitText="Refresh"
          data-cy="frontend-version-alert"
          onCancel={
            process.env.NODE_ENV === 'development' ? () => setVersionIsOutdated(false) : undefined
          }
        />
        {children}
      </Fragment>
    </FrontendVersionContext.Provider>
  );
};

export { FrontendVersionProvider, FrontendVersionContext };

export default FrontendVersionContext;
