import { Box, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Loader } from 'components/Forms';
import { GettingStarted, HomeProductOverview, HomeRequestsOverview } from 'components/Home';
import HomeAnalytics from 'components/Home/HomeAnalytics';
import Inspiration from 'components/Home/Inspiration';
import { HomeNextSteps } from 'components/Home/NextSteps';
import { DeniedRequestCard, ProvidedRequestCard } from 'components/Home/Requests';
import { useHomeData } from 'components/Home/hooks';
import usePartners from 'components/Partners/hooks/usePartners';
import { ErrorState, PageContainer, PageSubTitle, PageTitle } from 'components/Structure';
import { usePageTitle } from 'components/hooks';
import { appQueryParams } from 'constants/appQueryParams';
import { CardIllustration, EmptyStateCard } from 'designSystem';
import React, { Fragment, useState, FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { AvailableSizes, BackgroundTypes, IllustrationTypes, ItemTypes } from 'types/enums';

const SKIP_PARTNER_CARD_KEY = 'skipPartnerCard';

const Home: FC = () => {
  usePageTitle('Home');
  const theme = useTheme();
  const mediumScreen = useMediaQuery(theme.breakpoints.down('md'));
  const {
    loading,
    error,
    filteredProducts = [],
    productPublishedSingleLanguage,
    hasPublishedProduct = false,
    overallStatus,
    homeTitle,
    highlightedProduct,
    companyAnalyticsExist,
    showProductList,
    partnerRequests,
  } = useHomeData();
  const { partners, loading: loadingPartners } = usePartners();
  const navigate = useNavigate();
  const localSkipPartner = localStorage.getItem(SKIP_PARTNER_CARD_KEY);
  const [skipPartnerCard, setSkipPartnerCard] = useState(
    localSkipPartner ? JSON.parse(localSkipPartner) : false
  );

  // skip first engagement card on the home screen and save data in localStorage so it doesn't show after reload
  const handleSkipPartnerCard = () => {
    localStorage.setItem(SKIP_PARTNER_CARD_KEY, JSON.stringify(true));
    setSkipPartnerCard(true);
  };

  if (loading || loadingPartners) return <Loader />;

  if (error) return <ErrorState />;

  const displayPartnerCard = !partners.length && !skipPartnerCard && overallStatus === 'EMPTY';

  return (
    <Fragment>
      <PageContainer>
        <PageTitle title="Home" />
        {partnerRequests.length > 0 && (
          <Box mt={4}>
            <HomeRequestsOverview
              requests={partnerRequests}
              renderRequestCard={request => {
                if (request.requestStatus === 'PROVIDED')
                  return <ProvidedRequestCard request={request} key={request.id} />;

                if (request.requestStatus === 'DENIED')
                  return <DeniedRequestCard request={request} key={request.id} />;
              }}
              partnerUser={false}
            />
          </Box>
        )}

        <Box pt={2} pb={2}>
          <Box
            display="flex"
            flexDirection={mediumScreen ? 'column' : 'row'}
            data-cy="home-main-blocks"
            gap={3}
          >
            <Box display="flex" flexDirection="column" flex={1}>
              {hasPublishedProduct ? (
                <Fragment>
                  <PageSubTitle title="Next Steps" mb={2} />
                  <HomeNextSteps publishedProduct={productPublishedSingleLanguage} />
                </Fragment>
              ) : (
                <Fragment>
                  <PageSubTitle title="Getting Started" mb={2} />
                  {displayPartnerCard ? (
                    <EmptyStateCard
                      onCreateItemClick={() => {
                        navigate({ pathname: `/partners`, search: `?${appQueryParams.new}=1` });
                      }}
                      variant={ItemTypes.PARTNER}
                      title="First off, add your partners. It will lay the foundation for your tracing and transparency"
                      size={AvailableSizes.LARGE}
                      data-cy="partners-getting-started-home-card"
                      data-cy-button="create-partner-btn-getting-started"
                      background={BackgroundTypes.WORLD}
                      buttonText="Add partner"
                      onClickSecondaryButton={handleSkipPartnerCard}
                      secondaryButtonText="I will do that later"
                      renderIllustration={
                        <CardIllustration
                          image={IllustrationTypes.LOCATIONS}
                          width={300}
                          right={25}
                          top={0}
                        />
                      }
                    />
                  ) : (
                    <GettingStarted
                      products={filteredProducts}
                      overallStatus={overallStatus}
                      homeTitle={homeTitle}
                      highlightedProduct={highlightedProduct}
                      loading={loading}
                    />
                  )}
                </Fragment>
              )}
            </Box>

            {showProductList && (
              <HomeProductOverview loading={loading} products={filteredProducts} />
            )}
          </Box>
        </Box>

        {companyAnalyticsExist && <HomeAnalytics />}

        <Box pt={2} pb={2}>
          <PageSubTitle title="Inspiration" mb={2} />
          <Inspiration />
        </Box>
      </PageContainer>
    </Fragment>
  );
};

export default Home;
