import { AccordionDetails, AccordionSummary, Box } from '@mui/material';
import { ThemeTypography } from 'designSystem';
import { Accordion } from 'designSystem/DataDisplay/Accordion/Accordion';
import React, { FC } from 'react';
import { CatalogItemLinks, CatalogItemThirdParties } from '../ClaimCatalog';

interface IOtherClaimInformationProps {
  thirdParties?: string[];
  links?: string[];
}

const OtherClaimInformation: FC<IOtherClaimInformationProps> = ({
  thirdParties = [],
  links = [],
}) => {
  return (
    <Accordion transparent>
      <AccordionSummary has-border="false">
        <ThemeTypography variant="BODY_LARGE_BOLD" color="GRAY">
          More info
        </ThemeTypography>
      </AccordionSummary>
      <AccordionDetails>
        <Box>
          {thirdParties.length ? <CatalogItemThirdParties items={thirdParties} /> : null}
          {links.length ? <CatalogItemLinks items={links} /> : null}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default OtherClaimInformation;
