import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FileEarmarkText } from '@styled-icons/bootstrap';
import { ThemeTypography, Skeleton } from 'designSystem';
import React, { FC } from 'react';

interface IDocumentSkeletonProps {
  title?: string;
}

const DocumentIcon = styled(FileEarmarkText)(({ theme }) => ({
  marginRight: theme.spacing(1.5),
}));

const DocumentSkeleton: FC<IDocumentSkeletonProps> = ({ title }) => (
  <Box display="flex" alignItems="center">
    <DocumentIcon size={30} />
    <Box>
      <ThemeTypography variant="TITLE_EXTRA_SMALL">{title}</ThemeTypography>
      {!title && <Skeleton width={120} height={18} animation={false} />}
      <Box display="flex" alignItems="center">
        <Skeleton width={90} height={12} animation={false} />
      </Box>
    </Box>
    <Box ml="auto">
      <Skeleton width={160} height={30} animation={false} />
    </Box>
  </Box>
);

export default DocumentSkeleton;
