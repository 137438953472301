import { DialogContent } from '@mui/material';
import { styled } from '@mui/material/styles';
import { TextField } from 'formik-mui';

export const TopBackground = styled('div')(({ theme }) => ({
  position: 'absolute',
  zIndex: 0,
  top: 0,
  left: 0,
  right: 0,
  height: 157,
  background: theme.custom.colors.backgroundLight,
}));

export const GeneralSectionContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  background: '#fff',
  padding: theme.spacing(4),
  boxShadow: theme.custom.shadows[4],
  borderRadius: 6,
}));

export const TitleField = styled(TextField)(() => ({
  flex: 1,

  '& .MuiOutlinedInput-input': {
    fontSize: 16,
    fontWeight: 900,
  },
}));

export const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: theme.spacing(2, 5, 5, 5),
  overflow: 'visible',
  zIndex: 2,
}));

export const Spark = styled('img')(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(1),
  top: -34,
}));

export const ProductPlaceholderImage = styled('img')(() => ({
  width: 80,
}));
