import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Edit } from '@styled-icons/boxicons-solid/Edit';
import { LinkWithIcon } from 'components/Forms';
import { useConfig } from 'components/hooks';
import { ThemeTypography } from 'designSystem';
import React, { FC, Fragment } from 'react';
import FlagProgress from './FlagProgress';

interface IEnabledLanguagesProps {
  languageConfig: {
    default: string;
    enabled: string[];
  };
  publishedLanguages: string[];
  extended?: boolean;
  navigateToProductEditor?: (params: { language: string }) => void;
}

const LanguageContainer = styled(Box)<{ extended?: boolean }>(({ extended }) => ({
  display: 'flex',
  width: extended ? 'auto' : 100,
}));

const EnabledLanguages: FC<IEnabledLanguagesProps> = ({
  languageConfig,
  publishedLanguages,
  extended,
  navigateToProductEditor,
}) => {
  const config = useConfig();
  const showEditButton = languageConfig?.enabled.length > 1 && navigateToProductEditor;

  return (
    <LanguageContainer flexDirection={extended ? 'column' : 'row'} extended={extended}>
      {languageConfig?.enabled.map((lang, index) => {
        const { flag, title } = config.getLanguageConfig(lang);
        const published = publishedLanguages.includes(lang);
        const tooltip = published ? `${title}: Published` : `${title}: Draft`;
        const isDefault = languageConfig.default === lang;

        return (
          <Box display="flex" mb={extended ? 1 : 0} key={lang} data-cy={`lang-${lang}`}>
            <FlagProgress key={index} flag={flag} tooltip={tooltip} published={published} />
            {extended && (
              <Fragment>
                <ThemeTypography variant="BODY_LARGE" color={published ? 'GREEN' : 'GRAY'}>
                  {published ? 'PUBLISHED' : 'DRAFT'}
                </ThemeTypography>
                {isDefault && (
                  <Box mr={1} ml={0.5} display="flex" alignItems="center">
                    <ThemeTypography variant="LABEL_INPUT" color="GRAY">
                      (default)
                    </ThemeTypography>
                  </Box>
                )}
                {showEditButton && (
                  <LinkWithIcon
                    onClick={() =>
                      navigateToProductEditor({
                        language: lang,
                      })
                    }
                    Icon={Edit}
                    size={16}
                  >
                    Edit
                  </LinkWithIcon>
                )}
              </Fragment>
            )}
          </Box>
        );
      })}
    </LanguageContainer>
  );
};

export default EnabledLanguages;
