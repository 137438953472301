import { styled } from '@mui/material/styles';
import { CameraVideo } from '@styled-icons/bootstrap/CameraVideo';
import { CloudUpload } from '@styled-icons/bootstrap/CloudUpload';
import { ImageFill } from '@styled-icons/bootstrap/ImageFill';
import { UploadContainer } from 'components/FileUpload';
import { useLogEvent, useUploadState } from 'components/hooks';
import { useSelectable } from 'components/MediaLibrary/hooks';
import { AllowedFileType } from 'components/Structure';
import { ThemeTypography } from 'designSystem';
import partition from 'lodash/partition';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import UploadFileList from './UploadFileList';
import { SupportedFileTypes, FILE_MIME_TYPES } from 'components/FileUpload/Dropzone';

export const DropContainer = styled(({ children, ...rest }) => <div {...rest}>{children}</div>)(
  ({ theme }) => ({
    position: 'relative',
    display: 'flex',
    height: '250px',
    width: '100%',
    borderWidth: 2,
    borderRadius: theme.spacing(1),
    borderColor: theme.palette.grey[300],
    borderStyle: 'dashed',
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.grey[500],
    outline: 'none',
    transition: 'border .24s ease-in-out',

    '& .dropzone': {
      flex: 1,
      display: 'flex',
      height: '100%',
      flexDirection: 'column',
      alignItems: 'center',
      outline: 'none',
    },

    '&:hover': {
      cursor: 'pointer',
      borderColor: theme.custom.colors.blueFrost,
      '& *': {
        color: theme.custom.colors.blueFrost,
        fill: theme.custom.colors.blueFrost,
      },

      '& p': {
        color: `${theme.custom.colors.blueFrost} !important`,
      },
    },

    '&.bg-white': {
      background: '#fff',
    },
  })
);

const AllowedTypes = styled('div')(({ theme }) => ({
  position: 'absolute',
  display: 'flex',
  bottom: theme.spacing(5),
  left: '50%',
  transform: 'translateX(-50%)',
}));

const IconContainer = styled('div')(({ theme }) => ({
  color: theme.palette.grey[500],
  marginTop: theme.spacing(5),
  marginBottom: theme.spacing(2),
}));

const checkFileSize = ({ size, type }) => {
  type = type.split('/')[0];

  if ((type === 'image' && size < 2000000) || (type === 'video' && size < 1000000000)) {
    return true;
  }

  return false;
};

/**
 * @deprecated use the Dropzone component instead
 */
const UploadDropZone = ({ bgWhite, shouldCleanupFilesOnUnmount = true }) => {
  const { fileIDs, addFiles, cleanFiles, createdRecords } = useUploadState();

  const selectionContext = useSelectable();
  const { logEvent } = useLogEvent();

  // seems like its always image and video
  const selectableMedia = selectionContext?.selectableMedia || [
    SupportedFileTypes.IMAGE,
    SupportedFileTypes.VIDEO,
  ];

  const allowedMimeTypes = selectableMedia.reduce(
    (previous, current) => ({ ...previous, ...FILE_MIME_TYPES[current] }),
    {}
  );

  const onDrop = (acceptedFiles, fileRejections) => {
    let [checkedAcceptedFiles, rejectedAcceptedFiles] = partition(acceptedFiles, checkFileSize);

    const rejectedFiles = fileRejections.map(({ file }) => ({
      error: 'Invalid file type',
      file,
    }));

    rejectedAcceptedFiles = rejectedAcceptedFiles.map(file => ({
      error: 'File size too large',
      file,
    }));

    const finalAcceptedFiles = checkedAcceptedFiles.map(file => ({
      file,
      itemType: file.type.split('/')[0],
    }));

    addFiles(finalAcceptedFiles, [...rejectedFiles, ...rejectedAcceptedFiles]);

    logEvent('UPLOAD_FILE');
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: allowedMimeTypes,
  });

  useEffect(() => {
    // Cleanup effect which makes sure any uploaded files are cleared from the state.
    // Should only run when unmounting the component
    return () => {
      if (shouldCleanupFilesOnUnmount) {
        cleanFiles();
      }
    };
  }, []); // eslint-disable-line

  useEffect(() => {
    // Reads the createdRecords from the upload state ( which contains the newly created items directly from the api )
    // Then sets the last created record to be the selected record.
    if (createdRecords.length > 0) {
      selectionContext?.onSelect(createdRecords[createdRecords.length - 1]);
    }
  }, [createdRecords]); //eslint-disable-line

  const hasFiles = fileIDs.length > 0;

  return (
    <div>
      <DropContainer className={bgWhite ? 'bg-white' : 'default'}>
        <div
          {...getRootProps({
            className: 'dropzone',
          })}
        >
          <input {...getInputProps()} />
          <UploadContainer isDragActive={isDragActive} data-cy="upload-dropzone">
            <IconContainer>
              <CloudUpload size={40} />
            </IconContainer>
            <ThemeTypography color="GRAY" variant="BODY_LARGE">
              Click to upload media or drag and drop files here
            </ThemeTypography>

            <AllowedTypes>
              {selectableMedia.map(type => {
                if (type === 'video') {
                  return (
                    <AllowedFileType
                      key={`allowed-type-${type}`}
                      icon={<CameraVideo size={30} />}
                      title="Videos"
                      description=".mp4, .avi, .flv up to 100mb"
                    />
                  );
                }

                if (type === 'image') {
                  return (
                    <AllowedFileType
                      key={`allowed-type-${type}`}
                      icon={<ImageFill size={30} />}
                      title="Images"
                      description=".png, .jpg up to 2mb"
                    />
                  );
                }

                return null;
              })}
            </AllowedTypes>
          </UploadContainer>
        </div>
      </DropContainer>

      <UploadFileList hasFiles={hasFiles} fileIDs={fileIDs} />
    </div>
  );
};

UploadDropZone.propTypes = {
  bgWhite: PropTypes.bool,
  shouldCleanupFilesOnUnmount: PropTypes.bool,
};

export default UploadDropZone;
