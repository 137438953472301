import { useEffect } from 'react';

const usePasteHandler = (onPaste: (data: string) => void) => {
  useEffect(() => {
    const handlePaste = (event: ClipboardEvent) => {
      const pastedData = event.clipboardData?.getData('Text');
      if (pastedData) {
        onPaste(pastedData);
      }
    };

    document.addEventListener('paste', handlePaste);
    return () => {
      document.removeEventListener('paste', handlePaste);
    };
  }, [onPaste]);
};

export default usePasteHandler;
