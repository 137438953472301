import { useCompanyPlanContext } from 'contexts/CompanyPlanContext';
import { CustomerPlanType } from 'types/company.types';

const useHasAccess = (allowedPlans: CustomerPlanType[]) => {
  const { plan } = useCompanyPlanContext();

  return allowedPlans.includes(plan);
};

export default useHasAccess;
