import { IMAGE_FRAGMENT } from 'graphql/fragments';

const imageResolvers = {
  Query: {
    getImageClient: (_, { id }, { client, cache }) => {
      const imageCacheKey = cache.identify({
        __typename: 'Image',
        id,
      });

      const image = client.readFragment({
        fragment: IMAGE_FRAGMENT,
        id: imageCacheKey,
      });

      return image;
    },
  },
};

export default imageResolvers;
