import React from 'react';
import { OutlinedTextFieldProps, TextField as MaterialTextField } from '@mui/material';
import { styled, Theme } from '@mui/material/styles';
import FieldWrapper from '../FieldWrapper/FieldWrapper';
import ErrorMessage from 'components/Forms/ErrorMessage';

const StyledTextField = styled(MaterialTextField)(({ theme }: { theme: Theme }) => ({
  fontSize: '11px',

  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.custom.themeColors.grayScale[20],
  },
}));

export interface Props extends Partial<OutlinedTextFieldProps> {
  label?: string;
  subtitle?: string;
  errorMessage?: string;
}

const TextField: React.FC<Props> = ({
  label,
  subtitle,
  variant = 'outlined',
  error,
  errorMessage,
  ...props
}) => {
  return (
    <FieldWrapper label={label} subtitle={subtitle}>
      <StyledTextField variant={variant} {...props} />
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </FieldWrapper>
  );
};

export default TextField;
