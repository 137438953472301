import { gql } from '@apollo/client';
import {
  BASE_PARTNER_COMPANY_FRAGMENT,
  PARTNER_REQUEST_FRAGMENT,
} from 'graphql/fragments/partner.fragments';

export const CREATE_PARTNER_COMPANY = gql`
  mutation CreatePartner($input: CreatePartnerInput!) {
    createPartner(input: $input) {
      partner {
        ...basePartnerCompanyValues
      }
    }
  }
  ${BASE_PARTNER_COMPANY_FRAGMENT}
`;

export const UPDATE_PARTNER_COMPANY = gql`
  mutation UpdatePartner($id: UUID!, $input: UpdatePartnerInput!) {
    updatePartner(id: $id, input: $input) {
      partner {
        ...basePartnerCompanyValues
      }
    }
  }
  ${BASE_PARTNER_COMPANY_FRAGMENT}
`;

export const DELETE_PARTNER_COMPANY = gql`
  mutation DeletePartners($ids: [UUID!]!) {
    deletePartners(ids: $ids) {
      ids
    }
  }
`;

export const INVITE_PARTNER_COMPANY = gql`
  mutation InvitePartner($email: String!, $partnerCompanyId: UUID!) {
    invitePartner(email: $email, partnerCompanyId: $partnerCompanyId) {
      firstName
      lastName
      email
    }
  }
`;

/** @return { partnerRequest: PartnerRequest } */
export const CREATE_PARTNER_REQUEST = gql`
  mutation CreatePartnerRequest($input: CreatePartnerRequestInput!) {
    createPartnerRequest(input: $input) {
      partnerRequest {
        ...partnerRequestValues
      }
    }
  }
  ${PARTNER_REQUEST_FRAGMENT}
`;

/** @return { partnerRequest: PartnerRequest } */
export const UPDATE_PARTNER_REQUEST = gql`
  mutation UpdatePartnerRequest($id: UUID!, $input: UpdatePartnerRequestInput!) {
    updatePartnerRequest(id: $id, input: $input) {
      partnerRequest {
        ...partnerRequestValues
      }
    }
  }
  ${PARTNER_REQUEST_FRAGMENT}
`;

export const REINVITE_PARTNER = gql`
  mutation ReinvitePartner($email: String!, $companyId: UUID!) {
    reinvitePartner(email: $email, partnerCompanyId: $companyId) {
      firstName
      lastName
      email
    }
  }
`;
