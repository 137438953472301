import { Box, Checkbox, IconButton, LinearProgress, TableCell, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import ComponentChainPlaceholderImage from 'assets/img/components/component-chain-placeholder.svg';
import defaultPartnerImage from 'assets/img/partners/default-partner-image.png';
import SupplyChainPlaceholderImage from 'assets/img/supply-chain-placeholder-image.svg';
import { StyledTableRow } from 'components/TableBase';
import { ThemeTypography } from 'designSystem';
import Image from 'designSystem/DataDisplay/Image/Image';
import React, { FC, ReactNode } from 'react';
import { IChainTaskDetails } from 'types/chain.types';
import { ImageVariant } from 'types/media.types';
import { IBasePartnerCompany } from 'types/partner.types';
import { COMPONENT_CHAIN_COLUMN_IDS } from './constants/headerColumns';

interface ITableRowProps {
  id: string;
  title: string;
  columnKeys: COMPONENT_CHAIN_COLUMN_IDS[];
  taskDetails: Omit<IChainTaskDetails, 'tasks'>;
  image?: ImageVariant | null;
  isSupplyChain?: boolean;
  selectedIds?: string[];
  actions?: ITableRowAction[];
  inUse?: boolean;
  ownedBy?: IBasePartnerCompany | null;
  involvedPartnerCompanies?: IBasePartnerCompany[];
  isPartOfRequest?: boolean;
  onRowClick?: () => void;
  onSelect?: (id: string) => void;
}

export interface ITableRowAction {
  tooltip: string;
  disableIfUsed?: boolean;
  disableIfPartOfRequest?: boolean;
  disabledTooltip?: string;
  renderIcon: () => ReactNode;
  onClick: (id: string) => void;
  dataCy?: string;
}

const BorderLinearProgress = styled(LinearProgress)<{ percentage: number }>(
  ({ percentage, theme }) => ({
    borderRadius: 2,
    height: 12,
    width: 100,
    backgroundColor: theme.palette.grey[300],
    marginRight: theme.spacing(1),

    '& .MuiLinearProgress-bar': {
      backgroundColor: percentage === 100 ? theme.palette.success.main : theme.palette.primary.main,
    },
  })
);

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

const InvolvedPartners = styled('p')(() => ({
  marginRight: 3,
  display: 'inline',
}));

const InvolvedPartnersContainer = styled(Box)(({ theme }) => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontSize: 10,
  color: theme.custom.themeColors.grayScale[60],
  maxWidth: 150,
}));

const TableRow: FC<ITableRowProps> = ({
  id,
  title,
  taskDetails,
  image,
  isSupplyChain,
  onRowClick,
  selectedIds,
  onSelect,
  actions,
  inUse = false,
  isPartOfRequest,
  involvedPartnerCompanies: involvedPartners,
  ownedBy: partner,
  columnKeys,
}) => {
  const isSelected = selectedIds?.includes(id);
  const numberOfTasks = taskDetails.total;
  const percentage = (taskDetails.completed / numberOfTasks) * 100;

  const defaultImage = isSupplyChain ? SupplyChainPlaceholderImage : ComponentChainPlaceholderImage;

  const handleSelectComponentChain = () => {
    if (!onSelect) return;
    onSelect(id);
  };

  return (
    <StyledTableRow hover onClick={onRowClick}>
      {columnKeys.includes(COMPONENT_CHAIN_COLUMN_IDS.SELECTION) && (
        <TableCell padding="checkbox">
          <Checkbox onClick={handleSelectComponentChain} checked={isSelected} />
        </TableCell>
      )}

      {columnKeys.includes(COMPONENT_CHAIN_COLUMN_IDS.TITLE) && (
        <TableCell scope="row">
          <Box display="flex" alignItems="center">
            <Image image={image} width={42} height={42} backupImageUrl={defaultImage} />
            <Box ml={2}>
              <ThemeTypography variant="ITEM_TITLE">{title}</ThemeTypography>
              <Box display="flex" alignItems="center">
                <BorderLinearProgress
                  percentage={percentage}
                  variant="determinate"
                  value={percentage}
                />
                <ThemeTypography variant="ITEM_SUBTITLE" color="GRAY_80">
                  {taskDetails.completed}/{numberOfTasks} COMPLETED
                </ThemeTypography>
              </Box>
            </Box>
          </Box>
        </TableCell>
      )}

      {columnKeys?.includes(COMPONENT_CHAIN_COLUMN_IDS.OWNER) && (
        <TableCell scope="row">
          {partner && (
            <Box display="flex" alignItems="center">
              <Box mr={1} display="flex" alignItems="center">
                <Image
                  image={partner.logo}
                  width={44}
                  height={44}
                  backupImageUrl={defaultPartnerImage}
                />
              </Box>
              <ThemeTypography variant="BODY_LARGE">{partner.name}</ThemeTypography>
            </Box>
          )}
        </TableCell>
      )}

      {columnKeys?.includes(COMPONENT_CHAIN_COLUMN_IDS.INVOLVED_PARTNERS) && (
        <TableCell scope="row">
          {involvedPartners?.length ? (
            <InvolvedPartnersContainer>
              {involvedPartners.map(
                (involvedPartner, index) =>
                  involvedPartner && (
                    <InvolvedPartners key={involvedPartner.id}>
                      {involvedPartner.name}
                      {involvedPartners.length && index < involvedPartners.length - 1 && ','}
                    </InvolvedPartners>
                  )
              )}
            </InvolvedPartnersContainer>
          ) : null}
        </TableCell>
      )}

      {columnKeys?.includes(COMPONENT_CHAIN_COLUMN_IDS.ACTIONS) && (
        <TableCell scope="row" width={170} align="left">
          <Box display="flex" gap={1.5}>
            {actions &&
              actions.map((action, index) => {
                const disabled =
                  (action.disableIfUsed && inUse) ||
                  (action.disableIfPartOfRequest && isPartOfRequest);
                const disabledTooltip = action.disabledTooltip || '';
                return (
                  <Tooltip key={index} title={disabled ? disabledTooltip : action.tooltip}>
                    <Box component="span">
                      <StyledIconButton
                        onClick={event => {
                          event.stopPropagation();
                          action.onClick(id);
                        }}
                        data-cy={action.dataCy}
                        disabled={disabled}
                      >
                        {action.renderIcon()}
                      </StyledIconButton>
                    </Box>
                  </Tooltip>
                );
              })}
          </Box>
        </TableCell>
      )}
    </StyledTableRow>
  );
};

export default TableRow;
