import { Box, IconButton, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { BarChart } from '@styled-icons/bootstrap/BarChart';
import { PencilSquare } from '@styled-icons/bootstrap/PencilSquare';
import { CardContainer } from 'designSystem';
import React, { PropsWithChildren } from 'react';
import { BoxArrowUpRight, Trash } from '@styled-icons/bootstrap';

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  padding: theme.spacing(1),
  color: theme.custom.colors.almostBlack,
}));

interface Props {
  disabled?: boolean;
  onCardClick?: () => void;
  onClickEdit?: () => void;
  onClickAnalytics?: () => void;
  onClickDelete?: () => void;
  onClickOpen?: () => void;
}

const ItemCardSmall: React.FC<PropsWithChildren<Props>> = ({
  disabled,
  children,
  onCardClick,
  onClickEdit,
  onClickAnalytics,
  onClickDelete,
  onClickOpen,
}) => {
  const handleClickEdit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (!disabled) {
      onClickEdit?.();
    }
  };

  const handleClickAnalytics = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (!disabled) {
      onClickAnalytics?.();
    }
  };

  const handleClickDelete = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (!disabled) {
      onClickDelete?.();
    }
  };

  const handleClickOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (!disabled) {
      onClickOpen?.();
    }
  };

  return (
    <CardContainer
      padding={1.5}
      mt={1}
      onClick={onCardClick}
      blueHover={!!onCardClick}
      data-cy="item-card"
      disabled={disabled}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        {children}
        <Box display="flex" pr={1}>
          {onClickOpen && (
            <Tooltip title="Open in new tab">
              <StyledIconButton onClick={handleClickOpen}>
                <BoxArrowUpRight size={14} />
              </StyledIconButton>
            </Tooltip>
          )}
          {onClickEdit && (
            <Tooltip title="Edit">
              <StyledIconButton onClick={handleClickEdit}>
                <PencilSquare size={15} />
              </StyledIconButton>
            </Tooltip>
          )}
          {onClickAnalytics && (
            <Tooltip title="Go to analytics">
              <StyledIconButton onClick={handleClickAnalytics}>
                <BarChart size={17} />
              </StyledIconButton>
            </Tooltip>
          )}
          {onClickDelete && (
            <Tooltip title="Delete">
              <StyledIconButton onClick={handleClickDelete} data-cy="delete-item">
                <Trash size={14} />
              </StyledIconButton>
            </Tooltip>
          )}
        </Box>
      </Box>
    </CardContainer>
  );
};

export default ItemCardSmall;
