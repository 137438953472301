import { PaginationContainer, StyledTable, StyledTableBody } from 'components/TableBase';
import { COMPONENTS_TABLE_ROWS_PER_PAGE } from 'constants/pagination';
import React, { FC, useState } from 'react';
import { IBaseChain } from 'types/chain.types';
import TableRow, { ITableRowAction } from '../TableRow';
import { TablePagination } from '@mui/material';
import { COMPONENT_CHAIN_COLUMN_IDS } from '../constants/headerColumns';

interface IProductSupplyChainTableProps {
  productSupplyChains: IBaseChain[];
  actions: ITableRowAction[];
}

const ProductSupplyChainTable: FC<IProductSupplyChainTableProps> = ({
  productSupplyChains,
  actions,
}) => {
  const [currentPage, setCurrentPage] = useState<number>(0);

  return (
    <StyledTable>
      <StyledTableBody data-cy="product-chain-table-overview">
        {productSupplyChains
          .slice(
            currentPage * COMPONENTS_TABLE_ROWS_PER_PAGE,
            currentPage * COMPONENTS_TABLE_ROWS_PER_PAGE + COMPONENTS_TABLE_ROWS_PER_PAGE
          )
          .map(({ id, chainTasksDetails, title, image }) => (
            <TableRow
              id={id}
              key={id}
              columnKeys={[COMPONENT_CHAIN_COLUMN_IDS.TITLE, COMPONENT_CHAIN_COLUMN_IDS.ACTIONS]}
              title={title}
              taskDetails={chainTasksDetails}
              image={image}
              isSupplyChain
              actions={actions}
            />
          ))}
      </StyledTableBody>
      {productSupplyChains.length > COMPONENTS_TABLE_ROWS_PER_PAGE && (
        <PaginationContainer>
          <TablePagination
            component="div"
            rowsPerPageOptions={[]}
            count={productSupplyChains.length}
            rowsPerPage={COMPONENTS_TABLE_ROWS_PER_PAGE}
            page={currentPage}
            onPageChange={(_, newPage) => setCurrentPage(newPage)}
            labelDisplayedRows={({ page }) =>
              `Page ${page + 1} of ${Math.ceil(
                productSupplyChains.length / COMPONENTS_TABLE_ROWS_PER_PAGE
              )}`
            }
          />
        </PaginationContainer>
      )}
    </StyledTable>
  );
};

export default ProductSupplyChainTable;
