import { Box, Collapse, styled, Table, TableBody, TableCell } from '@mui/material';
import { StyledTableRow } from 'components/TableBase';
import React, { FC } from 'react';
import { Booleanish, booleanish } from 'types/booleanish.types';
import { ISite } from 'types/site.types';
import { HeaderColumn } from 'types/types';
import SiteTableRow from './SiteTableRow';

interface IInnerTableRowProps {
  site: ISite;
  open: boolean;
  headerColumns: HeaderColumn[];
  onClick?: (site: ISite) => void;
  onEdit?: (site: ISite) => void;
  onDelete?: (siteId: string) => void;
  onEditCultivationArea?: (site: ISite) => void;
}

const InnerSiteTableRow = styled(StyledTableRow)<{ display: booleanish }>(({ display }) => ({
  backgroundColor: 'transparent',
  boxShadow: 'none',
  display: display === 'true' ? 'table-row' : 'none',

  '&:hover': {
    background: 'transparent !important',
    boxShadow: 'none',
    cursor: 'pointer',
  },
}));

const InnerTableRow: FC<IInnerTableRowProps> = ({
  site,
  open,
  headerColumns,
  onClick,
  onEdit,
  onDelete,
  onEditCultivationArea,
}) => {
  return (
    <InnerSiteTableRow
      data-cy="site-table-item"
      data-cy-id={site?.id}
      hover
      key={site?.id}
      display={Booleanish(open)}
    >
      <TableCell
        style={{
          paddingBottom: 0,
          paddingTop: 0,
        }}
        colSpan={6}
      >
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box mr={-1.5} ml={1}>
            <Table size="small">
              <TableBody>
                <SiteTableRow
                  fixedSize
                  site={site}
                  headerColumns={headerColumns}
                  onRowClick={onClick}
                  onEdit={onEdit}
                  onDelete={onDelete}
                  onEditCultivationArea={onEditCultivationArea}
                />
              </TableBody>
            </Table>
          </Box>
        </Collapse>
      </TableCell>
    </InnerSiteTableRow>
  );
};

export default InnerTableRow;
