import React, { ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import { ExclamationCircleFill } from '@styled-icons/bootstrap/ExclamationCircleFill';

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(1),
  border: '1px solid',
  borderColor: theme.palette.secondary.main,
  background: theme.palette.grey[100],
  borderRadius: theme.shape.borderRadius,
}));

const Content = styled('div')(({ theme }) => ({
  fontSize: 12,
  fontWeight: 500,
  color: theme.palette.secondary.main,
}));

const Icon = styled(ExclamationCircleFill)(({ theme }) => ({
  flexShrink: 0,
  color: theme.palette.secondary.main,
  marginRight: theme.spacing(0.5),
  marginTop: 2,
}));

interface Props {
  children: ReactNode;
  className?: string;
}

const Warning: React.FC<Props> = ({ className = '', children }) => {
  return (
    <Container className={className}>
      <Icon size={14} />
      <Content>{children}</Content>
    </Container>
  );
};

export default Warning;
