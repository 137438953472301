import { styled, useTheme } from '@mui/material/styles';
import { LoadingCard } from 'components/Forms';
import { ThemeTypography } from 'designSystem';
import PropTypes from 'prop-types';
import React from 'react';
import StarRatings from 'react-star-ratings';

const Container = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
}));

const OverallRatingCard = ({ average, total }) => {
  const { palette } = useTheme();

  return (
    <LoadingCard label="Overall rating">
      <Container>
        <ThemeTypography variant="TITLE_LARGE">
          {average}
          /5
        </ThemeTypography>

        <StarRatings
          rating={average}
          starEmptyColor={palette.grey[300]}
          starRatedColor={palette.primary.main}
          starHoverColor={palette.primary.main}
          numberOfStars={5}
          name="rating"
          starDimension="28px"
          disabled
        />
        <ThemeTypography variant="BODY_LARGE">{total} reviews</ThemeTypography>
      </Container>
    </LoadingCard>
  );
};

OverallRatingCard.propTypes = {
  average: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
};

export default OverallRatingCard;
