import { useApolloClient } from '@apollo/client';
import useFeatureFlags, { FeatureFlag } from 'components/hooks/useFeatureFlags';
import { CREATE_DOCUMENT } from 'graphql/mutations';
import { useCallback, useState } from 'react';
import { urlSafeFileName } from 'utils';

const useDocumentUpload = () => {
  const client = useApolloClient();
  const [state, setState] = useState({
    uploadTask: null,
    record: null,
    error: null,
  });
  const { isFeatureEnabled } = useFeatureFlags();
  const visibilitySettingEnabled = isFeatureEnabled(FeatureFlag.DOCUMENT_VISIBILITY_SETTING);

  // this function creates a record for the file in the database and then uploads the file to firebase
  const uploadFile = useCallback(
    async ({
      file,
      category = undefined,
      onError = () => null,
      onSuccess = () => null,
      onProgress = () => null,
      onRecordCreated = () => null,
    }) => {
      try {
        const fileName = urlSafeFileName(file.name);

        const {
          data: {
            createDocument: { document },
          },
        } = await client.mutate({
          mutation: CREATE_DOCUMENT,
          variables: {
            input: {
              title: fileName,
              category,
              fileName,
              // Make sure that the document is visible on the impact claims.
              // The visibility can be set to public after the upload
              visibility: visibilitySettingEnabled ? 'PRIVATE' : 'PUBLIC',
            },
          },
        });

        onRecordCreated(document);

        const storageRef = client.defaultOptions.firebase
          .getFirebase()
          .storage()
          .ref(document.path);

        const uploadTask = storageRef.put(file);

        uploadTask.on(
          'state_changed',
          ({ bytesTransferred, totalBytes }) => {
            const progress = parseInt(((bytesTransferred / totalBytes) * 100).toFixed(2));

            onProgress(progress);
          },
          // error handler
          () => onError(),
          // success handler
          () => onSuccess()
        );

        const newState = {
          uploadTask,
          record: document,
        };

        setState(newState);
        return newState;
      } catch (error) {
        const newState = {
          ...state,
          error: error.message,
        };

        setState(newState);
        onError(error.message);
        return newState;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [visibilitySettingEnabled]
  );

  return [uploadFile, { ...state }];
};

export default useDocumentUpload;
