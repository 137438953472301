import { styled } from '@mui/material/styles';
import { CheckboxField } from 'components/Forms';
import { FastField as Field, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

const StyledLink = styled('a')(({ theme }) => ({
  color: theme.custom.colors.blueFrost,
  cursor: 'pointer',

  '&:hover': {
    textDecoration: 'underline',
  },
}));

const AcceptText = styled('span')(() => ({
  color: '#fff',
  cursor: 'pointer',
}));

const AcceptTermsCheckbox = ({ name = 'termsAccepted', handleOpenTerms }) => {
  const { values, setFieldValue } = useFormikContext();

  return (
    <Field
      component={CheckboxField}
      name={name}
      inverted
      inputProps={{
        'data-cy': 'terms-field',
      }}
      childComponent={
        <span>
          <AcceptText onClick={() => setFieldValue(name, !values[name])}>
            I agree to the &nbsp;
          </AcceptText>
          <StyledLink onClick={handleOpenTerms}>terms and conditions</StyledLink>
        </span>
      }
    />
  );
};

AcceptTermsCheckbox.propTypes = {
  name: PropTypes.string,
  handleOpenTerms: PropTypes.func.isRequired,
};

export default AcceptTermsCheckbox;
