import React from 'react';
import PropTypes from 'prop-types';
import { LoadingCard } from 'components/Forms';
import { BarChartHorizontal } from 'components/Product/Analytics/BarChartHorizontal';

const RatingChartCard = ({ ratings }) => {
  return (
    <LoadingCard label="Rating insights">
      <BarChartHorizontal
        x={d => d.weight}
        xDomain={[0, 1]}
        y={d => `${d.label} stars`}
        yRight={d => `${d.value}`}
        data={ratings}
        width={420}
        height={300}
        shadowBars
        margin={{
          top: 0,
          right: 80,
          bottom: 20,
          left: 24,
        }}
      />
    </LoadingCard>
  );
};

RatingChartCard.propTypes = {
  ratings: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.number.isRequired,
      value: PropTypes.number.isRequired,
      weight: PropTypes.number.isRequired,
    })
  ).isRequired,
};

export default RatingChartCard;
