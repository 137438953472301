import { gql } from '@apollo/client';
import { USER_FRAGMENT } from 'graphql/fragments';

export const REGISTER_USER = gql`
  mutation RegisterUser($input: UserRegistrationInput!) {
    registerUser(input: $input)
  }
`;

export const REGISTER_INVITED_USER = gql`
  mutation RegisterInvitedUser($input: InvitedUserRegistrationInput!) {
    registerInvitedUser(input: $input) {
      ...userValues
    }
  }
  ${USER_FRAGMENT}
`;

export const INVITE_USER = gql`
  mutation InviteUser($email: String!, $role: UserRole!) {
    inviteUser(email: $email, role: $role) {
      ...userValues
    }
  }
  ${USER_FRAGMENT}
`;

export const REINVITE_USER = gql`
  mutation ReinviteUser($id: UUID!) {
    reinviteUser(id: $id) {
      ...userValues
    }
  }
  ${USER_FRAGMENT}
`;

export const EDIT_USER = gql`
  mutation EditUser($firstName: String!, $lastName: String!) {
    editUser(firstName: $firstName, lastName: $lastName) {
      message
    }
  }
`;

export const DELETE_USER = gql`
  mutation DeleteUser($id: UUID!) {
    deleteUser(id: $id) {
      id
      message
    }
  }
`;

export const SEND_PW_RESET_MAIL = gql`
  mutation SendPwResetMail($email: String!) {
    sendPwResetMail(email: $email) {
      timestamp
    }
  }
`;

export const UPDATE_ONBOARDING = gql`
  mutation UpdateOnboarding($input: UserOnboardingInput!) {
    onboarding(input: $input) {
      id
      onboarding
    }
  }
`;

export const ACCEPT_TERMS = gql`
  mutation AcceptLatestTerms($input: AcceptLatestTermsInput!) {
    acceptLatestTerms(input: $input) {
      id
      latestTermsAccepted
    }
  }
`;

export const UPDATE_NOTIFICATION_SETTINGS = gql`
  mutation UpdateNotificationSettings($input: UserNotificationsInput!) {
    updateNotificationSettings(input: $input) {
      id
      notifications {
        weeklyRoundup
      }
    }
  }
`;

export const CHANGE_USER_ROLE = gql`
  mutation ChangeUserRole($id: UUID!, $role: UserRole!) {
    changeUserRole(id: $id, role: $role) {
      ...userValues
    }
  }
  ${USER_FRAGMENT}
`;

export const VERIFY_USER = gql`
  mutation VerifyUser {
    verifyUser {
      ...userValues
    }
  }
  ${USER_FRAGMENT}
`;
