import { CircularProgress, Link, LinkProps, styled } from '@mui/material';
import { ArrowLeftShort } from '@styled-icons/bootstrap/ArrowLeftShort';
import { ArrowRightShort } from '@styled-icons/bootstrap/ArrowRightShort';
import React, { PropsWithChildren } from 'react';

interface StyledProps {
  rtl?: boolean;
  inverted?: boolean;
  loading?: boolean;
  disabled?: boolean;
}

const StyledLink = styled(Link)<StyledProps>(({ theme, inverted, rtl, loading }) => ({
  position: 'relative',
  fontSize: 14,
  fontWeight: 700,
  textTransform: 'none',
  textDecoration: 'none',
  lineHeight: 'inherit',
  color: inverted ? '#fff' : theme.palette.secondary.main,
  pointerEvents: loading ? 'none' : 'all',

  '&.disabled': {
    pointerEvents: 'none',
    color: theme.custom.themeColors.grayScale[60],
    '&:before, &:after': {
      background: theme.custom.themeColors.grayScale[30],
    },
  },

  '&:hover:not(.disabled)': {
    textDecoration: 'underline',
  },

  '&:before': {
    content: rtl || loading ? 'none' : '""',
    width: 10,
    height: 10,
    display: 'inline-block',
    marginRight: theme.spacing(1),
    background: inverted ? theme.custom.colors.secondaryLighter : theme.palette.primary.main,
    borderRadius: '100%',
  },

  '&:after': {
    content: !rtl || loading ? 'none' : '""',
    width: 10,
    height: 10,
    display: 'inline-block',
    marginLeft: theme.spacing(1),
    background: inverted ? theme.custom.colors.secondaryLighter : theme.palette.primary.main,
    borderRadius: '100%',
  },
}));

const StyledLoader = styled(CircularProgress)(({ theme }) => ({
  margin: theme.spacing(0, 2),
}));

const StyledArrowRight = styled(ArrowRightShort)<StyledProps>(
  ({ theme, disabled, inverted, rtl }) => ({
    position: 'absolute',
    left: rtl ? 'auto' : -13,
    right: rtl ? -13 : 'auto',
    top: -3,
    transition: rtl ? 'right .2s ease' : 'left .2s ease',
    // Prevent buggy hover when hover over the arrow
    pointerEvents: 'none',
    color: disabled
      ? theme.custom.themeColors.grayScale[60]
      : inverted
      ? theme.palette.primary.main
      : 'inherit',

    '.action-link:not(.disabled):hover &': {
      left: rtl ? 'auto' : -4,
      right: rtl ? -4 : 'auto',
    },
  })
);

const StyledArrowLeft = styled(ArrowLeftShort)<StyledProps>(
  ({ theme, disabled, inverted, rtl }) => ({
    position: 'absolute',
    left: rtl ? 'auto' : -13,
    right: rtl ? -13 : 'auto',
    top: -3,
    transition: rtl ? 'right .2s ease' : 'left .2s ease',
    // Prevent buggy hover when hover over the arrow
    pointerEvents: 'none',
    color: disabled
      ? theme.custom.themeColors.grayScale[60]
      : inverted
      ? theme.palette.primary.main
      : 'inherit',

    '.action-link:not(.disabled):hover &': {
      left: rtl ? 'auto' : -4,
      right: rtl ? -4 : 'auto',
    },
  })
);

interface Props extends PropsWithChildren, LinkProps {
  className?: string;
  rtl?: boolean;
  inverted?: boolean;
  loading?: boolean;
  disabled?: boolean;
}

const ActionLink: React.FC<Props> = ({
  children,
  className,
  rtl,
  inverted,
  loading,
  disabled,
  ...rest
}) => {
  return (
    <StyledLink
      {...rest}
      inverted={inverted}
      rtl={rtl}
      loading={loading}
      className={`action-link ${className ?? ''}${disabled ? ' disabled' : ''}`}
    >
      {loading && !rtl && <StyledLoader color="primary" size={12} />}
      {!loading && rtl ? (
        <StyledArrowLeft rtl={rtl} inverted={inverted} size={26} />
      ) : (
        <StyledArrowRight rtl={rtl} inverted={inverted} size={26} />
      )}
      {children}
      {loading && rtl && <StyledLoader color="primary" size={12} />}
    </StyledLink>
  );
};

export default ActionLink;
