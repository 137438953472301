import React, { UIEvent, useCallback, useRef, useState, Fragment } from 'react';
import { styled } from '@mui/material/styles';
import { PartnerRequest } from 'types/partner.types';
import { useIsOverflow } from 'components/hooks';
import { ChevronLeft, ChevronRight } from '@styled-icons/bootstrap';
import { HomeRequestTitle } from 'components/Home/Requests';

const Container = styled('div')(({ theme }) => ({
  border: `1px solid ${theme.custom.colors.lightBorderColor}`,
  position: 'relative',
  borderRadius: 6,
  background: '#fff',
  overflow: 'hidden',
}));

const RequestsContainer = styled('div')(({ theme }) => ({
  overflow: 'auto',
  display: 'flex',
  gridGap: theme.spacing(2),
  padding: theme.spacing(3),
  transition: 'all .2s ease',

  '-ms-overflow-style': 'none' /* IE and Edge */,
  scrollbarWidth: 'none' /* Firefox */,

  '&::-webkit-scrollbar': {
    display: 'none',
  },
}));

const Fade = styled('div')(() => ({
  position: 'absolute',
  top: 0,
  display: 'block !important',
  width: 100,
  height: '100%',
  zIndex: 1,
  pointerEvents: 'none',
}));

const FadeOut = styled(Fade)(() => ({
  right: 0,
  background: 'linear-gradient(to left, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%)',
}));

const FadeIn = styled(Fade)(() => ({
  left: 0,
  background: 'linear-gradient(to right, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%)',
}));

const ArrowButton = styled('button')<{ backButton?: boolean }>(({ theme, backButton }) => ({
  position: 'absolute',
  bottom: 80,
  border: 0,
  background: '#fff',
  borderRadius: '50%',
  boxShadow: '0px 2px 16px rgba(0, 0, 0, 0.11)',
  height: '35px',
  width: '35px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 99,
  cursor: 'pointer',
  left: backButton ? theme.spacing(2) : 'initial',
  right: backButton ? 'initial' : theme.spacing(2),

  '& svg': {
    color: theme.custom.themeColors.accent[100],
  },
}));

interface Props {
  requests: PartnerRequest[];
  renderRequestCard: (request: PartnerRequest) => React.ReactNode;
  partnerUser: boolean;
}

const HomeRequestsOverview: React.FC<Props> = ({ requests, renderRequestCard, partnerUser }) => {
  const [currentScrollPosition, setCurrentScrollPosition] = useState<number>(0);

  const containerRef = useRef<HTMLDivElement | null>(null);
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const isOverflow = useIsOverflow(scrollRef);
  const maxScroll: number =
    (scrollRef.current?.scrollWidth || 0) - (containerRef.current?.clientWidth || 0) - 10;

  const scroll = (scrollOffset: number) => {
    if (scrollRef.current) {
      const newScroll = scrollRef.current.scrollLeft + scrollOffset;
      scrollRef.current.scrollTo({ left: newScroll, behavior: 'smooth' });
    }
  };

  const onScroll = useCallback(
    (event: UIEvent<HTMLDivElement>) => {
      setCurrentScrollPosition(event.currentTarget.scrollLeft);
    },
    [setCurrentScrollPosition]
  );

  return (
    <Container ref={containerRef}>
      <HomeRequestTitle requests={requests} partnerUser={partnerUser} />
      {isOverflow && (
        <Fragment>
          {!maxScroll ||
            (currentScrollPosition < maxScroll && (
              <Fragment>
                <ArrowButton onClick={() => scroll(300)} id="st-claims-next-button">
                  <ChevronRight size={20} />
                </ArrowButton>
                <FadeOut />
              </Fragment>
            ))}
          {currentScrollPosition > 0 && (
            <Fragment>
              <ArrowButton backButton onClick={() => scroll(-300)} id="st-claims-previous-button">
                <ChevronLeft size={20} />
              </ArrowButton>
              <FadeIn />
            </Fragment>
          )}
        </Fragment>
      )}
      <RequestsContainer ref={scrollRef} onScroll={onScroll} data-cy="home-requests-list">
        {requests
          .slice(0)
          .reverse()
          .map(request => renderRequestCard(request))}
      </RequestsContainer>
    </Container>
  );
};

export default HomeRequestsOverview;
