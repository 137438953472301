import React, { FC } from 'react';
import useActiveDocument from 'components/DocumentLibrary/hooks/useActiveDocument';

/**
 * Returns the title of the document, that is fetched from the cache only
 */
const DocumentTitle: FC = () => {
  const { document } = useActiveDocument();

  return <span>{document?.title || ''}</span>;
};

export default DocumentTitle;
