import { IconButton, TableCell, Tooltip, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { StyledTableBody, StyledTableRow } from 'components/TableBase';
import PropTypes from 'prop-types';
import React from 'react';
import Moment from 'react-moment';
import EnabledLanguages from './EnabledLanguages';
import ProductSummaryCard from './ProductSummaryCard';

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.secondary,
  '&:first-of-type': {
    paddingLeft: 0,
  },
}));

const IconContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'flex-start',
  width: 170,
}));

const Date = styled(Typography)(() => ({
  width: 140,
}));

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const OpacityContainer = styled(({ opacity, ...props }) => <div {...props} />)(({ opacity }) => ({
  opacity: opacity,
}));

const ProductTableBody = ({
  actions,
  products,
  onSelect,
  rowsPerPage,
  currentPage,
  archived,
  'data-cy': dataCy = '',
}) => {
  return (
    <StyledTableBody data-cy={dataCy}>
      {products
        .slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage)
        .map((product, index) => {
          const { id, lastEditedTimestamp, languageConfig, publishedLanguages } = product;
          const labelId = `enhanced-table-${id}`;

          return (
            <StyledTableRow
              data-cy="product-table-item"
              data-cy-id={id}
              hover
              key={id}
              onClick={event => {
                event.stopPropagation();
                onSelect(event, id);
              }}
            >
              <TableCell id={labelId} key={`${id}-${index}`} scope="row" width={500}>
                <OpacityContainer opacity={archived ? 0.5 : 1}>
                  <ProductSummaryCard product={product} />
                </OpacityContainer>
              </TableCell>
              <TableCell
                width={languageConfig?.enabled.length > 3 ? 170 : 100}
                id={labelId}
                scope="row"
              >
                <OpacityContainer opacity={archived ? 0.5 : 1}>
                  <EnabledLanguages
                    languageConfig={languageConfig}
                    publishedLanguages={publishedLanguages}
                  />
                </OpacityContainer>
              </TableCell>
              <TableCell id={labelId} scope="row">
                <Date variant="subtitle2">
                  <Moment format="LL">{lastEditedTimestamp}</Moment>
                </Date>
              </TableCell>
              <TableCell width={170} id={labelId} scope="row" align="left">
                <IconContainer>
                  {actions.map((action, index) => {
                    return (
                      <Tooltip key={index} title={action.tooltip}>
                        <StyledIconButton
                          data-cy={action.dataCy}
                          onClick={event => {
                            event.stopPropagation();
                            action.onClick(product);
                          }}
                        >
                          {action.icon}
                        </StyledIconButton>
                      </Tooltip>
                    );
                  })}
                </IconContainer>
              </TableCell>
            </StyledTableRow>
          );
        })}
    </StyledTableBody>
  );
};

ProductTableBody.propTypes = {
  rowsPerPage: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  actions: PropTypes.array.isRequired,
  products: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  archived: PropTypes.bool,
};

export default ProductTableBody;
