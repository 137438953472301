import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { BarChartHorizontal } from 'components/Product/Analytics/BarChartHorizontal';
import truncate from 'lodash/truncate';
import useMeasure from 'react-use/lib/useMeasure';
import { useTheme } from '@mui/material/styles';
import { millisecondsToMinutes } from 'components/Product/Analytics/utils';
import { PAGE_BLOCKS } from 'constants/behaviorAnalytics';
import { styled } from '@mui/material/styles';

const Container = styled('div')(({ theme }) => ({
  margin: theme.spacing(0, -2, 0, 0),
}));

const TimePerContenBlockCard = ({ data }) => {
  const theme = useTheme();
  const [ref, { width }] = useMeasure();
  const chartHorizontalWidth = width ? width : 464;

  const tickLabelPropsRight = useCallback(
    () => ({
      fontFamily: theme.typography.fontFamily,
      fontSize: 14,
      textAnchor: 'end',
      dx: '3em',
    }),
    [theme]
  );

  return (
    <Container ref={ref}>
      <BarChartHorizontal
        shadowBars
        width={chartHorizontalWidth}
        margin={{
          top: 0,
          right: 120,
          bottom: 0,
          left: 45,
        }}
        x={d => d.total}
        yRight={d => millisecondsToMinutes(d.total) + 'm'}
        labelPropsRight={tickLabelPropsRight}
        y={d =>
          truncate(PAGE_BLOCKS[d.contentType], {
            length: 17,
            separator: '',
          })
        }
        data={data}
      />
    </Container>
  );
};

TimePerContenBlockCard.propTypes = {
  data: PropTypes.array.isRequired,
};

export default TimePerContenBlockCard;
