import { Box, LinearProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import ThemeTypography from 'designSystem/Primitives/Typography/ThemeTypography';
import React, { FC } from 'react';

interface ITaskProgress {
  totalTasks: number;
  completedTasks: number;
}

const BorderLinearProgress = styled(LinearProgress)<{ percentage: number }>(
  ({ percentage, theme }) => ({
    borderRadius: 2,
    height: 12,
    width: 100,
    backgroundColor: theme.palette.grey[300],
    marginRight: theme.spacing(1),

    '& .MuiLinearProgress-bar': {
      backgroundColor: percentage === 100 ? theme.palette.success.main : theme.palette.primary.main,
    },
  })
);

const TaskProgress: FC<ITaskProgress> = ({ totalTasks, completedTasks }) => {
  const percentage = (completedTasks / totalTasks) * 100;

  return (
    <Box display="flex" alignItems="center">
      <BorderLinearProgress percentage={percentage} variant="determinate" value={percentage} />
      <ThemeTypography variant="ITEM_SUBTITLE" color="GRAY_80">
        {completedTasks}/{totalTasks} COMPLETED
      </ThemeTypography>
    </Box>
  );
};

export default TaskProgress;
