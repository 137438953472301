import { useQuery } from '@apollo/client';
import { GET_USER_LOGIN_DETAILS } from 'graphql/queries';
import { ICompany, User } from 'types/company.types';

interface UserDataValues {
  company?: ICompany;
  user?: User;
  refetch: () => void;
}

const useUserData = (): UserDataValues => {
  const { data: { company, user } = {}, refetch } = useQuery<UserDataValues>(
    GET_USER_LOGIN_DETAILS,
    {
      fetchPolicy: 'cache-only',
    }
  );

  return {
    company,
    user,
    refetch,
  };
};

export default useUserData;
