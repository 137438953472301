import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { styled } from '@mui/material/styles';
import { DeletableChip } from 'components/Forms';
import { ThemeTypography } from 'designSystem';
import React, { FC } from 'react';
import { StepIndexNumber } from './styles';
import { IChainActivity, IChainStep } from 'types/chain.types';
import { Booleanish, booleanish } from 'types/booleanish.types';

interface IIncomingLinkSelectionFieldProps {
  linkedActivities: string[];
  /* Only the steps before the currently selected step */
  linkableSteps: IChainStep[];
  /* Activities that are in a previous step */
  linkableActivities: IChainActivity[];
  disabled?: boolean;
  onChange: (activityIds: string[]) => void;
}

const StyledSelect = styled(Select<string[]>)<{ empty: booleanish }>(({ empty }) => ({
  '& .MuiSelect-select': {
    ...(empty === 'false' ? { padding: '5px 5px 2px 5px !important' } : {}),
    backgroundColor: '#fff',
  },
}));

const StyledLabel = styled(InputLabel)(({ theme }) => ({
  zIndex: 1,
  fontSize: 14,
  fontWeight: 500,
  color: theme.palette.grey[500],
  top: -6,
}));

const MenuSubtitle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0.5, 1),
  background: theme.palette.grey[200],
  fontWeight: 600,
  '& > div': {
    marginRight: theme.spacing(1),
  },
}));

const IncomingLinkSelectionField: FC<IIncomingLinkSelectionFieldProps> = ({
  linkableSteps,
  linkableActivities,
  linkedActivities,
  disabled,
  onChange,
}) => {
  const handleChange = (event: SelectChangeEvent<unknown>) => {
    onChange(event.target.value as string[]);
  };

  const handleRemoveLink = (id: string) => {
    onChange(linkedActivities.filter(activityId => activityId !== id));
  };

  const getSelectedActivity = (id: string) =>
    linkableActivities.find(activity => activity.id === id);

  return (
    <FormControl fullWidth disabled={disabled}>
      {/* Workaround to show a placeholder since it is not supported on the Select component */}
      {!linkedActivities?.length ? (
        <StyledLabel variant="outlined" shrink={false}>
          Select previous activities
        </StyledLabel>
      ) : (
        <></>
      )}
      <StyledSelect
        value={linkedActivities}
        empty={Booleanish(linkedActivities?.length === 0)}
        variant="outlined"
        multiple
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}
        fullWidth
        onChange={handleChange}
        defaultValue={[]}
        renderValue={selected =>
          selected.map(selectedId => {
            const activity = getSelectedActivity(selectedId);
            return (
              activity && (
                <DeletableChip
                  key={activity.id}
                  label={
                    <ThemeTypography variant="BODY_LARGE">
                      <b>{activity?.activity?.title}</b>: {activity?.activity?.component?.title}
                      {activity?.activity?.ownedBy?.name
                        ? ` (${activity.activity.ownedBy.name})`
                        : ''}
                    </ThemeTypography>
                  }
                  onDelete={() => handleRemoveLink(activity.id)}
                />
              )
            );
          })
        }
      >
        {linkableSteps.map(({ id, title }, index) => [
          linkableActivities.filter(({ pointPosition }) => pointPosition.x === index).length >
            0 && (
            <MenuSubtitle key={id}>
              <StepIndexNumber size="small">{index + 1}</StepIndexNumber>
              {title}
            </MenuSubtitle>
          ),
          linkableActivities
            .filter(({ pointPosition }) => pointPosition.x === index)
            .map(({ id, pointPosition: { x, y }, activity }) => (
              // Guarantee uniqueness since multiple activities can have the same id if imported multiple times
              <MenuItem key={`${id}-${x}-${y}`} value={id}>
                {activity.title}
              </MenuItem>
            )),
        ])}
      </StyledSelect>
    </FormControl>
  );
};

export default IncomingLinkSelectionField;
