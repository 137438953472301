import { SvgIcon } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ChevronDown } from '@styled-icons/bootstrap/ChevronDown';
import LogoIcon from 'assets/img/seedtrace_logo_icon_black_color.svg';
import Equal from 'assets/img/welcomeSlides/equal';
import Menu from 'assets/img/welcomeSlides/menu.svg';
import Pin from 'assets/img/welcomeSlides/pin';
import imageIllustration from 'assets/img/welcomeSlides/product-image-illustration.svg';
import Bubble from 'assets/img/welcomeSlides/speach_bubble';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

const Container = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: 10,
  boxShadow: theme.custom.shadows[3],
  height: 375,
  width: 192,
  background: `linear-gradient(180deg, #fff 0%, ${theme.custom.colors.backgroundLight} 25%, ${theme.custom.colors.backgroundLight} 100%)`,
  overflow: 'hidden',
  padding: 4,
}));

const AccordionContainer = styled('div')(() => ({
  position: 'absolute',
  bottom: 4,
  left: 4,
  right: 4,
  background: '#fff',
  height: 240,
  width: 'calc(100% - 8px)',
  borderTopRightRadius: 10,
  borderTopLeftRadius: 10,
}));

const ProductImage = styled('div')(({ theme }) => ({
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  left: '50%',
  top: 0,
  transform: 'translate3d(-50%, -80%, 0)',
  background: '#fff',
  width: 94,
  height: 94,
  borderRadius: 10,
  boxShadow: theme.custom.shadows[3],
}));

const Image = styled('img')(() => ({
  width: '48px !important',
}));

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Headline = styled(({ fontFamily, ...props }) => <div {...props} />)(({ fontFamily }) => ({
  fontSize: 18,
  fontFamily: `${fontFamily}, Lato`,
  fontWeight: 900,
}));

const From = styled('div')(() => ({
  fontSize: 11,
  fontWeight: 500,
}));

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Highlight = styled(({ color, ...props }) => <span {...props} />)(({ theme, color }) => ({
  color: color || theme.palette.primary.main,
  fontWeight: 700,
}));

const DetailsContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(1),
  padding: theme.spacing(2, 2, 1, 2),
}));

const AccordionList = styled('div')(({ theme }) => ({
  padding: theme.spacing(1),
  overflow: 'hidden',
}));

const AccordionItem = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  background: '#fff',
  padding: '6px 8px',
  marginBottom: theme.spacing(0.5),
  boxShadow: theme.custom.shadows[1],
  borderRadius: 8,

  '& svg': {
    width: 12,
    marginRight: theme.spacing(1),
  },
}));

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ItemTitle = styled(({ fontFamily, ...props }) => <div {...props} />)(({ fontFamily }) => ({
  textTransform: 'uppercase',
  fontSize: 9,
  fontWeight: 700,
  fontFamily: `${fontFamily}, Lato`,
}));

const Indicator = styled(ChevronDown)(() => ({
  position: 'absolute',
  right: 6,
  top: '50%',
  transform: 'translateY(-50%)',
}));

const Header = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  position: 'absolute',
  top: 0,
  width: '100%',
  padding: theme.spacing(0, 1),
}));

const HeaderItem = styled('img')(({ theme }) => ({
  marginTop: theme.spacing(1),
  width: '18px !important',
}));

const CustomerLogoContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: '#fff',
  width: '40px',
  height: '24px',
  borderBottomRightRadius: 4,
  borderBottomLeftRadius: 4,
  boxShadow: theme.custom.shadows[1],
  paddingBottom: 2,
}));

const ITEMS = [
  {
    title: 'Product Journey',
    icon: Pin,
  },
  {
    title: 'About',
    icon: Bubble,
  },
  {
    title: 'Fair Payments',
    icon: Equal,
  },
];

const addStylesheetURL = url => {
  var link = document.createElement('link');
  link.rel = 'stylesheet';
  link.href = url;
  document.getElementsByTagName('head')[0].appendChild(link);
};

const DesignPreview = ({ fontFamily, color, companyName, logoUrl }) => {
  useEffect(() => {
    addStylesheetURL(`https://fonts.googleapis.com/css2?family=${fontFamily}&display=swap`);
  }, [fontFamily]);

  return (
    <Container>
      <Header>
        <HeaderItem src={LogoIcon} alt="seedtrace logo icon" />
        {logoUrl && (
          <CustomerLogoContainer>
            <HeaderItem src={logoUrl} alt="logo" />
          </CustomerLogoContainer>
        )}
        <HeaderItem src={Menu} alt="menu" />
      </Header>
      <AccordionContainer>
        <ProductImage>
          <Image src={imageIllustration} alt="image illustration" />
        </ProductImage>
        <DetailsContainer>
          <Headline fontFamily={fontFamily}>Product name</Headline>
          <From>
            By <Highlight color={color}>{companyName}</Highlight>
          </From>
        </DetailsContainer>
        <AccordionList>
          {ITEMS.map(({ title, icon }, index) => (
            <AccordionItem key={`${title}-${index}`}>
              <SvgIcon stroke={color} component={icon} />
              <ItemTitle fontFamily={fontFamily}>{title}</ItemTitle>
              <Indicator size={8} />
            </AccordionItem>
          ))}
        </AccordionList>
      </AccordionContainer>
    </Container>
  );
};

DesignPreview.propTypes = {
  companyName: PropTypes.string.isRequired,
  fontFamily: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  logoUrl: PropTypes.string,
};

export default DesignPreview;
