import Icon from 'designSystem/Primitives/Icon/Icon';
import React, { FC } from 'react';
import { useMatch } from 'react-router-dom';

/**
 * This can either be a component or a component chain title
 */
const DrawerComponentItemIcon: FC = () => {
  const isComponentChainMapping = useMatch('/component-chains/:id/editor');

  if (isComponentChainMapping) {
    return <Icon name="component-chain" color="blue-frost" />;
  }
  return <Icon name="component" color="blue-frost" />;
};

export default DrawerComponentItemIcon;
