import { useQuery } from '@apollo/client';
import { Box, Grid, styled } from '@mui/material';
import EudrComplianceGuideSubSection from 'components/ComplianceGuide/EudrComplianceGuideSubSection';
import { useEudrComplianceGuide } from 'components/ComplianceGuide/EudrComplianceGuideContext';
import BatchResults from 'components/ComplianceGuide/components/BatchResults';
import BatchSelection from 'components/ComplianceGuide/components/BatchSelection';
import RiskAccordion from 'components/ComplianceGuide/components/RiskAccordion';
import { Loader } from 'components/Forms';
import { ErrorState } from 'components/Structure';
import PageSubTitle from 'components/Structure/PageSubTitle';
import { useFeatureFlags } from 'components/hooks';
import { FeatureFlag } from 'components/hooks/useFeatureFlags';
import { ThemeTypography } from 'designSystem';
import DatasetItem from 'designSystem/DataDisplay/DatasetItem/DatasetItem';
import Icon from 'designSystem/Primitives/Icon/Icon';
import { GET_GEOC_PARCELS } from 'graphql/queries/compliance.queries';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { GeoCBatch, GeocParcelConnection } from 'types/compliance.types';
import { RouteParamsWithId } from 'types/router.types';

const DataSheetContainer = styled('div')(({ theme }) => ({
  cursor: 'pointer',
}));

const DeforestationRiskResults: FC = () => {
  const { id: paramId } = useParams<RouteParamsWithId>();
  const { statement, updateSectionCompletion } = useEudrComplianceGuide();
  const [selectedDatasetId, setSelectedDatasetId] = useState<string>();
  const [selectedBatch, setSelectedBatch] = useState<GeoCBatch>();
  const { isFeatureEnabled } = useFeatureFlags();
  const disabledSectionAfterStep3 = isFeatureEnabled(FeatureFlag.DISABLE_EUDR_GUIDE_AFTER_STEP3);

  const {
    data: parcelsData,
    loading,
    error,
  } = useQuery<{ parcels: GeocParcelConnection }>(GET_GEOC_PARCELS, {
    variables: { geocBatchId: selectedBatch?.id, eudrStatementId: paramId }, // Pass all batch
    skip: !paramId || !selectedBatch?.id,
  });

  const parcels = useMemo(() => parcelsData?.parcels?.edges || [], [parcelsData]);

  useEffect(() => {
    updateSectionCompletion('risk-analysis'); // TODO: Do we need this ?
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!!statement?.eudrDatasets.length) {
      setSelectedDatasetId(statement.eudrDatasets[0].id);
    }
  }, [statement]);

  useEffect(() => {
    const selectedDataset = statement.eudrDatasets.find(({ id }) => id === selectedDatasetId);
    if (selectedDataset) {
      setSelectedBatch(selectedDataset.geocBatches[0]);
    }
  }, [statement, selectedDatasetId]);

  const handleClickDataSheet = (id: string) => {
    setSelectedDatasetId(id);
  };

  const handleBatchChange = (batch: GeoCBatch) => {
    setSelectedBatch(batch);
  };

  return (
    <>
      <EudrComplianceGuideSubSection
        disabledPreviousNavigation
        allowNextStepNavigation={!disabledSectionAfterStep3}
      >
        <Box display="flex" gap={2} flexWrap="wrap">
          {statement.eudrDatasets.map((dataSheet, index) => (
            <DataSheetContainer key={index} onClick={() => handleClickDataSheet(dataSheet.id)}>
              <DatasetItem
                title={dataSheet.title}
                location={dataSheet.originCountry}
                active={dataSheet?.id === selectedDatasetId}
                owner={dataSheet.ownedBy.name}
                commodity={dataSheet.rawMaterial.title}
              />
            </DataSheetContainer>
          ))}
        </Box>
      </EudrComplianceGuideSubSection>

      <Box my={4}>
        {selectedBatch && (
          <BatchSelection
            batches={
              statement.eudrDatasets.find(({ id }) => id === selectedDatasetId)?.geocBatches || []
            }
            selectedBatch={selectedBatch}
            onSelectBatch={handleBatchChange}
          />
        )}
      </Box>

      <Grid container spacing={2}>
        {!parcelsData ? (
          <Box position="relative" width="100%" mt={6}>
            {error && <ErrorState />}
            {loading && <Loader />}
          </Box>
        ) : (
          <>
            <Grid item xs={6}>
              <PageSubTitle title="Batch results" />
              <Box mt={0.5} />
              <BatchResults
                highRisks={parcelsData.parcels?.highRisks}
                mediumRisks={parcelsData.parcels?.mediumRisks}
                lowRisks={parcelsData.parcels?.lowRisks}
              />
            </Grid>
            <Grid item xs={6}>
              <Box display="flex" alignItems="center">
                <PageSubTitle title="Plot-specific results" />
                <Box mx={1}>
                  <Icon name="farm" size="large" />
                </Box>
                <ThemeTypography variant="BODY_SMALL" color="ALMOST_BLACK">
                  {parcelsData.parcels?.count} plots
                </ThemeTypography>
              </Box>
              <Box mt={0.5} />
              {parcelsData.parcels?.count > 0 &&
                parcels.map(({ node }) => (
                  <Box key={node.id} mb={1}>
                    <RiskAccordion parcel={node} />
                  </Box>
                ))}
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default DeforestationRiskResults;
