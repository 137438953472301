import { Box } from '@mui/material';
import EudrComplianceGuideSubSection from 'components/ComplianceGuide/EudrComplianceGuideSubSection';
import { useEudrComplianceGuide } from 'components/ComplianceGuide/EudrComplianceGuideContext';
import QnAGuideTemplate from 'components/ComplianceGuide/components/QnAGuideTemplate';
import AnnexTable from 'components/ComplianceGuide/components/data/AnnexTable';
import { PageSubTitle } from 'components/Structure';
import { ThemeButton } from 'designSystem';
import React, { FC, useState } from 'react';
import { EudrAffectednessType, EudrResponsibilityType } from 'types/compliance.types';

const AffectedCommodities: FC = () => {
  const {
    formData,
    selectedSubSection,
    selectedStatementId,
    setFormData,
    setSelectedSubSection,
    updateStatement,
  } = useEudrComplianceGuide();

  const [selectedAnswer, setSelectedAnswer] = useState<string | undefined>();

  const handleNextStepClick = () => {
    setFormData({
      ...formData,
      previousSubSectionKey: selectedSubSection?.key,
      affectedCommodities: selectedAnswer === 'yes',
    });
    if (formData.sourceOutsideOfEU === false) {
      // If answer is no, then the company is not affected, if answer is yes, then the company is indirectly affected as partner
      setSelectedSubSection(selectedAnswer === 'yes' ? 'indirectly-affected' : 'not-affected');
      if (selectedStatementId) {
        updateStatement(selectedStatementId, {
          companyAffectednessType:
            selectedAnswer === 'yes'
              ? EudrAffectednessType.INDIRECTLY_AFFECTED
              : EudrAffectednessType.NOT_AFFECTED,
          companyResponsibilityType:
            selectedAnswer === 'yes' ? EudrResponsibilityType.PARTNER : undefined,
        });
      }
    } else {
      setSelectedSubSection(
        selectedAnswer === 'yes' ? 'affectedness-market-placement' : 'affectedness-producer'
      );
    }
  };

  return (
    <EudrComplianceGuideSubSection
      allowNextStepNavigation={!!selectedAnswer}
      titleAdornment={
        <ThemeButton color="BLUE_ICE" disabled>
          Skip & apply previous affectedness
        </ThemeButton>
      }
      onNextStepClick={handleNextStepClick}
    >
      <QnAGuideTemplate
        selectedAnswer={selectedAnswer}
        question="Do you supply any of the products listed in Annex I to a company in the EU?"
        answers={[
          { id: 'yes', label: 'Yes' },
          {
            id: 'no',
            label: 'No',
          },
        ]}
        onAnswerChange={setSelectedAnswer}
      />

      <Box mt={2} />
      <PageSubTitle title="Annex I" />
      <Box mt={1} />
      <AnnexTable />
    </EudrComplianceGuideSubSection>
  );
};

export default AffectedCommodities;
