import { useQuery } from '@apollo/client';
import { useLogEvent } from 'components/hooks';
import { appQueryParams } from 'constants/appQueryParams';
import { GET_COMPONENTS_LIST } from 'graphql/queries';
import { useMemo } from 'react';
import { IComponentItem } from 'types/component.types';
import { GraphQlConnection } from 'types/types';
import { StringParam, useQueryParam } from 'use-query-params';
import { removeGraphConnections } from 'utils/graphql.utils';
import { useDebouncedCallback } from 'use-debounce';

const useComponentItems = ({ customSearch }: { customSearch?: string } = {}) => {
  const { data, error, loading, refetch } = useQuery<{
    components: GraphQlConnection<IComponentItem>;
  }>(GET_COMPONENTS_LIST);

  const { logEvent } = useLogEvent();

  const [searchTerm = '', setSearchTerm] = useQueryParam(appQueryParams.query, StringParam);

  const debouncedLogEvent = useDebouncedCallback(() => {
    logEvent('COMPONENT_SEARCHED', { searchTerm });
  }, 500);

  const handleSearchTermChange = (searchTerm: string) => {
    setSearchTerm(searchTerm);
    debouncedLogEvent.callback();
  };

  const components: IComponentItem[] = useMemo(() => {
    if (data?.components) {
      return removeGraphConnections(data.components);
    }
    return [];
  }, [data?.components]);

  const filteredComponents = useMemo(() => {
    const searchTermToUse = customSearch || searchTerm;
    const components = data?.components?.edges || [];
    if (!searchTermToUse?.length) return data?.components.edges.map(item => item.node);

    // filter products by title of supply chain
    const filtered = components.filter(
      ({ node: { title, articleNumber } }) =>
        title?.toLowerCase().includes(searchTermToUse.toLowerCase()) ||
        articleNumber?.toLowerCase()?.includes(searchTermToUse.toLowerCase())
    );

    return filtered.map(item => item.node);
  }, [searchTerm, customSearch, data?.components]);

  return {
    error,
    loading,
    components,
    filteredComponents,
    searchTerm,
    handleSearchTermChange,
    refetch,
  };
};

export default useComponentItems;
