import React from 'react';
import PropTypes from 'prop-types';
import { SlideIndicator } from 'components/Structure';
import { ControlBarContainer } from 'components/WelcomeSlides/styles';
import { styled } from '@mui/material/styles';
import ThemeButton from 'designSystem/Buttons/ThemeButton/ThemeButton';

const PrevButton = styled(ThemeButton)(({ theme }) => ({
  position: 'absolute',
  left: theme.spacing(4),
}));

const ControlBar = ({
  currentSlide,
  prevSlide,
  setCurrentSlide,
  slideCount,
  submitButton: SubmitButton,
}) => {
  return (
    <ControlBarContainer>
      {currentSlide !== 0 && (
        <PrevButton onClick={prevSlide} color="BLUE_ICE" size="large">
          Previous
        </PrevButton>
      )}
      <SlideIndicator
        activeIndex={currentSlide}
        slideCount={slideCount}
        onClick={setCurrentSlide}
      />
      {SubmitButton}
    </ControlBarContainer>
  );
};

ControlBar.propTypes = {
  submitButton: PropTypes.node.isRequired,
  currentSlide: PropTypes.number,
  prevSlide: PropTypes.func.isRequired,
  setCurrentSlide: PropTypes.func.isRequired,
  slideCount: PropTypes.number.isRequired,
};

export default ControlBar;
