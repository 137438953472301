import React from 'react';
import PropTypes from 'prop-types';
import MetricNumber from './MetricNumber';
import { millisecondsToMinutes } from 'components/Product/Analytics/utils';

const TimeNumber = ({ time, comparedLabel }) => {
  return (
    <MetricNumber
      total={time ? millisecondsToMinutes(time.current) : undefined}
      change={time?.percentageChange}
      comparedLabel={comparedLabel}
      label="Average time spent"
      unit="min"
    />
  );
};

TimeNumber.propTypes = {
  time: PropTypes.object,
  comparedLabel: PropTypes.string,
};

export default TimeNumber;
