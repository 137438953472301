export enum ILocationVisibility {
  APPROXIMATE = 'Show approximate location (Region)',
  COUNTRY = 'Show country of origin',
  EXACT = 'Show exact location (when available)',
}

export interface IVisibilitySettings {
  id: string;
  chainId: string;
  locationVisibility: keyof typeof ILocationVisibility;
}
