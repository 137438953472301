import { useMutation } from '@apollo/client';
import { GET_VIDEO_UPLOAD_LINK, UPDATE_VIDEO_UPLOAD_STATUS } from 'graphql/mutations';
import { GET_VIDEOS } from 'graphql/queries';
import { useCallback, useState } from 'react';
import * as tus from 'tus-js-client';

const useVideoUpload = () => {
  const [state, setState] = useState({
    uploadTask: null,
    record: null,
    error: null,
  });

  const [getUploadLink] = useMutation(GET_VIDEO_UPLOAD_LINK, {
    fetchPolicy: 'no-cache',
  });

  const [setUploadStatus] = useMutation(UPDATE_VIDEO_UPLOAD_STATUS, {
    refetchQueries: [
      {
        query: GET_VIDEOS,
      },
    ],
  });

  const uploadFile = useCallback(
    async ({
      file,
      onError = () => null,
      onSuccess = () => null,
      onProgress = () => null,
      onRecordCreated = () => null,
    }) => {
      try {
        const result = await getUploadLink({
          variables: {
            input: {
              fileName: file.name,
              fileSize: file.size,
            },
          },
        });

        const {
          video: { id },
          uploadLink,
        } = result.data.getVideoUploadLink;

        onRecordCreated({ id });

        const reader = new FileReader();
        const uploadTask = new tus.Upload(file, {
          uploadUrl: uploadLink,
          endpoint: uploadLink,
          metadata: {
            filename: file.name,
            filetype: file.type,
          },
          onError: () => {
            setUploadStatus({
              variables: {
                id,
                input: {
                  status: 'error',
                },
              },
            });

            onError();
          },
          onProgress: (bytesUploaded, bytesTotal) => {
            const progress = parseInt(((bytesUploaded / bytesTotal) * 100).toFixed(2));

            onProgress(progress);
          },
          onSuccess: async () => {
            // Todo: Update video doc that upload is completed
            setUploadStatus({
              variables: {
                id,
                input: {
                  status: 'success',
                },
              },
            });

            onSuccess();
          },
        });

        uploadTask.start();
        reader.readAsArrayBuffer(file);

        const newState = {
          ...state,
          uploadTask,
        };

        setState(newState);
        return newState;
      } catch (error) {
        const newState = {
          ...state,
          error: error.message,
        };

        setState(newState);
        onError();
        return newState;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return [uploadFile, { ...state }];
};

export default useVideoUpload;
