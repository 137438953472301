import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, Box } from '@mui/material';
import { Formik, Field } from 'formik';
import ControlBarCropping from './ControlBarCropping';
import CroppingField from './CroppingField';
import { styled } from '@mui/material/styles';
import * as Yup from 'yup';
import { mergeDefaults } from 'components/Forms/utils';
import { ErrorMessage } from 'components/Forms';

const StyledDialogContent = styled(DialogContent)(() => ({
  padding: '0 !important',
}));

const StyledBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}));

const StyledErrorMessage = styled(props => <ErrorMessage {...props} />)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(10),
  zIndex: 1,
}));

const CroppingDialog = ({ onClose, open, imageSrc, crop, onCropSelect }) => {
  const [imageSize, setImageSize] = useState(null);
  const [schema, setSchema] = useState(
    Yup.object().shape({
      x: Yup.number().default(0),
      y: Yup.number().default(0),
      width: Yup.number().default(0),
      height: Yup.number().default(0),
    })
  );

  useEffect(() => {
    if (imageSize) {
      const createCropSchema = max => {
        return Yup.number().default(0).min(0).max(max);
      };
      setSchema(
        Yup.object().shape({
          x: createCropSchema(imageSize.width),
          y: createCropSchema(imageSize.height),
          width: createCropSchema(imageSize.width),
          height: createCropSchema(imageSize.height),
        })
      );
    }
  }, [imageSize]);

  const initialValues = mergeDefaults(schema.default(), crop);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={values => onCropSelect(values)}
    >
      {({ handleSubmit, errors }) => (
        <Dialog fullWidth={true} maxWidth="md" open={open}>
          <StyledDialogContent>
            <StyledBox>
              {Object.entries(errors).length !== 0 && (
                <StyledErrorMessage>
                  Image crop has to be inside image dimensions of {imageSize.width}
                  px and {imageSize.height}
                  px
                </StyledErrorMessage>
              )}
              <ControlBarCropping
                imageSize={imageSize}
                onClose={onClose}
                onSubmit={handleSubmit}
                errors={errors}
              />
              <Field
                name="cropping"
                imageSrc={imageSrc}
                setImageSize={setImageSize}
                imageSize={imageSize}
                component={CroppingField}
              />
            </StyledBox>
          </StyledDialogContent>
        </Dialog>
      )}
    </Formik>
  );
};

CroppingDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  imageSrc: PropTypes.string.isRequired,
  crop: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
  }),
  onCropSelect: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default CroppingDialog;
