import { Box, IconButton } from '@mui/material';
import { FlexBox } from 'components/Structure';
import { DatasetBatch } from 'constants/schemas/riskAnalysis.schema';
import { CardContainer } from 'designSystem';
import DatePickerField from 'designSystem/Formik/DatePickerField/DatePickerField';
import Icon from 'designSystem/Primitives/Icon/Icon';
import { Field, FieldProps, useField } from 'formik';
import { TextField } from 'formik-mui';
import React, { FC, useEffect } from 'react';
import { v4 as uuid } from 'uuid';

interface IBatchCardFieldProps {
  enableDelete?: boolean;
  onBatchDelete: () => void;
}

const BatchCardField: FC<IBatchCardFieldProps & FieldProps<DatasetBatch>> = ({
  enableDelete,
  field: { name },
  onBatchDelete,
}) => {
  const [autoAssignIdsField] = useField<boolean>('autoAssignIds');
  const [{ value: externalBatchId }, , { setValue, setTouched }] = useField<string>(
    `${name}.externalBatchId`
  );

  useEffect(() => {
    if (autoAssignIdsField.value) {
      setValue(uuid());
      setTouched(true, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoAssignIdsField.value]);

  return (
    <CardContainer padding={1.5} width="100%">
      <FlexBox justifyContent="space-between">
        <FlexBox>
          <Icon name="batch" color="gray-100" size="xxx-large" mr={2} />
          <Field
            name={`${name}.externalBatchId`}
            disabled={autoAssignIdsField.value}
            size="small"
            component={TextField}
            // Bug fix to prevent label from overlapping with value when setting it to uuid
            InputLabelProps={{
              shrink: autoAssignIdsField.value || externalBatchId ? true : undefined,
            }}
            label="Origin batch ID"
          />
          <Box mr={2} />

          <Field
            name={`${name}.harvestDate`}
            size="small"
            component={DatePickerField}
            label="Harvest date"
          />
          <Box mr={2} />
          <Field
            name={`${name}.weight`}
            size="small"
            component={TextField}
            type="number"
            label="Quantity (kg)"
          />
        </FlexBox>
        {enableDelete && (
          <FlexBox>
            <IconButton onClick={onBatchDelete}>
              <Icon name="delete" />
            </IconButton>
          </FlexBox>
        )}
      </FlexBox>
    </CardContainer>
  );
};

export default BatchCardField;
