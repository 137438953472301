import { useEffect, useState } from 'react';
import useUploadState from 'components/hooks/useUploadState';

const useUploadItem = id => {
  const { fileMap } = useUploadState();
  const [item, setItem] = useState(fileMap[id] || {});
  const { progress, success, error } = fileMap[id] || {};

  useEffect(() => {
    setItem(fileMap[id] || {});
  }, [progress, success, error]); //eslint-disable-line

  return item;
};

export default useUploadItem;
