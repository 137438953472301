import { FormHelperText } from '@mui/material';
import { styled } from '@mui/material/styles';
import { SelectableMediaItem } from 'components/MediaLibrary';
import { getIn } from 'formik';
import React from 'react';

const Container = styled('div')({
  position: 'relative',
});

const determineMediaType = value => {
  if (!value || value.url) return 'image';
  if (value.vimeoLink || value.videoId) return 'video';
};

const MediaField = ({
  background,
  disabled,
  cropDisabled,
  singleUpload,
  moveLeft,
  moveRight,
  legacyImage = true,
  field: { name, value },
  onRemove,
  form: { isSubmitting, touched, errors, setFieldValue, validateOnMount },
  hideMediaIds,
  preventCleanFiles,
  ...props
}) => {
  const fieldError = getIn(errors, name);
  const showError = (getIn(touched, name) || validateOnMount) && !!fieldError;
  const type = determineMediaType(value);
  const imageTypeName = legacyImage ? 'ImageType' : 'ImageVariant';

  return (
    <Container>
      <SelectableMediaItem
        background={background}
        type={type}
        value={(value && (value.url || value.vimeoLink || value.hexCode)) || undefined}
        imageId={value && (value.imageId || value.id)}
        crop={(value && value.crop) || undefined}
        showEdit={!disabled}
        showError={showError}
        imageTypeName={imageTypeName}
        cropDisabled={cropDisabled}
        singleUpload={singleUpload}
        onRemove={onRemove}
        moveLeft={moveLeft}
        moveRight={moveRight}
        hideMediaIds={hideMediaIds}
        preventCleanFiles={preventCleanFiles}
        onChange={item => {
          if (disabled) return;
          const { id, videoId, imageId, url, vimeoLink, crop, __typename } = item;
          const isVideo = __typename === 'Video';
          const isCleared = !id && !url && !vimeoLink;

          if (isCleared) {
            setFieldValue(name, null);
          } else if (isVideo) {
            setFieldValue(name, {
              videoId,
              vimeoLink,
              __typename: 'Video',
            });
          } else {
            setFieldValue(name, {
              ...value,
              url,
              id,
              imageId,
              crop,
              __typename: imageTypeName,
            });
          }
        }}
        disabled={disabled || isSubmitting}
        {...props}
      />
      {showError && (
        <FormHelperText data-cy={`field-error-${name}`} error>
          {showError}
        </FormHelperText>
      )}
    </Container>
  );
};

export default MediaField;
