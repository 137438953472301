import { styled } from '@mui/material/styles';
import { useConfig } from 'components/hooks';
import { LevelPill } from 'components/ImpactClaims/ClaimShared';
import { impactLevelOptions } from 'components/ImpactClaims/constants/impactLevelOptions';
import { ThemeTypography } from 'designSystem';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

const Container = styled('div')(({ theme }) => ({
  border: '2px solid',
  borderColor: theme.custom.colors.backgroundLight,
  borderRadius: theme.shape.borderRadius,
}));

const Header = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(1.5, 2, 1.5, 2),
  fontWeight: 900,
  justifyContent: 'space-between',
}));

const PaddedHeader = styled(Header)(({ theme }) => ({
  paddingLeft: theme.spacing(5),
}));

const Body = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
  background: theme.custom.colors.backgroundLight,
}));

const SummaryImage = styled('img')(({ theme }) => ({
  width: 36,
  marginRight: theme.spacing(1),
}));

const TitleWrapper = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const BackgroundIcon = styled('img')(() => ({
  width: 36,
  position: 'absolute',
  left: 0,
  bottom: 0,
}));

const SummaryBox = ({
  title,
  category,
  imageUrl,
  variant = 'IMAGE',
  levelPillOptions,
  body,
  titleTestId,
}) => {
  const { claimCategories } = useConfig();
  const claimCategory = claimCategories[category?.type];
  const imagePath = imageUrl || claimCategory?.icon;

  const selectedImpactLevelInformation = useMemo(() => {
    if (!levelPillOptions) {
      return undefined;
    }

    return impactLevelOptions.find(level => level.value === levelPillOptions.level);
  }, [levelPillOptions]);

  return (
    <Container data-cy="summary-box">
      {variant === 'IMAGE' ? (
        <Header>
          <TitleWrapper>
            <SummaryImage src={imagePath} />
            <ThemeTypography variant="ITEM_TITLE" data-cy={titleTestId}>
              {title}
            </ThemeTypography>
          </TitleWrapper>
          {levelPillOptions && (
            <LevelPill
              claimLevel={selectedImpactLevelInformation?.label}
              title={levelPillOptions.title}
            />
          )}
        </Header>
      ) : (
        <PaddedHeader>
          <BackgroundIcon src={imagePath} />
          <ThemeTypography variant="ITEM_TITLE" data-cy={titleTestId}>
            {title}
          </ThemeTypography>
          {levelPillOptions && selectedImpactLevelInformation && (
            <LevelPill
              claimLevel={selectedImpactLevelInformation?.label}
              title={levelPillOptions.title}
            />
          )}
        </PaddedHeader>
      )}

      {body && <Body>{body}</Body>}
    </Container>
  );
};

SummaryBox.propTypes = {
  title: PropTypes.elementType.isRequired,
  imageUrl: PropTypes.string,
  category: PropTypes.object,
  variant: PropTypes.string,
  body: PropTypes.elementType,
  levelPillOptions: PropTypes.object,
  titleTestId: PropTypes.string,
};

export default SummaryBox;
