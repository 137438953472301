import { Theme } from '@mui/material';
import { styled } from '@mui/styles';

export const ClaimHistoryContainer = styled('div')(({ theme }: { theme: Theme }) => ({
  position: 'relative',
  maxWidth: 500,

  '&:before': {
    content: '""',
    display: 'block',
    position: 'absolute',
    zIndex: 0,
    width: '2px',
    left: '5px',
    top: '20px',
    bottom: '20px',
    background: theme.palette.background.default,
  },
}));

export const MiddleHistory = styled('div')(({ open }: { open: boolean }) => ({
  transition: 'all 0.4s ease',
  maxHeight: (open ? 500 : 0) + 'px',
  opacity: open ? 1 : 0,
  overflow: 'hidden',
}));

export const SeeHistoryButton = styled('button')(({ theme }: { theme: Theme }) => ({
  background: 'none',
  color: theme.palette.grey[400],
  border: 'none',
  fontSize: theme.typography.fontSize + 'px',
  padding: theme.spacing(1.5, 1, 1.5, 0),
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  gridGap: theme.spacing(1),
}));

export const MinimizeButton = styled('button')(({ theme }: { theme: Theme }) => ({
  background: 'none',
  color: theme.palette.grey[400],
  border: 'none',
  fontSize: theme.typography.fontSize + 'px',
  padding: theme.spacing(2, 1, 2, 2),
  cursor: 'pointer',
  textAlign: 'center',
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  gridGap: theme.spacing(1),
}));

export const ColoredDot = styled('div')(({ theme, color }: { theme: Theme; color: string }) => ({
  width: '11px',
  height: '11px',
  borderRadius: '50%',
  background: color,
  marginTop: theme.spacing(0.5),
  marginRight: theme.spacing(1),
  zIndex: 1,
}));

export const ClaimLogDate = styled('div')(({ theme }: { theme: Theme }) => ({
  color: theme.palette.grey[400],
  fontSize: 13,
}));

export const DotsBefore = styled('div')(({ theme }: { theme: Theme }) => ({
  background: '#fff',
  marginLeft: '-4px',
  padding: '3px 0',
  zIndex: 1,
  color: theme.palette.background.default,
}));

export const NameBox = styled('div')(({ theme }: { theme: Theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  background: theme.custom.themeColors.primary[5],
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
}));

export const AuthorIcon = styled('img')(({ theme }: { theme: Theme }) => ({
  width: 16,
  marginRight: theme.spacing(1),
}));
