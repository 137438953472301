import { Box, Checkbox } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Upload } from '@styled-icons/bootstrap/Upload';
import ThemeButton from 'designSystem/Buttons/ThemeButton/ThemeButton';
import xor from 'lodash/xor';
import PropTypes from 'prop-types';
import React from 'react';

const Container = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
  padding: theme.spacing(0.5, 1),
}));

const CheckboxWrapper = styled('div')(({ theme }) => ({
  marginRight: theme.spacing(2),
}));

const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  padding: theme.spacing(0.5, 1),
}));

const showFilterLabel = type => {
  switch (type) {
    case 'image':
      return 'Images';
    case 'video':
      return 'Video';
    default:
      return type;
  }
};

const LibraryFilterBar = ({
  selectedItemTypes,
  onItemTypeChange,
  selectableTypes,
  setUploadActive,
  hideUploadButton,
}) => {
  return (
    <Container>
      <Box display="flex" alignItems="center">
        {selectableTypes.length > 1 &&
          selectableTypes.map(type => (
            <CheckboxWrapper key={`media-check-${type}`}>
              <StyledCheckbox
                checked={selectedItemTypes.includes(type)}
                color="secondary"
                size="small"
                data-cy={`filter-check-${type}`}
                style={{
                  padding: 0,
                  marginRight: 2,
                }}
                onChange={() => {
                  onItemTypeChange(xor(selectedItemTypes, [type]));
                }}
              />
              {showFilterLabel(type)}
            </CheckboxWrapper>
          ))}

        {!hideUploadButton && (
          <ThemeButton
            onClick={setUploadActive}
            color="YELLOW"
            size="medium"
            data-cy="filter-bar-upload-button"
            startIcon={<Upload size={14} />}
          >
            Upload More
          </ThemeButton>
        )}
      </Box>
    </Container>
  );
};

LibraryFilterBar.propTypes = {
  selectedItemTypes: PropTypes.array.isRequired,
  onItemTypeChange: PropTypes.func.isRequired,
  selectableTypes: PropTypes.arrayOf(PropTypes.string),
  setUploadActive: PropTypes.func.isRequired,
  hideUploadButton: PropTypes.bool.isRequired,
};

export default LibraryFilterBar;
