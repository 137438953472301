import { Alert, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { FC, PropsWithChildren } from 'react';

const WarningText = styled(Typography)(({ theme }) => ({
  color: theme.palette.warning.main,
}));

const WarningMessage: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Alert variant="outlined" severity="warning" color="warning">
      <WarningText variant="body2" color="error">
        {children}
      </WarningText>
    </Alert>
  );
};

export default WarningMessage;
