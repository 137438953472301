import React, { Fragment } from 'react';
import { styled } from '@mui/material';
import PartnerList from './PartnerList';
import { IBasePartnerCompany } from 'types/partner.types';

export const RequestIcon = styled('img')(() => ({
  width: 18,
}));

interface IPartnerListOverview {
  partners: IBasePartnerCompany[];
}

const PartnerListOverview: React.FC<IPartnerListOverview> = ({ partners }) => {
  return <Fragment>{partners.length > 0 && <PartnerList partners={partners} />}</Fragment>;
};

export default PartnerListOverview;
