import { gql } from '@apollo/client';

export const VERSION_PROPS_FRAGMENT = gql`
  fragment versionProperties on ProductVersion {
    id
    productId
    published
    title
    webshopUrl
    image {
      imageId
      url
      crop {
        x
        y
        width
        height
      }
    }
  }
`;

export const MINIMAL_VERSION_PROPS_FRAGMENT = gql`
  fragment minimalVersionProperties on ProductVersion {
    id
    productId
  }
`;

export const VALIDATION_FRAGMENT = gql`
  fragment validationValues on Validation {
    __typename
    valid
    fields
    errors
  }
`;

export const VERSION_FRAGMENT = gql`
  fragment versionValues on ProductVersion {
    ...versionProperties
    contentBlocksValid @client @expensive
    generalValidation @client @expensive {
      ...validationValues
    }
    versionValidation @client @expensive {
      valid
      contentStatus
    }
    supplyChainValidation @client @expensive {
      ...validationValues
    }
  }
  ${VERSION_PROPS_FRAGMENT}
  ${VALIDATION_FRAGMENT}
`;
