import React, { useState } from 'react';
import PropTypes from 'prop-types';
import random from 'lodash/random';
import { Box, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { SupplyChainInspiration, TipCard } from 'components/Home';
import { CardIllustration } from 'designSystem';

const MAX_STEPS = 2;

const Inspiration = ({ showSupplyChainCard = true }) => {
  const theme = useTheme();
  const mediumScreen = useMediaQuery(theme.breakpoints.down('lg'));

  // Random the starting point of the tips
  const [activeCard, setActiveCard] = useState(random(0, 1));

  const handleShowNextCard = () => {
    setActiveCard(getNextActiveCardNumber(activeCard));
  };

  const getNextActiveCardNumber = currentNumber => {
    return currentNumber + 1 < MAX_STEPS ? currentNumber + 1 : 0;
  };

  return (
    <Box
      display="flex"
      flexDirection={mediumScreen ? 'column' : 'row'}
      gap={3}
      justifyContent="space-between"
      data-cy="inspiration-cards"
    >
      {activeCard === 0 && (
        <TipCard
          title="How to gain the trust of consumers?"
          content="It’s important to consider your target group when describing your products to your customers."
          readMoreUrl="https://seedtrace.org/blog/how-to-build-trust-with-consumers"
          handleShowNextCard={handleShowNextCard}
        >
          <CardIllustration image="TRUST" width={120} ml={-1} mb={-5} />
        </TipCard>
      )}
      {activeCard === 1 && (
        <TipCard
          title="How do I use transparency to convert more customers?"
          content="It matters how a message is delivered. Make sure you convey your key message to your consumers in the right way."
          readMoreUrl="https://seedtrace.org/blog/brand-transparency-and-honesty"
          handleShowNextCard={handleShowNextCard}
        >
          <CardIllustration image="IDEA" width={120} ml={-1} mb={-5} />
        </TipCard>
      )}
      {/*
      {activeCard === 2 && (
        <TipCard
          title="What do I really want my customers to understand?"
          content="It matters how a message is delivered. Make sure you convey your key message to your consumers in the right way."
          readMoreUrl="https://seedtrace.org"
          handleShowNextCard={handleShowNextCard}
        >
          <CardIllustration image="IDEA" width={160} ml={0} mb={-7} />
        </TipCard>
      )}
      */}
      {showSupplyChainCard && <SupplyChainInspiration />}
    </Box>
  );
};

Inspiration.propTypes = {
  showSupplyChainCard: PropTypes.bool,
};

export default Inspiration;
