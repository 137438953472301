import { Dialog, ModalProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { FC } from 'react';
import WelcomeSlidesCarousel from './WelcomeSlidesCarousel';

interface WelcomeSlidesDialogProps {
  isOpen: boolean;
  backdropClickEnabled: boolean;
  closeDialog: ModalProps['onClose'];
}

const StyledDialog = styled(Dialog)(() => ({
  width: 860,
  margin: 'auto',
}));

const WelcomeSlidesDialog: FC<WelcomeSlidesDialogProps> = ({
  isOpen,
  closeDialog,
  backdropClickEnabled,
}) => {
  return (
    <StyledDialog
      fullWidth={true}
      maxWidth="md"
      open={isOpen}
      onClose={backdropClickEnabled ? closeDialog : undefined}
    >
      <WelcomeSlidesCarousel />
    </StyledDialog>
  );
};

export default WelcomeSlidesDialog;
