import { Grid } from '@mui/material';
import { ThemeButton, FieldWrapper } from 'designSystem';
import { Field, Form, Formik } from 'formik';
import { Switch } from 'formik-mui';
import PropTypes from 'prop-types';
import React from 'react';
import * as Yup from 'yup';

const NotificationSchema = Yup.object().shape({
  weeklyRoundup: Yup.bool(),
});

const NotificationsForm = ({ onSubmit, notifications }) => {
  return (
    <Formik
      initialValues={notifications}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={NotificationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => (
        <Form>
          <Grid container spacing={3}>
            <Grid item xs={8}>
              <FieldWrapper
                label="Roundup Email"
                subtitle="We will send you emails regarding the performance of your products"
              >
                <Field component={Switch} type="checkbox" name="weeklyRoundup" />
              </FieldWrapper>
            </Grid>
            <Grid container item justifyContent="flex-end" alignItems="center">
              <Grid item>
                <ThemeButton color="YELLOW" size="large" loading={isSubmitting} type="submit">
                  Save
                </ThemeButton>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

NotificationsForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default NotificationsForm;
