import { useQuery } from '@apollo/client';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { X as Close } from '@styled-icons/bootstrap/X';
import { Loader } from 'components/Forms';
import { ReceivedRequestCard } from 'components/Home/Requests';
import usePartnerRequestActions from 'components/Partners/PartnerRequests/usePartnerRequestActions';
import { SlidesCloseButton, SlidesContainer, SlidesDialog } from 'components/SliderForm';
import { ErrorState } from 'components/Structure';
import { useMessages } from 'components/hooks';
import { CardContainer, DialogTitle, ThemeButton, ThemeTypography } from 'designSystem';
import ItemProgressTitle from 'designSystem/DataDisplay/ItemProgressTitle/ItemProgressTitle';
import Icon from 'designSystem/Primitives/Icon/Icon';
import { GET_BASE_PARTNER_REQUEST, GetPartnerRequestData } from 'graphql/queries/partner.queries';
import React, { FC } from 'react';
import { IMinBaseChain } from 'types/chain.types';
import { IDefaultDialogProps } from 'types/dialog.types';
import { PartnerRequest } from 'types/partner.types';
import { useRequestReplyDialogContext } from '../Contexts/RequestReplyDialogContext';
import RequestTitle from './RequestTitle';

interface IFinishChainRequestDialogProps extends IDefaultDialogProps {
  requestId: string;
  chain: IMinBaseChain;
}

const Container = styled('div')(() => ({
  display: 'flex',
  height: '100%',
  width: '100%',
}));

const DetailsColumn = styled('div')(({ theme }) => ({
  width: '50%',
  height: '100%',
  overflow: 'auto',
}));

const ResponseDetails = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(9.5, 5),
}));

const RequestColumn = styled('div')(({ theme }) => ({
  width: '50%',
  height: '100%',
  overflow: 'auto',
  padding: theme.spacing(2, 5, 3),
  background: theme.custom.colors.backgroundMedium,
}));

export const ButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  gridGap: theme.spacing(1),
  background: '#fff',
  marginTop: 'auto',
  padding: theme.spacing(3),
  position: 'absolute',
  bottom: 0,
  width: '50%',
  boxShadow: theme.shadows[28 as number],
  zIndex: 1,
}));

const FinishChainRequestDialog: FC<IFinishChainRequestDialogProps> = ({
  open,
  chain,
  requestId,
  onClose,
}) => {
  const { updatePartnerRequest, loading } = usePartnerRequestActions();
  const { openDismissDialog } = useRequestReplyDialogContext();
  const { setErrorMessage, setSuccessMessage } = useMessages();
  const { data, loading: loadingRequest } = useQuery<GetPartnerRequestData>(
    GET_BASE_PARTNER_REQUEST,
    {
      variables: {
        id: requestId,
      },
    }
  );

  const handleFulfillRequest = () => {
    updatePartnerRequest({
      variables: {
        id: requestId,
        input: {
          requestStatus: 'PROVIDED',
        },
      },
      onError: () =>
        setErrorMessage('Something went wrong finishing the request. Please try again later.'),
      onCompleted: () => {
        setSuccessMessage('You have fulfilled the request');
        onClose?.();
      },
    });
  };

  const handleDismissRequest = () => {
    onClose?.();
    openDismissDialog(requestId);
  };

  return (
    <SlidesDialog
      fullWidth={true}
      maxWidth="lg"
      open={!!open}
      onClose={onClose}
      data-cy="finish-chain-request-dialog"
    >
      <SlidesCloseButton onClick={onClose} data-cy="close-dialog-button">
        <Close size={20} />
      </SlidesCloseButton>
      <SlidesContainer>
        {loadingRequest ? (
          <Loader />
        ) : (
          <Container>
            {!data ? (
              <ErrorState error="Cannot load the request" />
            ) : (
              <>
                <RequestColumn>
                  <Box mb={4} ml={-3}>
                    <DialogTitle icon={<Icon name="chain-request-fulfilled" size="large" />}>
                      Finish chain mapping request
                    </DialogTitle>
                  </Box>
                  <Box mb={3}>
                    <RequestTitle request={data?.partnerRequest as PartnerRequest} />
                  </Box>
                  <Box mb={3}>
                    <ReceivedRequestCard
                      request={data?.partnerRequest as PartnerRequest}
                      displayActions={false}
                    />
                  </Box>
                </RequestColumn>
                <DetailsColumn>
                  <ResponseDetails>
                    <ThemeTypography variant="TITLE_SMALL" color="GRAY_40">
                      Response:
                    </ThemeTypography>
                    <Box mb={3} />
                    <CardContainer padding={1}>
                      <ItemProgressTitle
                        imageUrl={chain.image?.url}
                        backupIcon={
                          <Icon name="component-chain-image" size="xx-large" color="blue-ice" />
                        }
                        title={chain.title}
                        taskCompleted={chain.chainTasksDetails.completed}
                        taskTotal={chain.chainTasksDetails.total}
                      />
                    </CardContainer>
                  </ResponseDetails>
                  <ButtonContainer>
                    <ThemeButton
                      color="BLUE_ICE"
                      startIcon={<Icon name="chain-request-dismissed" />}
                      onClick={handleDismissRequest}
                    >
                      Dismiss request
                    </ThemeButton>
                    <Box display="flex" gap={1}>
                      <ThemeButton color="BLUE_ICE" size="medium" onClick={onClose}>
                        Cancel
                      </ThemeButton>
                      <ThemeButton
                        data-cy="submit-request-form-btn"
                        color="YELLOW"
                        type="submit"
                        size="medium"
                        onClick={handleFulfillRequest}
                        loading={loading}
                      >
                        Save & Share
                      </ThemeButton>
                    </Box>
                  </ButtonContainer>
                </DetailsColumn>
              </>
            )}
          </Container>
        )}
      </SlidesContainer>
    </SlidesDialog>
  );
};

export default FinishChainRequestDialog;
