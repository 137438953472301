import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Skeleton } from 'designSystem';
import claimCheck from 'assets/img/claim_check.svg';

const TitleIcon = styled('img')(({ theme }) => ({
  width: 70,
  marginLeft: theme.spacing(-1.5),
  marginBottom: theme.spacing(-1.5),
}));

const ImpactClaimSkeleton = () => {
  return (
    <Box display="flex" alignItems="center">
      <TitleIcon src={claimCheck} />
      <Box ml={1} gap={2.5}>
        <Skeleton width={150} height={25} mb={1} animation={false} />
        <Skeleton width={105} height={20} animation={false} />
      </Box>
      <Box ml="auto">
        <Skeleton width={63} height={25} animation={false} />
      </Box>
    </Box>
  );
};

ImpactClaimSkeleton.propTypes = {
  title: PropTypes.string,
  loading: PropTypes.bool,
};

export default ImpactClaimSkeleton;
