import { GraphQlConnection } from 'types/types';
import { PartnerRequest } from './partner.types';
import { IDocument } from './document.types';

export enum ImpactClaimStatus {
  'CREATED' = 'CREATED',
  'UPDATED' = 'UPDATED',
  'REQUESTED' = 'REQUESTED',
  'APPROVED' = 'APPROVED',
  'DENIED' = 'DENIED',
  'ARCHIVED' = 'ARCHIVED',
  'DELETED' = 'DELETED',
}

export interface ImpactClaimCreateInput {
  impactCatalogItemId: string;
  attachments: string[];
  productId?: string;
  componentId?: string;
  assignedToId?: string;
  status?: ImpactClaimStatus;
  requestIds: string[];
}
export interface CreateImpactClaimPayload {
  createImpactClaim: {
    impactClaim: ImpactClaim;
  };
}

interface ImpactClaimProductSummary {
  id: string;
  title: string;
  publicUrl: string;
}

interface ImpactClaimCompanySummary {
  id: string;
  name: string;
}

export interface ImpactClaim {
  id: string;
  impactCatalogItem: ImpactCatalogItem;
  createdTimestamp: Date;
  status: ImpactClaimStatus;
  product?: ImpactClaimProductSummary;
  assignedTo?: ImpactClaimCompanySummary;
  attachments: GraphQlConnection<IDocument>;
  attachmentCount: number;
  PartnerRequests: GraphQlConnection<PartnerRequest>;
  logs: ImpactClaimLog[];

  // Type policies
  impactLevel: 'BUSINESS' | 'PRODUCT' | 'PARTNER';
  impactLevelTitle?: string;
  isPending: boolean;
  isApproved: boolean;
  isInvalid: boolean;
}

export interface ImpactClaimLog {
  comment: string | null;
  timestamp: Date;
  action: ImpactClaimStatus;
  user: {
    email: string;
    firstName: string;
    lastName: string;
  };
}

export interface ImpactCatalogItem {
  id: string;
  title: string;
  definition: string;
  category: ImpactCatalogCategory;
  types: ImpactCatalogItemType[];
  links: string[];
  thirdParties: string[];
  iconUrl?: string;
  sustainableDevGoals: SustainableDevGoal[];
  acceptanceCriteria: ImpactCatalogItemAcceptanceCriteria[];
  docRequirements: ImpactCatalogItemDocRequirement[];
}

interface ImpactCatalogCategory {
  id: string;
  title: string;
  type: ImpactCatalogCategoryType;
}

export enum ImpactCatalogCategoryType {
  'SOCIAL' = 'SOCIAL',
  'ECOLOGICAL' = 'ECOLOGICAL',
}

enum ImpactCatalogItemType {
  'CLAIM' = 'CLAIM',
  'PROOF_POINT' = 'PROOF_POINT',
}

interface SustainableDevGoal {
  id: string;
  number: number;
  title: string;
  description: string;
  color: string;
  iconUrl: string;
}

interface ImpactCatalogItemAcceptanceCriteria {
  id: string;
  description: string;
}

interface ImpactCatalogItemDocRequirement {
  id: string;
  description: string;
}
export interface ImpactCatalogItemRequest {
  id: string;
  userId: string;
  title: string;
  description?: string;
  createdTimestamp: Date;
}

export enum DisclaimerTarget {
  'SELECT_CLAIM_SLIDE' = 'SELECT_CLAIM_SLIDE',
  'REQUEST_CLAIM_DIALOG' = 'REQUEST_CLAIM_DIALOG',
}
