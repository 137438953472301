import { Box, DialogActions, DialogContent } from '@mui/material';
import { FlexBox } from 'components/Structure';
import { useConfig } from 'components/hooks';
import { ThemeButton, ThemeTypography } from 'designSystem';
import DialogDefault from 'designSystem/Overlays/Dialog/DialogDefault/DialogDefault';
import React, { FC, useState } from 'react';
import { IDefaultDialogProps } from 'types/dialog.types';

interface IUnpublishDialogProps extends IDefaultDialogProps {
  currentProductLang: string;
  // If true, the unpublish all languages button is visible
  hasMultiplePublishedLanguages?: boolean;
  loading: boolean;
  onUnpublishAllLanguages: () => void;
  onUnpublishLanguages: (lang: string) => void;
}

const UnpublishDialog: FC<IUnpublishDialogProps> = ({
  open,
  currentProductLang,
  hasMultiplePublishedLanguages,
  loading,
  onClose,
  onUnpublishAllLanguages,
  onUnpublishLanguages,
}) => {
  const config = useConfig();
  const languageTitle = config.getLanguageConfig(currentProductLang)?.title;

  const [unpublishAllClicked, setUnpublishAllClicked] = useState<boolean>(false);

  const handleUnpublishAll = () => {
    setUnpublishAllClicked(true);
    onUnpublishAllLanguages();
  };

  return (
    <DialogDefault open={open} title="Unpublish product page" onClose={onClose}>
      <DialogContent>
        <ThemeTypography variant="BODY_LARGE">
          Do you want to unpublish this product page and convert to draft?
        </ThemeTypography>
        <Box mb={2} />
        <ThemeTypography variant="BODY_LARGE">
          This will remove access for any external page visitors scanning the product QR code or
          opening your product page link.
        </ThemeTypography>
        <Box mb={2} />
        <ThemeTypography variant="BODY_LARGE">
          Choose if you want to unpublish current language, or all languages.
        </ThemeTypography>
      </DialogContent>
      <DialogActions>
        <FlexBox
          justifyContent="space-between"
          width={hasMultiplePublishedLanguages ? '100%' : 'inherit'}
        >
          {hasMultiplePublishedLanguages && (
            <ThemeButton
              color="BLUE_ICE"
              size="large"
              disabled={loading}
              loading={unpublishAllClicked && loading}
              onClick={handleUnpublishAll}
              data-cy="button-unpublish-all"
            >
              Unpublish all languages
            </ThemeButton>
          )}
          <ThemeButton
            color="YELLOW"
            size="large"
            disabled={loading}
            loading={!unpublishAllClicked && loading}
            onClick={() => onUnpublishLanguages(currentProductLang)}
            data-cy="button-unpublish-language"
          >
            Unpublish {languageTitle}
          </ThemeButton>
        </FlexBox>
      </DialogActions>
    </DialogDefault>
  );
};

export default UnpublishDialog;
