import { Select } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

const StyledSelect = styled(props => <Select {...props} />)(({ theme }) => ({
  borderRadius: theme.spacing(0.5),
  boxShadow: theme.custom.shadows[1],
  padding: theme.spacing(1),

  '& .MuiChip-root': {
    margin: 0,
  },

  '& .MuiSelect-select': {
    padding: '0 !important',
  },
}));

export default StyledSelect;
