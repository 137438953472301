import { Dialog, DialogTitle } from '@mui/material';
import LanguageSettingsContent from 'components/Product/MultiStep/FormParts/LanguageSettingsContent';
import React, { FC } from 'react';
import { IDefaultDialogProps } from 'types/dialog.types';

interface ILanguageSettingDialogProps extends IDefaultDialogProps {
  productId: string;
}

const LanguageSettingDialog: FC<ILanguageSettingDialogProps> = ({ open, productId, onClose }) => (
  <Dialog open={!!open} onClose={onClose} maxWidth="md" fullWidth>
    <DialogTitle>Product language settings</DialogTitle>
    <LanguageSettingsContent productId={productId} onClose={onClose} />
  </Dialog>
);

export default LanguageSettingDialog;
