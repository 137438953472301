import { FormHelperText, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ImageFill } from '@styled-icons/bootstrap/ImageFill';
import { useLogEvent } from 'components/hooks';
import { useImageUpload, useSingleImageUpload } from 'components/MediaLibrary/hooks';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import ProgressIndicator from './ProgressIndicator';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Container = styled(({ isDragActive, error, styles, ...props }) => <div {...props} />)(
  ({ theme, error, styles, isDragActive }) => {
    const activeStyles = {
      cursor: 'pointer',
      borderColor: !error && theme.custom.colors.blueFrost,

      '& a': {
        textDecoration: 'underline',
      },

      '& .placeholder-icon': {
        color: theme.custom.colors.blueFrost,
      },

      '& .action-buttons, & .moving-buttons': {
        opacity: 1,
      },
    };

    const defaultStyles = {
      position: 'relative',
      display: 'flex',
      height: '100%',
      width: '100%',
      border: '2px dashed',
      borderColor: error ? theme.palette.error.main : theme.custom.colors.backgroundLight,
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 4,
      background: '#fff',
      color: theme.palette.secondary.main,
      textAlign: 'center',
      ...styles,

      '&:hover': activeStyles,

      '& .dropzone': {
        flex: 1,
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        outline: 'none',
      },

      '& .placeholder-icon': {
        color: theme.custom.colors.backgroundLight,
      },

      '& .MuiFormHelperText-root': {
        position: 'absolute',
        bottom: 0,
        left: 0,
        transform: 'translateY(110%)',
      },
    };

    if (isDragActive) {
      return {
        ...defaultStyles,
        ...activeStyles,
      };
    }

    return defaultStyles;
  }
);

const Placeholder = styled(ImageFill)(({ theme }) => ({
  color: theme.custom.colors.backgroundLight,
  transition: 'color 0.125s ease',
}));

const UploadText = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.light,
  fontSize: 12,
  marginTop: theme.spacing(0.5),
}));

const ActionLink = styled('a')(() => ({
  cursor: 'pointer',
  fontWeight: 700,
}));

const StyledProgress = styled(ProgressIndicator)(({ theme }) => ({
  position: 'absolute',
  bottom: theme.spacing(1.5),
  left: '50%',
  transform: 'translateX(-50%)',
}));

const SelectableField = ({
  singleUpload,
  onUploadComplete,
  showError,
  disabled,
  allowedFileTypes = ['jpg, png'],
  onClick,
}) => {
  // single image is not stored to library. Used for e.g. company logo
  const [uploadImage] = useSingleImageUpload();
  const [uploadImageToLibrary] = useImageUpload();
  const { logEvent } = useLogEvent();
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [progress, setProgress] = useState();
  const [previewURL, setPreviewURL] = useState(null);
  const [uploadedItem, setUploadedItem] = useState(null);
  const [uploading, setUploading] = useState(false);

  const determineUploadType = isSingleUpload => {
    if (isSingleUpload) {
      return uploadImage;
    } else {
      return uploadImageToLibrary;
    }
  };

  const onDrop = async (acceptedFiles, fileRejections) => {
    if (fileRejections.length > 1) {
      setError('Only one file allowed');
    }

    if (fileRejections.length === 1 && fileRejections[0].file?.type?.includes('video')) {
      setError('Upload & select videos from library');
    } else if (fileRejections.length === 1) {
      setError(`Only filetypes allowed: ${allowedFileTypes.join(', ')}`);
    }

    const uploadMethod = determineUploadType(singleUpload);

    if (acceptedFiles.length === 1) {
      if (acceptedFiles[0].size > 2000000) {
        setError('File size too large (2mb)');
      } else {
        setUploading(true);
        setError(null);
        setPreviewURL(URL.createObjectURL(acceptedFiles[0]));
        // set item already to display file name overwrite later
        setUploadedItem({
          fileName: acceptedFiles[0]?.name,
        });

        const item = await uploadMethod({
          file: acceptedFiles[0],
          onError: error => setError(error),
          onProgress: progress => setProgress(progress),
          onSuccess: () => {
            setSuccess(true);
            logEvent('UPLOAD_FILE');
            onUploadComplete(item.record);
          },
        });
        setUploadedItem(item.record);
      }
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/webp': ['.webp'],
      'image/heic': ['.heic'],
      'image/gif': ['.gif'],
    },
    maxFiles: 1,
    disabled,
  });

  // don't open native select if we want to select from media library
  const onDropZoneClick = event => {
    if (typeof onClick === 'function') {
      event.stopPropagation();
      onClick();
    }
  };

  useEffect(() => {
    setUploading(!success && !error && !isNaN(progress));
  }, [success, error, progress]);

  return (
    <Container isDragActive={isDragActive} error={showError || error}>
      <div
        {...getRootProps({
          className: 'dropzone',
          disabled: uploading || disabled,
          onClick: onDropZoneClick,
        })}
        data-cy="image-dropzone"
      >
        <input {...getInputProps()} />
        <div>
          <Placeholder className="placeholder-icon" size={70} />
          {uploading ? (
            <StyledProgress
              previewURL={previewURL}
              progress={progress}
              fileName={uploadedItem?.fileName}
            />
          ) : (
            <UploadText>
              Drop image here or <br />
              {singleUpload ? (
                <ActionLink>browse</ActionLink>
              ) : (
                <ActionLink>Select from library</ActionLink>
              )}
            </UploadText>
          )}
        </div>
      </div>
      {/* only show error from upload if there is no field error e.g. field required */}
      {error && !showError && <FormHelperText error>{error}</FormHelperText>}
    </Container>
  );
};

SelectableField.propTypes = {
  singleUpload: PropTypes.bool,
  onUploadComplete: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  showError: PropTypes.bool,
  allowedFileTypes: PropTypes.arrayOf(PropTypes.oneOf(['jpg', 'png'])),
  onClick: PropTypes.func,
};

export default SelectableField;
