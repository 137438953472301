import { ApolloCache } from '@apollo/client';
import { GET_PARTNER_USER_HOME_DATA, GET_PRODUCTS_HOME } from 'graphql/queries';
import {
  GET_ALL_PARTNER_COMPANIES,
  GET_PARTNER_OVERVIEW,
  GetPartnerUserHomeDataValues,
} from 'graphql/queries/partner.queries';
import { IBasePartnerCompany, IPartnerCompany, PartnerRequest } from 'types/partner.types';
import { GraphQlConnection, Product } from 'types/types';

export const addNewPartnerToCache = (
  cache: ApolloCache<unknown>,
  newPartner: IBasePartnerCompany
) => {
  const result = cache.readQuery<{ partners: GraphQlConnection<IBasePartnerCompany> }>({
    query: GET_ALL_PARTNER_COMPANIES,
  });

  const partners = result?.partners || { edges: [] };

  cache.writeQuery({
    query: GET_ALL_PARTNER_COMPANIES,
    data: {
      partners: {
        edges: [
          {
            node: newPartner,
            __typename: 'PartnerEdge',
          },
          ...partners.edges,
        ],
      },
    },
  });
};

export const removeRequestFromCachePartnerHome = (
  cache: ApolloCache<unknown>,
  removedRequest: PartnerRequest
) => {
  const cachedData = cache.readQuery<GetPartnerUserHomeDataValues>({
    query: GET_PARTNER_USER_HOME_DATA,
    variables: {
      filters: {
        requestStatus: ['REQUESTED'],
      },
    },
  });
  const updatedRequests = cachedData?.partnerRequests?.edges.filter(
    request => removedRequest.id !== request.node.id
  );

  cache.writeQuery({
    query: GET_PARTNER_USER_HOME_DATA,
    data: {
      documents: cachedData?.documents,
      partnerRequests: { edges: updatedRequests },
    },
    variables: {
      filters: {
        requestStatus: ['REQUESTED'],
      },
    },
  });
};

export const addRequestToCachePartnerOverview = (
  cache: ApolloCache<unknown>,
  request: PartnerRequest,
  companyId: string
) => {
  const cachedData = cache.readQuery<
    {
      partner: IPartnerCompany;
    },
    {
      id: string;
    }
  >({
    query: GET_PARTNER_OVERVIEW,
    variables: {
      id: companyId,
    },
  });

  if (!cachedData) return;

  const updatedRequests = [...cachedData.partner.requests.edges, { node: request }];

  cache.writeQuery({
    query: GET_PARTNER_OVERVIEW,
    data: {
      partner: { ...cachedData?.partner, requests: { edges: updatedRequests } },
    },
    variables: {
      id: companyId,
    },
  });
};

export const removeRequestFromCacheBuyerHome = (
  cache: ApolloCache<unknown>,
  removedRequest: PartnerRequest
) => {
  const cachedData = cache.readQuery<{
    products: GraphQlConnection<Product>;
    overallProductStatus: { status: string; homeTitle: string };
    companyAnalyticsExist: boolean;
    partnerRequests: GraphQlConnection<PartnerRequest>;
  }>({
    query: GET_PRODUCTS_HOME,
    variables: {
      filters: {
        requestStatus: ['DENIED', 'PROVIDED'],
      },
    },
  });

  if (!cachedData) return;

  const updatedRequests = cachedData.partnerRequests?.edges.filter(
    request => removedRequest.id !== request.node.id
  );

  cache.writeQuery({
    query: GET_PRODUCTS_HOME,
    data: {
      ...cachedData,
      partnerRequests: { edges: updatedRequests },
    },
    variables: {
      filters: {
        requestStatus: ['DENIED', 'PROVIDED'],
      },
    },
  });
};
