import { Autocomplete, Box, FormControl, FormHelperText, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import defaultPartnerImage from 'assets/img/partners/default-partner-image.png';
import Image from 'designSystem/DataDisplay/Image/Image';
import PaperWithAddItem from 'designSystem/Inputs/Paper/Paper';
import useSites from 'hooks/useSites';
import React, { ChangeEvent, FC, useState } from 'react';
import { ISite } from 'types/site.types';
import AddEditSiteDialog from '../AddEditSiteDialog';

interface ISitesAutocompleteProps {
  defaultValue?: ISite;
  placeholder?: string;
  size?: 'small' | 'medium';
  disabled?: boolean;
  fontSize?: number;
  hasError?: boolean;
  errorMessage?: string;
  onSiteSelect: (site: ISite | undefined) => void;
}

const StyledAutocomplete = styled(Autocomplete<ISite>)<{ fontSize?: number }>(({ fontSize }) => ({
  '& .MuiAutocomplete-inputRoot': {
    paddingLeft: '16px !important',
    fontSize: fontSize ? fontSize : 12,
  },
  '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
    padding: 4,
    '& .MuiInputBase-input': {
      fontSize: fontSize ? fontSize : 12,
    },
  },
  '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"]':
    {
      padding: 4,
      '& .MuiInputBase-input': {
        fontSize: fontSize ? fontSize : 12,
      },
    },
}));

const StyledFormHelperText = styled(FormHelperText)(() => ({
  '&.MuiFormHelperText-root': {
    marginTop: -2,
  },
}));

const SitesAutocomplete: FC<ISitesAutocompleteProps> = ({
  defaultValue,
  placeholder = 'Select site',
  size = 'medium',
  disabled,
  fontSize,
  hasError,
  errorMessage = 'Please select a site',
  onSiteSelect,
}) => {
  const [createSiteDialogOpen, setCreateSiteDialogOpen] = useState(false);
  const [selectedSite, setSelectedSite] = useState<ISite | null>(defaultValue || null);
  const sizeImage = size === 'small' ? 14 : 17;
  const { sites, loading, refetch } = useSites({ queryAllSites: true });

  const handleSelectSite = (event: ChangeEvent<unknown>, site: ISite | null) => {
    setSelectedSite(site);
    onSiteSelect(site || undefined);
  };

  const handleNewSiteCreated = async (site: ISite) => {
    await refetch();
    setSelectedSite(site);
    onSiteSelect(site);
  };

  return (
    <FormControl fullWidth size={size} error={hasError}>
      <StyledAutocomplete
        loading={loading}
        loadingText="Loading sites..."
        value={selectedSite}
        options={sites}
        data-cy="sites-autocomplete"
        onChange={handleSelectSite}
        fullWidth
        fontSize={fontSize}
        size={size}
        disabled={disabled}
        PaperComponent={({ children }) => (
          <PaperWithAddItem
            data-testId="create-sites-autocomplete"
            title="Create new site"
            onClick={() => setCreateSiteDialogOpen(true)}
          >
            {children}
          </PaperWithAddItem>
        )}
        getOptionLabel={option => option.title}
        renderOption={(props, option: ISite) => (
          <li {...props} key={option.id} data-cy="sites-autocomplete-option">
            <Box mr={2} display="flex" alignItems="center">
              <Image
                width={sizeImage}
                height={sizeImage}
                image={option.image}
                backupImageUrl={defaultPartnerImage}
                borderRadius={0}
              />
            </Box>
            {option.title}
          </li>
        )}
        renderInput={params => (
          <TextField
            {...params}
            error={hasError}
            variant="outlined"
            placeholder={placeholder}
            onKeyPress={keyEvent => {
              // Prevents the form from being submitted on enter.
              if (keyEvent.key === 'Enter') {
                keyEvent.preventDefault();
              }
            }}
            data-cy="sites-autocomplete-input"
            InputProps={{
              style: { height: size === 'medium' ? 40 : 30 },
              ...params.InputProps,
              // @ts-ignore
              'data-testId': 'sites-autocomplete-input',
              startAdornment: selectedSite ? (
                <Image
                  width={sizeImage}
                  height={sizeImage}
                  image={selectedSite.image}
                  backupImageUrl={defaultPartnerImage}
                  borderRadius={0}
                />
              ) : (
                <Image
                  width={sizeImage}
                  height={sizeImage}
                  backupImageUrl={defaultPartnerImage}
                  borderRadius={0}
                />
              ),
            }}
          />
        )}
      />
      {hasError && <StyledFormHelperText>{errorMessage}</StyledFormHelperText>}

      {/* Workaround: This dialog needs to live here and NOT in the context since the callback is changing the state */}
      <AddEditSiteDialog
        open={createSiteDialogOpen}
        onSiteCreated={handleNewSiteCreated}
        onClose={() => setCreateSiteDialogOpen(false)}
      />
    </FormControl>
  );
};

export default SitesAutocomplete;
