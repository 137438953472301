import { useCompanyPlanContext } from 'contexts/CompanyPlanContext';
import React, { Fragment } from 'react';
import { CustomerPlanType } from 'types/company.types';

interface Props {
  allowedPlans: CustomerPlanType[];
  children: React.ReactNode;
}

const HasAccess: React.FC<Props> = ({ allowedPlans, children }) => {
  const { plan } = useCompanyPlanContext();

  if (allowedPlans.includes(plan)) {
    return <Fragment>{children}</Fragment>;
  }

  return null;
};

export default HasAccess;
