export const GRAPH_MEDIUM = {
  COLUMN_WIDTH: 180,
  FIRST_COLUMN_WIDTH: 220,
  HEADER_HEIGHT: 38,
  NODE_HEIGHT: 120, // NODE_WIDTH -> COLUMN_WIDTH - PADDING * 2
  SPACING: 8,
  CONNECTION_SIZE: 8,
  TITLE_SIZE: 12, // TODO: use
  DEFAULT_TEXT_SIZE: 11,
  IMAGE_SIZE: 24,
};

export const STEP_BY_STEP_LIST_HEIGHT = 55;
