import React from 'react';
import PropTypes from 'prop-types';
import { ProductSummaryCard } from 'components/Product/ProductTable';
import { GettingStartedActions } from 'components/Home';
import { Box } from '@mui/material';
import { useProductActions } from 'components/hooks';
import { ItemSkeleton, CardIllustration, CardContainer } from 'designSystem';
import { BackgroundTypes, AvailableSizes } from 'types/enums';

const GettingStarted = ({
  products,
  overallStatus,
  homeTitle,
  highlightedProduct,
  loading,
  size,
}) => {
  const { navigateToProductOverview } = useProductActions();

  return (
    <CardContainer
      title={homeTitle}
      size={size ? size : products.length > 1 ? AvailableSizes.MEDIUM : AvailableSizes.LARGE}
      background={BackgroundTypes.WORLD}
      data-cy="home-getting-started-block"
      loading={loading}
      renderIllustration={<CardIllustration image="LOCATIONS" width={260} right={0} top={0} />}
    >
      <Box pb={2} mt="auto">
        {highlightedProduct ? (
          <CardContainer
            padding={1.5}
            maxWidth="550px"
            onClick={() => navigateToProductOverview(highlightedProduct.id)}
          >
            <ProductSummaryCard product={highlightedProduct} />
          </CardContainer>
        ) : (
          <ItemSkeleton variant="PRODUCT" />
        )}
      </Box>
      <GettingStartedActions
        overallStatus={overallStatus}
        highlightedProduct={highlightedProduct}
      />
    </CardContainer>
  );
};

GettingStarted.propTypes = {
  products: PropTypes.array.isRequired,
  highlightedProduct: PropTypes.object,
  overallStatus: PropTypes.string,
  homeTitle: PropTypes.string,
  loading: PropTypes.bool,
  size: PropTypes.oneOf(['SMALL', 'MEDIUM', 'LARGE']),
};

export default GettingStarted;
