import React from 'react';
import { useWelcomeSlides } from 'components/WelcomeSlides/hooks';
import { SlideIndicator } from 'components/Structure';
import { PrevButton, NextButton, ControlBarContainer } from './styles';

const TextControlBar = () => {
  const { currentSlide, nextSlide, prevSlide, slides, setCurrentSlide } = useWelcomeSlides();

  return (
    <ControlBarContainer>
      {currentSlide !== 0 && (
        <PrevButton onClick={prevSlide} inverted rtl>
          Previous
        </PrevButton>
      )}
      <SlideIndicator
        activeIndex={currentSlide}
        slideCount={slides.length}
        onClick={setCurrentSlide}
        stepColor="WHITE"
      />
      <NextButton onClick={nextSlide} inverted>
        Next
      </NextButton>
    </ControlBarContainer>
  );
};

export default TextControlBar;
