import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ArrowRight } from '@styled-icons/bootstrap/ArrowRight';
import { ThemeTypography } from 'designSystem';

const Item = styled('div')(({ theme }) => ({
  display: 'flex',
  margin: theme.spacing(1, 0),

  '&:first-of-type': {
    marginTop: 0,
  },
  '&:last-child': {
    marginBottom: 0,
  },
}));

const Arrow = styled(ArrowRight)(({ theme }) => ({
  color: theme.palette.primary.main,
  flexShrink: 0,
  marginRight: theme.spacing(1),
}));

const RequirementList = ({ items = [] }) => {
  return (
    <Box>
      {items.map(({ description }, index) => (
        <Item key={`req-${index}`}>
          <Arrow size={20} />
          <ThemeTypography variant="BODY_LARGE" color="GRAY">
            {description}
          </ThemeTypography>
        </Item>
      ))}
    </Box>
  );
};

RequirementList.propTypes = {
  items: PropTypes.array.isRequired,
};

export default RequirementList;
