import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import Image from './Image';

const ImageList = ({ images }) => {
  return (
    <Fragment>
      {images.length > 0 &&
        images.map(({ node: image }) => {
          return <Image key={image.id} image={image} />;
        })}
    </Fragment>
  );
};

ImageList.propTypes = {
  images: PropTypes.array.isRequired,
};

export default ImageList;
