// All the colors and hues as describe in the figma theme.

import { ThemeColors } from 'types/theme';

//https://www.figma.com/file/uxmh0dHgoEquQdUTdiZXjs/Seedtrace-Team-Library?node-id=328%3A27
export const themeColors: ThemeColors = {
  black: '#000',
  white: '#fff',
  primary: {
    100: '#0C193B',
    85: '#1D2D55',
    80: '#253561',
    60: '#5D6D98',
    40: '#B2BCEB',
    5: '#F4F5FA',
  },
  accent: {
    100: '#FFB801',
    80: '#FFCC47',
    60: '#FFE8AC',
    40: '#fdf5d9',
    20: '#FFF6E2',
  },
  error: {
    100: '#E74E4E',
    80: '#EE7E7E',
    60: '#F3B2B2',
    50: '#843939',
    20: '#FDF1ED',
  },
  success: {
    100: '#2DB875',
    80: '#3CD58B',
    60: '#62EFAB',
    20: '#E0FCEE',
  },
  grayScale: {
    100: '#3B3B3B',
    80: '#5D5D5D',
    60: '#7B7B7B',
    40: '#AFAFAF',
    30: '#D7D7D7',
    20: '#EEEEEE',
    10: '#F4F4F4',
    5: '#F9F9F9',
    0: '#FCFCFC',
  },
};
