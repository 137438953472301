import { CustomCellEditorProps } from 'ag-grid-react';
import useRawMaterials from 'components/ComponentsLibrary/hooks/useRawMaterials';
import AutocompleteSelection from 'designSystem/Inputs/AutocompleSelection/AutocompleteSelection';
import React, { FC, useState } from 'react';

const CropCellEditor: FC<CustomCellEditorProps> = ({ value, onValueChange, stopEditing }) => {
  const { rawMaterials, createRawMaterial } = useRawMaterials();

  const initialId = rawMaterials.find(rawMaterial => rawMaterial.title === value)?.id;

  const [selectedId, setSelectedId] = useState<string | undefined>(initialId);

  const getCropTitle = (id: string) =>
    rawMaterials.find(rawMaterial => rawMaterial.id === id)?.title;

  const handleAddNewItemClick = (title: string) => {
    createRawMaterial({ variables: { title } });
  };

  const handleRawMaterialSelect = (newId: string[] | string | undefined) => {
    setSelectedId(newId as string);
    onValueChange(getCropTitle(newId as string));
    stopEditing();
  };

  return (
    <AutocompleteSelection
      label="Select raw material"
      closeAfterSelection
      defaultOpen
      enableAddNewItem
      size="small"
      multiple={false}
      itemName="raw material"
      items={rawMaterials}
      errorMessage={'error'}
      hasError={false}
      selected={selectedId || ''}
      maxWidth={150}
      onAddNewItemClick={handleAddNewItemClick}
      onSelectionChange={handleRawMaterialSelect}
    />
  );
};

export default CropCellEditor;
