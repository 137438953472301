import { useQuery } from '@apollo/client';
import { Box } from '@mui/material';
import { Loader } from 'components/Forms';
import { FeedbackDashboard } from 'components/Product/Feedback';
import { ErrorState, FeatureOverlay, PageContainer, PageTitle } from 'components/Structure';
import { useCompanyFeatureFlags, useLogEventOnMount } from 'components/hooks';
import { Tabs } from 'designSystem';
import { GET_FEEDBACK } from 'graphql/queries';
import React, { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PRODUCT_TABS, ProductTabKey } from './ProductOverview';
import { CompanyFeature } from 'types/company.types';

const FeedbackAnalytics: FC = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  useLogEventOnMount('VIEW_PRODUCT_FEEDBACK', { productId: id });

  const { isCompanyFeatureEnabled } = useCompanyFeatureFlags();
  const feedbackFeatureEnabled = isCompanyFeatureEnabled(CompanyFeature.FEEDBACK);

  const handleChangeTab = (tab: ProductTabKey) => {
    if (tab === ProductTabKey.OVERVIEW) {
      navigate(`/products/${id}`);
    } else {
      navigate(`/products/${id}/${tab}`);
    }
  };

  const { error, loading, data } = useQuery(GET_FEEDBACK, {
    variables: { id },
  });

  if (error) return <ErrorState />;
  if (!data && loading) return <Loader />;

  const {
    product,
    productFeedback: { reactions },
  } = data;

  return (
    <PageContainer>
      <PageTitle
        title="Product feedback"
        titleExtension={product?.title}
        goBackLabel="Product pages"
        goBackUrl="/products"
      />

      <Box mt={3} mb={2}>
        <Tabs tabs={PRODUCT_TABS} selectedTab={ProductTabKey.FEEDBACK} onChange={handleChangeTab} />
      </Box>

      {!feedbackFeatureEnabled && <FeatureOverlay />}
      {!reactions?.edges.length ? (
        <ErrorState error="You have not received any feedback yet." />
      ) : (
        <FeedbackDashboard
          productFeedbackAnalytics={data.productFeedbackAnalytics}
          reactions={reactions}
        />
      )}
    </PageContainer>
  );
};

export default FeedbackAnalytics;
