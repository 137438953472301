import version1 from 'assets/img/qrGeneatator/edges/version1.svg';
import version2 from 'assets/img/qrGeneatator/edges/version2.svg';
import version3 from 'assets/img/qrGeneatator/edges/version3.svg';
import version4 from 'assets/img/qrGeneatator/edges/version4.svg';
import version5 from 'assets/img/qrGeneatator/edges/version5.svg';
import version6 from 'assets/img/qrGeneatator/edges/version6.svg';
import version7 from 'assets/img/qrGeneatator/edges/version7.svg';
import version8 from 'assets/img/qrGeneatator/edges/version8.svg';
import version9 from 'assets/img/qrGeneatator/edges/version9.svg';
import version10 from 'assets/img/qrGeneatator/edges/version10.svg';
import version11 from 'assets/img/qrGeneatator/edges/version11.svg';
import version13 from 'assets/img/qrGeneatator/edges/version13.svg';
import version15 from 'assets/img/qrGeneatator/edges/version15.svg';
import version16 from 'assets/img/qrGeneatator/edges/version16.svg';

const edges = [
  {
    displayName: 'Version 1',
    value: 'version1',
    img: version1,
  },
  {
    displayName: 'Version 2',
    value: 'version2',
    img: version2,
  },
  {
    displayName: 'Version 3',
    value: 'version3',
    img: version3,
  },
  {
    displayName: 'Version 4',
    value: 'version4',
    img: version4,
  },
  {
    displayName: 'Version 5',
    value: 'version5',
    img: version5,
  },
  {
    displayName: 'Version 6',
    value: 'version6',
    img: version6,
  },
  {
    displayName: 'Version 7',
    value: 'version7',
    img: version7,
  },
  {
    displayName: 'Version 8',
    value: 'version8',
    img: version8,
  },
  {
    displayName: 'Version 9',
    value: 'version9',
    img: version9,
  },
  {
    displayName: 'Version 10',
    value: 'version10',
    img: version10,
  },
  {
    displayName: 'Version 11',
    value: 'version11',
    img: version11,
  },
  {
    displayName: 'Version 12',
    value: 'version13',
    img: version13,
  },
  {
    displayName: 'Version 13',
    value: 'version15',
    img: version15,
  },
  {
    displayName: 'Version 14',
    value: 'version16',
    img: version16,
  },
];

export default edges;
