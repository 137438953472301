import { StyledTableBody } from 'components/TableBase';
import PropTypes from 'prop-types';
import React from 'react';
import ImpactClaimTableRow from './ImpactClaimTableRow';
import ImpactGroupTableRow from './ImpactGroupTableRow';

const ImpactClaimTableBody = ({
  actions,
  impactClaimGroups,
  onSelect,
  rowsPerPage,
  currentPage,
  displayGroup,
  'data-cy': dataCy = '',
}) => {
  return (
    <StyledTableBody data-cy={dataCy}>
      {impactClaimGroups
        .slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage)
        .map((impactClaimGroup, index) => {
          const isGroup = impactClaimGroup.impactClaims.length > 1 && displayGroup;

          if (isGroup) {
            return (
              <ImpactGroupTableRow
                key={index}
                impactGroup={impactClaimGroup}
                onSelect={onSelect}
                actions={actions}
              />
            );
          } else {
            return impactClaimGroup?.impactClaims.map((impactClaim, index) => (
              <ImpactClaimTableRow
                key={index}
                actions={actions}
                onSelect={onSelect}
                impactClaim={impactClaim}
                hasShadow={false}
              />
            ));
          }
        })}
    </StyledTableBody>
  );
};

ImpactClaimTableBody.propTypes = {
  rowsPerPage: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  actions: PropTypes.array.isRequired,
  impactClaimGroups: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  displayGroup: PropTypes.bool,
};

export default ImpactClaimTableBody;
