/* eslint-disable @typescript-eslint/no-explicit-any */
import { Percentage } from 'designSystem/Navigation/Stepper/Stepper';
import { Reducer } from 'react';
import { IEUDRComplianceGuideContextContextValues } from './EudrComplianceGuideContext';

type EUDRComplianceReducerActions =
  | { type: 'SET_FORM_DATA'; formData: any }
  | { type: 'SET_SELECTED_SECTION'; key: string | undefined }
  | { type: 'SET_SELECTED_SUB_SECTION'; key: string }
  | { type: 'SET_SECTIONS_DISABLED'; keys: string[] }
  | { type: 'SET_SECTIONS_ENABLED'; keys: string[] }
  | { type: 'UPDATE_SECTION_COMPLETION'; key: string; completion?: Percentage }
  | { type: 'SET_SUB_SECTION_DISABLED'; keys: string[] };

type ComplianceReducerState = Pick<
  IEUDRComplianceGuideContextContextValues,
  'sections' | 'formData'
> &
  Partial<
    Pick<
      IEUDRComplianceGuideContextContextValues,
      'selectedSection' | 'selectedSubSection' | 'selectedSectionIndex' | 'selectedSubSectionIndex'
    >
  > & { disabledSubSections: string[] };

const ComplianceReducer: Reducer<ComplianceReducerState, EUDRComplianceReducerActions> = (
  state,
  action
) => {
  switch (action.type) {
    case 'SET_FORM_DATA': {
      return {
        ...state,
        formData: action.formData,
      };
    }
    case 'SET_SELECTED_SECTION': {
      if (action.key === undefined) {
        return {
          ...state,
          selectedSection: undefined,
          selectedSectionIndex: undefined,
          selectedSubSection: undefined,
          selectedSubSectionIndex: undefined,
        };
      }
      const selectedSectionIndex = state.sections.findIndex(({ key: id }) => id === action.key);
      if (selectedSectionIndex === -1) {
        // eslint-disable-next-line no-console
        console.error(`Section with id ${action.key} not found`);
        return state;
      }
      // Find first index that is not in the disabledSubSections
      const selectedSubSectionIndex = state.sections[selectedSectionIndex].subSections.findIndex(
        ({ key }) => !state.disabledSubSections.includes(key)
      );

      return {
        ...state,
        selectedSection: state.sections[selectedSectionIndex],
        selectedSectionIndex,
        selectedSubSection:
          state.sections[selectedSectionIndex].subSections[selectedSubSectionIndex],
        selectedSubSectionIndex,
      };
    }
    case 'SET_SELECTED_SUB_SECTION': {
      if (state.selectedSection === undefined) {
        // eslint-disable-next-line no-console
        console.error('No section selected');
        return state;
      }
      const selectedSubSectionIndex = state.selectedSection.subSections.findIndex(
        ({ key: id }) => id === action.key
      );
      if (selectedSubSectionIndex === -1 || state.selectedSubSectionIndex === undefined) {
        // eslint-disable-next-line no-console
        console.error(`Subsection with id ${action.key} not found in the selected section`);
        return state;
      }
      return {
        ...state,
        selectedSubSection: state.selectedSection.subSections[selectedSubSectionIndex],
        selectedSubSectionIndex,
        sections: state.sections.map(section => {
          if (section.key === state.selectedSection?.key && section.completion !== 100) {
            return {
              ...section,
              completion: ((selectedSubSectionIndex / section.subSections.length) *
                100) as Percentage,
            };
          }
          return section;
        }),
      };
    }
    case 'UPDATE_SECTION_COMPLETION': {
      return {
        ...state,
        sections: state.sections.map(section => {
          if (action.key === section.key) {
            // Updating manually by event section key
            if (action.completion !== undefined) {
              return {
                ...section,
                completion: action.completion,
              };
            }

            // Updating automatically current selection by current subsection index
            if (
              state.selectedSection?.key === action.key &&
              state.selectedSubSectionIndex !== undefined &&
              section.completion !== 100
            ) {
              return {
                ...section,
                completion: ((state.selectedSubSectionIndex /
                  state.selectedSection.subSections.length) *
                  100) as Percentage,
              };
            }
          }
          return section;
        }),
      };
    }
    case 'SET_SECTIONS_DISABLED': {
      return {
        ...state,
        sections: state.sections.map(section => {
          if (action.keys.includes(section.key)) {
            return { ...section, disabled: true };
          }
          return section;
        }),
      };
    }
    case 'SET_SECTIONS_ENABLED': {
      return {
        ...state,
        sections: state.sections.map(section => {
          if (action.keys.includes(section.key)) {
            return { ...section, disabled: false };
          }
          return section;
        }),
      };
    }
    case 'SET_SUB_SECTION_DISABLED': {
      return {
        ...state,
        disabledSubSections: [...state.disabledSubSections, ...action.keys],
      };
    }
    default:
      return state;
  }
};

export default ComplianceReducer;
