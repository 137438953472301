import { Box, Card, Grid, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Plus } from '@styled-icons/bootstrap/Plus';
import { X } from '@styled-icons/bootstrap/X';
import dragIcon from 'assets/img/icons/drag-handle.svg';
import { ErrorMessage, MediaField, TextFieldLimited } from 'components/Forms';
import { createProductDefault } from 'constants/schemas';
import { ThemeButton, FieldWrapper } from 'designSystem';
import { Field, FieldArray, useFormikContext } from 'formik';
import { TextField } from 'formik-mui';
import update from 'immutability-helper';
import PropTypes from 'prop-types';
import React, { Fragment, useCallback } from 'react';
import { Container as DragContainer, Draggable } from 'react-smooth-dnd';

const DragHandle = styled('div')(() => ({
  position: 'absolute',
  top: 14,
  left: -22,
  width: 24,
  height: '100%',

  '&:hover': {
    cursor: 'grab',
  },
}));

const DragIcon = styled('img')(() => ({
  width: 10,
  pointerEvents: 'none',
}));

const RemoveButton = styled(IconButton)(() => ({
  position: 'absolute',
  top: 0,
  right: 0,
}));

const Item = styled(Card)(({ theme }) => ({
  position: 'relative',
  marginLeft: theme.spacing(3),
  boxShadow: theme.custom.shadows[1],
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  overflow: 'visible',
}));

const ProductItems = ({ items }) => {
  const { setFieldValue, errors } = useFormikContext();
  const itemArrayErrors = typeof errors.moreProducts === 'string' ? errors.moreProducts : null;

  const onDrop = useCallback(
    ({ removedIndex, addedIndex }) => {
      const product = items[removedIndex];
      const movedProducts = update(items, {
        $splice: [
          [removedIndex, 1],
          [addedIndex, 0, product],
        ],
      });

      setFieldValue('moreProducts', movedProducts);
    },
    [setFieldValue, items]
  );

  return (
    <FieldArray
      name="moreProducts"
      render={arrayHelpers => (
        <Fragment>
          {itemArrayErrors && <ErrorMessage>{itemArrayErrors}</ErrorMessage>}
          <DragContainer
            onDrop={onDrop}
            groupName="MORE_PRODUCTS"
            dragHandleSelector=".drag-handle"
          >
            {items.map((item, index) => (
              <Draggable key={item.id}>
                <Item>
                  {items.length > 1 && (
                    <Fragment>
                      <DragHandle className="drag-handle">
                        <DragIcon src={dragIcon} alt="drag handle" />
                      </DragHandle>
                      <RemoveButton
                        onClick={() => arrayHelpers.remove(index)}
                        data-cy="more-products-remove-button"
                      >
                        <X size={20} />
                      </RemoveButton>
                    </Fragment>
                  )}
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <Box mb={1}>
                        <FieldWrapper label="Title">
                          <Field
                            component={TextFieldLimited}
                            variant="outlined"
                            fullWidth
                            name={`moreProducts[${index}].title`}
                            charLimit={50}
                            placeholder="Raw cacao chocolate"
                            inputProps={{
                              'data-cy': 'more-products-title-field',
                            }}
                          />
                        </FieldWrapper>
                      </Box>
                      <Box mt={2}>
                        <Field
                          component={MediaField}
                          legacyImage={false}
                          fullWidth
                          name={`moreProducts[${index}].image`}
                          styles={{
                            width: '100%',
                            height: 180,
                          }}
                          data-cy="more-products-image-field"
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={8}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <FieldWrapper label="Description">
                            <Field
                              component={TextFieldLimited}
                              variant="outlined"
                              fullWidth
                              multiline
                              placeholder="Describe your product.."
                              rows={5}
                              name={`moreProducts[${index}].description`}
                              charLimit={650}
                              inputProps={{
                                'data-cy': 'more-products-description-field',
                              }}
                            />
                          </FieldWrapper>
                        </Grid>
                        <Grid item xs={12}>
                          <FieldWrapper label="Link to learn more">
                            <Field
                              component={TextField}
                              variant="outlined"
                              fullWidth
                              placeholder="https://"
                              name={`moreProducts[${index}].link`}
                              inputProps={{
                                'data-cy': 'more-products-link-field',
                              }}
                            />
                          </FieldWrapper>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Item>
              </Draggable>
            ))}
          </DragContainer>

          <Box pt={2} ml={3}>
            <ThemeButton
              size="large"
              color="BLUE_ICE"
              startIcon={<Plus size={14} />}
              onClick={() => arrayHelpers.push(createProductDefault())}
              data-cy="more-products-add-button"
            >
              Add new
            </ThemeButton>
          </Box>
        </Fragment>
      )}
    />
  );
};

ProductItems.propTypes = {
  items: PropTypes.array.isRequired,
};

export default ProductItems;
