import { Box } from '@mui/material';
import DocumentSkeleton from 'components/DocumentLibrary/DocumentSkeleton';
import { Loader, SearchToolbar } from 'components/Forms';
import PaginatedRequestsTable from 'components/Requests/PaginatedRequestsTable';
import {
  EmptySearchResults,
  ErrorState,
  PageContainer,
  PageTitle,
  ScrollableTableContainer,
  ActionContainer,
} from 'components/Structure';
import { usePartnerRequests } from 'components/hooks';
import { PARTNER_REQUEST_RECEIVED_ROWS_PER_PAGE } from 'constants/pagination';
import React, { FC, Fragment } from 'react';

const RequestedDocuments: FC = () => {
  const { filteredRequests, loading, error, searchTerm, setSearchTerm, handleActionClick } =
    usePartnerRequests();

  if (error) {
    return <ErrorState />;
  }
  if (loading) {
    return <Loader />;
  }

  if (!filteredRequests.length && !searchTerm) {
    return (
      <PageContainer>
        <PageTitle title="Requests" />
        <Box mt={4}>
          <EmptySearchResults skeletonComponent={<DocumentSkeleton />} />
        </Box>
      </PageContainer>
    );
  }
  return (
    <PageContainer>
      <PageTitle title="Requests" />
      <Box position="relative">
        <ActionContainer>
          <SearchToolbar
            setSearchTerm={setSearchTerm}
            searchTerm={searchTerm}
            data-cy="document-search-input"
            placeholder="Search document"
            className="search-input"
          />
        </ActionContainer>

        <ScrollableTableContainer>
          <PaginatedRequestsTable
            rowsPerPage={PARTNER_REQUEST_RECEIVED_ROWS_PER_PAGE}
            requests={filteredRequests}
            onActionClick={handleActionClick}
          />
        </ScrollableTableContainer>
      </Box>

      {searchTerm?.length && !filteredRequests.length ? (
        <EmptySearchResults
          message="No search results found"
          skeletonComponent={<DocumentSkeleton title={searchTerm} />}
        />
      ) : (
        <Fragment />
      )}
    </PageContainer>
  );
};

export default RequestedDocuments;
