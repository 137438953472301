import { styled } from '@mui/material/styles';
import { CameraVideo } from '@styled-icons/bootstrap/CameraVideo';
import { ImageFill } from '@styled-icons/bootstrap/ImageFill';
import nopattern from 'assets/img/no-pattern.png';
import { CroppingDialog } from 'components/Cropping';
import LibraryModal from 'components/MediaLibrary/LibraryModal';
import { mediaSchema } from 'constants/schemas';
import PropTypes from 'prop-types';
import React, { Fragment, memo, useEffect, useState } from 'react';
import Imgix from 'react-imgix';
import ReactPlayer from 'react-player';
import MediaActionButtons from './MediaActionButtons';
import SelectableFieldUpload from './SelectableFieldUpload';
import { UploadProvider } from 'contexts/UploadContext';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Container = styled(({ styles, ...props }) => <div {...props} />)(({ styles }) => ({
  ...styles,
  position: 'relative',
}));

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ImageContainer = styled(({ styles, ...rest }) => <div {...rest} />)(({ styles = {} }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  overflow: 'hidden',
  background: `url(${nopattern})`,
  backgroundSize: 24,
  borderRadius: 4,
  ...styles,

  '&:hover .action-buttons, &:hover .moving-buttons': {
    opacity: 1,
  },
}));

const Image = styled(({ ...props }) => <Imgix sizes="50wv" {...props} />)(() => ({
  width: '100%',
  height: '100%',
  objectFit: 'contain',
}));

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const VideoContainer = styled(({ styles, ...rest }) => <div {...rest} />)(({ theme, styles }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  border: `1px solid ${theme.palette.grey[300]}`,
  overflow: 'hidden',
  borderRadius: 4,
  ...styles,

  '&:hover .action-buttons, &:hover .moving-buttons': {
    opacity: 1,
  },
}));

const LoadingContainer = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  background: '#fff',
  color: theme.custom.colors.blueFrost,
}));

const createCrop = crop => {
  if (crop && typeof crop.x === 'number') {
    const { x, y, width, height } = crop;

    if (!x && !y && !width && !height) return '';

    return `${x},${y},${width},${height}`;
  }

  return '';
};

const SelectableMediaItem = ({
  type = 'image',
  value,
  crop,
  className,
  onChange,
  styles,
  allowedMedia = ['image'],
  background = true,
  cropDisabled = false,
  imageTypeName = 'ImageType',
  singleUpload = false,
  onRemove = () => null,
  showError,
  imageId,
  moveLeft,
  moveRight,
  'data-cy': dataCy = '',
  hideMediaIds,
  disabled,
  preventCleanFiles = false,
}) => {
  const [selectorOpen, setSelectorOpen] = useState(null);
  const [loading, setLoading] = useState(true);
  const cropString = createCrop(crop);
  const isImage = type === 'image';
  const closeSelector = () => setSelectorOpen(null);
  const openMediaLibrary = () => setSelectorOpen('mediaLibrary');

  useEffect(() => {
    setLoading(true);
  }, [value, cropString]);

  const onChangeCrop = crop => {
    const updatedImage = {
      url: value,
      __typename: imageTypeName,
      crop: crop,
      imageId,
    };
    onChange(updatedImage);
    closeSelector();
  };

  const ActionButtons = (
    <MediaActionButtons
      cropDisabled={!isImage || cropDisabled || disabled}
      selectorDisabled={singleUpload || disabled}
      deleteDisabled={disabled}
      openSelector={openMediaLibrary}
      openCropping={() => setSelectorOpen('crop')}
      onDelete={() => {
        onChange(mediaSchema.default());
        onRemove();
      }}
      moveLeft={moveLeft}
      moveRight={moveRight}
    />
  );

  return (
    <Container styles={styles} data-cy={dataCy}>
      {selectorOpen === 'mediaLibrary' && (
        <UploadProvider>
          <LibraryModal
            open
            onMediaSelect={onChange}
            selectableMedia={allowedMedia}
            onClose={closeSelector}
            hideMediaIds={hideMediaIds}
            preventCleanFiles={preventCleanFiles}
          />
        </UploadProvider>
      )}
      {selectorOpen === 'crop' && (
        <CroppingDialog
          open
          onClose={closeSelector}
          imageSrc={value}
          crop={crop}
          onCropSelect={onChangeCrop}
        />
      )}
      {value && (
        <Fragment>
          {type === 'image' && (
            <ImageContainer
              styles={styles}
              className={className}
              data-cy="selectable-image-container"
            >
              {loading && (
                <LoadingContainer className="shimmer">
                  <ImageFill size={70} />
                </LoadingContainer>
              )}
              <Image
                background={background}
                src={value}
                htmlAttributes={{
                  onLoad: () => {
                    setLoading(false);
                  },
                }}
                imgixParams={
                  cropString.length
                    ? {
                        rect: cropString,
                      }
                    : {}
                }
              />
              {ActionButtons}
            </ImageContainer>
          )}
          {type === 'video' && (
            <VideoContainer styles={styles} className={className}>
              {loading && (
                <LoadingContainer className="shimmer">
                  <CameraVideo size={58} />
                </LoadingContainer>
              )}
              <ReactPlayer
                url={value}
                width="unset"
                height="100%"
                onReady={() => setLoading(false)}
              />
              {ActionButtons}
            </VideoContainer>
          )}
        </Fragment>
      )}
      {!value && (
        <SelectableFieldUpload
          onClick={singleUpload ? null : openMediaLibrary}
          disabled={disabled}
          singleUpload={singleUpload}
          onUploadComplete={onChange}
          showError={showError}
          allowedMedia={allowedMedia}
        />
      )}
    </Container>
  );
};

SelectableMediaItem.propTypes = {
  type: PropTypes.oneOf(['image', 'video']),
  value: PropTypes.string,
  crop: PropTypes.object,
  className: PropTypes.string,
  placeholderHeight: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func,
  showEdit: PropTypes.bool,
  styles: PropTypes.object,
  allowedMedia: PropTypes.arrayOf(PropTypes.oneOf(['image', 'video'])),
  background: PropTypes.bool,
  cropDisabled: PropTypes.bool,
  singleUpload: PropTypes.bool,
  imageTypeName: PropTypes.string,
  showError: PropTypes.bool,
  imageId: PropTypes.string,
  moveLeft: PropTypes.func,
  moveRight: PropTypes.func,
  hideMediaIds: PropTypes.arrayOf(PropTypes.string),
  preventCleanFiles: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default memo(SelectableMediaItem);
