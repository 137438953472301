import { Box, Button, IconButton, Tooltip, styled } from '@mui/material';
import { ActivityDefaultIcon, EditPenIcon } from 'assets/img/icons';
import FlexBox from 'components/Structure/FlexBox';
import { ThemeTypography } from 'designSystem';
import Icon from 'designSystem/Primitives/Icon/Icon';
import React, { FC, MouseEvent, ReactNode } from 'react';
import { Booleanish, booleanish } from 'types/booleanish.types';
import { IPrevConnectionIds } from '../useChainMapping';
import AbsoluteElement from './AbsoluteElement';
import useChainMappingConfig from './useChainMappingConfig';

export const PREFIX_CREATE_SUB_CHAIN_BTN = 'create-sub-chain-btn-';

export interface IGraphActivity {
  id: string;
  zIndex: number; // Based on the position in the chain the last (in x direction) activities get a lower zIndex
  x: number; // Absolute x position in px
  y: number; // Absolute y position in px
  title: string;
  subtitle: string; // Company name
  subtitleIcon: ReactNode; // Company logo
  output: string; // Output component
  name: string; // Location name
  incomingLinkColors?: string[]; // Array of colors
  outgoingLinkColor?: string; // Undefined if no outputColor should be set
  siteCluster?: { siteCount: number; id: string };
  /*
   * Visibility of the activity to the consumer side (default: true)
   * If not visible opacity is added to the activity
   */
  isVisible?: boolean;
  /**
   * An object of the activity ids (excluding the once inside a sub chain) and optional subChain ids that can be used to create a sub chain
   * If undefined the button to create a sub chain will not be shown
   */
  prevConnectionIds?: IPrevConnectionIds;
}

interface IActivityProps extends IGraphActivity {
  highlight?: boolean;
  partnerView?: boolean;
  showClusterIndicator?: boolean;
  canEdit?: boolean;
  onEditClick?: (id: string) => void;
  onSubChainCreationClick?: (prevConnectionIds: IPrevConnectionIds) => void;
  onSubChainCreationHighlightToggle?: (prevConnectionIds?: IPrevConnectionIds) => void;
  onClusterClick?: (id: string) => void;
}

const Shadow = styled(AbsoluteElement)(() => ({
  backgroundColor: 'rgba(0, 0, 0, 0.11)',
  filter: 'blur(16px)',
  borderRadius: 4,
  zIndex: 0,
}));

const Container = styled(AbsoluteElement)<{
  disabled: booleanish;
  faded: booleanish;
  activeBorder: booleanish;
}>(({ theme, disabled, faded, activeBorder }) => ({
  backgroundColor: '#fff',
  borderRadius: 4,
  cursor: disabled === 'true' ? 'default' : 'pointer',
  opacity: faded === 'true' ? 0.4 : 1,
  '& > *': {
    cursor: disabled === 'true' ? 'default' : 'pointer',
  },
  '&:hover .hover-btn': {
    display: 'flex',
  },
  // Add border if active without shifting the content
  '&::after': {
    content: activeBorder === 'true' ? '""' : 'none',
    position: 'absolute',
    border: `1px solid ${theme.custom.colors.seedtraceOrange}`,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    borderRadius: 4,
    zIndex: 1,
  },
}));

const Content = styled('div')<{ width: number; height: number }>(({ theme, width, height }) => ({
  width,
  height,
  position: 'relative',
  padding: theme.spacing(1),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
}));

const Tag = styled(FlexBox)(({ theme }) => ({
  background: theme.custom.colors.lightBorderColor,
  height: theme.spacing(2.625),
  padding: theme.spacing(1),
  borderRadius: 4,
}));

const Circle = styled(AbsoluteElement)<{ backgroundColor: string }>(({ backgroundColor }) => ({
  borderRadius: '50%',
  backgroundColor: backgroundColor,
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  padding: 4,
  color: theme.palette.primary.main,
  backgroundColor: theme.custom.themeColors.primary[5],
  display: 'none',
  zIndex: 2,
  '&:hover': {
    backgroundColor: 'white',
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.main,
  whiteSpace: 'nowrap',
  zIndex: 10,
  minWidth: 'auto',
  transition: 'none',
  display: 'none',
  padding: '8.8px 8px !important',
  '& .MuiTypography-root': {
    display: 'none',
    fontWeight: '700 !important',
  },
  '&:hover, &.hovered': {
    display: 'flex',
    boxShadow: theme.custom.shadows[4],
    backgroundColor: theme.custom.themeColors.accent[60],
    '& .MuiTypography-root': {
      display: 'block',
    },
  },
}));

const ClusterTag = styled(AbsoluteElement)(({ theme }) => ({
  cursor: 'pointer !important',
  display: 'flex',
  padding: '4px 8px',
  gap: theme.spacing(1),
  borderRadius: '16px 0px 0px 16px',
  alignItems: 'center',
  background: theme.custom.themeColors.grayScale[20],
  border: '1px solid rgba(215, 215, 215, 0.40)',
}));

const Activity: FC<IActivityProps> = ({
  id,
  x,
  y,
  title,
  highlight,
  subtitle,
  subtitleIcon,
  output,
  name,
  incomingLinkColors = [],
  siteCluster,
  showClusterIndicator,
  isVisible = true,
  outgoingLinkColor,
  canEdit,
  prevConnectionIds,
  zIndex,
  onEditClick,
  onSubChainCreationClick,
  onSubChainCreationHighlightToggle,
  onClusterClick,
}) => {
  const { SPACING, NODE_HEIGHT, NODE_WIDTH, CONNECTION_SIZE } = useChainMappingConfig();

  const handleEditClick = () => (canEdit ? onEditClick?.(id) : undefined);
  const handleAddSubChainHighlightToggle = (event: MouseEvent) => {
    onSubChainCreationHighlightToggle?.(event.type === 'mouseover' ? prevConnectionIds : undefined);
  };
  const handleAddSubChainClick = (event: MouseEvent) => {
    event.stopPropagation();
    if (!prevConnectionIds) {
      console.error('No activities to create sub chain from');
      return;
    }
    onSubChainCreationClick?.(prevConnectionIds);
  };

  const handleSiteClusterClick = (event: MouseEvent) => {
    event.stopPropagation();
    if (siteCluster?.id) {
      onClusterClick?.(siteCluster.id);
    }
  };

  return (
    <>
      {/* Shadow */}
      <Shadow x={x} y={y} width={NODE_WIDTH} height={NODE_HEIGHT} />

      <Container
        x={x}
        y={y}
        zIndex={zIndex}
        width={NODE_WIDTH}
        height={NODE_HEIGHT}
        faded={Booleanish(!isVisible)}
        disabled={Booleanish(!canEdit)}
        activeBorder={Booleanish(highlight)}
        data-tut="onboarding_tour_editor_step_activity"
        onClick={handleEditClick}
        data-cy="chain-mapping-activity"
      >
        {showClusterIndicator && siteCluster && (
          <Tooltip title="Go to origin cluster">
            <ClusterTag
              x={-100}
              y={NODE_HEIGHT - 34}
              width={100}
              height={30}
              onClick={handleSiteClusterClick}
            >
              <Icon name="farm" color="black" />
              <ThemeTypography variant="BODY_SMALL" autoOverflow maxWidth={80}>
                {siteCluster.siteCount.toLocaleString('en-GB').replace(/,/g, ' ')} sites
              </ThemeTypography>
            </ClusterTag>
          </Tooltip>
        )}

        {/* Ingoing connections */}
        <AbsoluteElement
          x={-CONNECTION_SIZE}
          y={
            NODE_HEIGHT / 2 -
            (incomingLinkColors.length * CONNECTION_SIZE * 2 +
              ((incomingLinkColors.length - 1) * SPACING) / 2) /
              2
          }
        >
          {incomingLinkColors.map((color, index) => (
            <AbsoluteElement
              key={color}
              y={index * CONNECTION_SIZE + (index * SPACING) / 2 - 2}
              width={CONNECTION_SIZE}
              height={CONNECTION_SIZE}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="9"
                height="10"
                viewBox="0 0 9 10"
                fill="none"
              >
                <path
                  d="M8.15655 4.15385C8.7793 4.54608 8.7793 5.45392 8.15655 5.84615L2.78294 9.23065C2.11702 9.65007 1.25 9.17149 1.25 8.3845L1.25 1.6155C1.25 0.828508 2.11702 0.349929 2.78294 0.769349L8.15655 4.15385Z"
                  fill={color}
                />
              </svg>
            </AbsoluteElement>
          ))}
        </AbsoluteElement>

        {/* Outgoing connection */}
        {outgoingLinkColor && (
          <Circle
            x={NODE_WIDTH - CONNECTION_SIZE / 2}
            y={NODE_HEIGHT / 2 - CONNECTION_SIZE / 2}
            width={CONNECTION_SIZE}
            height={CONNECTION_SIZE}
            backgroundColor={outgoingLinkColor}
          />
        )}

        {/* Activity card content */}
        <Content width={NODE_WIDTH} height={NODE_HEIGHT}>
          <FlexBox justifyContent="space-between" width="100%">
            <ThemeTypography
              variant="BODY_MEDIUM_BOLD"
              autoOverflow
              maxWidth={NODE_WIDTH - SPACING * 4 - (prevConnectionIds ? 24 : 0)}
            >
              {title}
            </ThemeTypography>
            {!!prevConnectionIds && (
              // Box to reserve spacing for absolute element
              <Box width={36} height={24} position="relative">
                <AbsoluteElement x={5}>
                  <StyledButton
                    id={PREFIX_CREATE_SUB_CHAIN_BTN + id}
                    size="small"
                    className="hover-btn"
                    startIcon={<Icon ml={1} color="black" name="component-chain" />}
                    onMouseOver={handleAddSubChainHighlightToggle}
                    onMouseOut={handleAddSubChainHighlightToggle}
                    onClick={handleAddSubChainClick}
                  >
                    <ThemeTypography color="BLACK" variant="BODY_SMALL">
                      Add component chain
                    </ThemeTypography>
                  </StyledButton>
                </AbsoluteElement>
              </Box>
            )}
          </FlexBox>

          <FlexBox>
            {subtitleIcon}
            <Box mr={`${SPACING / 2}px`} />
            <ThemeTypography
              variant="BODY_SMALL"
              color="ALMOST_BLACK"
              autoOverflow
              maxWidth={NODE_WIDTH - 16 - SPACING * 4}
            >
              {subtitle}
            </ThemeTypography>
          </FlexBox>

          {/* Output component */}
          <Tag>
            <ThemeTypography variant="LABEL_INPUT" autoOverflow maxWidth={NODE_WIDTH - SPACING * 4}>
              {output}
            </ThemeTypography>
          </Tag>

          <FlexBox>
            <ActivityDefaultIcon width="18" height="18" />
            <Box mr={`${SPACING / 2}px`} />
            <ThemeTypography
              variant="BODY_SMALL"
              autoOverflow
              maxWidth={NODE_WIDTH - (SPACING * 4 + 9 + SPACING / 2)}
            >
              {name}
            </ThemeTypography>
          </FlexBox>
        </Content>

        {/* Edit button - click is already handled in the top parent container */}
        {canEdit && !highlight && (
          <AbsoluteElement x={NODE_WIDTH / 2 - SPACING} y={-SPACING * 0.8}>
            <Tooltip title="Edit activity">
              <StyledIconButton className="hover-btn" onClick={handleEditClick} disableRipple>
                <EditPenIcon width={8} />
              </StyledIconButton>
            </Tooltip>
          </AbsoluteElement>
        )}
      </Container>
    </>
  );
};

export default Activity;
