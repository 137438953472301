import { FormHelperText } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { getIn } from 'formik';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { capitalizeFirstLetter } from './utils';

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  '&.Mui-selected': {
    background: theme.palette.secondary.main,
    color: '#fff',
    fontWeight: 600,
  },

  '&.MuiToggleButton-root': {
    padding: theme.spacing(0.8),
    fontSize: 12,
    textTransform: 'none',
  },

  '&:hover.Mui-selected': {
    background: theme.palette.secondary.light,
  },
}));

const Toggle = ({ options, ...props }) => {
  const {
    field: { value, name },
    form: { setFieldValue, errors, touched, validateOnMount },
  } = props;

  const fieldError = getIn(errors, name);
  const showError = (getIn(touched, name) || validateOnMount) && !!fieldError;

  const handleChange = (e, value) => {
    if (value !== null) {
      setFieldValue(name, value);
    }
  };

  return (
    <Fragment>
      <ToggleButtonGroup value={value} exclusive onChange={handleChange}>
        {options.map(value => (
          <StyledToggleButton color="primary" value={value} key={value}>
            {capitalizeFirstLetter(value)}
          </StyledToggleButton>
        ))}
      </ToggleButtonGroup>
      {showError && <FormHelperText error>{fieldError}</FormHelperText>}
    </Fragment>
  );
};

Toggle.propTypes = {
  options: PropTypes.array.isRequired,
};

export default Toggle;
