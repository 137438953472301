import { useMediaQuery } from '@mui/material';
import MobileWarning from 'components/Structure/MobileWarning';
import { useConfig } from 'components/hooks';
import React, { FC } from 'react';
import Router from 'routes/Router';

const App: FC = () => {
  const { minScreenWidth, minScreenHeight } = useConfig();
  const matchesMinScreenWidth = useMediaQuery(`(max-width:${minScreenWidth}px)`);
  const matchesMinScreenHeight = useMediaQuery(`(max-height:${minScreenHeight}px)`);

  return (
    <>
      <MobileWarning open={matchesMinScreenWidth || matchesMinScreenHeight} />
      <Router />
    </>
  );
};

export default App;
