import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { ClaimTitleAndStatus, LevelPill } from 'components/ImpactClaims/ClaimShared';
import { HeaderContainer, Description } from './styles';
import { Box } from '@mui/material';
import { useDialog } from 'components/hooks';
import { Edit } from '@styled-icons/boxicons-solid/Edit';
import { impactLevelCategories } from '../constants/impactLevelCategories';
import ThemeButton from 'designSystem/Buttons/ThemeButton/ThemeButton';

const ClaimHeader = ({ impactClaim, loading }) => {
  const { openDialog } = useDialog();
  const handleClickEdit = () =>
    openDialog({
      type: 'EDIT_IMPACT_CLAIM',
      props: {
        claim: impactClaim,
      },
    });
  const impactLevel = impactLevelCategories[impactClaim?.impactLevel];

  return (
    <Fragment>
      <HeaderContainer
        invalid={String(impactClaim?.isInvalid)}
        pending={String(impactClaim?.isPending)}
        display="flex"
        justifyContent="space-between"
      >
        <Box display="flex" alignItems="start" gap={2.5}>
          <ClaimTitleAndStatus
            impactCatalogItem={impactClaim?.impactCatalogItem}
            loading={loading}
            claimStatus={impactClaim?.status || 'CREATED'}
            enlargeTitle
          />

          <LevelPill claimLevel={impactLevel} title={impactClaim?.impactLevelTitle} />
        </Box>
        <Box display="flex" alignItems="center">
          {/* TODO: Add unarchive functionality */}
          {/* {impactClaim.isArchived && (
          <Button onClick={unarchive} data-cy="unarchive-claim-button">Unarchive</Button>
        ) : ( */}
          {impactClaim && (
            <ThemeButton
              onClick={handleClickEdit}
              color={impactClaim?.isInvalid ? 'YELLOW' : 'BLUE_ICE'}
              size="medium"
              data-cy="edit-claim-button"
              startIcon={<Edit size={16} />}
            >
              Edit
            </ThemeButton>
          )}
          {/* ) */}
        </Box>
      </HeaderContainer>
      <Description>{impactClaim?.impactCatalogItem?.definition}</Description>
    </Fragment>
  );
};

ClaimHeader.propTypes = {
  impactClaim: PropTypes.object,
  loading: PropTypes.bool,
};

export default ClaimHeader;
