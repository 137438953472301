import React from 'react';
import { DeletableChip, LanguageSelectorItem } from 'components/Forms';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { Flag } from 'components/Structure';
import { useConfig } from 'components/hooks';
import { StyledInput, StyledSelect } from 'components/Forms/Input';

const LanguageMultiSelect = ({
  selectedLanguages,
  setSelectedLanguages,
  languages,
  onDelete,
  defaultLanguage,
}) => {
  const config = useConfig();
  return (
    <StyledSelect
      value={selectedLanguages}
      onChange={event => setSelectedLanguages(event.target.value)}
      variant="outlined"
      defaultValue="none"
      fullWidth
      multiple={true}
      input={<StyledInput />}
      data-cy="language-multi-select"
      renderValue={selectedItems => {
        return selectedItems.map(language => {
          const { flag, title } = config.getLanguageConfig(language);
          // The item should not be able to be deleted if it's the default language
          const canDelete = language !== defaultLanguage;

          return (
            <DeletableChip
              key={`selected-lang-${language}`}
              label={
                <Box display="flex" alignItems="center">
                  <Flag src={flag} gutterRight={true} />

                  {title}
                </Box>
              }
              flag={flag}
              onDelete={onDelete && canDelete ? () => onDelete(language) : undefined}
            />
          );
        });
      }}
    >
      {languages.map(({ title, key, flag }) => {
        return (
          <LanguageSelectorItem
            value={key}
            data-cy="select-item"
            key={`product-lang-${key}`}
            flag={flag}
            title={title}
          />
        );
      })}
    </StyledSelect>
  );
};

LanguageMultiSelect.propTypes = {
  selectedLanguages: PropTypes.array.isRequired,
  setSelectedLanguages: PropTypes.func.isRequired,
  languages: PropTypes.array.isRequired,
  onDelete: PropTypes.func,
  defaultLanguage: PropTypes.string,
};

export default LanguageMultiSelect;
