import { useCallback } from 'react';
import { useApolloClient, useQuery } from '@apollo/client';
import { GET_DRAWER_STATE_CLIENT } from 'graphql/queries';

const useDrawerState = () => {
  const client = useApolloClient();

  const { data: { drawerOpen = true } = {} } = useQuery(GET_DRAWER_STATE_CLIENT);

  const toggleDrawer = useCallback(
    (open?: boolean) => {
      const openState = open === undefined ? !drawerOpen : open;

      client.writeQuery({
        query: GET_DRAWER_STATE_CLIENT,
        data: {
          drawerOpen: openState,
        },
      });
    },
    [drawerOpen, client]
  );

  return {
    drawerOpen,
    toggleDrawer,
  };
};

export default useDrawerState;
