import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { SmallUserCard } from 'components/Structure';
import PropTypes from 'prop-types';
import React from 'react';
import Moment from 'react-moment';

const CreatedOnTypography = styled('p')(({ theme }) => ({
  position: 'relative',
  fontWeight: 300,
  fontSize: 14,
  padding: theme.spacing(1, 0),
  margin: 0,

  '&:before': {
    position: 'absolute',
    content: '""',
    width: 2,
    background: theme.custom.colors.backgroundLight,
    left: -20 + 4.5,
    top: 'calc(50% + 5.5px)',
    bottom: 0,
  },

  '&:after': {
    position: 'absolute',
    content: '""',
    width: 11,
    height: 11,
    background: theme.palette.secondary.main,
    borderRadius: '50%',
    left: -20,
    top: 'calc(50% - 5.5px)',
  },
}));

const LastEditedTypography = styled('p')(({ theme }) => ({
  position: 'relative',
  fontWeight: 400,
  fontSize: 14,
  margin: 0,

  '&:before': {
    position: 'absolute',
    content: '""',
    width: 2,
    background: theme.custom.colors.backgroundLight,
    left: -20 + 4.5,
    top: 0,
    bottom: 'calc(50% - 5.5px)',
  },

  '&:after': {
    position: 'absolute',
    content: '""',
    width: 11,
    height: 11,
    background: theme.custom.colors.backgroundLight,
    borderRadius: '50%',
    left: -20,
    top: 'calc(50% - 5.5px)',
  },
}));

const EditStatus = ({ product: { createdTimestamp, modifiedTimestamp, latestAction } }) => (
  <Box display="flex" flexDirection="column" pl={3}>
    <CreatedOnTypography>
      Created on <Moment format="LL">{createdTimestamp}</Moment>
    </CreatedOnTypography>
    <LastEditedTypography fontSize={14}>
      Last edited <Moment format="LL">{modifiedTimestamp}</Moment>
    </LastEditedTypography>
    <SmallUserCard mt={1} userName={latestAction?.userName} />
  </Box>
);

EditStatus.propTypes = {
  product: PropTypes.object.isRequired,
};

export default EditStatus;
