import { useEffect } from 'react';

const useCopyHandler = (onCopy: () => void) => {
  useEffect(() => {
    const handleCopy = () => {
      onCopy();
    };

    document.addEventListener('copy', handleCopy);
    return () => {
      document.removeEventListener('copy', handleCopy);
    };
  }, [onCopy]);
};

export default useCopyHandler;
