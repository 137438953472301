import { styled } from '@mui/material';
import { Skeleton } from 'designSystem';

export const TitleIcon = styled('img')(({ theme }) => ({
  width: 70,
}));

export const StyledSkeleton = styled(Skeleton)(({ theme }) => ({
  backgroundColor: '#F4F5FA',
}));
