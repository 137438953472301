import ComponentChainPlaceholderImage from 'assets/img/components/component-chain-placeholder.svg';
import SupplyChainPlaceholderImage from 'assets/img/supply-chain-placeholder-image.svg';
import { ImageVariant } from 'types/media.types';

export const getChainPartnerTitle = (outputComponents?: { ownedBy: { name: string } }[]) => {
  if (!outputComponents?.length) {
    return 'Unknown';
  }

  return outputComponents.reduce((prev, { ownedBy }) => {
    if (!prev) {
      return ownedBy?.name || '';
    }
    return ownedBy?.name ? `${prev} & ${ownedBy.name}` : prev;
  }, '');
};

/**
 * Get the image of a component chain
 * First tries to return the real image, then tries to return the first image of a output component, if none of those are available, returns the default placeholder image
 */
export const getChainImage = (chain?: { image?: ImageVariant; productId?: string }) =>
  chain?.image?.url ||
  (chain?.productId ? SupplyChainPlaceholderImage : ComponentChainPlaceholderImage);
