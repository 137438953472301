import { styled, useTheme } from '@mui/material/styles';
import { Map } from '@styled-icons/boxicons-regular/Map';
import { Group } from '@vx/group';
import Pie from '@vx/shape/lib/shapes/Pie';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

const LegendContainer = styled('div')(({ height = 320 }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: height,
}));

const Icon = styled('span')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.primary.main,
  background: theme.palette.common.white,
  width: 48,
  height: 48,
  margin: theme.spacing(1),
}));

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const AverageStep = styled(({ scale, ...props }) => <span {...props} />)(({ theme, scale }) => ({
  display: 'flex',
  position: 'relative',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.text.primary,
  background: theme.palette.common.white,
  fontSize: 32,
  transform: scale ? 'scaleX(-1)' : 'unset',
}));

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TotalSteps = styled(({ scale, ...props }) => <span {...props} />)(({ theme, scale }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.text.primary,
  background: theme.palette.common.white,
  fontSize: 12,
  fontWeight: 400,
  transform: scale ? 'scaleX(-1)' : 'unset',
}));

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Hint = styled(({ scale, ...props }) => <span {...props} />)(({ theme, scale }) => ({
  fontSize: 10,
  color: theme.palette.grey[500],
  transform: scale ? 'scaleX(-1)' : 'unset',
}));

const defaultMargin = {
  top: 10,
  right: 10,
  bottom: 10,
  left: 10,
};

const value = d => d.value;

const ProductJourneyInteractionsCard = ({
  width = 320,
  height = 320,
  data,
  margin = defaultMargin,
}) => {
  const theme = useTheme();

  if (width < 10) return null;
  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;
  const radius = Math.min(innerWidth, innerHeight) / 2;
  const centerY = innerHeight / 2;
  const centerX = innerWidth / 2;
  const donutThickness = 16;
  const average = Math.round(data.average * 100) / 100;
  const averageStepPercentage = average / data.totalSteps;

  const totalStepsData = [
    {
      value: 1,
      color: theme.palette.secondary.main,
    },
  ];

  const averageStepsData = [
    {
      value: averageStepPercentage,
      color: theme.palette.primary.main,
    },
    {
      value: 1 - averageStepPercentage,
      color: 'transparent',
    },
  ];

  return (
    <svg
      width={width}
      height={height}
      style={{
        transform: averageStepPercentage < 0.5 ? 'scaleX(-1)' : 'unset',
      }}
    >
      <Group top={centerY + margin.top} left={centerX + margin.left}>
        <Pie
          data={totalStepsData}
          pieValue={value}
          outerRadius={radius - (donutThickness - 5)}
          innerRadius={radius - donutThickness / 2 + 5}
          cornerRadius={5}
          padAngle={0.005}
          endAngle={4.505}
          startAngle={8.05}
        >
          {({ arcs, path }) =>
            arcs.map((arc, i) => {
              return (
                <g key={i}>
                  <path d={path(arc)} fill={arc.data.color} opacity={1} />
                </g>
              );
            })
          }
        </Pie>
        <Pie
          data={averageStepsData}
          pieValue={value}
          outerRadius={radius}
          innerRadius={radius - donutThickness}
          cornerRadius={5}
          padAngle={0.005}
          endAngle={8.05}
          startAngle={4.505}
          centroid={() => <span>out of {data.totalSteps} steps</span>}
        >
          {({ arcs, path }) =>
            arcs.reverse().map((arc, i) => (
              <g key={i}>
                <path d={path(arc)} fill={arc.data.color} opacity={1} />
              </g>
            ))
          }
        </Pie>
      </Group>
      <foreignObject
        width={width}
        height={height}
        textAnchor="middle"
        requiredExtensions="http://www.w3.org/1999/xhtml"
      >
        <LegendContainer height={height}>
          <Icon>
            <Map size={42} />
          </Icon>
          <Hint scale={averageStepPercentage < 0.5}>on average</Hint>
          <AverageStep scale={averageStepPercentage < 0.5}>{average}</AverageStep>
          <TotalSteps scale={averageStepPercentage < 0.5}>
            out of {data.totalSteps} steps
          </TotalSteps>
        </LegendContainer>
      </foreignObject>
    </svg>
  );
};

ProductJourneyInteractionsCard.propTypes = {
  data: PropTypes.shape({
    average: PropTypes.number.isRequired,
    totalSteps: PropTypes.number.isRequired,
  }).isRequired,
};

export default memo(ProductJourneyInteractionsCard);
