import { AccordionProps, AccordionSlots, Collapse, Accordion as MuiAccordion } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { FC } from 'react';
import { Booleanish, booleanish } from 'types/booleanish.types';

// This can also be moved into the theme later if we only use the design of this one Accordion
const StyledAccordion = styled(MuiAccordion)<{
  elevated: booleanish;
  transparent: booleanish;
}>(({ theme, elevated, transparent }) => ({
  boxShadow: 'none',
  border: `${theme.custom.colors.lightBorderColor} 1px solid`,
  backgroundColor: transparent === 'true' ? 'transparent' : 'white',
  borderRadius: 6,
  width: '100%',

  '&.MuiAccordion-root': {
    margin: 0,
    marginBottom: theme.spacing(1),
  },
  '&::before': {
    display: 'none',
  },

  '& .MuiAccordionSummary-root': {
    ...(elevated === 'true'
      ? {
          backgroundColor: theme.custom.themeColors.white,
          borderRadius: 8,
          boxShadow: theme.custom.shadows[4],
        }
      : {}),
    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: theme.spacing(1.5, 0),
    },
  },

  '& .MuiAccordionDetails-root': {
    padding: theme.spacing(2),
    backgroundColor: theme.custom.themeColors.white,
    borderRadius: 8,
    ...(elevated === 'false' ? { borderTopRightRadius: 0, borderTopLeftRadius: 0 } : {}),
  },
}));

export const Accordion: FC<AccordionProps & { elevated?: boolean; transparent?: boolean }> = ({
  transparent,
  elevated,
  ...props
}) => {
  return (
    <StyledAccordion
      transparent={Booleanish(transparent)}
      elevated={Booleanish(elevated)}
      slots={{ transition: Collapse as AccordionSlots['transition'] }}
      slotProps={{ transition: { timeout: 100 } }}
      {...props}
    />
  );
};
