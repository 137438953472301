import { Box, styled } from '@mui/material';
import { Plus } from '@styled-icons/bootstrap/Plus';
import ActivitySelection from 'components/Partners/ActivitySelection/ActivitySelection';
import { DialogInner } from 'components/Partners/Partners.styles';
import { DialogDefault, ThemeButton } from 'designSystem';
import React, { FC, useState } from 'react';
import { IDefaultDialogProps } from 'types/dialog.types';
import { FormContainerLeft, FormContainerRight, BlockContainer } from '../Forms/styles';
import SelectActivityType from '../Forms/SelectActivityType';
import { Loader } from 'components/Forms';
import { IActivity } from 'types/activity.types';
import {
  ActivityOwnership,
  ActivityOwnershipType,
} from 'graphql/mutations/types/activity-mutation.types';

interface ISelectActivityDialogProps extends IDefaultDialogProps {
  onlyInternal?: boolean;
  onActivityAdd: (activityId: string) => void;
}

const StyledBlockContainer = styled(BlockContainer)({
  paddingTop: 0,
  height: '100%',
});

const ButtonContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  width: '100%',
  padding: theme.spacing(4),
  boxShadow: theme.custom.shadows[4],
}));

const SelectActivityDialog: FC<ISelectActivityDialogProps> = ({
  open,
  onlyInternal,
  onActivityAdd,
  onClose,
}) => {
  const [selectedActivity, setSelectedActivity] = useState<IActivity>();
  const [activities, setActivities] = useState<IActivity[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [currentActivityType, setCurrentActivityType] = useState<ActivityOwnershipType>(
    onlyInternal ? ActivityOwnership.INTERNAL : ActivityOwnership.EXTERNAL
  );

  const onSubmitActivitySelection = () => {
    if (!selectedActivity) {
      return;
    }
    onActivityAdd(selectedActivity.id);
    onClose?.();
  };

  return (
    <DialogDefault
      title="Add existing activity"
      onClose={onClose}
      open={open}
      fullWidth
      icon={Plus}
      maxWidth="xl"
      background="#fff"
    >
      <DialogInner container>
        <FormContainerLeft item xs={6}>
          <StyledBlockContainer container z-index={1}>
            <SelectActivityType
              tabKeys={[
                ActivityOwnership.INTERNAL,
                ...(onlyInternal ? [] : [ActivityOwnership.EXTERNAL, ActivityOwnership.UNASSIGNED]),
              ]}
              currentActivityType={currentActivityType}
              setCurrentActivityType={setCurrentActivityType}
              setActivities={setActivities}
              setLoading={setLoading}
            />
          </StyledBlockContainer>
        </FormContainerLeft>
        <FormContainerRight item xs={6}>
          <StyledBlockContainer container z-index={0}>
            {loading ? (
              <Loader />
            ) : (
              <ActivitySelection
                activityOptions={activities}
                currentActivityType={currentActivityType}
                selectedActivity={selectedActivity}
                setSelectedActivity={setSelectedActivity}
              />
            )}
          </StyledBlockContainer>
          <ButtonContainer>
            <Box display="flex" justifyContent="end" gap={1.8} width="100%">
              <ThemeButton color="BLUE_ICE" size="large" type="button" onClick={onClose}>
                Cancel
              </ThemeButton>
              <ThemeButton
                color="YELLOW"
                size="large"
                type="submit"
                data-cy="add-ac-button"
                disabled={selectedActivity === undefined}
                onClick={onSubmitActivitySelection}
              >
                Add
              </ThemeButton>
            </Box>
          </ButtonContainer>
        </FormContainerRight>
      </DialogInner>
    </DialogDefault>
  );
};

export default SelectActivityDialog;
