import {
  AccordionDetails,
  AccordionSummary,
  DialogActions,
  DialogContent,
  Divider,
  Tooltip,
  styled,
} from '@mui/material';
import { Box } from '@mui/system';
import { Title } from 'components/Partners/PartnersTable/PartnersTableBody/PartnersTableBody.styles';
import { FlexBox } from 'components/Structure';
import { DialogDefault, InfoTooltip, ThemeButton, ThemeTypography } from 'designSystem';
import { Accordion } from 'designSystem/DataDisplay/Accordion/Accordion';
import RiskBadge from 'designSystem/DataDisplay/RiskBadge/RiskBadge';
import Icon from 'designSystem/Primitives/Icon/Icon';
import React, { FC, useState } from 'react';
import { EudrDataset, RiskLevel } from 'types/compliance.types';
import { IDefaultDialogProps } from 'types/dialog.types';
import { AvailableSizes } from 'types/enums';
import { getCountryLabelFromCode } from 'utils/country.utils';
import BatchTable from '../BatchTable';

interface IEudrStatementDetailsDialogProps extends IDefaultDialogProps {
  eudrDatasets: EudrDataset[];
  eoriNumber: string;
  internalDdsNumber?: string;
}

const Badge = styled(FlexBox)(({ theme }) => ({
  borderRadius: theme.spacing(0.5),
  padding: theme.spacing(0, 2),
  background: theme.custom.themeColors.primary[5],
}));

const AffectivenessIndicator = styled(FlexBox)(({ theme }) => ({
  background: theme.custom.themeColors.accent[20],
  borderRadius: 4,
  padding: theme.spacing(0, 2),
}));

const OverviewContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: 4,
  border: `1px solid ${theme.custom.themeColors.grayScale[10]}`,
}));

const SubmitStatus = styled(ThemeTypography)(() => ({
  textTransform: 'uppercase',
  fontSize: '14px !important',
}));

const EudrStatementDetailsDialog: FC<IEudrStatementDetailsDialogProps> = ({
  open,
  eudrDatasets,
  eoriNumber,
  internalDdsNumber,
  onClose,
}) => {
  const [expandedId, setExpandedId] = useState<string>();

  const handleDatasetExpandToggle = (id: string) => {
    setExpandedId(prev => (prev === id ? undefined : id));
  };

  return (
    <DialogDefault
      open={open}
      title="Statement details"
      data-cy="statement-details-dialog"
      fullWidth
      iconName="statement"
      maxWidth="sm"
      onClose={onClose}
    >
      <DialogContent>
        <OverviewContainer>
          <FlexBox mb={1}>
            <ThemeTypography variant="BODY_LARGE_BOLD">Statement:</ThemeTypography>
            <Box mr={1} />
            <Badge>
              <ThemeTypography variant="BODY_SMALL_BOLD">Sep 2024 Imports</ThemeTypography>
            </Badge>
            <AffectivenessIndicator mx={1}>
              <ThemeTypography variant="BODY_MEDIUM_BOLD">Fully affected</ThemeTypography>
            </AffectivenessIndicator>
            <RiskBadge riskLevel={RiskLevel.LOW} />
          </FlexBox>
          <Divider />
          <FlexBox my={1}>
            <SubmitStatus color="GREEN" variant="ITEM_SUBTITLE">
              Submitted
            </SubmitStatus>
          </FlexBox>

          <FlexBox mt={1} gap={1}>
            <ThemeTypography variant="BODY_MEDIUM">
              Due diligence statement (DDS) number:
            </ThemeTypography>
            <Badge>
              <ThemeTypography variant="BODY_SMALL_BOLD">{eoriNumber}</ThemeTypography>
            </Badge>
            <InfoTooltip
              size={AvailableSizes.SMALL}
              text="An Economic Operators Registration and Identification number (EORI number) is a EU registration and identification number for businesses which undertake the import or export of goods into or out of the EU. Any business or individual established in the EU needs to obtain an EORI number from their national customs authority before commencing customs activities in the EU."
            />
          </FlexBox>

          {internalDdsNumber && (
            <FlexBox mt={1} gap={1}>
              <ThemeTypography variant="BODY_MEDIUM">Reference number:</ThemeTypography>
              <Badge>
                <ThemeTypography variant="BODY_SMALL_BOLD">{internalDdsNumber}</ThemeTypography>
              </Badge>
              <InfoTooltip
                size={AvailableSizes.SMALL}
                text="If you have an internal Due diligence statement (DDS) number, you can add that here. This will allow other parties to find your statement and refer to it in the EU Information system."
              />
            </FlexBox>
          )}
        </OverviewContainer>

        <FlexBox mt={2}>
          <ThemeTypography variant="BODY_LARGE_BOLD">
            Origin geo-data & batch details:
          </ThemeTypography>
          <Badge ml={1}>
            <ThemeTypography variant="BODY_MEDIUM_BOLD">13 749 plots in total</ThemeTypography>
          </Badge>
        </FlexBox>

        <FlexBox width="100%" flexDirection="column" my={2} mb={3} maxHeight="50vh" overflow="auto">
          {eudrDatasets.map(
            ({
              id,
              title,
              rawMaterial,
              originCountry,
              ownedBy: partner,
              riskStatus,
              geocBatches,
            }) => (
              <Accordion key={id} expanded={id === expandedId}>
                <AccordionSummary onClick={() => handleDatasetExpandToggle(id)}>
                  <FlexBox>
                    <Icon name="polygon-data" color="gray-100" size="xx-large" mr={2} />
                    <Box display="flex" flexDirection="column">
                      <FlexBox>
                        <Title>{title}</Title>
                        <Badge p={0.5} mx={1}>
                          <ThemeTypography variant="BODY_SMALL_BOLD">120 plots</ThemeTypography>
                        </Badge>
                        {/* TODO: risk status does not exist in backend */}
                        <RiskBadge riskLevel={RiskLevel[riskStatus] || RiskLevel.LOW} />
                      </FlexBox>
                      <FlexBox>
                        <FlexBox mr={1}>
                          <Icon name="raw-material" color="gray-80" size="small" mr={0.5} />
                          <ThemeTypography variant="BODY_MEDIUM_BOLD" color="GRAY_80">
                            {rawMaterial.title}
                          </ThemeTypography>
                        </FlexBox>
                        <FlexBox mr={1}>
                          <Icon name="location" color="gray-80" size="small" mr={0.5} />
                          <ThemeTypography variant="BODY_MEDIUM_BOLD" color="GRAY_80">
                            {getCountryLabelFromCode(originCountry)}
                          </ThemeTypography>
                        </FlexBox>
                        <FlexBox>
                          <Icon name="partner" color="gray-80" size="small" mr={0.5} />
                          <ThemeTypography variant="BODY_MEDIUM_BOLD" color="GRAY_80">
                            {partner.name}
                          </ThemeTypography>
                        </FlexBox>
                      </FlexBox>
                    </Box>
                  </FlexBox>
                </AccordionSummary>
                <AccordionDetails>
                  <BatchTable batches={geocBatches} />
                </AccordionDetails>
              </Accordion>
            )
          )}
        </FlexBox>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <FlexBox gap={2}>
          <ThemeButton disabled startIcon={<Icon name="statement-retract" />} color="BLUE_ICE">
            Retract statement
          </ThemeButton>
          <Tooltip title="This option is no longer available from 72 h after submission.">
            <span>
              <ThemeButton disabled startIcon={<Icon name="statement-edit" />} color="BLUE_ICE">
                Amend statement
              </ThemeButton>
            </span>
          </Tooltip>
        </FlexBox>
        <ThemeButton onClick={onClose}>Ok</ThemeButton>
      </DialogActions>
    </DialogDefault>
  );
};

export default EudrStatementDetailsDialog;
