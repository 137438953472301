import isNil from 'lodash/isNil';
import every from 'lodash/every';
import isObject from 'lodash/isObject';

const hasCrop = crop => {
  return (
    isObject(crop) &&
    !isNil(crop.x) &&
    !isNil(crop.y) &&
    !isNil(crop.width) &&
    !isNil(crop.height) &&
    every(Object.values, val => !!val)
  );
};

const typeSerializers = {
  VideoType: (value = {}) => {
    const { __typename, vimeoLink } = value;

    return {
      __typename,
      vimeoLink,
    };
  },
  Video: (value = {}) => {
    const { __typename, videoId } = value;

    return {
      __typename,
      videoId,
    };
  },
  ColorType: (value = {}) => {
    const { __typename, hexCode } = value;

    return {
      __typename,
      hexCode,
    };
  },
  ImageVariant: (value = {}) => {
    const { crop, id, imageId } = value;
    const result = {
      imageId: id || imageId,
    };

    if (hasCrop(crop)) {
      result.crop = crop;
    }

    return result;
  },
  ImageType: (value = {}) => {
    const { crop, url, __typename } = value;
    const result = {
      url,
      __typename,
    };

    if (hasCrop(crop)) {
      result.crop = crop;
    }

    return result;
  },
};

export default typeSerializers;
