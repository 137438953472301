import { useQuery } from '@apollo/client';
import { useRequestReplyDialogContext } from 'components/PartnerIntegration/Contexts/RequestReplyDialogContext';
import usePartnerRequestActions from 'components/Partners/PartnerRequests/usePartnerRequestActions';
import { removeRequestFromCacheBuyerHome } from 'components/Partners/utils/partnerUtils';
import { RequestsTableAction } from 'components/Requests/PaginatedRequestsTable';
import { useConfig, useDialog, useMessages } from 'components/hooks';
import {
  GET_BASE_PARTNER_COMPANY,
  GET_ALL_PARTNER_REQUESTS,
} from 'graphql/queries/partner.queries';
import { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CompanyStatus } from 'types/company.types';
import { IBasePartnerCompany, PartnerRequest, PartnerRequestStatusType } from 'types/partner.types';
import { GraphQlConnection } from 'types/types';
import { useQueryParam } from 'use-query-params';

const usePartnerRequests = () => {
  const { id } = useParams<{ id: string | undefined }>();
  const navigate = useNavigate();
  const { updatePartnerRequest } = usePartnerRequestActions();
  const { setErrorMessage } = useMessages();
  const { openDialog } = useDialog();
  const { openDismissDialog } = useRequestReplyDialogContext();
  const { appQueryParams } = useConfig();
  const { data, loading, error } = useQuery<{
    partnerRequests: GraphQlConnection<PartnerRequest>;
  }>(GET_ALL_PARTNER_REQUESTS, {
    variables: {
      filters: {
        ...(id ? { requestedToCompanyIds: [id] } : {}),
        requestStatus: ['REQUESTED', 'PROVIDED', 'PROVIDED_COMPLETED', 'DENIED', 'EDITED'],
      },
    },
  });
  const { data: partnerData, loading: partnerLoading } = useQuery<{
    partner: IBasePartnerCompany;
  }>(GET_BASE_PARTNER_COMPANY, {
    skip: !id,
    variables: {
      id,
    },
  });

  const partner: IBasePartnerCompany | undefined = partnerData?.partner;
  const isPartnerRegistered = partner?.status === CompanyStatus.REGISTERED;

  const [searchTerm = '', setSearchTerm] = useQueryParam<string>(appQueryParams.query);

  const handleUpdateRequest = (requestId: string, requestStatus: PartnerRequestStatusType) => {
    updatePartnerRequest({
      variables: {
        id: requestId as string,
        input: {
          requestStatus: requestStatus,
        },
      },
      onError: () =>
        setErrorMessage('Something went wrong finishing the request. Please try again later.'),
      update: (cache, { data }) => {
        if (data) {
          removeRequestFromCacheBuyerHome(cache, data.updatePartnerRequest.partnerRequest);
        }
      },
    });
  };

  const handleAcceptRequest = (requestId: string) => {
    openDialog({
      type: 'ALERT',
      props: {
        title: 'Accept request',
        submitText: 'Accept and close request',
        text: `Are you sure you want to accept this request? The request will be closed and considered completed.`,
        onSubmit: () => handleUpdateRequest(requestId, 'PROVIDED_COMPLETED'),
        onCancel: () => undefined,
      },
    });
  };

  const handleReviewAndApprove = (request: PartnerRequest) => {
    openDialog({
      type: 'REVIEW_DOCUMENT_REQUEST',
      props: {
        request: request,
      },
    });
  };

  const handleRequestClick = (requestId: string) => {
    navigate(`/partners/${id}/requests/${requestId}`);
  };

  const handleActionClick = (requestId: string, action: RequestsTableAction) => {
    switch (action) {
      case RequestsTableAction.MANAGE:
        const request = allRequests?.find(request => request.id === requestId);
        if (!request) {
          console.error('No request found');
          return;
        }
        openDialog({
          type: 'MANAGE_REQUEST',
          props: { request },
        });
        break;
      case RequestsTableAction.DISMISS:
        openDismissDialog(requestId);
        break;
      case RequestsTableAction.SEE_DETAILS: {
        const request = allRequests?.find(request => request.id === requestId);
        if (!request) {
          console.error('No request found');
          return;
        }
        openDialog({ type: 'REQUEST_DETAILS', props: { request } });
        break;
      }
      case RequestsTableAction.ACCEPT:
        handleAcceptRequest(requestId);
        break;
      case RequestsTableAction.ADD_CLAIM:
        openDialog({ type: 'CREATE_IMPACT_CLAIM' });
        break;
      case RequestsTableAction.DISMISS_EDIT: {
        const request = allRequests?.find(request => request.id === requestId);
        if (!request) {
          console.error('No request found');
          return;
        }
        openDialog({
          type: 'PARTNER_REQUEST',
          props: { requestedToCompany: request?.requestedToCompany, initialRequest: request },
        });
        break;
      }
      case RequestsTableAction.EDIT: {
        const request = allRequests?.find(request => request.id === requestId);
        if (!request) {
          console.error('No request found');
          return;
        }
        openDialog({
          type: 'PARTNER_REQUEST',
          props: { requestedToCompany: request?.requestedToCompany, initialRequest: request },
        });
        break;
      }
      case RequestsTableAction.REVIEW: {
        const request = allRequests?.find(request => request.id === requestId);
        if (!request) {
          console.error('No request found');
          return;
        }
        handleReviewAndApprove(request);
        break;
      }
      case RequestsTableAction.DELETE:
        openDialog({
          type: 'ALERT',
          props: {
            title: 'Delete request',
            submitText: 'Delete request',
            text: `Are you sure you want to delete this request?`,
            onSubmit: () => handleUpdateRequest(requestId, 'DELETED'),
            onCancel: () => undefined,
          },
        });
        break;
      default:
        console.error('Unknown action', action);
    }
  };

  /**
   * Route to main overview and open create new request dialog
   */
  const handleNewRequestClick = () => {
    if (id) {
      navigate(`/partners/${id}?createNewRequest=1`);
    } else {
      console.error('No partner id found');
    }
  };

  const allRequests = useMemo(() => {
    // use removeGraphConnections
    const nodeDocuments = [...(data?.partnerRequests?.edges || [])];
    // Last items as first
    // Denied completed and deleted requests are not shown
    const requestedDocuments = nodeDocuments
      .map(item => item.node)
      .filter(
        ({ requestStatus }) => requestStatus !== 'DENIED_COMPLETED' && requestStatus !== 'DELETED'
      )
      .reverse();

    return requestedDocuments;
  }, [data]);

  /**
   * Filter requests by search term
   * By default the all requests without the fulfilled once are shown
   */
  const filteredRequests = useMemo(() => {
    if (searchTerm?.length) {
      return allRequests.filter(({ requestTitle }) =>
        requestTitle.toLowerCase().includes(searchTerm.toLowerCase())
      );
    } else {
      return allRequests.filter(({ requestStatus }) => requestStatus !== 'PROVIDED_COMPLETED');
    }
  }, [allRequests, searchTerm]);

  const fulfilledRequests = useMemo(
    () => allRequests.filter(({ requestStatus }) => requestStatus === 'PROVIDED_COMPLETED'),
    [allRequests]
  );

  return {
    error,
    // If partner id is defined, wait for both partner and requests to be loaded
    loading: id ? loading || partnerLoading : loading,
    allRequests,
    filteredRequests,
    fulfilledRequests,
    searchTerm,
    isPartnerRegistered,
    setSearchTerm,
    handleRequestClick,
    handleActionClick,
    handleNewRequestClick,
  };
};

export default usePartnerRequests;
