import { useQuery } from '@apollo/client';
import { useConfig } from 'components/hooks';
import { GET_LATEST_VERSION_AND_PRODUCT } from 'graphql/queries';
import { useParams } from 'react-router-dom';
import { AvailableLanguages } from 'types/enums';
import { RouteParamsWithId } from 'types/router.types';
import { Product, ProductVersion } from 'types/types';
import { StringParam, useQueryParam } from 'use-query-params';

interface UseProductProps {
  loading: boolean;
  error?: unknown;
  currentProductId?: string;
  product?: Product;
  latestProductVersion?: ProductVersion;
  lang?: AvailableLanguages;
}

/**
 *  This dialog gets called from a dialog and the dialog context is not aware of any auth props
 */
export const useProduct = (productId?: string): UseProductProps => {
  const params = useParams<RouteParamsWithId>();

  const { appQueryParams } = useConfig();
  const [lang] = useQueryParam(appQueryParams.lang, StringParam);

  const { data, loading, error } = useQuery<{
    product: Product;
    latestProductVersion: ProductVersion;
  }>(GET_LATEST_VERSION_AND_PRODUCT, {
    skip: !params.id && !productId,
    variables: {
      id: params.id || productId,
      lang,
    },
  });
  // Use the default language as fallback since the lang query param will be empty if it's the default language
  const languageWithFallback = lang || data?.product?.languageConfig?.default;

  return {
    loading,
    error,
    currentProductId: params.id || productId,
    product: data?.product,
    latestProductVersion: data?.latestProductVersion,
    // Make sure the lang is actually a language that's defined, since it can be changed in the url
    lang: Object.values(AvailableLanguages).includes(languageWithFallback as AvailableLanguages)
      ? (languageWithFallback as AvailableLanguages)
      : undefined,
  };
};
