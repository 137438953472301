import { Box } from '@mui/material';
import { Edit } from '@styled-icons/boxicons-solid';
import { FlexBox } from 'components/Structure';
import { CardContainer, ItemCardSmall, ThemeButton, ThemeTypography } from 'designSystem';
import Icon from 'designSystem/Primitives/Icon/Icon';
import React, { FC } from 'react';
import { AvailableSizes, BackgroundTypes } from 'types/enums';
import { ISite } from 'types/site.types';
import { SiteLabelKeys } from 'utils/site.utils';
import Image from 'designSystem/DataDisplay/Image/Image';

interface IPolygonDataCardProps {
  site: ISite;
  onAddPolygonClick: () => void;
}

const PolygonDataCard: FC<IPolygonDataCardProps> = ({ site, onAddPolygonClick }) => {
  return (
    <CardContainer
      size={AvailableSizes.LARGE}
      background={BackgroundTypes.MAP_LAYERS}
      title="Upload or draw polygons for the cultivation area of each site"
    >
      <Box mt="auto" pb={2}>
        <ItemCardSmall>
          <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
            <Box display="flex" alignItems="center">
              {!!site.image ? (
                <Image width={44} height={44} image={site.image} />
              ) : (
                <Box display="flex" bgcolor="white" borderRadius="6px" padding="4px">
                  <Icon name="site-placeholder" size="xx-large" color="blue-ice" />
                </Box>
              )}
              <Box ml={1.5}>
                <FlexBox>
                  <ThemeTypography variant="ITEM_TITLE">{site.title}</ThemeTypography>
                  {site.externalId && (
                    <Box ml={0.8}>
                      <ThemeTypography
                        variant="BODY_MEDIUM"
                        autoOverflow
                        maxWidth={150}
                        color="GRAY_80"
                      >
                        | {site.externalId}
                      </ThemeTypography>
                    </Box>
                  )}
                </FlexBox>
                <FlexBox>
                  <Icon name="tag-filled" size="small" color="gray-60" />
                  <Box ml={0.5} />
                  <ThemeTypography variant="LABEL_INPUT">
                    {SiteLabelKeys[site.siteType]}
                  </ThemeTypography>
                  <Box ml={1} />
                  <Icon name="location" size="small" color="gray-60" />
                  <Box ml={0.5} />
                  <ThemeTypography variant="LABEL_INPUT">{site.locationName}</ThemeTypography>
                </FlexBox>
              </Box>
            </Box>
            <ThemeButton
              color="YELLOW"
              size="large"
              startIcon={<Edit size={14} />}
              onClick={onAddPolygonClick}
            >
              Add polygon
            </ThemeButton>
          </Box>
        </ItemCardSmall>
      </Box>
    </CardContainer>
  );
};

export default PolygonDataCard;
