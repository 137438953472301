import * as Yup from 'yup';
import { createMediaSchema, dependantUrlTest } from './shared';

export const companySchema = Yup.object().shape({
  name: Yup.string().required('This field is required'),
  onlineShop: Yup.string().url(),
  website: Yup.string().url().required('This field is required'),
  social: Yup.object().shape({
    facebook: Yup.string().label('Facebook page').test(dependantUrlTest).nullable().default(''),
    instagram: Yup.string()
      .label('Instagram account')
      .test(dependantUrlTest)
      .nullable()
      .default(''),
    twitter: Yup.string().label('Twitter account').test(dependantUrlTest).nullable().default(''),
  }),
});

export const addressSchema = Yup.object().shape({
  street: Yup.string().default('').required('This field is required'),
  streetNumber: Yup.string().default('').required('This field is required'),
  zipCode: Yup.string().default('').required('This field is required'),
  city: Yup.string().default('').required('This field is required'),
  country: Yup.string().default('').required('This field is required'),
});

export const companySchemaCombined = companySchema.concat(
  // @ts-ignore TODO: Check the error
  Yup.object().shape({
    logo: createMediaSchema('Please add a logo'),
    address: addressSchema,
  })
);
