import Moment from 'react-moment';
import PropTypes from 'prop-types';
import React from 'react';

const recentActivityTooltip = ({ tooltipState, timeInterval, colors }) => {
  const tooltipDateFormat = timeInterval === 'H' ? 'hh:mm, MMMM D' : 'MMMM D, YYYY';

  return (
    <div>
      <strong>
        {tooltipState.tooltipData && (
          <Moment format={tooltipDateFormat}>{tooltipState.tooltipData[0].date}</Moment>
        )}
      </strong>
      {tooltipState.tooltipData &&
        tooltipState.tooltipData.map((d, i) => (
          <div key={i}>
            <span
              style={{
                display: 'inline-block',
                borderRadius: '50%',
                width: 8,
                height: 8,
                marginRight: 6,
                backgroundColor: colors[i],
              }}
            />
            {d.value}
          </div>
        ))}
    </div>
  );
};

recentActivityTooltip.propTypes = {
  tooltipState: PropTypes.object.isRequired,
  timeInterval: PropTypes.string,
  colors: PropTypes.arrayOf(PropTypes.string.isRequired),
};

export default recentActivityTooltip;
