import { Alert, AlertTitle, Backdrop } from '@mui/material';
import { styled } from '@mui/material/styles';
import useFeatureFlags, { FeatureFlag } from 'components/hooks/useFeatureFlags';
import ThemeTypography from 'designSystem/Primitives/Typography/ThemeTypography';
import React, { FC, PropsWithChildren, createContext } from 'react';

const MaintenanceContext = createContext<null>(null);

const StyledBackdrop = styled(Backdrop)(() => ({
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 9999,
}));

/**
 * Needs the custom params provider
 */
const MaintenanceProvider: FC<PropsWithChildren> = ({ children }) => {
  const { isFeatureEnabled } = useFeatureFlags();
  const isUnderMaintenance = isFeatureEnabled(FeatureFlag.UNDER_MAINTENANCE);

  return (
    <MaintenanceContext.Provider value={null}>
      <StyledBackdrop open={isUnderMaintenance || false}>
        <Alert severity="warning">
          <AlertTitle>Under Maintenance</AlertTitle>
          <ThemeTypography variant="BODY_LARGE">
            We are currently working on the seedtrace platform. Please be patient. The platform will
            be available again soon.
          </ThemeTypography>
        </Alert>
      </StyledBackdrop>
      {children}
    </MaintenanceContext.Provider>
  );
};

export default MaintenanceProvider;
