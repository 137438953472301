import { Box, Grid } from '@mui/material';
import { Loader } from 'components/Forms';
import { MetricNumber } from 'components/Product/Analytics';
import { RecentActivityLineChart } from 'components/Product/Analytics/Cards';
import RecentActivityLegend from 'components/Product/Analytics/Cards/RecentActivityLegend';
import { useProductAnalyticsOverview } from 'components/Product/Overview/hooks';
import { EmptyState, ErrorState } from 'components/Structure';
import { useProductActions } from 'components/hooks';
import { ActionLink, CardContainer, ThemeTypography } from 'designSystem';
import React, { FC } from 'react';
import { ProductVersion } from 'types/types';

interface IProductAnalyticsSmallProps {
  product: ProductVersion;
}

const ProductAnalyticsSmall: FC<IProductAnalyticsSmallProps> = ({ product }) => {
  const { hasData, productAnalyticsExist, pageActivity, views, loading, status, error } =
    useProductAnalyticsOverview({
      product,
    });

  const { goToProductAnalytics } = useProductActions();
  const partialLoading = hasData && loading;

  if (loading && !hasData)
    return (
      <CardContainer minHeight={400}>
        <Loader />
      </CardContainer>
    );
  if (error) return <ErrorState error={error} />;
  if (status === 'draft') {
    return <ErrorState error="Publish your product to start collecting data" />;
  }

  if (!productAnalyticsExist) return <EmptyState />;

  return (
    <CardContainer minHeight={400}>
      <Grid container>
        <Grid item xs={8}>
          <Box mb={1}>
            <ThemeTypography variant="TITLE_SMALL">See how people use your product</ThemeTypography>
          </Box>
          <ThemeTypography variant="BODY_LARGE">
            Analyse your visitors specifically for this product.
          </ThemeTypography>
        </Grid>
        <Grid item xs={4}>
          <RecentActivityLegend flexDirection="column" gridGap={8} alignItems="flex-end" />
        </Grid>
      </Grid>
      <RecentActivityLineChart
        data={[pageActivity.total, pageActivity.totalUnique]}
        interval={pageActivity.timeInterval}
        loading={partialLoading}
        showAxisBottom={false}
        showAxisLeft={false}
        height={280}
        filled
        margin={{
          top: 10,
          left: 0,
          bottom: 10,
          right: 0,
        }}
      />
      <Box display="flex" alignItems="flex-end" justifyContent="space-between">
        <MetricNumber
          total={views.current}
          label="Total page views this month"
          change={views.percentageChange}
          display="flex"
          textAlign="left"
          alignItems="center"
          comparedLabel="vs. last week"
          unit={undefined}
          gap={1}
          p={0}
        />
        <ActionLink onClick={() => goToProductAnalytics(product.id)}>View analytics</ActionLink>
      </Box>
    </CardContainer>
  );
};

export default ProductAnalyticsSmall;
