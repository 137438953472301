import { Box } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { AxisBottom, AxisLeft } from '@vx/axis';
import { curveMonotoneX } from '@vx/curve';
import { localPoint } from '@vx/event';
import { Group } from '@vx/group';
import { ScaleSVG, withParentSize } from '@vx/responsive';
import { scaleLinear, scaleTime } from '@vx/scale';
import { AreaClosed, Line, LinePath } from '@vx/shape';
import { useBeforeFirstRender } from 'components/Product/hooks';
import { bisector, extent, max } from 'd3-array';
import PropTypes from 'prop-types';
import React, { Fragment, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Motion, spring } from 'react-motion';
import { findPathYatX } from './utils';

const AxisLeftMem = memo(AxisLeft);
const AxisBottomMem = memo(AxisBottom);
const GroupMem = memo(Group);
const LinePathMem = memo(LinePath);

const bisectDate = bisector(d => new Date(d.date)).left;

const Tooltip = styled(({ innerRef, children, ...props }) => (
  <div ref={innerRef} {...props}>
    {children}
  </div>
))({
  position: 'absolute',
  backgroundColor: 'white',
  color: 'rgba(25, 29, 34, 0.54)',
  padding: 12,
  fontSize: 14,
  boxShadow: '0 4px 8px 0 rgba(25, 29, 34, 0.1)',
  pointerEvents: 'none',
  borderRadius: 3,
  border: '1px solid rgba(25, 29, 34, 0.12)',
});

const defaultMargin = {
  top: 10,
  left: 20,
  bottom: 30,
  right: 20,
};

const LineGraph = ({
  height = 400,
  parentWidth = 800,
  margin = defaultMargin,
  data: series,
  interval,
  renderHeader,
  graphTooltip,
  graphColors,
  graphNumTicks,
  tickFormatY,
  filled,
  showAxisBottom = true,
  showAxisLeft = true,
}) => {
  const [, setForceRender] = useState(0);
  const theme = useTheme();
  const graphColor = theme.palette.grey[400];
  const colors = graphColors || [theme.palette.secondary.main, theme.palette.primary.main];

  let numTicksX = Math.round(series[0].length * 1.1);
  numTicksX = numTicksX > 14 ? 14 : numTicksX;

  const allData = useMemo(() => series.reduce((acc, arr) => acc.concat(arr), []), [series]);

  // STATE

  const [tooltipState, setTooltipState] = useState({
    tooltipOpen: false,
    tooltipLeft: 0,
    tooltipTop: 0,
    tooltipData: null,
    vertLineLeft: 0,
  });

  const svgRef = useRef();
  const pathRefs = useRef({});
  const state = useRef({
    tooltip: null,
    tooltipWidth: 0,
    yMax: height,
    xMax: parentWidth,
  });

  const setPathRef = useCallback(ref => {
    if (ref) pathRefs.current[ref.getAttribute('data-index')] = ref;
  }, []);

  const setTooltipRef = useCallback(ref => {
    state.current.tooltip = ref;

    if (state.current.tooltip) {
      state.current.tooltipWidth = ref.getBoundingClientRect().width;
    }
  }, []);

  // GRAPH COORDINATES METHODS

  const x = useCallback(d => new Date(d.date), []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const y = useCallback(d => (interval === 'ms' ? d.value / 1000 : d.value), []);

  const getXMax = useCallback(() => {
    return parentWidth - margin.left - margin.right;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentWidth]);

  const getYMax = useCallback(() => {
    return height - margin.top - margin.bottom;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [height]);

  const getXScale = useCallback((data, x, xMax) => {
    return scaleTime({
      domain: extent(data, x),
      range: [0, xMax],
    });
  }, []);

  const getYScale = useCallback((data, y, yMax) => {
    return scaleLinear({
      domain: [0, max(data, y)],
      range: [yMax, 0],
    });
  }, []);

  const tickFormat = useCallback(e => {
    if (Math.floor(e) !== e) return;

    if (tickFormatY) e = tickFormatY(e);

    return e;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tickLabelProps = useCallback(
    () => ({
      fill: graphColor,
      fontFamily: theme.typography.fontFamily,
      fontSize: 10,
      textAnchor: 'middle',
      fontWeight: '600',
      dy: '0.25em',
    }),
    [theme, graphColor]
  );

  const update = useCallback(
    data => {
      state.current.xMax = getXMax();
      state.current.yMax = getYMax();

      const { yMax, xMax } = state.current;

      state.current.xScale = getXScale(data, x, xMax);
      state.current.yScale = getYScale(data, y, yMax);

      state.current.yScaleFormat = state.current.yScale.tickFormat(5, '0');
    },
    [getXMax, getYMax, getYScale, getXScale, x, y]
  );

  // TOOLTIP METHODS

  const closeTooltip = useCallback(() => {
    setTooltipState(tState => ({
      ...tState,
      tooltipOpen: false,
    }));
  }, [setTooltipState]);

  const showTooltipAt = useCallback(
    (x, y) => {
      const xMax = getXMax();
      const yMax = getYMax();

      const positionX = x - margin.left;
      const positionY = y - margin.top;

      if (positionX < 0 || positionX > xMax || positionY < 0 || positionY > yMax) {
        return closeTooltip();
      }

      state.current.tooltipWidth = state.current.tooltip.getBoundingClientRect().width;

      const xOffset = 18;
      const yOffset = 18;

      const positionXWithOffset = positionX + xOffset;
      const pastRightSide = positionXWithOffset + state.current.tooltipWidth > xMax;
      const tooltipLeft = pastRightSide
        ? positionX - state.current.tooltipWidth - xOffset
        : positionXWithOffset;

      const tooltipTop = positionY - yOffset;

      const dataPoints = series.map(d => {
        const xDomain = state.current.xScale.invert(x - margin.left);
        const index = bisectDate(d, xDomain, 1);

        const dLeft = d[index - 1] || {};
        const dRight = d[index] || {};

        const isRightCloser = xDomain - new Date(dLeft.date) >= new Date(dRight.date) - xDomain;

        return isRightCloser ? dRight : dLeft;
      });

      setTooltipState(tState => ({
        ...tState,
        tooltipOpen: true,
        tooltipData: dataPoints,
        tooltipLeft,
        tooltipTop,
        vertLineLeft: state.current.xScale(new Date(dataPoints[0].date)),
      }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setTooltipState, closeTooltip, getXMax, getYMax, series]
  );

  const mouseLeave = useCallback(() => {
    closeTooltip();
  }, [closeTooltip]);

  const mouseMove = useCallback(
    event => {
      const { x, y } = localPoint(svgRef.current, event);
      showTooltipAt(x, y);
    },
    [showTooltipAt]
  );

  const getPathYFromX = useCallback((index, x) => {
    const path = pathRefs.current[index];

    try {
      return findPathYatX(x, path, index);
    } catch {
      return null;
    }
  }, []);

  // SIDE EFFECTS

  useEffect(() => {
    update(allData);
    setForceRender(f => f + 1);
  }, [update, allData]);

  useBeforeFirstRender(() => update(allData));

  return (
    <Box position="relative">
      {renderHeader}
      <ScaleSVG
        width={parentWidth}
        height={height}
        style={{
          overflow: 'visible',
        }}
        innerRef={svgRef}
      >
        <GroupMem top={margin.top} left={margin.left}>
          <Motion
            defaultStyle={{
              left: 0,
              opacity: 0,
            }}
            style={{
              left: spring(tooltipState.vertLineLeft || 0),
              opacity: spring(tooltipState.tooltipOpen ? 0.5 : 0),
            }}
          >
            {style => (
              <Line
                from={{
                  x: style.left,
                  y: 0,
                }}
                to={{
                  x: style.left,
                  y: state.current.yMax,
                }}
                stroke={graphColor}
                strokeDasharray={4}
                opacity={style.opacity}
              />
            )}
          </Motion>
          {series.map((seriesData, i) => (
            <Fragment key={i}>
              {filled && (
                <AreaClosed
                  data={seriesData}
                  x={d => state.current.xScale(x(d))}
                  y={d => state.current.yScale(y(d))}
                  yScale={state.current.yScale}
                  strokeWidth={0}
                  stroke={colors[i]}
                  fill={colors[i]}
                  fillOpacity={0.15}
                  curve={curveMonotoneX}
                />
              )}
              <LinePathMem
                data-index={i}
                data={seriesData}
                x={d => state.current.xScale(x(d))}
                y={d => state.current.yScale(y(d))}
                curve={curveMonotoneX}
                stroke={colors[i]}
                strokeLinecap="round"
                strokeWidth={2}
                innerRef={setPathRef}
                data-cy="line-path"
              />
            </Fragment>
          ))}
          <Motion
            defaultStyle={{
              opacity: 0,
              x: tooltipState.vertLineLeft,
            }}
            style={{
              opacity: spring(tooltipState.tooltipOpen ? 1 : 0),
              x: spring(tooltipState.vertLineLeft),
            }}
          >
            {style =>
              tooltipState.tooltipData && (
                <g>
                  {tooltipState.tooltipData.map((d, i) => {
                    const y = getPathYFromX(i, style.x);

                    return (
                      <g key={i}>
                        <circle
                          cx={style.x}
                          cy={y}
                          r={12}
                          fill={colors[i]}
                          stroke={colors[i]}
                          strokeWidth=".6"
                          fillOpacity={style.opacity / 12}
                          strokeOpacity={style.opacity / 2}
                        />
                        <circle
                          cx={style.x}
                          cy={y}
                          r={4}
                          fill="white"
                          stroke={colors[i]}
                          strokeWidth="1.5"
                          fillOpacity={style.opacity}
                          strokeOpacity={style.opacity}
                        />
                      </g>
                    );
                  })}
                </g>
              )
            }
          </Motion>
          <rect
            x="0"
            y="0"
            width={state.current.xMax}
            height={state.current.yMax}
            fill="transparent"
            onMouseLeave={mouseLeave}
            onMouseMove={mouseMove}
            onTouchMove={mouseMove}
          />
        </GroupMem>
        {showAxisLeft && (
          <AxisLeftMem
            top={margin.top}
            left={margin.left}
            scale={state.current.yScale}
            tickFormat={tickFormat}
            hideTicks
            hideAxisLine
            numTicks={graphNumTicks || 5}
            stroke={graphColor}
            tickLabelProps={tickLabelProps}
          />
        )}
        {showAxisBottom && (
          <AxisBottomMem
            top={height - margin.bottom}
            left={margin.left}
            scale={state.current.xScale}
            hideTicks
            numTicks={numTicksX}
            stroke={graphColor}
            tickLabelProps={tickLabelProps}
          />
        )}
      </ScaleSVG>
      <div
        style={{
          position: 'absolute',
          top: margin.top,
          left: margin.left,
          width: state.current.xMax,
          height: state.current.yMax,
          pointerEvents: 'none',
        }}
      >
        <Motion
          defaultStyle={{
            left: tooltipState.tooltipLeft || 0,
            top: tooltipState.tooltipTop || 0,
            opacity: 0,
          }}
          style={{
            left: spring(tooltipState.tooltipLeft || 0),
            top: spring(tooltipState.tooltipTop || 0),
            opacity: spring(tooltipState.tooltipOpen ? 1 : 0),
          }}
        >
          {style => (
            <Tooltip
              innerRef={setTooltipRef}
              style={{
                top: style.top,
                left: style.left,
                opacity: style.opacity,
              }}
            >
              {graphTooltip(tooltipState)}
            </Tooltip>
          )}
        </Motion>
      </div>
    </Box>
  );
};

LineGraph.propTypes = {
  data: PropTypes.array.isRequired,
  height: PropTypes.number,
  parentWidth: PropTypes.number,
  margin: PropTypes.shape({
    top: PropTypes.number,
    right: PropTypes.number,
    bottom: PropTypes.number,
    left: PropTypes.number,
  }),
  interval: PropTypes.string,
  renderHeader: PropTypes.node,
  graphTooltip: PropTypes.func,
  graphColors: PropTypes.array,
  graphNumTicks: PropTypes.number,
  tickFormatY: PropTypes.func,
  filled: PropTypes.bool,
  showAxisBottom: PropTypes.bool,
  showAxisLeft: PropTypes.bool,
};

export default memo(withParentSize(LineGraph));
