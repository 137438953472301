import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React from 'react';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Label = styled(({ color, backgroundColor, ...props }) => <div {...props} />)(
  ({ theme, color, backgroundColor }) => ({
    color,
    backgroundColor,
    fontSize: 12,
    fontWeight: 600,
    textTransform: 'uppercase',
    padding: theme.spacing(0.5, 2),
    borderRadius: 4,
  })
);

const StatusLabel = ({ progress }) => {
  return (
    <Label color={progress[2]} backgroundColor={progress[3]}>
      {progress[0]}
    </Label>
  );
};

StatusLabel.propTypes = {
  progress: PropTypes.array.isRequired,
};

export default StatusLabel;
