import React from 'react';
import { FormikAutoValidate } from 'components/Forms';
import { useEditorState } from './hooks';

const AutoValidate = () => {
  const { showValidation } = useEditorState();

  return <FormikAutoValidate showValidation={showValidation} />;
};

export default AutoValidate;
