import { InputLabel, Select } from '@mui/material';
import { styled } from '@mui/material/styles';
import { DropDown } from 'components/Forms';

export const StyledSelect = styled(Select)({
  '& .MuiSelect-select': {
    padding: '5px 5px 2px 5px',
    backgroundColor: '#fff',
    minHeight: 33,
  },
});

export const StyledDropDown = styled(DropDown)({
  minHeight: 40,
});

export const Label = styled(InputLabel)(({ theme }) => ({
  zIndex: 1,
  fontSize: 14,
  fontWeight: 500,
  color: theme.palette.grey[500],
}));
