import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import FileUploadItem from './FileUploadItem';

const List = styled('div')(({ theme }) => ({
  height: '100%',
  overflow: 'auto',
  marginTop: theme.spacing(3),
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(2),
  overflowX: 'auto',
}));

const UploadFileList = ({ fileIDs, hasFiles }) => {
  return (
    hasFiles && (
      <List data-cy="upload-file-list">
        {fileIDs.map(fileID => (
          <FileUploadItem key={fileID} id={fileID} />
        ))}
      </List>
    )
  );
};

UploadFileList.propTypes = {
  fileIDs: PropTypes.array.isRequired,
  hasFiles: PropTypes.bool,
};

export default UploadFileList;
