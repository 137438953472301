import { Grid, styled } from '@mui/material';
import SitesAutocomplete from 'components/Sites/Forms/SitesAutocomplete';
import { applicationHints } from 'config/applicationHints';
import { FieldWrapper, ThemeTypography } from 'designSystem';
import HTMLEditorField from 'designSystem/Formik/HTMLEditorField/HTMLEditorField';
import TextField from 'designSystem/Formik/TextField/TextField';
import { Field, useField } from 'formik';
import React, { FC } from 'react';
import { IChainActivity } from 'types/chain.types';
import { ISite, SiteType } from 'types/site.types';
import { ShadowedFieldBlock } from './styles';

interface ISiteSelectorBlockProps {
  /**
   * The mode of the component block, either "select" the output component which will query all available components or
   * "translate" which will only allow the user to change the language of already selected output component, if none is selected the field will be hidden
   * Default: "select"
   */
  mode?: 'default' | 'translate';
}

const StyledFieldWrapper = styled(FieldWrapper)(({ theme }) => ({
  gap: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
}));

const SiteSelectorBlock: FC<ISiteSelectorBlockProps> = ({ mode = 'default' }) => {
  /**
   *  Set the value of the owner automatically when a site is selected
   *  They can be changed again by the user if needed, but we want to guide the user to select the correct values
   */
  const [, , { setValue: setOwnerValue }] = useField<IChainActivity['activity']['ownedBy'] | null>(
    'activity.ownedBy'
  );

  const [{ value }, { error }, { setValue, setTouched }] = useField<
    IChainActivity['activity']['site'] | undefined
  >('activity.site');

  const handleSelectionChange = async (selectedSite: ISite | undefined) => {
    await setTouched(true);
    setValue(selectedSite, true);
    if (selectedSite?.ownedBy) {
      setOwnerValue(selectedSite.ownedBy);
    }
  };

  return (
    <ShadowedFieldBlock container grid-gap={2}>
      <Grid item xs={12}>
        {mode === 'default' && (
          <FieldWrapper
            label="Site"
            required
            fullWidth
            tooltip={{
              helperText:
                'Enter the site where this activity takes place. This includes the physical location and the type of site. You can re-use your existing sites in the platform, or create new.',
              variant: 'INFO',
            }}
          >
            <SitesAutocomplete
              defaultValue={value}
              errorMessage={error}
              onSiteSelect={handleSelectionChange}
            />
          </FieldWrapper>
        )}

        {mode === 'translate' && (
          <>
            <StyledFieldWrapper
              label="Site"
              required
              fullWidth
              tooltip={{
                helperText: 'Enter the translation of the site details.',
                variant: 'INFO',
              }}
            >
              <FieldWrapper
                label={value?.siteType === SiteType.CLUSTER ? 'Country' : 'Location'}
                required
                fullWidth
                gap={2}
              >
                <Field
                  component={TextField}
                  fullWidth
                  type="text"
                  name="activity.site.locationName"
                  variant="outlined"
                  disabled
                  size="small"
                  placeholder="Change site language here"
                  data-cy="site-lang-input"
                  inputProps={{
                    style: { fontSize: 14, height: 19 }, // Fix size difference between select and text input
                    'data-cy': 'site-lang-input',
                  }}
                />
              </FieldWrapper>

              <FieldWrapper label="Site title" required fullWidth>
                <Field
                  component={TextField}
                  fullWidth
                  type="text"
                  name="activity.site.title"
                  variant="outlined"
                  charLimit={50}
                  size="small"
                  placeholder="Change site language here"
                  data-cy="site-lang-input"
                  inputProps={{
                    style: { fontSize: 14, height: 19 }, // Fix size difference between select and text input
                    'data-cy': 'site-lang-input',
                  }}
                />
              </FieldWrapper>

              {/* The description can not be added / edited on clusters */}
              {value?.siteType !== SiteType.CLUSTER && (
                <FieldWrapper
                  label="Description"
                  tooltip={{
                    variant: 'HINT',
                    helperText: applicationHints.activity.description,
                  }}
                >
                  <Field
                    component={HTMLEditorField}
                    name="activity.site.description"
                    minHeight={80}
                    charLimit={650}
                    placeholder="Describe what is happening at this site..."
                    data-cy="site-description-input"
                  />
                </FieldWrapper>
              )}

              <ThemeTypography variant="BODY_MEDIUM" color="ALMOST_BLACK">
                You can only correct the language translation here. The site can be changed in the
                supply chain mapping tool.
              </ThemeTypography>
            </StyledFieldWrapper>
          </>
        )}
      </Grid>
    </ShadowedFieldBlock>
  );
};

export default SiteSelectorBlock;
