import React from 'react';
import { Grid, Box } from '@mui/material';
import OverallRatingCard from './OverallRatingCard';
import RatingChartCard from './RatingChartCard';
import FeedbackList from './FeedbackList';

const FeedbackDashboard = ({ productFeedbackAnalytics, reactions }) => {
  const { average, ratings, total } = productFeedbackAnalytics;

  return (
    <Box ml={-1} mr={-1} mt={2}>
      <Grid container spacing={2}>
        <Grid item xs={5}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <OverallRatingCard average={average} total={total} />
            </Grid>
            <Grid item xs={12}>
              <RatingChartCard ratings={ratings} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={7}>
          <FeedbackList reactions={reactions} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default FeedbackDashboard;
