import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { TextInputField, CountrySelect } from 'components/Forms';
import { FieldWrapper } from 'designSystem';
import { FastField as Field } from 'formik';

const AddressFields = ({ fullWidth, accessor = '' }) => {
  return (
    <Grid item xs={fullWidth ? 12 : 6}>
      <Grid container spacing={3}>
        <Grid item xl={9} lg={9} md={9} xs={12}>
          <TextInputField
            label="Street Name"
            name={`${accessor}street`}
            inputProps={{
              'data-cy': 'street-name-field',
            }}
          />
        </Grid>
        <Grid item xl={3} lg={3} md={3} xs={12}>
          <TextInputField
            label="Number"
            name={`${accessor}streetNumber`}
            inputProps={{
              'data-cy': 'street-number-field',
            }}
          />
        </Grid>
        <Grid item xl={6} lg={6} md={6} xs={12}>
          <TextInputField
            label="Postal Code"
            name={`${accessor}zipCode`}
            inputProps={{
              'data-cy': 'zip-code-field',
            }}
          />
        </Grid>
        <Grid item xl={6} lg={6} md={6} xs={12}>
          <TextInputField
            label="City"
            name={`${accessor}city`}
            inputProps={{
              'data-cy': 'city-field',
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <FieldWrapper label="Country">
            <Field component={CountrySelect} name={`${accessor}country`} />
          </FieldWrapper>
        </Grid>
      </Grid>
    </Grid>
  );
};

AddressFields.propTypes = {
  fullWidth: PropTypes.bool,
};

export default AddressFields;
