import React, { FC } from 'react';
import { Box, Collapse, TableBody, TableCell } from '@mui/material';
import { styled } from '@mui/material/styles';
import { StyledTable, StyledTableRow } from 'components/TableBase';
import { ThemeButton, ThemeTypography } from 'designSystem';
import { Booleanish, booleanish } from 'types/booleanish.types';
import ItemProgressTitle from 'designSystem/DataDisplay/ItemProgressTitle/ItemProgressTitle';
import Icon from 'designSystem/Primitives/Icon/Icon';
import { useDialog } from 'components/hooks';
import { PartnerRequest, PartnerRequestStatus } from 'types/partner.types';
import { useNavigate } from 'react-router-dom';
import { useCompanyPlanContext } from 'contexts/CompanyPlanContext';
import { CustomerPlan } from 'types/company.types';

interface IInnerChainTableRowProps {
  isExpanded: boolean;
  request: PartnerRequest;
  chainId?: string;
}

const Table = styled(StyledTable)(() => ({
  borderSpacing: 0,
}));

const InnerTableRow = styled(StyledTableRow)<{ 'is-displayed': booleanish }>(
  ({ 'is-displayed': isDisplayed }) => ({
    backgroundColor: 'transparent',
    boxShadow: 'none',
    display: isDisplayed === 'true' ? 'table-row' : 'none',

    '&:hover': {
      background: 'transparent !important',
      boxShadow: 'none',
      cursor: 'default',
    },
  })
);

const InnerChainTableRow: FC<IInnerChainTableRowProps> = ({ isExpanded, chainId, request }) => {
  const { openDialog } = useDialog();
  const navigate = useNavigate();
  const { plan } = useCompanyPlanContext();
  const isPartner = plan === CustomerPlan.FREEMIUM;

  const handleClickFulfillRequestButton = () => {
    openDialog({
      type: 'REVIEW_CHAIN_REQUEST',
      props: { request },
    });
  };

  const handleNavigateToChain = () => {
    navigate(`/component-chains/${chainId}/editor`);
  };

  return (
    <InnerTableRow data-cy="request-table-item" hover is-displayed={Booleanish(isExpanded)}>
      <TableCell
        style={{
          paddingBottom: 0,
          paddingTop: 0,
        }}
        colSpan={6}
      >
        <Collapse in={isExpanded} timeout="auto" unmountOnExit>
          <Box
            style={{
              margin: '0 -12px 0 8px',
            }}
          >
            <Table size="small" data-cy="requested-chain-inner-table">
              <TableBody>
                <StyledTableRow disabled={Booleanish(true)}>
                  <TableCell>
                    <Box display="flex" justifyContent="space-between" position="relative" mr={1}>
                      <Box display="flex" width="100%" justifyContent="space-between">
                        <ItemProgressTitle
                          title={request?.chain?.title || ''}
                          imageUrl={request?.chain?.image?.url}
                          taskCompleted={request?.chain?.chainTasksDetails.completed || 0}
                          taskTotal={request?.chain?.chainTasksDetails.total || 0}
                          backupIcon={
                            <Icon name="component-chain-image" size="xx-large" color="blue-ice" />
                          }
                        />
                        {!isPartner && (
                          <>
                            {request.requestStatus === PartnerRequestStatus.PROVIDED_COMPLETED ? (
                              <ThemeButton
                                variant="contained"
                                startIcon={<Icon name="chain-request-manage" />}
                                onClick={handleNavigateToChain}
                              >
                                Go to chain
                              </ThemeButton>
                            ) : (
                              <ThemeButton
                                variant="contained"
                                startIcon={<Icon name="chain-request-manage" />}
                                onClick={handleClickFulfillRequestButton}
                              >
                                Review and approve
                              </ThemeButton>
                            )}
                          </>
                        )}
                      </Box>
                    </Box>
                  </TableCell>
                </StyledTableRow>
              </TableBody>
            </Table>
          </Box>

          <Box mt={0.5} ml={1.5} display="flex" alignItems="center">
            <Box mr={1}>
              <Icon name="info" size="medium" />
            </Box>
            <ThemeTypography variant="BODY_LARGE">
              {isPartner ? (
                <>
                  Mapped chains can also be managed in your <b>Components</b>.
                </>
              ) : (
                <>
                  Mapped partner chains can also be managed in your <b>Components</b>, or in
                  <b> Partners </b>
                  under “Partner components”.
                </>
              )}
            </ThemeTypography>
          </Box>
        </Collapse>
      </TableCell>
    </InnerTableRow>
  );
};

export default InnerChainTableRow;
