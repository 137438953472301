import { useMutation, useQuery } from '@apollo/client';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Pencil } from '@styled-icons/bootstrap/Pencil';
import { Trash } from '@styled-icons/bootstrap/Trash';
import { useMessages } from 'components/hooks';
import { useSelectable } from 'components/MediaLibrary/hooks';
import { IMAGE_PLACEHOLDER_URL } from 'constants';
import { DELETE_IMAGE, UPDATE_IMAGE } from 'graphql/mutations';
import { GET_IMAGES, GET_IMAGE_CLIENT } from 'graphql/queries';
import update from 'immutability-helper';
import findIndex from 'lodash/findIndex';
import PropTypes from 'prop-types';
import React, { Fragment, useState } from 'react';
import Imgix from 'react-imgix';
import Moment from 'react-moment';
import LibraryItemEditForm from './LibraryItemEditForm';
import { ThemeTypography, ThemeButton, AlertDialog } from 'designSystem';

const Image = styled(({ className, ...rest }) => (
  <Imgix
    className={className}
    imgixParams={{
      q: 90,
    }}
    sizes="30vw"
    {...rest}
  />
))(() => ({
  maxWidth: '100%',
  maxHeight: '100%',
  objectFit: 'cover',
}));

const ImageContainer = styled('div')(({ theme }) => ({
  width: '80%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  maxHeight: 200,
  overflow: 'hidden',
  marginBottom: theme.spacing(2),
  background: theme.custom.colors.offWhite,
  borderRadius: 15,
}));

const Actions = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(3),
  display: 'flex',

  '& button': {
    marginRight: theme.spacing(1),
  },
}));

const ImageDetail = ({ image: { id: imageId } }) => {
  const { onUnselect } = useSelectable();
  const { data: { getImageClient = {} } = {} } = useQuery(GET_IMAGE_CLIENT, {
    variables: {
      id: imageId,
    },
  });

  const { url, fileName, attribution, createdTimestamp, width, height } = getImageClient;
  const { setErrorMessage, setSuccessMessage } = useMessages();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [editing, setEditing] = useState(false);
  const [updateImage] = useMutation(UPDATE_IMAGE, {
    onCompleted: () => {
      setEditing(false);
      setSuccessMessage('Image successfully updated ');
    },
    onError: () => setErrorMessage('Something went wrong updating your image'),
  });
  const [deleteImageMutation] = useMutation(DELETE_IMAGE, {
    onCompleted: () => {
      setSuccessMessage(`${fileName} was deleted.`);
      onUnselect();
    },
    onError: () => {
      setErrorMessage('Something went wrong deleting your image');
    },
    update: cache => {
      const {
        getImages: { edges: images, pageInfo },
      } = cache.readQuery({
        query: GET_IMAGES,
      });
      const removedIndex = findIndex(images, ['node.id', imageId]);

      cache.writeQuery({
        query: GET_IMAGES,
        data: {
          getImages: {
            edges: update(images, {
              $splice: [[removedIndex, 1]],
            }),
            pageInfo,
          },
        },
      });
    },
  });

  const deleteImage = async () => {
    await deleteImageMutation({
      variables: {
        id: imageId,
      },
    });
  };

  const saveDetails = values => {
    return updateImage({
      variables: {
        id: imageId,
        input: values,
      },
    });
  };

  return (
    <Fragment>
      <Box mb={1}>
        <ThemeTypography variant="TITLE_SMALL">Image Details</ThemeTypography>
      </Box>
      <ImageContainer>
        <Image src={url || IMAGE_PLACEHOLDER_URL} />
      </ImageContainer>
      {!editing && (
        <Fragment>
          {fileName && (
            <ThemeTypography autoOverflow variant="BODY_LARGE_BOLD">
              {fileName}
            </ThemeTypography>
          )}
          <ThemeTypography
            variant="BODY_LARGE_BOLD"
            color="GRAY"
          >{`${width}x${height}px`}</ThemeTypography>

          <ThemeTypography variant="BODY_LARGE" color="GRAY">
            <Moment format="MMMM D, YYYY">{createdTimestamp}</Moment>
          </ThemeTypography>
          {attribution && (
            <ThemeTypography autoOverflow variant="BODY_LARGE" color="GRAY">
              {attribution}
            </ThemeTypography>
          )}
          <Actions>
            <ThemeButton
              onClick={() => setEditing(true)}
              color="BLUE_ICE"
              size="medium"
              startIcon={<Pencil size={14} />}
            >
              Edit
            </ThemeButton>
            <ThemeButton
              onClick={() => setDeleteDialogOpen(true)}
              color="BLUE_ICE"
              size="medium"
              startIcon={<Trash size={14} />}
              data-cy="image-delete-button"
            >
              Delete
            </ThemeButton>
          </Actions>
        </Fragment>
      )}
      {editing && (
        <LibraryItemEditForm
          fileName={fileName || ''}
          attribution={attribution || ''}
          onSubmit={saveDetails}
          onCancel={() => setEditing(false)}
        />
      )}

      <AlertDialog
        onClose={() => setDeleteDialogOpen(false)}
        title="Delete Image"
        open={deleteDialogOpen}
        text="Deleting this image can remove it from already published products. Are you sure you want to delete this image?"
        onSubmit={deleteImage}
        onCancel={() => setDeleteDialogOpen(false)}
        submitText="Delete"
        data-cy="alert-dialog"
      />
    </Fragment>
  );
};

ImageDetail.propTypes = {
  image: PropTypes.shape({
    url: PropTypes.string,
    fileName: PropTypes.string,
    dimensions: PropTypes.string,
    attribution: PropTypes.string,
    createdTimestamp: PropTypes.string,
  }).isRequired,
};

export default ImageDetail;
