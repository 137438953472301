import { styled } from '@mui/material/styles';
import ThemeTypography from 'designSystem/Primitives/Typography/ThemeTypography';
import React from 'react';
import { ImpactClaim } from 'types/impactClaim.types';

const ClaimWrapper = styled('div')(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  background: theme.custom.themeColors.white,
  border: `1px solid ${theme.custom.themeColors.primary[5]}`,
  borderRadius: 25,
  padding: theme.spacing(1, 2),
  height: 46,
}));

const IconContainer = styled('div')(({ theme }) => ({
  width: 28,
  height: 28,
  marginRight: theme.spacing(1),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  '& img': {
    maxWidth: '100%',
  },
}));

interface Props {
  impactClaim: ImpactClaim;
}

const ImpactClaimBadge: React.FC<Props> = ({ impactClaim }): JSX.Element => {
  const iconUrl: string | undefined = impactClaim.impactCatalogItem.iconUrl;

  return (
    <ClaimWrapper>
      {iconUrl && (
        <IconContainer>
          <img src={iconUrl} alt="impact claim icon" />
        </IconContainer>
      )}
      <ThemeTypography variant="BODY_LARGE">{impactClaim.impactCatalogItem.title}</ThemeTypography>
    </ClaimWrapper>
  );
};

export default ImpactClaimBadge;
