import { Box, Grid } from '@mui/material';
import { ExclamationTriangle } from '@styled-icons/bootstrap/ExclamationTriangle';
import { Trash } from '@styled-icons/bootstrap/Trash';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { ActionButton, Container, ErrorMessage, FileNameText } from './styles';

const UploadErrorItem = ({ fileName, error, onDelete }) => {
  useEffect(() => {
    if (onDelete) {
      // after 5 seconds the item should disappear
      setTimeout(() => onDelete(), 5000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Container error>
          <ExclamationTriangle size={20} />
          <Box display="flex" flexGrow={1} flexDirection="column">
            <FileNameText>{fileName}</FileNameText>
            <ErrorMessage error>{error}</ErrorMessage>
          </Box>
          {onDelete && (
            <ActionButton variant="contained" onClick={onDelete}>
              <Trash size={14} />
            </ActionButton>
          )}
        </Container>
      </Grid>
    </Grid>
  );
};

UploadErrorItem.propTypes = {
  fileName: PropTypes.string.isRequired,
  error: PropTypes.string.isRequired,
  onDelete: PropTypes.func,
};

export default UploadErrorItem;
