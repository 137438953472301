import { useApolloClient } from '@apollo/client';
import { Box, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Plus } from '@styled-icons/bootstrap/Plus';
import { Dropzone, UploadListItem } from 'components/FileUpload';
import { useLogEvent, useUploadState } from 'components/hooks';
import { DialogTitle } from 'designSystem';
import { DOCUMENT_FRAGMENT } from 'graphql/fragments';
import filter from 'lodash/filter';
import uniqBy from 'lodash/uniqBy';
import React, { FC, Fragment, useEffect, useMemo, useState } from 'react';
import { IDocument } from 'types/document.types';
import EditContainer from './DocumentDialog/EditContainer';
import DocumentSelector from './DocumentSelector';
import DocumentUploadItem from './DocumentUploadItem';
import SubTitleUploadItem from './SubTitleUploadItem';
import { useDocumentUploadActions } from './hooks';

const SelectDocumentsButton = styled(Button)(() => ({
  position: 'absolute',
  right: 0,
  top: 0,
}));

interface IDocumentUploadProps {
  documents: IDocument[];
  deletedDocuments: string[];
  title: string;
  displaySelector?: boolean;
  multipleDocuments?: boolean;
  isRequestDocument?: boolean;
  handleDelete: (id: string) => void;
  handleDocumentsChange: (documents: IDocument[]) => void;
}

const DocumentUpload: FC<IDocumentUploadProps> = ({
  documents,
  handleDocumentsChange,
  deletedDocuments,
  handleDelete,
  title,
  displaySelector,
  multipleDocuments = true,
  isRequestDocument = false,
}) => {
  const { fileIDs, fileMap, createdRecords } = useUploadState();
  const { getSelectedDocuments, onDrop, maxSize } = useDocumentUploadActions();
  const client = useApolloClient();
  const [editableDocument, setEditableDocument] = useState<IDocument>();
  const [openSelector, setOpenSelector] = useState<boolean>(false);
  const { logEvent } = useLogEvent();
  const handleEdit = (id: string) => {
    const document = documents.find(document => document.id === id);
    setEditableDocument(document);
  };

  const displayedFileIds = useMemo(() => {
    return filter(fileIDs, id => {
      const { recordId } = fileMap[id];
      return !deletedDocuments.includes(recordId);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileIDs, deletedDocuments]);

  const displayedExistingDocuments = useMemo(() => {
    return documents.filter(({ id }) => {
      return (
        !createdRecords.find(createdRecord => createdRecord.id === id) &&
        !deletedDocuments.includes(id)
      );
    });
  }, [documents, createdRecords, deletedDocuments]);

  useEffect(() => {
    const allDocuments = uniqBy([...createdRecords, ...documents], 'id')
      .map(({ id }) =>
        client.readFragment({
          fragment: DOCUMENT_FRAGMENT,
          id: `Document:${id}`,
        })
      )
      .filter(doc => doc);

    if (!allDocuments?.length) return;

    const filteredDocuments = filter(allDocuments, ({ id }) => !deletedDocuments.includes(id));

    handleDocumentsChange(filteredDocuments);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayedFileIds, createdRecords, editableDocument]);

  const handleSelectDocuments = (selectedIds: string[]) => {
    const markedDocuments = getSelectedDocuments(selectedIds);
    handleDocumentsChange(markedDocuments);
  };

  return (
    <Box position="relative">
      <Box mb={2}>
        <DialogTitle>{title}</DialogTitle>
      </Box>
      {displaySelector && (
        <SelectDocumentsButton
          onClick={() => {
            logEvent('OPEN_SELECT_DOCUMENT');
            setOpenSelector(true);
          }}
          color="inherit"
          variant="contained"
          size="small"
          startIcon={<Plus size={14} />}
          data-cy="document-selector-button"
          disabled={!multipleDocuments && documents.length >= 1}
        >
          Select from document list
        </SelectDocumentsButton>
      )}
      <Dropzone
        onDrop={onDrop}
        maxFileMb={maxSize}
        multiple={multipleDocuments}
        disabled={!multipleDocuments && documents.length >= 1}
      />
      {displayedFileIds.map(fileID => {
        const { recordId } = fileMap[fileID];

        return (
          <Fragment key={fileID}>
            {editableDocument && editableDocument?.id === recordId ? (
              <Box mb={1}>
                <EditContainer
                  isRequestDocument={isRequestDocument}
                  documentId={recordId}
                  hideCancelButton
                  onDelete={() => {
                    handleDelete(editableDocument.id);
                    setEditableDocument(undefined);
                  }}
                  onClose={() => {
                    setEditableDocument(undefined);
                    setOpenSelector(false);
                  }}
                />
              </Box>
            ) : (
              <DocumentUploadItem
                id={fileID}
                onDelete={handleDelete}
                onEdit={handleEdit}
                openOnSuccess
                editableDocument={editableDocument}
              />
            )}
          </Fragment>
        );
      })}
      {displayedExistingDocuments.map(({ title, id, expiryDate, category }) => (
        <Fragment key={id}>
          {editableDocument && editableDocument?.id === id ? (
            <Box mb={1}>
              <EditContainer
                isRequestDocument={isRequestDocument}
                onClose={() => setEditableDocument(undefined)}
                documentId={id}
                onDelete={() => {
                  handleDelete(editableDocument.id);
                  setEditableDocument(undefined);
                }}
              />
            </Box>
          ) : (
            <UploadListItem
              fileName={title}
              success
              onDelete={() => handleDelete(id)}
              onEdit={() => handleEdit(id)}
              onOpen={undefined}
              subTitle={<SubTitleUploadItem expiryDate={expiryDate} category={category} />}
            />
          )}
        </Fragment>
      ))}
      {openSelector && (
        <DocumentSelector
          open={openSelector}
          onClose={() => setOpenSelector(false)}
          selectedDocuments={documents}
          onSelect={handleSelectDocuments}
          multiselect={multipleDocuments}
        />
      )}
    </Box>
  );
};

export default DocumentUpload;
