import React, { useEffect, useState } from 'react';
import ReactCrop from 'react-image-crop';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';

const StyledBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '650px',
}));

const CroppingField = ({ setImageSize, imageSrc, imageSize, ...props }) => {
  const {
    form: {
      setValues,
      values: { x, y, width, height },
    },
  } = props;
  const [crop, setCrop] = useState(null);

  useEffect(() => {
    if (width && height && imageSize) {
      setCrop({
        unit: '%',
        x: (x / imageSize.width) * 100,
        y: (y / imageSize.height) * 100,
        width: (width / imageSize.width) * 100,
        height: (height / imageSize.height) * 100,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width, height, imageSize]);

  const onCropChange = (_, crop) => {
    setCrop(crop);
  };

  const onImageLoaded = image => {
    const { naturalHeight, naturalWidth } = image;
    setImageSize({
      width: naturalWidth,
      height: naturalHeight,
    });

    if (!width && !height) {
      setValues({
        x: 0,
        y: 0,
        width: naturalWidth,
        height: naturalHeight,
      });
    }
  };

  const onComplete = (_, crop) => {
    if (crop.width === 0 && crop.height === 0) return;

    setCrop(crop);
    setValues({
      x: Math.round((crop.x / 100) * imageSize.width),
      y: Math.round((crop.y / 100) * imageSize.height),
      width: Math.round((crop.width / 100) * imageSize.width),
      height: Math.round((crop.height / 100) * imageSize.height),
    });
  };

  return (
    <StyledBox>
      <ReactCrop
        imageStyle={{
          maxWidth: '900px',
          maxHeight: '570px',
          width: 'auto',
          height: 'auto',
        }}
        src={imageSrc}
        ruleOfThirds
        crop={crop}
        onChange={onCropChange}
        onComplete={onComplete}
        onImageLoaded={onImageLoaded}
      />
    </StyledBox>
  );
};

CroppingField.propTypes = {
  setImageSize: PropTypes.func.isRequired,
  imageSize: PropTypes.object,
  imageSrc: PropTypes.string.isRequired,
};

export default CroppingField;
