import { useQuery } from '@apollo/client';
import { Box, Grid, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { AnalyticsIcon, FeedbackIcon, Overview } from 'assets/img/menu';
import { Loader } from 'components/Forms';
import { NextStepWebshop } from 'components/Home';
import { ProductAnalyticsSmall } from 'components/Product/Overview/Analytics';
import { ShareSummaryCard } from 'components/Product/Overview/Cards';
import { ProductStatusCard } from 'components/Product/Overview/ProductStatus';
import { ErrorState, PageContainer, PageTitle } from 'components/Structure';
import HasAccess from 'components/Structure/HasAccess';
import { Tabs } from 'designSystem';
import { ITab } from 'designSystem/Primitives/Tabs/Tabs';
import { GET_PRODUCT_AND_ANALYTICS_EXIST } from 'graphql/queries';
import React from 'react';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { CustomerPlan } from 'types/company.types';
import { RouteParamsWithId } from 'types/router.types';

export enum ProductTabKey {
  OVERVIEW = 'overview',
  ANALYTICS = 'analytics',
  FEEDBACK = 'feedback',
}

export const PRODUCT_TABS: ITab<ProductTabKey>[] = [
  {
    key: ProductTabKey.OVERVIEW,
    label: 'Overview',
    startIcon: <Overview />,
  },
  {
    key: ProductTabKey.ANALYTICS,
    label: 'Analytics',
    startIcon: <AnalyticsIcon />,
  },
  {
    key: ProductTabKey.FEEDBACK,
    label: 'Feedback',
    startIcon: <FeedbackIcon />,
  },
];

const ProductOverview = () => {
  const theme = useTheme();
  const mediumScreenAndSmaller = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();
  const { id: paramProductId } = useParams<RouteParamsWithId>();
  const { error, loading, data } = useQuery(GET_PRODUCT_AND_ANALYTICS_EXIST, {
    variables: { id: paramProductId },
  });

  const handleChangeTab = (tab: ProductTabKey) => {
    if (tab === ProductTabKey.OVERVIEW) {
      navigate(`/products/${paramProductId}`);
    } else {
      navigate(`/products/${paramProductId}/${tab}`);
    }
  };

  if (error) return <ErrorState />;
  if (!data && loading) return <Loader />;

  const { product, productAnalyticsExist } = data;
  const { publicUrl, defaultQrCode, title, id, slug } = product;

  return (
    <PageContainer>
      <PageTitle
        title="Product page overview"
        titleExtension={product?.title}
        goBackLabel="Product pages"
        goBackUrl="/products"
      />

      <Box mt={3} mb={2}>
        <Tabs tabs={PRODUCT_TABS} selectedTab={ProductTabKey.OVERVIEW} onChange={handleChangeTab} />
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <ProductStatusCard product={product} />
        </Grid>
        <Grid item xs={12}>
          <Grid container direction={mediumScreenAndSmaller ? 'column-reverse' : 'row'} spacing={3}>
            <Grid item xs={mediumScreenAndSmaller ? 12 : 6}>
              <Grid container spacing={3}>
                {!productAnalyticsExist && (
                  <Grid item xs={12}>
                    <NextStepWebshop actionType="LINK" alignmentActions="RIGHT" />
                  </Grid>
                )}

                {product && productAnalyticsExist && (
                  <Grid item xs={12}>
                    <ProductAnalyticsSmall product={product} />
                  </Grid>
                )}

                {/* Commented since the analytics article is not there yet, if the article is there please remove ProductAnalyticsSmall above and uncomment next section */}
                {/* <Grid item xs={12}>
                {
                  product && productAnalyticsExist ? (
                    <ProductAnalyticsSmall product={product} />
                  ) : null
                  <AnalyticsInspireCard />
                }
              </Grid> */}

                {/* Commented because the feedback article is not there yet */}
                {/* {!isDemo && (
                <Grid item xs={12}>
                  <FeedbackInspireCard />
                </Grid>
              )} */}
              </Grid>
            </Grid>
            <Grid item xs={mediumScreenAndSmaller ? 12 : 6}>
              <Grid container spacing={3}>
                {productAnalyticsExist && (
                  <Grid item xs={12}>
                    <NextStepWebshop actionType="LINK" alignmentActions="RIGHT" />
                  </Grid>
                )}
                <HasAccess
                  allowedPlans={[CustomerPlan.PAYING, CustomerPlan.INTERNAL, CustomerPlan.TRIAL]}
                >
                  <Grid item xs={12}>
                    <ShareSummaryCard
                      productId={id}
                      publicUrl={publicUrl}
                      qrCode={defaultQrCode}
                      productTitle={title}
                      slug={slug}
                    />
                  </Grid>
                </HasAccess>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </PageContainer>
  );
};

export default ProductOverview;
