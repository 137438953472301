import { styled } from '@mui/material/styles';
import React, { useEffect, useState, FC } from 'react';

interface ISlideIndicatorProps {
  slideCount: number;
  activeIndex: number;
  stepColor: 'WHITE' | 'GRAY';
  fillPrevious?: boolean;
  onClick?: (index: number) => void;
}

const IndicatorContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
}));

const Indicator = styled('div')<{
  active: boolean;
  clickable: boolean;
  stepColor: 'GRAY' | 'WHITE';
}>(({ theme, active, clickable, stepColor }) => ({
  padding: 4,
  marginRight: theme.spacing(1),
  width: 35,
  borderRadius: 5,
  background: `${
    active
      ? theme.palette.primary.main
      : stepColor === 'WHITE'
      ? '#fff'
      : theme.custom.themeColors.grayScale[20]
  }`,
  cursor: clickable ? 'pointer' : 'inherit',
}));

const SlideIndicator: FC<ISlideIndicatorProps> = ({
  slideCount,
  activeIndex,
  fillPrevious,
  stepColor = 'WHITE',
  onClick,
}) => {
  const [maxActive, setMaxActive] = useState(0);

  // Make sure the user can only go to slides it's already been
  useEffect(() => {
    if (activeIndex > maxActive) {
      setMaxActive(activeIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeIndex]);

  return (
    <IndicatorContainer>
      {Array.from(Array(slideCount).keys()).map((slide, index) => {
        const isClickable = maxActive >= index && !!onClick;
        return (
          <Indicator
            key={index}
            active={fillPrevious ? index <= activeIndex : activeIndex === index}
            stepColor={stepColor}
            clickable={isClickable}
            onClick={() => (isClickable ? onClick?.(index) : null)}
          />
        );
      })}
    </IndicatorContainer>
  );
};

export default SlideIndicator;
