import { styled } from '@mui/material/styles';

export const PaginationContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  borderRadius: 6,
  width: '100%',

  '& .MuiTypography-root ': {
    fontWeight: 400,
  },

  '& .MuiTablePagination-root': {
    borderBottom: 'none',
  },

  '& .MuiTablePagination-toolbar': {
    minHeight: 10,
  },

  '& .MuiIconButton-root': {
    padding: theme.spacing(0, 1),
  },

  '& .MuiTablePagination-displayedRows': {
    margin: 0,
  },
}));
