import React from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { ThemeTypography, CardContainer, ActionLink } from 'designSystem';

const TipCard = ({ title, content, readMoreUrl, handleShowNextCard, children }) => {
  return (
    <CardContainer title={title} size="SMALL" background="SEXAGON" flex={1}>
      <ThemeTypography variant="BODY_LARGE">{content}</ThemeTypography>
      <Box display="flex" justifyContent="space-between" mt="auto">
        {children}
        <Box display="flex" justifyContent="end" mt="auto" gap={5} pt={2}>
          {handleShowNextCard && <ActionLink onClick={handleShowNextCard}>Next tip</ActionLink>}
          <ActionLink href={readMoreUrl} target="_blank">
            Read more
          </ActionLink>
        </Box>
      </Box>
    </CardContainer>
  );
};

TipCard.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  readMoreUrl: PropTypes.string.isRequired,
  handleShowNextCard: PropTypes.func,
  children: PropTypes.node,
};

export default TipCard;
