import { TextField, styled } from '@mui/material';
import { COUNTRIES } from 'constants/countries';
import { Autocomplete } from 'formik-mui';
import find from 'lodash/find';
import React, { useMemo, useState } from 'react';

const StyledAutocomplete = styled(Autocomplete)(() => ({
  '& .MuiAutocomplete-inputRoot': {
    padding: 3,
  },
}));

/** @deprecated */
const CountrySelect = ({ ...props }) => {
  const {
    field: { name, value: initialValue },
    form: { touched, errors, setFieldValue },
  } = props;

  const initialCountry = useMemo(
    () => find(COUNTRIES, ['code', (initialValue || '').toUpperCase()]),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const [value, setValue] = useState(initialCountry);
  const [inputValue, setInputValue] = useState(initialCountry?.label || '');

  return (
    <StyledAutocomplete
      {...props}
      value={value}
      onChange={(event, newValue) => {
        if (!newValue) return;
        setValue(newValue);
        setFieldValue(name, newValue.code);
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      options={COUNTRIES}
      autoHighlight
      getOptionLabel={option => option.label}
      renderOption={(props, option) => (
        <span data-cy="country-option" {...props}>
          {option.label}
        </span>
      )}
      renderInput={params => (
        <TextField
          {...params}
          placeholder="Choose a country"
          variant="outlined"
          error={touched && !!errors[name]}
          helperText={touched[name] && errors[name]}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
            'data-cy': 'country-field',
          }}
        />
      )}
    />
  );
};

// CountrySelect.propTypes =
//   fieldProps: PropTypes.object.isRequired,
// };

export default CountrySelect;
