import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React from 'react';

const Highlight = styled('span')(({ theme }) => ({
  color: theme.palette.primary.main,
}));

const StyledHeadline = styled(Typography)(({ theme }) => ({
  color: '#fff',
  fontWeight: 700,
  fontFamily: theme.custom.fontFamilyTitle,
}));

const AuthHeadline = ({ text, highlighted }) => {
  return (
    <StyledHeadline variant="h4">
      {text} <Highlight>{highlighted}</Highlight>
    </StyledHeadline>
  );
};

AuthHeadline.propTypes = {
  text: PropTypes.string.isRequired,
  highlighted: PropTypes.string.isRequired,
};

export default AuthHeadline;
