export const getYearsUntilToday = () => {
  const fromDate = new Date('2015');
  const toDate = new Date(Date.now());
  const years = [];

  for (let year = fromDate.getFullYear(); year <= toDate.getFullYear(); year++) {
    years.push(year);
  }
  return years.reverse();
};
