import React, { useState, FC } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { ArrowLeftShort } from '@styled-icons/bootstrap/ArrowLeftShort';
import { ArrowRightShort } from '@styled-icons/bootstrap/ArrowRightShort';
import { BoxArrowUpRight as ExternalLink } from '@styled-icons/bootstrap/BoxArrowUpRight';
import { styled } from '@mui/material/styles';
import { IconButton } from '@mui/material';
import ThemeButton from 'designSystem/Buttons/ThemeButton/ThemeButton';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import { booleanish, Booleanish } from 'types/booleanish.types';

// Required because PDFWorker can not be find otherwise
// https://github.com/wojtekmaj/react-pdf/issues/136#issuecomment-940584082
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const Container = styled('div')<{ 'transparent-background': booleanish }>(
  ({ theme, 'transparent-background': transparentBackground }) => ({
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: transparentBackground === 'true' ? 'transparent' : theme.custom.colors.almostBlack,
    padding: '40px 20px 60px',
    height: '100%',

    '& .react-pdf__Page__canvas': {
      width: '100% !important',
      height: 'auto !important',
      maxWidth: 450,
    },

    '& .textLayer': {
      width: 'unset !important',
      height: 'unset !important',
    },
  })
);

const OpenDocumentContainer = styled('a')(({ theme }) => ({
  position: 'absolute',
  top: 10,
  right: 10,
  zIndex: 2,
  boxShadow: theme.custom.shadows[1],
}));

const DocumentNavigation = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: 300,
  color: '#fff',
  position: 'absolute',
  bottom: 8,
  fontSize: 14,
}));

interface IDocumentViewer {
  file: string;
  transparentBackground?: boolean;
  displayLink?: boolean;
}

const DocumentViewer: FC<IDocumentViewer> = ({ file, transparentBackground, displayLink }) => {
  const [numPages, setNumPages] = useState<number>(1);
  const [pageNumber, setPageNumber] = useState<number>(1);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
    setPageNumber(1);
  };

  const nextPage = () => {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
    }
  };

  const prevPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  const handleOpenDocument = () => {
    if (file) {
      window.open(file, '_blank');
    }
  };

  return (
    <Container transparent-background={Booleanish(transparentBackground)}>
      {displayLink && (
        <OpenDocumentContainer>
          <ThemeButton
            variant="contained"
            color="WHITE"
            size="large"
            onClick={handleOpenDocument}
            startIcon={<ExternalLink size={12} />}
          >
            Open in new tab
          </ThemeButton>
        </OpenDocumentContainer>
      )}
      {numPages > 1 && (
        <DocumentNavigation>
          <IconButton disabled={pageNumber <= 1} onClick={prevPage}>
            <ArrowLeftShort size={30} color={pageNumber <= 1 ? 'rgba(255,255,255,0.3)' : '#fff'} />
          </IconButton>
          <div>
            Page {pageNumber} of {numPages}
          </div>
          <IconButton disabled={pageNumber >= numPages} onClick={nextPage}>
            <ArrowRightShort
              size={30}
              color={pageNumber >= numPages ? 'rgba(255,255,255,0.3)' : '#fff'}
            />
          </IconButton>
        </DocumentNavigation>
      )}
      <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
        <Page pageNumber={pageNumber} renderAnnotationLayer={false} />
      </Document>
    </Container>
  );
};

export default DocumentViewer;
