import { gql } from '@apollo/client';
import { CMS_CERTIFICATE_CATALOG_ITEM_FRAGMENT } from 'graphql/fragments';

// CreateCmsCertificateCatalogItemInput: { title, description }
export const CREATE_CERTIFICATE_CATALOG_ITEM = gql`
  mutation CreateCertificateCatalogItem($input: CreateCmsCertificateCatalogItemInput!) {
    createCertificateCatalogItem(input: $input) {
      item {
        ...certificateCatalogItem
      }
    }
  }
  ${CMS_CERTIFICATE_CATALOG_ITEM_FRAGMENT}
`;
