import { Box, TableCell, TableHead, TableRow, styled } from '@mui/material';
import { ChartsAxisContentProps, ChartsAxisTooltipContent } from '@mui/x-charts';

import { StyledTable, StyledTableBody } from 'components/TableBase';
import { ThemeTypography } from 'designSystem';

import React, { FC } from 'react';

const getRiskByWeight = (weight: number) => {
  if (weight <= 0.33) {
    return 'LOW';
  }
  if (weight > 0.33 && weight <= 0.66) {
    return 'MEDIUM';
  }
  return 'HIGH';
};

const RISK_COLOR: Record<'LOW' | 'MEDIUM' | 'HIGH', string> = {
  LOW: '#2DB875',
  MEDIUM: 'orange',
  HIGH: '#FF7F7F',
};

const Table = styled(StyledTable)(({ theme }) => ({
  padding: theme.spacing(1, 2),

  '& .MuiTableHead-root .MuiTableCell-root': {
    borderBottom: 'none',
    padding: 0,
    paddingRight: theme.spacing(1),
  },
  '& .MuiTableBody-root .MuiTableCell-root': {
    borderBottom: 'none',
    padding: 0,
    paddingRight: theme.spacing(1),
  },
}));

const TooltipContainer = styled(Box)(({ theme }) => ({
  background: theme.custom.themeColors.white,
  borderRadius: 6,
  border: `1px solid ${theme.custom.themeColors.grayScale[20]}`,
}));

const Rectangle = styled(Box)(({ theme }) => ({
  width: 12,
  height: 12,
}));

const Tooltip: FC<ChartsAxisContentProps> = ({ axisValue: axisQuestions, ...props }) => {
  return (
    <ChartsAxisTooltipContent
      {...props}
      open
      content={({ dataIndex }) => {
        if (dataIndex === undefined || dataIndex === null || !axisQuestions[dataIndex]) {
          return null;
        }

        const options = axisQuestions[dataIndex].options as {
          optionId: string;
          text: string;
          weight: number;
          answerPercentage: number;
        }[];

        return (
          <TooltipContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <ThemeTypography variant="BODY_MEDIUM_BOLD" color="GRAY_80">
                      Risk
                    </ThemeTypography>
                  </TableCell>
                  <TableCell>
                    <ThemeTypography variant="BODY_MEDIUM_BOLD" color="GRAY_80">
                      Answer
                    </ThemeTypography>
                  </TableCell>
                  <TableCell>
                    <ThemeTypography variant="BODY_MEDIUM_BOLD" color="GRAY_80">
                      Responses
                    </ThemeTypography>
                  </TableCell>
                </TableRow>
              </TableHead>

              <StyledTableBody>
                {options.map(({ optionId, text, weight, answerPercentage }) => (
                  <TableRow key={optionId}>
                    <TableCell>
                      <Rectangle bgcolor={RISK_COLOR[getRiskByWeight(weight)]} />
                    </TableCell>
                    <TableCell>
                      <ThemeTypography variant="BODY_MEDIUM" autoOverflow maxWidth={200}>
                        {text}
                      </ThemeTypography>
                    </TableCell>
                    <TableCell>
                      <ThemeTypography variant="BODY_MEDIUM" autoOverflow maxWidth={200}>
                        {answerPercentage}%
                      </ThemeTypography>
                    </TableCell>
                  </TableRow>
                ))}
              </StyledTableBody>
            </Table>
          </TooltipContainer>
        );
      }}
    />
  );
};

export default Tooltip;
