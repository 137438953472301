import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, BoxProps } from '@mui/material';
import Locations from 'assets/img/cards/locations.png';
import Trust from 'assets/img/cards/trust.png';
import Proof from 'assets/img/cards/proof.png';
import Rocket from 'assets/img/cards/rocket.png';
import Idea from 'assets/img/cards/idea.png';
import Certificate from 'assets/img/cards/certificate.png';
import Webshop from 'assets/img/cards/webshop.png';
import Media from 'assets/img/cards/media-items.png';
import EmptySearch from 'assets/img/cards/empty-search.png';
import Seedtrace from 'assets/img/cards/seedtrace.png';
import MapLayers from 'assets/img/cards/map-layers.png';
import ChainMapping from 'assets/img/cards/chain-mapping.png';
import Compliance from 'assets/img/cards/compliance.png';
import { IllustrationTypes } from 'types/enums';

const StyledImg = styled('img')(() => ({
  display: 'block',
}));

interface Props {
  image: IllustrationTypes;
  width?: number;
  height?: number;
}

const CardIllustration: React.FC<Props & BoxProps> = ({
  image,
  width = '100%',
  height,
  ...props
}) => {
  return (
    <Box position="relative" {...props}>
      <StyledImg alt={`card-${image}`} src={getImage(image)} width={width} height={height} />
    </Box>
  );
};

export default CardIllustration;

const getImage = (imageName: IllustrationTypes) => {
  return {
    LOCATIONS: Locations,
    TRUST: Trust,
    PROOF: Proof,
    ROCKET: Rocket,
    IDEA: Idea,
    CERTIFICATE: Certificate,
    WEBSHOP: Webshop,
    MEDIA: Media,
    EMPTY_SEARCH: EmptySearch,
    SEEDTRACE: Seedtrace,
    MAP_LAYERS: MapLayers,
    CHAIN_MAPPING: ChainMapping,
    COMPLIANCE: Compliance,
  }[imageName];
};
