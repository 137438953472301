import { Box, Grid, styled } from '@mui/material';
import { Link } from 'react-router-dom';
import { themeColors } from 'styles/themeColors';

export const ShadowedFieldBlock = styled(Grid)<{ 'grid-gap'?: number }>(
  ({ theme, 'grid-gap': gridGap = 0 }) => ({
    background: '#fff',
    padding: theme.spacing(2),
    borderRadius: 6,
    boxShadow: theme.custom.shadows[5],
    gridGap: theme.spacing(gridGap),
  })
);

export const StepIndexNumber = styled('div')<{ size?: 'small' | 'medium' }>(
  ({ theme, size = 'medium' }) => ({
    color: '#fff',
    background: theme.palette.secondary.main,
    borderRadius: '50%',
    width: size === 'medium' ? theme.spacing(3) : theme.spacing(2),
    minWidth: size === 'medium' ? theme.spacing(3) : theme.spacing(2),
    height: size === 'medium' ? theme.spacing(3) : theme.spacing(2),
    fontSize: size === 'medium' ? 11 : 8,
    lineHeight: size === 'medium' ? 11 : 8,
    boxShadow: theme.custom.shadows[1],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 700,
  })
);

export const DialogInner = styled(Grid)<{ 'min-height'?: number | string }>(
  ({ 'min-height': minHeight }) => ({
    minHeight: minHeight === undefined ? '65vh' : minHeight,
  })
);

export const OpenMapAction = styled('div')(({ theme }) => ({
  float: 'right',
  fontSize: 12,
  color: theme.custom.colors.textLight,
  '& .MuiLink-root': {
    color: theme.custom.colors.textLight,
  },
}));

export const BlockContainer = styled(Grid)<{
  'z-index'?: number;
  height?: number | string;
  bottomSpacing?: number;
  overflowY?: 'auto';
}>(({ theme, 'z-index': zIndex = 1, height, bottomSpacing = 0, overflowY }) => ({
  height: height ? height : 'auto',
  maxHeight: `calc(100% - ${bottomSpacing}px)`,
  overflowY: overflowY,
  overflowX: 'hidden',
  position: 'relative',
  zIndex: zIndex,
  padding: theme.spacing(2, 4),
  gridGap: theme.spacing(3),
  display: 'flex',
  alignContent: 'baseline',
}));

export const BlockBoxContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2, 4),
  '& > div:not(:last-child)': {
    marginBottom: theme.spacing(3),
  },
  alignContent: 'baseline',
}));

export const ScrollBlockContainer = styled(Box)<{
  height?: number | string;
  bottomSpacing?: number;
}>(({ theme, height, bottomSpacing = 0 }) => ({
  height: height ? height : 'auto',
  maxHeight: `calc(100% - ${bottomSpacing}px)`,
  overflow: 'auto',
  position: 'relative',
  '& > div:not(:last-child)': {
    marginBottom: theme.spacing(3),
  },
}));

export const BottomContainer = styled(Box)(({ theme }) => ({
  zIndex: 2,
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const CommunicationInfoContainer = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  border: `1px dashed ${themeColors.grayScale[30]}`,
  justifyContent: 'center',
  padding: theme.spacing(2),
  borderRadius: 6,
  maxHeight: 50,
}));

export const FormContainerLeft = styled(Grid)(({ theme }) => ({
  position: 'relative',
  overflow: 'auto',
  '&:before': {
    content: '""',
    boxShadow: theme.custom.shadows[5],
    background: themeColors.white,
    position: 'absolute',
    top: -100,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 1,
  },
}));

export const FormContainerRight = styled(Grid)(({ theme }) => ({
  position: 'relative',
  zIndex: 0,
  '&:before': {
    content: '""',
    background: theme.custom.colors.offWhite,
    position: 'absolute',
    top: -100,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 0,
  },
}));

export const ButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  position: 'absolute',
  width: '100%',
  bottom: 0,
  justifyContent: 'space-between',
  background: '#fff',
  zIndex: 0,
  padding: theme.spacing(4),
  boxShadow: theme.custom.shadows[5],
}));

export const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.custom.colors.seedtraceOrange,
  textDecoration: 'underline',
  '&:hover': {
    textDecoration: 'none',
  },
}));
