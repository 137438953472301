import {
  AccordionDetails,
  AccordionSlots,
  AccordionSummary,
  Box,
  Collapse,
  Menu,
  styled,
} from '@mui/material';
import { useEudrComplianceGuide } from 'components/ComplianceGuide/EudrComplianceGuideContext';
import EudrComplianceGuideSubSection from 'components/ComplianceGuide/EudrComplianceGuideSubSection';
import { FlexBox, PageSubTitle } from 'components/Structure';
import { ThemeTypography } from 'designSystem';
import { Accordion } from 'designSystem/DataDisplay/Accordion/Accordion';
import HorizontalScrollContainer from 'designSystem/Layout/HorizontalScrollContainer/HorizontalScrollContainer';
import React, { FC, useEffect, useRef, useState } from 'react';
import { IBasePartnerCompany } from 'types/partner.types';
import PartnerCard from '../../PartnerCard';

const SectionContainer = styled(Box)(({ theme }) => ({
  borderRadius: 6,
  border: `1px solid ${theme.custom.colors.lightBorderColor}`,
  background: theme.custom.themeColors.white,
  padding: theme.spacing(2),
  cursor: 'pointer',
}));

const Pill = styled('div')(({ theme }) => ({
  color: theme.palette.secondary.main,
  background: theme.custom.themeColors.grayScale[20],
  borderRadius: '50%',
  minWidth: theme.spacing(3.5),
  height: theme.spacing(3.5),
  fontSize: 12,
  lineHeight: 11,
  boxShadow: theme.custom.shadows[1],
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontWeight: 700,
  marginRight: theme.spacing(2),
}));

const StyledTypography = styled(ThemeTypography)(() => ({
  fontSize: '14px',
  fontWeight: 300,
  lineHeight: '16.8px',
  letterSpacing: '0.1em',
  textAlign: 'left',
  textTransform: 'uppercase',
}));

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  background: 'transparent',
  boxShadow: 'none',

  '&.MuiAccordion-root': {
    margin: 0,
  },

  '&::before': {
    display: 'none',
  },
}));

const QuestionContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  background: theme.custom.themeColors.grayScale[5],
  borderRadius: 6,
}));

const PreviewPartnerAssessment: FC = () => {
  const menuRef1 = useRef<HTMLDivElement>(null);
  const { formData } = useEudrComplianceGuide();

  const [selectedPartnerId, setSelectedPartnerId] = useState<string | null>(null);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    if (formData?.partnerSelfAssessmentIds) {
      setSelectedPartnerId(formData.partnerSelfAssessmentIds[0].id);
    }
  }, [formData]);

  return (
    <EudrComplianceGuideSubSection
      allowNextStepNavigation
      customNextStepButton={{ text: 'Send self-assessments', startIcon: 'invite' }}
    >
      <Box display="flex" flexDirection="column" gap={2}>
        <Box>
          <PageSubTitle title="Included partners" />
          <FlexBox mt={1}>
            <HorizontalScrollContainer>
              {formData?.partnerSelfAssessmentIds?.map((partner: IBasePartnerCompany) => (
                <PartnerCard
                  key={partner.id}
                  partner={{ partner, location: 'Columbia' }}
                  active={selectedPartnerId === partner.id}
                  onClick={() => setSelectedPartnerId(partner.id)}
                />
              ))}
            </HorizontalScrollContainer>
          </FlexBox>
        </Box>

        {/* Section A */}
        <SectionContainer>
          <FlexBox mb={2}>
            <Pill>A</Pill>
            <ThemeTypography variant="TITLE_SMALL" color="GRAY_60">
              Company information
            </ThemeTypography>
          </FlexBox>

          <Box display="flex" flexDirection="column" gap={2}>
            <Accordion>
              <AccordionSummary>
                <StyledTypography variant="BODY_LARGE">Company role</StyledTypography>
              </AccordionSummary>
              <AccordionDetails>
                <ul>
                  <li>Full name</li>
                  <li>Email</li>
                  <li>Department or job title</li>
                </ul>
              </AccordionDetails>
            </Accordion>
            <StyledAccordion
              slots={{ transition: Collapse as AccordionSlots['transition'] }}
              slotProps={{ transition: { timeout: 100 } }}
            >
              <AccordionSummary>
                <StyledTypography variant="BODY_LARGE">Company details</StyledTypography>
              </AccordionSummary>
              <AccordionDetails>
                <ul>
                  <li>Company name</li>
                  <li>Street address</li>
                  <li>Postal code</li>
                  <li>Country</li>
                  <li>Description of operations</li>
                </ul>
              </AccordionDetails>
            </StyledAccordion>
            <StyledAccordion
              slots={{ transition: Collapse as AccordionSlots['transition'] }}
              slotProps={{ transition: { timeout: 100 } }}
            >
              <AccordionSummary>
                <StyledTypography variant="BODY_LARGE">Company ownership</StyledTypography>
              </AccordionSummary>
              <AccordionDetails>
                <ul>
                  <li>Ownership status</li>
                  <li>Owner </li>
                  <li>General Manager / Managing board</li>
                </ul>
              </AccordionDetails>
            </StyledAccordion>
          </Box>
        </SectionContainer>

        {/* Section B */}
        <SectionContainer>
          <FlexBox mb={2}>
            <Pill>B</Pill>
            <ThemeTypography variant="TITLE_SMALL" color="GRAY_60">
              General laws & regulations
            </ThemeTypography>
          </FlexBox>
          <Box display="flex" flexDirection="column" gap={2}>
            <QuestionContainer ref={menuRef1} onClick={() => setMenuOpen(true)}>
              <ThemeTypography variant="BODY_LARGE_BOLD">
                Are you and your business partners (producers and service providers) aware of the
                regional and national laws and regulations applicable to your company?
              </ThemeTypography>
            </QuestionContainer>
            <Menu
              open={menuOpen}
              anchorEl={menuRef1.current}
              anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
              onClose={() => setMenuOpen(false)}
            >
              <Box p={2}>
                <ThemeTypography variant="BODY_MEDIUM_BOLD" color="GRAY_80">
                  Optional answers
                </ThemeTypography>
                <Box mb={1} />
                <ul style={{ listStyleType: 'disc' }}>
                  <li>Yes, we are fully aware</li>
                  <li>We are partially aware. Some laws and regulations are known but not all</li>
                  <li>No, we are not aware of these laws and regulations</li>
                  <li>Unknown</li>
                </ul>
              </Box>
            </Menu>
            <QuestionContainer>
              <ThemeTypography variant="BODY_LARGE_BOLD">
                What measures have you and your business partners taken to ensure compliance with
                the relevant laws and regulations listed above?
              </ThemeTypography>
            </QuestionContainer>
            <QuestionContainer>
              <ThemeTypography variant="BODY_LARGE_BOLD">
                Do you have trainings or information material in place, which explain the policy
                update on the current development of changes due to EUDR of applicable laws and
                regulations for your staff and your business partners?
              </ThemeTypography>
            </QuestionContainer>
            <QuestionContainer>
              <ThemeTypography variant="BODY_LARGE_BOLD">
                Are your business partners informed about these materials and have committed to
                follow the requirements?
              </ThemeTypography>
            </QuestionContainer>
            <QuestionContainer>
              <ThemeTypography variant="BODY_LARGE_BOLD">
                How often are the trainings on EUDR updates conducted?
              </ThemeTypography>
            </QuestionContainer>
            <QuestionContainer>
              <ThemeTypography variant="BODY_LARGE_BOLD">
                How do you ensure that the information material on EUDR updates is accessible to all
                relevant staff and business partners?
              </ThemeTypography>
            </QuestionContainer>
            <QuestionContainer>
              <ThemeTypography variant="BODY_LARGE_BOLD">
                Do you have a system in place to check and ensure the compliance of your business
                partners with all applicable laws and regulations?
              </ThemeTypography>
            </QuestionContainer>
            <QuestionContainer>
              <ThemeTypography variant="BODY_LARGE_BOLD">
                Do you have evidence demonstrating the commitment of all business partners to the
                respective national laws and regulations, relevant to raw material production,
                processing, and trade?
              </ThemeTypography>
            </QuestionContainer>
          </Box>
        </SectionContainer>

        {/* Section C */}
        <SectionContainer>
          <FlexBox mb={2}>
            <Pill>C</Pill>
            <ThemeTypography variant="TITLE_SMALL" color="GRAY_60">
              Land use rights
            </ThemeTypography>
          </FlexBox>
          <Box display="flex" flexDirection="column" gap={2}>
            <QuestionContainer>
              <ThemeTypography variant="BODY_LARGE_BOLD">
                Are practices of forced eviction to acquire land for raw material production,
                processing, and handling inhibited?
              </ThemeTypography>
            </QuestionContainer>
            <QuestionContainer>
              <ThemeTypography variant="BODY_LARGE_BOLD">
                Do you have evidence and controls in place to ensure that there have been no
                incidents of forced evictions related to land acquisition for raw material
                production, processing, or handling since 2006?
              </ThemeTypography>
            </QuestionContainer>
            <QuestionContainer>
              <ThemeTypography variant="BODY_LARGE_BOLD">
                In cases of legally mandated evictions, what measures are taken to mitigate the
                negative impacts of relocation?
              </ThemeTypography>
            </QuestionContainer>
            <QuestionContainer>
              <ThemeTypography variant="BODY_LARGE_BOLD">
                Is evidence available that compensation was paid, including information on
                recipients of compensation, amount of compensation etc. as mutually agreed upon?
              </ThemeTypography>
            </QuestionContainer>
            <QuestionContainer>
              <ThemeTypography variant="BODY_LARGE_BOLD">
                Do all your producers possess legal land ownership documents or government-issued
                permits for the land they farm?
              </ThemeTypography>
            </QuestionContainer>
            <QuestionContainer>
              <ThemeTypography variant="BODY_LARGE_BOLD">
                If legal land ownership documents or government permits are not available for all
                land, is there an official certificate from the municipal authority or an equivalent
                available?
              </ThemeTypography>
            </QuestionContainer>
            <QuestionContainer>
              <ThemeTypography variant="BODY_LARGE_BOLD">
                Do you have mechanisms in place to verify and maintain evidence that demonstrates
                the presence of clear land ownership documents, land rights, or government permits
                in compliance with national and customary laws?
              </ThemeTypography>
            </QuestionContainer>
          </Box>
        </SectionContainer>

        {/* Section D */}
        <SectionContainer>
          <FlexBox mb={2}>
            <Pill>D</Pill>
            <ThemeTypography variant="TITLE_SMALL" color="GRAY_60">
              Environmental protection
            </ThemeTypography>
          </FlexBox>
          <Box display="flex" flexDirection="column" gap={2}>
            <QuestionContainer>
              <ThemeTypography variant="BODY_LARGE_BOLD">
                Do you monitor and have documentation proving that no hunting or trapping of
                protected species occurs within your operations?
              </ThemeTypography>
            </QuestionContainer>
            <QuestionContainer>
              <ThemeTypography variant="BODY_LARGE_BOLD">
                Do you monitor and have documentation in place proving that the use of pesticides
                for raw material production is limited to officially registered products in the
                country?
              </ThemeTypography>
            </QuestionContainer>
            <QuestionContainer>
              <ThemeTypography variant="BODY_LARGE_BOLD">
                Do you monitor and have documentation in place to ensure safe handling, storage,
                application, and disposal of pesticides/chemicals, including empty containers?
              </ThemeTypography>
            </QuestionContainer>
            <QuestionContainer>
              <ThemeTypography variant="BODY_LARGE_BOLD">
                Do you monitor and have documentation in place to ensure compliance with regulations
                regarding pest control methods, including restrictions on burning?
              </ThemeTypography>
            </QuestionContainer>
            <QuestionContainer>
              <ThemeTypography variant="BODY_LARGE_BOLD">
                Do you monitor and have documentation in place to show that fertilizers are applied
                according to national requirements?
              </ThemeTypography>
            </QuestionContainer>
            <QuestionContainer>
              <ThemeTypography variant="BODY_LARGE_BOLD">
                Do you monitor and have documentation in place to manage waste generated from your
                production processes to minimize environmental impact?
              </ThemeTypography>
            </QuestionContainer>
            <QuestionContainer>
              <ThemeTypography variant="BODY_LARGE_BOLD">
                Do you monitor and have documentation in place to show that hazardous wastes are
                safely disposed of to prevent contamination of water, soil, and air resources as
                well as to harm human beings and animals?
              </ThemeTypography>
            </QuestionContainer>
            <QuestionContainer>
              <ThemeTypography variant="BODY_LARGE_BOLD">
                Do you monitor and have documentation in place to manage water resources generated
                from your production processes to minimize environmental impact?
              </ThemeTypography>
            </QuestionContainer>
            <QuestionContainer>
              <ThemeTypography variant="BODY_LARGE_BOLD">
                Do you monitor and have documentation in place to show that water use is following
                applicable regulations and local legislations and does respect existing water use
                rights?
              </ThemeTypography>
            </QuestionContainer>
            <QuestionContainer>
              <ThemeTypography variant="BODY_LARGE_BOLD">
                Do you monitor and have documentation in place to show that overuse of water in
                critical catchment areas is avoided?
              </ThemeTypography>
            </QuestionContainer>
            <QuestionContainer>
              <ThemeTypography variant="BODY_LARGE_BOLD">
                Are any of your production areas located near or within protected areas as defined
                by national laws?
              </ThemeTypography>
            </QuestionContainer>
            <QuestionContainer>
              <ThemeTypography variant="BODY_LARGE_BOLD">
                Do you monitor and have documentation in place to protect biodiversity within and
                around your production areas?
              </ThemeTypography>
            </QuestionContainer>
            <QuestionContainer>
              <ThemeTypography variant="BODY_LARGE_BOLD">
                Do you conduct regular assessments of the social and environmental impacts of your
                operations?
              </ThemeTypography>
            </QuestionContainer>
            <QuestionContainer>
              <ThemeTypography variant="BODY_LARGE_BOLD">
                Do you implement sustainable agricultural practices that comply with your national
                legislation?
              </ThemeTypography>
            </QuestionContainer>
          </Box>
        </SectionContainer>

        {/* Section E */}
        <SectionContainer>
          <FlexBox mb={2}>
            <Pill>E</Pill>
            <ThemeTypography variant="TITLE_SMALL" color="GRAY_60">
              Third-party rights
            </ThemeTypography>
          </FlexBox>
          <Box display="flex" flexDirection="column" gap={2}>
            <QuestionContainer>
              <ThemeTypography variant="BODY_LARGE_BOLD">
                Have you obtained all necessary permits and agreements to ensure that your land
                ownership and usage comply with legal requirements and the rights of local
                communities?
              </ThemeTypography>
            </QuestionContainer>
            <QuestionContainer>
              <ThemeTypography variant="BODY_LARGE_BOLD">
                Are there any ongoing disputes or unresolved conflict regarding land use or
                ownership in your production areas?
              </ThemeTypography>
            </QuestionContainer>
            <QuestionContainer>
              <ThemeTypography variant="BODY_LARGE_BOLD">
                Do you have procedures in place to obtain the free, prior, and informed consent
                (FPIC) of indigenous and local communities in your production areas?
              </ThemeTypography>
            </QuestionContainer>
            <QuestionContainer>
              <ThemeTypography variant="BODY_LARGE_BOLD">
                Can you provide documentation or evidence of FPIC processes that have been
                conducted?
              </ThemeTypography>
            </QuestionContainer>
          </Box>
        </SectionContainer>

        {/* Section F */}
        <SectionContainer>
          <FlexBox mb={2}>
            <Pill>F</Pill>
            <ThemeTypography variant="TITLE_SMALL" color="GRAY_60">
              Labor Rights and Working Conditions
            </ThemeTypography>
          </FlexBox>
          <Box display="flex" flexDirection="column" gap={2}>
            <QuestionContainer>
              <ThemeTypography variant="BODY_LARGE_BOLD">
                Do you monitor and have documentation in place to show that you comply with national
                labor laws and regulations, including those protecting workers&apos; rights to fair
                working conditions, wages, and hours?
              </ThemeTypography>
            </QuestionContainer>
            <QuestionContainer>
              <ThemeTypography variant="BODY_LARGE_BOLD">
                Is it assured that the wages paid by your company for a working week (excluding
                overtime) always at least meet the legal or industry minimum standards or collective
                agreements (where applicable)?
              </ThemeTypography>
            </QuestionContainer>
            <QuestionContainer>
              <ThemeTypography variant="BODY_LARGE_BOLD">
                Do you monitor and have documentation in place to ensure that no child labor occurs
                in your operations? Child: Anyone under the age of 15, unless the minimum age for
                work or compulsory schooling is higher under local law. In this case, the higher age
                stipulated at this location applies. Child labor: Any work carried out by a child
                (see definition above) who is younger than the above age, except as provided for in
                ILO Recommendation 146.
              </ThemeTypography>
            </QuestionContainer>
            <QuestionContainer>
              <ThemeTypography variant="BODY_LARGE_BOLD">
                Do you monitor and have documentation in place to ensure that no forced or
                compulsory labor occurs in your operations? Forced and compulsory labor: Any work or
                service that was not voluntarily offered by a person and performed under threat of
                punishment or reprisals or demanded as repayment of a debt.
              </ThemeTypography>
            </QuestionContainer>
            <QuestionContainer>
              <ThemeTypography variant="BODY_LARGE_BOLD">
                Do all workers have written employment contracts? Please check whether written
                labour contracts for all workers are available and that all workers have a copy of
                their labour contract.
              </ThemeTypography>
            </QuestionContainer>
            <QuestionContainer>
              <ThemeTypography variant="BODY_LARGE_BOLD">
                Do all workers have written employment contracts that outline their rights and
                responsibilities, in accordance with national legislation? Please check whether
                written labour contracts for all workers are available and that all workers have a
                copy of their labour contract.
              </ThemeTypography>
            </QuestionContainer>
            <QuestionContainer>
              <ThemeTypography variant="BODY_LARGE_BOLD">
                Do you monitor and have documentation in place to ensure that neither your company
                nor any other party working for you that provides labor is engaged in or supports
                human trafficking? Trafficking in human beings: The recruitment, transfer,
                accommodation or reception of people by means of threats, use of force or other
                forms of coercion for the purpose of exploitation.
              </ThemeTypography>
            </QuestionContainer>
            <QuestionContainer>
              <ThemeTypography variant="BODY_LARGE_BOLD">
                Do you monitor and have documentation in place to ensure the health and safety of
                your workers?
              </ThemeTypography>
            </QuestionContainer>
            <QuestionContainer>
              <ThemeTypography variant="BODY_LARGE_BOLD">
                In the event of an accident, is adequate first aid provided in the company?
              </ThemeTypography>
            </QuestionContainer>
            <QuestionContainer>
              <ThemeTypography variant="BODY_LARGE_BOLD">
                Do you have trainings or information material in place, which explain safety
                inspections to ensure compliance with occupational safety regulations?
              </ThemeTypography>
            </QuestionContainer>
            <QuestionContainer>
              <ThemeTypography variant="BODY_LARGE_BOLD">
                How often are the trainings on health and safety conducted?
              </ThemeTypography>
            </QuestionContainer>
            <QuestionContainer>
              <ThemeTypography variant="BODY_LARGE_BOLD">
                Do you monitor and have documentation in place to allow workers and members of local
                communities to raise concerns or complaints anonymously and without fear of
                retailiation?
              </ThemeTypography>
            </QuestionContainer>
            <QuestionContainer>
              <ThemeTypography variant="BODY_LARGE_BOLD">
                How do you ensure that complaints are addressed promptly and fairly?
              </ThemeTypography>
            </QuestionContainer>
            <QuestionContainer>
              <ThemeTypography variant="BODY_LARGE_BOLD">
                Do you respect the rights of workers to form and join trade unions and to bargain
                collectively?
              </ThemeTypography>
            </QuestionContainer>
            <QuestionContainer>
              <ThemeTypography variant="BODY_LARGE_BOLD">
                Are there any trade unions or worker associations active within your operations?
              </ThemeTypography>
            </QuestionContainer>
            <QuestionContainer>
              <ThemeTypography variant="BODY_LARGE_BOLD">
                Are practices of any kind of discrimination, violation of equal rights, harassment
                and/or abusive treatment prevented?
              </ThemeTypography>
            </QuestionContainer>
            <QuestionContainer>
              <ThemeTypography variant="BODY_LARGE_BOLD">
                Do you monitor and have documentation in place to prevent discrimination based on
                race, gender, religion, age, disability, or any other protected characteristic?
                Please check selection and hiring procedure, as well as rules and policies for any
                indication of discrimination (discrimination includes unequal remuneration for
                equivalent work)
              </ThemeTypography>
            </QuestionContainer>
          </Box>
        </SectionContainer>

        {/* Section G */}
        <SectionContainer>
          <FlexBox mb={2}>
            <Pill>G</Pill>
            <ThemeTypography variant="TITLE_SMALL" color="GRAY_60">
              The principle of free, prior and informed consent (FPIC)
            </ThemeTypography>
          </FlexBox>
          <Box display="flex" flexDirection="column" gap={2}>
            <QuestionContainer>
              <ThemeTypography variant="BODY_LARGE_BOLD">
                Have new land acquisitions been carried out with free, prior, and informed consent
                (FPIC)?
              </ThemeTypography>
            </QuestionContainer>
            <QuestionContainer>
              <ThemeTypography variant="BODY_LARGE_BOLD">
                Do you monitor and have documentation in place to protect the cultural heritage of
                indigenous and local communities in your production area?
              </ThemeTypography>
            </QuestionContainer>
          </Box>
        </SectionContainer>

        {/* Section H */}
        <SectionContainer>
          <FlexBox mb={2}>
            <Pill>H</Pill>
            <ThemeTypography variant="TITLE_SMALL" color="GRAY_60">
              Audit
            </ThemeTypography>
          </FlexBox>
          <Box display="flex" flexDirection="column" gap={2}>
            <QuestionContainer>
              <ThemeTypography variant="BODY_LARGE_BOLD">
                Do you agree to a supplier audit by your partners in the EU or auditors appointed by
                your partners in the EU?
              </ThemeTypography>
            </QuestionContainer>
          </Box>
        </SectionContainer>
      </Box>
    </EudrComplianceGuideSubSection>
  );
};

export default PreviewPartnerAssessment;
