import { Card, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import ThemeButton from 'designSystem/Buttons/ThemeButton/ThemeButton';
import React, { FC } from 'react';

const Container = styled(Card)(({ theme }) => ({
  margin: theme.spacing(4),
  padding: theme.spacing(3),
}));

const ErrorView: FC = () => {
  const refreshPage = () => {
    window.location.reload();
  };

  return (
    <Container>
      <Grid container direction="column" spacing={3} justifyContent="center" alignItems="center">
        <Grid item>Sorry, an unexpected error occurred. Please try to reload the page.</Grid>
        <Grid item>
          <ThemeButton color="YELLOW" size="large" onClick={refreshPage}>
            Reload
          </ThemeButton>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ErrorView;
